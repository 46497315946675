import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'
import { HeaderResult } from '../data/HeaderFragment'
import { LinkResult } from '../data/LinkFragment'
import { LinkListResult } from '../data/LinkListFragment'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Container } from './Container'
import styles from './HeaderMain.module.sass'
import { Icon } from './Icon'
import { LanguageSwitcher } from './LanguageSwitcher'
import { MenuToggle } from './MenuToggle'
import { useHomeUrl } from './SpecialLinksProvider'
import { letterClipTargetClassName } from './LetterClip'

export interface HeaderMainProps {
	header: HeaderResult | undefined
	locale: string
	locales: Array<{ code: string; url: string }>
	isInsideMenu?: boolean
	showMobileHeader: boolean
	toggleMobileHeader: () => void
}

export const HeaderMain: FunctionComponent<HeaderMainProps> = ({
	header,
	locale,
	locales,
	showMobileHeader,
	toggleMobileHeader,
	isInsideMenu = false,
}) => {
	const homeUrl = useHomeUrl()
	const linkList = header?.localesByLocale?.linkList

	return (
		<Container size="ultraWide">
			<div className={clsx(styles.wrapper, isInsideMenu && styles.is_inside_menu)}>
				<div className={styles.primary}>
					<div className={styles.primary_links}>
						{linkList && <LinkList list={linkList} />}
					</div>
					{false /* manually enable when CZ content ready */ && isInsideMenu && (
						<div className={styles.smallSwitch}>
							<LanguageSwitcher locale={locale} locales={locales} />
						</div>
					)}
				</div>
				<h1 className={styles.title}>
					<Link href={homeUrl} className={styles.title_link} aria-label="Artin">
						<Icon name="logo" />
					</Link>
				</h1>
				<div className={styles.secondary}>
					<div className={styles.social}>
						{header?.localesByLocale?.socialLinkList && <LinkList list={header.localesByLocale.socialLinkList} />}
					</div>
					<div className={`${styles.primary_links} ${styles.right_links}`}>
						{linkList && <LinkList list={{...linkList, items: linkList.items.slice(-1)}} /> }
					</div>
					{false /* manually enable when CZ content ready */ && (
						<div className={styles.largeSwitch}>
							<LanguageSwitcher locale={locale} locales={locales} />
						</div>
					)}
					<div className={styles.toggle}>
						<MenuToggle isOpen={showMobileHeader} toggle={toggleMobileHeader} />
					</div>
				</div>
			</div>
		</Container>
	)
}

const LinkList: FunctionComponent<{ list: LinkListResult }> = ({ list }) => {
	return <ul className={styles.links}>{list.items.map(({ id, link }) => link && <LinkItem key={id} link={link} />)}</ul>
}

const LinkItem: FunctionComponent<{ link: LinkResult }> = ({ link }) => {
	const { asPath } = useRouter()
	const href = contemberLinkToHref(link)
	const isActive = href === asPath

	return (
		<li className={clsx(styles.links_item, letterClipTargetClassName)}>
			<Link
				{...contemberLinkToHrefTargetRel(link)}
				className={clsx(styles.links_link, isActive && styles.is_active)}
				data-content={link.title}
			>
				{link.title}
			</Link>
		</li>
	)
}
