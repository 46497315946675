import { createContext, FunctionComponent, ReactNode, useContext } from 'react'

const localeContext = createContext<string>('en')

export interface LocaleProviderProps {
	locale: string
	children: ReactNode
}

export const LocaleProvider: FunctionComponent<LocaleProviderProps> = ({ locale, children }) => {
	return <localeContext.Provider value={locale}>{children}</localeContext.Provider>
}

export const useLocale = () => useContext(localeContext)
