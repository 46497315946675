// Reexport all svg icons
export { default as arrow } from './arrow.svg'
export { default as a_filled } from './a_filled.svg'
export { default as a_outline } from './a_outline.svg'
export { default as chevron } from './chevron.svg'
export { default as email } from './email.svg'
export { default as facebook } from './facebook.svg'
export { default as instagram } from './instagram.svg'
export { default as linkedin } from './linkedin.svg'
export { default as lock } from './lock.svg'
export { default as logo } from './logo.svg'
export { default as phone } from './phone.svg'
export { default as twitter } from './twitter.svg'
export { default as youtube } from './youtube.svg'
