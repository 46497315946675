/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Json: `scalar.Json` as const,
	_RelationSide: "enum" as const,
	_OnDeleteBehaviour: "enum" as const,
	_OrderByDirection: "enum" as const,
	Query:{
		getGeneral:{
			by:"GeneralUniqueWhere",
			filter:"GeneralWhere"
		},
		listGeneral:{
			filter:"GeneralWhere",
			orderBy:"GeneralOrderBy"
		},
		paginateGeneral:{
			filter:"GeneralWhere",
			orderBy:"GeneralOrderBy"
		},
		getImage:{
			by:"ImageUniqueWhere",
			filter:"ImageWhere"
		},
		listImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		paginateImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		getGenericPage:{
			by:"GenericPageUniqueWhere",
			filter:"GenericPageWhere"
		},
		listGenericPage:{
			filter:"GenericPageWhere",
			orderBy:"GenericPageOrderBy"
		},
		paginateGenericPage:{
			filter:"GenericPageWhere",
			orderBy:"GenericPageOrderBy"
		},
		getLinkable:{
			by:"LinkableUniqueWhere",
			filter:"LinkableWhere"
		},
		listLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		paginateLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		getSeo:{
			by:"SeoUniqueWhere",
			filter:"SeoWhere"
		},
		listSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		paginateSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		getContent:{
			by:"ContentUniqueWhere",
			filter:"ContentWhere"
		},
		listContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		paginateContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		getContentBlock:{
			by:"ContentBlockUniqueWhere",
			filter:"ContentBlockWhere"
		},
		listContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		paginateContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		getContentReference:{
			by:"ContentReferenceUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		listContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		paginateContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		getGeneralLocale:{
			by:"GeneralLocaleUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		listGeneralLocale:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		paginateGeneralLocale:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		getLocale:{
			by:"LocaleUniqueWhere",
			filter:"LocaleWhere"
		},
		listLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		paginateLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		getGenericPageLocale:{
			by:"GenericPageLocaleUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		listGenericPageLocale:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		paginateGenericPageLocale:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		getLink:{
			by:"LinkUniqueWhere",
			filter:"LinkWhere"
		},
		listLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		paginateLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		getLinkList:{
			by:"LinkListUniqueWhere",
			filter:"LinkListWhere"
		},
		listLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		paginateLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		getLinkListItem:{
			by:"LinkListItemUniqueWhere",
			filter:"LinkListItemWhere"
		},
		listLinkListItem:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		paginateLinkListItem:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		getHeader:{
			by:"HeaderUniqueWhere",
			filter:"HeaderWhere"
		},
		listHeader:{
			filter:"HeaderWhere",
			orderBy:"HeaderOrderBy"
		},
		paginateHeader:{
			filter:"HeaderWhere",
			orderBy:"HeaderOrderBy"
		},
		getHeaderLocale:{
			by:"HeaderLocaleUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		listHeaderLocale:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		paginateHeaderLocale:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		getFooter:{
			by:"FooterUniqueWhere",
			filter:"FooterWhere"
		},
		listFooter:{
			filter:"FooterWhere",
			orderBy:"FooterOrderBy"
		},
		paginateFooter:{
			filter:"FooterWhere",
			orderBy:"FooterOrderBy"
		},
		getFooterLocale:{
			by:"FooterLocaleUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		listFooterLocale:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		paginateFooterLocale:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		getCaseStudy:{
			by:"CaseStudyUniqueWhere",
			filter:"CaseStudyWhere"
		},
		listCaseStudy:{
			filter:"CaseStudyWhere",
			orderBy:"CaseStudyOrderBy"
		},
		paginateCaseStudy:{
			filter:"CaseStudyWhere",
			orderBy:"CaseStudyOrderBy"
		},
		getCaseStudyLocale:{
			by:"CaseStudyLocaleUniqueWhere",
			filter:"CaseStudyLocaleWhere"
		},
		listCaseStudyLocale:{
			filter:"CaseStudyLocaleWhere",
			orderBy:"CaseStudyLocaleOrderBy"
		},
		paginateCaseStudyLocale:{
			filter:"CaseStudyLocaleWhere",
			orderBy:"CaseStudyLocaleOrderBy"
		},
		getTag:{
			by:"TagUniqueWhere",
			filter:"TagWhere"
		},
		listTag:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateTag:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		getTagLocale:{
			by:"TagLocaleUniqueWhere",
			filter:"TagLocaleWhere"
		},
		listTagLocale:{
			filter:"TagLocaleWhere",
			orderBy:"TagLocaleOrderBy"
		},
		paginateTagLocale:{
			filter:"TagLocaleWhere",
			orderBy:"TagLocaleOrderBy"
		},
		getCaseStudyTag:{
			by:"CaseStudyTagUniqueWhere",
			filter:"CaseStudyTagWhere"
		},
		listCaseStudyTag:{
			filter:"CaseStudyTagWhere",
			orderBy:"CaseStudyTagOrderBy"
		},
		paginateCaseStudyTag:{
			filter:"CaseStudyTagWhere",
			orderBy:"CaseStudyTagOrderBy"
		},
		getCaseStudyList:{
			by:"CaseStudyListUniqueWhere",
			filter:"CaseStudyListWhere"
		},
		listCaseStudyList:{
			filter:"CaseStudyListWhere",
			orderBy:"CaseStudyListOrderBy"
		},
		paginateCaseStudyList:{
			filter:"CaseStudyListWhere",
			orderBy:"CaseStudyListOrderBy"
		},
		getCaseStudyListItem:{
			by:"CaseStudyListItemUniqueWhere",
			filter:"CaseStudyListItemWhere"
		},
		listCaseStudyListItem:{
			filter:"CaseStudyListItemWhere",
			orderBy:"CaseStudyListItemOrderBy"
		},
		paginateCaseStudyListItem:{
			filter:"CaseStudyListItemWhere",
			orderBy:"CaseStudyListItemOrderBy"
		},
		getImageList:{
			by:"ImageListUniqueWhere",
			filter:"ImageListWhere"
		},
		listImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		paginateImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		getImageListItem:{
			by:"ImageListItemUniqueWhere",
			filter:"ImageListItemWhere"
		},
		listImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		paginateImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		getInformationList:{
			by:"InformationListUniqueWhere",
			filter:"InformationListWhere"
		},
		listInformationList:{
			filter:"InformationListWhere",
			orderBy:"InformationListOrderBy"
		},
		paginateInformationList:{
			filter:"InformationListWhere",
			orderBy:"InformationListOrderBy"
		},
		getPieceOfInformation:{
			by:"PieceOfInformationUniqueWhere",
			filter:"PieceOfInformationWhere"
		},
		listPieceOfInformation:{
			filter:"PieceOfInformationWhere",
			orderBy:"PieceOfInformationOrderBy"
		},
		paginatePieceOfInformation:{
			filter:"PieceOfInformationWhere",
			orderBy:"PieceOfInformationOrderBy"
		},
		getPositionTag:{
			by:"PositionTagUniqueWhere",
			filter:"PositionTagWhere"
		},
		listPositionTag:{
			filter:"PositionTagWhere",
			orderBy:"PositionTagOrderBy"
		},
		paginatePositionTag:{
			filter:"PositionTagWhere",
			orderBy:"PositionTagOrderBy"
		},
		getPositionTagLocale:{
			by:"PositionTagLocaleUniqueWhere",
			filter:"PositionTagLocaleWhere"
		},
		listPositionTagLocale:{
			filter:"PositionTagLocaleWhere",
			orderBy:"PositionTagLocaleOrderBy"
		},
		paginatePositionTagLocale:{
			filter:"PositionTagLocaleWhere",
			orderBy:"PositionTagLocaleOrderBy"
		},
		getPosition:{
			by:"PositionUniqueWhere",
			filter:"PositionWhere"
		},
		listPosition:{
			filter:"PositionWhere",
			orderBy:"PositionOrderBy"
		},
		paginatePosition:{
			filter:"PositionWhere",
			orderBy:"PositionOrderBy"
		},
		getPositionLocale:{
			by:"PositionLocaleUniqueWhere",
			filter:"PositionLocaleWhere"
		},
		listPositionLocale:{
			filter:"PositionLocaleWhere",
			orderBy:"PositionLocaleOrderBy"
		},
		paginatePositionLocale:{
			filter:"PositionLocaleWhere",
			orderBy:"PositionLocaleOrderBy"
		},
		getPositionTagList:{
			by:"PositionTagListUniqueWhere",
			filter:"PositionTagListWhere"
		},
		listPositionTagList:{
			filter:"PositionTagListWhere",
			orderBy:"PositionTagListOrderBy"
		},
		paginatePositionTagList:{
			filter:"PositionTagListWhere",
			orderBy:"PositionTagListOrderBy"
		},
		getPositionTagListItem:{
			by:"PositionTagListItemUniqueWhere",
			filter:"PositionTagListItemWhere"
		},
		listPositionTagListItem:{
			filter:"PositionTagListItemWhere",
			orderBy:"PositionTagListItemOrderBy"
		},
		paginatePositionTagListItem:{
			filter:"PositionTagListItemWhere",
			orderBy:"PositionTagListItemOrderBy"
		},
		getPositionCategory:{
			by:"PositionCategoryUniqueWhere",
			filter:"PositionCategoryWhere"
		},
		listPositionCategory:{
			filter:"PositionCategoryWhere",
			orderBy:"PositionCategoryOrderBy"
		},
		paginatePositionCategory:{
			filter:"PositionCategoryWhere",
			orderBy:"PositionCategoryOrderBy"
		},
		getPositionCategoryLocale:{
			by:"PositionCategoryLocaleUniqueWhere",
			filter:"PositionCategoryLocaleWhere"
		},
		listPositionCategoryLocale:{
			filter:"PositionCategoryLocaleWhere",
			orderBy:"PositionCategoryLocaleOrderBy"
		},
		paginatePositionCategoryLocale:{
			filter:"PositionCategoryLocaleWhere",
			orderBy:"PositionCategoryLocaleOrderBy"
		},
		getEmbed:{
			by:"EmbedUniqueWhere",
			filter:"EmbedWhere"
		},
		listEmbed:{
			filter:"EmbedWhere",
			orderBy:"EmbedOrderBy"
		},
		paginateEmbed:{
			filter:"EmbedWhere",
			orderBy:"EmbedOrderBy"
		},
		getRedirect:{
			by:"RedirectUniqueWhere",
			filter:"RedirectWhere"
		},
		listRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		paginateRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		getTalk:{
			by:"TalkUniqueWhere",
			filter:"TalkWhere"
		},
		listTalk:{
			filter:"TalkWhere",
			orderBy:"TalkOrderBy"
		},
		paginateTalk:{
			filter:"TalkWhere",
			orderBy:"TalkOrderBy"
		},
		getTalkList:{
			by:"TalkListUniqueWhere",
			filter:"TalkListWhere"
		},
		listTalkList:{
			filter:"TalkListWhere",
			orderBy:"TalkListOrderBy"
		},
		paginateTalkList:{
			filter:"TalkListWhere",
			orderBy:"TalkListOrderBy"
		},
		getTalkListItem:{
			by:"TalkListItemUniqueWhere",
			filter:"TalkListItemWhere"
		},
		listTalkListItem:{
			filter:"TalkListItemWhere",
			orderBy:"TalkListItemOrderBy"
		},
		paginateTalkListItem:{
			filter:"TalkListItemWhere",
			orderBy:"TalkListItemOrderBy"
		},
		getTalkLists:{
			by:"TalkListsUniqueWhere",
			filter:"TalkListsWhere"
		},
		listTalkLists:{
			filter:"TalkListsWhere",
			orderBy:"TalkListsOrderBy"
		},
		paginateTalkLists:{
			filter:"TalkListsWhere",
			orderBy:"TalkListsOrderBy"
		},
		getBusinessCard:{
			by:"BusinessCardUniqueWhere",
			filter:"BusinessCardWhere"
		},
		listBusinessCard:{
			filter:"BusinessCardWhere",
			orderBy:"BusinessCardOrderBy"
		},
		paginateBusinessCard:{
			filter:"BusinessCardWhere",
			orderBy:"BusinessCardOrderBy"
		},
		getBusinessCardList:{
			by:"BusinessCardListUniqueWhere",
			filter:"BusinessCardListWhere"
		},
		listBusinessCardList:{
			filter:"BusinessCardListWhere",
			orderBy:"BusinessCardListOrderBy"
		},
		paginateBusinessCardList:{
			filter:"BusinessCardListWhere",
			orderBy:"BusinessCardListOrderBy"
		},
		getBusinessCardListItem:{
			by:"BusinessCardListItemUniqueWhere",
			filter:"BusinessCardListItemWhere"
		},
		listBusinessCardListItem:{
			filter:"BusinessCardListItemWhere",
			orderBy:"BusinessCardListItemOrderBy"
		},
		paginateBusinessCardListItem:{
			filter:"BusinessCardListItemWhere",
			orderBy:"BusinessCardListItemOrderBy"
		},
		getTestimonial:{
			by:"TestimonialUniqueWhere",
			filter:"TestimonialWhere"
		},
		listTestimonial:{
			filter:"TestimonialWhere",
			orderBy:"TestimonialOrderBy"
		},
		paginateTestimonial:{
			filter:"TestimonialWhere",
			orderBy:"TestimonialOrderBy"
		},
		getTestimonialList:{
			by:"TestimonialListUniqueWhere",
			filter:"TestimonialListWhere"
		},
		listTestimonialList:{
			filter:"TestimonialListWhere",
			orderBy:"TestimonialListOrderBy"
		},
		paginateTestimonialList:{
			filter:"TestimonialListWhere",
			orderBy:"TestimonialListOrderBy"
		},
		getTestimonialListItem:{
			by:"TestimonialListItemUniqueWhere",
			filter:"TestimonialListItemWhere"
		},
		listTestimonialListItem:{
			filter:"TestimonialListItemWhere",
			orderBy:"TestimonialListItemOrderBy"
		},
		paginateTestimonialListItem:{
			filter:"TestimonialListItemWhere",
			orderBy:"TestimonialListItemOrderBy"
		},
		getContactRequestOption:{
			by:"ContactRequestOptionUniqueWhere",
			filter:"ContactRequestOptionWhere"
		},
		listContactRequestOption:{
			filter:"ContactRequestOptionWhere",
			orderBy:"ContactRequestOptionOrderBy"
		},
		paginateContactRequestOption:{
			filter:"ContactRequestOptionWhere",
			orderBy:"ContactRequestOptionOrderBy"
		},
		getContactRequestOptionList:{
			by:"ContactRequestOptionListUniqueWhere",
			filter:"ContactRequestOptionListWhere"
		},
		listContactRequestOptionList:{
			filter:"ContactRequestOptionListWhere",
			orderBy:"ContactRequestOptionListOrderBy"
		},
		paginateContactRequestOptionList:{
			filter:"ContactRequestOptionListWhere",
			orderBy:"ContactRequestOptionListOrderBy"
		},
		getYoutubeVideo:{
			by:"YoutubeVideoUniqueWhere",
			filter:"YoutubeVideoWhere"
		},
		listYoutubeVideo:{
			filter:"YoutubeVideoWhere",
			orderBy:"YoutubeVideoOrderBy"
		},
		paginateYoutubeVideo:{
			filter:"YoutubeVideoWhere",
			orderBy:"YoutubeVideoOrderBy"
		},
		getYoutubeVideoList:{
			by:"YoutubeVideoListUniqueWhere",
			filter:"YoutubeVideoListWhere"
		},
		listYoutubeVideoList:{
			filter:"YoutubeVideoListWhere",
			orderBy:"YoutubeVideoListOrderBy"
		},
		paginateYoutubeVideoList:{
			filter:"YoutubeVideoListWhere",
			orderBy:"YoutubeVideoListOrderBy"
		},
		getYoutubeVideoListItem:{
			by:"YoutubeVideoListItemUniqueWhere",
			filter:"YoutubeVideoListItemWhere"
		},
		listYoutubeVideoListItem:{
			filter:"YoutubeVideoListItemWhere",
			orderBy:"YoutubeVideoListItemOrderBy"
		},
		paginateYoutubeVideoListItem:{
			filter:"YoutubeVideoListItemWhere",
			orderBy:"YoutubeVideoListItemOrderBy"
		},
		getTranslationCatalogue:{
			by:"TranslationCatalogueUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		listTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		paginateTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		getTranslationCataloguesIdentifier:{
			by:"TranslationCataloguesIdentifierUniqueWhere",
			filter:"TranslationCataloguesIdentifierWhere"
		},
		listTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		paginateTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		getTranslationDomain:{
			by:"TranslationDomainUniqueWhere",
			filter:"TranslationDomainWhere"
		},
		listTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		paginateTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		getTranslationKey:{
			by:"TranslationKeyUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		listTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		paginateTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		getTranslationValue:{
			by:"TranslationValueUniqueWhere",
			filter:"TranslationValueWhere"
		},
		listTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		paginateTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		validateCreateContactRequestSubmission:{
			data:"ContactRequestSubmissionCreateInput"
		},
		getSubscribeForm:{
			by:"SubscribeFormUniqueWhere",
			filter:"SubscribeFormWhere"
		},
		listSubscribeForm:{
			filter:"SubscribeFormWhere",
			orderBy:"SubscribeFormOrderBy"
		},
		paginateSubscribeForm:{
			filter:"SubscribeFormWhere",
			orderBy:"SubscribeFormOrderBy"
		},
		validateCreateSubscribeForm:{
			data:"SubscribeFormCreateInput"
		},
		validateUpdateSubscribeForm:{
			by:"SubscribeFormUniqueWhere",
			data:"SubscribeFormUpdateInput"
		},
		validateCreateSubscribeSubmission:{
			data:"SubscribeSubmissionCreateInput"
		},
		getContentReferencePositionCategoryItem:{
			by:"ContentReferencePositionCategoryItemUniqueWhere",
			filter:"ContentReferencePositionCategoryItemWhere"
		},
		listContentReferencePositionCategoryItem:{
			filter:"ContentReferencePositionCategoryItemWhere",
			orderBy:"ContentReferencePositionCategoryItemOrderBy"
		},
		paginateContentReferencePositionCategoryItem:{
			filter:"ContentReferencePositionCategoryItemWhere",
			orderBy:"ContentReferencePositionCategoryItemOrderBy"
		},
		getVideo:{
			by:"VideoUniqueWhere",
			filter:"VideoWhere"
		},
		listVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		paginateVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		getContentReferenceCaseStudyItem:{
			by:"ContentReferenceCaseStudyItemUniqueWhere",
			filter:"ContentReferenceCaseStudyItemWhere"
		},
		listContentReferenceCaseStudyItem:{
			filter:"ContentReferenceCaseStudyItemWhere",
			orderBy:"ContentReferenceCaseStudyItemOrderBy"
		},
		paginateContentReferenceCaseStudyItem:{
			filter:"ContentReferenceCaseStudyItemWhere",
			orderBy:"ContentReferenceCaseStudyItemOrderBy"
		},
		getContentReferenceTagItem:{
			by:"ContentReferenceTagItemUniqueWhere",
			filter:"ContentReferenceTagItemWhere"
		},
		listContentReferenceTagItem:{
			filter:"ContentReferenceTagItemWhere",
			orderBy:"ContentReferenceTagItemOrderBy"
		},
		paginateContentReferenceTagItem:{
			filter:"ContentReferenceTagItemWhere",
			orderBy:"ContentReferenceTagItemOrderBy"
		},
		getPositionList:{
			by:"PositionListUniqueWhere",
			filter:"PositionListWhere"
		},
		listPositionList:{
			filter:"PositionListWhere",
			orderBy:"PositionListOrderBy"
		},
		paginatePositionList:{
			filter:"PositionListWhere",
			orderBy:"PositionListOrderBy"
		},
		getPositionListItem:{
			by:"PositionListItemUniqueWhere",
			filter:"PositionListItemWhere"
		},
		listPositionListItem:{
			filter:"PositionListItemWhere",
			orderBy:"PositionListItemOrderBy"
		},
		paginatePositionListItem:{
			filter:"PositionListItemWhere",
			orderBy:"PositionListItemOrderBy"
		},
		getTestimonialFull:{
			by:"TestimonialFullUniqueWhere",
			filter:"TestimonialFullWhere"
		},
		listTestimonialFull:{
			filter:"TestimonialFullWhere",
			orderBy:"TestimonialFullOrderBy"
		},
		paginateTestimonialFull:{
			filter:"TestimonialFullWhere",
			orderBy:"TestimonialFullOrderBy"
		},
		getTestimonialFullList:{
			by:"TestimonialFullListUniqueWhere",
			filter:"TestimonialFullListWhere"
		},
		listTestimonialFullList:{
			filter:"TestimonialFullListWhere",
			orderBy:"TestimonialFullListOrderBy"
		},
		paginateTestimonialFullList:{
			filter:"TestimonialFullListWhere",
			orderBy:"TestimonialFullListOrderBy"
		},
		getTestimonialFullListItem:{
			by:"TestimonialFullListItemUniqueWhere",
			filter:"TestimonialFullListItemWhere"
		},
		listTestimonialFullListItem:{
			filter:"TestimonialFullListItemWhere",
			orderBy:"TestimonialFullListItemOrderBy"
		},
		paginateTestimonialFullListItem:{
			filter:"TestimonialFullListItemWhere",
			orderBy:"TestimonialFullListItemOrderBy"
		},
		getSecondLevelLinkItem:{
			by:"SecondLevelLinkItemUniqueWhere",
			filter:"SecondLevelLinkItemWhere"
		},
		listSecondLevelLinkItem:{
			filter:"SecondLevelLinkItemWhere",
			orderBy:"SecondLevelLinkItemOrderBy"
		},
		paginateSecondLevelLinkItem:{
			filter:"SecondLevelLinkItemWhere",
			orderBy:"SecondLevelLinkItemOrderBy"
		},
		getThirdLevelLinkItem:{
			by:"ThirdLevelLinkItemUniqueWhere",
			filter:"ThirdLevelLinkItemWhere"
		},
		listThirdLevelLinkItem:{
			filter:"ThirdLevelLinkItemWhere",
			orderBy:"ThirdLevelLinkItemOrderBy"
		},
		paginateThirdLevelLinkItem:{
			filter:"ThirdLevelLinkItemWhere",
			orderBy:"ThirdLevelLinkItemOrderBy"
		},
		getTopLevelLinkItem:{
			by:"TopLevelLinkItemUniqueWhere",
			filter:"TopLevelLinkItemWhere"
		},
		listTopLevelLinkItem:{
			filter:"TopLevelLinkItemWhere",
			orderBy:"TopLevelLinkItemOrderBy"
		},
		paginateTopLevelLinkItem:{
			filter:"TopLevelLinkItemWhere",
			orderBy:"TopLevelLinkItemOrderBy"
		},
		getAuthor:{
			by:"AuthorUniqueWhere",
			filter:"AuthorWhere"
		},
		listAuthor:{
			filter:"AuthorWhere",
			orderBy:"AuthorOrderBy"
		},
		paginateAuthor:{
			filter:"AuthorWhere",
			orderBy:"AuthorOrderBy"
		},
		getAuthorLocale:{
			by:"AuthorLocaleUniqueWhere",
			filter:"AuthorLocaleWhere"
		},
		listAuthorLocale:{
			filter:"AuthorLocaleWhere",
			orderBy:"AuthorLocaleOrderBy"
		},
		paginateAuthorLocale:{
			filter:"AuthorLocaleWhere",
			orderBy:"AuthorLocaleOrderBy"
		},
		getBlogPost:{
			by:"BlogPostUniqueWhere",
			filter:"BlogPostWhere"
		},
		listBlogPost:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		},
		paginateBlogPost:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		},
		getBlogPostLocale:{
			by:"BlogPostLocaleUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		listBlogPostLocale:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		paginateBlogPostLocale:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		getContentReferenceBlogPostItem:{
			by:"ContentReferenceBlogPostItemUniqueWhere",
			filter:"ContentReferenceBlogPostItemWhere"
		},
		listContentReferenceBlogPostItem:{
			filter:"ContentReferenceBlogPostItemWhere",
			orderBy:"ContentReferenceBlogPostItemOrderBy"
		},
		paginateContentReferenceBlogPostItem:{
			filter:"ContentReferenceBlogPostItemWhere",
			orderBy:"ContentReferenceBlogPostItemOrderBy"
		},
		getRelevantBlogPostList:{
			by:"RelevantBlogPostListUniqueWhere",
			filter:"RelevantBlogPostListWhere"
		},
		listRelevantBlogPostList:{
			filter:"RelevantBlogPostListWhere",
			orderBy:"RelevantBlogPostListOrderBy"
		},
		paginateRelevantBlogPostList:{
			filter:"RelevantBlogPostListWhere",
			orderBy:"RelevantBlogPostListOrderBy"
		},
		getBlogPostCommon:{
			by:"BlogPostCommonUniqueWhere",
			filter:"BlogPostCommonWhere"
		},
		listBlogPostCommon:{
			filter:"BlogPostCommonWhere",
			orderBy:"BlogPostCommonOrderBy"
		},
		paginateBlogPostCommon:{
			filter:"BlogPostCommonWhere",
			orderBy:"BlogPostCommonOrderBy"
		},
		getBlogPostCommonLocale:{
			by:"BlogPostCommonLocaleUniqueWhere",
			filter:"BlogPostCommonLocaleWhere"
		},
		listBlogPostCommonLocale:{
			filter:"BlogPostCommonLocaleWhere",
			orderBy:"BlogPostCommonLocaleOrderBy"
		},
		paginateBlogPostCommonLocale:{
			filter:"BlogPostCommonLocaleWhere",
			orderBy:"BlogPostCommonLocaleOrderBy"
		}
	},
	General:{
		locales:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		localesByLocale:{
			by:"GeneralLocalesByLocaleUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		localesBySeo:{
			by:"GeneralLocalesBySeoUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		localesByContent:{
			by:"GeneralLocalesByContentUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		paginateLocales:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		}
	},
	UUID: `scalar.UUID` as const,
	One: "enum" as const,
	GeneralLocale:{
		root:{
			filter:"GeneralWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		seo:{
			filter:"SeoWhere"
		},
		homeLinkable:{
			filter:"LinkableWhere"
		},
		caseStudiesLink:{
			filter:"LinkWhere"
		},
		positionsLink:{
			filter:"LinkWhere"
		},
		content:{
			filter:"ContentWhere"
		}
	},
	GeneralWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"GeneralLocaleWhere",
		and:"GeneralWhere",
		or:"GeneralWhere",
		not:"GeneralWhere"
	},
	UUIDCondition:{
		and:"UUIDCondition",
		or:"UUIDCondition",
		not:"UUIDCondition",
		eq:"UUID",
		notEq:"UUID",
		in:"UUID",
		notIn:"UUID",
		lt:"UUID",
		lte:"UUID",
		gt:"UUID",
		gte:"UUID"
	},
	OneCondition:{
		and:"OneCondition",
		or:"OneCondition",
		not:"OneCondition",
		eq:"One",
		notEq:"One",
		in:"One",
		notIn:"One",
		lt:"One",
		lte:"One",
		gt:"One",
		gte:"One"
	},
	GeneralLocaleWhere:{
		id:"UUIDCondition",
		root:"GeneralWhere",
		locale:"LocaleWhere",
		seo:"SeoWhere",
		homeLinkable:"LinkableWhere",
		caseStudiesLink:"LinkWhere",
		positionsLink:"LinkWhere",
		facebookUrl:"StringCondition",
		twitterUrl:"StringCondition",
		instagramUrl:"StringCondition",
		youtubeUrl:"StringCondition",
		linkedinUrl:"StringCondition",
		formPrivacyPolicy:"StringCondition",
		formMarketingConsent:"StringCondition",
		acceptButtonLabel:"StringCondition",
		rejectButtonLabel:"StringCondition",
		content:"ContentWhere",
		and:"GeneralLocaleWhere",
		or:"GeneralLocaleWhere",
		not:"GeneralLocaleWhere"
	},
	LocaleWhere:{
		id:"UUIDCondition",
		code:"StringCondition",
		label:"StringCondition",
		order:"IntCondition",
		general:"GeneralLocaleWhere",
		genericPages:"GenericPageLocaleWhere",
		headers:"HeaderLocaleWhere",
		footers:"FooterLocaleWhere",
		caseStudies:"CaseStudyLocaleWhere",
		tags:"TagLocaleWhere",
		positionTags:"PositionTagLocaleWhere",
		positions:"PositionLocaleWhere",
		positionCategories:"PositionCategoryLocaleWhere",
		authors:"AuthorLocaleWhere",
		blogPosts:"BlogPostLocaleWhere",
		blogPostCommon:"BlogPostCommonLocaleWhere",
		and:"LocaleWhere",
		or:"LocaleWhere",
		not:"LocaleWhere"
	},
	StringCondition:{
		and:"StringCondition",
		or:"StringCondition",
		not:"StringCondition"
	},
	IntCondition:{
		and:"IntCondition",
		or:"IntCondition",
		not:"IntCondition"
	},
	GenericPageLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		root:"GenericPageWhere",
		locale:"LocaleWhere",
		content:"ContentWhere",
		link:"LinkableWhere",
		seo:"SeoWhere",
		and:"GenericPageLocaleWhere",
		or:"GenericPageLocaleWhere",
		not:"GenericPageLocaleWhere"
	},
	GenericPageWhere:{
		id:"UUIDCondition",
		locales:"GenericPageLocaleWhere",
		publishedAt:"DateTimeCondition",
		and:"GenericPageWhere",
		or:"GenericPageWhere",
		not:"GenericPageWhere"
	},
	DateTimeCondition:{
		and:"DateTimeCondition",
		or:"DateTimeCondition",
		not:"DateTimeCondition",
		eq:"DateTime",
		notEq:"DateTime",
		in:"DateTime",
		notIn:"DateTime",
		lt:"DateTime",
		lte:"DateTime",
		gt:"DateTime",
		gte:"DateTime"
	},
	DateTime: `scalar.DateTime` as const,
	ContentWhere:{
		id:"UUIDCondition",
		blocks:"ContentBlockWhere",
		and:"ContentWhere",
		or:"ContentWhere",
		not:"ContentWhere"
	},
	ContentBlockWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		json:"StringCondition",
		content:"ContentWhere",
		references:"ContentReferenceWhere",
		and:"ContentBlockWhere",
		or:"ContentBlockWhere",
		not:"ContentBlockWhere"
	},
	ContentReferenceWhere:{
		id:"UUIDCondition",
		type:"ContentReferenceTypeCondition",
		primaryText:"StringCondition",
		block:"ContentBlockWhere",
		image:"ImageWhere",
		secondaryText:"StringCondition",
		link:"LinkWhere",
		featuredCaseStudies:"CaseStudyListWhere",
		images:"ImageListWhere",
		otherImages:"ImageListWhere",
		informationList:"InformationListWhere",
		tertiaryText:"StringCondition",
		embed:"EmbedWhere",
		links:"LinkListWhere",
		talkLists:"TalkListsWhere",
		businessCardList:"BusinessCardListWhere",
		testimonials:"TestimonialListWhere",
		background:"ContentBackgroundTypeCondition",
		otherImage:"ImageWhere",
		caseStudy:"CaseStudyWhere",
		alternative:"BooleanCondition",
		contactRequestOptions:"ContactRequestOptionListWhere",
		youtubeVideo:"YoutubeVideoWhere",
		subscribeForm:"SubscribeFormWhere",
		video:"VideoWhere",
		otherVideo:"VideoWhere",
		positionCategories:"ContentReferencePositionCategoryItemWhere",
		caseStudies:"ContentReferenceCaseStudyItemWhere",
		tags:"ContentReferenceTagItemWhere",
		positions:"PositionListWhere",
		testimonialsFull:"TestimonialFullListWhere",
		blogPosts:"ContentReferenceBlogPostItemWhere",
		verticalMargin:"ContentVerticalMarginCondition",
		variant:"StringCondition",
		content:"ContentWhere",
		and:"ContentReferenceWhere",
		or:"ContentReferenceWhere",
		not:"ContentReferenceWhere"
	},
	ContentReferenceTypeCondition:{
		and:"ContentReferenceTypeCondition",
		or:"ContentReferenceTypeCondition",
		not:"ContentReferenceTypeCondition",
		eq:"ContentReferenceType",
		notEq:"ContentReferenceType",
		in:"ContentReferenceType",
		notIn:"ContentReferenceType",
		lt:"ContentReferenceType",
		lte:"ContentReferenceType",
		gt:"ContentReferenceType",
		gte:"ContentReferenceType"
	},
	ContentReferenceType: "enum" as const,
	ImageWhere:{
		id:"UUIDCondition",
		url:"StringCondition",
		width:"IntCondition",
		height:"IntCondition",
		size:"IntCondition",
		type:"StringCondition",
		alt:"StringCondition",
		fileName:"StringCondition",
		and:"ImageWhere",
		or:"ImageWhere",
		not:"ImageWhere"
	},
	LinkWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		externalLink:"StringCondition",
		internalLink:"LinkableWhere",
		type:"LinkTypeCondition",
		isTargetBlank:"BooleanCondition",
		and:"LinkWhere",
		or:"LinkWhere",
		not:"LinkWhere"
	},
	LinkableWhere:{
		id:"UUIDCondition",
		url:"StringCondition",
		genericPage:"GenericPageLocaleWhere",
		caseStudy:"CaseStudyLocaleWhere",
		position:"PositionLocaleWhere",
		redirect:"RedirectWhere",
		blogPost:"BlogPostLocaleWhere",
		and:"LinkableWhere",
		or:"LinkableWhere",
		not:"LinkableWhere"
	},
	CaseStudyLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		root:"CaseStudyWhere",
		locale:"LocaleWhere",
		content:"ContentWhere",
		link:"LinkableWhere",
		seo:"SeoWhere",
		note:"StringCondition",
		and:"CaseStudyLocaleWhere",
		or:"CaseStudyLocaleWhere",
		not:"CaseStudyLocaleWhere"
	},
	CaseStudyWhere:{
		id:"UUIDCondition",
		locales:"CaseStudyLocaleWhere",
		tags:"CaseStudyTagWhere",
		wideImage:"ImageWhere",
		narrowImage:"ImageWhere",
		order:"IntCondition",
		publishedAt:"DateTimeCondition",
		and:"CaseStudyWhere",
		or:"CaseStudyWhere",
		not:"CaseStudyWhere"
	},
	CaseStudyTagWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		caseStudy:"CaseStudyWhere",
		tag:"TagWhere",
		and:"CaseStudyTagWhere",
		or:"CaseStudyTagWhere",
		not:"CaseStudyTagWhere"
	},
	TagWhere:{
		id:"UUIDCondition",
		locales:"TagLocaleWhere",
		caseStudyTags:"CaseStudyTagWhere",
		order:"IntCondition",
		and:"TagWhere",
		or:"TagWhere",
		not:"TagWhere"
	},
	TagLocaleWhere:{
		id:"UUIDCondition",
		slug:"StringCondition",
		title:"StringCondition",
		root:"TagWhere",
		locale:"LocaleWhere",
		and:"TagLocaleWhere",
		or:"TagLocaleWhere",
		not:"TagLocaleWhere"
	},
	SeoWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		description:"StringCondition",
		ogTitle:"StringCondition",
		ogDescription:"StringCondition",
		ogImage:"ImageWhere",
		noIndex:"BooleanCondition",
		noFollow:"BooleanCondition",
		and:"SeoWhere",
		or:"SeoWhere",
		not:"SeoWhere"
	},
	BooleanCondition:{
		and:"BooleanCondition",
		or:"BooleanCondition",
		not:"BooleanCondition"
	},
	PositionLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		link:"LinkableWhere",
		root:"PositionWhere",
		locale:"LocaleWhere",
		seo:"SeoWhere",
		content:"ContentWhere",
		summary:"StringCondition",
		salaryRange:"StringCondition",
		and:"PositionLocaleWhere",
		or:"PositionLocaleWhere",
		not:"PositionLocaleWhere"
	},
	PositionWhere:{
		id:"UUIDCondition",
		tags:"PositionTagListWhere",
		locales:"PositionLocaleWhere",
		orderInCategory:"IntCondition",
		category:"PositionCategoryWhere",
		publishedAt:"DateTimeCondition",
		and:"PositionWhere",
		or:"PositionWhere",
		not:"PositionWhere"
	},
	PositionTagListWhere:{
		id:"UUIDCondition",
		items:"PositionTagListItemWhere",
		and:"PositionTagListWhere",
		or:"PositionTagListWhere",
		not:"PositionTagListWhere"
	},
	PositionTagListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"PositionTagListWhere",
		tag:"PositionTagWhere",
		and:"PositionTagListItemWhere",
		or:"PositionTagListItemWhere",
		not:"PositionTagListItemWhere"
	},
	PositionTagWhere:{
		id:"UUIDCondition",
		locales:"PositionTagLocaleWhere",
		and:"PositionTagWhere",
		or:"PositionTagWhere",
		not:"PositionTagWhere"
	},
	PositionTagLocaleWhere:{
		id:"UUIDCondition",
		slug:"StringCondition",
		title:"StringCondition",
		root:"PositionTagWhere",
		locale:"LocaleWhere",
		and:"PositionTagLocaleWhere",
		or:"PositionTagLocaleWhere",
		not:"PositionTagLocaleWhere"
	},
	PositionCategoryWhere:{
		id:"UUIDCondition",
		positions:"PositionWhere",
		locales:"PositionCategoryLocaleWhere",
		order:"IntCondition",
		and:"PositionCategoryWhere",
		or:"PositionCategoryWhere",
		not:"PositionCategoryWhere"
	},
	PositionCategoryLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		root:"PositionCategoryWhere",
		locale:"LocaleWhere",
		and:"PositionCategoryLocaleWhere",
		or:"PositionCategoryLocaleWhere",
		not:"PositionCategoryLocaleWhere"
	},
	RedirectWhere:{
		id:"UUIDCondition",
		link:"LinkableWhere",
		target:"LinkWhere",
		and:"RedirectWhere",
		or:"RedirectWhere",
		not:"RedirectWhere"
	},
	BlogPostLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		root:"BlogPostWhere",
		locale:"LocaleWhere",
		content:"ContentWhere",
		seo:"SeoWhere",
		link:"LinkableWhere",
		lead:"StringCondition",
		and:"BlogPostLocaleWhere",
		or:"BlogPostLocaleWhere",
		not:"BlogPostLocaleWhere"
	},
	BlogPostWhere:{
		id:"UUIDCondition",
		publishedAt:"DateTimeCondition",
		author:"AuthorWhere",
		wideImage:"ImageWhere",
		narrowImage:"ImageWhere",
		locales:"BlogPostLocaleWhere",
		relevantBlogPosts:"RelevantBlogPostListWhere",
		and:"BlogPostWhere",
		or:"BlogPostWhere",
		not:"BlogPostWhere"
	},
	AuthorWhere:{
		id:"UUIDCondition",
		locales:"AuthorLocaleWhere",
		blogPosts:"BlogPostWhere",
		name:"StringCondition",
		profileUrl:"StringCondition",
		image:"ImageWhere",
		avatarImage:"ImageWhere",
		and:"AuthorWhere",
		or:"AuthorWhere",
		not:"AuthorWhere"
	},
	AuthorLocaleWhere:{
		id:"UUIDCondition",
		role:"StringCondition",
		bio:"StringCondition",
		root:"AuthorWhere",
		locale:"LocaleWhere",
		and:"AuthorLocaleWhere",
		or:"AuthorLocaleWhere",
		not:"AuthorLocaleWhere"
	},
	RelevantBlogPostListWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		blogPost:"BlogPostWhere",
		relevantBlogPost:"BlogPostWhere",
		and:"RelevantBlogPostListWhere",
		or:"RelevantBlogPostListWhere",
		not:"RelevantBlogPostListWhere"
	},
	LinkTypeCondition:{
		and:"LinkTypeCondition",
		or:"LinkTypeCondition",
		not:"LinkTypeCondition",
		eq:"LinkType",
		notEq:"LinkType",
		in:"LinkType",
		notIn:"LinkType",
		lt:"LinkType",
		lte:"LinkType",
		gt:"LinkType",
		gte:"LinkType"
	},
	LinkType: "enum" as const,
	CaseStudyListWhere:{
		id:"UUIDCondition",
		items:"CaseStudyListItemWhere",
		and:"CaseStudyListWhere",
		or:"CaseStudyListWhere",
		not:"CaseStudyListWhere"
	},
	CaseStudyListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"CaseStudyListWhere",
		caseStudy:"CaseStudyWhere",
		and:"CaseStudyListItemWhere",
		or:"CaseStudyListItemWhere",
		not:"CaseStudyListItemWhere"
	},
	ImageListWhere:{
		id:"UUIDCondition",
		items:"ImageListItemWhere",
		and:"ImageListWhere",
		or:"ImageListWhere",
		not:"ImageListWhere"
	},
	ImageListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"ImageListWhere",
		image:"ImageWhere",
		and:"ImageListItemWhere",
		or:"ImageListItemWhere",
		not:"ImageListItemWhere"
	},
	InformationListWhere:{
		id:"UUIDCondition",
		items:"PieceOfInformationWhere",
		and:"InformationListWhere",
		or:"InformationListWhere",
		not:"InformationListWhere"
	},
	PieceOfInformationWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		title:"StringCondition",
		description:"StringCondition",
		list:"InformationListWhere",
		and:"PieceOfInformationWhere",
		or:"PieceOfInformationWhere",
		not:"PieceOfInformationWhere"
	},
	EmbedWhere:{
		id:"UUIDCondition",
		type:"EmbedTypeCondition",
		embedId:"StringCondition",
		and:"EmbedWhere",
		or:"EmbedWhere",
		not:"EmbedWhere"
	},
	EmbedTypeCondition:{
		and:"EmbedTypeCondition",
		or:"EmbedTypeCondition",
		not:"EmbedTypeCondition",
		eq:"EmbedType",
		notEq:"EmbedType",
		in:"EmbedType",
		notIn:"EmbedType",
		lt:"EmbedType",
		lte:"EmbedType",
		gt:"EmbedType",
		gte:"EmbedType"
	},
	EmbedType: "enum" as const,
	LinkListWhere:{
		id:"UUIDCondition",
		items:"LinkListItemWhere",
		and:"LinkListWhere",
		or:"LinkListWhere",
		not:"LinkListWhere"
	},
	LinkListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"LinkListWhere",
		link:"LinkWhere",
		and:"LinkListItemWhere",
		or:"LinkListItemWhere",
		not:"LinkListItemWhere"
	},
	TalkListsWhere:{
		id:"UUIDCondition",
		items:"TalkListWhere",
		and:"TalkListsWhere",
		or:"TalkListsWhere",
		not:"TalkListsWhere"
	},
	TalkListWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		title:"StringCondition",
		list:"TalkListsWhere",
		items:"TalkListItemWhere",
		and:"TalkListWhere",
		or:"TalkListWhere",
		not:"TalkListWhere"
	},
	TalkListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"TalkListWhere",
		talk:"TalkWhere",
		and:"TalkListItemWhere",
		or:"TalkListItemWhere",
		not:"TalkListItemWhere"
	},
	TalkWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		slug:"StringCondition",
		date:"DateTimeCondition",
		note:"StringCondition",
		content:"StringCondition",
		coverImage:"ImageWhere",
		otherImage:"ImageWhere",
		and:"TalkWhere",
		or:"TalkWhere",
		not:"TalkWhere"
	},
	BusinessCardListWhere:{
		id:"UUIDCondition",
		items:"BusinessCardListItemWhere",
		and:"BusinessCardListWhere",
		or:"BusinessCardListWhere",
		not:"BusinessCardListWhere"
	},
	BusinessCardListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"BusinessCardListWhere",
		businessCard:"BusinessCardWhere",
		and:"BusinessCardListItemWhere",
		or:"BusinessCardListItemWhere",
		not:"BusinessCardListItemWhere"
	},
	BusinessCardWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		role:"StringCondition",
		email:"StringCondition",
		avatar:"ImageWhere",
		phone:"StringCondition",
		and:"BusinessCardWhere",
		or:"BusinessCardWhere",
		not:"BusinessCardWhere"
	},
	TestimonialListWhere:{
		id:"UUIDCondition",
		items:"TestimonialListItemWhere",
		and:"TestimonialListWhere",
		or:"TestimonialListWhere",
		not:"TestimonialListWhere"
	},
	TestimonialListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"TestimonialListWhere",
		testimonial:"TestimonialWhere",
		and:"TestimonialListItemWhere",
		or:"TestimonialListItemWhere",
		not:"TestimonialListItemWhere"
	},
	TestimonialWhere:{
		id:"UUIDCondition",
		content:"StringCondition",
		note:"StringCondition",
		avatar:"ImageWhere",
		and:"TestimonialWhere",
		or:"TestimonialWhere",
		not:"TestimonialWhere"
	},
	ContentBackgroundTypeCondition:{
		and:"ContentBackgroundTypeCondition",
		or:"ContentBackgroundTypeCondition",
		not:"ContentBackgroundTypeCondition",
		eq:"ContentBackgroundType",
		notEq:"ContentBackgroundType",
		in:"ContentBackgroundType",
		notIn:"ContentBackgroundType",
		lt:"ContentBackgroundType",
		lte:"ContentBackgroundType",
		gt:"ContentBackgroundType",
		gte:"ContentBackgroundType"
	},
	ContentBackgroundType: "enum" as const,
	ContactRequestOptionListWhere:{
		id:"UUIDCondition",
		items:"ContactRequestOptionWhere",
		and:"ContactRequestOptionListWhere",
		or:"ContactRequestOptionListWhere",
		not:"ContactRequestOptionListWhere"
	},
	ContactRequestOptionWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		label:"StringCondition",
		description:"StringCondition",
		list:"ContactRequestOptionListWhere",
		code:"StringCondition",
		type:"ContactRequestOptionTypeCondition",
		subscribeForm:"SubscribeFormWhere",
		and:"ContactRequestOptionWhere",
		or:"ContactRequestOptionWhere",
		not:"ContactRequestOptionWhere"
	},
	ContactRequestOptionTypeCondition:{
		and:"ContactRequestOptionTypeCondition",
		or:"ContactRequestOptionTypeCondition",
		not:"ContactRequestOptionTypeCondition",
		eq:"ContactRequestOptionType",
		notEq:"ContactRequestOptionType",
		in:"ContactRequestOptionType",
		notIn:"ContactRequestOptionType",
		lt:"ContactRequestOptionType",
		lte:"ContactRequestOptionType",
		gt:"ContactRequestOptionType",
		gte:"ContactRequestOptionType"
	},
	ContactRequestOptionType: "enum" as const,
	SubscribeFormWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		datacruitJobId:"IntCondition",
		datacruitReferenceNumber:"StringCondition",
		lastUpdateAt:"DateTimeCondition",
		and:"SubscribeFormWhere",
		or:"SubscribeFormWhere",
		not:"SubscribeFormWhere"
	},
	YoutubeVideoWhere:{
		id:"UUIDCondition",
		videoId:"StringCondition",
		and:"YoutubeVideoWhere",
		or:"YoutubeVideoWhere",
		not:"YoutubeVideoWhere"
	},
	VideoWhere:{
		id:"UUIDCondition",
		src:"StringCondition",
		height:"IntCondition",
		width:"IntCondition",
		duration:"FloatCondition",
		size:"IntCondition",
		type:"StringCondition",
		and:"VideoWhere",
		or:"VideoWhere",
		not:"VideoWhere"
	},
	FloatCondition:{
		and:"FloatCondition",
		or:"FloatCondition",
		not:"FloatCondition"
	},
	ContentReferencePositionCategoryItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		item:"PositionCategoryWhere",
		reference:"ContentReferenceWhere",
		and:"ContentReferencePositionCategoryItemWhere",
		or:"ContentReferencePositionCategoryItemWhere",
		not:"ContentReferencePositionCategoryItemWhere"
	},
	ContentReferenceCaseStudyItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		item:"CaseStudyWhere",
		reference:"ContentReferenceWhere",
		and:"ContentReferenceCaseStudyItemWhere",
		or:"ContentReferenceCaseStudyItemWhere",
		not:"ContentReferenceCaseStudyItemWhere"
	},
	ContentReferenceTagItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		item:"TagWhere",
		reference:"ContentReferenceWhere",
		and:"ContentReferenceTagItemWhere",
		or:"ContentReferenceTagItemWhere",
		not:"ContentReferenceTagItemWhere"
	},
	PositionListWhere:{
		id:"UUIDCondition",
		items:"PositionListItemWhere",
		and:"PositionListWhere",
		or:"PositionListWhere",
		not:"PositionListWhere"
	},
	PositionListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"PositionListWhere",
		position:"PositionLocaleWhere",
		and:"PositionListItemWhere",
		or:"PositionListItemWhere",
		not:"PositionListItemWhere"
	},
	TestimonialFullListWhere:{
		id:"UUIDCondition",
		items:"TestimonialFullListItemWhere",
		and:"TestimonialFullListWhere",
		or:"TestimonialFullListWhere",
		not:"TestimonialFullListWhere"
	},
	TestimonialFullListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"TestimonialFullListWhere",
		testimonial:"TestimonialFullWhere",
		and:"TestimonialFullListItemWhere",
		or:"TestimonialFullListItemWhere",
		not:"TestimonialFullListItemWhere"
	},
	TestimonialFullWhere:{
		id:"UUIDCondition",
		content:"StringCondition",
		name:"StringCondition",
		note:"StringCondition",
		avatar:"ImageWhere",
		imageVariant:"ImageVariantCondition",
		and:"TestimonialFullWhere",
		or:"TestimonialFullWhere",
		not:"TestimonialFullWhere"
	},
	ImageVariantCondition:{
		and:"ImageVariantCondition",
		or:"ImageVariantCondition",
		not:"ImageVariantCondition",
		eq:"ImageVariant",
		notEq:"ImageVariant",
		in:"ImageVariant",
		notIn:"ImageVariant",
		lt:"ImageVariant",
		lte:"ImageVariant",
		gt:"ImageVariant",
		gte:"ImageVariant"
	},
	ImageVariant: "enum" as const,
	ContentReferenceBlogPostItemWhere:{
		id:"UUIDCondition",
		publishedAt:"DateCondition",
		item:"BlogPostWhere",
		reference:"ContentReferenceWhere",
		and:"ContentReferenceBlogPostItemWhere",
		or:"ContentReferenceBlogPostItemWhere",
		not:"ContentReferenceBlogPostItemWhere"
	},
	DateCondition:{
		and:"DateCondition",
		or:"DateCondition",
		not:"DateCondition",
		eq:"Date",
		notEq:"Date",
		in:"Date",
		notIn:"Date",
		lt:"Date",
		lte:"Date",
		gt:"Date",
		gte:"Date"
	},
	Date: `scalar.Date` as const,
	ContentVerticalMarginCondition:{
		and:"ContentVerticalMarginCondition",
		or:"ContentVerticalMarginCondition",
		not:"ContentVerticalMarginCondition",
		eq:"ContentVerticalMargin",
		notEq:"ContentVerticalMargin",
		in:"ContentVerticalMargin",
		notIn:"ContentVerticalMargin",
		lt:"ContentVerticalMargin",
		lte:"ContentVerticalMargin",
		gt:"ContentVerticalMargin",
		gte:"ContentVerticalMargin"
	},
	ContentVerticalMargin: "enum" as const,
	HeaderLocaleWhere:{
		id:"UUIDCondition",
		email:"StringCondition",
		root:"HeaderWhere",
		locale:"LocaleWhere",
		linkList:"LinkListWhere",
		socialLinkList:"LinkListWhere",
		topLevelLinkItems:"TopLevelLinkItemWhere",
		and:"HeaderLocaleWhere",
		or:"HeaderLocaleWhere",
		not:"HeaderLocaleWhere"
	},
	HeaderWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"HeaderLocaleWhere",
		and:"HeaderWhere",
		or:"HeaderWhere",
		not:"HeaderWhere"
	},
	TopLevelLinkItemWhere:{
		id:"UUIDCondition",
		isLink:"IsLinkCondition",
		order:"IntCondition",
		title:"StringCondition",
		secondLevelLinkItems:"SecondLevelLinkItemWhere",
		header:"HeaderLocaleWhere",
		link:"LinkWhere",
		and:"TopLevelLinkItemWhere",
		or:"TopLevelLinkItemWhere",
		not:"TopLevelLinkItemWhere"
	},
	IsLinkCondition:{
		and:"IsLinkCondition",
		or:"IsLinkCondition",
		not:"IsLinkCondition",
		eq:"IsLink",
		notEq:"IsLink",
		in:"IsLink",
		notIn:"IsLink",
		lt:"IsLink",
		lte:"IsLink",
		gt:"IsLink",
		gte:"IsLink"
	},
	IsLink: "enum" as const,
	SecondLevelLinkItemWhere:{
		id:"UUIDCondition",
		isLink:"IsLinkCondition",
		order:"IntCondition",
		title:"StringCondition",
		topLevelLinkItem:"TopLevelLinkItemWhere",
		link:"LinkWhere",
		thirdLevelLinkItems:"ThirdLevelLinkItemWhere",
		and:"SecondLevelLinkItemWhere",
		or:"SecondLevelLinkItemWhere",
		not:"SecondLevelLinkItemWhere"
	},
	ThirdLevelLinkItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		secondLevelLinkItem:"SecondLevelLinkItemWhere",
		link:"LinkWhere",
		and:"ThirdLevelLinkItemWhere",
		or:"ThirdLevelLinkItemWhere",
		not:"ThirdLevelLinkItemWhere"
	},
	FooterLocaleWhere:{
		id:"UUIDCondition",
		email:"StringCondition",
		root:"FooterWhere",
		locale:"LocaleWhere",
		address:"StringCondition",
		phone:"StringCondition",
		legal:"StringCondition",
		navigation:"LinkListWhere",
		and:"FooterLocaleWhere",
		or:"FooterLocaleWhere",
		not:"FooterLocaleWhere"
	},
	FooterWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"FooterLocaleWhere",
		and:"FooterWhere",
		or:"FooterWhere",
		not:"FooterWhere"
	},
	BlogPostCommonLocaleWhere:{
		id:"UUIDCondition",
		root:"BlogPostCommonWhere",
		locale:"LocaleWhere",
		ctaLink:"LinkWhere",
		ctaTitle:"StringCondition",
		and:"BlogPostCommonLocaleWhere",
		or:"BlogPostCommonLocaleWhere",
		not:"BlogPostCommonLocaleWhere"
	},
	BlogPostCommonWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"BlogPostCommonLocaleWhere",
		and:"BlogPostCommonWhere",
		or:"BlogPostCommonWhere",
		not:"BlogPostCommonWhere"
	},
	Locale:{
		general:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		genericPages:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		headers:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		footers:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		caseStudies:{
			filter:"CaseStudyLocaleWhere",
			orderBy:"CaseStudyLocaleOrderBy"
		},
		tags:{
			filter:"TagLocaleWhere",
			orderBy:"TagLocaleOrderBy"
		},
		positionTags:{
			filter:"PositionTagLocaleWhere",
			orderBy:"PositionTagLocaleOrderBy"
		},
		positions:{
			filter:"PositionLocaleWhere",
			orderBy:"PositionLocaleOrderBy"
		},
		positionCategories:{
			filter:"PositionCategoryLocaleWhere",
			orderBy:"PositionCategoryLocaleOrderBy"
		},
		authors:{
			filter:"AuthorLocaleWhere",
			orderBy:"AuthorLocaleOrderBy"
		},
		blogPosts:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		blogPostCommon:{
			filter:"BlogPostCommonLocaleWhere",
			orderBy:"BlogPostCommonLocaleOrderBy"
		},
		generalByRoot:{
			by:"LocaleGeneralByRootUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		generalBySeo:{
			by:"LocaleGeneralBySeoUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		generalByContent:{
			by:"LocaleGeneralByContentUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		genericPagesByRoot:{
			by:"LocaleGenericPagesByRootUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		genericPagesByContent:{
			by:"LocaleGenericPagesByContentUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		genericPagesByLink:{
			by:"LocaleGenericPagesByLinkUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		genericPagesBySeo:{
			by:"LocaleGenericPagesBySeoUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		headersByRoot:{
			by:"LocaleHeadersByRootUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		headersByLinkList:{
			by:"LocaleHeadersByLinkListUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		headersBySocialLinkList:{
			by:"LocaleHeadersBySocialLinkListUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		headersByTopLevelLinkItems:{
			by:"LocaleHeadersByTopLevelLinkItemsUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		footersByRoot:{
			by:"LocaleFootersByRootUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		footersByNavigation:{
			by:"LocaleFootersByNavigationUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		caseStudiesByRoot:{
			by:"LocaleCaseStudiesByRootUniqueWhere",
			filter:"CaseStudyLocaleWhere"
		},
		caseStudiesByContent:{
			by:"LocaleCaseStudiesByContentUniqueWhere",
			filter:"CaseStudyLocaleWhere"
		},
		caseStudiesByLink:{
			by:"LocaleCaseStudiesByLinkUniqueWhere",
			filter:"CaseStudyLocaleWhere"
		},
		caseStudiesBySeo:{
			by:"LocaleCaseStudiesBySeoUniqueWhere",
			filter:"CaseStudyLocaleWhere"
		},
		tagsBySlug:{
			by:"LocaleTagsBySlugUniqueWhere",
			filter:"TagLocaleWhere"
		},
		tagsByRoot:{
			by:"LocaleTagsByRootUniqueWhere",
			filter:"TagLocaleWhere"
		},
		positionTagsBySlug:{
			by:"LocalePositionTagsBySlugUniqueWhere",
			filter:"PositionTagLocaleWhere"
		},
		positionTagsByRoot:{
			by:"LocalePositionTagsByRootUniqueWhere",
			filter:"PositionTagLocaleWhere"
		},
		positionsByRoot:{
			by:"LocalePositionsByRootUniqueWhere",
			filter:"PositionLocaleWhere"
		},
		positionsByLink:{
			by:"LocalePositionsByLinkUniqueWhere",
			filter:"PositionLocaleWhere"
		},
		positionsBySeo:{
			by:"LocalePositionsBySeoUniqueWhere",
			filter:"PositionLocaleWhere"
		},
		positionsByContent:{
			by:"LocalePositionsByContentUniqueWhere",
			filter:"PositionLocaleWhere"
		},
		positionCategoriesByRoot:{
			by:"LocalePositionCategoriesByRootUniqueWhere",
			filter:"PositionCategoryLocaleWhere"
		},
		authorsByRoot:{
			by:"LocaleAuthorsByRootUniqueWhere",
			filter:"AuthorLocaleWhere"
		},
		blogPostsByRoot:{
			by:"LocaleBlogPostsByRootUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		blogPostsByContent:{
			by:"LocaleBlogPostsByContentUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		blogPostsBySeo:{
			by:"LocaleBlogPostsBySeoUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		blogPostsByLink:{
			by:"LocaleBlogPostsByLinkUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		blogPostCommonByRoot:{
			by:"LocaleBlogPostCommonByRootUniqueWhere",
			filter:"BlogPostCommonLocaleWhere"
		},
		paginateGeneral:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		paginateGenericPages:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		paginateHeaders:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		paginateFooters:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		paginateCaseStudies:{
			filter:"CaseStudyLocaleWhere",
			orderBy:"CaseStudyLocaleOrderBy"
		},
		paginateTags:{
			filter:"TagLocaleWhere",
			orderBy:"TagLocaleOrderBy"
		},
		paginatePositionTags:{
			filter:"PositionTagLocaleWhere",
			orderBy:"PositionTagLocaleOrderBy"
		},
		paginatePositions:{
			filter:"PositionLocaleWhere",
			orderBy:"PositionLocaleOrderBy"
		},
		paginatePositionCategories:{
			filter:"PositionCategoryLocaleWhere",
			orderBy:"PositionCategoryLocaleOrderBy"
		},
		paginateAuthors:{
			filter:"AuthorLocaleWhere",
			orderBy:"AuthorLocaleOrderBy"
		},
		paginateBlogPosts:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		paginateBlogPostCommon:{
			filter:"BlogPostCommonLocaleWhere",
			orderBy:"BlogPostCommonLocaleOrderBy"
		}
	},
	GeneralLocaleOrderBy:{
		id:"OrderDirection",
		root:"GeneralOrderBy",
		locale:"LocaleOrderBy",
		seo:"SeoOrderBy",
		homeLinkable:"LinkableOrderBy",
		caseStudiesLink:"LinkOrderBy",
		positionsLink:"LinkOrderBy",
		facebookUrl:"OrderDirection",
		twitterUrl:"OrderDirection",
		instagramUrl:"OrderDirection",
		youtubeUrl:"OrderDirection",
		linkedinUrl:"OrderDirection",
		formPrivacyPolicy:"OrderDirection",
		formMarketingConsent:"OrderDirection",
		acceptButtonLabel:"OrderDirection",
		rejectButtonLabel:"OrderDirection",
		content:"ContentOrderBy"
	},
	OrderDirection: "enum" as const,
	GeneralOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection"
	},
	LocaleOrderBy:{
		id:"OrderDirection",
		code:"OrderDirection",
		label:"OrderDirection",
		order:"OrderDirection"
	},
	SeoOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		description:"OrderDirection",
		ogTitle:"OrderDirection",
		ogDescription:"OrderDirection",
		ogImage:"ImageOrderBy",
		noIndex:"OrderDirection",
		noFollow:"OrderDirection"
	},
	ImageOrderBy:{
		id:"OrderDirection",
		url:"OrderDirection",
		width:"OrderDirection",
		height:"OrderDirection",
		size:"OrderDirection",
		type:"OrderDirection",
		alt:"OrderDirection",
		fileName:"OrderDirection"
	},
	LinkableOrderBy:{
		id:"OrderDirection",
		url:"OrderDirection",
		genericPage:"GenericPageLocaleOrderBy",
		caseStudy:"CaseStudyLocaleOrderBy",
		position:"PositionLocaleOrderBy",
		redirect:"RedirectOrderBy",
		blogPost:"BlogPostLocaleOrderBy"
	},
	GenericPageLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		root:"GenericPageOrderBy",
		locale:"LocaleOrderBy",
		content:"ContentOrderBy",
		link:"LinkableOrderBy",
		seo:"SeoOrderBy"
	},
	GenericPageOrderBy:{
		id:"OrderDirection",
		publishedAt:"OrderDirection"
	},
	ContentOrderBy:{
		id:"OrderDirection"
	},
	CaseStudyLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		root:"CaseStudyOrderBy",
		locale:"LocaleOrderBy",
		content:"ContentOrderBy",
		link:"LinkableOrderBy",
		seo:"SeoOrderBy",
		note:"OrderDirection"
	},
	CaseStudyOrderBy:{
		id:"OrderDirection",
		wideImage:"ImageOrderBy",
		narrowImage:"ImageOrderBy",
		order:"OrderDirection",
		publishedAt:"OrderDirection"
	},
	PositionLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		link:"LinkableOrderBy",
		root:"PositionOrderBy",
		locale:"LocaleOrderBy",
		seo:"SeoOrderBy",
		content:"ContentOrderBy",
		summary:"OrderDirection",
		salaryRange:"OrderDirection"
	},
	PositionOrderBy:{
		id:"OrderDirection",
		tags:"PositionTagListOrderBy",
		orderInCategory:"OrderDirection",
		category:"PositionCategoryOrderBy",
		publishedAt:"OrderDirection"
	},
	PositionTagListOrderBy:{
		id:"OrderDirection"
	},
	PositionCategoryOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection"
	},
	RedirectOrderBy:{
		id:"OrderDirection",
		link:"LinkableOrderBy",
		target:"LinkOrderBy"
	},
	LinkOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		externalLink:"OrderDirection",
		internalLink:"LinkableOrderBy",
		type:"OrderDirection",
		isTargetBlank:"OrderDirection"
	},
	BlogPostLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		root:"BlogPostOrderBy",
		locale:"LocaleOrderBy",
		content:"ContentOrderBy",
		seo:"SeoOrderBy",
		link:"LinkableOrderBy",
		lead:"OrderDirection"
	},
	BlogPostOrderBy:{
		id:"OrderDirection",
		publishedAt:"OrderDirection",
		author:"AuthorOrderBy",
		wideImage:"ImageOrderBy",
		narrowImage:"ImageOrderBy"
	},
	AuthorOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		profileUrl:"OrderDirection",
		image:"ImageOrderBy",
		avatarImage:"ImageOrderBy"
	},
	GenericPageLocale:{
		root:{
			filter:"GenericPageWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		content:{
			filter:"ContentWhere"
		},
		link:{
			filter:"LinkableWhere"
		},
		seo:{
			filter:"SeoWhere"
		}
	},
	GenericPage:{
		locales:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		localesByLocale:{
			by:"GenericPageLocalesByLocaleUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		localesByContent:{
			by:"GenericPageLocalesByContentUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		localesByLink:{
			by:"GenericPageLocalesByLinkUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		localesBySeo:{
			by:"GenericPageLocalesBySeoUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		paginateLocales:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		}
	},
	GenericPageLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	LocaleUniqueWhere:{
		id:"UUID",
		general:"GeneralLocaleUniqueWhere",
		genericPages:"GenericPageLocaleUniqueWhere",
		headers:"HeaderLocaleUniqueWhere",
		footers:"FooterLocaleUniqueWhere",
		caseStudies:"CaseStudyLocaleUniqueWhere",
		tags:"TagLocaleUniqueWhere",
		positionTags:"PositionTagLocaleUniqueWhere",
		positions:"PositionLocaleUniqueWhere",
		positionCategories:"PositionCategoryLocaleUniqueWhere",
		authors:"AuthorLocaleUniqueWhere",
		blogPosts:"BlogPostLocaleUniqueWhere",
		blogPostCommon:"BlogPostCommonLocaleUniqueWhere"
	},
	GeneralLocaleUniqueWhere:{
		id:"UUID",
		root:"GeneralUniqueWhere",
		locale:"LocaleUniqueWhere",
		seo:"SeoUniqueWhere",
		content:"ContentUniqueWhere"
	},
	GeneralUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"GeneralLocaleUniqueWhere"
	},
	SeoUniqueWhere:{
		id:"UUID",
		ogImage:"ImageUniqueWhere"
	},
	ImageUniqueWhere:{
		id:"UUID"
	},
	ContentUniqueWhere:{
		id:"UUID",
		blocks:"ContentBlockUniqueWhere"
	},
	ContentBlockUniqueWhere:{
		id:"UUID",
		references:"ContentReferenceUniqueWhere"
	},
	ContentReferenceUniqueWhere:{
		id:"UUID",
		link:"LinkUniqueWhere",
		featuredCaseStudies:"CaseStudyListUniqueWhere",
		images:"ImageListUniqueWhere",
		otherImages:"ImageListUniqueWhere",
		informationList:"InformationListUniqueWhere",
		embed:"EmbedUniqueWhere",
		links:"LinkListUniqueWhere",
		talkLists:"TalkListsUniqueWhere",
		businessCardList:"BusinessCardListUniqueWhere",
		testimonials:"TestimonialListUniqueWhere",
		contactRequestOptions:"ContactRequestOptionListUniqueWhere",
		youtubeVideo:"YoutubeVideoUniqueWhere",
		positionCategories:"ContentReferencePositionCategoryItemUniqueWhere",
		caseStudies:"ContentReferenceCaseStudyItemUniqueWhere",
		tags:"ContentReferenceTagItemUniqueWhere",
		positions:"PositionListUniqueWhere",
		testimonialsFull:"TestimonialFullListUniqueWhere",
		blogPosts:"ContentReferenceBlogPostItemUniqueWhere",
		content:"ContentUniqueWhere"
	},
	LinkUniqueWhere:{
		id:"UUID"
	},
	CaseStudyListUniqueWhere:{
		id:"UUID",
		items:"CaseStudyListItemUniqueWhere"
	},
	CaseStudyListItemUniqueWhere:{
		id:"UUID"
	},
	ImageListUniqueWhere:{
		id:"UUID",
		items:"ImageListItemUniqueWhere"
	},
	ImageListItemUniqueWhere:{
		id:"UUID",
		image:"ImageUniqueWhere"
	},
	InformationListUniqueWhere:{
		id:"UUID",
		items:"PieceOfInformationUniqueWhere"
	},
	PieceOfInformationUniqueWhere:{
		id:"UUID"
	},
	EmbedUniqueWhere:{
		id:"UUID"
	},
	LinkListUniqueWhere:{
		id:"UUID",
		items:"LinkListItemUniqueWhere"
	},
	LinkListItemUniqueWhere:{
		id:"UUID",
		link:"LinkUniqueWhere"
	},
	TalkListsUniqueWhere:{
		id:"UUID",
		items:"TalkListUniqueWhere"
	},
	TalkListUniqueWhere:{
		id:"UUID",
		items:"TalkListItemUniqueWhere"
	},
	TalkListItemUniqueWhere:{
		id:"UUID"
	},
	BusinessCardListUniqueWhere:{
		id:"UUID",
		items:"BusinessCardListItemUniqueWhere"
	},
	BusinessCardListItemUniqueWhere:{
		id:"UUID",
		businessCard:"BusinessCardUniqueWhere"
	},
	BusinessCardUniqueWhere:{
		id:"UUID"
	},
	TestimonialListUniqueWhere:{
		id:"UUID",
		items:"TestimonialListItemUniqueWhere"
	},
	TestimonialListItemUniqueWhere:{
		id:"UUID",
		testimonial:"TestimonialUniqueWhere"
	},
	TestimonialUniqueWhere:{
		id:"UUID"
	},
	ContactRequestOptionListUniqueWhere:{
		id:"UUID",
		items:"ContactRequestOptionUniqueWhere"
	},
	ContactRequestOptionUniqueWhere:{
		id:"UUID"
	},
	YoutubeVideoUniqueWhere:{
		id:"UUID"
	},
	ContentReferencePositionCategoryItemUniqueWhere:{
		id:"UUID",
		item:"PositionCategoryUniqueWhere"
	},
	PositionCategoryUniqueWhere:{
		id:"UUID",
		positions:"PositionUniqueWhere",
		locales:"PositionCategoryLocaleUniqueWhere"
	},
	PositionUniqueWhere:{
		id:"UUID",
		tags:"PositionTagListUniqueWhere",
		locales:"PositionLocaleUniqueWhere"
	},
	PositionTagListUniqueWhere:{
		id:"UUID",
		items:"PositionTagListItemUniqueWhere"
	},
	PositionTagListItemUniqueWhere:{
		id:"UUID"
	},
	PositionLocaleUniqueWhere:{
		id:"UUID",
		root:"PositionUniqueWhere",
		locale:"LocaleUniqueWhere",
		link:"LinkableUniqueWhere",
		seo:"SeoUniqueWhere",
		content:"ContentUniqueWhere"
	},
	LinkableUniqueWhere:{
		id:"UUID",
		genericPage:"GenericPageLocaleUniqueWhere",
		caseStudy:"CaseStudyLocaleUniqueWhere",
		position:"PositionLocaleUniqueWhere",
		redirect:"RedirectUniqueWhere",
		blogPost:"BlogPostLocaleUniqueWhere"
	},
	GenericPageLocaleUniqueWhere:{
		id:"UUID",
		root:"GenericPageUniqueWhere",
		locale:"LocaleUniqueWhere",
		content:"ContentUniqueWhere",
		link:"LinkableUniqueWhere",
		seo:"SeoUniqueWhere"
	},
	GenericPageUniqueWhere:{
		id:"UUID",
		locales:"GenericPageLocaleUniqueWhere"
	},
	CaseStudyLocaleUniqueWhere:{
		id:"UUID",
		root:"CaseStudyUniqueWhere",
		locale:"LocaleUniqueWhere",
		content:"ContentUniqueWhere",
		link:"LinkableUniqueWhere",
		seo:"SeoUniqueWhere"
	},
	CaseStudyUniqueWhere:{
		id:"UUID",
		locales:"CaseStudyLocaleUniqueWhere",
		tags:"CaseStudyTagUniqueWhere"
	},
	CaseStudyTagUniqueWhere:{
		id:"UUID"
	},
	RedirectUniqueWhere:{
		id:"UUID",
		link:"LinkableUniqueWhere",
		target:"LinkUniqueWhere"
	},
	BlogPostLocaleUniqueWhere:{
		id:"UUID",
		root:"BlogPostUniqueWhere",
		locale:"LocaleUniqueWhere",
		content:"ContentUniqueWhere",
		seo:"SeoUniqueWhere",
		link:"LinkableUniqueWhere"
	},
	BlogPostUniqueWhere:{
		id:"UUID",
		locales:"BlogPostLocaleUniqueWhere",
		relevantBlogPosts:"RelevantBlogPostListUniqueWhere"
	},
	RelevantBlogPostListUniqueWhere:{
		id:"UUID",
		blogPost:"BlogPostUniqueWhere",
		relevantBlogPost:"BlogPostUniqueWhere"
	},
	PositionCategoryLocaleUniqueWhere:{
		id:"UUID",
		root:"PositionCategoryUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	ContentReferenceCaseStudyItemUniqueWhere:{
		id:"UUID",
		item:"CaseStudyUniqueWhere"
	},
	ContentReferenceTagItemUniqueWhere:{
		id:"UUID",
		item:"TagUniqueWhere"
	},
	TagUniqueWhere:{
		id:"UUID",
		locales:"TagLocaleUniqueWhere",
		caseStudyTags:"CaseStudyTagUniqueWhere"
	},
	TagLocaleUniqueWhere:{
		id:"UUID",
		locale:"LocaleUniqueWhere",
		root:"TagUniqueWhere"
	},
	PositionListUniqueWhere:{
		id:"UUID",
		items:"PositionListItemUniqueWhere"
	},
	PositionListItemUniqueWhere:{
		id:"UUID"
	},
	TestimonialFullListUniqueWhere:{
		id:"UUID",
		items:"TestimonialFullListItemUniqueWhere"
	},
	TestimonialFullListItemUniqueWhere:{
		id:"UUID",
		testimonial:"TestimonialFullUniqueWhere"
	},
	TestimonialFullUniqueWhere:{
		id:"UUID"
	},
	ContentReferenceBlogPostItemUniqueWhere:{
		id:"UUID",
		item:"BlogPostUniqueWhere"
	},
	HeaderLocaleUniqueWhere:{
		id:"UUID",
		root:"HeaderUniqueWhere",
		locale:"LocaleUniqueWhere",
		linkList:"LinkListUniqueWhere",
		socialLinkList:"LinkListUniqueWhere",
		topLevelLinkItems:"TopLevelLinkItemUniqueWhere"
	},
	HeaderUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"HeaderLocaleUniqueWhere"
	},
	TopLevelLinkItemUniqueWhere:{
		id:"UUID",
		secondLevelLinkItems:"SecondLevelLinkItemUniqueWhere",
		link:"LinkUniqueWhere"
	},
	SecondLevelLinkItemUniqueWhere:{
		id:"UUID",
		link:"LinkUniqueWhere",
		thirdLevelLinkItems:"ThirdLevelLinkItemUniqueWhere"
	},
	ThirdLevelLinkItemUniqueWhere:{
		id:"UUID",
		link:"LinkUniqueWhere"
	},
	FooterLocaleUniqueWhere:{
		id:"UUID",
		root:"FooterUniqueWhere",
		locale:"LocaleUniqueWhere",
		navigation:"LinkListUniqueWhere"
	},
	FooterUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"FooterLocaleUniqueWhere"
	},
	PositionTagLocaleUniqueWhere:{
		id:"UUID",
		locale:"LocaleUniqueWhere",
		root:"PositionTagUniqueWhere"
	},
	PositionTagUniqueWhere:{
		id:"UUID",
		locales:"PositionTagLocaleUniqueWhere"
	},
	AuthorLocaleUniqueWhere:{
		id:"UUID",
		root:"AuthorUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	AuthorUniqueWhere:{
		id:"UUID",
		locales:"AuthorLocaleUniqueWhere",
		blogPosts:"BlogPostUniqueWhere"
	},
	BlogPostCommonLocaleUniqueWhere:{
		id:"UUID",
		root:"BlogPostCommonUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	BlogPostCommonUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"BlogPostCommonLocaleUniqueWhere"
	},
	GenericPageLocalesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	GenericPageLocalesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	GenericPageLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	Content:{
		blocks:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		blocksByReferences:{
			by:"ContentBlocksByReferencesUniqueWhere",
			filter:"ContentBlockWhere"
		},
		paginateBlocks:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		}
	},
	ContentBlock:{
		content:{
			filter:"ContentWhere"
		},
		references:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		referencesByLink:{
			by:"ContentBlockReferencesByLinkUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByFeaturedCaseStudies:{
			by:"ContentBlockReferencesByFeaturedCaseStudiesUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByImages:{
			by:"ContentBlockReferencesByImagesUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByOtherImages:{
			by:"ContentBlockReferencesByOtherImagesUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByInformationList:{
			by:"ContentBlockReferencesByInformationListUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByEmbed:{
			by:"ContentBlockReferencesByEmbedUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByLinks:{
			by:"ContentBlockReferencesByLinksUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByTalkLists:{
			by:"ContentBlockReferencesByTalkListsUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByBusinessCardList:{
			by:"ContentBlockReferencesByBusinessCardListUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByTestimonials:{
			by:"ContentBlockReferencesByTestimonialsUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByContactRequestOptions:{
			by:"ContentBlockReferencesByContactRequestOptionsUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByYoutubeVideo:{
			by:"ContentBlockReferencesByYoutubeVideoUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByPositionCategories:{
			by:"ContentBlockReferencesByPositionCategoriesUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByCaseStudies:{
			by:"ContentBlockReferencesByCaseStudiesUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByTags:{
			by:"ContentBlockReferencesByTagsUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByPositions:{
			by:"ContentBlockReferencesByPositionsUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByTestimonialsFull:{
			by:"ContentBlockReferencesByTestimonialsFullUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByBlogPosts:{
			by:"ContentBlockReferencesByBlogPostsUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByContent:{
			by:"ContentBlockReferencesByContentUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		paginateReferences:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		}
	},
	ContentReference:{
		block:{
			filter:"ContentBlockWhere"
		},
		image:{
			filter:"ImageWhere"
		},
		link:{
			filter:"LinkWhere"
		},
		featuredCaseStudies:{
			filter:"CaseStudyListWhere"
		},
		images:{
			filter:"ImageListWhere"
		},
		otherImages:{
			filter:"ImageListWhere"
		},
		informationList:{
			filter:"InformationListWhere"
		},
		embed:{
			filter:"EmbedWhere"
		},
		links:{
			filter:"LinkListWhere"
		},
		talkLists:{
			filter:"TalkListsWhere"
		},
		businessCardList:{
			filter:"BusinessCardListWhere"
		},
		testimonials:{
			filter:"TestimonialListWhere"
		},
		otherImage:{
			filter:"ImageWhere"
		},
		caseStudy:{
			filter:"CaseStudyWhere"
		},
		contactRequestOptions:{
			filter:"ContactRequestOptionListWhere"
		},
		youtubeVideo:{
			filter:"YoutubeVideoWhere"
		},
		subscribeForm:{
			filter:"SubscribeFormWhere"
		},
		video:{
			filter:"VideoWhere"
		},
		otherVideo:{
			filter:"VideoWhere"
		},
		positionCategories:{
			filter:"ContentReferencePositionCategoryItemWhere",
			orderBy:"ContentReferencePositionCategoryItemOrderBy"
		},
		caseStudies:{
			filter:"ContentReferenceCaseStudyItemWhere",
			orderBy:"ContentReferenceCaseStudyItemOrderBy"
		},
		tags:{
			filter:"ContentReferenceTagItemWhere",
			orderBy:"ContentReferenceTagItemOrderBy"
		},
		positions:{
			filter:"PositionListWhere"
		},
		testimonialsFull:{
			filter:"TestimonialFullListWhere"
		},
		blogPosts:{
			filter:"ContentReferenceBlogPostItemWhere",
			orderBy:"ContentReferenceBlogPostItemOrderBy"
		},
		content:{
			filter:"ContentWhere"
		},
		positionCategoriesByItem:{
			by:"ContentReferencePositionCategoriesByItemUniqueWhere",
			filter:"ContentReferencePositionCategoryItemWhere"
		},
		caseStudiesByItem:{
			by:"ContentReferenceCaseStudiesByItemUniqueWhere",
			filter:"ContentReferenceCaseStudyItemWhere"
		},
		tagsByItem:{
			by:"ContentReferenceTagsByItemUniqueWhere",
			filter:"ContentReferenceTagItemWhere"
		},
		blogPostsByItem:{
			by:"ContentReferenceBlogPostsByItemUniqueWhere",
			filter:"ContentReferenceBlogPostItemWhere"
		},
		paginatePositionCategories:{
			filter:"ContentReferencePositionCategoryItemWhere",
			orderBy:"ContentReferencePositionCategoryItemOrderBy"
		},
		paginateCaseStudies:{
			filter:"ContentReferenceCaseStudyItemWhere",
			orderBy:"ContentReferenceCaseStudyItemOrderBy"
		},
		paginateTags:{
			filter:"ContentReferenceTagItemWhere",
			orderBy:"ContentReferenceTagItemOrderBy"
		},
		paginateBlogPosts:{
			filter:"ContentReferenceBlogPostItemWhere",
			orderBy:"ContentReferenceBlogPostItemOrderBy"
		}
	},
	Link:{
		internalLink:{
			filter:"LinkableWhere"
		}
	},
	Linkable:{
		genericPage:{
			filter:"GenericPageLocaleWhere"
		},
		caseStudy:{
			filter:"CaseStudyLocaleWhere"
		},
		position:{
			filter:"PositionLocaleWhere"
		},
		redirect:{
			filter:"RedirectWhere"
		},
		blogPost:{
			filter:"BlogPostLocaleWhere"
		}
	},
	CaseStudyLocale:{
		root:{
			filter:"CaseStudyWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		content:{
			filter:"ContentWhere"
		},
		link:{
			filter:"LinkableWhere"
		},
		seo:{
			filter:"SeoWhere"
		}
	},
	CaseStudy:{
		locales:{
			filter:"CaseStudyLocaleWhere",
			orderBy:"CaseStudyLocaleOrderBy"
		},
		tags:{
			filter:"CaseStudyTagWhere",
			orderBy:"CaseStudyTagOrderBy"
		},
		wideImage:{
			filter:"ImageWhere"
		},
		narrowImage:{
			filter:"ImageWhere"
		},
		localesByLocale:{
			by:"CaseStudyLocalesByLocaleUniqueWhere",
			filter:"CaseStudyLocaleWhere"
		},
		localesByContent:{
			by:"CaseStudyLocalesByContentUniqueWhere",
			filter:"CaseStudyLocaleWhere"
		},
		localesByLink:{
			by:"CaseStudyLocalesByLinkUniqueWhere",
			filter:"CaseStudyLocaleWhere"
		},
		localesBySeo:{
			by:"CaseStudyLocalesBySeoUniqueWhere",
			filter:"CaseStudyLocaleWhere"
		},
		paginateLocales:{
			filter:"CaseStudyLocaleWhere",
			orderBy:"CaseStudyLocaleOrderBy"
		},
		paginateTags:{
			filter:"CaseStudyTagWhere",
			orderBy:"CaseStudyTagOrderBy"
		}
	},
	CaseStudyTag:{
		caseStudy:{
			filter:"CaseStudyWhere"
		},
		tag:{
			filter:"TagWhere"
		}
	},
	Tag:{
		locales:{
			filter:"TagLocaleWhere",
			orderBy:"TagLocaleOrderBy"
		},
		caseStudyTags:{
			filter:"CaseStudyTagWhere",
			orderBy:"CaseStudyTagOrderBy"
		},
		localesByLocale:{
			by:"TagLocalesByLocaleUniqueWhere",
			filter:"TagLocaleWhere"
		},
		paginateLocales:{
			filter:"TagLocaleWhere",
			orderBy:"TagLocaleOrderBy"
		},
		paginateCaseStudyTags:{
			filter:"CaseStudyTagWhere",
			orderBy:"CaseStudyTagOrderBy"
		}
	},
	TagLocale:{
		root:{
			filter:"TagWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	TagLocaleOrderBy:{
		id:"OrderDirection",
		slug:"OrderDirection",
		title:"OrderDirection",
		root:"TagOrderBy",
		locale:"LocaleOrderBy"
	},
	TagOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection"
	},
	CaseStudyTagOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		caseStudy:"CaseStudyOrderBy",
		tag:"TagOrderBy"
	},
	TagLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	CaseStudyLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	CaseStudyLocalesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	CaseStudyLocalesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	CaseStudyLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	Seo:{
		ogImage:{
			filter:"ImageWhere"
		}
	},
	PositionLocale:{
		link:{
			filter:"LinkableWhere"
		},
		root:{
			filter:"PositionWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		seo:{
			filter:"SeoWhere"
		},
		content:{
			filter:"ContentWhere"
		}
	},
	Position:{
		tags:{
			filter:"PositionTagListWhere"
		},
		locales:{
			filter:"PositionLocaleWhere",
			orderBy:"PositionLocaleOrderBy"
		},
		category:{
			filter:"PositionCategoryWhere"
		},
		localesByLocale:{
			by:"PositionLocalesByLocaleUniqueWhere",
			filter:"PositionLocaleWhere"
		},
		localesByLink:{
			by:"PositionLocalesByLinkUniqueWhere",
			filter:"PositionLocaleWhere"
		},
		localesBySeo:{
			by:"PositionLocalesBySeoUniqueWhere",
			filter:"PositionLocaleWhere"
		},
		localesByContent:{
			by:"PositionLocalesByContentUniqueWhere",
			filter:"PositionLocaleWhere"
		},
		paginateLocales:{
			filter:"PositionLocaleWhere",
			orderBy:"PositionLocaleOrderBy"
		}
	},
	PositionTagList:{
		items:{
			filter:"PositionTagListItemWhere",
			orderBy:"PositionTagListItemOrderBy"
		},
		paginateItems:{
			filter:"PositionTagListItemWhere",
			orderBy:"PositionTagListItemOrderBy"
		}
	},
	PositionTagListItem:{
		list:{
			filter:"PositionTagListWhere"
		},
		tag:{
			filter:"PositionTagWhere"
		}
	},
	PositionTag:{
		locales:{
			filter:"PositionTagLocaleWhere",
			orderBy:"PositionTagLocaleOrderBy"
		},
		localesByLocale:{
			by:"PositionTagLocalesByLocaleUniqueWhere",
			filter:"PositionTagLocaleWhere"
		},
		paginateLocales:{
			filter:"PositionTagLocaleWhere",
			orderBy:"PositionTagLocaleOrderBy"
		}
	},
	PositionTagLocale:{
		root:{
			filter:"PositionTagWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	PositionTagLocaleOrderBy:{
		id:"OrderDirection",
		slug:"OrderDirection",
		title:"OrderDirection",
		root:"PositionTagOrderBy",
		locale:"LocaleOrderBy"
	},
	PositionTagOrderBy:{
		id:"OrderDirection"
	},
	PositionTagLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	PositionTagListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"PositionTagListOrderBy",
		tag:"PositionTagOrderBy"
	},
	PositionCategory:{
		positions:{
			filter:"PositionWhere",
			orderBy:"PositionOrderBy"
		},
		locales:{
			filter:"PositionCategoryLocaleWhere",
			orderBy:"PositionCategoryLocaleOrderBy"
		},
		positionsByTags:{
			by:"PositionCategoryPositionsByTagsUniqueWhere",
			filter:"PositionWhere"
		},
		positionsByLocales:{
			by:"PositionCategoryPositionsByLocalesUniqueWhere",
			filter:"PositionWhere"
		},
		localesByLocale:{
			by:"PositionCategoryLocalesByLocaleUniqueWhere",
			filter:"PositionCategoryLocaleWhere"
		},
		paginatePositions:{
			filter:"PositionWhere",
			orderBy:"PositionOrderBy"
		},
		paginateLocales:{
			filter:"PositionCategoryLocaleWhere",
			orderBy:"PositionCategoryLocaleOrderBy"
		}
	},
	PositionCategoryLocale:{
		root:{
			filter:"PositionCategoryWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	PositionCategoryLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		root:"PositionCategoryOrderBy",
		locale:"LocaleOrderBy"
	},
	PositionCategoryPositionsByTagsUniqueWhere:{
		tags:"PositionTagListUniqueWhere"
	},
	PositionCategoryPositionsByLocalesUniqueWhere:{
		locales:"PositionLocaleUniqueWhere"
	},
	PositionCategoryLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	PositionLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	PositionLocalesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	PositionLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	PositionLocalesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	Redirect:{
		link:{
			filter:"LinkableWhere"
		},
		target:{
			filter:"LinkWhere"
		}
	},
	BlogPostLocale:{
		root:{
			filter:"BlogPostWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		content:{
			filter:"ContentWhere"
		},
		seo:{
			filter:"SeoWhere"
		},
		link:{
			filter:"LinkableWhere"
		}
	},
	BlogPost:{
		author:{
			filter:"AuthorWhere"
		},
		wideImage:{
			filter:"ImageWhere"
		},
		narrowImage:{
			filter:"ImageWhere"
		},
		locales:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		relevantBlogPosts:{
			filter:"RelevantBlogPostListWhere",
			orderBy:"RelevantBlogPostListOrderBy"
		},
		localesByLocale:{
			by:"BlogPostLocalesByLocaleUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		localesByContent:{
			by:"BlogPostLocalesByContentUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		localesBySeo:{
			by:"BlogPostLocalesBySeoUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		localesByLink:{
			by:"BlogPostLocalesByLinkUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		relevantBlogPostsByRelevantBlogPost:{
			by:"BlogPostRelevantBlogPostsByRelevantBlogPostUniqueWhere",
			filter:"RelevantBlogPostListWhere"
		},
		paginateLocales:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		paginateRelevantBlogPosts:{
			filter:"RelevantBlogPostListWhere",
			orderBy:"RelevantBlogPostListOrderBy"
		}
	},
	Author:{
		locales:{
			filter:"AuthorLocaleWhere",
			orderBy:"AuthorLocaleOrderBy"
		},
		blogPosts:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		},
		image:{
			filter:"ImageWhere"
		},
		avatarImage:{
			filter:"ImageWhere"
		},
		localesByLocale:{
			by:"AuthorLocalesByLocaleUniqueWhere",
			filter:"AuthorLocaleWhere"
		},
		blogPostsByLocales:{
			by:"AuthorBlogPostsByLocalesUniqueWhere",
			filter:"BlogPostWhere"
		},
		blogPostsByRelevantBlogPosts:{
			by:"AuthorBlogPostsByRelevantBlogPostsUniqueWhere",
			filter:"BlogPostWhere"
		},
		paginateLocales:{
			filter:"AuthorLocaleWhere",
			orderBy:"AuthorLocaleOrderBy"
		},
		paginateBlogPosts:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		}
	},
	AuthorLocale:{
		root:{
			filter:"AuthorWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	AuthorLocaleOrderBy:{
		id:"OrderDirection",
		role:"OrderDirection",
		bio:"OrderDirection",
		root:"AuthorOrderBy",
		locale:"LocaleOrderBy"
	},
	AuthorLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	AuthorBlogPostsByLocalesUniqueWhere:{
		locales:"BlogPostLocaleUniqueWhere"
	},
	AuthorBlogPostsByRelevantBlogPostsUniqueWhere:{
		relevantBlogPosts:"RelevantBlogPostListUniqueWhere"
	},
	RelevantBlogPostList:{
		blogPost:{
			filter:"BlogPostWhere"
		},
		relevantBlogPost:{
			filter:"BlogPostWhere"
		}
	},
	RelevantBlogPostListOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		blogPost:"BlogPostOrderBy",
		relevantBlogPost:"BlogPostOrderBy"
	},
	BlogPostLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	BlogPostLocalesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	BlogPostLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	BlogPostLocalesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	BlogPostRelevantBlogPostsByRelevantBlogPostUniqueWhere:{
		relevantBlogPost:"BlogPostUniqueWhere"
	},
	CaseStudyList:{
		items:{
			filter:"CaseStudyListItemWhere",
			orderBy:"CaseStudyListItemOrderBy"
		},
		paginateItems:{
			filter:"CaseStudyListItemWhere",
			orderBy:"CaseStudyListItemOrderBy"
		}
	},
	CaseStudyListItem:{
		list:{
			filter:"CaseStudyListWhere"
		},
		caseStudy:{
			filter:"CaseStudyWhere"
		}
	},
	CaseStudyListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"CaseStudyListOrderBy",
		caseStudy:"CaseStudyOrderBy"
	},
	CaseStudyListOrderBy:{
		id:"OrderDirection"
	},
	ImageList:{
		items:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		itemsByImage:{
			by:"ImageListItemsByImageUniqueWhere",
			filter:"ImageListItemWhere"
		},
		paginateItems:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		}
	},
	ImageListItem:{
		list:{
			filter:"ImageListWhere"
		},
		image:{
			filter:"ImageWhere"
		}
	},
	ImageListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"ImageListOrderBy",
		image:"ImageOrderBy"
	},
	ImageListOrderBy:{
		id:"OrderDirection"
	},
	ImageListItemsByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	InformationList:{
		items:{
			filter:"PieceOfInformationWhere",
			orderBy:"PieceOfInformationOrderBy"
		},
		paginateItems:{
			filter:"PieceOfInformationWhere",
			orderBy:"PieceOfInformationOrderBy"
		}
	},
	PieceOfInformation:{
		list:{
			filter:"InformationListWhere"
		}
	},
	PieceOfInformationOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		title:"OrderDirection",
		description:"OrderDirection",
		list:"InformationListOrderBy"
	},
	InformationListOrderBy:{
		id:"OrderDirection"
	},
	LinkList:{
		items:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		itemsByLink:{
			by:"LinkListItemsByLinkUniqueWhere",
			filter:"LinkListItemWhere"
		},
		paginateItems:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		}
	},
	LinkListItem:{
		list:{
			filter:"LinkListWhere"
		},
		link:{
			filter:"LinkWhere"
		}
	},
	LinkListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"LinkListOrderBy",
		link:"LinkOrderBy"
	},
	LinkListOrderBy:{
		id:"OrderDirection"
	},
	LinkListItemsByLinkUniqueWhere:{
		link:"LinkUniqueWhere"
	},
	TalkLists:{
		items:{
			filter:"TalkListWhere",
			orderBy:"TalkListOrderBy"
		},
		itemsByItems:{
			by:"TalkListsItemsByItemsUniqueWhere",
			filter:"TalkListWhere"
		},
		paginateItems:{
			filter:"TalkListWhere",
			orderBy:"TalkListOrderBy"
		}
	},
	TalkList:{
		list:{
			filter:"TalkListsWhere"
		},
		items:{
			filter:"TalkListItemWhere",
			orderBy:"TalkListItemOrderBy"
		},
		paginateItems:{
			filter:"TalkListItemWhere",
			orderBy:"TalkListItemOrderBy"
		}
	},
	TalkListItem:{
		list:{
			filter:"TalkListWhere"
		},
		talk:{
			filter:"TalkWhere"
		}
	},
	Talk:{
		coverImage:{
			filter:"ImageWhere"
		},
		otherImage:{
			filter:"ImageWhere"
		}
	},
	TalkListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"TalkListOrderBy",
		talk:"TalkOrderBy"
	},
	TalkListOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		title:"OrderDirection",
		list:"TalkListsOrderBy"
	},
	TalkListsOrderBy:{
		id:"OrderDirection"
	},
	TalkOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		slug:"OrderDirection",
		date:"OrderDirection",
		note:"OrderDirection",
		content:"OrderDirection",
		coverImage:"ImageOrderBy",
		otherImage:"ImageOrderBy"
	},
	TalkListsItemsByItemsUniqueWhere:{
		items:"TalkListItemUniqueWhere"
	},
	BusinessCardList:{
		items:{
			filter:"BusinessCardListItemWhere",
			orderBy:"BusinessCardListItemOrderBy"
		},
		itemsByBusinessCard:{
			by:"BusinessCardListItemsByBusinessCardUniqueWhere",
			filter:"BusinessCardListItemWhere"
		},
		paginateItems:{
			filter:"BusinessCardListItemWhere",
			orderBy:"BusinessCardListItemOrderBy"
		}
	},
	BusinessCardListItem:{
		list:{
			filter:"BusinessCardListWhere"
		},
		businessCard:{
			filter:"BusinessCardWhere"
		}
	},
	BusinessCard:{
		avatar:{
			filter:"ImageWhere"
		}
	},
	BusinessCardListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"BusinessCardListOrderBy",
		businessCard:"BusinessCardOrderBy"
	},
	BusinessCardListOrderBy:{
		id:"OrderDirection"
	},
	BusinessCardOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		role:"OrderDirection",
		email:"OrderDirection",
		avatar:"ImageOrderBy",
		phone:"OrderDirection"
	},
	BusinessCardListItemsByBusinessCardUniqueWhere:{
		businessCard:"BusinessCardUniqueWhere"
	},
	TestimonialList:{
		items:{
			filter:"TestimonialListItemWhere",
			orderBy:"TestimonialListItemOrderBy"
		},
		itemsByTestimonial:{
			by:"TestimonialListItemsByTestimonialUniqueWhere",
			filter:"TestimonialListItemWhere"
		},
		paginateItems:{
			filter:"TestimonialListItemWhere",
			orderBy:"TestimonialListItemOrderBy"
		}
	},
	TestimonialListItem:{
		list:{
			filter:"TestimonialListWhere"
		},
		testimonial:{
			filter:"TestimonialWhere"
		}
	},
	Testimonial:{
		avatar:{
			filter:"ImageWhere"
		}
	},
	TestimonialListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"TestimonialListOrderBy",
		testimonial:"TestimonialOrderBy"
	},
	TestimonialListOrderBy:{
		id:"OrderDirection"
	},
	TestimonialOrderBy:{
		id:"OrderDirection",
		content:"OrderDirection",
		note:"OrderDirection",
		avatar:"ImageOrderBy"
	},
	TestimonialListItemsByTestimonialUniqueWhere:{
		testimonial:"TestimonialUniqueWhere"
	},
	ContactRequestOptionList:{
		items:{
			filter:"ContactRequestOptionWhere",
			orderBy:"ContactRequestOptionOrderBy"
		},
		paginateItems:{
			filter:"ContactRequestOptionWhere",
			orderBy:"ContactRequestOptionOrderBy"
		}
	},
	ContactRequestOption:{
		list:{
			filter:"ContactRequestOptionListWhere"
		},
		subscribeForm:{
			filter:"SubscribeFormWhere"
		}
	},
	ContactRequestOptionOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		label:"OrderDirection",
		description:"OrderDirection",
		list:"ContactRequestOptionListOrderBy",
		code:"OrderDirection",
		type:"OrderDirection",
		subscribeForm:"SubscribeFormOrderBy"
	},
	ContactRequestOptionListOrderBy:{
		id:"OrderDirection"
	},
	SubscribeFormOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		datacruitJobId:"OrderDirection",
		datacruitReferenceNumber:"OrderDirection",
		lastUpdateAt:"OrderDirection"
	},
	ContentReferencePositionCategoryItem:{
		item:{
			filter:"PositionCategoryWhere"
		},
		reference:{
			filter:"ContentReferenceWhere"
		}
	},
	ContentReferencePositionCategoryItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		item:"PositionCategoryOrderBy",
		reference:"ContentReferenceOrderBy"
	},
	ContentReferenceOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		primaryText:"OrderDirection",
		block:"ContentBlockOrderBy",
		image:"ImageOrderBy",
		secondaryText:"OrderDirection",
		link:"LinkOrderBy",
		featuredCaseStudies:"CaseStudyListOrderBy",
		images:"ImageListOrderBy",
		otherImages:"ImageListOrderBy",
		informationList:"InformationListOrderBy",
		tertiaryText:"OrderDirection",
		embed:"EmbedOrderBy",
		links:"LinkListOrderBy",
		talkLists:"TalkListsOrderBy",
		businessCardList:"BusinessCardListOrderBy",
		testimonials:"TestimonialListOrderBy",
		background:"OrderDirection",
		otherImage:"ImageOrderBy",
		caseStudy:"CaseStudyOrderBy",
		alternative:"OrderDirection",
		contactRequestOptions:"ContactRequestOptionListOrderBy",
		youtubeVideo:"YoutubeVideoOrderBy",
		subscribeForm:"SubscribeFormOrderBy",
		video:"VideoOrderBy",
		otherVideo:"VideoOrderBy",
		positions:"PositionListOrderBy",
		testimonialsFull:"TestimonialFullListOrderBy",
		verticalMargin:"OrderDirection",
		variant:"OrderDirection",
		content:"ContentOrderBy"
	},
	ContentBlockOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		json:"OrderDirection",
		content:"ContentOrderBy"
	},
	EmbedOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		embedId:"OrderDirection"
	},
	YoutubeVideoOrderBy:{
		id:"OrderDirection",
		videoId:"OrderDirection"
	},
	VideoOrderBy:{
		id:"OrderDirection",
		src:"OrderDirection",
		height:"OrderDirection",
		width:"OrderDirection",
		duration:"OrderDirection",
		size:"OrderDirection",
		type:"OrderDirection"
	},
	PositionListOrderBy:{
		id:"OrderDirection"
	},
	TestimonialFullListOrderBy:{
		id:"OrderDirection"
	},
	ContentReferenceCaseStudyItem:{
		item:{
			filter:"CaseStudyWhere"
		},
		reference:{
			filter:"ContentReferenceWhere"
		}
	},
	ContentReferenceCaseStudyItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		item:"CaseStudyOrderBy",
		reference:"ContentReferenceOrderBy"
	},
	ContentReferenceTagItem:{
		item:{
			filter:"TagWhere"
		},
		reference:{
			filter:"ContentReferenceWhere"
		}
	},
	ContentReferenceTagItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		item:"TagOrderBy",
		reference:"ContentReferenceOrderBy"
	},
	PositionList:{
		items:{
			filter:"PositionListItemWhere",
			orderBy:"PositionListItemOrderBy"
		},
		paginateItems:{
			filter:"PositionListItemWhere",
			orderBy:"PositionListItemOrderBy"
		}
	},
	PositionListItem:{
		list:{
			filter:"PositionListWhere"
		},
		position:{
			filter:"PositionLocaleWhere"
		}
	},
	PositionListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"PositionListOrderBy",
		position:"PositionLocaleOrderBy"
	},
	TestimonialFullList:{
		items:{
			filter:"TestimonialFullListItemWhere",
			orderBy:"TestimonialFullListItemOrderBy"
		},
		itemsByTestimonial:{
			by:"TestimonialFullListItemsByTestimonialUniqueWhere",
			filter:"TestimonialFullListItemWhere"
		},
		paginateItems:{
			filter:"TestimonialFullListItemWhere",
			orderBy:"TestimonialFullListItemOrderBy"
		}
	},
	TestimonialFullListItem:{
		list:{
			filter:"TestimonialFullListWhere"
		},
		testimonial:{
			filter:"TestimonialFullWhere"
		}
	},
	TestimonialFull:{
		avatar:{
			filter:"ImageWhere"
		}
	},
	TestimonialFullListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"TestimonialFullListOrderBy",
		testimonial:"TestimonialFullOrderBy"
	},
	TestimonialFullOrderBy:{
		id:"OrderDirection",
		content:"OrderDirection",
		name:"OrderDirection",
		note:"OrderDirection",
		avatar:"ImageOrderBy",
		imageVariant:"OrderDirection"
	},
	TestimonialFullListItemsByTestimonialUniqueWhere:{
		testimonial:"TestimonialFullUniqueWhere"
	},
	ContentReferenceBlogPostItem:{
		item:{
			filter:"BlogPostWhere"
		},
		reference:{
			filter:"ContentReferenceWhere"
		}
	},
	ContentReferenceBlogPostItemOrderBy:{
		id:"OrderDirection",
		publishedAt:"OrderDirection",
		item:"BlogPostOrderBy",
		reference:"ContentReferenceOrderBy"
	},
	ContentReferencePositionCategoriesByItemUniqueWhere:{
		item:"PositionCategoryUniqueWhere"
	},
	ContentReferenceCaseStudiesByItemUniqueWhere:{
		item:"CaseStudyUniqueWhere"
	},
	ContentReferenceTagsByItemUniqueWhere:{
		item:"TagUniqueWhere"
	},
	ContentReferenceBlogPostsByItemUniqueWhere:{
		item:"BlogPostUniqueWhere"
	},
	ContentBlockReferencesByLinkUniqueWhere:{
		link:"LinkUniqueWhere"
	},
	ContentBlockReferencesByFeaturedCaseStudiesUniqueWhere:{
		featuredCaseStudies:"CaseStudyListUniqueWhere"
	},
	ContentBlockReferencesByImagesUniqueWhere:{
		images:"ImageListUniqueWhere"
	},
	ContentBlockReferencesByOtherImagesUniqueWhere:{
		otherImages:"ImageListUniqueWhere"
	},
	ContentBlockReferencesByInformationListUniqueWhere:{
		informationList:"InformationListUniqueWhere"
	},
	ContentBlockReferencesByEmbedUniqueWhere:{
		embed:"EmbedUniqueWhere"
	},
	ContentBlockReferencesByLinksUniqueWhere:{
		links:"LinkListUniqueWhere"
	},
	ContentBlockReferencesByTalkListsUniqueWhere:{
		talkLists:"TalkListsUniqueWhere"
	},
	ContentBlockReferencesByBusinessCardListUniqueWhere:{
		businessCardList:"BusinessCardListUniqueWhere"
	},
	ContentBlockReferencesByTestimonialsUniqueWhere:{
		testimonials:"TestimonialListUniqueWhere"
	},
	ContentBlockReferencesByContactRequestOptionsUniqueWhere:{
		contactRequestOptions:"ContactRequestOptionListUniqueWhere"
	},
	ContentBlockReferencesByYoutubeVideoUniqueWhere:{
		youtubeVideo:"YoutubeVideoUniqueWhere"
	},
	ContentBlockReferencesByPositionCategoriesUniqueWhere:{
		positionCategories:"ContentReferencePositionCategoryItemUniqueWhere"
	},
	ContentBlockReferencesByCaseStudiesUniqueWhere:{
		caseStudies:"ContentReferenceCaseStudyItemUniqueWhere"
	},
	ContentBlockReferencesByTagsUniqueWhere:{
		tags:"ContentReferenceTagItemUniqueWhere"
	},
	ContentBlockReferencesByPositionsUniqueWhere:{
		positions:"PositionListUniqueWhere"
	},
	ContentBlockReferencesByTestimonialsFullUniqueWhere:{
		testimonialsFull:"TestimonialFullListUniqueWhere"
	},
	ContentBlockReferencesByBlogPostsUniqueWhere:{
		blogPosts:"ContentReferenceBlogPostItemUniqueWhere"
	},
	ContentBlockReferencesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	ContentBlocksByReferencesUniqueWhere:{
		references:"ContentReferenceUniqueWhere"
	},
	HeaderLocale:{
		root:{
			filter:"HeaderWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		linkList:{
			filter:"LinkListWhere"
		},
		socialLinkList:{
			filter:"LinkListWhere"
		},
		topLevelLinkItems:{
			filter:"TopLevelLinkItemWhere",
			orderBy:"TopLevelLinkItemOrderBy"
		},
		topLevelLinkItemsBySecondLevelLinkItems:{
			by:"HeaderLocaleTopLevelLinkItemsBySecondLevelLinkItemsUniqueWhere",
			filter:"TopLevelLinkItemWhere"
		},
		topLevelLinkItemsByLink:{
			by:"HeaderLocaleTopLevelLinkItemsByLinkUniqueWhere",
			filter:"TopLevelLinkItemWhere"
		},
		paginateTopLevelLinkItems:{
			filter:"TopLevelLinkItemWhere",
			orderBy:"TopLevelLinkItemOrderBy"
		}
	},
	Header:{
		locales:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		localesByLocale:{
			by:"HeaderLocalesByLocaleUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		localesByLinkList:{
			by:"HeaderLocalesByLinkListUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		localesBySocialLinkList:{
			by:"HeaderLocalesBySocialLinkListUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		localesByTopLevelLinkItems:{
			by:"HeaderLocalesByTopLevelLinkItemsUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		paginateLocales:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		}
	},
	HeaderLocaleOrderBy:{
		id:"OrderDirection",
		email:"OrderDirection",
		root:"HeaderOrderBy",
		locale:"LocaleOrderBy",
		linkList:"LinkListOrderBy",
		socialLinkList:"LinkListOrderBy"
	},
	HeaderOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection"
	},
	HeaderLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	HeaderLocalesByLinkListUniqueWhere:{
		linkList:"LinkListUniqueWhere"
	},
	HeaderLocalesBySocialLinkListUniqueWhere:{
		socialLinkList:"LinkListUniqueWhere"
	},
	HeaderLocalesByTopLevelLinkItemsUniqueWhere:{
		topLevelLinkItems:"TopLevelLinkItemUniqueWhere"
	},
	TopLevelLinkItem:{
		secondLevelLinkItems:{
			filter:"SecondLevelLinkItemWhere",
			orderBy:"SecondLevelLinkItemOrderBy"
		},
		header:{
			filter:"HeaderLocaleWhere"
		},
		link:{
			filter:"LinkWhere"
		},
		secondLevelLinkItemsByLink:{
			by:"TopLevelLinkItemSecondLevelLinkItemsByLinkUniqueWhere",
			filter:"SecondLevelLinkItemWhere"
		},
		secondLevelLinkItemsByThirdLevelLinkItems:{
			by:"TopLevelLinkItemSecondLevelLinkItemsByThirdLevelLinkItemsUniqueWhere",
			filter:"SecondLevelLinkItemWhere"
		},
		paginateSecondLevelLinkItems:{
			filter:"SecondLevelLinkItemWhere",
			orderBy:"SecondLevelLinkItemOrderBy"
		}
	},
	SecondLevelLinkItem:{
		topLevelLinkItem:{
			filter:"TopLevelLinkItemWhere"
		},
		link:{
			filter:"LinkWhere"
		},
		thirdLevelLinkItems:{
			filter:"ThirdLevelLinkItemWhere",
			orderBy:"ThirdLevelLinkItemOrderBy"
		},
		thirdLevelLinkItemsByLink:{
			by:"SecondLevelLinkItemThirdLevelLinkItemsByLinkUniqueWhere",
			filter:"ThirdLevelLinkItemWhere"
		},
		paginateThirdLevelLinkItems:{
			filter:"ThirdLevelLinkItemWhere",
			orderBy:"ThirdLevelLinkItemOrderBy"
		}
	},
	ThirdLevelLinkItem:{
		secondLevelLinkItem:{
			filter:"SecondLevelLinkItemWhere"
		},
		link:{
			filter:"LinkWhere"
		}
	},
	ThirdLevelLinkItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		secondLevelLinkItem:"SecondLevelLinkItemOrderBy",
		link:"LinkOrderBy"
	},
	SecondLevelLinkItemOrderBy:{
		id:"OrderDirection",
		isLink:"OrderDirection",
		order:"OrderDirection",
		title:"OrderDirection",
		topLevelLinkItem:"TopLevelLinkItemOrderBy",
		link:"LinkOrderBy"
	},
	TopLevelLinkItemOrderBy:{
		id:"OrderDirection",
		isLink:"OrderDirection",
		order:"OrderDirection",
		title:"OrderDirection",
		header:"HeaderLocaleOrderBy",
		link:"LinkOrderBy"
	},
	SecondLevelLinkItemThirdLevelLinkItemsByLinkUniqueWhere:{
		link:"LinkUniqueWhere"
	},
	TopLevelLinkItemSecondLevelLinkItemsByLinkUniqueWhere:{
		link:"LinkUniqueWhere"
	},
	TopLevelLinkItemSecondLevelLinkItemsByThirdLevelLinkItemsUniqueWhere:{
		thirdLevelLinkItems:"ThirdLevelLinkItemUniqueWhere"
	},
	HeaderLocaleTopLevelLinkItemsBySecondLevelLinkItemsUniqueWhere:{
		secondLevelLinkItems:"SecondLevelLinkItemUniqueWhere"
	},
	HeaderLocaleTopLevelLinkItemsByLinkUniqueWhere:{
		link:"LinkUniqueWhere"
	},
	FooterLocale:{
		root:{
			filter:"FooterWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		navigation:{
			filter:"LinkListWhere"
		}
	},
	Footer:{
		locales:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		localesByLocale:{
			by:"FooterLocalesByLocaleUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		localesByNavigation:{
			by:"FooterLocalesByNavigationUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		paginateLocales:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		}
	},
	FooterLocaleOrderBy:{
		id:"OrderDirection",
		email:"OrderDirection",
		root:"FooterOrderBy",
		locale:"LocaleOrderBy",
		address:"OrderDirection",
		phone:"OrderDirection",
		legal:"OrderDirection",
		navigation:"LinkListOrderBy"
	},
	FooterOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection"
	},
	FooterLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	FooterLocalesByNavigationUniqueWhere:{
		navigation:"LinkListUniqueWhere"
	},
	BlogPostCommonLocale:{
		root:{
			filter:"BlogPostCommonWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		ctaLink:{
			filter:"LinkWhere"
		}
	},
	BlogPostCommon:{
		locales:{
			filter:"BlogPostCommonLocaleWhere",
			orderBy:"BlogPostCommonLocaleOrderBy"
		},
		localesByLocale:{
			by:"BlogPostCommonLocalesByLocaleUniqueWhere",
			filter:"BlogPostCommonLocaleWhere"
		},
		paginateLocales:{
			filter:"BlogPostCommonLocaleWhere",
			orderBy:"BlogPostCommonLocaleOrderBy"
		}
	},
	BlogPostCommonLocaleOrderBy:{
		id:"OrderDirection",
		root:"BlogPostCommonOrderBy",
		locale:"LocaleOrderBy",
		ctaLink:"LinkOrderBy",
		ctaTitle:"OrderDirection"
	},
	BlogPostCommonOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection"
	},
	BlogPostCommonLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	LocaleGeneralByRootUniqueWhere:{
		root:"GeneralUniqueWhere"
	},
	LocaleGeneralBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocaleGeneralByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	LocaleGenericPagesByRootUniqueWhere:{
		root:"GenericPageUniqueWhere"
	},
	LocaleGenericPagesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	LocaleGenericPagesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	LocaleGenericPagesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocaleHeadersByRootUniqueWhere:{
		root:"HeaderUniqueWhere"
	},
	LocaleHeadersByLinkListUniqueWhere:{
		linkList:"LinkListUniqueWhere"
	},
	LocaleHeadersBySocialLinkListUniqueWhere:{
		socialLinkList:"LinkListUniqueWhere"
	},
	LocaleHeadersByTopLevelLinkItemsUniqueWhere:{
		topLevelLinkItems:"TopLevelLinkItemUniqueWhere"
	},
	LocaleFootersByRootUniqueWhere:{
		root:"FooterUniqueWhere"
	},
	LocaleFootersByNavigationUniqueWhere:{
		navigation:"LinkListUniqueWhere"
	},
	LocaleCaseStudiesByRootUniqueWhere:{
		root:"CaseStudyUniqueWhere"
	},
	LocaleCaseStudiesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	LocaleCaseStudiesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	LocaleCaseStudiesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocaleTagsBySlugUniqueWhere:{

	},
	LocaleTagsByRootUniqueWhere:{
		root:"TagUniqueWhere"
	},
	LocalePositionTagsBySlugUniqueWhere:{

	},
	LocalePositionTagsByRootUniqueWhere:{
		root:"PositionTagUniqueWhere"
	},
	LocalePositionsByRootUniqueWhere:{
		root:"PositionUniqueWhere"
	},
	LocalePositionsByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	LocalePositionsBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocalePositionsByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	LocalePositionCategoriesByRootUniqueWhere:{
		root:"PositionCategoryUniqueWhere"
	},
	LocaleAuthorsByRootUniqueWhere:{
		root:"AuthorUniqueWhere"
	},
	LocaleBlogPostsByRootUniqueWhere:{
		root:"BlogPostUniqueWhere"
	},
	LocaleBlogPostsByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	LocaleBlogPostsBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocaleBlogPostsByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	LocaleBlogPostCommonByRootUniqueWhere:{
		root:"BlogPostCommonUniqueWhere"
	},
	GeneralLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	GeneralLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	GeneralLocalesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	TalkUniqueWhere:{
		id:"UUID"
	},
	YoutubeVideoList:{
		items:{
			filter:"YoutubeVideoListItemWhere",
			orderBy:"YoutubeVideoListItemOrderBy"
		},
		itemsByYoutube:{
			by:"YoutubeVideoListItemsByYoutubeUniqueWhere",
			filter:"YoutubeVideoListItemWhere"
		},
		paginateItems:{
			filter:"YoutubeVideoListItemWhere",
			orderBy:"YoutubeVideoListItemOrderBy"
		}
	},
	YoutubeVideoListItem:{
		list:{
			filter:"YoutubeVideoListWhere"
		},
		youtube:{
			filter:"YoutubeVideoWhere"
		}
	},
	YoutubeVideoListWhere:{
		id:"UUIDCondition",
		items:"YoutubeVideoListItemWhere",
		and:"YoutubeVideoListWhere",
		or:"YoutubeVideoListWhere",
		not:"YoutubeVideoListWhere"
	},
	YoutubeVideoListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"YoutubeVideoListWhere",
		youtube:"YoutubeVideoWhere",
		and:"YoutubeVideoListItemWhere",
		or:"YoutubeVideoListItemWhere",
		not:"YoutubeVideoListItemWhere"
	},
	YoutubeVideoListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"YoutubeVideoListOrderBy",
		youtube:"YoutubeVideoOrderBy"
	},
	YoutubeVideoListOrderBy:{
		id:"OrderDirection"
	},
	YoutubeVideoListItemsByYoutubeUniqueWhere:{
		youtube:"YoutubeVideoUniqueWhere"
	},
	YoutubeVideoListUniqueWhere:{
		id:"UUID",
		items:"YoutubeVideoListItemUniqueWhere"
	},
	YoutubeVideoListItemUniqueWhere:{
		id:"UUID",
		youtube:"YoutubeVideoUniqueWhere"
	},
	TranslationCatalogue:{
		domain:{
			filter:"TranslationDomainWhere"
		},
		identifier:{
			filter:"TranslationCataloguesIdentifierWhere"
		},
		fallback:{
			filter:"TranslationCatalogueWhere"
		},
		values:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		valuesByKey:{
			by:"TranslationCatalogueValuesByKeyUniqueWhere",
			filter:"TranslationValueWhere"
		},
		paginateValues:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		}
	},
	TranslationDomain:{
		catalogues:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		keys:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		cataloguesByIdentifier:{
			by:"TranslationDomainCataloguesByIdentifierUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		cataloguesByValues:{
			by:"TranslationDomainCataloguesByValuesUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		keysByIdentifier:{
			by:"TranslationDomainKeysByIdentifierUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		keysByValues:{
			by:"TranslationDomainKeysByValuesUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		paginateCatalogues:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		paginateKeys:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		}
	},
	TranslationCatalogueWhere:{
		id:"UUIDCondition",
		domain:"TranslationDomainWhere",
		identifier:"TranslationCataloguesIdentifierWhere",
		fallback:"TranslationCatalogueWhere",
		values:"TranslationValueWhere",
		and:"TranslationCatalogueWhere",
		or:"TranslationCatalogueWhere",
		not:"TranslationCatalogueWhere"
	},
	TranslationDomainWhere:{
		id:"UUIDCondition",
		identifier:"StringCondition",
		name:"StringCondition",
		catalogues:"TranslationCatalogueWhere",
		keys:"TranslationKeyWhere",
		and:"TranslationDomainWhere",
		or:"TranslationDomainWhere",
		not:"TranslationDomainWhere"
	},
	TranslationKeyWhere:{
		id:"UUIDCondition",
		identifier:"StringCondition",
		contentType:"TranslationContentTypeCondition",
		note:"StringCondition",
		domain:"TranslationDomainWhere",
		values:"TranslationValueWhere",
		and:"TranslationKeyWhere",
		or:"TranslationKeyWhere",
		not:"TranslationKeyWhere"
	},
	TranslationContentTypeCondition:{
		and:"TranslationContentTypeCondition",
		or:"TranslationContentTypeCondition",
		not:"TranslationContentTypeCondition",
		eq:"TranslationContentType",
		notEq:"TranslationContentType",
		in:"TranslationContentType",
		notIn:"TranslationContentType",
		lt:"TranslationContentType",
		lte:"TranslationContentType",
		gt:"TranslationContentType",
		gte:"TranslationContentType"
	},
	TranslationContentType: "enum" as const,
	TranslationValueWhere:{
		id:"UUIDCondition",
		value:"StringCondition",
		catalogue:"TranslationCatalogueWhere",
		key:"TranslationKeyWhere",
		and:"TranslationValueWhere",
		or:"TranslationValueWhere",
		not:"TranslationValueWhere"
	},
	TranslationCataloguesIdentifierWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		catalogue:"TranslationCatalogueWhere",
		and:"TranslationCataloguesIdentifierWhere",
		or:"TranslationCataloguesIdentifierWhere",
		not:"TranslationCataloguesIdentifierWhere"
	},
	TranslationCatalogueOrderBy:{
		id:"OrderDirection",
		domain:"TranslationDomainOrderBy",
		identifier:"TranslationCataloguesIdentifierOrderBy",
		fallback:"TranslationCatalogueOrderBy"
	},
	TranslationDomainOrderBy:{
		id:"OrderDirection",
		identifier:"OrderDirection",
		name:"OrderDirection"
	},
	TranslationCataloguesIdentifierOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection"
	},
	TranslationKey:{
		domain:{
			filter:"TranslationDomainWhere"
		},
		values:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		valuesByCatalogue:{
			by:"TranslationKeyValuesByCatalogueUniqueWhere",
			filter:"TranslationValueWhere"
		},
		paginateValues:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		}
	},
	TranslationValue:{
		catalogue:{
			filter:"TranslationCatalogueWhere"
		},
		key:{
			filter:"TranslationKeyWhere"
		}
	},
	TranslationValueOrderBy:{
		id:"OrderDirection",
		value:"OrderDirection",
		catalogue:"TranslationCatalogueOrderBy",
		key:"TranslationKeyOrderBy"
	},
	TranslationKeyOrderBy:{
		id:"OrderDirection",
		identifier:"OrderDirection",
		contentType:"OrderDirection",
		note:"OrderDirection",
		domain:"TranslationDomainOrderBy"
	},
	TranslationKeyValuesByCatalogueUniqueWhere:{
		catalogue:"TranslationCatalogueUniqueWhere"
	},
	TranslationCatalogueUniqueWhere:{
		id:"UUID",
		domain:"TranslationDomainUniqueWhere",
		identifier:"TranslationCataloguesIdentifierUniqueWhere",
		values:"TranslationValueUniqueWhere"
	},
	TranslationDomainUniqueWhere:{
		id:"UUID",
		catalogues:"TranslationCatalogueUniqueWhere",
		keys:"TranslationKeyUniqueWhere"
	},
	TranslationKeyUniqueWhere:{
		id:"UUID",
		domain:"TranslationDomainUniqueWhere",
		values:"TranslationValueUniqueWhere"
	},
	TranslationValueUniqueWhere:{
		id:"UUID",
		catalogue:"TranslationCatalogueUniqueWhere",
		key:"TranslationKeyUniqueWhere"
	},
	TranslationCataloguesIdentifierUniqueWhere:{
		id:"UUID",
		catalogue:"TranslationCatalogueUniqueWhere"
	},
	TranslationDomainCataloguesByIdentifierUniqueWhere:{
		identifier:"TranslationCataloguesIdentifierUniqueWhere"
	},
	TranslationDomainCataloguesByValuesUniqueWhere:{
		values:"TranslationValueUniqueWhere"
	},
	TranslationDomainKeysByIdentifierUniqueWhere:{

	},
	TranslationDomainKeysByValuesUniqueWhere:{
		values:"TranslationValueUniqueWhere"
	},
	TranslationCataloguesIdentifier:{
		catalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		catalogueByDomain:{
			by:"TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		catalogueByValues:{
			by:"TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		paginateCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		}
	},
	TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere:{
		domain:"TranslationDomainUniqueWhere"
	},
	TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere:{
		values:"TranslationValueUniqueWhere"
	},
	TranslationCatalogueValuesByKeyUniqueWhere:{
		key:"TranslationKeyUniqueWhere"
	},
	ContactRequestSubmissionCreateInput:{
		createdAt:"DateTime",
		locale:"ContactRequestSubmissionCreateLocaleEntityRelationInput"
	},
	ContactRequestSubmissionCreateLocaleEntityRelationInput:{
		connect:"LocaleUniqueWhere"
	},
	SubscribeFormUniqueWhere:{
		id:"UUID"
	},
	SubscribeFormCreateInput:{
		submissions:"SubscribeFormCreateSubmissionsEntityRelationInput",
		lastUpdateAt:"DateTime"
	},
	SubscribeFormCreateSubmissionsEntityRelationInput:{
		create:"SubscribeSubmissionWithoutFormCreateInput"
	},
	SubscribeSubmissionWithoutFormCreateInput:{
		createdAt:"DateTime",
		locale:"SubscribeSubmissionCreateLocaleEntityRelationInput"
	},
	SubscribeSubmissionCreateLocaleEntityRelationInput:{
		connect:"LocaleUniqueWhere"
	},
	SubscribeFormUpdateInput:{
		lastUpdateAt:"DateTime"
	},
	SubscribeSubmissionCreateInput:{
		createdAt:"DateTime",
		form:"SubscribeSubmissionCreateFormEntityRelationInput",
		locale:"SubscribeSubmissionCreateLocaleEntityRelationInput"
	},
	SubscribeSubmissionCreateFormEntityRelationInput:{
		connect:"SubscribeFormUniqueWhere",
		create:"SubscribeFormWithoutSubmissionsCreateInput",
		connectOrCreate:"SubscribeSubmissionConnectOrCreateFormRelationInput"
	},
	SubscribeFormWithoutSubmissionsCreateInput:{
		lastUpdateAt:"DateTime"
	},
	SubscribeSubmissionConnectOrCreateFormRelationInput:{
		connect:"SubscribeFormUniqueWhere",
		create:"SubscribeFormWithoutSubmissionsCreateInput"
	},
	VideoUniqueWhere:{
		id:"UUID"
	},
	QueryTransaction:{
		getGeneral:{
			by:"GeneralUniqueWhere",
			filter:"GeneralWhere"
		},
		listGeneral:{
			filter:"GeneralWhere",
			orderBy:"GeneralOrderBy"
		},
		paginateGeneral:{
			filter:"GeneralWhere",
			orderBy:"GeneralOrderBy"
		},
		getImage:{
			by:"ImageUniqueWhere",
			filter:"ImageWhere"
		},
		listImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		paginateImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		getGenericPage:{
			by:"GenericPageUniqueWhere",
			filter:"GenericPageWhere"
		},
		listGenericPage:{
			filter:"GenericPageWhere",
			orderBy:"GenericPageOrderBy"
		},
		paginateGenericPage:{
			filter:"GenericPageWhere",
			orderBy:"GenericPageOrderBy"
		},
		getLinkable:{
			by:"LinkableUniqueWhere",
			filter:"LinkableWhere"
		},
		listLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		paginateLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		getSeo:{
			by:"SeoUniqueWhere",
			filter:"SeoWhere"
		},
		listSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		paginateSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		getContent:{
			by:"ContentUniqueWhere",
			filter:"ContentWhere"
		},
		listContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		paginateContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		getContentBlock:{
			by:"ContentBlockUniqueWhere",
			filter:"ContentBlockWhere"
		},
		listContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		paginateContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		getContentReference:{
			by:"ContentReferenceUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		listContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		paginateContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		getGeneralLocale:{
			by:"GeneralLocaleUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		listGeneralLocale:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		paginateGeneralLocale:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		getLocale:{
			by:"LocaleUniqueWhere",
			filter:"LocaleWhere"
		},
		listLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		paginateLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		getGenericPageLocale:{
			by:"GenericPageLocaleUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		listGenericPageLocale:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		paginateGenericPageLocale:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		getLink:{
			by:"LinkUniqueWhere",
			filter:"LinkWhere"
		},
		listLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		paginateLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		getLinkList:{
			by:"LinkListUniqueWhere",
			filter:"LinkListWhere"
		},
		listLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		paginateLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		getLinkListItem:{
			by:"LinkListItemUniqueWhere",
			filter:"LinkListItemWhere"
		},
		listLinkListItem:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		paginateLinkListItem:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		getHeader:{
			by:"HeaderUniqueWhere",
			filter:"HeaderWhere"
		},
		listHeader:{
			filter:"HeaderWhere",
			orderBy:"HeaderOrderBy"
		},
		paginateHeader:{
			filter:"HeaderWhere",
			orderBy:"HeaderOrderBy"
		},
		getHeaderLocale:{
			by:"HeaderLocaleUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		listHeaderLocale:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		paginateHeaderLocale:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		getFooter:{
			by:"FooterUniqueWhere",
			filter:"FooterWhere"
		},
		listFooter:{
			filter:"FooterWhere",
			orderBy:"FooterOrderBy"
		},
		paginateFooter:{
			filter:"FooterWhere",
			orderBy:"FooterOrderBy"
		},
		getFooterLocale:{
			by:"FooterLocaleUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		listFooterLocale:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		paginateFooterLocale:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		getCaseStudy:{
			by:"CaseStudyUniqueWhere",
			filter:"CaseStudyWhere"
		},
		listCaseStudy:{
			filter:"CaseStudyWhere",
			orderBy:"CaseStudyOrderBy"
		},
		paginateCaseStudy:{
			filter:"CaseStudyWhere",
			orderBy:"CaseStudyOrderBy"
		},
		getCaseStudyLocale:{
			by:"CaseStudyLocaleUniqueWhere",
			filter:"CaseStudyLocaleWhere"
		},
		listCaseStudyLocale:{
			filter:"CaseStudyLocaleWhere",
			orderBy:"CaseStudyLocaleOrderBy"
		},
		paginateCaseStudyLocale:{
			filter:"CaseStudyLocaleWhere",
			orderBy:"CaseStudyLocaleOrderBy"
		},
		getTag:{
			by:"TagUniqueWhere",
			filter:"TagWhere"
		},
		listTag:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateTag:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		getTagLocale:{
			by:"TagLocaleUniqueWhere",
			filter:"TagLocaleWhere"
		},
		listTagLocale:{
			filter:"TagLocaleWhere",
			orderBy:"TagLocaleOrderBy"
		},
		paginateTagLocale:{
			filter:"TagLocaleWhere",
			orderBy:"TagLocaleOrderBy"
		},
		getCaseStudyTag:{
			by:"CaseStudyTagUniqueWhere",
			filter:"CaseStudyTagWhere"
		},
		listCaseStudyTag:{
			filter:"CaseStudyTagWhere",
			orderBy:"CaseStudyTagOrderBy"
		},
		paginateCaseStudyTag:{
			filter:"CaseStudyTagWhere",
			orderBy:"CaseStudyTagOrderBy"
		},
		getCaseStudyList:{
			by:"CaseStudyListUniqueWhere",
			filter:"CaseStudyListWhere"
		},
		listCaseStudyList:{
			filter:"CaseStudyListWhere",
			orderBy:"CaseStudyListOrderBy"
		},
		paginateCaseStudyList:{
			filter:"CaseStudyListWhere",
			orderBy:"CaseStudyListOrderBy"
		},
		getCaseStudyListItem:{
			by:"CaseStudyListItemUniqueWhere",
			filter:"CaseStudyListItemWhere"
		},
		listCaseStudyListItem:{
			filter:"CaseStudyListItemWhere",
			orderBy:"CaseStudyListItemOrderBy"
		},
		paginateCaseStudyListItem:{
			filter:"CaseStudyListItemWhere",
			orderBy:"CaseStudyListItemOrderBy"
		},
		getImageList:{
			by:"ImageListUniqueWhere",
			filter:"ImageListWhere"
		},
		listImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		paginateImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		getImageListItem:{
			by:"ImageListItemUniqueWhere",
			filter:"ImageListItemWhere"
		},
		listImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		paginateImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		getInformationList:{
			by:"InformationListUniqueWhere",
			filter:"InformationListWhere"
		},
		listInformationList:{
			filter:"InformationListWhere",
			orderBy:"InformationListOrderBy"
		},
		paginateInformationList:{
			filter:"InformationListWhere",
			orderBy:"InformationListOrderBy"
		},
		getPieceOfInformation:{
			by:"PieceOfInformationUniqueWhere",
			filter:"PieceOfInformationWhere"
		},
		listPieceOfInformation:{
			filter:"PieceOfInformationWhere",
			orderBy:"PieceOfInformationOrderBy"
		},
		paginatePieceOfInformation:{
			filter:"PieceOfInformationWhere",
			orderBy:"PieceOfInformationOrderBy"
		},
		getPositionTag:{
			by:"PositionTagUniqueWhere",
			filter:"PositionTagWhere"
		},
		listPositionTag:{
			filter:"PositionTagWhere",
			orderBy:"PositionTagOrderBy"
		},
		paginatePositionTag:{
			filter:"PositionTagWhere",
			orderBy:"PositionTagOrderBy"
		},
		getPositionTagLocale:{
			by:"PositionTagLocaleUniqueWhere",
			filter:"PositionTagLocaleWhere"
		},
		listPositionTagLocale:{
			filter:"PositionTagLocaleWhere",
			orderBy:"PositionTagLocaleOrderBy"
		},
		paginatePositionTagLocale:{
			filter:"PositionTagLocaleWhere",
			orderBy:"PositionTagLocaleOrderBy"
		},
		getPosition:{
			by:"PositionUniqueWhere",
			filter:"PositionWhere"
		},
		listPosition:{
			filter:"PositionWhere",
			orderBy:"PositionOrderBy"
		},
		paginatePosition:{
			filter:"PositionWhere",
			orderBy:"PositionOrderBy"
		},
		getPositionLocale:{
			by:"PositionLocaleUniqueWhere",
			filter:"PositionLocaleWhere"
		},
		listPositionLocale:{
			filter:"PositionLocaleWhere",
			orderBy:"PositionLocaleOrderBy"
		},
		paginatePositionLocale:{
			filter:"PositionLocaleWhere",
			orderBy:"PositionLocaleOrderBy"
		},
		getPositionTagList:{
			by:"PositionTagListUniqueWhere",
			filter:"PositionTagListWhere"
		},
		listPositionTagList:{
			filter:"PositionTagListWhere",
			orderBy:"PositionTagListOrderBy"
		},
		paginatePositionTagList:{
			filter:"PositionTagListWhere",
			orderBy:"PositionTagListOrderBy"
		},
		getPositionTagListItem:{
			by:"PositionTagListItemUniqueWhere",
			filter:"PositionTagListItemWhere"
		},
		listPositionTagListItem:{
			filter:"PositionTagListItemWhere",
			orderBy:"PositionTagListItemOrderBy"
		},
		paginatePositionTagListItem:{
			filter:"PositionTagListItemWhere",
			orderBy:"PositionTagListItemOrderBy"
		},
		getPositionCategory:{
			by:"PositionCategoryUniqueWhere",
			filter:"PositionCategoryWhere"
		},
		listPositionCategory:{
			filter:"PositionCategoryWhere",
			orderBy:"PositionCategoryOrderBy"
		},
		paginatePositionCategory:{
			filter:"PositionCategoryWhere",
			orderBy:"PositionCategoryOrderBy"
		},
		getPositionCategoryLocale:{
			by:"PositionCategoryLocaleUniqueWhere",
			filter:"PositionCategoryLocaleWhere"
		},
		listPositionCategoryLocale:{
			filter:"PositionCategoryLocaleWhere",
			orderBy:"PositionCategoryLocaleOrderBy"
		},
		paginatePositionCategoryLocale:{
			filter:"PositionCategoryLocaleWhere",
			orderBy:"PositionCategoryLocaleOrderBy"
		},
		getEmbed:{
			by:"EmbedUniqueWhere",
			filter:"EmbedWhere"
		},
		listEmbed:{
			filter:"EmbedWhere",
			orderBy:"EmbedOrderBy"
		},
		paginateEmbed:{
			filter:"EmbedWhere",
			orderBy:"EmbedOrderBy"
		},
		getRedirect:{
			by:"RedirectUniqueWhere",
			filter:"RedirectWhere"
		},
		listRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		paginateRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		getTalk:{
			by:"TalkUniqueWhere",
			filter:"TalkWhere"
		},
		listTalk:{
			filter:"TalkWhere",
			orderBy:"TalkOrderBy"
		},
		paginateTalk:{
			filter:"TalkWhere",
			orderBy:"TalkOrderBy"
		},
		getTalkList:{
			by:"TalkListUniqueWhere",
			filter:"TalkListWhere"
		},
		listTalkList:{
			filter:"TalkListWhere",
			orderBy:"TalkListOrderBy"
		},
		paginateTalkList:{
			filter:"TalkListWhere",
			orderBy:"TalkListOrderBy"
		},
		getTalkListItem:{
			by:"TalkListItemUniqueWhere",
			filter:"TalkListItemWhere"
		},
		listTalkListItem:{
			filter:"TalkListItemWhere",
			orderBy:"TalkListItemOrderBy"
		},
		paginateTalkListItem:{
			filter:"TalkListItemWhere",
			orderBy:"TalkListItemOrderBy"
		},
		getTalkLists:{
			by:"TalkListsUniqueWhere",
			filter:"TalkListsWhere"
		},
		listTalkLists:{
			filter:"TalkListsWhere",
			orderBy:"TalkListsOrderBy"
		},
		paginateTalkLists:{
			filter:"TalkListsWhere",
			orderBy:"TalkListsOrderBy"
		},
		getBusinessCard:{
			by:"BusinessCardUniqueWhere",
			filter:"BusinessCardWhere"
		},
		listBusinessCard:{
			filter:"BusinessCardWhere",
			orderBy:"BusinessCardOrderBy"
		},
		paginateBusinessCard:{
			filter:"BusinessCardWhere",
			orderBy:"BusinessCardOrderBy"
		},
		getBusinessCardList:{
			by:"BusinessCardListUniqueWhere",
			filter:"BusinessCardListWhere"
		},
		listBusinessCardList:{
			filter:"BusinessCardListWhere",
			orderBy:"BusinessCardListOrderBy"
		},
		paginateBusinessCardList:{
			filter:"BusinessCardListWhere",
			orderBy:"BusinessCardListOrderBy"
		},
		getBusinessCardListItem:{
			by:"BusinessCardListItemUniqueWhere",
			filter:"BusinessCardListItemWhere"
		},
		listBusinessCardListItem:{
			filter:"BusinessCardListItemWhere",
			orderBy:"BusinessCardListItemOrderBy"
		},
		paginateBusinessCardListItem:{
			filter:"BusinessCardListItemWhere",
			orderBy:"BusinessCardListItemOrderBy"
		},
		getTestimonial:{
			by:"TestimonialUniqueWhere",
			filter:"TestimonialWhere"
		},
		listTestimonial:{
			filter:"TestimonialWhere",
			orderBy:"TestimonialOrderBy"
		},
		paginateTestimonial:{
			filter:"TestimonialWhere",
			orderBy:"TestimonialOrderBy"
		},
		getTestimonialList:{
			by:"TestimonialListUniqueWhere",
			filter:"TestimonialListWhere"
		},
		listTestimonialList:{
			filter:"TestimonialListWhere",
			orderBy:"TestimonialListOrderBy"
		},
		paginateTestimonialList:{
			filter:"TestimonialListWhere",
			orderBy:"TestimonialListOrderBy"
		},
		getTestimonialListItem:{
			by:"TestimonialListItemUniqueWhere",
			filter:"TestimonialListItemWhere"
		},
		listTestimonialListItem:{
			filter:"TestimonialListItemWhere",
			orderBy:"TestimonialListItemOrderBy"
		},
		paginateTestimonialListItem:{
			filter:"TestimonialListItemWhere",
			orderBy:"TestimonialListItemOrderBy"
		},
		getContactRequestOption:{
			by:"ContactRequestOptionUniqueWhere",
			filter:"ContactRequestOptionWhere"
		},
		listContactRequestOption:{
			filter:"ContactRequestOptionWhere",
			orderBy:"ContactRequestOptionOrderBy"
		},
		paginateContactRequestOption:{
			filter:"ContactRequestOptionWhere",
			orderBy:"ContactRequestOptionOrderBy"
		},
		getContactRequestOptionList:{
			by:"ContactRequestOptionListUniqueWhere",
			filter:"ContactRequestOptionListWhere"
		},
		listContactRequestOptionList:{
			filter:"ContactRequestOptionListWhere",
			orderBy:"ContactRequestOptionListOrderBy"
		},
		paginateContactRequestOptionList:{
			filter:"ContactRequestOptionListWhere",
			orderBy:"ContactRequestOptionListOrderBy"
		},
		getYoutubeVideo:{
			by:"YoutubeVideoUniqueWhere",
			filter:"YoutubeVideoWhere"
		},
		listYoutubeVideo:{
			filter:"YoutubeVideoWhere",
			orderBy:"YoutubeVideoOrderBy"
		},
		paginateYoutubeVideo:{
			filter:"YoutubeVideoWhere",
			orderBy:"YoutubeVideoOrderBy"
		},
		getYoutubeVideoList:{
			by:"YoutubeVideoListUniqueWhere",
			filter:"YoutubeVideoListWhere"
		},
		listYoutubeVideoList:{
			filter:"YoutubeVideoListWhere",
			orderBy:"YoutubeVideoListOrderBy"
		},
		paginateYoutubeVideoList:{
			filter:"YoutubeVideoListWhere",
			orderBy:"YoutubeVideoListOrderBy"
		},
		getYoutubeVideoListItem:{
			by:"YoutubeVideoListItemUniqueWhere",
			filter:"YoutubeVideoListItemWhere"
		},
		listYoutubeVideoListItem:{
			filter:"YoutubeVideoListItemWhere",
			orderBy:"YoutubeVideoListItemOrderBy"
		},
		paginateYoutubeVideoListItem:{
			filter:"YoutubeVideoListItemWhere",
			orderBy:"YoutubeVideoListItemOrderBy"
		},
		getTranslationCatalogue:{
			by:"TranslationCatalogueUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		listTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		paginateTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		getTranslationCataloguesIdentifier:{
			by:"TranslationCataloguesIdentifierUniqueWhere",
			filter:"TranslationCataloguesIdentifierWhere"
		},
		listTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		paginateTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		getTranslationDomain:{
			by:"TranslationDomainUniqueWhere",
			filter:"TranslationDomainWhere"
		},
		listTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		paginateTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		getTranslationKey:{
			by:"TranslationKeyUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		listTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		paginateTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		getTranslationValue:{
			by:"TranslationValueUniqueWhere",
			filter:"TranslationValueWhere"
		},
		listTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		paginateTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		validateCreateContactRequestSubmission:{
			data:"ContactRequestSubmissionCreateInput"
		},
		getSubscribeForm:{
			by:"SubscribeFormUniqueWhere",
			filter:"SubscribeFormWhere"
		},
		listSubscribeForm:{
			filter:"SubscribeFormWhere",
			orderBy:"SubscribeFormOrderBy"
		},
		paginateSubscribeForm:{
			filter:"SubscribeFormWhere",
			orderBy:"SubscribeFormOrderBy"
		},
		validateCreateSubscribeForm:{
			data:"SubscribeFormCreateInput"
		},
		validateUpdateSubscribeForm:{
			by:"SubscribeFormUniqueWhere",
			data:"SubscribeFormUpdateInput"
		},
		validateCreateSubscribeSubmission:{
			data:"SubscribeSubmissionCreateInput"
		},
		getContentReferencePositionCategoryItem:{
			by:"ContentReferencePositionCategoryItemUniqueWhere",
			filter:"ContentReferencePositionCategoryItemWhere"
		},
		listContentReferencePositionCategoryItem:{
			filter:"ContentReferencePositionCategoryItemWhere",
			orderBy:"ContentReferencePositionCategoryItemOrderBy"
		},
		paginateContentReferencePositionCategoryItem:{
			filter:"ContentReferencePositionCategoryItemWhere",
			orderBy:"ContentReferencePositionCategoryItemOrderBy"
		},
		getVideo:{
			by:"VideoUniqueWhere",
			filter:"VideoWhere"
		},
		listVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		paginateVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		getContentReferenceCaseStudyItem:{
			by:"ContentReferenceCaseStudyItemUniqueWhere",
			filter:"ContentReferenceCaseStudyItemWhere"
		},
		listContentReferenceCaseStudyItem:{
			filter:"ContentReferenceCaseStudyItemWhere",
			orderBy:"ContentReferenceCaseStudyItemOrderBy"
		},
		paginateContentReferenceCaseStudyItem:{
			filter:"ContentReferenceCaseStudyItemWhere",
			orderBy:"ContentReferenceCaseStudyItemOrderBy"
		},
		getContentReferenceTagItem:{
			by:"ContentReferenceTagItemUniqueWhere",
			filter:"ContentReferenceTagItemWhere"
		},
		listContentReferenceTagItem:{
			filter:"ContentReferenceTagItemWhere",
			orderBy:"ContentReferenceTagItemOrderBy"
		},
		paginateContentReferenceTagItem:{
			filter:"ContentReferenceTagItemWhere",
			orderBy:"ContentReferenceTagItemOrderBy"
		},
		getPositionList:{
			by:"PositionListUniqueWhere",
			filter:"PositionListWhere"
		},
		listPositionList:{
			filter:"PositionListWhere",
			orderBy:"PositionListOrderBy"
		},
		paginatePositionList:{
			filter:"PositionListWhere",
			orderBy:"PositionListOrderBy"
		},
		getPositionListItem:{
			by:"PositionListItemUniqueWhere",
			filter:"PositionListItemWhere"
		},
		listPositionListItem:{
			filter:"PositionListItemWhere",
			orderBy:"PositionListItemOrderBy"
		},
		paginatePositionListItem:{
			filter:"PositionListItemWhere",
			orderBy:"PositionListItemOrderBy"
		},
		getTestimonialFull:{
			by:"TestimonialFullUniqueWhere",
			filter:"TestimonialFullWhere"
		},
		listTestimonialFull:{
			filter:"TestimonialFullWhere",
			orderBy:"TestimonialFullOrderBy"
		},
		paginateTestimonialFull:{
			filter:"TestimonialFullWhere",
			orderBy:"TestimonialFullOrderBy"
		},
		getTestimonialFullList:{
			by:"TestimonialFullListUniqueWhere",
			filter:"TestimonialFullListWhere"
		},
		listTestimonialFullList:{
			filter:"TestimonialFullListWhere",
			orderBy:"TestimonialFullListOrderBy"
		},
		paginateTestimonialFullList:{
			filter:"TestimonialFullListWhere",
			orderBy:"TestimonialFullListOrderBy"
		},
		getTestimonialFullListItem:{
			by:"TestimonialFullListItemUniqueWhere",
			filter:"TestimonialFullListItemWhere"
		},
		listTestimonialFullListItem:{
			filter:"TestimonialFullListItemWhere",
			orderBy:"TestimonialFullListItemOrderBy"
		},
		paginateTestimonialFullListItem:{
			filter:"TestimonialFullListItemWhere",
			orderBy:"TestimonialFullListItemOrderBy"
		},
		getSecondLevelLinkItem:{
			by:"SecondLevelLinkItemUniqueWhere",
			filter:"SecondLevelLinkItemWhere"
		},
		listSecondLevelLinkItem:{
			filter:"SecondLevelLinkItemWhere",
			orderBy:"SecondLevelLinkItemOrderBy"
		},
		paginateSecondLevelLinkItem:{
			filter:"SecondLevelLinkItemWhere",
			orderBy:"SecondLevelLinkItemOrderBy"
		},
		getThirdLevelLinkItem:{
			by:"ThirdLevelLinkItemUniqueWhere",
			filter:"ThirdLevelLinkItemWhere"
		},
		listThirdLevelLinkItem:{
			filter:"ThirdLevelLinkItemWhere",
			orderBy:"ThirdLevelLinkItemOrderBy"
		},
		paginateThirdLevelLinkItem:{
			filter:"ThirdLevelLinkItemWhere",
			orderBy:"ThirdLevelLinkItemOrderBy"
		},
		getTopLevelLinkItem:{
			by:"TopLevelLinkItemUniqueWhere",
			filter:"TopLevelLinkItemWhere"
		},
		listTopLevelLinkItem:{
			filter:"TopLevelLinkItemWhere",
			orderBy:"TopLevelLinkItemOrderBy"
		},
		paginateTopLevelLinkItem:{
			filter:"TopLevelLinkItemWhere",
			orderBy:"TopLevelLinkItemOrderBy"
		},
		getAuthor:{
			by:"AuthorUniqueWhere",
			filter:"AuthorWhere"
		},
		listAuthor:{
			filter:"AuthorWhere",
			orderBy:"AuthorOrderBy"
		},
		paginateAuthor:{
			filter:"AuthorWhere",
			orderBy:"AuthorOrderBy"
		},
		getAuthorLocale:{
			by:"AuthorLocaleUniqueWhere",
			filter:"AuthorLocaleWhere"
		},
		listAuthorLocale:{
			filter:"AuthorLocaleWhere",
			orderBy:"AuthorLocaleOrderBy"
		},
		paginateAuthorLocale:{
			filter:"AuthorLocaleWhere",
			orderBy:"AuthorLocaleOrderBy"
		},
		getBlogPost:{
			by:"BlogPostUniqueWhere",
			filter:"BlogPostWhere"
		},
		listBlogPost:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		},
		paginateBlogPost:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		},
		getBlogPostLocale:{
			by:"BlogPostLocaleUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		listBlogPostLocale:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		paginateBlogPostLocale:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		getContentReferenceBlogPostItem:{
			by:"ContentReferenceBlogPostItemUniqueWhere",
			filter:"ContentReferenceBlogPostItemWhere"
		},
		listContentReferenceBlogPostItem:{
			filter:"ContentReferenceBlogPostItemWhere",
			orderBy:"ContentReferenceBlogPostItemOrderBy"
		},
		paginateContentReferenceBlogPostItem:{
			filter:"ContentReferenceBlogPostItemWhere",
			orderBy:"ContentReferenceBlogPostItemOrderBy"
		},
		getRelevantBlogPostList:{
			by:"RelevantBlogPostListUniqueWhere",
			filter:"RelevantBlogPostListWhere"
		},
		listRelevantBlogPostList:{
			filter:"RelevantBlogPostListWhere",
			orderBy:"RelevantBlogPostListOrderBy"
		},
		paginateRelevantBlogPostList:{
			filter:"RelevantBlogPostListWhere",
			orderBy:"RelevantBlogPostListOrderBy"
		},
		getBlogPostCommon:{
			by:"BlogPostCommonUniqueWhere",
			filter:"BlogPostCommonWhere"
		},
		listBlogPostCommon:{
			filter:"BlogPostCommonWhere",
			orderBy:"BlogPostCommonOrderBy"
		},
		paginateBlogPostCommon:{
			filter:"BlogPostCommonWhere",
			orderBy:"BlogPostCommonOrderBy"
		},
		getBlogPostCommonLocale:{
			by:"BlogPostCommonLocaleUniqueWhere",
			filter:"BlogPostCommonLocaleWhere"
		},
		listBlogPostCommonLocale:{
			filter:"BlogPostCommonLocaleWhere",
			orderBy:"BlogPostCommonLocaleOrderBy"
		},
		paginateBlogPostCommonLocale:{
			filter:"BlogPostCommonLocaleWhere",
			orderBy:"BlogPostCommonLocaleOrderBy"
		}
	},
	Mutation:{
		generateUploadUrl:{
			input:"S3GenerateSignedUploadInput"
		},
		generateReadUrl:{

		},
		createContactRequestSubmission:{
			data:"ContactRequestSubmissionCreateInput"
		},
		createSubscribeForm:{
			data:"SubscribeFormCreateInput"
		},
		updateSubscribeForm:{
			by:"SubscribeFormUniqueWhere",
			filter:"SubscribeFormWhere",
			data:"SubscribeFormUpdateInput"
		},
		upsertSubscribeForm:{
			by:"SubscribeFormUniqueWhere",
			filter:"SubscribeFormWhere",
			update:"SubscribeFormUpdateInput",
			create:"SubscribeFormCreateInput"
		},
		createSubscribeSubmission:{
			data:"SubscribeSubmissionCreateInput"
		},
		transaction:{
			options:"MutationTransactionOptions"
		}
	},
	S3GenerateSignedUploadInput:{
		contentDisposition:"S33ContentDisposition",
		acl:"S3Acl"
	},
	S33ContentDisposition: "enum" as const,
	S3Acl: "enum" as const,
	_MutationErrorType: "enum" as const,
	MutationTransaction:{
		generateUploadUrl:{
			input:"S3GenerateSignedUploadInput"
		},
		generateReadUrl:{

		},
		createContactRequestSubmission:{
			data:"ContactRequestSubmissionCreateInput"
		},
		createSubscribeForm:{
			data:"SubscribeFormCreateInput"
		},
		updateSubscribeForm:{
			by:"SubscribeFormUniqueWhere",
			filter:"SubscribeFormWhere",
			data:"SubscribeFormUpdateInput"
		},
		upsertSubscribeForm:{
			by:"SubscribeFormUniqueWhere",
			filter:"SubscribeFormWhere",
			update:"SubscribeFormUpdateInput",
			create:"SubscribeFormCreateInput"
		},
		createSubscribeSubmission:{
			data:"SubscribeSubmissionCreateInput"
		}
	},
	MutationTransactionOptions:{

	}
}

export const ReturnTypes: Record<string,any> = {
	_Column:{
		name:"String",
		type:"String",
		enumName:"String",
		defaultValue:"Json",
		nullable:"Boolean",
		rules:"_Rule",
		validators:"_Validator"
	},
	_Field:{
		"...on _Column": "_Column",
		"...on _Relation": "_Relation",
		name:"String",
		type:"String",
		nullable:"Boolean",
		rules:"_Rule",
		validators:"_Validator"
	},
	_Rule:{
		message:"_RuleMessage",
		validator:"Int"
	},
	_RuleMessage:{
		text:"String"
	},
	_Validator:{
		operation:"String",
		arguments:"_Argument"
	},
	_Argument:{
		"...on _ValidatorArgument":"_ValidatorArgument",
		"...on _PathArgument":"_PathArgument",
		"...on _LiteralArgument":"_LiteralArgument"
	},
	_ValidatorArgument:{
		validator:"Int"
	},
	_PathArgument:{
		path:"String"
	},
	_LiteralArgument:{
		value:"Json"
	},
	Json: `scalar.Json` as const,
	_Relation:{
		name:"String",
		type:"String",
		side:"_RelationSide",
		targetEntity:"String",
		ownedBy:"String",
		inversedBy:"String",
		nullable:"Boolean",
		onDelete:"_OnDeleteBehaviour",
		orphanRemoval:"Boolean",
		orderBy:"_OrderBy",
		rules:"_Rule",
		validators:"_Validator"
	},
	_OrderBy:{
		path:"String",
		direction:"_OrderByDirection"
	},
	Query:{
		schema:"_Schema",
		s3DummyQuery:"String",
		getGeneral:"General",
		listGeneral:"General",
		paginateGeneral:"GeneralConnection",
		getImage:"Image",
		listImage:"Image",
		paginateImage:"ImageConnection",
		getGenericPage:"GenericPage",
		listGenericPage:"GenericPage",
		paginateGenericPage:"GenericPageConnection",
		getLinkable:"Linkable",
		listLinkable:"Linkable",
		paginateLinkable:"LinkableConnection",
		getSeo:"Seo",
		listSeo:"Seo",
		paginateSeo:"SeoConnection",
		getContent:"Content",
		listContent:"Content",
		paginateContent:"ContentConnection",
		getContentBlock:"ContentBlock",
		listContentBlock:"ContentBlock",
		paginateContentBlock:"ContentBlockConnection",
		getContentReference:"ContentReference",
		listContentReference:"ContentReference",
		paginateContentReference:"ContentReferenceConnection",
		getGeneralLocale:"GeneralLocale",
		listGeneralLocale:"GeneralLocale",
		paginateGeneralLocale:"GeneralLocaleConnection",
		getLocale:"Locale",
		listLocale:"Locale",
		paginateLocale:"LocaleConnection",
		getGenericPageLocale:"GenericPageLocale",
		listGenericPageLocale:"GenericPageLocale",
		paginateGenericPageLocale:"GenericPageLocaleConnection",
		getLink:"Link",
		listLink:"Link",
		paginateLink:"LinkConnection",
		getLinkList:"LinkList",
		listLinkList:"LinkList",
		paginateLinkList:"LinkListConnection",
		getLinkListItem:"LinkListItem",
		listLinkListItem:"LinkListItem",
		paginateLinkListItem:"LinkListItemConnection",
		getHeader:"Header",
		listHeader:"Header",
		paginateHeader:"HeaderConnection",
		getHeaderLocale:"HeaderLocale",
		listHeaderLocale:"HeaderLocale",
		paginateHeaderLocale:"HeaderLocaleConnection",
		getFooter:"Footer",
		listFooter:"Footer",
		paginateFooter:"FooterConnection",
		getFooterLocale:"FooterLocale",
		listFooterLocale:"FooterLocale",
		paginateFooterLocale:"FooterLocaleConnection",
		getCaseStudy:"CaseStudy",
		listCaseStudy:"CaseStudy",
		paginateCaseStudy:"CaseStudyConnection",
		getCaseStudyLocale:"CaseStudyLocale",
		listCaseStudyLocale:"CaseStudyLocale",
		paginateCaseStudyLocale:"CaseStudyLocaleConnection",
		getTag:"Tag",
		listTag:"Tag",
		paginateTag:"TagConnection",
		getTagLocale:"TagLocale",
		listTagLocale:"TagLocale",
		paginateTagLocale:"TagLocaleConnection",
		getCaseStudyTag:"CaseStudyTag",
		listCaseStudyTag:"CaseStudyTag",
		paginateCaseStudyTag:"CaseStudyTagConnection",
		getCaseStudyList:"CaseStudyList",
		listCaseStudyList:"CaseStudyList",
		paginateCaseStudyList:"CaseStudyListConnection",
		getCaseStudyListItem:"CaseStudyListItem",
		listCaseStudyListItem:"CaseStudyListItem",
		paginateCaseStudyListItem:"CaseStudyListItemConnection",
		getImageList:"ImageList",
		listImageList:"ImageList",
		paginateImageList:"ImageListConnection",
		getImageListItem:"ImageListItem",
		listImageListItem:"ImageListItem",
		paginateImageListItem:"ImageListItemConnection",
		getInformationList:"InformationList",
		listInformationList:"InformationList",
		paginateInformationList:"InformationListConnection",
		getPieceOfInformation:"PieceOfInformation",
		listPieceOfInformation:"PieceOfInformation",
		paginatePieceOfInformation:"PieceOfInformationConnection",
		getPositionTag:"PositionTag",
		listPositionTag:"PositionTag",
		paginatePositionTag:"PositionTagConnection",
		getPositionTagLocale:"PositionTagLocale",
		listPositionTagLocale:"PositionTagLocale",
		paginatePositionTagLocale:"PositionTagLocaleConnection",
		getPosition:"Position",
		listPosition:"Position",
		paginatePosition:"PositionConnection",
		getPositionLocale:"PositionLocale",
		listPositionLocale:"PositionLocale",
		paginatePositionLocale:"PositionLocaleConnection",
		getPositionTagList:"PositionTagList",
		listPositionTagList:"PositionTagList",
		paginatePositionTagList:"PositionTagListConnection",
		getPositionTagListItem:"PositionTagListItem",
		listPositionTagListItem:"PositionTagListItem",
		paginatePositionTagListItem:"PositionTagListItemConnection",
		getPositionCategory:"PositionCategory",
		listPositionCategory:"PositionCategory",
		paginatePositionCategory:"PositionCategoryConnection",
		getPositionCategoryLocale:"PositionCategoryLocale",
		listPositionCategoryLocale:"PositionCategoryLocale",
		paginatePositionCategoryLocale:"PositionCategoryLocaleConnection",
		getEmbed:"Embed",
		listEmbed:"Embed",
		paginateEmbed:"EmbedConnection",
		getRedirect:"Redirect",
		listRedirect:"Redirect",
		paginateRedirect:"RedirectConnection",
		getTalk:"Talk",
		listTalk:"Talk",
		paginateTalk:"TalkConnection",
		getTalkList:"TalkList",
		listTalkList:"TalkList",
		paginateTalkList:"TalkListConnection",
		getTalkListItem:"TalkListItem",
		listTalkListItem:"TalkListItem",
		paginateTalkListItem:"TalkListItemConnection",
		getTalkLists:"TalkLists",
		listTalkLists:"TalkLists",
		paginateTalkLists:"TalkListsConnection",
		getBusinessCard:"BusinessCard",
		listBusinessCard:"BusinessCard",
		paginateBusinessCard:"BusinessCardConnection",
		getBusinessCardList:"BusinessCardList",
		listBusinessCardList:"BusinessCardList",
		paginateBusinessCardList:"BusinessCardListConnection",
		getBusinessCardListItem:"BusinessCardListItem",
		listBusinessCardListItem:"BusinessCardListItem",
		paginateBusinessCardListItem:"BusinessCardListItemConnection",
		getTestimonial:"Testimonial",
		listTestimonial:"Testimonial",
		paginateTestimonial:"TestimonialConnection",
		getTestimonialList:"TestimonialList",
		listTestimonialList:"TestimonialList",
		paginateTestimonialList:"TestimonialListConnection",
		getTestimonialListItem:"TestimonialListItem",
		listTestimonialListItem:"TestimonialListItem",
		paginateTestimonialListItem:"TestimonialListItemConnection",
		getContactRequestOption:"ContactRequestOption",
		listContactRequestOption:"ContactRequestOption",
		paginateContactRequestOption:"ContactRequestOptionConnection",
		getContactRequestOptionList:"ContactRequestOptionList",
		listContactRequestOptionList:"ContactRequestOptionList",
		paginateContactRequestOptionList:"ContactRequestOptionListConnection",
		getYoutubeVideo:"YoutubeVideo",
		listYoutubeVideo:"YoutubeVideo",
		paginateYoutubeVideo:"YoutubeVideoConnection",
		getYoutubeVideoList:"YoutubeVideoList",
		listYoutubeVideoList:"YoutubeVideoList",
		paginateYoutubeVideoList:"YoutubeVideoListConnection",
		getYoutubeVideoListItem:"YoutubeVideoListItem",
		listYoutubeVideoListItem:"YoutubeVideoListItem",
		paginateYoutubeVideoListItem:"YoutubeVideoListItemConnection",
		getTranslationCatalogue:"TranslationCatalogue",
		listTranslationCatalogue:"TranslationCatalogue",
		paginateTranslationCatalogue:"TranslationCatalogueConnection",
		getTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		listTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		paginateTranslationCataloguesIdentifier:"TranslationCataloguesIdentifierConnection",
		getTranslationDomain:"TranslationDomain",
		listTranslationDomain:"TranslationDomain",
		paginateTranslationDomain:"TranslationDomainConnection",
		getTranslationKey:"TranslationKey",
		listTranslationKey:"TranslationKey",
		paginateTranslationKey:"TranslationKeyConnection",
		getTranslationValue:"TranslationValue",
		listTranslationValue:"TranslationValue",
		paginateTranslationValue:"TranslationValueConnection",
		validateCreateContactRequestSubmission:"_ValidationResult",
		getSubscribeForm:"SubscribeForm",
		listSubscribeForm:"SubscribeForm",
		paginateSubscribeForm:"SubscribeFormConnection",
		validateCreateSubscribeForm:"_ValidationResult",
		validateUpdateSubscribeForm:"_ValidationResult",
		validateCreateSubscribeSubmission:"_ValidationResult",
		getContentReferencePositionCategoryItem:"ContentReferencePositionCategoryItem",
		listContentReferencePositionCategoryItem:"ContentReferencePositionCategoryItem",
		paginateContentReferencePositionCategoryItem:"ContentReferencePositionCategoryItemConnection",
		getVideo:"Video",
		listVideo:"Video",
		paginateVideo:"VideoConnection",
		getContentReferenceCaseStudyItem:"ContentReferenceCaseStudyItem",
		listContentReferenceCaseStudyItem:"ContentReferenceCaseStudyItem",
		paginateContentReferenceCaseStudyItem:"ContentReferenceCaseStudyItemConnection",
		getContentReferenceTagItem:"ContentReferenceTagItem",
		listContentReferenceTagItem:"ContentReferenceTagItem",
		paginateContentReferenceTagItem:"ContentReferenceTagItemConnection",
		getPositionList:"PositionList",
		listPositionList:"PositionList",
		paginatePositionList:"PositionListConnection",
		getPositionListItem:"PositionListItem",
		listPositionListItem:"PositionListItem",
		paginatePositionListItem:"PositionListItemConnection",
		getTestimonialFull:"TestimonialFull",
		listTestimonialFull:"TestimonialFull",
		paginateTestimonialFull:"TestimonialFullConnection",
		getTestimonialFullList:"TestimonialFullList",
		listTestimonialFullList:"TestimonialFullList",
		paginateTestimonialFullList:"TestimonialFullListConnection",
		getTestimonialFullListItem:"TestimonialFullListItem",
		listTestimonialFullListItem:"TestimonialFullListItem",
		paginateTestimonialFullListItem:"TestimonialFullListItemConnection",
		getSecondLevelLinkItem:"SecondLevelLinkItem",
		listSecondLevelLinkItem:"SecondLevelLinkItem",
		paginateSecondLevelLinkItem:"SecondLevelLinkItemConnection",
		getThirdLevelLinkItem:"ThirdLevelLinkItem",
		listThirdLevelLinkItem:"ThirdLevelLinkItem",
		paginateThirdLevelLinkItem:"ThirdLevelLinkItemConnection",
		getTopLevelLinkItem:"TopLevelLinkItem",
		listTopLevelLinkItem:"TopLevelLinkItem",
		paginateTopLevelLinkItem:"TopLevelLinkItemConnection",
		getAuthor:"Author",
		listAuthor:"Author",
		paginateAuthor:"AuthorConnection",
		getAuthorLocale:"AuthorLocale",
		listAuthorLocale:"AuthorLocale",
		paginateAuthorLocale:"AuthorLocaleConnection",
		getBlogPost:"BlogPost",
		listBlogPost:"BlogPost",
		paginateBlogPost:"BlogPostConnection",
		getBlogPostLocale:"BlogPostLocale",
		listBlogPostLocale:"BlogPostLocale",
		paginateBlogPostLocale:"BlogPostLocaleConnection",
		getContentReferenceBlogPostItem:"ContentReferenceBlogPostItem",
		listContentReferenceBlogPostItem:"ContentReferenceBlogPostItem",
		paginateContentReferenceBlogPostItem:"ContentReferenceBlogPostItemConnection",
		getRelevantBlogPostList:"RelevantBlogPostList",
		listRelevantBlogPostList:"RelevantBlogPostList",
		paginateRelevantBlogPostList:"RelevantBlogPostListConnection",
		getBlogPostCommon:"BlogPostCommon",
		listBlogPostCommon:"BlogPostCommon",
		paginateBlogPostCommon:"BlogPostCommonConnection",
		getBlogPostCommonLocale:"BlogPostCommonLocale",
		listBlogPostCommonLocale:"BlogPostCommonLocale",
		paginateBlogPostCommonLocale:"BlogPostCommonLocaleConnection",
		transaction:"QueryTransaction",
		_info:"Info"
	},
	_Schema:{
		enums:"_Enum",
		entities:"_Entity"
	},
	_Enum:{
		name:"String",
		values:"String"
	},
	_Entity:{
		name:"String",
		customPrimaryAllowed:"Boolean",
		fields:"_Field",
		unique:"_UniqueConstraint"
	},
	_UniqueConstraint:{
		fields:"String"
	},
	General:{
		_meta:"GeneralMeta",
		id:"UUID",
		unique:"One",
		locales:"GeneralLocale",
		localesByLocale:"GeneralLocale",
		localesBySeo:"GeneralLocale",
		localesByContent:"GeneralLocale",
		paginateLocales:"GeneralLocaleConnection"
	},
	GeneralMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta"
	},
	FieldMeta:{
		readable:"Boolean",
		updatable:"Boolean"
	},
	UUID: `scalar.UUID` as const,
	GeneralLocale:{
		_meta:"GeneralLocaleMeta",
		id:"UUID",
		root:"General",
		locale:"Locale",
		seo:"Seo",
		homeLinkable:"Linkable",
		caseStudiesLink:"Link",
		positionsLink:"Link",
		facebookUrl:"String",
		twitterUrl:"String",
		instagramUrl:"String",
		youtubeUrl:"String",
		linkedinUrl:"String",
		formPrivacyPolicy:"String",
		formMarketingConsent:"String",
		acceptButtonLabel:"String",
		rejectButtonLabel:"String",
		content:"Content"
	},
	GeneralLocaleMeta:{
		id:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		seo:"FieldMeta",
		homeLinkable:"FieldMeta",
		caseStudiesLink:"FieldMeta",
		positionsLink:"FieldMeta",
		facebookUrl:"FieldMeta",
		twitterUrl:"FieldMeta",
		instagramUrl:"FieldMeta",
		youtubeUrl:"FieldMeta",
		linkedinUrl:"FieldMeta",
		formPrivacyPolicy:"FieldMeta",
		formMarketingConsent:"FieldMeta",
		acceptButtonLabel:"FieldMeta",
		rejectButtonLabel:"FieldMeta",
		content:"FieldMeta"
	},
	DateTime: `scalar.DateTime` as const,
	Date: `scalar.Date` as const,
	Locale:{
		_meta:"LocaleMeta",
		id:"UUID",
		code:"String",
		label:"String",
		order:"Int",
		general:"GeneralLocale",
		genericPages:"GenericPageLocale",
		headers:"HeaderLocale",
		footers:"FooterLocale",
		caseStudies:"CaseStudyLocale",
		tags:"TagLocale",
		positionTags:"PositionTagLocale",
		positions:"PositionLocale",
		positionCategories:"PositionCategoryLocale",
		authors:"AuthorLocale",
		blogPosts:"BlogPostLocale",
		blogPostCommon:"BlogPostCommonLocale",
		generalByRoot:"GeneralLocale",
		generalBySeo:"GeneralLocale",
		generalByContent:"GeneralLocale",
		genericPagesByRoot:"GenericPageLocale",
		genericPagesByContent:"GenericPageLocale",
		genericPagesByLink:"GenericPageLocale",
		genericPagesBySeo:"GenericPageLocale",
		headersByRoot:"HeaderLocale",
		headersByLinkList:"HeaderLocale",
		headersBySocialLinkList:"HeaderLocale",
		headersByTopLevelLinkItems:"HeaderLocale",
		footersByRoot:"FooterLocale",
		footersByNavigation:"FooterLocale",
		caseStudiesByRoot:"CaseStudyLocale",
		caseStudiesByContent:"CaseStudyLocale",
		caseStudiesByLink:"CaseStudyLocale",
		caseStudiesBySeo:"CaseStudyLocale",
		tagsBySlug:"TagLocale",
		tagsByRoot:"TagLocale",
		positionTagsBySlug:"PositionTagLocale",
		positionTagsByRoot:"PositionTagLocale",
		positionsByRoot:"PositionLocale",
		positionsByLink:"PositionLocale",
		positionsBySeo:"PositionLocale",
		positionsByContent:"PositionLocale",
		positionCategoriesByRoot:"PositionCategoryLocale",
		authorsByRoot:"AuthorLocale",
		blogPostsByRoot:"BlogPostLocale",
		blogPostsByContent:"BlogPostLocale",
		blogPostsBySeo:"BlogPostLocale",
		blogPostsByLink:"BlogPostLocale",
		blogPostCommonByRoot:"BlogPostCommonLocale",
		paginateGeneral:"GeneralLocaleConnection",
		paginateGenericPages:"GenericPageLocaleConnection",
		paginateHeaders:"HeaderLocaleConnection",
		paginateFooters:"FooterLocaleConnection",
		paginateCaseStudies:"CaseStudyLocaleConnection",
		paginateTags:"TagLocaleConnection",
		paginatePositionTags:"PositionTagLocaleConnection",
		paginatePositions:"PositionLocaleConnection",
		paginatePositionCategories:"PositionCategoryLocaleConnection",
		paginateAuthors:"AuthorLocaleConnection",
		paginateBlogPosts:"BlogPostLocaleConnection",
		paginateBlogPostCommon:"BlogPostCommonLocaleConnection"
	},
	LocaleMeta:{
		id:"FieldMeta",
		code:"FieldMeta",
		label:"FieldMeta",
		order:"FieldMeta",
		general:"FieldMeta",
		genericPages:"FieldMeta",
		headers:"FieldMeta",
		footers:"FieldMeta",
		caseStudies:"FieldMeta",
		tags:"FieldMeta",
		positionTags:"FieldMeta",
		positions:"FieldMeta",
		positionCategories:"FieldMeta",
		authors:"FieldMeta",
		blogPosts:"FieldMeta",
		blogPostCommon:"FieldMeta"
	},
	GenericPageLocale:{
		_meta:"GenericPageLocaleMeta",
		id:"UUID",
		title:"String",
		root:"GenericPage",
		locale:"Locale",
		content:"Content",
		link:"Linkable",
		seo:"Seo"
	},
	GenericPageLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		content:"FieldMeta",
		link:"FieldMeta",
		seo:"FieldMeta"
	},
	GenericPage:{
		_meta:"GenericPageMeta",
		id:"UUID",
		locales:"GenericPageLocale",
		publishedAt:"DateTime",
		localesByLocale:"GenericPageLocale",
		localesByContent:"GenericPageLocale",
		localesByLink:"GenericPageLocale",
		localesBySeo:"GenericPageLocale",
		paginateLocales:"GenericPageLocaleConnection"
	},
	GenericPageMeta:{
		id:"FieldMeta",
		locales:"FieldMeta",
		publishedAt:"FieldMeta"
	},
	GenericPageLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"GenericPageLocaleEdge"
	},
	PageInfo:{
		totalCount:"Int"
	},
	GenericPageLocaleEdge:{
		node:"GenericPageLocale"
	},
	Content:{
		_meta:"ContentMeta",
		id:"UUID",
		blocks:"ContentBlock",
		blocksByReferences:"ContentBlock",
		paginateBlocks:"ContentBlockConnection"
	},
	ContentMeta:{
		id:"FieldMeta",
		blocks:"FieldMeta"
	},
	ContentBlock:{
		_meta:"ContentBlockMeta",
		id:"UUID",
		order:"Int",
		json:"String",
		content:"Content",
		references:"ContentReference",
		referencesByLink:"ContentReference",
		referencesByFeaturedCaseStudies:"ContentReference",
		referencesByImages:"ContentReference",
		referencesByOtherImages:"ContentReference",
		referencesByInformationList:"ContentReference",
		referencesByEmbed:"ContentReference",
		referencesByLinks:"ContentReference",
		referencesByTalkLists:"ContentReference",
		referencesByBusinessCardList:"ContentReference",
		referencesByTestimonials:"ContentReference",
		referencesByContactRequestOptions:"ContentReference",
		referencesByYoutubeVideo:"ContentReference",
		referencesByPositionCategories:"ContentReference",
		referencesByCaseStudies:"ContentReference",
		referencesByTags:"ContentReference",
		referencesByPositions:"ContentReference",
		referencesByTestimonialsFull:"ContentReference",
		referencesByBlogPosts:"ContentReference",
		referencesByContent:"ContentReference",
		paginateReferences:"ContentReferenceConnection"
	},
	ContentBlockMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		json:"FieldMeta",
		content:"FieldMeta",
		references:"FieldMeta"
	},
	ContentReference:{
		_meta:"ContentReferenceMeta",
		id:"UUID",
		type:"ContentReferenceType",
		primaryText:"String",
		block:"ContentBlock",
		image:"Image",
		secondaryText:"String",
		link:"Link",
		featuredCaseStudies:"CaseStudyList",
		images:"ImageList",
		otherImages:"ImageList",
		informationList:"InformationList",
		tertiaryText:"String",
		embed:"Embed",
		links:"LinkList",
		talkLists:"TalkLists",
		businessCardList:"BusinessCardList",
		testimonials:"TestimonialList",
		background:"ContentBackgroundType",
		otherImage:"Image",
		caseStudy:"CaseStudy",
		alternative:"Boolean",
		contactRequestOptions:"ContactRequestOptionList",
		youtubeVideo:"YoutubeVideo",
		subscribeForm:"SubscribeForm",
		video:"Video",
		otherVideo:"Video",
		positionCategories:"ContentReferencePositionCategoryItem",
		caseStudies:"ContentReferenceCaseStudyItem",
		tags:"ContentReferenceTagItem",
		positions:"PositionList",
		testimonialsFull:"TestimonialFullList",
		blogPosts:"ContentReferenceBlogPostItem",
		verticalMargin:"ContentVerticalMargin",
		variant:"String",
		content:"Content",
		positionCategoriesByItem:"ContentReferencePositionCategoryItem",
		caseStudiesByItem:"ContentReferenceCaseStudyItem",
		tagsByItem:"ContentReferenceTagItem",
		blogPostsByItem:"ContentReferenceBlogPostItem",
		paginatePositionCategories:"ContentReferencePositionCategoryItemConnection",
		paginateCaseStudies:"ContentReferenceCaseStudyItemConnection",
		paginateTags:"ContentReferenceTagItemConnection",
		paginateBlogPosts:"ContentReferenceBlogPostItemConnection"
	},
	ContentReferenceMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		primaryText:"FieldMeta",
		block:"FieldMeta",
		image:"FieldMeta",
		secondaryText:"FieldMeta",
		link:"FieldMeta",
		featuredCaseStudies:"FieldMeta",
		images:"FieldMeta",
		otherImages:"FieldMeta",
		informationList:"FieldMeta",
		tertiaryText:"FieldMeta",
		embed:"FieldMeta",
		links:"FieldMeta",
		talkLists:"FieldMeta",
		businessCardList:"FieldMeta",
		testimonials:"FieldMeta",
		background:"FieldMeta",
		otherImage:"FieldMeta",
		caseStudy:"FieldMeta",
		alternative:"FieldMeta",
		contactRequestOptions:"FieldMeta",
		youtubeVideo:"FieldMeta",
		subscribeForm:"FieldMeta",
		video:"FieldMeta",
		otherVideo:"FieldMeta",
		positionCategories:"FieldMeta",
		caseStudies:"FieldMeta",
		tags:"FieldMeta",
		positions:"FieldMeta",
		testimonialsFull:"FieldMeta",
		blogPosts:"FieldMeta",
		verticalMargin:"FieldMeta",
		variant:"FieldMeta",
		content:"FieldMeta"
	},
	Image:{
		_meta:"ImageMeta",
		id:"UUID",
		url:"String",
		width:"Int",
		height:"Int",
		size:"Int",
		type:"String",
		alt:"String",
		fileName:"String"
	},
	ImageMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		width:"FieldMeta",
		height:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta",
		alt:"FieldMeta",
		fileName:"FieldMeta"
	},
	Link:{
		_meta:"LinkMeta",
		id:"UUID",
		title:"String",
		externalLink:"String",
		internalLink:"Linkable",
		type:"LinkType",
		isTargetBlank:"Boolean"
	},
	LinkMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		externalLink:"FieldMeta",
		internalLink:"FieldMeta",
		type:"FieldMeta",
		isTargetBlank:"FieldMeta"
	},
	Linkable:{
		_meta:"LinkableMeta",
		id:"UUID",
		url:"String",
		genericPage:"GenericPageLocale",
		caseStudy:"CaseStudyLocale",
		position:"PositionLocale",
		redirect:"Redirect",
		blogPost:"BlogPostLocale"
	},
	LinkableMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		genericPage:"FieldMeta",
		caseStudy:"FieldMeta",
		position:"FieldMeta",
		redirect:"FieldMeta",
		blogPost:"FieldMeta"
	},
	CaseStudyLocale:{
		_meta:"CaseStudyLocaleMeta",
		id:"UUID",
		title:"String",
		root:"CaseStudy",
		locale:"Locale",
		content:"Content",
		link:"Linkable",
		seo:"Seo",
		note:"String"
	},
	CaseStudyLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		content:"FieldMeta",
		link:"FieldMeta",
		seo:"FieldMeta",
		note:"FieldMeta"
	},
	CaseStudy:{
		_meta:"CaseStudyMeta",
		id:"UUID",
		locales:"CaseStudyLocale",
		tags:"CaseStudyTag",
		wideImage:"Image",
		narrowImage:"Image",
		order:"Int",
		publishedAt:"DateTime",
		localesByLocale:"CaseStudyLocale",
		localesByContent:"CaseStudyLocale",
		localesByLink:"CaseStudyLocale",
		localesBySeo:"CaseStudyLocale",
		paginateLocales:"CaseStudyLocaleConnection",
		paginateTags:"CaseStudyTagConnection"
	},
	CaseStudyMeta:{
		id:"FieldMeta",
		locales:"FieldMeta",
		tags:"FieldMeta",
		wideImage:"FieldMeta",
		narrowImage:"FieldMeta",
		order:"FieldMeta",
		publishedAt:"FieldMeta"
	},
	CaseStudyTag:{
		_meta:"CaseStudyTagMeta",
		id:"UUID",
		order:"Int",
		caseStudy:"CaseStudy",
		tag:"Tag"
	},
	CaseStudyTagMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		caseStudy:"FieldMeta",
		tag:"FieldMeta"
	},
	Tag:{
		_meta:"TagMeta",
		id:"UUID",
		locales:"TagLocale",
		caseStudyTags:"CaseStudyTag",
		order:"Int",
		localesByLocale:"TagLocale",
		paginateLocales:"TagLocaleConnection",
		paginateCaseStudyTags:"CaseStudyTagConnection"
	},
	TagMeta:{
		id:"FieldMeta",
		locales:"FieldMeta",
		caseStudyTags:"FieldMeta",
		order:"FieldMeta"
	},
	TagLocale:{
		_meta:"TagLocaleMeta",
		id:"UUID",
		slug:"String",
		title:"String",
		root:"Tag",
		locale:"Locale"
	},
	TagLocaleMeta:{
		id:"FieldMeta",
		slug:"FieldMeta",
		title:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	TagLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"TagLocaleEdge"
	},
	TagLocaleEdge:{
		node:"TagLocale"
	},
	CaseStudyTagConnection:{
		pageInfo:"PageInfo",
		edges:"CaseStudyTagEdge"
	},
	CaseStudyTagEdge:{
		node:"CaseStudyTag"
	},
	CaseStudyLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"CaseStudyLocaleEdge"
	},
	CaseStudyLocaleEdge:{
		node:"CaseStudyLocale"
	},
	Seo:{
		_meta:"SeoMeta",
		id:"UUID",
		title:"String",
		description:"String",
		ogTitle:"String",
		ogDescription:"String",
		ogImage:"Image",
		noIndex:"Boolean",
		noFollow:"Boolean"
	},
	SeoMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		description:"FieldMeta",
		ogTitle:"FieldMeta",
		ogDescription:"FieldMeta",
		ogImage:"FieldMeta",
		noIndex:"FieldMeta",
		noFollow:"FieldMeta"
	},
	PositionLocale:{
		_meta:"PositionLocaleMeta",
		id:"UUID",
		title:"String",
		link:"Linkable",
		root:"Position",
		locale:"Locale",
		seo:"Seo",
		content:"Content",
		summary:"String",
		salaryRange:"String"
	},
	PositionLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		link:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		seo:"FieldMeta",
		content:"FieldMeta",
		summary:"FieldMeta",
		salaryRange:"FieldMeta"
	},
	Position:{
		_meta:"PositionMeta",
		id:"UUID",
		tags:"PositionTagList",
		locales:"PositionLocale",
		orderInCategory:"Int",
		category:"PositionCategory",
		publishedAt:"DateTime",
		localesByLocale:"PositionLocale",
		localesByLink:"PositionLocale",
		localesBySeo:"PositionLocale",
		localesByContent:"PositionLocale",
		paginateLocales:"PositionLocaleConnection"
	},
	PositionMeta:{
		id:"FieldMeta",
		tags:"FieldMeta",
		locales:"FieldMeta",
		orderInCategory:"FieldMeta",
		category:"FieldMeta",
		publishedAt:"FieldMeta"
	},
	PositionTagList:{
		_meta:"PositionTagListMeta",
		id:"UUID",
		items:"PositionTagListItem",
		paginateItems:"PositionTagListItemConnection"
	},
	PositionTagListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	PositionTagListItem:{
		_meta:"PositionTagListItemMeta",
		id:"UUID",
		order:"Int",
		list:"PositionTagList",
		tag:"PositionTag"
	},
	PositionTagListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		tag:"FieldMeta"
	},
	PositionTag:{
		_meta:"PositionTagMeta",
		id:"UUID",
		locales:"PositionTagLocale",
		localesByLocale:"PositionTagLocale",
		paginateLocales:"PositionTagLocaleConnection"
	},
	PositionTagMeta:{
		id:"FieldMeta",
		locales:"FieldMeta"
	},
	PositionTagLocale:{
		_meta:"PositionTagLocaleMeta",
		id:"UUID",
		slug:"String",
		title:"String",
		root:"PositionTag",
		locale:"Locale"
	},
	PositionTagLocaleMeta:{
		id:"FieldMeta",
		slug:"FieldMeta",
		title:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	PositionTagLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"PositionTagLocaleEdge"
	},
	PositionTagLocaleEdge:{
		node:"PositionTagLocale"
	},
	PositionTagListItemConnection:{
		pageInfo:"PageInfo",
		edges:"PositionTagListItemEdge"
	},
	PositionTagListItemEdge:{
		node:"PositionTagListItem"
	},
	PositionCategory:{
		_meta:"PositionCategoryMeta",
		id:"UUID",
		positions:"Position",
		locales:"PositionCategoryLocale",
		order:"Int",
		positionsByTags:"Position",
		positionsByLocales:"Position",
		localesByLocale:"PositionCategoryLocale",
		paginatePositions:"PositionConnection",
		paginateLocales:"PositionCategoryLocaleConnection"
	},
	PositionCategoryMeta:{
		id:"FieldMeta",
		positions:"FieldMeta",
		locales:"FieldMeta",
		order:"FieldMeta"
	},
	PositionCategoryLocale:{
		_meta:"PositionCategoryLocaleMeta",
		id:"UUID",
		title:"String",
		root:"PositionCategory",
		locale:"Locale"
	},
	PositionCategoryLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	PositionConnection:{
		pageInfo:"PageInfo",
		edges:"PositionEdge"
	},
	PositionEdge:{
		node:"Position"
	},
	PositionCategoryLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"PositionCategoryLocaleEdge"
	},
	PositionCategoryLocaleEdge:{
		node:"PositionCategoryLocale"
	},
	PositionLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"PositionLocaleEdge"
	},
	PositionLocaleEdge:{
		node:"PositionLocale"
	},
	Redirect:{
		_meta:"RedirectMeta",
		id:"UUID",
		link:"Linkable",
		target:"Link"
	},
	RedirectMeta:{
		id:"FieldMeta",
		link:"FieldMeta",
		target:"FieldMeta"
	},
	BlogPostLocale:{
		_meta:"BlogPostLocaleMeta",
		id:"UUID",
		title:"String",
		root:"BlogPost",
		locale:"Locale",
		content:"Content",
		seo:"Seo",
		link:"Linkable",
		lead:"String"
	},
	BlogPostLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		content:"FieldMeta",
		seo:"FieldMeta",
		link:"FieldMeta",
		lead:"FieldMeta"
	},
	BlogPost:{
		_meta:"BlogPostMeta",
		id:"UUID",
		publishedAt:"DateTime",
		author:"Author",
		wideImage:"Image",
		narrowImage:"Image",
		locales:"BlogPostLocale",
		relevantBlogPosts:"RelevantBlogPostList",
		localesByLocale:"BlogPostLocale",
		localesByContent:"BlogPostLocale",
		localesBySeo:"BlogPostLocale",
		localesByLink:"BlogPostLocale",
		relevantBlogPostsByRelevantBlogPost:"RelevantBlogPostList",
		paginateLocales:"BlogPostLocaleConnection",
		paginateRelevantBlogPosts:"RelevantBlogPostListConnection"
	},
	BlogPostMeta:{
		id:"FieldMeta",
		publishedAt:"FieldMeta",
		author:"FieldMeta",
		wideImage:"FieldMeta",
		narrowImage:"FieldMeta",
		locales:"FieldMeta",
		relevantBlogPosts:"FieldMeta"
	},
	Author:{
		_meta:"AuthorMeta",
		id:"UUID",
		locales:"AuthorLocale",
		blogPosts:"BlogPost",
		name:"String",
		profileUrl:"String",
		image:"Image",
		avatarImage:"Image",
		localesByLocale:"AuthorLocale",
		blogPostsByLocales:"BlogPost",
		blogPostsByRelevantBlogPosts:"BlogPost",
		paginateLocales:"AuthorLocaleConnection",
		paginateBlogPosts:"BlogPostConnection"
	},
	AuthorMeta:{
		id:"FieldMeta",
		locales:"FieldMeta",
		blogPosts:"FieldMeta",
		name:"FieldMeta",
		profileUrl:"FieldMeta",
		image:"FieldMeta",
		avatarImage:"FieldMeta"
	},
	AuthorLocale:{
		_meta:"AuthorLocaleMeta",
		id:"UUID",
		role:"String",
		bio:"String",
		root:"Author",
		locale:"Locale"
	},
	AuthorLocaleMeta:{
		id:"FieldMeta",
		role:"FieldMeta",
		bio:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	AuthorLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"AuthorLocaleEdge"
	},
	AuthorLocaleEdge:{
		node:"AuthorLocale"
	},
	BlogPostConnection:{
		pageInfo:"PageInfo",
		edges:"BlogPostEdge"
	},
	BlogPostEdge:{
		node:"BlogPost"
	},
	RelevantBlogPostList:{
		_meta:"RelevantBlogPostListMeta",
		id:"UUID",
		order:"Int",
		blogPost:"BlogPost",
		relevantBlogPost:"BlogPost"
	},
	RelevantBlogPostListMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		blogPost:"FieldMeta",
		relevantBlogPost:"FieldMeta"
	},
	BlogPostLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"BlogPostLocaleEdge"
	},
	BlogPostLocaleEdge:{
		node:"BlogPostLocale"
	},
	RelevantBlogPostListConnection:{
		pageInfo:"PageInfo",
		edges:"RelevantBlogPostListEdge"
	},
	RelevantBlogPostListEdge:{
		node:"RelevantBlogPostList"
	},
	CaseStudyList:{
		_meta:"CaseStudyListMeta",
		id:"UUID",
		items:"CaseStudyListItem",
		paginateItems:"CaseStudyListItemConnection"
	},
	CaseStudyListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	CaseStudyListItem:{
		_meta:"CaseStudyListItemMeta",
		id:"UUID",
		order:"Int",
		list:"CaseStudyList",
		caseStudy:"CaseStudy"
	},
	CaseStudyListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		caseStudy:"FieldMeta"
	},
	CaseStudyListItemConnection:{
		pageInfo:"PageInfo",
		edges:"CaseStudyListItemEdge"
	},
	CaseStudyListItemEdge:{
		node:"CaseStudyListItem"
	},
	ImageList:{
		_meta:"ImageListMeta",
		id:"UUID",
		items:"ImageListItem",
		itemsByImage:"ImageListItem",
		paginateItems:"ImageListItemConnection"
	},
	ImageListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	ImageListItem:{
		_meta:"ImageListItemMeta",
		id:"UUID",
		order:"Int",
		list:"ImageList",
		image:"Image"
	},
	ImageListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		image:"FieldMeta"
	},
	ImageListItemConnection:{
		pageInfo:"PageInfo",
		edges:"ImageListItemEdge"
	},
	ImageListItemEdge:{
		node:"ImageListItem"
	},
	InformationList:{
		_meta:"InformationListMeta",
		id:"UUID",
		items:"PieceOfInformation",
		paginateItems:"PieceOfInformationConnection"
	},
	InformationListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	PieceOfInformation:{
		_meta:"PieceOfInformationMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		description:"String",
		list:"InformationList"
	},
	PieceOfInformationMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		title:"FieldMeta",
		description:"FieldMeta",
		list:"FieldMeta"
	},
	PieceOfInformationConnection:{
		pageInfo:"PageInfo",
		edges:"PieceOfInformationEdge"
	},
	PieceOfInformationEdge:{
		node:"PieceOfInformation"
	},
	Embed:{
		_meta:"EmbedMeta",
		id:"UUID",
		type:"EmbedType",
		embedId:"String"
	},
	EmbedMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		embedId:"FieldMeta"
	},
	LinkList:{
		_meta:"LinkListMeta",
		id:"UUID",
		items:"LinkListItem",
		itemsByLink:"LinkListItem",
		paginateItems:"LinkListItemConnection"
	},
	LinkListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	LinkListItem:{
		_meta:"LinkListItemMeta",
		id:"UUID",
		order:"Int",
		list:"LinkList",
		link:"Link"
	},
	LinkListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		link:"FieldMeta"
	},
	LinkListItemConnection:{
		pageInfo:"PageInfo",
		edges:"LinkListItemEdge"
	},
	LinkListItemEdge:{
		node:"LinkListItem"
	},
	TalkLists:{
		_meta:"TalkListsMeta",
		id:"UUID",
		items:"TalkList",
		itemsByItems:"TalkList",
		paginateItems:"TalkListConnection"
	},
	TalkListsMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	TalkList:{
		_meta:"TalkListMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		list:"TalkLists",
		items:"TalkListItem",
		paginateItems:"TalkListItemConnection"
	},
	TalkListMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		title:"FieldMeta",
		list:"FieldMeta",
		items:"FieldMeta"
	},
	TalkListItem:{
		_meta:"TalkListItemMeta",
		id:"UUID",
		order:"Int",
		list:"TalkList",
		talk:"Talk"
	},
	TalkListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		talk:"FieldMeta"
	},
	Talk:{
		_meta:"TalkMeta",
		id:"UUID",
		title:"String",
		slug:"String",
		date:"DateTime",
		note:"String",
		content:"String",
		coverImage:"Image",
		otherImage:"Image"
	},
	TalkMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		slug:"FieldMeta",
		date:"FieldMeta",
		note:"FieldMeta",
		content:"FieldMeta",
		coverImage:"FieldMeta",
		otherImage:"FieldMeta"
	},
	TalkListItemConnection:{
		pageInfo:"PageInfo",
		edges:"TalkListItemEdge"
	},
	TalkListItemEdge:{
		node:"TalkListItem"
	},
	TalkListConnection:{
		pageInfo:"PageInfo",
		edges:"TalkListEdge"
	},
	TalkListEdge:{
		node:"TalkList"
	},
	BusinessCardList:{
		_meta:"BusinessCardListMeta",
		id:"UUID",
		items:"BusinessCardListItem",
		itemsByBusinessCard:"BusinessCardListItem",
		paginateItems:"BusinessCardListItemConnection"
	},
	BusinessCardListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	BusinessCardListItem:{
		_meta:"BusinessCardListItemMeta",
		id:"UUID",
		order:"Int",
		list:"BusinessCardList",
		businessCard:"BusinessCard"
	},
	BusinessCardListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		businessCard:"FieldMeta"
	},
	BusinessCard:{
		_meta:"BusinessCardMeta",
		id:"UUID",
		name:"String",
		role:"String",
		email:"String",
		avatar:"Image",
		phone:"String"
	},
	BusinessCardMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		role:"FieldMeta",
		email:"FieldMeta",
		avatar:"FieldMeta",
		phone:"FieldMeta"
	},
	BusinessCardListItemConnection:{
		pageInfo:"PageInfo",
		edges:"BusinessCardListItemEdge"
	},
	BusinessCardListItemEdge:{
		node:"BusinessCardListItem"
	},
	TestimonialList:{
		_meta:"TestimonialListMeta",
		id:"UUID",
		items:"TestimonialListItem",
		itemsByTestimonial:"TestimonialListItem",
		paginateItems:"TestimonialListItemConnection"
	},
	TestimonialListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	TestimonialListItem:{
		_meta:"TestimonialListItemMeta",
		id:"UUID",
		order:"Int",
		list:"TestimonialList",
		testimonial:"Testimonial"
	},
	TestimonialListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		testimonial:"FieldMeta"
	},
	Testimonial:{
		_meta:"TestimonialMeta",
		id:"UUID",
		content:"String",
		note:"String",
		avatar:"Image"
	},
	TestimonialMeta:{
		id:"FieldMeta",
		content:"FieldMeta",
		note:"FieldMeta",
		avatar:"FieldMeta"
	},
	TestimonialListItemConnection:{
		pageInfo:"PageInfo",
		edges:"TestimonialListItemEdge"
	},
	TestimonialListItemEdge:{
		node:"TestimonialListItem"
	},
	ContactRequestOptionList:{
		_meta:"ContactRequestOptionListMeta",
		id:"UUID",
		items:"ContactRequestOption",
		paginateItems:"ContactRequestOptionConnection"
	},
	ContactRequestOptionListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	ContactRequestOption:{
		_meta:"ContactRequestOptionMeta",
		id:"UUID",
		order:"Int",
		label:"String",
		description:"String",
		list:"ContactRequestOptionList",
		code:"String",
		type:"ContactRequestOptionType",
		subscribeForm:"SubscribeForm"
	},
	ContactRequestOptionMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		label:"FieldMeta",
		description:"FieldMeta",
		list:"FieldMeta",
		code:"FieldMeta",
		type:"FieldMeta",
		subscribeForm:"FieldMeta"
	},
	SubscribeForm:{
		_meta:"SubscribeFormMeta",
		id:"UUID",
		name:"String",
		datacruitJobId:"Int",
		datacruitReferenceNumber:"String",
		lastUpdateAt:"DateTime"
	},
	SubscribeFormMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		datacruitJobId:"FieldMeta",
		datacruitReferenceNumber:"FieldMeta",
		lastUpdateAt:"FieldMeta"
	},
	ContactRequestOptionConnection:{
		pageInfo:"PageInfo",
		edges:"ContactRequestOptionEdge"
	},
	ContactRequestOptionEdge:{
		node:"ContactRequestOption"
	},
	YoutubeVideo:{
		_meta:"YoutubeVideoMeta",
		id:"UUID",
		videoId:"String"
	},
	YoutubeVideoMeta:{
		id:"FieldMeta",
		videoId:"FieldMeta"
	},
	Video:{
		_meta:"VideoMeta",
		id:"UUID",
		src:"String",
		height:"Int",
		width:"Int",
		duration:"Float",
		size:"Int",
		type:"String"
	},
	VideoMeta:{
		id:"FieldMeta",
		src:"FieldMeta",
		height:"FieldMeta",
		width:"FieldMeta",
		duration:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta"
	},
	ContentReferencePositionCategoryItem:{
		_meta:"ContentReferencePositionCategoryItemMeta",
		id:"UUID",
		order:"Int",
		item:"PositionCategory",
		reference:"ContentReference"
	},
	ContentReferencePositionCategoryItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		item:"FieldMeta",
		reference:"FieldMeta"
	},
	ContentReferenceCaseStudyItem:{
		_meta:"ContentReferenceCaseStudyItemMeta",
		id:"UUID",
		order:"Int",
		item:"CaseStudy",
		reference:"ContentReference"
	},
	ContentReferenceCaseStudyItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		item:"FieldMeta",
		reference:"FieldMeta"
	},
	ContentReferenceTagItem:{
		_meta:"ContentReferenceTagItemMeta",
		id:"UUID",
		order:"Int",
		item:"Tag",
		reference:"ContentReference"
	},
	ContentReferenceTagItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		item:"FieldMeta",
		reference:"FieldMeta"
	},
	PositionList:{
		_meta:"PositionListMeta",
		id:"UUID",
		items:"PositionListItem",
		paginateItems:"PositionListItemConnection"
	},
	PositionListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	PositionListItem:{
		_meta:"PositionListItemMeta",
		id:"UUID",
		order:"Int",
		list:"PositionList",
		position:"PositionLocale"
	},
	PositionListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		position:"FieldMeta"
	},
	PositionListItemConnection:{
		pageInfo:"PageInfo",
		edges:"PositionListItemEdge"
	},
	PositionListItemEdge:{
		node:"PositionListItem"
	},
	TestimonialFullList:{
		_meta:"TestimonialFullListMeta",
		id:"UUID",
		items:"TestimonialFullListItem",
		itemsByTestimonial:"TestimonialFullListItem",
		paginateItems:"TestimonialFullListItemConnection"
	},
	TestimonialFullListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	TestimonialFullListItem:{
		_meta:"TestimonialFullListItemMeta",
		id:"UUID",
		order:"Int",
		list:"TestimonialFullList",
		testimonial:"TestimonialFull"
	},
	TestimonialFullListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		testimonial:"FieldMeta"
	},
	TestimonialFull:{
		_meta:"TestimonialFullMeta",
		id:"UUID",
		content:"String",
		name:"String",
		note:"String",
		avatar:"Image",
		imageVariant:"ImageVariant"
	},
	TestimonialFullMeta:{
		id:"FieldMeta",
		content:"FieldMeta",
		name:"FieldMeta",
		note:"FieldMeta",
		avatar:"FieldMeta",
		imageVariant:"FieldMeta"
	},
	TestimonialFullListItemConnection:{
		pageInfo:"PageInfo",
		edges:"TestimonialFullListItemEdge"
	},
	TestimonialFullListItemEdge:{
		node:"TestimonialFullListItem"
	},
	ContentReferenceBlogPostItem:{
		_meta:"ContentReferenceBlogPostItemMeta",
		id:"UUID",
		publishedAt:"Date",
		item:"BlogPost",
		reference:"ContentReference"
	},
	ContentReferenceBlogPostItemMeta:{
		id:"FieldMeta",
		publishedAt:"FieldMeta",
		item:"FieldMeta",
		reference:"FieldMeta"
	},
	ContentReferencePositionCategoryItemConnection:{
		pageInfo:"PageInfo",
		edges:"ContentReferencePositionCategoryItemEdge"
	},
	ContentReferencePositionCategoryItemEdge:{
		node:"ContentReferencePositionCategoryItem"
	},
	ContentReferenceCaseStudyItemConnection:{
		pageInfo:"PageInfo",
		edges:"ContentReferenceCaseStudyItemEdge"
	},
	ContentReferenceCaseStudyItemEdge:{
		node:"ContentReferenceCaseStudyItem"
	},
	ContentReferenceTagItemConnection:{
		pageInfo:"PageInfo",
		edges:"ContentReferenceTagItemEdge"
	},
	ContentReferenceTagItemEdge:{
		node:"ContentReferenceTagItem"
	},
	ContentReferenceBlogPostItemConnection:{
		pageInfo:"PageInfo",
		edges:"ContentReferenceBlogPostItemEdge"
	},
	ContentReferenceBlogPostItemEdge:{
		node:"ContentReferenceBlogPostItem"
	},
	ContentReferenceConnection:{
		pageInfo:"PageInfo",
		edges:"ContentReferenceEdge"
	},
	ContentReferenceEdge:{
		node:"ContentReference"
	},
	ContentBlockConnection:{
		pageInfo:"PageInfo",
		edges:"ContentBlockEdge"
	},
	ContentBlockEdge:{
		node:"ContentBlock"
	},
	HeaderLocale:{
		_meta:"HeaderLocaleMeta",
		id:"UUID",
		email:"String",
		root:"Header",
		locale:"Locale",
		linkList:"LinkList",
		socialLinkList:"LinkList",
		topLevelLinkItems:"TopLevelLinkItem",
		topLevelLinkItemsBySecondLevelLinkItems:"TopLevelLinkItem",
		topLevelLinkItemsByLink:"TopLevelLinkItem",
		paginateTopLevelLinkItems:"TopLevelLinkItemConnection"
	},
	HeaderLocaleMeta:{
		id:"FieldMeta",
		email:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		linkList:"FieldMeta",
		socialLinkList:"FieldMeta",
		topLevelLinkItems:"FieldMeta"
	},
	Header:{
		_meta:"HeaderMeta",
		id:"UUID",
		unique:"One",
		locales:"HeaderLocale",
		localesByLocale:"HeaderLocale",
		localesByLinkList:"HeaderLocale",
		localesBySocialLinkList:"HeaderLocale",
		localesByTopLevelLinkItems:"HeaderLocale",
		paginateLocales:"HeaderLocaleConnection"
	},
	HeaderMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta"
	},
	HeaderLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"HeaderLocaleEdge"
	},
	HeaderLocaleEdge:{
		node:"HeaderLocale"
	},
	TopLevelLinkItem:{
		_meta:"TopLevelLinkItemMeta",
		id:"UUID",
		isLink:"IsLink",
		order:"Int",
		title:"String",
		secondLevelLinkItems:"SecondLevelLinkItem",
		header:"HeaderLocale",
		link:"Link",
		secondLevelLinkItemsByLink:"SecondLevelLinkItem",
		secondLevelLinkItemsByThirdLevelLinkItems:"SecondLevelLinkItem",
		paginateSecondLevelLinkItems:"SecondLevelLinkItemConnection"
	},
	TopLevelLinkItemMeta:{
		id:"FieldMeta",
		isLink:"FieldMeta",
		order:"FieldMeta",
		title:"FieldMeta",
		secondLevelLinkItems:"FieldMeta",
		header:"FieldMeta",
		link:"FieldMeta"
	},
	SecondLevelLinkItem:{
		_meta:"SecondLevelLinkItemMeta",
		id:"UUID",
		isLink:"IsLink",
		order:"Int",
		title:"String",
		topLevelLinkItem:"TopLevelLinkItem",
		link:"Link",
		thirdLevelLinkItems:"ThirdLevelLinkItem",
		thirdLevelLinkItemsByLink:"ThirdLevelLinkItem",
		paginateThirdLevelLinkItems:"ThirdLevelLinkItemConnection"
	},
	SecondLevelLinkItemMeta:{
		id:"FieldMeta",
		isLink:"FieldMeta",
		order:"FieldMeta",
		title:"FieldMeta",
		topLevelLinkItem:"FieldMeta",
		link:"FieldMeta",
		thirdLevelLinkItems:"FieldMeta"
	},
	ThirdLevelLinkItem:{
		_meta:"ThirdLevelLinkItemMeta",
		id:"UUID",
		order:"Int",
		secondLevelLinkItem:"SecondLevelLinkItem",
		link:"Link"
	},
	ThirdLevelLinkItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		secondLevelLinkItem:"FieldMeta",
		link:"FieldMeta"
	},
	ThirdLevelLinkItemConnection:{
		pageInfo:"PageInfo",
		edges:"ThirdLevelLinkItemEdge"
	},
	ThirdLevelLinkItemEdge:{
		node:"ThirdLevelLinkItem"
	},
	SecondLevelLinkItemConnection:{
		pageInfo:"PageInfo",
		edges:"SecondLevelLinkItemEdge"
	},
	SecondLevelLinkItemEdge:{
		node:"SecondLevelLinkItem"
	},
	TopLevelLinkItemConnection:{
		pageInfo:"PageInfo",
		edges:"TopLevelLinkItemEdge"
	},
	TopLevelLinkItemEdge:{
		node:"TopLevelLinkItem"
	},
	FooterLocale:{
		_meta:"FooterLocaleMeta",
		id:"UUID",
		email:"String",
		root:"Footer",
		locale:"Locale",
		address:"String",
		phone:"String",
		legal:"String",
		navigation:"LinkList"
	},
	FooterLocaleMeta:{
		id:"FieldMeta",
		email:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		address:"FieldMeta",
		phone:"FieldMeta",
		legal:"FieldMeta",
		navigation:"FieldMeta"
	},
	Footer:{
		_meta:"FooterMeta",
		id:"UUID",
		unique:"One",
		locales:"FooterLocale",
		localesByLocale:"FooterLocale",
		localesByNavigation:"FooterLocale",
		paginateLocales:"FooterLocaleConnection"
	},
	FooterMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta"
	},
	FooterLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"FooterLocaleEdge"
	},
	FooterLocaleEdge:{
		node:"FooterLocale"
	},
	BlogPostCommonLocale:{
		_meta:"BlogPostCommonLocaleMeta",
		id:"UUID",
		root:"BlogPostCommon",
		locale:"Locale",
		ctaLink:"Link",
		ctaTitle:"String"
	},
	BlogPostCommonLocaleMeta:{
		id:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		ctaLink:"FieldMeta",
		ctaTitle:"FieldMeta"
	},
	BlogPostCommon:{
		_meta:"BlogPostCommonMeta",
		id:"UUID",
		unique:"One",
		locales:"BlogPostCommonLocale",
		localesByLocale:"BlogPostCommonLocale",
		paginateLocales:"BlogPostCommonLocaleConnection"
	},
	BlogPostCommonMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta"
	},
	BlogPostCommonLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"BlogPostCommonLocaleEdge"
	},
	BlogPostCommonLocaleEdge:{
		node:"BlogPostCommonLocale"
	},
	GeneralLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"GeneralLocaleEdge"
	},
	GeneralLocaleEdge:{
		node:"GeneralLocale"
	},
	GeneralConnection:{
		pageInfo:"PageInfo",
		edges:"GeneralEdge"
	},
	GeneralEdge:{
		node:"General"
	},
	ImageConnection:{
		pageInfo:"PageInfo",
		edges:"ImageEdge"
	},
	ImageEdge:{
		node:"Image"
	},
	GenericPageConnection:{
		pageInfo:"PageInfo",
		edges:"GenericPageEdge"
	},
	GenericPageEdge:{
		node:"GenericPage"
	},
	LinkableConnection:{
		pageInfo:"PageInfo",
		edges:"LinkableEdge"
	},
	LinkableEdge:{
		node:"Linkable"
	},
	SeoConnection:{
		pageInfo:"PageInfo",
		edges:"SeoEdge"
	},
	SeoEdge:{
		node:"Seo"
	},
	ContentConnection:{
		pageInfo:"PageInfo",
		edges:"ContentEdge"
	},
	ContentEdge:{
		node:"Content"
	},
	LocaleConnection:{
		pageInfo:"PageInfo",
		edges:"LocaleEdge"
	},
	LocaleEdge:{
		node:"Locale"
	},
	LinkConnection:{
		pageInfo:"PageInfo",
		edges:"LinkEdge"
	},
	LinkEdge:{
		node:"Link"
	},
	LinkListConnection:{
		pageInfo:"PageInfo",
		edges:"LinkListEdge"
	},
	LinkListEdge:{
		node:"LinkList"
	},
	HeaderConnection:{
		pageInfo:"PageInfo",
		edges:"HeaderEdge"
	},
	HeaderEdge:{
		node:"Header"
	},
	FooterConnection:{
		pageInfo:"PageInfo",
		edges:"FooterEdge"
	},
	FooterEdge:{
		node:"Footer"
	},
	CaseStudyConnection:{
		pageInfo:"PageInfo",
		edges:"CaseStudyEdge"
	},
	CaseStudyEdge:{
		node:"CaseStudy"
	},
	TagConnection:{
		pageInfo:"PageInfo",
		edges:"TagEdge"
	},
	TagEdge:{
		node:"Tag"
	},
	CaseStudyListConnection:{
		pageInfo:"PageInfo",
		edges:"CaseStudyListEdge"
	},
	CaseStudyListEdge:{
		node:"CaseStudyList"
	},
	ImageListConnection:{
		pageInfo:"PageInfo",
		edges:"ImageListEdge"
	},
	ImageListEdge:{
		node:"ImageList"
	},
	InformationListConnection:{
		pageInfo:"PageInfo",
		edges:"InformationListEdge"
	},
	InformationListEdge:{
		node:"InformationList"
	},
	PositionTagConnection:{
		pageInfo:"PageInfo",
		edges:"PositionTagEdge"
	},
	PositionTagEdge:{
		node:"PositionTag"
	},
	PositionTagListConnection:{
		pageInfo:"PageInfo",
		edges:"PositionTagListEdge"
	},
	PositionTagListEdge:{
		node:"PositionTagList"
	},
	PositionCategoryConnection:{
		pageInfo:"PageInfo",
		edges:"PositionCategoryEdge"
	},
	PositionCategoryEdge:{
		node:"PositionCategory"
	},
	EmbedConnection:{
		pageInfo:"PageInfo",
		edges:"EmbedEdge"
	},
	EmbedEdge:{
		node:"Embed"
	},
	RedirectConnection:{
		pageInfo:"PageInfo",
		edges:"RedirectEdge"
	},
	RedirectEdge:{
		node:"Redirect"
	},
	TalkConnection:{
		pageInfo:"PageInfo",
		edges:"TalkEdge"
	},
	TalkEdge:{
		node:"Talk"
	},
	TalkListsConnection:{
		pageInfo:"PageInfo",
		edges:"TalkListsEdge"
	},
	TalkListsEdge:{
		node:"TalkLists"
	},
	BusinessCardConnection:{
		pageInfo:"PageInfo",
		edges:"BusinessCardEdge"
	},
	BusinessCardEdge:{
		node:"BusinessCard"
	},
	BusinessCardListConnection:{
		pageInfo:"PageInfo",
		edges:"BusinessCardListEdge"
	},
	BusinessCardListEdge:{
		node:"BusinessCardList"
	},
	TestimonialConnection:{
		pageInfo:"PageInfo",
		edges:"TestimonialEdge"
	},
	TestimonialEdge:{
		node:"Testimonial"
	},
	TestimonialListConnection:{
		pageInfo:"PageInfo",
		edges:"TestimonialListEdge"
	},
	TestimonialListEdge:{
		node:"TestimonialList"
	},
	ContactRequestOptionListConnection:{
		pageInfo:"PageInfo",
		edges:"ContactRequestOptionListEdge"
	},
	ContactRequestOptionListEdge:{
		node:"ContactRequestOptionList"
	},
	YoutubeVideoConnection:{
		pageInfo:"PageInfo",
		edges:"YoutubeVideoEdge"
	},
	YoutubeVideoEdge:{
		node:"YoutubeVideo"
	},
	YoutubeVideoList:{
		_meta:"YoutubeVideoListMeta",
		id:"UUID",
		items:"YoutubeVideoListItem",
		itemsByYoutube:"YoutubeVideoListItem",
		paginateItems:"YoutubeVideoListItemConnection"
	},
	YoutubeVideoListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	YoutubeVideoListItem:{
		_meta:"YoutubeVideoListItemMeta",
		id:"UUID",
		order:"Int",
		list:"YoutubeVideoList",
		youtube:"YoutubeVideo"
	},
	YoutubeVideoListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		youtube:"FieldMeta"
	},
	YoutubeVideoListItemConnection:{
		pageInfo:"PageInfo",
		edges:"YoutubeVideoListItemEdge"
	},
	YoutubeVideoListItemEdge:{
		node:"YoutubeVideoListItem"
	},
	YoutubeVideoListConnection:{
		pageInfo:"PageInfo",
		edges:"YoutubeVideoListEdge"
	},
	YoutubeVideoListEdge:{
		node:"YoutubeVideoList"
	},
	TranslationCatalogue:{
		_meta:"TranslationCatalogueMeta",
		id:"UUID",
		domain:"TranslationDomain",
		identifier:"TranslationCataloguesIdentifier",
		fallback:"TranslationCatalogue",
		values:"TranslationValue",
		valuesByKey:"TranslationValue",
		paginateValues:"TranslationValueConnection"
	},
	TranslationCatalogueMeta:{
		id:"FieldMeta",
		domain:"FieldMeta",
		identifier:"FieldMeta",
		fallback:"FieldMeta",
		values:"FieldMeta"
	},
	TranslationDomain:{
		_meta:"TranslationDomainMeta",
		id:"UUID",
		identifier:"String",
		name:"String",
		catalogues:"TranslationCatalogue",
		keys:"TranslationKey",
		cataloguesByIdentifier:"TranslationCatalogue",
		cataloguesByValues:"TranslationCatalogue",
		keysByIdentifier:"TranslationKey",
		keysByValues:"TranslationKey",
		paginateCatalogues:"TranslationCatalogueConnection",
		paginateKeys:"TranslationKeyConnection"
	},
	TranslationDomainMeta:{
		id:"FieldMeta",
		identifier:"FieldMeta",
		name:"FieldMeta",
		catalogues:"FieldMeta",
		keys:"FieldMeta"
	},
	TranslationKey:{
		_meta:"TranslationKeyMeta",
		id:"UUID",
		identifier:"String",
		contentType:"TranslationContentType",
		note:"String",
		domain:"TranslationDomain",
		values:"TranslationValue",
		valuesByCatalogue:"TranslationValue",
		paginateValues:"TranslationValueConnection"
	},
	TranslationKeyMeta:{
		id:"FieldMeta",
		identifier:"FieldMeta",
		contentType:"FieldMeta",
		note:"FieldMeta",
		domain:"FieldMeta",
		values:"FieldMeta"
	},
	TranslationValue:{
		_meta:"TranslationValueMeta",
		id:"UUID",
		value:"String",
		catalogue:"TranslationCatalogue",
		key:"TranslationKey"
	},
	TranslationValueMeta:{
		id:"FieldMeta",
		value:"FieldMeta",
		catalogue:"FieldMeta",
		key:"FieldMeta"
	},
	TranslationValueConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationValueEdge"
	},
	TranslationValueEdge:{
		node:"TranslationValue"
	},
	TranslationCatalogueConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationCatalogueEdge"
	},
	TranslationCatalogueEdge:{
		node:"TranslationCatalogue"
	},
	TranslationKeyConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationKeyEdge"
	},
	TranslationKeyEdge:{
		node:"TranslationKey"
	},
	TranslationCataloguesIdentifier:{
		_meta:"TranslationCataloguesIdentifierMeta",
		id:"UUID",
		name:"String",
		code:"String",
		catalogue:"TranslationCatalogue",
		catalogueByDomain:"TranslationCatalogue",
		catalogueByValues:"TranslationCatalogue",
		paginateCatalogue:"TranslationCatalogueConnection"
	},
	TranslationCataloguesIdentifierMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		catalogue:"FieldMeta"
	},
	TranslationCataloguesIdentifierConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationCataloguesIdentifierEdge"
	},
	TranslationCataloguesIdentifierEdge:{
		node:"TranslationCataloguesIdentifier"
	},
	TranslationDomainConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationDomainEdge"
	},
	TranslationDomainEdge:{
		node:"TranslationDomain"
	},
	_ValidationResult:{
		valid:"Boolean",
		errors:"_ValidationError"
	},
	_ValidationError:{
		path:"_PathFragment",
		message:"_ValidationMessage"
	},
	_PathFragment:{
		"...on _FieldPathFragment":"_FieldPathFragment",
		"...on _IndexPathFragment":"_IndexPathFragment"
	},
	_FieldPathFragment:{
		field:"String"
	},
	_IndexPathFragment:{
		index:"Int",
		alias:"String"
	},
	_ValidationMessage:{
		text:"String"
	},
	SubscribeFormConnection:{
		pageInfo:"PageInfo",
		edges:"SubscribeFormEdge"
	},
	SubscribeFormEdge:{
		node:"SubscribeForm"
	},
	VideoConnection:{
		pageInfo:"PageInfo",
		edges:"VideoEdge"
	},
	VideoEdge:{
		node:"Video"
	},
	PositionListConnection:{
		pageInfo:"PageInfo",
		edges:"PositionListEdge"
	},
	PositionListEdge:{
		node:"PositionList"
	},
	TestimonialFullConnection:{
		pageInfo:"PageInfo",
		edges:"TestimonialFullEdge"
	},
	TestimonialFullEdge:{
		node:"TestimonialFull"
	},
	TestimonialFullListConnection:{
		pageInfo:"PageInfo",
		edges:"TestimonialFullListEdge"
	},
	TestimonialFullListEdge:{
		node:"TestimonialFullList"
	},
	AuthorConnection:{
		pageInfo:"PageInfo",
		edges:"AuthorEdge"
	},
	AuthorEdge:{
		node:"Author"
	},
	BlogPostCommonConnection:{
		pageInfo:"PageInfo",
		edges:"BlogPostCommonEdge"
	},
	BlogPostCommonEdge:{
		node:"BlogPostCommon"
	},
	QueryTransaction:{
		schema:"_Schema",
		s3DummyQuery:"String",
		getGeneral:"General",
		listGeneral:"General",
		paginateGeneral:"GeneralConnection",
		getImage:"Image",
		listImage:"Image",
		paginateImage:"ImageConnection",
		getGenericPage:"GenericPage",
		listGenericPage:"GenericPage",
		paginateGenericPage:"GenericPageConnection",
		getLinkable:"Linkable",
		listLinkable:"Linkable",
		paginateLinkable:"LinkableConnection",
		getSeo:"Seo",
		listSeo:"Seo",
		paginateSeo:"SeoConnection",
		getContent:"Content",
		listContent:"Content",
		paginateContent:"ContentConnection",
		getContentBlock:"ContentBlock",
		listContentBlock:"ContentBlock",
		paginateContentBlock:"ContentBlockConnection",
		getContentReference:"ContentReference",
		listContentReference:"ContentReference",
		paginateContentReference:"ContentReferenceConnection",
		getGeneralLocale:"GeneralLocale",
		listGeneralLocale:"GeneralLocale",
		paginateGeneralLocale:"GeneralLocaleConnection",
		getLocale:"Locale",
		listLocale:"Locale",
		paginateLocale:"LocaleConnection",
		getGenericPageLocale:"GenericPageLocale",
		listGenericPageLocale:"GenericPageLocale",
		paginateGenericPageLocale:"GenericPageLocaleConnection",
		getLink:"Link",
		listLink:"Link",
		paginateLink:"LinkConnection",
		getLinkList:"LinkList",
		listLinkList:"LinkList",
		paginateLinkList:"LinkListConnection",
		getLinkListItem:"LinkListItem",
		listLinkListItem:"LinkListItem",
		paginateLinkListItem:"LinkListItemConnection",
		getHeader:"Header",
		listHeader:"Header",
		paginateHeader:"HeaderConnection",
		getHeaderLocale:"HeaderLocale",
		listHeaderLocale:"HeaderLocale",
		paginateHeaderLocale:"HeaderLocaleConnection",
		getFooter:"Footer",
		listFooter:"Footer",
		paginateFooter:"FooterConnection",
		getFooterLocale:"FooterLocale",
		listFooterLocale:"FooterLocale",
		paginateFooterLocale:"FooterLocaleConnection",
		getCaseStudy:"CaseStudy",
		listCaseStudy:"CaseStudy",
		paginateCaseStudy:"CaseStudyConnection",
		getCaseStudyLocale:"CaseStudyLocale",
		listCaseStudyLocale:"CaseStudyLocale",
		paginateCaseStudyLocale:"CaseStudyLocaleConnection",
		getTag:"Tag",
		listTag:"Tag",
		paginateTag:"TagConnection",
		getTagLocale:"TagLocale",
		listTagLocale:"TagLocale",
		paginateTagLocale:"TagLocaleConnection",
		getCaseStudyTag:"CaseStudyTag",
		listCaseStudyTag:"CaseStudyTag",
		paginateCaseStudyTag:"CaseStudyTagConnection",
		getCaseStudyList:"CaseStudyList",
		listCaseStudyList:"CaseStudyList",
		paginateCaseStudyList:"CaseStudyListConnection",
		getCaseStudyListItem:"CaseStudyListItem",
		listCaseStudyListItem:"CaseStudyListItem",
		paginateCaseStudyListItem:"CaseStudyListItemConnection",
		getImageList:"ImageList",
		listImageList:"ImageList",
		paginateImageList:"ImageListConnection",
		getImageListItem:"ImageListItem",
		listImageListItem:"ImageListItem",
		paginateImageListItem:"ImageListItemConnection",
		getInformationList:"InformationList",
		listInformationList:"InformationList",
		paginateInformationList:"InformationListConnection",
		getPieceOfInformation:"PieceOfInformation",
		listPieceOfInformation:"PieceOfInformation",
		paginatePieceOfInformation:"PieceOfInformationConnection",
		getPositionTag:"PositionTag",
		listPositionTag:"PositionTag",
		paginatePositionTag:"PositionTagConnection",
		getPositionTagLocale:"PositionTagLocale",
		listPositionTagLocale:"PositionTagLocale",
		paginatePositionTagLocale:"PositionTagLocaleConnection",
		getPosition:"Position",
		listPosition:"Position",
		paginatePosition:"PositionConnection",
		getPositionLocale:"PositionLocale",
		listPositionLocale:"PositionLocale",
		paginatePositionLocale:"PositionLocaleConnection",
		getPositionTagList:"PositionTagList",
		listPositionTagList:"PositionTagList",
		paginatePositionTagList:"PositionTagListConnection",
		getPositionTagListItem:"PositionTagListItem",
		listPositionTagListItem:"PositionTagListItem",
		paginatePositionTagListItem:"PositionTagListItemConnection",
		getPositionCategory:"PositionCategory",
		listPositionCategory:"PositionCategory",
		paginatePositionCategory:"PositionCategoryConnection",
		getPositionCategoryLocale:"PositionCategoryLocale",
		listPositionCategoryLocale:"PositionCategoryLocale",
		paginatePositionCategoryLocale:"PositionCategoryLocaleConnection",
		getEmbed:"Embed",
		listEmbed:"Embed",
		paginateEmbed:"EmbedConnection",
		getRedirect:"Redirect",
		listRedirect:"Redirect",
		paginateRedirect:"RedirectConnection",
		getTalk:"Talk",
		listTalk:"Talk",
		paginateTalk:"TalkConnection",
		getTalkList:"TalkList",
		listTalkList:"TalkList",
		paginateTalkList:"TalkListConnection",
		getTalkListItem:"TalkListItem",
		listTalkListItem:"TalkListItem",
		paginateTalkListItem:"TalkListItemConnection",
		getTalkLists:"TalkLists",
		listTalkLists:"TalkLists",
		paginateTalkLists:"TalkListsConnection",
		getBusinessCard:"BusinessCard",
		listBusinessCard:"BusinessCard",
		paginateBusinessCard:"BusinessCardConnection",
		getBusinessCardList:"BusinessCardList",
		listBusinessCardList:"BusinessCardList",
		paginateBusinessCardList:"BusinessCardListConnection",
		getBusinessCardListItem:"BusinessCardListItem",
		listBusinessCardListItem:"BusinessCardListItem",
		paginateBusinessCardListItem:"BusinessCardListItemConnection",
		getTestimonial:"Testimonial",
		listTestimonial:"Testimonial",
		paginateTestimonial:"TestimonialConnection",
		getTestimonialList:"TestimonialList",
		listTestimonialList:"TestimonialList",
		paginateTestimonialList:"TestimonialListConnection",
		getTestimonialListItem:"TestimonialListItem",
		listTestimonialListItem:"TestimonialListItem",
		paginateTestimonialListItem:"TestimonialListItemConnection",
		getContactRequestOption:"ContactRequestOption",
		listContactRequestOption:"ContactRequestOption",
		paginateContactRequestOption:"ContactRequestOptionConnection",
		getContactRequestOptionList:"ContactRequestOptionList",
		listContactRequestOptionList:"ContactRequestOptionList",
		paginateContactRequestOptionList:"ContactRequestOptionListConnection",
		getYoutubeVideo:"YoutubeVideo",
		listYoutubeVideo:"YoutubeVideo",
		paginateYoutubeVideo:"YoutubeVideoConnection",
		getYoutubeVideoList:"YoutubeVideoList",
		listYoutubeVideoList:"YoutubeVideoList",
		paginateYoutubeVideoList:"YoutubeVideoListConnection",
		getYoutubeVideoListItem:"YoutubeVideoListItem",
		listYoutubeVideoListItem:"YoutubeVideoListItem",
		paginateYoutubeVideoListItem:"YoutubeVideoListItemConnection",
		getTranslationCatalogue:"TranslationCatalogue",
		listTranslationCatalogue:"TranslationCatalogue",
		paginateTranslationCatalogue:"TranslationCatalogueConnection",
		getTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		listTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		paginateTranslationCataloguesIdentifier:"TranslationCataloguesIdentifierConnection",
		getTranslationDomain:"TranslationDomain",
		listTranslationDomain:"TranslationDomain",
		paginateTranslationDomain:"TranslationDomainConnection",
		getTranslationKey:"TranslationKey",
		listTranslationKey:"TranslationKey",
		paginateTranslationKey:"TranslationKeyConnection",
		getTranslationValue:"TranslationValue",
		listTranslationValue:"TranslationValue",
		paginateTranslationValue:"TranslationValueConnection",
		validateCreateContactRequestSubmission:"_ValidationResult",
		getSubscribeForm:"SubscribeForm",
		listSubscribeForm:"SubscribeForm",
		paginateSubscribeForm:"SubscribeFormConnection",
		validateCreateSubscribeForm:"_ValidationResult",
		validateUpdateSubscribeForm:"_ValidationResult",
		validateCreateSubscribeSubmission:"_ValidationResult",
		getContentReferencePositionCategoryItem:"ContentReferencePositionCategoryItem",
		listContentReferencePositionCategoryItem:"ContentReferencePositionCategoryItem",
		paginateContentReferencePositionCategoryItem:"ContentReferencePositionCategoryItemConnection",
		getVideo:"Video",
		listVideo:"Video",
		paginateVideo:"VideoConnection",
		getContentReferenceCaseStudyItem:"ContentReferenceCaseStudyItem",
		listContentReferenceCaseStudyItem:"ContentReferenceCaseStudyItem",
		paginateContentReferenceCaseStudyItem:"ContentReferenceCaseStudyItemConnection",
		getContentReferenceTagItem:"ContentReferenceTagItem",
		listContentReferenceTagItem:"ContentReferenceTagItem",
		paginateContentReferenceTagItem:"ContentReferenceTagItemConnection",
		getPositionList:"PositionList",
		listPositionList:"PositionList",
		paginatePositionList:"PositionListConnection",
		getPositionListItem:"PositionListItem",
		listPositionListItem:"PositionListItem",
		paginatePositionListItem:"PositionListItemConnection",
		getTestimonialFull:"TestimonialFull",
		listTestimonialFull:"TestimonialFull",
		paginateTestimonialFull:"TestimonialFullConnection",
		getTestimonialFullList:"TestimonialFullList",
		listTestimonialFullList:"TestimonialFullList",
		paginateTestimonialFullList:"TestimonialFullListConnection",
		getTestimonialFullListItem:"TestimonialFullListItem",
		listTestimonialFullListItem:"TestimonialFullListItem",
		paginateTestimonialFullListItem:"TestimonialFullListItemConnection",
		getSecondLevelLinkItem:"SecondLevelLinkItem",
		listSecondLevelLinkItem:"SecondLevelLinkItem",
		paginateSecondLevelLinkItem:"SecondLevelLinkItemConnection",
		getThirdLevelLinkItem:"ThirdLevelLinkItem",
		listThirdLevelLinkItem:"ThirdLevelLinkItem",
		paginateThirdLevelLinkItem:"ThirdLevelLinkItemConnection",
		getTopLevelLinkItem:"TopLevelLinkItem",
		listTopLevelLinkItem:"TopLevelLinkItem",
		paginateTopLevelLinkItem:"TopLevelLinkItemConnection",
		getAuthor:"Author",
		listAuthor:"Author",
		paginateAuthor:"AuthorConnection",
		getAuthorLocale:"AuthorLocale",
		listAuthorLocale:"AuthorLocale",
		paginateAuthorLocale:"AuthorLocaleConnection",
		getBlogPost:"BlogPost",
		listBlogPost:"BlogPost",
		paginateBlogPost:"BlogPostConnection",
		getBlogPostLocale:"BlogPostLocale",
		listBlogPostLocale:"BlogPostLocale",
		paginateBlogPostLocale:"BlogPostLocaleConnection",
		getContentReferenceBlogPostItem:"ContentReferenceBlogPostItem",
		listContentReferenceBlogPostItem:"ContentReferenceBlogPostItem",
		paginateContentReferenceBlogPostItem:"ContentReferenceBlogPostItemConnection",
		getRelevantBlogPostList:"RelevantBlogPostList",
		listRelevantBlogPostList:"RelevantBlogPostList",
		paginateRelevantBlogPostList:"RelevantBlogPostListConnection",
		getBlogPostCommon:"BlogPostCommon",
		listBlogPostCommon:"BlogPostCommon",
		paginateBlogPostCommon:"BlogPostCommonConnection",
		getBlogPostCommonLocale:"BlogPostCommonLocale",
		listBlogPostCommonLocale:"BlogPostCommonLocale",
		paginateBlogPostCommonLocale:"BlogPostCommonLocaleConnection"
	},
	Info:{
		description:"String"
	},
	Mutation:{
		generateUploadUrl:"S3SignedUpload",
		generateReadUrl:"S3SignedRead",
		createContactRequestSubmission:"ContactRequestSubmissionCreateResult",
		createSubscribeForm:"SubscribeFormCreateResult",
		updateSubscribeForm:"SubscribeFormUpdateResult",
		upsertSubscribeForm:"SubscribeFormUpsertResult",
		createSubscribeSubmission:"SubscribeSubmissionCreateResult",
		transaction:"MutationTransaction",
		query:"Query"
	},
	S3SignedUpload:{
		url:"String",
		headers:"S3Header",
		method:"String",
		objectKey:"String",
		bucket:"String",
		publicUrl:"String"
	},
	S3Header:{
		key:"String",
		value:"String"
	},
	S3SignedRead:{
		url:"String",
		headers:"S3Header",
		method:"String",
		objectKey:"String",
		bucket:"String"
	},
	ContactRequestSubmissionCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		validation:"_ValidationResult"
	},
	MutationResult:{
		"...on ContactRequestSubmissionCreateResult": "ContactRequestSubmissionCreateResult",
		"...on SubscribeFormCreateResult": "SubscribeFormCreateResult",
		"...on SubscribeFormUpdateResult": "SubscribeFormUpdateResult",
		"...on SubscribeFormUpsertResult": "SubscribeFormUpsertResult",
		"...on SubscribeSubmissionCreateResult": "SubscribeSubmissionCreateResult",
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError"
	},
	_MutationError:{
		path:"_PathFragment",
		paths:"_PathFragment",
		type:"_MutationErrorType",
		message:"String"
	},
	SubscribeFormCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"SubscribeForm",
		validation:"_ValidationResult"
	},
	SubscribeFormUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"SubscribeForm",
		validation:"_ValidationResult"
	},
	SubscribeFormUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"SubscribeForm",
		validation:"_ValidationResult"
	},
	SubscribeSubmissionCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		validation:"_ValidationResult"
	},
	MutationTransaction:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		validation:"_ValidationResult",
		generateUploadUrl:"S3SignedUpload",
		generateReadUrl:"S3SignedRead",
		createContactRequestSubmission:"ContactRequestSubmissionCreateResult",
		createSubscribeForm:"SubscribeFormCreateResult",
		updateSubscribeForm:"SubscribeFormUpdateResult",
		upsertSubscribeForm:"SubscribeFormUpsertResult",
		createSubscribeSubmission:"SubscribeSubmissionCreateResult",
		query:"Query"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}


export const HOST="Specify host"


export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json();
};

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  options?: OperationOptions;
  scalars?: ScalarDefinition;
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = [],
  ): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars,
      })(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false, vars);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const keyForDirectives = o.__directives ?? '';
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`;
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`;
    }
    const varsString = vars.map((v) => `${v.name}: ${v.graphQLType}`).join(', ');
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`;
  };
  return ibb;
};

export const Thunder =
  (fn: FetchFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: Record<string, unknown> }) =>
    fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
      ops?.variables,
    ).then((data) => {
      if (graphqlOptions?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: graphqlOptions.scalars,
          ops: Ops,
        });
      }
      return data;
    }) as Promise<InputType<GraphQLTypes[R], Z, SCLR>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
  (fn: SubscriptionFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: ExtractVariables<Z> }) => {
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], SCLR>;
    if (returnedFunction?.on && graphqlOptions?.scalars) {
      const wrapped = returnedFunction.on;
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, SCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, SCLR>) => {
          if (graphqlOptions?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: graphqlOptions.scalars,
                ops: Ops,
              }),
            );
          }
          return fnToCall(data);
        });
    }
    return returnedFunction;
  };

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: {
    operationOptions?: OperationOptions;
    scalars?: ScalarDefinition;
  },
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars,
  })(operation, o as VType);

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS,
  },
});

export const ZeusScalars = ZeusSelect<ScalarCoders>();

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp,
}: {
  ops: O;
  response: any;
  returns: ReturnTypesType;
  scalars?: Record<string, ScalarResolver | undefined>;
  initialOp: keyof O;
  initialZeusQuery: InputValueType | VType;
}) => {
  if (!scalars) {
    return response;
  }
  const builder = PrepareScalarPaths({
    ops,
    returns,
  });

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery);
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })(initialOp as string, response, [ops[initialOp]]);
    return r;
  }
  return response;
};

export const traverseResponse = ({
  resolvers,
  scalarPaths,
}: {
  scalarPaths: { [x: string]: `scalar.${string}` };
  resolvers: {
    [x: string]: ScalarResolver | undefined;
  };
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map((eachO) => ibb(k, eachO, p));
    }
    if (o == null) {
      return o;
    }
    const scalarPathString = p.join(SEPARATOR);
    const currentScalarString = scalarPaths[scalarPathString];
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode;
      if (currentDecoder) {
        return currentDecoder(o);
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o;
    }
    return Object.fromEntries(Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])]));
  };
  return ibb;
};

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | `scalar.${string}`
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions = {
  operationName?: string;
};

export type ScalarCoder = Record<string, (s: unknown) => string>;

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders;
};

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return;
  }
  const oKey = mappedParts[0];
  const returnP1 = returns[oKey];
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]];
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns);
    }
    return undefined;
  }
  return returnP1 as `scalar.${string}` | undefined;
};

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true,
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return;
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey];
      const isScalar = ExtractScalar(extractionArray, returns);
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar,
        };
        return partOfTree;
      }
      return {};
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false);
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(alias, operationName, operation, p, pOriginals, false);
        })
        .reduce((a, b) => ({
          ...a,
          ...b,
        }));
    }
    const keyName = root ? ops[k] : k;
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) => {
        // Inline fragments shouldn't be added to the path as they aren't a field
        const isInlineFragment = originalKey.match(/^...\s*on/) != null;
        return ibb(
          k,
          k,
          v,
          isInlineFragment ? p : [...p, purifyGraphQLKey(keyName || k)],
          isInlineFragment ? pOriginals : [...pOriginals, purifyGraphQLKey(originalKey)],
          false,
        );
      })
      .reduce((a, b) => ({
        ...a,
        ...b,
      }));
  };
  return ibb;
};

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1;
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not';
      }
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not';
        }
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not';
    }
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      if (mappedParts.length < 2) return 'not';
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  scalars?: ScalarDefinition;
  vars: Array<{ name: string; graphQLType: string }>;
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR);
        const v = vars.find((v) => v.name === varName);
        if (!v) {
          vars.push({
            name: varName,
            graphQLType,
          });
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`,
            );
          }
        }
        return varName;
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p);
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.');
      const scalarKey = splittedScalar.join('.');
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a);
    }
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};

export const resolverFor = <X, T extends keyof ResolverInputTypes, Z extends keyof ResolverInputTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any, source?: any) => any;

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
export type ScalarDefinition = Record<string, ScalarResolver>;

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S;
type IsArray<T, U, SCLR extends ScalarDefinition> = T extends Array<infer R>
  ? InputType<R, U, SCLR>[]
  : InputType<T, U, SCLR>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>;

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> = FlattenArray<SRC> extends
  | ZEUS_INTERFACES
  | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
          : Record<string, unknown>
        : never;
    }[keyof DST] & {
      [P in keyof Omit<
        Pick<
          SRC,
          {
            [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
          }[keyof DST]
        >,
        '__typename'
      >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>;
    }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
        ? IsScalar<SRC[P], SCLR>
        : IsArray<SRC[P], DST[P], SCLR>;
    };

export type MapType<SRC, DST, SCLR extends ScalarDefinition> = SRC extends DeepAnify<DST>
  ? IsInterfaced<SRC, DST, SCLR>
  : never;
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>];
    } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
  : MapType<SRC, IsPayLoad<DST>, SCLR>;
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void;
  open: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>;

export type ScalarResolver = {
  encode?: (s: unknown) => string;
  decode?: (s: unknown) => unknown;
};

export type SelectionFunction<V> = <T>(t: T | V) => T;

type BuiltInVariableTypes = {
  ['String']: string;
  ['Int']: number;
  ['Float']: number;
  ['ID']: unknown;
  ['Boolean']: boolean;
};
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES;
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`;
type VR<T extends string> = VariableRequired<VariableRequired<T>>;

export type GraphQLVariableType = VR<AllVariableTypes>;

type ExtractVariableTypeString<T extends string> = T extends VR<infer R1>
  ? R1 extends VR<infer R2>
    ? R2 extends VR<infer R3>
      ? R3 extends VR<infer R4>
        ? R4 extends VR<infer R5>
          ? R5
          : R4
        : R3
      : R2
    : R1
  : T;

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
  ? NonNullable<DecomposeType<R, Type>>
  : Type | undefined;

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
  ? BuiltInVariableTypes[T]
  : any;

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>;

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K;
}[keyof T];

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>;
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>;

type OptionalKeys<T> = {
  [P in keyof T]?: T[P];
};

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>;

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name;
  ' __zeus_type': T;
};

export type ExtractVariables<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends [infer Inputs, infer Outputs]
  ? ExtractVariables<Inputs> & ExtractVariables<Outputs>
  : Query extends string | number | boolean
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export const START_VAR_NAME = `$ZEUS_VAR`;
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`;

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>;
};
type ZEUS_INTERFACES = GraphQLTypes["_Field"] | GraphQLTypes["MutationResult"]
export type ScalarCoders = {
	Json?: ScalarResolver;
	UUID?: ScalarResolver;
	DateTime?: ScalarResolver;
	Date?: ScalarResolver;
}
type ZEUS_UNIONS = GraphQLTypes["_Argument"] | GraphQLTypes["_PathFragment"]

export type ValueTypes = {
    ["_Column"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	enumName?:boolean | `@${string}`,
	defaultValue?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_Field"]:AliasType<{
		name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"];
		['...on _Column']?: Omit<ValueTypes["_Column"],keyof ValueTypes["_Field"]>;
		['...on _Relation']?: Omit<ValueTypes["_Relation"],keyof ValueTypes["_Field"]>;
		__typename?: boolean | `@${string}`
}>;
	["_Rule"]: AliasType<{
	message?:ValueTypes["_RuleMessage"],
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_RuleMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Validator"]: AliasType<{
	operation?:boolean | `@${string}`,
	arguments?:ValueTypes["_Argument"],
		__typename?: boolean | `@${string}`
}>;
	["_Argument"]: AliasType<{		["...on _ValidatorArgument"] : ValueTypes["_ValidatorArgument"],
		["...on _PathArgument"] : ValueTypes["_PathArgument"],
		["...on _LiteralArgument"] : ValueTypes["_LiteralArgument"]
		__typename?: boolean | `@${string}`
}>;
	["_ValidatorArgument"]: AliasType<{
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_PathArgument"]: AliasType<{
	path?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_LiteralArgument"]: AliasType<{
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Json custom scalar type */
["Json"]:unknown;
	["_Relation"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	side?:boolean | `@${string}`,
	targetEntity?:boolean | `@${string}`,
	ownedBy?:boolean | `@${string}`,
	inversedBy?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	onDelete?:boolean | `@${string}`,
	orphanRemoval?:boolean | `@${string}`,
	orderBy?:ValueTypes["_OrderBy"],
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_RelationSide"]:_RelationSide;
	["_OnDeleteBehaviour"]:_OnDeleteBehaviour;
	["_OrderBy"]: AliasType<{
	path?:boolean | `@${string}`,
	direction?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_OrderByDirection"]:_OrderByDirection;
	["Query"]: AliasType<{
	schema?:ValueTypes["_Schema"],
	s3DummyQuery?:boolean | `@${string}`,
getGeneral?: [{	by: ValueTypes["GeneralUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>},ValueTypes["General"]],
listGeneral?: [{	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["General"]],
paginateGeneral?: [{	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralConnection"]],
getImage?: [{	by: ValueTypes["ImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
listImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Image"]],
paginateImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageConnection"]],
getGenericPage?: [{	by: ValueTypes["GenericPageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPage"]],
listGenericPage?: [{	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPage"]],
paginateGenericPage?: [{	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageConnection"]],
getLinkable?: [{	by: ValueTypes["LinkableUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
listLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
paginateLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkableConnection"]],
getSeo?: [{	by: ValueTypes["SeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
listSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
paginateSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SeoConnection"]],
getContent?: [{	by: ValueTypes["ContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
listContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Content"]],
paginateContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentConnection"]],
getContentBlock?: [{	by: ValueTypes["ContentBlockUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ValueTypes["ContentReferenceUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
listContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceConnection"]],
getGeneralLocale?: [{	by: ValueTypes["GeneralLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
listGeneralLocale?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
paginateGeneralLocale?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocaleConnection"]],
getLocale?: [{	by: ValueTypes["LocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
listLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
paginateLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LocaleConnection"]],
getGenericPageLocale?: [{	by: ValueTypes["GenericPageLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
listGenericPageLocale?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
paginateGenericPageLocale?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocaleConnection"]],
getLink?: [{	by: ValueTypes["LinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
listLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Link"]],
paginateLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkConnection"]],
getLinkList?: [{	by: ValueTypes["LinkListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["LinkList"]],
listLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["LinkList"]],
paginateLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkListConnection"]],
getLinkListItem?: [{	by: ValueTypes["LinkListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["LinkListItem"]],
listLinkListItem?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["LinkListItem"]],
paginateLinkListItem?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkListItemConnection"]],
getHeader?: [{	by: ValueTypes["HeaderUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>},ValueTypes["Header"]],
listHeader?: [{	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Header"]],
paginateHeader?: [{	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderConnection"]],
getHeaderLocale?: [{	by: ValueTypes["HeaderLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
listHeaderLocale?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
paginateHeaderLocale?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocaleConnection"]],
getFooter?: [{	by: ValueTypes["FooterUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>},ValueTypes["Footer"]],
listFooter?: [{	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Footer"]],
paginateFooter?: [{	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterConnection"]],
getFooterLocale?: [{	by: ValueTypes["FooterLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
listFooterLocale?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
paginateFooterLocale?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocaleConnection"]],
getCaseStudy?: [{	by: ValueTypes["CaseStudyUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudy"]],
listCaseStudy?: [{	filter?: ValueTypes["CaseStudyWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudy"]],
paginateCaseStudy?: [{	filter?: ValueTypes["CaseStudyWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyConnection"]],
getCaseStudyLocale?: [{	by: ValueTypes["CaseStudyLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocale"]],
listCaseStudyLocale?: [{	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocale"]],
paginateCaseStudyLocale?: [{	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocaleConnection"]],
getTag?: [{	by: ValueTypes["TagUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TagWhere"] | undefined | null | Variable<any, string>},ValueTypes["Tag"]],
listTag?: [{	filter?: ValueTypes["TagWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Tag"]],
paginateTag?: [{	filter?: ValueTypes["TagWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TagConnection"]],
getTagLocale?: [{	by: ValueTypes["TagLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TagLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["TagLocale"]],
listTagLocale?: [{	filter?: ValueTypes["TagLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TagLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TagLocale"]],
paginateTagLocale?: [{	filter?: ValueTypes["TagLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TagLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TagLocaleConnection"]],
getCaseStudyTag?: [{	by: ValueTypes["CaseStudyTagUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyTagWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyTag"]],
listCaseStudyTag?: [{	filter?: ValueTypes["CaseStudyTagWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyTagOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyTag"]],
paginateCaseStudyTag?: [{	filter?: ValueTypes["CaseStudyTagWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyTagOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyTagConnection"]],
getCaseStudyList?: [{	by: ValueTypes["CaseStudyListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyListWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyList"]],
listCaseStudyList?: [{	filter?: ValueTypes["CaseStudyListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyList"]],
paginateCaseStudyList?: [{	filter?: ValueTypes["CaseStudyListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyListConnection"]],
getCaseStudyListItem?: [{	by: ValueTypes["CaseStudyListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyListItem"]],
listCaseStudyListItem?: [{	filter?: ValueTypes["CaseStudyListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyListItem"]],
paginateCaseStudyListItem?: [{	filter?: ValueTypes["CaseStudyListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyListItemConnection"]],
getImageList?: [{	by: ValueTypes["ImageListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
listImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
paginateImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListConnection"]],
getImageListItem?: [{	by: ValueTypes["ImageListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItemConnection"]],
getInformationList?: [{	by: ValueTypes["InformationListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["InformationListWhere"] | undefined | null | Variable<any, string>},ValueTypes["InformationList"]],
listInformationList?: [{	filter?: ValueTypes["InformationListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["InformationListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["InformationList"]],
paginateInformationList?: [{	filter?: ValueTypes["InformationListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["InformationListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["InformationListConnection"]],
getPieceOfInformation?: [{	by: ValueTypes["PieceOfInformationUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PieceOfInformationWhere"] | undefined | null | Variable<any, string>},ValueTypes["PieceOfInformation"]],
listPieceOfInformation?: [{	filter?: ValueTypes["PieceOfInformationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PieceOfInformationOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PieceOfInformation"]],
paginatePieceOfInformation?: [{	filter?: ValueTypes["PieceOfInformationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PieceOfInformationOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PieceOfInformationConnection"]],
getPositionTag?: [{	by: ValueTypes["PositionTagUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionTagWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionTag"]],
listPositionTag?: [{	filter?: ValueTypes["PositionTagWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTag"]],
paginatePositionTag?: [{	filter?: ValueTypes["PositionTagWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagConnection"]],
getPositionTagLocale?: [{	by: ValueTypes["PositionTagLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionTagLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionTagLocale"]],
listPositionTagLocale?: [{	filter?: ValueTypes["PositionTagLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagLocale"]],
paginatePositionTagLocale?: [{	filter?: ValueTypes["PositionTagLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagLocaleConnection"]],
getPosition?: [{	by: ValueTypes["PositionUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionWhere"] | undefined | null | Variable<any, string>},ValueTypes["Position"]],
listPosition?: [{	filter?: ValueTypes["PositionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Position"]],
paginatePosition?: [{	filter?: ValueTypes["PositionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionConnection"]],
getPositionLocale?: [{	by: ValueTypes["PositionLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionLocale"]],
listPositionLocale?: [{	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionLocale"]],
paginatePositionLocale?: [{	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionLocaleConnection"]],
getPositionTagList?: [{	by: ValueTypes["PositionTagListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionTagListWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionTagList"]],
listPositionTagList?: [{	filter?: ValueTypes["PositionTagListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagList"]],
paginatePositionTagList?: [{	filter?: ValueTypes["PositionTagListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagListConnection"]],
getPositionTagListItem?: [{	by: ValueTypes["PositionTagListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionTagListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionTagListItem"]],
listPositionTagListItem?: [{	filter?: ValueTypes["PositionTagListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagListItem"]],
paginatePositionTagListItem?: [{	filter?: ValueTypes["PositionTagListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagListItemConnection"]],
getPositionCategory?: [{	by: ValueTypes["PositionCategoryUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionCategoryWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionCategory"]],
listPositionCategory?: [{	filter?: ValueTypes["PositionCategoryWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionCategoryOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionCategory"]],
paginatePositionCategory?: [{	filter?: ValueTypes["PositionCategoryWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionCategoryOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionCategoryConnection"]],
getPositionCategoryLocale?: [{	by: ValueTypes["PositionCategoryLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionCategoryLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionCategoryLocale"]],
listPositionCategoryLocale?: [{	filter?: ValueTypes["PositionCategoryLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionCategoryLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionCategoryLocale"]],
paginatePositionCategoryLocale?: [{	filter?: ValueTypes["PositionCategoryLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionCategoryLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionCategoryLocaleConnection"]],
getEmbed?: [{	by: ValueTypes["EmbedUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["EmbedWhere"] | undefined | null | Variable<any, string>},ValueTypes["Embed"]],
listEmbed?: [{	filter?: ValueTypes["EmbedWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["EmbedOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Embed"]],
paginateEmbed?: [{	filter?: ValueTypes["EmbedWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["EmbedOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["EmbedConnection"]],
getRedirect?: [{	by: ValueTypes["RedirectUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>},ValueTypes["Redirect"]],
listRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Redirect"]],
paginateRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["RedirectConnection"]],
getTalk?: [{	by: ValueTypes["TalkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TalkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Talk"]],
listTalk?: [{	filter?: ValueTypes["TalkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Talk"]],
paginateTalk?: [{	filter?: ValueTypes["TalkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TalkConnection"]],
getTalkList?: [{	by: ValueTypes["TalkListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TalkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["TalkList"]],
listTalkList?: [{	filter?: ValueTypes["TalkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TalkList"]],
paginateTalkList?: [{	filter?: ValueTypes["TalkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TalkListConnection"]],
getTalkListItem?: [{	by: ValueTypes["TalkListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TalkListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["TalkListItem"]],
listTalkListItem?: [{	filter?: ValueTypes["TalkListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TalkListItem"]],
paginateTalkListItem?: [{	filter?: ValueTypes["TalkListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TalkListItemConnection"]],
getTalkLists?: [{	by: ValueTypes["TalkListsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TalkListsWhere"] | undefined | null | Variable<any, string>},ValueTypes["TalkLists"]],
listTalkLists?: [{	filter?: ValueTypes["TalkListsWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkListsOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TalkLists"]],
paginateTalkLists?: [{	filter?: ValueTypes["TalkListsWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkListsOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TalkListsConnection"]],
getBusinessCard?: [{	by: ValueTypes["BusinessCardUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessCardWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessCard"]],
listBusinessCard?: [{	filter?: ValueTypes["BusinessCardWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCardOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessCard"]],
paginateBusinessCard?: [{	filter?: ValueTypes["BusinessCardWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCardOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessCardConnection"]],
getBusinessCardList?: [{	by: ValueTypes["BusinessCardListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessCardListWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessCardList"]],
listBusinessCardList?: [{	filter?: ValueTypes["BusinessCardListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCardListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessCardList"]],
paginateBusinessCardList?: [{	filter?: ValueTypes["BusinessCardListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCardListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessCardListConnection"]],
getBusinessCardListItem?: [{	by: ValueTypes["BusinessCardListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessCardListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessCardListItem"]],
listBusinessCardListItem?: [{	filter?: ValueTypes["BusinessCardListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCardListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessCardListItem"]],
paginateBusinessCardListItem?: [{	filter?: ValueTypes["BusinessCardListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCardListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessCardListItemConnection"]],
getTestimonial?: [{	by: ValueTypes["TestimonialUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TestimonialWhere"] | undefined | null | Variable<any, string>},ValueTypes["Testimonial"]],
listTestimonial?: [{	filter?: ValueTypes["TestimonialWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Testimonial"]],
paginateTestimonial?: [{	filter?: ValueTypes["TestimonialWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialConnection"]],
getTestimonialList?: [{	by: ValueTypes["TestimonialListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TestimonialListWhere"] | undefined | null | Variable<any, string>},ValueTypes["TestimonialList"]],
listTestimonialList?: [{	filter?: ValueTypes["TestimonialListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialList"]],
paginateTestimonialList?: [{	filter?: ValueTypes["TestimonialListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialListConnection"]],
getTestimonialListItem?: [{	by: ValueTypes["TestimonialListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TestimonialListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["TestimonialListItem"]],
listTestimonialListItem?: [{	filter?: ValueTypes["TestimonialListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialListItem"]],
paginateTestimonialListItem?: [{	filter?: ValueTypes["TestimonialListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialListItemConnection"]],
getContactRequestOption?: [{	by: ValueTypes["ContactRequestOptionUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContactRequestOptionWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContactRequestOption"]],
listContactRequestOption?: [{	filter?: ValueTypes["ContactRequestOptionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContactRequestOptionOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContactRequestOption"]],
paginateContactRequestOption?: [{	filter?: ValueTypes["ContactRequestOptionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContactRequestOptionOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContactRequestOptionConnection"]],
getContactRequestOptionList?: [{	by: ValueTypes["ContactRequestOptionListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContactRequestOptionListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContactRequestOptionList"]],
listContactRequestOptionList?: [{	filter?: ValueTypes["ContactRequestOptionListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContactRequestOptionListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContactRequestOptionList"]],
paginateContactRequestOptionList?: [{	filter?: ValueTypes["ContactRequestOptionListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContactRequestOptionListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContactRequestOptionListConnection"]],
getYoutubeVideo?: [{	by: ValueTypes["YoutubeVideoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["YoutubeVideoWhere"] | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideo"]],
listYoutubeVideo?: [{	filter?: ValueTypes["YoutubeVideoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["YoutubeVideoOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideo"]],
paginateYoutubeVideo?: [{	filter?: ValueTypes["YoutubeVideoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["YoutubeVideoOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoConnection"]],
getYoutubeVideoList?: [{	by: ValueTypes["YoutubeVideoListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["YoutubeVideoListWhere"] | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoList"]],
listYoutubeVideoList?: [{	filter?: ValueTypes["YoutubeVideoListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["YoutubeVideoListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoList"]],
paginateYoutubeVideoList?: [{	filter?: ValueTypes["YoutubeVideoListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["YoutubeVideoListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoListConnection"]],
getYoutubeVideoListItem?: [{	by: ValueTypes["YoutubeVideoListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["YoutubeVideoListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoListItem"]],
listYoutubeVideoListItem?: [{	filter?: ValueTypes["YoutubeVideoListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["YoutubeVideoListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoListItem"]],
paginateYoutubeVideoListItem?: [{	filter?: ValueTypes["YoutubeVideoListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["YoutubeVideoListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoListItemConnection"]],
getTranslationCatalogue?: [{	by: ValueTypes["TranslationCatalogueUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
listTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
paginateTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogueConnection"]],
getTranslationCataloguesIdentifier?: [{	by: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCataloguesIdentifier"]],
listTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCataloguesIdentifier"]],
paginateTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCataloguesIdentifierConnection"]],
getTranslationDomain?: [{	by: ValueTypes["TranslationDomainUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationDomain"]],
listTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationDomain"]],
paginateTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationDomainConnection"]],
getTranslationKey?: [{	by: ValueTypes["TranslationKeyUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationKey"]],
listTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationKey"]],
paginateTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationKeyConnection"]],
getTranslationValue?: [{	by: ValueTypes["TranslationValueUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationValue"]],
listTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationValue"]],
paginateTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationValueConnection"]],
validateCreateContactRequestSubmission?: [{	data: ValueTypes["ContactRequestSubmissionCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
getSubscribeForm?: [{	by: ValueTypes["SubscribeFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SubscribeFormWhere"] | undefined | null | Variable<any, string>},ValueTypes["SubscribeForm"]],
listSubscribeForm?: [{	filter?: ValueTypes["SubscribeFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SubscribeFormOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SubscribeForm"]],
paginateSubscribeForm?: [{	filter?: ValueTypes["SubscribeFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SubscribeFormOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SubscribeFormConnection"]],
validateCreateSubscribeForm?: [{	data: ValueTypes["SubscribeFormCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
validateUpdateSubscribeForm?: [{	by: ValueTypes["SubscribeFormUniqueWhere"] | Variable<any, string>,	data: ValueTypes["SubscribeFormUpdateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
validateCreateSubscribeSubmission?: [{	data: ValueTypes["SubscribeSubmissionCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
getContentReferencePositionCategoryItem?: [{	by: ValueTypes["ContentReferencePositionCategoryItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReferencePositionCategoryItem"]],
listContentReferencePositionCategoryItem?: [{	filter?: ValueTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferencePositionCategoryItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferencePositionCategoryItem"]],
paginateContentReferencePositionCategoryItem?: [{	filter?: ValueTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferencePositionCategoryItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferencePositionCategoryItemConnection"]],
getVideo?: [{	by: ValueTypes["VideoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Video"]],
listVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Video"]],
paginateVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["VideoConnection"]],
getContentReferenceCaseStudyItem?: [{	by: ValueTypes["ContentReferenceCaseStudyItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceCaseStudyItem"]],
listContentReferenceCaseStudyItem?: [{	filter?: ValueTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceCaseStudyItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceCaseStudyItem"]],
paginateContentReferenceCaseStudyItem?: [{	filter?: ValueTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceCaseStudyItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceCaseStudyItemConnection"]],
getContentReferenceTagItem?: [{	by: ValueTypes["ContentReferenceTagItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceTagItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceTagItem"]],
listContentReferenceTagItem?: [{	filter?: ValueTypes["ContentReferenceTagItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceTagItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceTagItem"]],
paginateContentReferenceTagItem?: [{	filter?: ValueTypes["ContentReferenceTagItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceTagItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceTagItemConnection"]],
getPositionList?: [{	by: ValueTypes["PositionListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionListWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionList"]],
listPositionList?: [{	filter?: ValueTypes["PositionListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionList"]],
paginatePositionList?: [{	filter?: ValueTypes["PositionListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionListConnection"]],
getPositionListItem?: [{	by: ValueTypes["PositionListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionListItem"]],
listPositionListItem?: [{	filter?: ValueTypes["PositionListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionListItem"]],
paginatePositionListItem?: [{	filter?: ValueTypes["PositionListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionListItemConnection"]],
getTestimonialFull?: [{	by: ValueTypes["TestimonialFullUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TestimonialFullWhere"] | undefined | null | Variable<any, string>},ValueTypes["TestimonialFull"]],
listTestimonialFull?: [{	filter?: ValueTypes["TestimonialFullWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialFullOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialFull"]],
paginateTestimonialFull?: [{	filter?: ValueTypes["TestimonialFullWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialFullOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullConnection"]],
getTestimonialFullList?: [{	by: ValueTypes["TestimonialFullListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TestimonialFullListWhere"] | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullList"]],
listTestimonialFullList?: [{	filter?: ValueTypes["TestimonialFullListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialFullListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullList"]],
paginateTestimonialFullList?: [{	filter?: ValueTypes["TestimonialFullListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialFullListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullListConnection"]],
getTestimonialFullListItem?: [{	by: ValueTypes["TestimonialFullListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TestimonialFullListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullListItem"]],
listTestimonialFullListItem?: [{	filter?: ValueTypes["TestimonialFullListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialFullListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullListItem"]],
paginateTestimonialFullListItem?: [{	filter?: ValueTypes["TestimonialFullListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialFullListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullListItemConnection"]],
getSecondLevelLinkItem?: [{	by: ValueTypes["SecondLevelLinkItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SecondLevelLinkItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["SecondLevelLinkItem"]],
listSecondLevelLinkItem?: [{	filter?: ValueTypes["SecondLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SecondLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SecondLevelLinkItem"]],
paginateSecondLevelLinkItem?: [{	filter?: ValueTypes["SecondLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SecondLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SecondLevelLinkItemConnection"]],
getThirdLevelLinkItem?: [{	by: ValueTypes["ThirdLevelLinkItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ThirdLevelLinkItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ThirdLevelLinkItem"]],
listThirdLevelLinkItem?: [{	filter?: ValueTypes["ThirdLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ThirdLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ThirdLevelLinkItem"]],
paginateThirdLevelLinkItem?: [{	filter?: ValueTypes["ThirdLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ThirdLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ThirdLevelLinkItemConnection"]],
getTopLevelLinkItem?: [{	by: ValueTypes["TopLevelLinkItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TopLevelLinkItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["TopLevelLinkItem"]],
listTopLevelLinkItem?: [{	filter?: ValueTypes["TopLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TopLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TopLevelLinkItem"]],
paginateTopLevelLinkItem?: [{	filter?: ValueTypes["TopLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TopLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TopLevelLinkItemConnection"]],
getAuthor?: [{	by: ValueTypes["AuthorUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["AuthorWhere"] | undefined | null | Variable<any, string>},ValueTypes["Author"]],
listAuthor?: [{	filter?: ValueTypes["AuthorWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuthorOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Author"]],
paginateAuthor?: [{	filter?: ValueTypes["AuthorWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuthorOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["AuthorConnection"]],
getAuthorLocale?: [{	by: ValueTypes["AuthorLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["AuthorLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["AuthorLocale"]],
listAuthorLocale?: [{	filter?: ValueTypes["AuthorLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuthorLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["AuthorLocale"]],
paginateAuthorLocale?: [{	filter?: ValueTypes["AuthorLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuthorLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["AuthorLocaleConnection"]],
getBlogPost?: [{	by: ValueTypes["BlogPostUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPost"]],
listBlogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPost"]],
paginateBlogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostConnection"]],
getBlogPostLocale?: [{	by: ValueTypes["BlogPostLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocale"]],
listBlogPostLocale?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocale"]],
paginateBlogPostLocale?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocaleConnection"]],
getContentReferenceBlogPostItem?: [{	by: ValueTypes["ContentReferenceBlogPostItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceBlogPostItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceBlogPostItem"]],
listContentReferenceBlogPostItem?: [{	filter?: ValueTypes["ContentReferenceBlogPostItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceBlogPostItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceBlogPostItem"]],
paginateContentReferenceBlogPostItem?: [{	filter?: ValueTypes["ContentReferenceBlogPostItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceBlogPostItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceBlogPostItemConnection"]],
getRelevantBlogPostList?: [{	by: ValueTypes["RelevantBlogPostListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["RelevantBlogPostListWhere"] | undefined | null | Variable<any, string>},ValueTypes["RelevantBlogPostList"]],
listRelevantBlogPostList?: [{	filter?: ValueTypes["RelevantBlogPostListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RelevantBlogPostListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["RelevantBlogPostList"]],
paginateRelevantBlogPostList?: [{	filter?: ValueTypes["RelevantBlogPostListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RelevantBlogPostListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["RelevantBlogPostListConnection"]],
getBlogPostCommon?: [{	by: ValueTypes["BlogPostCommonUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostCommonWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommon"]],
listBlogPostCommon?: [{	filter?: ValueTypes["BlogPostCommonWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostCommonOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommon"]],
paginateBlogPostCommon?: [{	filter?: ValueTypes["BlogPostCommonWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostCommonOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommonConnection"]],
getBlogPostCommonLocale?: [{	by: ValueTypes["BlogPostCommonLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostCommonLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommonLocale"]],
listBlogPostCommonLocale?: [{	filter?: ValueTypes["BlogPostCommonLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostCommonLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommonLocale"]],
paginateBlogPostCommonLocale?: [{	filter?: ValueTypes["BlogPostCommonLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostCommonLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommonLocaleConnection"]],
	transaction?:ValueTypes["QueryTransaction"],
	_info?:ValueTypes["Info"],
		__typename?: boolean | `@${string}`
}>;
	["_Schema"]: AliasType<{
	enums?:ValueTypes["_Enum"],
	entities?:ValueTypes["_Entity"],
		__typename?: boolean | `@${string}`
}>;
	["_Enum"]: AliasType<{
	name?:boolean | `@${string}`,
	values?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Entity"]: AliasType<{
	name?:boolean | `@${string}`,
	customPrimaryAllowed?:boolean | `@${string}`,
	fields?:ValueTypes["_Field"],
	unique?:ValueTypes["_UniqueConstraint"],
		__typename?: boolean | `@${string}`
}>;
	["_UniqueConstraint"]: AliasType<{
	fields?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["General"]: AliasType<{
	_meta?:ValueTypes["GeneralMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
localesByLocale?: [{	by: ValueTypes["GeneralLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
localesBySeo?: [{	by: ValueTypes["GeneralLocalesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
localesByContent?: [{	by: ValueTypes["GeneralLocalesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
paginateLocales?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GeneralMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FieldMeta"]: AliasType<{
	readable?:boolean | `@${string}`,
	updatable?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UUID"]:unknown;
	["One"]:One;
	["GeneralLocale"]: AliasType<{
	_meta?:ValueTypes["GeneralLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>},ValueTypes["General"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
homeLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
caseStudiesLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
positionsLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
	facebookUrl?:boolean | `@${string}`,
	twitterUrl?:boolean | `@${string}`,
	instagramUrl?:boolean | `@${string}`,
	youtubeUrl?:boolean | `@${string}`,
	linkedinUrl?:boolean | `@${string}`,
	formPrivacyPolicy?:boolean | `@${string}`,
	formMarketingConsent?:boolean | `@${string}`,
	acceptButtonLabel?:boolean | `@${string}`,
	rejectButtonLabel?:boolean | `@${string}`,
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	homeLinkable?:ValueTypes["FieldMeta"],
	caseStudiesLink?:ValueTypes["FieldMeta"],
	positionsLink?:ValueTypes["FieldMeta"],
	facebookUrl?:ValueTypes["FieldMeta"],
	twitterUrl?:ValueTypes["FieldMeta"],
	instagramUrl?:ValueTypes["FieldMeta"],
	youtubeUrl?:ValueTypes["FieldMeta"],
	linkedinUrl?:ValueTypes["FieldMeta"],
	formPrivacyPolicy?:ValueTypes["FieldMeta"],
	formMarketingConsent?:ValueTypes["FieldMeta"],
	acceptButtonLabel?:ValueTypes["FieldMeta"],
	rejectButtonLabel?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["GeneralWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["GeneralWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>
};
	["UUIDCondition"]: {
	and?: Array<ValueTypes["UUIDCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["UUIDCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["UUID"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["UUID"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["OneCondition"]: {
	and?: Array<ValueTypes["OneCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["OneCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["One"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["One"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["One"] | undefined | null | Variable<any, string>
};
	["GeneralLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,
	homeLinkable?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	caseStudiesLink?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	positionsLink?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	facebookUrl?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	twitterUrl?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	instagramUrl?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	youtubeUrl?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	linkedinUrl?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	formPrivacyPolicy?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	formMarketingConsent?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	acceptButtonLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	rejectButtonLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["GeneralLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["GeneralLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>
};
	["LocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	code?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	label?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	general?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,
	genericPages?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,
	headers?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,
	footers?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,
	caseStudies?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["TagLocaleWhere"] | undefined | null | Variable<any, string>,
	positionTags?: ValueTypes["PositionTagLocaleWhere"] | undefined | null | Variable<any, string>,
	positions?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>,
	positionCategories?: ValueTypes["PositionCategoryLocaleWhere"] | undefined | null | Variable<any, string>,
	authors?: ValueTypes["AuthorLocaleWhere"] | undefined | null | Variable<any, string>,
	blogPosts?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>,
	blogPostCommon?: ValueTypes["BlogPostCommonLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["LocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["LocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>
};
	["StringCondition"]: {
	and?: Array<ValueTypes["StringCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["StringCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: string | undefined | null | Variable<any, string>,
	notEq?: string | undefined | null | Variable<any, string>,
	in?: Array<string> | undefined | null | Variable<any, string>,
	notIn?: Array<string> | undefined | null | Variable<any, string>,
	lt?: string | undefined | null | Variable<any, string>,
	lte?: string | undefined | null | Variable<any, string>,
	gt?: string | undefined | null | Variable<any, string>,
	gte?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	startsWith?: string | undefined | null | Variable<any, string>,
	endsWith?: string | undefined | null | Variable<any, string>,
	containsCI?: string | undefined | null | Variable<any, string>,
	startsWithCI?: string | undefined | null | Variable<any, string>,
	endsWithCI?: string | undefined | null | Variable<any, string>
};
	["IntCondition"]: {
	and?: Array<ValueTypes["IntCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["IntCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: number | undefined | null | Variable<any, string>,
	notEq?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>
};
	["GenericPageLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["GenericPageLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["GenericPageLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["DateTimeCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["GenericPageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["GenericPageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>
};
	["DateTimeCondition"]: {
	and?: Array<ValueTypes["DateTimeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["DateTimeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["DateTimeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["DateTime"]:unknown;
	["ContentWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	blocks?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContentWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	json?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,
	references?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContentBlockWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentBlockWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["ContentReferenceTypeCondition"] | undefined | null | Variable<any, string>,
	primaryText?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	block?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	secondaryText?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	featuredCaseStudies?: ValueTypes["CaseStudyListWhere"] | undefined | null | Variable<any, string>,
	images?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,
	otherImages?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,
	informationList?: ValueTypes["InformationListWhere"] | undefined | null | Variable<any, string>,
	tertiaryText?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	embed?: ValueTypes["EmbedWhere"] | undefined | null | Variable<any, string>,
	links?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>,
	talkLists?: ValueTypes["TalkListsWhere"] | undefined | null | Variable<any, string>,
	businessCardList?: ValueTypes["BusinessCardListWhere"] | undefined | null | Variable<any, string>,
	testimonials?: ValueTypes["TestimonialListWhere"] | undefined | null | Variable<any, string>,
	background?: ValueTypes["ContentBackgroundTypeCondition"] | undefined | null | Variable<any, string>,
	otherImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	caseStudy?: ValueTypes["CaseStudyWhere"] | undefined | null | Variable<any, string>,
	alternative?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	contactRequestOptions?: ValueTypes["ContactRequestOptionListWhere"] | undefined | null | Variable<any, string>,
	youtubeVideo?: ValueTypes["YoutubeVideoWhere"] | undefined | null | Variable<any, string>,
	subscribeForm?: ValueTypes["SubscribeFormWhere"] | undefined | null | Variable<any, string>,
	video?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,
	otherVideo?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,
	positionCategories?: ValueTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null | Variable<any, string>,
	caseStudies?: ValueTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["ContentReferenceTagItemWhere"] | undefined | null | Variable<any, string>,
	positions?: ValueTypes["PositionListWhere"] | undefined | null | Variable<any, string>,
	testimonialsFull?: ValueTypes["TestimonialFullListWhere"] | undefined | null | Variable<any, string>,
	blogPosts?: ValueTypes["ContentReferenceBlogPostItemWhere"] | undefined | null | Variable<any, string>,
	verticalMargin?: ValueTypes["ContentVerticalMarginCondition"] | undefined | null | Variable<any, string>,
	variant?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContentReferenceWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentReferenceWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceTypeCondition"]: {
	and?: Array<ValueTypes["ContentReferenceTypeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentReferenceTypeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentReferenceTypeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ContentReferenceType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ContentReferenceType"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>
};
	["ContentReferenceType"]:ContentReferenceType;
	["ImageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	alt?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	fileName?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ImageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ImageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>
};
	["LinkWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	externalLink?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	internalLink?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["LinkTypeCondition"] | undefined | null | Variable<any, string>,
	isTargetBlank?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["LinkWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["LinkWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>
};
	["LinkableWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	genericPage?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,
	caseStudy?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>,
	position?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>,
	redirect?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>,
	blogPost?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["LinkableWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["LinkableWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>
};
	["CaseStudyLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["CaseStudyWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,
	note?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["CaseStudyLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["CaseStudyLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>
};
	["CaseStudyWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["CaseStudyTagWhere"] | undefined | null | Variable<any, string>,
	wideImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	narrowImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["DateTimeCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["CaseStudyWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["CaseStudyWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["CaseStudyWhere"] | undefined | null | Variable<any, string>
};
	["CaseStudyTagWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	caseStudy?: ValueTypes["CaseStudyWhere"] | undefined | null | Variable<any, string>,
	tag?: ValueTypes["TagWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["CaseStudyTagWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["CaseStudyTagWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["CaseStudyTagWhere"] | undefined | null | Variable<any, string>
};
	["TagWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["TagLocaleWhere"] | undefined | null | Variable<any, string>,
	caseStudyTags?: ValueTypes["CaseStudyTagWhere"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TagWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TagWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TagWhere"] | undefined | null | Variable<any, string>
};
	["TagLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["TagWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TagLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TagLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TagLocaleWhere"] | undefined | null | Variable<any, string>
};
	["SeoWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	ogTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	ogDescription?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	ogImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	noIndex?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	noFollow?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["SeoWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["SeoWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>
};
	["BooleanCondition"]: {
	and?: Array<ValueTypes["BooleanCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["BooleanCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: boolean | undefined | null | Variable<any, string>,
	notEq?: boolean | undefined | null | Variable<any, string>,
	in?: Array<boolean> | undefined | null | Variable<any, string>,
	notIn?: Array<boolean> | undefined | null | Variable<any, string>,
	lt?: boolean | undefined | null | Variable<any, string>,
	lte?: boolean | undefined | null | Variable<any, string>,
	gt?: boolean | undefined | null | Variable<any, string>,
	gte?: boolean | undefined | null | Variable<any, string>
};
	["PositionLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["PositionWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,
	summary?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	salaryRange?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["PositionLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["PositionLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>
};
	["PositionWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["PositionTagListWhere"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>,
	orderInCategory?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["PositionCategoryWhere"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["DateTimeCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["PositionWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["PositionWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["PositionWhere"] | undefined | null | Variable<any, string>
};
	["PositionTagListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["PositionTagListItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["PositionTagListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["PositionTagListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["PositionTagListWhere"] | undefined | null | Variable<any, string>
};
	["PositionTagListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["PositionTagListWhere"] | undefined | null | Variable<any, string>,
	tag?: ValueTypes["PositionTagWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["PositionTagListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["PositionTagListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["PositionTagListItemWhere"] | undefined | null | Variable<any, string>
};
	["PositionTagWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["PositionTagLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["PositionTagWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["PositionTagWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["PositionTagWhere"] | undefined | null | Variable<any, string>
};
	["PositionTagLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["PositionTagWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["PositionTagLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["PositionTagLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["PositionTagLocaleWhere"] | undefined | null | Variable<any, string>
};
	["PositionCategoryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	positions?: ValueTypes["PositionWhere"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["PositionCategoryLocaleWhere"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["PositionCategoryWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["PositionCategoryWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["PositionCategoryWhere"] | undefined | null | Variable<any, string>
};
	["PositionCategoryLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["PositionCategoryWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["PositionCategoryLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["PositionCategoryLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["PositionCategoryLocaleWhere"] | undefined | null | Variable<any, string>
};
	["RedirectWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	target?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["RedirectWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["RedirectWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>
};
	["BlogPostLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	lead?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["BlogPostLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["BlogPostLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>
};
	["BlogPostWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["DateTimeCondition"] | undefined | null | Variable<any, string>,
	author?: ValueTypes["AuthorWhere"] | undefined | null | Variable<any, string>,
	wideImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	narrowImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>,
	relevantBlogPosts?: ValueTypes["RelevantBlogPostListWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["BlogPostWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["BlogPostWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>
};
	["AuthorWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["AuthorLocaleWhere"] | undefined | null | Variable<any, string>,
	blogPosts?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	profileUrl?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	avatarImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["AuthorWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["AuthorWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["AuthorWhere"] | undefined | null | Variable<any, string>
};
	["AuthorLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	role?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	bio?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["AuthorWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["AuthorLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["AuthorLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["AuthorLocaleWhere"] | undefined | null | Variable<any, string>
};
	["RelevantBlogPostListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	blogPost?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>,
	relevantBlogPost?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["RelevantBlogPostListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["RelevantBlogPostListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["RelevantBlogPostListWhere"] | undefined | null | Variable<any, string>
};
	["LinkTypeCondition"]: {
	and?: Array<ValueTypes["LinkTypeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["LinkTypeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["LinkTypeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["LinkType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["LinkType"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>
};
	["LinkType"]:LinkType;
	["CaseStudyListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["CaseStudyListItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["CaseStudyListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["CaseStudyListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["CaseStudyListWhere"] | undefined | null | Variable<any, string>
};
	["CaseStudyListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["CaseStudyListWhere"] | undefined | null | Variable<any, string>,
	caseStudy?: ValueTypes["CaseStudyWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["CaseStudyListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["CaseStudyListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["CaseStudyListItemWhere"] | undefined | null | Variable<any, string>
};
	["ImageListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ImageListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ImageListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>
};
	["ImageListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ImageListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ImageListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>
};
	["InformationListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["PieceOfInformationWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["InformationListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["InformationListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["InformationListWhere"] | undefined | null | Variable<any, string>
};
	["PieceOfInformationWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["InformationListWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["PieceOfInformationWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["PieceOfInformationWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["PieceOfInformationWhere"] | undefined | null | Variable<any, string>
};
	["EmbedWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["EmbedTypeCondition"] | undefined | null | Variable<any, string>,
	embedId?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["EmbedWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["EmbedWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["EmbedWhere"] | undefined | null | Variable<any, string>
};
	["EmbedTypeCondition"]: {
	and?: Array<ValueTypes["EmbedTypeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["EmbedTypeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["EmbedTypeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["EmbedType"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["EmbedType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["EmbedType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["EmbedType"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["EmbedType"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["EmbedType"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["EmbedType"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["EmbedType"] | undefined | null | Variable<any, string>
};
	["EmbedType"]:EmbedType;
	["LinkListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["LinkListItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["LinkListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["LinkListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>
};
	["LinkListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["LinkListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["LinkListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["LinkListItemWhere"] | undefined | null | Variable<any, string>
};
	["TalkListsWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["TalkListWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TalkListsWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TalkListsWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TalkListsWhere"] | undefined | null | Variable<any, string>
};
	["TalkListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["TalkListsWhere"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["TalkListItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TalkListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TalkListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TalkListWhere"] | undefined | null | Variable<any, string>
};
	["TalkListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["TalkListWhere"] | undefined | null | Variable<any, string>,
	talk?: ValueTypes["TalkWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TalkListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TalkListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TalkListItemWhere"] | undefined | null | Variable<any, string>
};
	["TalkWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["DateTimeCondition"] | undefined | null | Variable<any, string>,
	note?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	coverImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	otherImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TalkWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TalkWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TalkWhere"] | undefined | null | Variable<any, string>
};
	["BusinessCardListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["BusinessCardListItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["BusinessCardListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["BusinessCardListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["BusinessCardListWhere"] | undefined | null | Variable<any, string>
};
	["BusinessCardListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["BusinessCardListWhere"] | undefined | null | Variable<any, string>,
	businessCard?: ValueTypes["BusinessCardWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["BusinessCardListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["BusinessCardListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["BusinessCardListItemWhere"] | undefined | null | Variable<any, string>
};
	["BusinessCardWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	role?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	avatar?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	phone?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["BusinessCardWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["BusinessCardWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["BusinessCardWhere"] | undefined | null | Variable<any, string>
};
	["TestimonialListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["TestimonialListItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TestimonialListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TestimonialListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TestimonialListWhere"] | undefined | null | Variable<any, string>
};
	["TestimonialListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["TestimonialListWhere"] | undefined | null | Variable<any, string>,
	testimonial?: ValueTypes["TestimonialWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TestimonialListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TestimonialListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TestimonialListItemWhere"] | undefined | null | Variable<any, string>
};
	["TestimonialWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	note?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	avatar?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TestimonialWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TestimonialWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TestimonialWhere"] | undefined | null | Variable<any, string>
};
	["ContentBackgroundTypeCondition"]: {
	and?: Array<ValueTypes["ContentBackgroundTypeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentBackgroundTypeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentBackgroundTypeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["ContentBackgroundType"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["ContentBackgroundType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ContentBackgroundType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ContentBackgroundType"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["ContentBackgroundType"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["ContentBackgroundType"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["ContentBackgroundType"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["ContentBackgroundType"] | undefined | null | Variable<any, string>
};
	["ContentBackgroundType"]:ContentBackgroundType;
	["ContactRequestOptionListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["ContactRequestOptionWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContactRequestOptionListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContactRequestOptionListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContactRequestOptionListWhere"] | undefined | null | Variable<any, string>
};
	["ContactRequestOptionWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	label?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["ContactRequestOptionListWhere"] | undefined | null | Variable<any, string>,
	code?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["ContactRequestOptionTypeCondition"] | undefined | null | Variable<any, string>,
	subscribeForm?: ValueTypes["SubscribeFormWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContactRequestOptionWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContactRequestOptionWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContactRequestOptionWhere"] | undefined | null | Variable<any, string>
};
	["ContactRequestOptionTypeCondition"]: {
	and?: Array<ValueTypes["ContactRequestOptionTypeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContactRequestOptionTypeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContactRequestOptionTypeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["ContactRequestOptionType"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["ContactRequestOptionType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ContactRequestOptionType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ContactRequestOptionType"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["ContactRequestOptionType"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["ContactRequestOptionType"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["ContactRequestOptionType"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["ContactRequestOptionType"] | undefined | null | Variable<any, string>
};
	["ContactRequestOptionType"]:ContactRequestOptionType;
	["SubscribeFormWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	datacruitJobId?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	datacruitReferenceNumber?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	lastUpdateAt?: ValueTypes["DateTimeCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["SubscribeFormWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["SubscribeFormWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["SubscribeFormWhere"] | undefined | null | Variable<any, string>
};
	["YoutubeVideoWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	videoId?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["YoutubeVideoWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["YoutubeVideoWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["YoutubeVideoWhere"] | undefined | null | Variable<any, string>
};
	["VideoWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	src?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	duration?: ValueTypes["FloatCondition"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["VideoWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["VideoWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>
};
	["FloatCondition"]: {
	and?: Array<ValueTypes["FloatCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FloatCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FloatCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: number | undefined | null | Variable<any, string>,
	notEq?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>
};
	["ContentReferencePositionCategoryItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["PositionCategoryWhere"] | undefined | null | Variable<any, string>,
	reference?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceCaseStudyItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["CaseStudyWhere"] | undefined | null | Variable<any, string>,
	reference?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceTagItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["TagWhere"] | undefined | null | Variable<any, string>,
	reference?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContentReferenceTagItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentReferenceTagItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentReferenceTagItemWhere"] | undefined | null | Variable<any, string>
};
	["PositionListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["PositionListItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["PositionListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["PositionListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["PositionListWhere"] | undefined | null | Variable<any, string>
};
	["PositionListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["PositionListWhere"] | undefined | null | Variable<any, string>,
	position?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["PositionListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["PositionListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["PositionListItemWhere"] | undefined | null | Variable<any, string>
};
	["TestimonialFullListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["TestimonialFullListItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TestimonialFullListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TestimonialFullListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TestimonialFullListWhere"] | undefined | null | Variable<any, string>
};
	["TestimonialFullListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["TestimonialFullListWhere"] | undefined | null | Variable<any, string>,
	testimonial?: ValueTypes["TestimonialFullWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TestimonialFullListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TestimonialFullListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TestimonialFullListItemWhere"] | undefined | null | Variable<any, string>
};
	["TestimonialFullWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	note?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	avatar?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	imageVariant?: ValueTypes["ImageVariantCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TestimonialFullWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TestimonialFullWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TestimonialFullWhere"] | undefined | null | Variable<any, string>
};
	["ImageVariantCondition"]: {
	and?: Array<ValueTypes["ImageVariantCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ImageVariantCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ImageVariantCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["ImageVariant"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["ImageVariant"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ImageVariant"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ImageVariant"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["ImageVariant"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["ImageVariant"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["ImageVariant"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["ImageVariant"] | undefined | null | Variable<any, string>
};
	["ImageVariant"]:ImageVariant;
	["ContentReferenceBlogPostItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["DateCondition"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>,
	reference?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContentReferenceBlogPostItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentReferenceBlogPostItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentReferenceBlogPostItemWhere"] | undefined | null | Variable<any, string>
};
	["DateCondition"]: {
	and?: Array<ValueTypes["DateCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["DateCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["DateCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["Date"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["Date"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Date"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["Date"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["Date"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["Date"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["Date"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["Date"] | undefined | null | Variable<any, string>
};
	["Date"]:unknown;
	["ContentVerticalMarginCondition"]: {
	and?: Array<ValueTypes["ContentVerticalMarginCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentVerticalMarginCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentVerticalMarginCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["ContentVerticalMargin"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["ContentVerticalMargin"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ContentVerticalMargin"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ContentVerticalMargin"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["ContentVerticalMargin"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["ContentVerticalMargin"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["ContentVerticalMargin"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["ContentVerticalMargin"] | undefined | null | Variable<any, string>
};
	["ContentVerticalMargin"]:ContentVerticalMargin;
	["HeaderLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	linkList?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>,
	socialLinkList?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>,
	topLevelLinkItems?: ValueTypes["TopLevelLinkItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["HeaderLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["HeaderLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>
};
	["HeaderWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["HeaderWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["HeaderWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>
};
	["TopLevelLinkItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	isLink?: ValueTypes["IsLinkCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	secondLevelLinkItems?: ValueTypes["SecondLevelLinkItemWhere"] | undefined | null | Variable<any, string>,
	header?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TopLevelLinkItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TopLevelLinkItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TopLevelLinkItemWhere"] | undefined | null | Variable<any, string>
};
	["IsLinkCondition"]: {
	and?: Array<ValueTypes["IsLinkCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["IsLinkCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["IsLinkCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["IsLink"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["IsLink"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["IsLink"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["IsLink"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["IsLink"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["IsLink"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["IsLink"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["IsLink"] | undefined | null | Variable<any, string>
};
	["IsLink"]:IsLink;
	["SecondLevelLinkItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	isLink?: ValueTypes["IsLinkCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	topLevelLinkItem?: ValueTypes["TopLevelLinkItemWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	thirdLevelLinkItems?: ValueTypes["ThirdLevelLinkItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["SecondLevelLinkItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["SecondLevelLinkItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["SecondLevelLinkItemWhere"] | undefined | null | Variable<any, string>
};
	["ThirdLevelLinkItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	secondLevelLinkItem?: ValueTypes["SecondLevelLinkItemWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ThirdLevelLinkItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ThirdLevelLinkItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ThirdLevelLinkItemWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	phone?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	legal?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	navigation?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FooterLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FooterLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>
};
	["FooterWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FooterWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FooterWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>
};
	["BlogPostCommonLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["BlogPostCommonWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	ctaLink?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	ctaTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["BlogPostCommonLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["BlogPostCommonLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["BlogPostCommonLocaleWhere"] | undefined | null | Variable<any, string>
};
	["BlogPostCommonWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["BlogPostCommonLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["BlogPostCommonWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["BlogPostCommonWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["BlogPostCommonWhere"] | undefined | null | Variable<any, string>
};
	["Locale"]: AliasType<{
	_meta?:ValueTypes["LocaleMeta"],
	id?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
general?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
genericPages?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
headers?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
footers?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
caseStudies?: [{	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocale"]],
tags?: [{	filter?: ValueTypes["TagLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TagLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TagLocale"]],
positionTags?: [{	filter?: ValueTypes["PositionTagLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagLocale"]],
positions?: [{	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionLocale"]],
positionCategories?: [{	filter?: ValueTypes["PositionCategoryLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionCategoryLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionCategoryLocale"]],
authors?: [{	filter?: ValueTypes["AuthorLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuthorLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["AuthorLocale"]],
blogPosts?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocale"]],
blogPostCommon?: [{	filter?: ValueTypes["BlogPostCommonLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostCommonLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommonLocale"]],
generalByRoot?: [{	by: ValueTypes["LocaleGeneralByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
generalBySeo?: [{	by: ValueTypes["LocaleGeneralBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
generalByContent?: [{	by: ValueTypes["LocaleGeneralByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
genericPagesByRoot?: [{	by: ValueTypes["LocaleGenericPagesByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
genericPagesByContent?: [{	by: ValueTypes["LocaleGenericPagesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
genericPagesByLink?: [{	by: ValueTypes["LocaleGenericPagesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
genericPagesBySeo?: [{	by: ValueTypes["LocaleGenericPagesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
headersByRoot?: [{	by: ValueTypes["LocaleHeadersByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
headersByLinkList?: [{	by: ValueTypes["LocaleHeadersByLinkListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
headersBySocialLinkList?: [{	by: ValueTypes["LocaleHeadersBySocialLinkListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
headersByTopLevelLinkItems?: [{	by: ValueTypes["LocaleHeadersByTopLevelLinkItemsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
footersByRoot?: [{	by: ValueTypes["LocaleFootersByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
footersByNavigation?: [{	by: ValueTypes["LocaleFootersByNavigationUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
caseStudiesByRoot?: [{	by: ValueTypes["LocaleCaseStudiesByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocale"]],
caseStudiesByContent?: [{	by: ValueTypes["LocaleCaseStudiesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocale"]],
caseStudiesByLink?: [{	by: ValueTypes["LocaleCaseStudiesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocale"]],
caseStudiesBySeo?: [{	by: ValueTypes["LocaleCaseStudiesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocale"]],
tagsBySlug?: [{	by: ValueTypes["LocaleTagsBySlugUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TagLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["TagLocale"]],
tagsByRoot?: [{	by: ValueTypes["LocaleTagsByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TagLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["TagLocale"]],
positionTagsBySlug?: [{	by: ValueTypes["LocalePositionTagsBySlugUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionTagLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionTagLocale"]],
positionTagsByRoot?: [{	by: ValueTypes["LocalePositionTagsByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionTagLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionTagLocale"]],
positionsByRoot?: [{	by: ValueTypes["LocalePositionsByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionLocale"]],
positionsByLink?: [{	by: ValueTypes["LocalePositionsByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionLocale"]],
positionsBySeo?: [{	by: ValueTypes["LocalePositionsBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionLocale"]],
positionsByContent?: [{	by: ValueTypes["LocalePositionsByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionLocale"]],
positionCategoriesByRoot?: [{	by: ValueTypes["LocalePositionCategoriesByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionCategoryLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionCategoryLocale"]],
authorsByRoot?: [{	by: ValueTypes["LocaleAuthorsByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["AuthorLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["AuthorLocale"]],
blogPostsByRoot?: [{	by: ValueTypes["LocaleBlogPostsByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocale"]],
blogPostsByContent?: [{	by: ValueTypes["LocaleBlogPostsByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocale"]],
blogPostsBySeo?: [{	by: ValueTypes["LocaleBlogPostsBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocale"]],
blogPostsByLink?: [{	by: ValueTypes["LocaleBlogPostsByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocale"]],
blogPostCommonByRoot?: [{	by: ValueTypes["LocaleBlogPostCommonByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostCommonLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommonLocale"]],
paginateGeneral?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocaleConnection"]],
paginateGenericPages?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocaleConnection"]],
paginateHeaders?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocaleConnection"]],
paginateFooters?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocaleConnection"]],
paginateCaseStudies?: [{	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocaleConnection"]],
paginateTags?: [{	filter?: ValueTypes["TagLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TagLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TagLocaleConnection"]],
paginatePositionTags?: [{	filter?: ValueTypes["PositionTagLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagLocaleConnection"]],
paginatePositions?: [{	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionLocaleConnection"]],
paginatePositionCategories?: [{	filter?: ValueTypes["PositionCategoryLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionCategoryLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionCategoryLocaleConnection"]],
paginateAuthors?: [{	filter?: ValueTypes["AuthorLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuthorLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["AuthorLocaleConnection"]],
paginateBlogPosts?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocaleConnection"]],
paginateBlogPostCommon?: [{	filter?: ValueTypes["BlogPostCommonLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostCommonLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommonLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["LocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	label?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	general?:ValueTypes["FieldMeta"],
	genericPages?:ValueTypes["FieldMeta"],
	headers?:ValueTypes["FieldMeta"],
	footers?:ValueTypes["FieldMeta"],
	caseStudies?:ValueTypes["FieldMeta"],
	tags?:ValueTypes["FieldMeta"],
	positionTags?:ValueTypes["FieldMeta"],
	positions?:ValueTypes["FieldMeta"],
	positionCategories?:ValueTypes["FieldMeta"],
	authors?:ValueTypes["FieldMeta"],
	blogPosts?:ValueTypes["FieldMeta"],
	blogPostCommon?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GeneralOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null | Variable<any, string>,
	homeLinkable?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	caseStudiesLink?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	positionsLink?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	facebookUrl?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	twitterUrl?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	instagramUrl?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	youtubeUrl?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	linkedinUrl?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	formPrivacyPolicy?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	formMarketingConsent?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	acceptButtonLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	rejectButtonLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentOrderBy"] | undefined | null | Variable<any, string>
};
	["OrderDirection"]:OrderDirection;
	["GeneralOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["LocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	code?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	label?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["SeoOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	ogTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	ogDescription?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	ogImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	noIndex?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	noFollow?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ImageOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	alt?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	fileName?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["LinkableOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	genericPage?: ValueTypes["GenericPageLocaleOrderBy"] | undefined | null | Variable<any, string>,
	caseStudy?: ValueTypes["CaseStudyLocaleOrderBy"] | undefined | null | Variable<any, string>,
	position?: ValueTypes["PositionLocaleOrderBy"] | undefined | null | Variable<any, string>,
	redirect?: ValueTypes["RedirectOrderBy"] | undefined | null | Variable<any, string>,
	blogPost?: ValueTypes["BlogPostLocaleOrderBy"] | undefined | null | Variable<any, string>
};
	["GenericPageLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GenericPageOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentOrderBy"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null | Variable<any, string>
};
	["GenericPageOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ContentOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["CaseStudyLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["CaseStudyOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentOrderBy"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null | Variable<any, string>,
	note?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["CaseStudyOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	wideImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	narrowImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["PositionLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["PositionOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentOrderBy"] | undefined | null | Variable<any, string>,
	summary?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	salaryRange?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["PositionOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["PositionTagListOrderBy"] | undefined | null | Variable<any, string>,
	orderInCategory?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["PositionCategoryOrderBy"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["PositionTagListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["PositionCategoryOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["RedirectOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	target?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>
};
	["LinkOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	externalLink?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	internalLink?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	isTargetBlank?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["BlogPostLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["BlogPostOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentOrderBy"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	lead?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["BlogPostOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	author?: ValueTypes["AuthorOrderBy"] | undefined | null | Variable<any, string>,
	wideImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	narrowImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["AuthorOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	profileUrl?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	avatarImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["GenericPageLocale"]: AliasType<{
	_meta?:ValueTypes["GenericPageLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPage"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPage"]: AliasType<{
	_meta?:ValueTypes["GenericPageMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
	publishedAt?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["GenericPageLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
localesByContent?: [{	by: ValueTypes["GenericPageLocalesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
localesByLink?: [{	by: ValueTypes["GenericPageLocalesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
localesBySeo?: [{	by: ValueTypes["GenericPageLocalesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
paginateLocales?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	publishedAt?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	code?: string | undefined | null | Variable<any, string>,
	general?: ValueTypes["GeneralLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	genericPages?: ValueTypes["GenericPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	headers?: ValueTypes["HeaderLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	footers?: ValueTypes["FooterLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	caseStudies?: ValueTypes["CaseStudyLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["TagLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	positionTags?: ValueTypes["PositionTagLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	positions?: ValueTypes["PositionLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	positionCategories?: ValueTypes["PositionCategoryLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	authors?: ValueTypes["AuthorLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	blogPosts?: ValueTypes["BlogPostLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	blogPostCommon?: ValueTypes["BlogPostCommonLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GeneralLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GeneralUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GeneralUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["GeneralLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["SeoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	ogImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ImageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["ContentUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	blocks?: ValueTypes["ContentBlockUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	references?: ValueTypes["ContentReferenceUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>,
	featuredCaseStudies?: ValueTypes["CaseStudyListUniqueWhere"] | undefined | null | Variable<any, string>,
	images?: ValueTypes["ImageListUniqueWhere"] | undefined | null | Variable<any, string>,
	otherImages?: ValueTypes["ImageListUniqueWhere"] | undefined | null | Variable<any, string>,
	informationList?: ValueTypes["InformationListUniqueWhere"] | undefined | null | Variable<any, string>,
	embed?: ValueTypes["EmbedUniqueWhere"] | undefined | null | Variable<any, string>,
	links?: ValueTypes["LinkListUniqueWhere"] | undefined | null | Variable<any, string>,
	talkLists?: ValueTypes["TalkListsUniqueWhere"] | undefined | null | Variable<any, string>,
	businessCardList?: ValueTypes["BusinessCardListUniqueWhere"] | undefined | null | Variable<any, string>,
	testimonials?: ValueTypes["TestimonialListUniqueWhere"] | undefined | null | Variable<any, string>,
	contactRequestOptions?: ValueTypes["ContactRequestOptionListUniqueWhere"] | undefined | null | Variable<any, string>,
	youtubeVideo?: ValueTypes["YoutubeVideoUniqueWhere"] | undefined | null | Variable<any, string>,
	positionCategories?: ValueTypes["ContentReferencePositionCategoryItemUniqueWhere"] | undefined | null | Variable<any, string>,
	caseStudies?: ValueTypes["ContentReferenceCaseStudyItemUniqueWhere"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["ContentReferenceTagItemUniqueWhere"] | undefined | null | Variable<any, string>,
	positions?: ValueTypes["PositionListUniqueWhere"] | undefined | null | Variable<any, string>,
	testimonialsFull?: ValueTypes["TestimonialFullListUniqueWhere"] | undefined | null | Variable<any, string>,
	blogPosts?: ValueTypes["ContentReferenceBlogPostItemUniqueWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LinkUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["CaseStudyListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["CaseStudyListItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["CaseStudyListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["ImageListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["ImageListItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ImageListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["InformationListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["PieceOfInformationUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PieceOfInformationUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["EmbedUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["LinkListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["LinkListItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LinkListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TalkListsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["TalkListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TalkListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["TalkListItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TalkListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["BusinessCardListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["BusinessCardListItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BusinessCardListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	businessCard?: ValueTypes["BusinessCardUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BusinessCardUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["TestimonialListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["TestimonialListItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TestimonialListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	testimonial?: ValueTypes["TestimonialUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TestimonialUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["ContactRequestOptionListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["ContactRequestOptionUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContactRequestOptionUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["YoutubeVideoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["ContentReferencePositionCategoryItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["PositionCategoryUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PositionCategoryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	positions?: ValueTypes["PositionUniqueWhere"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["PositionCategoryLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PositionUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["PositionTagListUniqueWhere"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["PositionLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PositionTagListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["PositionTagListItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PositionTagListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["PositionLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["PositionUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LinkableUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	genericPage?: ValueTypes["GenericPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	caseStudy?: ValueTypes["CaseStudyLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	position?: ValueTypes["PositionLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	redirect?: ValueTypes["RedirectUniqueWhere"] | undefined | null | Variable<any, string>,
	blogPost?: ValueTypes["BlogPostLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GenericPageUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["GenericPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["CaseStudyLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["CaseStudyUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["CaseStudyUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["CaseStudyLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["CaseStudyTagUniqueWhere"] | undefined | null | Variable<any, string>
};
	["CaseStudyTagUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["RedirectUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>,
	target?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BlogPostLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["BlogPostUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BlogPostUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["BlogPostLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	relevantBlogPosts?: ValueTypes["RelevantBlogPostListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["RelevantBlogPostListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	blogPost?: ValueTypes["BlogPostUniqueWhere"] | undefined | null | Variable<any, string>,
	relevantBlogPost?: ValueTypes["BlogPostUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PositionCategoryLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["PositionCategoryUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceCaseStudyItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["CaseStudyUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceTagItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["TagUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TagUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["TagLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	caseStudyTags?: ValueTypes["CaseStudyTagUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TagLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	slug?: string | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["TagUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PositionListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["PositionListItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PositionListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["TestimonialFullListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["TestimonialFullListItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TestimonialFullListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	testimonial?: ValueTypes["TestimonialFullUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TestimonialFullUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["ContentReferenceBlogPostItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["BlogPostUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HeaderLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["HeaderUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	linkList?: ValueTypes["LinkListUniqueWhere"] | undefined | null | Variable<any, string>,
	socialLinkList?: ValueTypes["LinkListUniqueWhere"] | undefined | null | Variable<any, string>,
	topLevelLinkItems?: ValueTypes["TopLevelLinkItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HeaderUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["HeaderLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TopLevelLinkItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	secondLevelLinkItems?: ValueTypes["SecondLevelLinkItemUniqueWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["SecondLevelLinkItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>,
	thirdLevelLinkItems?: ValueTypes["ThirdLevelLinkItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ThirdLevelLinkItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["FooterUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	navigation?: ValueTypes["LinkListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["FooterLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PositionTagLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	slug?: string | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["PositionTagUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PositionTagUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["PositionTagLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["AuthorLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["AuthorUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["AuthorUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["AuthorLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	blogPosts?: ValueTypes["BlogPostUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BlogPostCommonLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["BlogPostCommonUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BlogPostCommonUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["BlogPostCommonLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GenericPageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PageInfo"]: AliasType<{
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocaleEdge"]: AliasType<{
	node?:ValueTypes["GenericPageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Content"]: AliasType<{
	_meta?:ValueTypes["ContentMeta"],
	id?:boolean | `@${string}`,
blocks?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
blocksByReferences?: [{	by: ValueTypes["ContentBlocksByReferencesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
paginateBlocks?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlockConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	blocks?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlock"]: AliasType<{
	_meta?:ValueTypes["ContentBlockMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	json?:boolean | `@${string}`,
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
references?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByLink?: [{	by: ValueTypes["ContentBlockReferencesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByFeaturedCaseStudies?: [{	by: ValueTypes["ContentBlockReferencesByFeaturedCaseStudiesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByImages?: [{	by: ValueTypes["ContentBlockReferencesByImagesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByOtherImages?: [{	by: ValueTypes["ContentBlockReferencesByOtherImagesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByInformationList?: [{	by: ValueTypes["ContentBlockReferencesByInformationListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByEmbed?: [{	by: ValueTypes["ContentBlockReferencesByEmbedUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByLinks?: [{	by: ValueTypes["ContentBlockReferencesByLinksUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByTalkLists?: [{	by: ValueTypes["ContentBlockReferencesByTalkListsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByBusinessCardList?: [{	by: ValueTypes["ContentBlockReferencesByBusinessCardListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByTestimonials?: [{	by: ValueTypes["ContentBlockReferencesByTestimonialsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByContactRequestOptions?: [{	by: ValueTypes["ContentBlockReferencesByContactRequestOptionsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByYoutubeVideo?: [{	by: ValueTypes["ContentBlockReferencesByYoutubeVideoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByPositionCategories?: [{	by: ValueTypes["ContentBlockReferencesByPositionCategoriesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByCaseStudies?: [{	by: ValueTypes["ContentBlockReferencesByCaseStudiesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByTags?: [{	by: ValueTypes["ContentBlockReferencesByTagsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByPositions?: [{	by: ValueTypes["ContentBlockReferencesByPositionsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByTestimonialsFull?: [{	by: ValueTypes["ContentBlockReferencesByTestimonialsFullUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByBlogPosts?: [{	by: ValueTypes["ContentBlockReferencesByBlogPostsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByContent?: [{	by: ValueTypes["ContentBlockReferencesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
paginateReferences?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	json?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	references?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReference"]: AliasType<{
	_meta?:ValueTypes["ContentReferenceMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	primaryText?:boolean | `@${string}`,
block?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
	secondaryText?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
featuredCaseStudies?: [{	filter?: ValueTypes["CaseStudyListWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyList"]],
images?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
otherImages?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
informationList?: [{	filter?: ValueTypes["InformationListWhere"] | undefined | null | Variable<any, string>},ValueTypes["InformationList"]],
	tertiaryText?:boolean | `@${string}`,
embed?: [{	filter?: ValueTypes["EmbedWhere"] | undefined | null | Variable<any, string>},ValueTypes["Embed"]],
links?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["LinkList"]],
talkLists?: [{	filter?: ValueTypes["TalkListsWhere"] | undefined | null | Variable<any, string>},ValueTypes["TalkLists"]],
businessCardList?: [{	filter?: ValueTypes["BusinessCardListWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessCardList"]],
testimonials?: [{	filter?: ValueTypes["TestimonialListWhere"] | undefined | null | Variable<any, string>},ValueTypes["TestimonialList"]],
	background?:boolean | `@${string}`,
otherImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
caseStudy?: [{	filter?: ValueTypes["CaseStudyWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudy"]],
	alternative?:boolean | `@${string}`,
contactRequestOptions?: [{	filter?: ValueTypes["ContactRequestOptionListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContactRequestOptionList"]],
youtubeVideo?: [{	filter?: ValueTypes["YoutubeVideoWhere"] | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideo"]],
subscribeForm?: [{	filter?: ValueTypes["SubscribeFormWhere"] | undefined | null | Variable<any, string>},ValueTypes["SubscribeForm"]],
video?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Video"]],
otherVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Video"]],
positionCategories?: [{	filter?: ValueTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferencePositionCategoryItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferencePositionCategoryItem"]],
caseStudies?: [{	filter?: ValueTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceCaseStudyItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceCaseStudyItem"]],
tags?: [{	filter?: ValueTypes["ContentReferenceTagItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceTagItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceTagItem"]],
positions?: [{	filter?: ValueTypes["PositionListWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionList"]],
testimonialsFull?: [{	filter?: ValueTypes["TestimonialFullListWhere"] | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullList"]],
blogPosts?: [{	filter?: ValueTypes["ContentReferenceBlogPostItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceBlogPostItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceBlogPostItem"]],
	verticalMargin?:boolean | `@${string}`,
	variant?:boolean | `@${string}`,
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
positionCategoriesByItem?: [{	by: ValueTypes["ContentReferencePositionCategoriesByItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReferencePositionCategoryItem"]],
caseStudiesByItem?: [{	by: ValueTypes["ContentReferenceCaseStudiesByItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceCaseStudyItem"]],
tagsByItem?: [{	by: ValueTypes["ContentReferenceTagsByItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceTagItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceTagItem"]],
blogPostsByItem?: [{	by: ValueTypes["ContentReferenceBlogPostsByItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceBlogPostItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceBlogPostItem"]],
paginatePositionCategories?: [{	filter?: ValueTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferencePositionCategoryItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferencePositionCategoryItemConnection"]],
paginateCaseStudies?: [{	filter?: ValueTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceCaseStudyItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceCaseStudyItemConnection"]],
paginateTags?: [{	filter?: ValueTypes["ContentReferenceTagItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceTagItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceTagItemConnection"]],
paginateBlogPosts?: [{	filter?: ValueTypes["ContentReferenceBlogPostItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceBlogPostItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceBlogPostItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	primaryText?:ValueTypes["FieldMeta"],
	block?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	secondaryText?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	featuredCaseStudies?:ValueTypes["FieldMeta"],
	images?:ValueTypes["FieldMeta"],
	otherImages?:ValueTypes["FieldMeta"],
	informationList?:ValueTypes["FieldMeta"],
	tertiaryText?:ValueTypes["FieldMeta"],
	embed?:ValueTypes["FieldMeta"],
	links?:ValueTypes["FieldMeta"],
	talkLists?:ValueTypes["FieldMeta"],
	businessCardList?:ValueTypes["FieldMeta"],
	testimonials?:ValueTypes["FieldMeta"],
	background?:ValueTypes["FieldMeta"],
	otherImage?:ValueTypes["FieldMeta"],
	caseStudy?:ValueTypes["FieldMeta"],
	alternative?:ValueTypes["FieldMeta"],
	contactRequestOptions?:ValueTypes["FieldMeta"],
	youtubeVideo?:ValueTypes["FieldMeta"],
	subscribeForm?:ValueTypes["FieldMeta"],
	video?:ValueTypes["FieldMeta"],
	otherVideo?:ValueTypes["FieldMeta"],
	positionCategories?:ValueTypes["FieldMeta"],
	caseStudies?:ValueTypes["FieldMeta"],
	tags?:ValueTypes["FieldMeta"],
	positions?:ValueTypes["FieldMeta"],
	testimonialsFull?:ValueTypes["FieldMeta"],
	blogPosts?:ValueTypes["FieldMeta"],
	verticalMargin?:ValueTypes["FieldMeta"],
	variant?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Image"]: AliasType<{
	_meta?:ValueTypes["ImageMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	alt?:boolean | `@${string}`,
	fileName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ImageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	width?:ValueTypes["FieldMeta"],
	height?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	alt?:ValueTypes["FieldMeta"],
	fileName?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Link"]: AliasType<{
	_meta?:ValueTypes["LinkMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	externalLink?:boolean | `@${string}`,
internalLink?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
	type?:boolean | `@${string}`,
	isTargetBlank?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LinkMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	externalLink?:ValueTypes["FieldMeta"],
	internalLink?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	isTargetBlank?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Linkable"]: AliasType<{
	_meta?:ValueTypes["LinkableMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
genericPage?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
caseStudy?: [{	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocale"]],
position?: [{	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionLocale"]],
redirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>},ValueTypes["Redirect"]],
blogPost?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkableMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	genericPage?:ValueTypes["FieldMeta"],
	caseStudy?:ValueTypes["FieldMeta"],
	position?:ValueTypes["FieldMeta"],
	redirect?:ValueTypes["FieldMeta"],
	blogPost?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyLocale"]: AliasType<{
	_meta?:ValueTypes["CaseStudyLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["CaseStudyWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudy"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
	note?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudy"]: AliasType<{
	_meta?:ValueTypes["CaseStudyMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocale"]],
tags?: [{	filter?: ValueTypes["CaseStudyTagWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyTagOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyTag"]],
wideImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
narrowImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
	order?:boolean | `@${string}`,
	publishedAt?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["CaseStudyLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocale"]],
localesByContent?: [{	by: ValueTypes["CaseStudyLocalesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocale"]],
localesByLink?: [{	by: ValueTypes["CaseStudyLocalesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocale"]],
localesBySeo?: [{	by: ValueTypes["CaseStudyLocalesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocale"]],
paginateLocales?: [{	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocaleConnection"]],
paginateTags?: [{	filter?: ValueTypes["CaseStudyTagWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyTagOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyTagConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	tags?:ValueTypes["FieldMeta"],
	wideImage?:ValueTypes["FieldMeta"],
	narrowImage?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	publishedAt?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyTag"]: AliasType<{
	_meta?:ValueTypes["CaseStudyTagMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
caseStudy?: [{	filter?: ValueTypes["CaseStudyWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudy"]],
tag?: [{	filter?: ValueTypes["TagWhere"] | undefined | null | Variable<any, string>},ValueTypes["Tag"]],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyTagMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	caseStudy?:ValueTypes["FieldMeta"],
	tag?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Tag"]: AliasType<{
	_meta?:ValueTypes["TagMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["TagLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TagLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TagLocale"]],
caseStudyTags?: [{	filter?: ValueTypes["CaseStudyTagWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyTagOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyTag"]],
	order?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["TagLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TagLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["TagLocale"]],
paginateLocales?: [{	filter?: ValueTypes["TagLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TagLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TagLocaleConnection"]],
paginateCaseStudyTags?: [{	filter?: ValueTypes["CaseStudyTagWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyTagOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyTagConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TagMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	caseStudyTags?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TagLocale"]: AliasType<{
	_meta?:ValueTypes["TagLocaleMeta"],
	id?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["TagWhere"] | undefined | null | Variable<any, string>},ValueTypes["Tag"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["TagLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	slug?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TagLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["TagOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>
};
	["TagOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["CaseStudyTagOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	caseStudy?: ValueTypes["CaseStudyOrderBy"] | undefined | null | Variable<any, string>,
	tag?: ValueTypes["TagOrderBy"] | undefined | null | Variable<any, string>
};
	["TagLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TagLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TagLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TagLocaleEdge"]: AliasType<{
	node?:ValueTypes["TagLocale"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyTagConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CaseStudyTagEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyTagEdge"]: AliasType<{
	node?:ValueTypes["CaseStudyTag"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["CaseStudyLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["CaseStudyLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["CaseStudyLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["CaseStudyLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CaseStudyLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyLocaleEdge"]: AliasType<{
	node?:ValueTypes["CaseStudyLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Seo"]: AliasType<{
	_meta?:ValueTypes["SeoMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	ogTitle?:boolean | `@${string}`,
	ogDescription?:boolean | `@${string}`,
ogImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
	noIndex?:boolean | `@${string}`,
	noFollow?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SeoMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	ogTitle?:ValueTypes["FieldMeta"],
	ogDescription?:ValueTypes["FieldMeta"],
	ogImage?:ValueTypes["FieldMeta"],
	noIndex?:ValueTypes["FieldMeta"],
	noFollow?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionLocale"]: AliasType<{
	_meta?:ValueTypes["PositionLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
root?: [{	filter?: ValueTypes["PositionWhere"] | undefined | null | Variable<any, string>},ValueTypes["Position"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
	summary?:boolean | `@${string}`,
	salaryRange?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PositionLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	summary?:ValueTypes["FieldMeta"],
	salaryRange?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Position"]: AliasType<{
	_meta?:ValueTypes["PositionMeta"],
	id?:boolean | `@${string}`,
tags?: [{	filter?: ValueTypes["PositionTagListWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionTagList"]],
locales?: [{	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionLocale"]],
	orderInCategory?:boolean | `@${string}`,
category?: [{	filter?: ValueTypes["PositionCategoryWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionCategory"]],
	publishedAt?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["PositionLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionLocale"]],
localesByLink?: [{	by: ValueTypes["PositionLocalesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionLocale"]],
localesBySeo?: [{	by: ValueTypes["PositionLocalesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionLocale"]],
localesByContent?: [{	by: ValueTypes["PositionLocalesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionLocale"]],
paginateLocales?: [{	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	tags?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	orderInCategory?:ValueTypes["FieldMeta"],
	category?:ValueTypes["FieldMeta"],
	publishedAt?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagList"]: AliasType<{
	_meta?:ValueTypes["PositionTagListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["PositionTagListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagListItem"]],
paginateItems?: [{	filter?: ValueTypes["PositionTagListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagListItem"]: AliasType<{
	_meta?:ValueTypes["PositionTagListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["PositionTagListWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionTagList"]],
tag?: [{	filter?: ValueTypes["PositionTagWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionTag"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	tag?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTag"]: AliasType<{
	_meta?:ValueTypes["PositionTagMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["PositionTagLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagLocale"]],
localesByLocale?: [{	by: ValueTypes["PositionTagLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionTagLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionTagLocale"]],
paginateLocales?: [{	filter?: ValueTypes["PositionTagLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagLocale"]: AliasType<{
	_meta?:ValueTypes["PositionTagLocaleMeta"],
	id?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["PositionTagWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionTag"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	slug?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["PositionTagOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>
};
	["PositionTagOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["PositionTagLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PositionTagLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PositionTagLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagLocaleEdge"]: AliasType<{
	node?:ValueTypes["PositionTagLocale"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagListItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["PositionTagListOrderBy"] | undefined | null | Variable<any, string>,
	tag?: ValueTypes["PositionTagOrderBy"] | undefined | null | Variable<any, string>
};
	["PositionTagListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PositionTagListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagListItemEdge"]: AliasType<{
	node?:ValueTypes["PositionTagListItem"],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategory"]: AliasType<{
	_meta?:ValueTypes["PositionCategoryMeta"],
	id?:boolean | `@${string}`,
positions?: [{	filter?: ValueTypes["PositionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Position"]],
locales?: [{	filter?: ValueTypes["PositionCategoryLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionCategoryLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionCategoryLocale"]],
	order?:boolean | `@${string}`,
positionsByTags?: [{	by: ValueTypes["PositionCategoryPositionsByTagsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionWhere"] | undefined | null | Variable<any, string>},ValueTypes["Position"]],
positionsByLocales?: [{	by: ValueTypes["PositionCategoryPositionsByLocalesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionWhere"] | undefined | null | Variable<any, string>},ValueTypes["Position"]],
localesByLocale?: [{	by: ValueTypes["PositionCategoryLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionCategoryLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionCategoryLocale"]],
paginatePositions?: [{	filter?: ValueTypes["PositionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionConnection"]],
paginateLocales?: [{	filter?: ValueTypes["PositionCategoryLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionCategoryLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionCategoryLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategoryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	positions?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategoryLocale"]: AliasType<{
	_meta?:ValueTypes["PositionCategoryLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["PositionCategoryWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionCategory"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategoryLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategoryLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["PositionCategoryOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>
};
	["PositionCategoryPositionsByTagsUniqueWhere"]: {
	tags?: ValueTypes["PositionTagListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PositionCategoryPositionsByLocalesUniqueWhere"]: {
	locales?: ValueTypes["PositionLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PositionCategoryLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PositionConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PositionEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionEdge"]: AliasType<{
	node?:ValueTypes["Position"],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategoryLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PositionCategoryLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategoryLocaleEdge"]: AliasType<{
	node?:ValueTypes["PositionCategoryLocale"],
		__typename?: boolean | `@${string}`
}>;
	["PositionLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PositionLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PositionLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PositionLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["PositionLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PositionLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionLocaleEdge"]: AliasType<{
	node?:ValueTypes["PositionLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Redirect"]: AliasType<{
	_meta?:ValueTypes["RedirectMeta"],
	id?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
target?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["RedirectMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	target?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocale"]: AliasType<{
	_meta?:ValueTypes["BlogPostLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPost"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
	lead?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	lead?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPost"]: AliasType<{
	_meta?:ValueTypes["BlogPostMeta"],
	id?:boolean | `@${string}`,
	publishedAt?:boolean | `@${string}`,
author?: [{	filter?: ValueTypes["AuthorWhere"] | undefined | null | Variable<any, string>},ValueTypes["Author"]],
wideImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
narrowImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
locales?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocale"]],
relevantBlogPosts?: [{	filter?: ValueTypes["RelevantBlogPostListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RelevantBlogPostListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["RelevantBlogPostList"]],
localesByLocale?: [{	by: ValueTypes["BlogPostLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocale"]],
localesByContent?: [{	by: ValueTypes["BlogPostLocalesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocale"]],
localesBySeo?: [{	by: ValueTypes["BlogPostLocalesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocale"]],
localesByLink?: [{	by: ValueTypes["BlogPostLocalesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocale"]],
relevantBlogPostsByRelevantBlogPost?: [{	by: ValueTypes["BlogPostRelevantBlogPostsByRelevantBlogPostUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["RelevantBlogPostListWhere"] | undefined | null | Variable<any, string>},ValueTypes["RelevantBlogPostList"]],
paginateLocales?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocaleConnection"]],
paginateRelevantBlogPosts?: [{	filter?: ValueTypes["RelevantBlogPostListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RelevantBlogPostListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["RelevantBlogPostListConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	publishedAt?:ValueTypes["FieldMeta"],
	author?:ValueTypes["FieldMeta"],
	wideImage?:ValueTypes["FieldMeta"],
	narrowImage?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	relevantBlogPosts?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Author"]: AliasType<{
	_meta?:ValueTypes["AuthorMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["AuthorLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuthorLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["AuthorLocale"]],
blogPosts?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPost"]],
	name?:boolean | `@${string}`,
	profileUrl?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
avatarImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
localesByLocale?: [{	by: ValueTypes["AuthorLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["AuthorLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["AuthorLocale"]],
blogPostsByLocales?: [{	by: ValueTypes["AuthorBlogPostsByLocalesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPost"]],
blogPostsByRelevantBlogPosts?: [{	by: ValueTypes["AuthorBlogPostsByRelevantBlogPostsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPost"]],
paginateLocales?: [{	filter?: ValueTypes["AuthorLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuthorLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["AuthorLocaleConnection"]],
paginateBlogPosts?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["AuthorMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	blogPosts?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	profileUrl?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	avatarImage?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AuthorLocale"]: AliasType<{
	_meta?:ValueTypes["AuthorLocaleMeta"],
	id?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	bio?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["AuthorWhere"] | undefined | null | Variable<any, string>},ValueTypes["Author"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["AuthorLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	role?:ValueTypes["FieldMeta"],
	bio?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AuthorLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	role?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	bio?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["AuthorOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>
};
	["AuthorLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["AuthorBlogPostsByLocalesUniqueWhere"]: {
	locales?: ValueTypes["BlogPostLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["AuthorBlogPostsByRelevantBlogPostsUniqueWhere"]: {
	relevantBlogPosts?: ValueTypes["RelevantBlogPostListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["AuthorLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["AuthorLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AuthorLocaleEdge"]: AliasType<{
	node?:ValueTypes["AuthorLocale"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogPostEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostEdge"]: AliasType<{
	node?:ValueTypes["BlogPost"],
		__typename?: boolean | `@${string}`
}>;
	["RelevantBlogPostList"]: AliasType<{
	_meta?:ValueTypes["RelevantBlogPostListMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
blogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPost"]],
relevantBlogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPost"]],
		__typename?: boolean | `@${string}`
}>;
	["RelevantBlogPostListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	blogPost?:ValueTypes["FieldMeta"],
	relevantBlogPost?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["RelevantBlogPostListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	blogPost?: ValueTypes["BlogPostOrderBy"] | undefined | null | Variable<any, string>,
	relevantBlogPost?: ValueTypes["BlogPostOrderBy"] | undefined | null | Variable<any, string>
};
	["BlogPostLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BlogPostLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BlogPostLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BlogPostLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BlogPostRelevantBlogPostsByRelevantBlogPostUniqueWhere"]: {
	relevantBlogPost?: ValueTypes["BlogPostUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BlogPostLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogPostLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocaleEdge"]: AliasType<{
	node?:ValueTypes["BlogPostLocale"],
		__typename?: boolean | `@${string}`
}>;
	["RelevantBlogPostListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["RelevantBlogPostListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["RelevantBlogPostListEdge"]: AliasType<{
	node?:ValueTypes["RelevantBlogPostList"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyList"]: AliasType<{
	_meta?:ValueTypes["CaseStudyListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["CaseStudyListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyListItem"]],
paginateItems?: [{	filter?: ValueTypes["CaseStudyListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyListItem"]: AliasType<{
	_meta?:ValueTypes["CaseStudyListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["CaseStudyListWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyList"]],
caseStudy?: [{	filter?: ValueTypes["CaseStudyWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudy"]],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	caseStudy?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyListItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["CaseStudyListOrderBy"] | undefined | null | Variable<any, string>,
	caseStudy?: ValueTypes["CaseStudyOrderBy"] | undefined | null | Variable<any, string>
};
	["CaseStudyListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["CaseStudyListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CaseStudyListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyListItemEdge"]: AliasType<{
	node?:ValueTypes["CaseStudyListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ImageList"]: AliasType<{
	_meta?:ValueTypes["ImageListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItem"]],
itemsByImage?: [{	by: ValueTypes["ImageListItemsByImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageListItem"]],
paginateItems?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItem"]: AliasType<{
	_meta?:ValueTypes["ImageListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["ImageListOrderBy"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["ImageListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ImageListItemsByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ImageListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemEdge"]: AliasType<{
	node?:ValueTypes["ImageListItem"],
		__typename?: boolean | `@${string}`
}>;
	["InformationList"]: AliasType<{
	_meta?:ValueTypes["InformationListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["PieceOfInformationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PieceOfInformationOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PieceOfInformation"]],
paginateItems?: [{	filter?: ValueTypes["PieceOfInformationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PieceOfInformationOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PieceOfInformationConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["InformationListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PieceOfInformation"]: AliasType<{
	_meta?:ValueTypes["PieceOfInformationMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["InformationListWhere"] | undefined | null | Variable<any, string>},ValueTypes["InformationList"]],
		__typename?: boolean | `@${string}`
}>;
	["PieceOfInformationMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PieceOfInformationOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["InformationListOrderBy"] | undefined | null | Variable<any, string>
};
	["InformationListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["PieceOfInformationConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PieceOfInformationEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PieceOfInformationEdge"]: AliasType<{
	node?:ValueTypes["PieceOfInformation"],
		__typename?: boolean | `@${string}`
}>;
	["Embed"]: AliasType<{
	_meta?:ValueTypes["EmbedMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	embedId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EmbedMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	embedId?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkList"]: AliasType<{
	_meta?:ValueTypes["LinkListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["LinkListItem"]],
itemsByLink?: [{	by: ValueTypes["LinkListItemsByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["LinkListItem"]],
paginateItems?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItem"]: AliasType<{
	_meta?:ValueTypes["LinkListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["LinkList"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["LinkListOrderBy"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>
};
	["LinkListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["LinkListItemsByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LinkListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItemEdge"]: AliasType<{
	node?:ValueTypes["LinkListItem"],
		__typename?: boolean | `@${string}`
}>;
	["TalkLists"]: AliasType<{
	_meta?:ValueTypes["TalkListsMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["TalkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TalkList"]],
itemsByItems?: [{	by: ValueTypes["TalkListsItemsByItemsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TalkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["TalkList"]],
paginateItems?: [{	filter?: ValueTypes["TalkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TalkListConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TalkListsMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TalkList"]: AliasType<{
	_meta?:ValueTypes["TalkListMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["TalkListsWhere"] | undefined | null | Variable<any, string>},ValueTypes["TalkLists"]],
items?: [{	filter?: ValueTypes["TalkListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TalkListItem"]],
paginateItems?: [{	filter?: ValueTypes["TalkListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TalkListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TalkListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TalkListItem"]: AliasType<{
	_meta?:ValueTypes["TalkListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["TalkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["TalkList"]],
talk?: [{	filter?: ValueTypes["TalkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Talk"]],
		__typename?: boolean | `@${string}`
}>;
	["TalkListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	talk?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Talk"]: AliasType<{
	_meta?:ValueTypes["TalkMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
coverImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
otherImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["TalkMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	slug?:ValueTypes["FieldMeta"],
	date?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	coverImage?:ValueTypes["FieldMeta"],
	otherImage?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TalkListItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["TalkListOrderBy"] | undefined | null | Variable<any, string>,
	talk?: ValueTypes["TalkOrderBy"] | undefined | null | Variable<any, string>
};
	["TalkListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["TalkListsOrderBy"] | undefined | null | Variable<any, string>
};
	["TalkListsOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["TalkOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	note?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	coverImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	otherImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["TalkListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TalkListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TalkListItemEdge"]: AliasType<{
	node?:ValueTypes["TalkListItem"],
		__typename?: boolean | `@${string}`
}>;
	["TalkListsItemsByItemsUniqueWhere"]: {
	items?: ValueTypes["TalkListItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TalkListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TalkListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TalkListEdge"]: AliasType<{
	node?:ValueTypes["TalkList"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardList"]: AliasType<{
	_meta?:ValueTypes["BusinessCardListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["BusinessCardListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCardListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessCardListItem"]],
itemsByBusinessCard?: [{	by: ValueTypes["BusinessCardListItemsByBusinessCardUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessCardListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessCardListItem"]],
paginateItems?: [{	filter?: ValueTypes["BusinessCardListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCardListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessCardListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardListItem"]: AliasType<{
	_meta?:ValueTypes["BusinessCardListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["BusinessCardListWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessCardList"]],
businessCard?: [{	filter?: ValueTypes["BusinessCardWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessCard"]],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	businessCard?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCard"]: AliasType<{
	_meta?:ValueTypes["BusinessCardMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
avatar?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
	phone?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	role?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
	avatar?:ValueTypes["FieldMeta"],
	phone?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardListItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["BusinessCardListOrderBy"] | undefined | null | Variable<any, string>,
	businessCard?: ValueTypes["BusinessCardOrderBy"] | undefined | null | Variable<any, string>
};
	["BusinessCardListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["BusinessCardOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	role?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	avatar?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	phone?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["BusinessCardListItemsByBusinessCardUniqueWhere"]: {
	businessCard?: ValueTypes["BusinessCardUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BusinessCardListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BusinessCardListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardListItemEdge"]: AliasType<{
	node?:ValueTypes["BusinessCardListItem"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialList"]: AliasType<{
	_meta?:ValueTypes["TestimonialListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["TestimonialListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialListItem"]],
itemsByTestimonial?: [{	by: ValueTypes["TestimonialListItemsByTestimonialUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TestimonialListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["TestimonialListItem"]],
paginateItems?: [{	filter?: ValueTypes["TestimonialListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialListItem"]: AliasType<{
	_meta?:ValueTypes["TestimonialListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["TestimonialListWhere"] | undefined | null | Variable<any, string>},ValueTypes["TestimonialList"]],
testimonial?: [{	filter?: ValueTypes["TestimonialWhere"] | undefined | null | Variable<any, string>},ValueTypes["Testimonial"]],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	testimonial?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Testimonial"]: AliasType<{
	_meta?:ValueTypes["TestimonialMeta"],
	id?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
avatar?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	avatar?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialListItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["TestimonialListOrderBy"] | undefined | null | Variable<any, string>,
	testimonial?: ValueTypes["TestimonialOrderBy"] | undefined | null | Variable<any, string>
};
	["TestimonialListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["TestimonialOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	note?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	avatar?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["TestimonialListItemsByTestimonialUniqueWhere"]: {
	testimonial?: ValueTypes["TestimonialUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TestimonialListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TestimonialListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialListItemEdge"]: AliasType<{
	node?:ValueTypes["TestimonialListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestOptionList"]: AliasType<{
	_meta?:ValueTypes["ContactRequestOptionListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ContactRequestOptionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContactRequestOptionOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContactRequestOption"]],
paginateItems?: [{	filter?: ValueTypes["ContactRequestOptionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContactRequestOptionOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContactRequestOptionConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestOptionListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestOption"]: AliasType<{
	_meta?:ValueTypes["ContactRequestOptionMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ContactRequestOptionListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContactRequestOptionList"]],
	code?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
subscribeForm?: [{	filter?: ValueTypes["SubscribeFormWhere"] | undefined | null | Variable<any, string>},ValueTypes["SubscribeForm"]],
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestOptionMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	label?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	subscribeForm?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SubscribeForm"]: AliasType<{
	_meta?:ValueTypes["SubscribeFormMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	datacruitJobId?:boolean | `@${string}`,
	datacruitReferenceNumber?:boolean | `@${string}`,
	lastUpdateAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscribeFormMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	datacruitJobId?:ValueTypes["FieldMeta"],
	datacruitReferenceNumber?:ValueTypes["FieldMeta"],
	lastUpdateAt?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestOptionOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	label?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["ContactRequestOptionListOrderBy"] | undefined | null | Variable<any, string>,
	code?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	subscribeForm?: ValueTypes["SubscribeFormOrderBy"] | undefined | null | Variable<any, string>
};
	["ContactRequestOptionListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["SubscribeFormOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	datacruitJobId?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	datacruitReferenceNumber?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	lastUpdateAt?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ContactRequestOptionConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContactRequestOptionEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestOptionEdge"]: AliasType<{
	node?:ValueTypes["ContactRequestOption"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideo"]: AliasType<{
	_meta?:ValueTypes["YoutubeVideoMeta"],
	id?:boolean | `@${string}`,
	videoId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	videoId?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Video"]: AliasType<{
	_meta?:ValueTypes["VideoMeta"],
	id?:boolean | `@${string}`,
	src?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["VideoMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	src?:ValueTypes["FieldMeta"],
	height?:ValueTypes["FieldMeta"],
	width?:ValueTypes["FieldMeta"],
	duration?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferencePositionCategoryItem"]: AliasType<{
	_meta?:ValueTypes["ContentReferencePositionCategoryItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
item?: [{	filter?: ValueTypes["PositionCategoryWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionCategory"]],
reference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferencePositionCategoryItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	item?:ValueTypes["FieldMeta"],
	reference?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferencePositionCategoryItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["PositionCategoryOrderBy"] | undefined | null | Variable<any, string>,
	reference?: ValueTypes["ContentReferenceOrderBy"] | undefined | null | Variable<any, string>
};
	["ContentReferenceOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	primaryText?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	block?: ValueTypes["ContentBlockOrderBy"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	secondaryText?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	featuredCaseStudies?: ValueTypes["CaseStudyListOrderBy"] | undefined | null | Variable<any, string>,
	images?: ValueTypes["ImageListOrderBy"] | undefined | null | Variable<any, string>,
	otherImages?: ValueTypes["ImageListOrderBy"] | undefined | null | Variable<any, string>,
	informationList?: ValueTypes["InformationListOrderBy"] | undefined | null | Variable<any, string>,
	tertiaryText?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	embed?: ValueTypes["EmbedOrderBy"] | undefined | null | Variable<any, string>,
	links?: ValueTypes["LinkListOrderBy"] | undefined | null | Variable<any, string>,
	talkLists?: ValueTypes["TalkListsOrderBy"] | undefined | null | Variable<any, string>,
	businessCardList?: ValueTypes["BusinessCardListOrderBy"] | undefined | null | Variable<any, string>,
	testimonials?: ValueTypes["TestimonialListOrderBy"] | undefined | null | Variable<any, string>,
	background?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	otherImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	caseStudy?: ValueTypes["CaseStudyOrderBy"] | undefined | null | Variable<any, string>,
	alternative?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	contactRequestOptions?: ValueTypes["ContactRequestOptionListOrderBy"] | undefined | null | Variable<any, string>,
	youtubeVideo?: ValueTypes["YoutubeVideoOrderBy"] | undefined | null | Variable<any, string>,
	subscribeForm?: ValueTypes["SubscribeFormOrderBy"] | undefined | null | Variable<any, string>,
	video?: ValueTypes["VideoOrderBy"] | undefined | null | Variable<any, string>,
	otherVideo?: ValueTypes["VideoOrderBy"] | undefined | null | Variable<any, string>,
	positions?: ValueTypes["PositionListOrderBy"] | undefined | null | Variable<any, string>,
	testimonialsFull?: ValueTypes["TestimonialFullListOrderBy"] | undefined | null | Variable<any, string>,
	verticalMargin?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	variant?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentOrderBy"] | undefined | null | Variable<any, string>
};
	["ContentBlockOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	json?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentOrderBy"] | undefined | null | Variable<any, string>
};
	["EmbedOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	embedId?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["YoutubeVideoOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	videoId?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["VideoOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	src?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	duration?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["PositionListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["TestimonialFullListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ContentReferenceCaseStudyItem"]: AliasType<{
	_meta?:ValueTypes["ContentReferenceCaseStudyItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
item?: [{	filter?: ValueTypes["CaseStudyWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudy"]],
reference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceCaseStudyItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	item?:ValueTypes["FieldMeta"],
	reference?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceCaseStudyItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["CaseStudyOrderBy"] | undefined | null | Variable<any, string>,
	reference?: ValueTypes["ContentReferenceOrderBy"] | undefined | null | Variable<any, string>
};
	["ContentReferenceTagItem"]: AliasType<{
	_meta?:ValueTypes["ContentReferenceTagItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
item?: [{	filter?: ValueTypes["TagWhere"] | undefined | null | Variable<any, string>},ValueTypes["Tag"]],
reference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceTagItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	item?:ValueTypes["FieldMeta"],
	reference?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceTagItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["TagOrderBy"] | undefined | null | Variable<any, string>,
	reference?: ValueTypes["ContentReferenceOrderBy"] | undefined | null | Variable<any, string>
};
	["PositionList"]: AliasType<{
	_meta?:ValueTypes["PositionListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["PositionListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionListItem"]],
paginateItems?: [{	filter?: ValueTypes["PositionListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionListItem"]: AliasType<{
	_meta?:ValueTypes["PositionListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["PositionListWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionList"]],
position?: [{	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	position?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionListItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["PositionListOrderBy"] | undefined | null | Variable<any, string>,
	position?: ValueTypes["PositionLocaleOrderBy"] | undefined | null | Variable<any, string>
};
	["PositionListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PositionListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionListItemEdge"]: AliasType<{
	node?:ValueTypes["PositionListItem"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullList"]: AliasType<{
	_meta?:ValueTypes["TestimonialFullListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["TestimonialFullListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialFullListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullListItem"]],
itemsByTestimonial?: [{	by: ValueTypes["TestimonialFullListItemsByTestimonialUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TestimonialFullListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullListItem"]],
paginateItems?: [{	filter?: ValueTypes["TestimonialFullListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialFullListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullListItem"]: AliasType<{
	_meta?:ValueTypes["TestimonialFullListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["TestimonialFullListWhere"] | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullList"]],
testimonial?: [{	filter?: ValueTypes["TestimonialFullWhere"] | undefined | null | Variable<any, string>},ValueTypes["TestimonialFull"]],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	testimonial?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFull"]: AliasType<{
	_meta?:ValueTypes["TestimonialFullMeta"],
	id?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
avatar?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
	imageVariant?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	avatar?:ValueTypes["FieldMeta"],
	imageVariant?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullListItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["TestimonialFullListOrderBy"] | undefined | null | Variable<any, string>,
	testimonial?: ValueTypes["TestimonialFullOrderBy"] | undefined | null | Variable<any, string>
};
	["TestimonialFullOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	note?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	avatar?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	imageVariant?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["TestimonialFullListItemsByTestimonialUniqueWhere"]: {
	testimonial?: ValueTypes["TestimonialFullUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TestimonialFullListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TestimonialFullListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullListItemEdge"]: AliasType<{
	node?:ValueTypes["TestimonialFullListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceBlogPostItem"]: AliasType<{
	_meta?:ValueTypes["ContentReferenceBlogPostItemMeta"],
	id?:boolean | `@${string}`,
	publishedAt?:boolean | `@${string}`,
item?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPost"]],
reference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceBlogPostItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	publishedAt?:ValueTypes["FieldMeta"],
	item?:ValueTypes["FieldMeta"],
	reference?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceBlogPostItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["BlogPostOrderBy"] | undefined | null | Variable<any, string>,
	reference?: ValueTypes["ContentReferenceOrderBy"] | undefined | null | Variable<any, string>
};
	["ContentReferencePositionCategoriesByItemUniqueWhere"]: {
	item?: ValueTypes["PositionCategoryUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceCaseStudiesByItemUniqueWhere"]: {
	item?: ValueTypes["CaseStudyUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceTagsByItemUniqueWhere"]: {
	item?: ValueTypes["TagUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceBlogPostsByItemUniqueWhere"]: {
	item?: ValueTypes["BlogPostUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferencePositionCategoryItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentReferencePositionCategoryItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferencePositionCategoryItemEdge"]: AliasType<{
	node?:ValueTypes["ContentReferencePositionCategoryItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceCaseStudyItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentReferenceCaseStudyItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceCaseStudyItemEdge"]: AliasType<{
	node?:ValueTypes["ContentReferenceCaseStudyItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceTagItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentReferenceTagItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceTagItemEdge"]: AliasType<{
	node?:ValueTypes["ContentReferenceTagItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceBlogPostItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentReferenceBlogPostItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceBlogPostItemEdge"]: AliasType<{
	node?:ValueTypes["ContentReferenceBlogPostItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockReferencesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByFeaturedCaseStudiesUniqueWhere"]: {
	featuredCaseStudies?: ValueTypes["CaseStudyListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByImagesUniqueWhere"]: {
	images?: ValueTypes["ImageListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByOtherImagesUniqueWhere"]: {
	otherImages?: ValueTypes["ImageListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByInformationListUniqueWhere"]: {
	informationList?: ValueTypes["InformationListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByEmbedUniqueWhere"]: {
	embed?: ValueTypes["EmbedUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByLinksUniqueWhere"]: {
	links?: ValueTypes["LinkListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByTalkListsUniqueWhere"]: {
	talkLists?: ValueTypes["TalkListsUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByBusinessCardListUniqueWhere"]: {
	businessCardList?: ValueTypes["BusinessCardListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByTestimonialsUniqueWhere"]: {
	testimonials?: ValueTypes["TestimonialListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByContactRequestOptionsUniqueWhere"]: {
	contactRequestOptions?: ValueTypes["ContactRequestOptionListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByYoutubeVideoUniqueWhere"]: {
	youtubeVideo?: ValueTypes["YoutubeVideoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByPositionCategoriesUniqueWhere"]: {
	positionCategories?: ValueTypes["ContentReferencePositionCategoryItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByCaseStudiesUniqueWhere"]: {
	caseStudies?: ValueTypes["ContentReferenceCaseStudyItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByTagsUniqueWhere"]: {
	tags?: ValueTypes["ContentReferenceTagItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByPositionsUniqueWhere"]: {
	positions?: ValueTypes["PositionListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByTestimonialsFullUniqueWhere"]: {
	testimonialsFull?: ValueTypes["TestimonialFullListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByBlogPostsUniqueWhere"]: {
	blogPosts?: ValueTypes["ContentReferenceBlogPostItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentReferenceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceEdge"]: AliasType<{
	node?:ValueTypes["ContentReference"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlocksByReferencesUniqueWhere"]: {
	references?: ValueTypes["ContentReferenceUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentBlockEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockEdge"]: AliasType<{
	node?:ValueTypes["ContentBlock"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocale"]: AliasType<{
	_meta?:ValueTypes["HeaderLocaleMeta"],
	id?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>},ValueTypes["Header"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
linkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["LinkList"]],
socialLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["LinkList"]],
topLevelLinkItems?: [{	filter?: ValueTypes["TopLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TopLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TopLevelLinkItem"]],
topLevelLinkItemsBySecondLevelLinkItems?: [{	by: ValueTypes["HeaderLocaleTopLevelLinkItemsBySecondLevelLinkItemsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TopLevelLinkItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["TopLevelLinkItem"]],
topLevelLinkItemsByLink?: [{	by: ValueTypes["HeaderLocaleTopLevelLinkItemsByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TopLevelLinkItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["TopLevelLinkItem"]],
paginateTopLevelLinkItems?: [{	filter?: ValueTypes["TopLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TopLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TopLevelLinkItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	linkList?:ValueTypes["FieldMeta"],
	socialLinkList?:ValueTypes["FieldMeta"],
	topLevelLinkItems?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Header"]: AliasType<{
	_meta?:ValueTypes["HeaderMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
localesByLocale?: [{	by: ValueTypes["HeaderLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
localesByLinkList?: [{	by: ValueTypes["HeaderLocalesByLinkListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
localesBySocialLinkList?: [{	by: ValueTypes["HeaderLocalesBySocialLinkListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
localesByTopLevelLinkItems?: [{	by: ValueTypes["HeaderLocalesByTopLevelLinkItemsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
paginateLocales?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["HeaderMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["HeaderOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	linkList?: ValueTypes["LinkListOrderBy"] | undefined | null | Variable<any, string>,
	socialLinkList?: ValueTypes["LinkListOrderBy"] | undefined | null | Variable<any, string>
};
	["HeaderOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["HeaderLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HeaderLocalesByLinkListUniqueWhere"]: {
	linkList?: ValueTypes["LinkListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HeaderLocalesBySocialLinkListUniqueWhere"]: {
	socialLinkList?: ValueTypes["LinkListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HeaderLocalesByTopLevelLinkItemsUniqueWhere"]: {
	topLevelLinkItems?: ValueTypes["TopLevelLinkItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HeaderLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["HeaderLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleEdge"]: AliasType<{
	node?:ValueTypes["HeaderLocale"],
		__typename?: boolean | `@${string}`
}>;
	["TopLevelLinkItem"]: AliasType<{
	_meta?:ValueTypes["TopLevelLinkItemMeta"],
	id?:boolean | `@${string}`,
	isLink?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
secondLevelLinkItems?: [{	filter?: ValueTypes["SecondLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SecondLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SecondLevelLinkItem"]],
header?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
secondLevelLinkItemsByLink?: [{	by: ValueTypes["TopLevelLinkItemSecondLevelLinkItemsByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SecondLevelLinkItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["SecondLevelLinkItem"]],
secondLevelLinkItemsByThirdLevelLinkItems?: [{	by: ValueTypes["TopLevelLinkItemSecondLevelLinkItemsByThirdLevelLinkItemsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SecondLevelLinkItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["SecondLevelLinkItem"]],
paginateSecondLevelLinkItems?: [{	filter?: ValueTypes["SecondLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SecondLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SecondLevelLinkItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TopLevelLinkItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	isLink?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	secondLevelLinkItems?:ValueTypes["FieldMeta"],
	header?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SecondLevelLinkItem"]: AliasType<{
	_meta?:ValueTypes["SecondLevelLinkItemMeta"],
	id?:boolean | `@${string}`,
	isLink?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
topLevelLinkItem?: [{	filter?: ValueTypes["TopLevelLinkItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["TopLevelLinkItem"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
thirdLevelLinkItems?: [{	filter?: ValueTypes["ThirdLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ThirdLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ThirdLevelLinkItem"]],
thirdLevelLinkItemsByLink?: [{	by: ValueTypes["SecondLevelLinkItemThirdLevelLinkItemsByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ThirdLevelLinkItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ThirdLevelLinkItem"]],
paginateThirdLevelLinkItems?: [{	filter?: ValueTypes["ThirdLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ThirdLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ThirdLevelLinkItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["SecondLevelLinkItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	isLink?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	topLevelLinkItem?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	thirdLevelLinkItems?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ThirdLevelLinkItem"]: AliasType<{
	_meta?:ValueTypes["ThirdLevelLinkItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
secondLevelLinkItem?: [{	filter?: ValueTypes["SecondLevelLinkItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["SecondLevelLinkItem"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["ThirdLevelLinkItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	secondLevelLinkItem?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ThirdLevelLinkItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	secondLevelLinkItem?: ValueTypes["SecondLevelLinkItemOrderBy"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>
};
	["SecondLevelLinkItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	isLink?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	topLevelLinkItem?: ValueTypes["TopLevelLinkItemOrderBy"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>
};
	["TopLevelLinkItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	isLink?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	header?: ValueTypes["HeaderLocaleOrderBy"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>
};
	["SecondLevelLinkItemThirdLevelLinkItemsByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ThirdLevelLinkItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ThirdLevelLinkItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ThirdLevelLinkItemEdge"]: AliasType<{
	node?:ValueTypes["ThirdLevelLinkItem"],
		__typename?: boolean | `@${string}`
}>;
	["TopLevelLinkItemSecondLevelLinkItemsByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TopLevelLinkItemSecondLevelLinkItemsByThirdLevelLinkItemsUniqueWhere"]: {
	thirdLevelLinkItems?: ValueTypes["ThirdLevelLinkItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["SecondLevelLinkItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SecondLevelLinkItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SecondLevelLinkItemEdge"]: AliasType<{
	node?:ValueTypes["SecondLevelLinkItem"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleTopLevelLinkItemsBySecondLevelLinkItemsUniqueWhere"]: {
	secondLevelLinkItems?: ValueTypes["SecondLevelLinkItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HeaderLocaleTopLevelLinkItemsByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TopLevelLinkItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TopLevelLinkItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TopLevelLinkItemEdge"]: AliasType<{
	node?:ValueTypes["TopLevelLinkItem"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocale"]: AliasType<{
	_meta?:ValueTypes["FooterLocaleMeta"],
	id?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>},ValueTypes["Footer"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
	address?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	legal?:boolean | `@${string}`,
navigation?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["LinkList"]],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	address?:ValueTypes["FieldMeta"],
	phone?:ValueTypes["FieldMeta"],
	legal?:ValueTypes["FieldMeta"],
	navigation?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Footer"]: AliasType<{
	_meta?:ValueTypes["FooterMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
localesByLocale?: [{	by: ValueTypes["FooterLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
localesByNavigation?: [{	by: ValueTypes["FooterLocalesByNavigationUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
paginateLocales?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["FooterMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["FooterOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	phone?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	legal?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	navigation?: ValueTypes["LinkListOrderBy"] | undefined | null | Variable<any, string>
};
	["FooterOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["FooterLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocalesByNavigationUniqueWhere"]: {
	navigation?: ValueTypes["LinkListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FooterLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleEdge"]: AliasType<{
	node?:ValueTypes["FooterLocale"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostCommonLocale"]: AliasType<{
	_meta?:ValueTypes["BlogPostCommonLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["BlogPostCommonWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommon"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
ctaLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
	ctaTitle?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogPostCommonLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	ctaLink?:ValueTypes["FieldMeta"],
	ctaTitle?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostCommon"]: AliasType<{
	_meta?:ValueTypes["BlogPostCommonMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["BlogPostCommonLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostCommonLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommonLocale"]],
localesByLocale?: [{	by: ValueTypes["BlogPostCommonLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostCommonLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommonLocale"]],
paginateLocales?: [{	filter?: ValueTypes["BlogPostCommonLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostCommonLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommonLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostCommonMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostCommonLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["BlogPostCommonOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	ctaLink?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	ctaTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["BlogPostCommonOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["BlogPostCommonLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BlogPostCommonLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogPostCommonLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostCommonLocaleEdge"]: AliasType<{
	node?:ValueTypes["BlogPostCommonLocale"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleGeneralByRootUniqueWhere"]: {
	root?: ValueTypes["GeneralUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGeneralBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGeneralByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGenericPagesByRootUniqueWhere"]: {
	root?: ValueTypes["GenericPageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGenericPagesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGenericPagesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGenericPagesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHeadersByRootUniqueWhere"]: {
	root?: ValueTypes["HeaderUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHeadersByLinkListUniqueWhere"]: {
	linkList?: ValueTypes["LinkListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHeadersBySocialLinkListUniqueWhere"]: {
	socialLinkList?: ValueTypes["LinkListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHeadersByTopLevelLinkItemsUniqueWhere"]: {
	topLevelLinkItems?: ValueTypes["TopLevelLinkItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleFootersByRootUniqueWhere"]: {
	root?: ValueTypes["FooterUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleFootersByNavigationUniqueWhere"]: {
	navigation?: ValueTypes["LinkListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleCaseStudiesByRootUniqueWhere"]: {
	root?: ValueTypes["CaseStudyUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleCaseStudiesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleCaseStudiesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleCaseStudiesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleTagsBySlugUniqueWhere"]: {
	slug?: string | undefined | null | Variable<any, string>
};
	["LocaleTagsByRootUniqueWhere"]: {
	root?: ValueTypes["TagUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocalePositionTagsBySlugUniqueWhere"]: {
	slug?: string | undefined | null | Variable<any, string>
};
	["LocalePositionTagsByRootUniqueWhere"]: {
	root?: ValueTypes["PositionTagUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocalePositionsByRootUniqueWhere"]: {
	root?: ValueTypes["PositionUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocalePositionsByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocalePositionsBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocalePositionsByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocalePositionCategoriesByRootUniqueWhere"]: {
	root?: ValueTypes["PositionCategoryUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleAuthorsByRootUniqueWhere"]: {
	root?: ValueTypes["AuthorUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleBlogPostsByRootUniqueWhere"]: {
	root?: ValueTypes["BlogPostUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleBlogPostsByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleBlogPostsBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleBlogPostsByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleBlogPostCommonByRootUniqueWhere"]: {
	root?: ValueTypes["BlogPostCommonUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GeneralLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GeneralLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleEdge"]: AliasType<{
	node?:ValueTypes["GeneralLocale"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GeneralLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GeneralLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GeneralConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GeneralEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralEdge"]: AliasType<{
	node?:ValueTypes["General"],
		__typename?: boolean | `@${string}`
}>;
	["ImageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageEdge"]: AliasType<{
	node?:ValueTypes["Image"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GenericPageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageEdge"]: AliasType<{
	node?:ValueTypes["GenericPage"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkableEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableEdge"]: AliasType<{
	node?:ValueTypes["Linkable"],
		__typename?: boolean | `@${string}`
}>;
	["SeoConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SeoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SeoEdge"]: AliasType<{
	node?:ValueTypes["Seo"],
		__typename?: boolean | `@${string}`
}>;
	["ContentConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentEdge"]: AliasType<{
	node?:ValueTypes["Content"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleEdge"]: AliasType<{
	node?:ValueTypes["Locale"],
		__typename?: boolean | `@${string}`
}>;
	["LinkConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkEdge"]: AliasType<{
	node?:ValueTypes["Link"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListEdge"]: AliasType<{
	node?:ValueTypes["LinkList"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["HeaderEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderEdge"]: AliasType<{
	node?:ValueTypes["Header"],
		__typename?: boolean | `@${string}`
}>;
	["FooterConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FooterEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FooterEdge"]: AliasType<{
	node?:ValueTypes["Footer"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CaseStudyEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyEdge"]: AliasType<{
	node?:ValueTypes["CaseStudy"],
		__typename?: boolean | `@${string}`
}>;
	["TagConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TagEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TagEdge"]: AliasType<{
	node?:ValueTypes["Tag"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CaseStudyListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyListEdge"]: AliasType<{
	node?:ValueTypes["CaseStudyList"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListEdge"]: AliasType<{
	node?:ValueTypes["ImageList"],
		__typename?: boolean | `@${string}`
}>;
	["InformationListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["InformationListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["InformationListEdge"]: AliasType<{
	node?:ValueTypes["InformationList"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PositionTagEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagEdge"]: AliasType<{
	node?:ValueTypes["PositionTag"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PositionTagListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagListEdge"]: AliasType<{
	node?:ValueTypes["PositionTagList"],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PositionCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategoryEdge"]: AliasType<{
	node?:ValueTypes["PositionCategory"],
		__typename?: boolean | `@${string}`
}>;
	["EmbedConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["EmbedEdge"],
		__typename?: boolean | `@${string}`
}>;
	["EmbedEdge"]: AliasType<{
	node?:ValueTypes["Embed"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["RedirectEdge"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectEdge"]: AliasType<{
	node?:ValueTypes["Redirect"],
		__typename?: boolean | `@${string}`
}>;
	["TalkUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	slug?: string | undefined | null | Variable<any, string>
};
	["TalkConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TalkEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TalkEdge"]: AliasType<{
	node?:ValueTypes["Talk"],
		__typename?: boolean | `@${string}`
}>;
	["TalkListsConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TalkListsEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TalkListsEdge"]: AliasType<{
	node?:ValueTypes["TalkLists"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BusinessCardEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardEdge"]: AliasType<{
	node?:ValueTypes["BusinessCard"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BusinessCardListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardListEdge"]: AliasType<{
	node?:ValueTypes["BusinessCardList"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TestimonialEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialEdge"]: AliasType<{
	node?:ValueTypes["Testimonial"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TestimonialListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialListEdge"]: AliasType<{
	node?:ValueTypes["TestimonialList"],
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestOptionListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContactRequestOptionListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestOptionListEdge"]: AliasType<{
	node?:ValueTypes["ContactRequestOptionList"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["YoutubeVideoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoEdge"]: AliasType<{
	node?:ValueTypes["YoutubeVideo"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoList"]: AliasType<{
	_meta?:ValueTypes["YoutubeVideoListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["YoutubeVideoListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["YoutubeVideoListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoListItem"]],
itemsByYoutube?: [{	by: ValueTypes["YoutubeVideoListItemsByYoutubeUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["YoutubeVideoListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoListItem"]],
paginateItems?: [{	filter?: ValueTypes["YoutubeVideoListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["YoutubeVideoListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoListItem"]: AliasType<{
	_meta?:ValueTypes["YoutubeVideoListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["YoutubeVideoListWhere"] | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoList"]],
youtube?: [{	filter?: ValueTypes["YoutubeVideoWhere"] | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideo"]],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	youtube?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["YoutubeVideoListItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["YoutubeVideoListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["YoutubeVideoListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["YoutubeVideoListWhere"] | undefined | null | Variable<any, string>
};
	["YoutubeVideoListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["YoutubeVideoListWhere"] | undefined | null | Variable<any, string>,
	youtube?: ValueTypes["YoutubeVideoWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["YoutubeVideoListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["YoutubeVideoListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["YoutubeVideoListItemWhere"] | undefined | null | Variable<any, string>
};
	["YoutubeVideoListItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["YoutubeVideoListOrderBy"] | undefined | null | Variable<any, string>,
	youtube?: ValueTypes["YoutubeVideoOrderBy"] | undefined | null | Variable<any, string>
};
	["YoutubeVideoListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["YoutubeVideoListItemsByYoutubeUniqueWhere"]: {
	youtube?: ValueTypes["YoutubeVideoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["YoutubeVideoListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["YoutubeVideoListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoListItemEdge"]: AliasType<{
	node?:ValueTypes["YoutubeVideoListItem"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["YoutubeVideoListItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["YoutubeVideoListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	youtube?: ValueTypes["YoutubeVideoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["YoutubeVideoListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["YoutubeVideoListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoListEdge"]: AliasType<{
	node?:ValueTypes["YoutubeVideoList"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogue"]: AliasType<{
	_meta?:ValueTypes["TranslationCatalogueMeta"],
	id?:boolean | `@${string}`,
domain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationDomain"]],
identifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCataloguesIdentifier"]],
fallback?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
values?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationValue"]],
valuesByKey?: [{	by: ValueTypes["TranslationCatalogueValuesByKeyUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationValue"]],
paginateValues?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationValueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	domain?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	fallback?:ValueTypes["FieldMeta"],
	values?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomain"]: AliasType<{
	_meta?:ValueTypes["TranslationDomainMeta"],
	id?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
catalogues?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
keys?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationKey"]],
cataloguesByIdentifier?: [{	by: ValueTypes["TranslationDomainCataloguesByIdentifierUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
cataloguesByValues?: [{	by: ValueTypes["TranslationDomainCataloguesByValuesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
keysByIdentifier?: [{	by: ValueTypes["TranslationDomainKeysByIdentifierUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationKey"]],
keysByValues?: [{	by: ValueTypes["TranslationDomainKeysByValuesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationKey"]],
paginateCatalogues?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogueConnection"]],
paginateKeys?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationKeyConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	catalogues?:ValueTypes["FieldMeta"],
	keys?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	domain?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>,
	identifier?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>,
	fallback?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,
	values?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TranslationCatalogueWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TranslationCatalogueWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationDomainWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	identifier?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	catalogues?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,
	keys?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TranslationDomainWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TranslationDomainWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>
};
	["TranslationKeyWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	identifier?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	contentType?: ValueTypes["TranslationContentTypeCondition"] | undefined | null | Variable<any, string>,
	note?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	domain?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>,
	values?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TranslationKeyWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TranslationKeyWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>
};
	["TranslationContentTypeCondition"]: {
	and?: Array<ValueTypes["TranslationContentTypeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TranslationContentTypeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TranslationContentTypeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["TranslationContentType"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["TranslationContentType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["TranslationContentType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["TranslationContentType"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["TranslationContentType"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["TranslationContentType"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["TranslationContentType"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["TranslationContentType"] | undefined | null | Variable<any, string>
};
	["TranslationContentType"]:TranslationContentType;
	["TranslationValueWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	catalogue?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TranslationValueWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TranslationValueWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationCataloguesIdentifierWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	code?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	catalogue?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>
};
	["TranslationCatalogueOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	domain?: ValueTypes["TranslationDomainOrderBy"] | undefined | null | Variable<any, string>,
	identifier?: ValueTypes["TranslationCataloguesIdentifierOrderBy"] | undefined | null | Variable<any, string>,
	fallback?: ValueTypes["TranslationCatalogueOrderBy"] | undefined | null | Variable<any, string>
};
	["TranslationDomainOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	identifier?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["TranslationCataloguesIdentifierOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	code?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["TranslationKey"]: AliasType<{
	_meta?:ValueTypes["TranslationKeyMeta"],
	id?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
domain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationDomain"]],
values?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationValue"]],
valuesByCatalogue?: [{	by: ValueTypes["TranslationKeyValuesByCatalogueUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationValue"]],
paginateValues?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationValueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	contentType?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	domain?:ValueTypes["FieldMeta"],
	values?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValue"]: AliasType<{
	_meta?:ValueTypes["TranslationValueMeta"],
	id?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
catalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
key?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationKey"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	value?:ValueTypes["FieldMeta"],
	catalogue?:ValueTypes["FieldMeta"],
	key?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	catalogue?: ValueTypes["TranslationCatalogueOrderBy"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["TranslationKeyOrderBy"] | undefined | null | Variable<any, string>
};
	["TranslationKeyOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	identifier?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	contentType?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	note?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	domain?: ValueTypes["TranslationDomainOrderBy"] | undefined | null | Variable<any, string>
};
	["TranslationKeyValuesByCatalogueUniqueWhere"]: {
	catalogue?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationCatalogueUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	domain?: ValueTypes["TranslationDomainUniqueWhere"] | undefined | null | Variable<any, string>,
	identifier?: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined | null | Variable<any, string>,
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationDomainUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	identifier?: string | undefined | null | Variable<any, string>,
	catalogues?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null | Variable<any, string>,
	keys?: ValueTypes["TranslationKeyUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationKeyUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	domain?: ValueTypes["TranslationDomainUniqueWhere"] | undefined | null | Variable<any, string>,
	identifier?: string | undefined | null | Variable<any, string>,
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationValueUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	catalogue?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["TranslationKeyUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationCataloguesIdentifierUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	code?: string | undefined | null | Variable<any, string>,
	catalogue?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationValueConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationValueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueEdge"]: AliasType<{
	node?:ValueTypes["TranslationValue"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
	identifier?: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationDomainCataloguesByValuesUniqueWhere"]: {
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationDomainKeysByIdentifierUniqueWhere"]: {
	identifier?: string | undefined | null | Variable<any, string>
};
	["TranslationDomainKeysByValuesUniqueWhere"]: {
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationCatalogueConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationCatalogueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueEdge"]: AliasType<{
	node?:ValueTypes["TranslationCatalogue"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationKeyEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyEdge"]: AliasType<{
	node?:ValueTypes["TranslationKey"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifier"]: AliasType<{
	_meta?:ValueTypes["TranslationCataloguesIdentifierMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
catalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
catalogueByDomain?: [{	by: ValueTypes["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
catalogueByValues?: [{	by: ValueTypes["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
paginateCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	catalogue?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: {
	domain?: ValueTypes["TranslationDomainUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: {
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationCatalogueValuesByKeyUniqueWhere"]: {
	key?: ValueTypes["TranslationKeyUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationCataloguesIdentifierConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationCataloguesIdentifierEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierEdge"]: AliasType<{
	node?:ValueTypes["TranslationCataloguesIdentifier"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationDomainEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainEdge"]: AliasType<{
	node?:ValueTypes["TranslationDomain"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidationResult"]: AliasType<{
	valid?:boolean | `@${string}`,
	errors?:ValueTypes["_ValidationError"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidationError"]: AliasType<{
	path?:ValueTypes["_PathFragment"],
	message?:ValueTypes["_ValidationMessage"],
		__typename?: boolean | `@${string}`
}>;
	["_PathFragment"]: AliasType<{		["...on _FieldPathFragment"] : ValueTypes["_FieldPathFragment"],
		["...on _IndexPathFragment"] : ValueTypes["_IndexPathFragment"]
		__typename?: boolean | `@${string}`
}>;
	["_FieldPathFragment"]: AliasType<{
	field?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_IndexPathFragment"]: AliasType<{
	index?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_ValidationMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestSubmissionCreateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	requestOption?: string | undefined | null | Variable<any, string>,
	givenName?: string | undefined | null | Variable<any, string>,
	familyName?: string | undefined | null | Variable<any, string>,
	text?: string | undefined | null | Variable<any, string>,
	locale?: ValueTypes["ContactRequestSubmissionCreateLocaleEntityRelationInput"] | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	marketingConsent?: boolean | undefined | null | Variable<any, string>,
	_dummy_field_?: boolean | undefined | null | Variable<any, string>
};
	["ContactRequestSubmissionCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["SubscribeFormUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	datacruitJobId?: number | undefined | null | Variable<any, string>
};
	["SubscribeFormConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SubscribeFormEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SubscribeFormEdge"]: AliasType<{
	node?:ValueTypes["SubscribeForm"],
		__typename?: boolean | `@${string}`
}>;
	["SubscribeFormCreateInput"]: {
	submissions?: Array<ValueTypes["SubscribeFormCreateSubmissionsEntityRelationInput"]> | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	datacruitJobId?: number | undefined | null | Variable<any, string>,
	datacruitReferenceNumber?: string | undefined | null | Variable<any, string>,
	lastUpdateAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	_dummy_field_?: boolean | undefined | null | Variable<any, string>
};
	["SubscribeFormCreateSubmissionsEntityRelationInput"]: {
	create?: ValueTypes["SubscribeSubmissionWithoutFormCreateInput"] | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>
};
	["SubscribeSubmissionWithoutFormCreateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	locale?: ValueTypes["SubscribeSubmissionCreateLocaleEntityRelationInput"] | undefined | null | Variable<any, string>,
	firstName?: string | undefined | null | Variable<any, string>,
	lastName?: string | undefined | null | Variable<any, string>,
	datacruitId?: string | undefined | null | Variable<any, string>,
	consent?: boolean | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	attachmentFileName?: string | undefined | null | Variable<any, string>,
	message?: string | undefined | null | Variable<any, string>,
	_dummy_field_?: boolean | undefined | null | Variable<any, string>
};
	["SubscribeSubmissionCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["SubscribeFormUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	datacruitJobId?: number | undefined | null | Variable<any, string>,
	datacruitReferenceNumber?: string | undefined | null | Variable<any, string>,
	lastUpdateAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	_dummy_field_?: boolean | undefined | null | Variable<any, string>
};
	["SubscribeSubmissionCreateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	form?: ValueTypes["SubscribeSubmissionCreateFormEntityRelationInput"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["SubscribeSubmissionCreateLocaleEntityRelationInput"] | undefined | null | Variable<any, string>,
	firstName?: string | undefined | null | Variable<any, string>,
	lastName?: string | undefined | null | Variable<any, string>,
	datacruitId?: string | undefined | null | Variable<any, string>,
	consent?: boolean | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	attachmentFileName?: string | undefined | null | Variable<any, string>,
	message?: string | undefined | null | Variable<any, string>,
	_dummy_field_?: boolean | undefined | null | Variable<any, string>
};
	["SubscribeSubmissionCreateFormEntityRelationInput"]: {
	connect?: ValueTypes["SubscribeFormUniqueWhere"] | undefined | null | Variable<any, string>,
	create?: ValueTypes["SubscribeFormWithoutSubmissionsCreateInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["SubscribeSubmissionConnectOrCreateFormRelationInput"] | undefined | null | Variable<any, string>
};
	["SubscribeFormWithoutSubmissionsCreateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	datacruitJobId?: number | undefined | null | Variable<any, string>,
	datacruitReferenceNumber?: string | undefined | null | Variable<any, string>,
	lastUpdateAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	_dummy_field_?: boolean | undefined | null | Variable<any, string>
};
	["SubscribeSubmissionConnectOrCreateFormRelationInput"]: {
	connect: ValueTypes["SubscribeFormUniqueWhere"] | Variable<any, string>,
	create: ValueTypes["SubscribeFormWithoutSubmissionsCreateInput"] | Variable<any, string>
};
	["VideoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["VideoConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VideoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VideoEdge"]: AliasType<{
	node?:ValueTypes["Video"],
		__typename?: boolean | `@${string}`
}>;
	["PositionListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PositionListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionListEdge"]: AliasType<{
	node?:ValueTypes["PositionList"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TestimonialFullEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullEdge"]: AliasType<{
	node?:ValueTypes["TestimonialFull"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TestimonialFullListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullListEdge"]: AliasType<{
	node?:ValueTypes["TestimonialFullList"],
		__typename?: boolean | `@${string}`
}>;
	["AuthorConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["AuthorEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AuthorEdge"]: AliasType<{
	node?:ValueTypes["Author"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostCommonConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogPostCommonEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostCommonEdge"]: AliasType<{
	node?:ValueTypes["BlogPostCommon"],
		__typename?: boolean | `@${string}`
}>;
	["QueryTransaction"]: AliasType<{
	schema?:ValueTypes["_Schema"],
	s3DummyQuery?:boolean | `@${string}`,
getGeneral?: [{	by: ValueTypes["GeneralUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>},ValueTypes["General"]],
listGeneral?: [{	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["General"]],
paginateGeneral?: [{	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralConnection"]],
getImage?: [{	by: ValueTypes["ImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
listImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Image"]],
paginateImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageConnection"]],
getGenericPage?: [{	by: ValueTypes["GenericPageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPage"]],
listGenericPage?: [{	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPage"]],
paginateGenericPage?: [{	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageConnection"]],
getLinkable?: [{	by: ValueTypes["LinkableUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
listLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
paginateLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkableConnection"]],
getSeo?: [{	by: ValueTypes["SeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
listSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
paginateSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SeoConnection"]],
getContent?: [{	by: ValueTypes["ContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
listContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Content"]],
paginateContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentConnection"]],
getContentBlock?: [{	by: ValueTypes["ContentBlockUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ValueTypes["ContentReferenceUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
listContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceConnection"]],
getGeneralLocale?: [{	by: ValueTypes["GeneralLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
listGeneralLocale?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
paginateGeneralLocale?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocaleConnection"]],
getLocale?: [{	by: ValueTypes["LocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
listLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
paginateLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LocaleConnection"]],
getGenericPageLocale?: [{	by: ValueTypes["GenericPageLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
listGenericPageLocale?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
paginateGenericPageLocale?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocaleConnection"]],
getLink?: [{	by: ValueTypes["LinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
listLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Link"]],
paginateLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkConnection"]],
getLinkList?: [{	by: ValueTypes["LinkListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["LinkList"]],
listLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["LinkList"]],
paginateLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkListConnection"]],
getLinkListItem?: [{	by: ValueTypes["LinkListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["LinkListItem"]],
listLinkListItem?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["LinkListItem"]],
paginateLinkListItem?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkListItemConnection"]],
getHeader?: [{	by: ValueTypes["HeaderUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>},ValueTypes["Header"]],
listHeader?: [{	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Header"]],
paginateHeader?: [{	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderConnection"]],
getHeaderLocale?: [{	by: ValueTypes["HeaderLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
listHeaderLocale?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
paginateHeaderLocale?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocaleConnection"]],
getFooter?: [{	by: ValueTypes["FooterUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>},ValueTypes["Footer"]],
listFooter?: [{	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Footer"]],
paginateFooter?: [{	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterConnection"]],
getFooterLocale?: [{	by: ValueTypes["FooterLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
listFooterLocale?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
paginateFooterLocale?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocaleConnection"]],
getCaseStudy?: [{	by: ValueTypes["CaseStudyUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudy"]],
listCaseStudy?: [{	filter?: ValueTypes["CaseStudyWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudy"]],
paginateCaseStudy?: [{	filter?: ValueTypes["CaseStudyWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyConnection"]],
getCaseStudyLocale?: [{	by: ValueTypes["CaseStudyLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocale"]],
listCaseStudyLocale?: [{	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocale"]],
paginateCaseStudyLocale?: [{	filter?: ValueTypes["CaseStudyLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyLocaleConnection"]],
getTag?: [{	by: ValueTypes["TagUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TagWhere"] | undefined | null | Variable<any, string>},ValueTypes["Tag"]],
listTag?: [{	filter?: ValueTypes["TagWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Tag"]],
paginateTag?: [{	filter?: ValueTypes["TagWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TagConnection"]],
getTagLocale?: [{	by: ValueTypes["TagLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TagLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["TagLocale"]],
listTagLocale?: [{	filter?: ValueTypes["TagLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TagLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TagLocale"]],
paginateTagLocale?: [{	filter?: ValueTypes["TagLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TagLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TagLocaleConnection"]],
getCaseStudyTag?: [{	by: ValueTypes["CaseStudyTagUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyTagWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyTag"]],
listCaseStudyTag?: [{	filter?: ValueTypes["CaseStudyTagWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyTagOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyTag"]],
paginateCaseStudyTag?: [{	filter?: ValueTypes["CaseStudyTagWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyTagOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyTagConnection"]],
getCaseStudyList?: [{	by: ValueTypes["CaseStudyListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyListWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyList"]],
listCaseStudyList?: [{	filter?: ValueTypes["CaseStudyListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyList"]],
paginateCaseStudyList?: [{	filter?: ValueTypes["CaseStudyListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyListConnection"]],
getCaseStudyListItem?: [{	by: ValueTypes["CaseStudyListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CaseStudyListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["CaseStudyListItem"]],
listCaseStudyListItem?: [{	filter?: ValueTypes["CaseStudyListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyListItem"]],
paginateCaseStudyListItem?: [{	filter?: ValueTypes["CaseStudyListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CaseStudyListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CaseStudyListItemConnection"]],
getImageList?: [{	by: ValueTypes["ImageListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
listImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
paginateImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListConnection"]],
getImageListItem?: [{	by: ValueTypes["ImageListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItemConnection"]],
getInformationList?: [{	by: ValueTypes["InformationListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["InformationListWhere"] | undefined | null | Variable<any, string>},ValueTypes["InformationList"]],
listInformationList?: [{	filter?: ValueTypes["InformationListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["InformationListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["InformationList"]],
paginateInformationList?: [{	filter?: ValueTypes["InformationListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["InformationListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["InformationListConnection"]],
getPieceOfInformation?: [{	by: ValueTypes["PieceOfInformationUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PieceOfInformationWhere"] | undefined | null | Variable<any, string>},ValueTypes["PieceOfInformation"]],
listPieceOfInformation?: [{	filter?: ValueTypes["PieceOfInformationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PieceOfInformationOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PieceOfInformation"]],
paginatePieceOfInformation?: [{	filter?: ValueTypes["PieceOfInformationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PieceOfInformationOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PieceOfInformationConnection"]],
getPositionTag?: [{	by: ValueTypes["PositionTagUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionTagWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionTag"]],
listPositionTag?: [{	filter?: ValueTypes["PositionTagWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTag"]],
paginatePositionTag?: [{	filter?: ValueTypes["PositionTagWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagConnection"]],
getPositionTagLocale?: [{	by: ValueTypes["PositionTagLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionTagLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionTagLocale"]],
listPositionTagLocale?: [{	filter?: ValueTypes["PositionTagLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagLocale"]],
paginatePositionTagLocale?: [{	filter?: ValueTypes["PositionTagLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagLocaleConnection"]],
getPosition?: [{	by: ValueTypes["PositionUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionWhere"] | undefined | null | Variable<any, string>},ValueTypes["Position"]],
listPosition?: [{	filter?: ValueTypes["PositionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Position"]],
paginatePosition?: [{	filter?: ValueTypes["PositionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionConnection"]],
getPositionLocale?: [{	by: ValueTypes["PositionLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionLocale"]],
listPositionLocale?: [{	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionLocale"]],
paginatePositionLocale?: [{	filter?: ValueTypes["PositionLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionLocaleConnection"]],
getPositionTagList?: [{	by: ValueTypes["PositionTagListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionTagListWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionTagList"]],
listPositionTagList?: [{	filter?: ValueTypes["PositionTagListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagList"]],
paginatePositionTagList?: [{	filter?: ValueTypes["PositionTagListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagListConnection"]],
getPositionTagListItem?: [{	by: ValueTypes["PositionTagListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionTagListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionTagListItem"]],
listPositionTagListItem?: [{	filter?: ValueTypes["PositionTagListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagListItem"]],
paginatePositionTagListItem?: [{	filter?: ValueTypes["PositionTagListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionTagListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionTagListItemConnection"]],
getPositionCategory?: [{	by: ValueTypes["PositionCategoryUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionCategoryWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionCategory"]],
listPositionCategory?: [{	filter?: ValueTypes["PositionCategoryWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionCategoryOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionCategory"]],
paginatePositionCategory?: [{	filter?: ValueTypes["PositionCategoryWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionCategoryOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionCategoryConnection"]],
getPositionCategoryLocale?: [{	by: ValueTypes["PositionCategoryLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionCategoryLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionCategoryLocale"]],
listPositionCategoryLocale?: [{	filter?: ValueTypes["PositionCategoryLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionCategoryLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionCategoryLocale"]],
paginatePositionCategoryLocale?: [{	filter?: ValueTypes["PositionCategoryLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionCategoryLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionCategoryLocaleConnection"]],
getEmbed?: [{	by: ValueTypes["EmbedUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["EmbedWhere"] | undefined | null | Variable<any, string>},ValueTypes["Embed"]],
listEmbed?: [{	filter?: ValueTypes["EmbedWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["EmbedOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Embed"]],
paginateEmbed?: [{	filter?: ValueTypes["EmbedWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["EmbedOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["EmbedConnection"]],
getRedirect?: [{	by: ValueTypes["RedirectUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>},ValueTypes["Redirect"]],
listRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Redirect"]],
paginateRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["RedirectConnection"]],
getTalk?: [{	by: ValueTypes["TalkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TalkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Talk"]],
listTalk?: [{	filter?: ValueTypes["TalkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Talk"]],
paginateTalk?: [{	filter?: ValueTypes["TalkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TalkConnection"]],
getTalkList?: [{	by: ValueTypes["TalkListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TalkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["TalkList"]],
listTalkList?: [{	filter?: ValueTypes["TalkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TalkList"]],
paginateTalkList?: [{	filter?: ValueTypes["TalkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TalkListConnection"]],
getTalkListItem?: [{	by: ValueTypes["TalkListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TalkListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["TalkListItem"]],
listTalkListItem?: [{	filter?: ValueTypes["TalkListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TalkListItem"]],
paginateTalkListItem?: [{	filter?: ValueTypes["TalkListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TalkListItemConnection"]],
getTalkLists?: [{	by: ValueTypes["TalkListsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TalkListsWhere"] | undefined | null | Variable<any, string>},ValueTypes["TalkLists"]],
listTalkLists?: [{	filter?: ValueTypes["TalkListsWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkListsOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TalkLists"]],
paginateTalkLists?: [{	filter?: ValueTypes["TalkListsWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TalkListsOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TalkListsConnection"]],
getBusinessCard?: [{	by: ValueTypes["BusinessCardUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessCardWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessCard"]],
listBusinessCard?: [{	filter?: ValueTypes["BusinessCardWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCardOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessCard"]],
paginateBusinessCard?: [{	filter?: ValueTypes["BusinessCardWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCardOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessCardConnection"]],
getBusinessCardList?: [{	by: ValueTypes["BusinessCardListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessCardListWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessCardList"]],
listBusinessCardList?: [{	filter?: ValueTypes["BusinessCardListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCardListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessCardList"]],
paginateBusinessCardList?: [{	filter?: ValueTypes["BusinessCardListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCardListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessCardListConnection"]],
getBusinessCardListItem?: [{	by: ValueTypes["BusinessCardListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessCardListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessCardListItem"]],
listBusinessCardListItem?: [{	filter?: ValueTypes["BusinessCardListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCardListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessCardListItem"]],
paginateBusinessCardListItem?: [{	filter?: ValueTypes["BusinessCardListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCardListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessCardListItemConnection"]],
getTestimonial?: [{	by: ValueTypes["TestimonialUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TestimonialWhere"] | undefined | null | Variable<any, string>},ValueTypes["Testimonial"]],
listTestimonial?: [{	filter?: ValueTypes["TestimonialWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Testimonial"]],
paginateTestimonial?: [{	filter?: ValueTypes["TestimonialWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialConnection"]],
getTestimonialList?: [{	by: ValueTypes["TestimonialListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TestimonialListWhere"] | undefined | null | Variable<any, string>},ValueTypes["TestimonialList"]],
listTestimonialList?: [{	filter?: ValueTypes["TestimonialListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialList"]],
paginateTestimonialList?: [{	filter?: ValueTypes["TestimonialListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialListConnection"]],
getTestimonialListItem?: [{	by: ValueTypes["TestimonialListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TestimonialListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["TestimonialListItem"]],
listTestimonialListItem?: [{	filter?: ValueTypes["TestimonialListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialListItem"]],
paginateTestimonialListItem?: [{	filter?: ValueTypes["TestimonialListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialListItemConnection"]],
getContactRequestOption?: [{	by: ValueTypes["ContactRequestOptionUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContactRequestOptionWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContactRequestOption"]],
listContactRequestOption?: [{	filter?: ValueTypes["ContactRequestOptionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContactRequestOptionOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContactRequestOption"]],
paginateContactRequestOption?: [{	filter?: ValueTypes["ContactRequestOptionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContactRequestOptionOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContactRequestOptionConnection"]],
getContactRequestOptionList?: [{	by: ValueTypes["ContactRequestOptionListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContactRequestOptionListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContactRequestOptionList"]],
listContactRequestOptionList?: [{	filter?: ValueTypes["ContactRequestOptionListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContactRequestOptionListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContactRequestOptionList"]],
paginateContactRequestOptionList?: [{	filter?: ValueTypes["ContactRequestOptionListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContactRequestOptionListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContactRequestOptionListConnection"]],
getYoutubeVideo?: [{	by: ValueTypes["YoutubeVideoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["YoutubeVideoWhere"] | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideo"]],
listYoutubeVideo?: [{	filter?: ValueTypes["YoutubeVideoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["YoutubeVideoOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideo"]],
paginateYoutubeVideo?: [{	filter?: ValueTypes["YoutubeVideoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["YoutubeVideoOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoConnection"]],
getYoutubeVideoList?: [{	by: ValueTypes["YoutubeVideoListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["YoutubeVideoListWhere"] | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoList"]],
listYoutubeVideoList?: [{	filter?: ValueTypes["YoutubeVideoListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["YoutubeVideoListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoList"]],
paginateYoutubeVideoList?: [{	filter?: ValueTypes["YoutubeVideoListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["YoutubeVideoListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoListConnection"]],
getYoutubeVideoListItem?: [{	by: ValueTypes["YoutubeVideoListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["YoutubeVideoListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoListItem"]],
listYoutubeVideoListItem?: [{	filter?: ValueTypes["YoutubeVideoListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["YoutubeVideoListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoListItem"]],
paginateYoutubeVideoListItem?: [{	filter?: ValueTypes["YoutubeVideoListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["YoutubeVideoListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["YoutubeVideoListItemConnection"]],
getTranslationCatalogue?: [{	by: ValueTypes["TranslationCatalogueUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
listTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
paginateTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogueConnection"]],
getTranslationCataloguesIdentifier?: [{	by: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCataloguesIdentifier"]],
listTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCataloguesIdentifier"]],
paginateTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCataloguesIdentifierConnection"]],
getTranslationDomain?: [{	by: ValueTypes["TranslationDomainUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationDomain"]],
listTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationDomain"]],
paginateTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationDomainConnection"]],
getTranslationKey?: [{	by: ValueTypes["TranslationKeyUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationKey"]],
listTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationKey"]],
paginateTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationKeyConnection"]],
getTranslationValue?: [{	by: ValueTypes["TranslationValueUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationValue"]],
listTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationValue"]],
paginateTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationValueConnection"]],
validateCreateContactRequestSubmission?: [{	data: ValueTypes["ContactRequestSubmissionCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
getSubscribeForm?: [{	by: ValueTypes["SubscribeFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SubscribeFormWhere"] | undefined | null | Variable<any, string>},ValueTypes["SubscribeForm"]],
listSubscribeForm?: [{	filter?: ValueTypes["SubscribeFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SubscribeFormOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SubscribeForm"]],
paginateSubscribeForm?: [{	filter?: ValueTypes["SubscribeFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SubscribeFormOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SubscribeFormConnection"]],
validateCreateSubscribeForm?: [{	data: ValueTypes["SubscribeFormCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
validateUpdateSubscribeForm?: [{	by: ValueTypes["SubscribeFormUniqueWhere"] | Variable<any, string>,	data: ValueTypes["SubscribeFormUpdateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
validateCreateSubscribeSubmission?: [{	data: ValueTypes["SubscribeSubmissionCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
getContentReferencePositionCategoryItem?: [{	by: ValueTypes["ContentReferencePositionCategoryItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReferencePositionCategoryItem"]],
listContentReferencePositionCategoryItem?: [{	filter?: ValueTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferencePositionCategoryItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferencePositionCategoryItem"]],
paginateContentReferencePositionCategoryItem?: [{	filter?: ValueTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferencePositionCategoryItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferencePositionCategoryItemConnection"]],
getVideo?: [{	by: ValueTypes["VideoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Video"]],
listVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Video"]],
paginateVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["VideoConnection"]],
getContentReferenceCaseStudyItem?: [{	by: ValueTypes["ContentReferenceCaseStudyItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceCaseStudyItem"]],
listContentReferenceCaseStudyItem?: [{	filter?: ValueTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceCaseStudyItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceCaseStudyItem"]],
paginateContentReferenceCaseStudyItem?: [{	filter?: ValueTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceCaseStudyItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceCaseStudyItemConnection"]],
getContentReferenceTagItem?: [{	by: ValueTypes["ContentReferenceTagItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceTagItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceTagItem"]],
listContentReferenceTagItem?: [{	filter?: ValueTypes["ContentReferenceTagItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceTagItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceTagItem"]],
paginateContentReferenceTagItem?: [{	filter?: ValueTypes["ContentReferenceTagItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceTagItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceTagItemConnection"]],
getPositionList?: [{	by: ValueTypes["PositionListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionListWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionList"]],
listPositionList?: [{	filter?: ValueTypes["PositionListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionList"]],
paginatePositionList?: [{	filter?: ValueTypes["PositionListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionListConnection"]],
getPositionListItem?: [{	by: ValueTypes["PositionListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["PositionListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["PositionListItem"]],
listPositionListItem?: [{	filter?: ValueTypes["PositionListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["PositionListItem"]],
paginatePositionListItem?: [{	filter?: ValueTypes["PositionListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PositionListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["PositionListItemConnection"]],
getTestimonialFull?: [{	by: ValueTypes["TestimonialFullUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TestimonialFullWhere"] | undefined | null | Variable<any, string>},ValueTypes["TestimonialFull"]],
listTestimonialFull?: [{	filter?: ValueTypes["TestimonialFullWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialFullOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialFull"]],
paginateTestimonialFull?: [{	filter?: ValueTypes["TestimonialFullWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialFullOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullConnection"]],
getTestimonialFullList?: [{	by: ValueTypes["TestimonialFullListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TestimonialFullListWhere"] | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullList"]],
listTestimonialFullList?: [{	filter?: ValueTypes["TestimonialFullListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialFullListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullList"]],
paginateTestimonialFullList?: [{	filter?: ValueTypes["TestimonialFullListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialFullListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullListConnection"]],
getTestimonialFullListItem?: [{	by: ValueTypes["TestimonialFullListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TestimonialFullListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullListItem"]],
listTestimonialFullListItem?: [{	filter?: ValueTypes["TestimonialFullListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialFullListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullListItem"]],
paginateTestimonialFullListItem?: [{	filter?: ValueTypes["TestimonialFullListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TestimonialFullListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TestimonialFullListItemConnection"]],
getSecondLevelLinkItem?: [{	by: ValueTypes["SecondLevelLinkItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SecondLevelLinkItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["SecondLevelLinkItem"]],
listSecondLevelLinkItem?: [{	filter?: ValueTypes["SecondLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SecondLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SecondLevelLinkItem"]],
paginateSecondLevelLinkItem?: [{	filter?: ValueTypes["SecondLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SecondLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SecondLevelLinkItemConnection"]],
getThirdLevelLinkItem?: [{	by: ValueTypes["ThirdLevelLinkItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ThirdLevelLinkItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ThirdLevelLinkItem"]],
listThirdLevelLinkItem?: [{	filter?: ValueTypes["ThirdLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ThirdLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ThirdLevelLinkItem"]],
paginateThirdLevelLinkItem?: [{	filter?: ValueTypes["ThirdLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ThirdLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ThirdLevelLinkItemConnection"]],
getTopLevelLinkItem?: [{	by: ValueTypes["TopLevelLinkItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TopLevelLinkItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["TopLevelLinkItem"]],
listTopLevelLinkItem?: [{	filter?: ValueTypes["TopLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TopLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TopLevelLinkItem"]],
paginateTopLevelLinkItem?: [{	filter?: ValueTypes["TopLevelLinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TopLevelLinkItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TopLevelLinkItemConnection"]],
getAuthor?: [{	by: ValueTypes["AuthorUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["AuthorWhere"] | undefined | null | Variable<any, string>},ValueTypes["Author"]],
listAuthor?: [{	filter?: ValueTypes["AuthorWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuthorOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Author"]],
paginateAuthor?: [{	filter?: ValueTypes["AuthorWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuthorOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["AuthorConnection"]],
getAuthorLocale?: [{	by: ValueTypes["AuthorLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["AuthorLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["AuthorLocale"]],
listAuthorLocale?: [{	filter?: ValueTypes["AuthorLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuthorLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["AuthorLocale"]],
paginateAuthorLocale?: [{	filter?: ValueTypes["AuthorLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuthorLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["AuthorLocaleConnection"]],
getBlogPost?: [{	by: ValueTypes["BlogPostUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPost"]],
listBlogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPost"]],
paginateBlogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostConnection"]],
getBlogPostLocale?: [{	by: ValueTypes["BlogPostLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocale"]],
listBlogPostLocale?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocale"]],
paginateBlogPostLocale?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostLocaleConnection"]],
getContentReferenceBlogPostItem?: [{	by: ValueTypes["ContentReferenceBlogPostItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceBlogPostItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceBlogPostItem"]],
listContentReferenceBlogPostItem?: [{	filter?: ValueTypes["ContentReferenceBlogPostItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceBlogPostItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceBlogPostItem"]],
paginateContentReferenceBlogPostItem?: [{	filter?: ValueTypes["ContentReferenceBlogPostItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceBlogPostItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceBlogPostItemConnection"]],
getRelevantBlogPostList?: [{	by: ValueTypes["RelevantBlogPostListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["RelevantBlogPostListWhere"] | undefined | null | Variable<any, string>},ValueTypes["RelevantBlogPostList"]],
listRelevantBlogPostList?: [{	filter?: ValueTypes["RelevantBlogPostListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RelevantBlogPostListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["RelevantBlogPostList"]],
paginateRelevantBlogPostList?: [{	filter?: ValueTypes["RelevantBlogPostListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RelevantBlogPostListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["RelevantBlogPostListConnection"]],
getBlogPostCommon?: [{	by: ValueTypes["BlogPostCommonUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostCommonWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommon"]],
listBlogPostCommon?: [{	filter?: ValueTypes["BlogPostCommonWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostCommonOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommon"]],
paginateBlogPostCommon?: [{	filter?: ValueTypes["BlogPostCommonWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostCommonOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommonConnection"]],
getBlogPostCommonLocale?: [{	by: ValueTypes["BlogPostCommonLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BlogPostCommonLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommonLocale"]],
listBlogPostCommonLocale?: [{	filter?: ValueTypes["BlogPostCommonLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostCommonLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommonLocale"]],
paginateBlogPostCommonLocale?: [{	filter?: ValueTypes["BlogPostCommonLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BlogPostCommonLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BlogPostCommonLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Info"]: AliasType<{
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
generateUploadUrl?: [{	input?: ValueTypes["S3GenerateSignedUploadInput"] | undefined | null | Variable<any, string>},ValueTypes["S3SignedUpload"]],
generateReadUrl?: [{	objectKey: string | Variable<any, string>,	expiration?: number | undefined | null | Variable<any, string>},ValueTypes["S3SignedRead"]],
createContactRequestSubmission?: [{	data: ValueTypes["ContactRequestSubmissionCreateInput"] | Variable<any, string>},ValueTypes["ContactRequestSubmissionCreateResult"]],
createSubscribeForm?: [{	data: ValueTypes["SubscribeFormCreateInput"] | Variable<any, string>},ValueTypes["SubscribeFormCreateResult"]],
updateSubscribeForm?: [{	by: ValueTypes["SubscribeFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SubscribeFormWhere"] | undefined | null | Variable<any, string>,	data: ValueTypes["SubscribeFormUpdateInput"] | Variable<any, string>},ValueTypes["SubscribeFormUpdateResult"]],
upsertSubscribeForm?: [{	by: ValueTypes["SubscribeFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SubscribeFormWhere"] | undefined | null | Variable<any, string>,	update: ValueTypes["SubscribeFormUpdateInput"] | Variable<any, string>,	create: ValueTypes["SubscribeFormCreateInput"] | Variable<any, string>},ValueTypes["SubscribeFormUpsertResult"]],
createSubscribeSubmission?: [{	data: ValueTypes["SubscribeSubmissionCreateInput"] | Variable<any, string>},ValueTypes["SubscribeSubmissionCreateResult"]],
transaction?: [{	options?: ValueTypes["MutationTransactionOptions"] | undefined | null | Variable<any, string>},ValueTypes["MutationTransaction"]],
	query?:ValueTypes["Query"],
		__typename?: boolean | `@${string}`
}>;
	["S3SignedUpload"]: AliasType<{
	url?:boolean | `@${string}`,
	headers?:ValueTypes["S3Header"],
	method?:boolean | `@${string}`,
	objectKey?:boolean | `@${string}`,
	bucket?:boolean | `@${string}`,
	publicUrl?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["S3Header"]: AliasType<{
	key?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["S3GenerateSignedUploadInput"]: {
	contentType?: string | undefined | null | Variable<any, string>,
	/** If not provided, extension is detected from a content-type. */
	extension?: string | undefined | null | Variable<any, string>,
	/** Signed URL expiration. */
	expiration?: number | undefined | null | Variable<any, string>,
	/** Uploaded file size. Required when you enable ACL size limits. */
	size?: number | undefined | null | Variable<any, string>,
	/** Can be used as a "directory". */
	prefix?: string | undefined | null | Variable<any, string>,
	/** Suffix after generated id and before the extension. */
	suffix?: string | undefined | null | Variable<any, string>,
	/** This only affects Content-disposition header. Does not affect actual object key. */
	fileName?: string | undefined | null | Variable<any, string>,
	contentDisposition?: ValueTypes["S33ContentDisposition"] | undefined | null | Variable<any, string>,
	/** If not supported by S3 provider, an error is thrown. */
	acl?: ValueTypes["S3Acl"] | undefined | null | Variable<any, string>
};
	["S33ContentDisposition"]:S33ContentDisposition;
	["S3Acl"]:S3Acl;
	["S3SignedRead"]: AliasType<{
	url?:boolean | `@${string}`,
	headers?:ValueTypes["S3Header"],
	method?:boolean | `@${string}`,
	objectKey?:boolean | `@${string}`,
	bucket?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestSubmissionCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["MutationResult"]:AliasType<{
		ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"];
		['...on ContactRequestSubmissionCreateResult']?: Omit<ValueTypes["ContactRequestSubmissionCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on SubscribeFormCreateResult']?: Omit<ValueTypes["SubscribeFormCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on SubscribeFormUpdateResult']?: Omit<ValueTypes["SubscribeFormUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on SubscribeFormUpsertResult']?: Omit<ValueTypes["SubscribeFormUpsertResult"],keyof ValueTypes["MutationResult"]>;
		['...on SubscribeSubmissionCreateResult']?: Omit<ValueTypes["SubscribeSubmissionCreateResult"],keyof ValueTypes["MutationResult"]>;
		__typename?: boolean | `@${string}`
}>;
	["_MutationError"]: AliasType<{
	path?:ValueTypes["_PathFragment"],
	paths?:ValueTypes["_PathFragment"],
	type?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_MutationErrorType"]:_MutationErrorType;
	["SubscribeFormCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["SubscribeForm"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["SubscribeFormUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["SubscribeForm"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["SubscribeFormUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["SubscribeForm"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["SubscribeSubmissionCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["MutationTransaction"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	validation?:ValueTypes["_ValidationResult"],
generateUploadUrl?: [{	input?: ValueTypes["S3GenerateSignedUploadInput"] | undefined | null | Variable<any, string>},ValueTypes["S3SignedUpload"]],
generateReadUrl?: [{	objectKey: string | Variable<any, string>,	expiration?: number | undefined | null | Variable<any, string>},ValueTypes["S3SignedRead"]],
createContactRequestSubmission?: [{	data: ValueTypes["ContactRequestSubmissionCreateInput"] | Variable<any, string>},ValueTypes["ContactRequestSubmissionCreateResult"]],
createSubscribeForm?: [{	data: ValueTypes["SubscribeFormCreateInput"] | Variable<any, string>},ValueTypes["SubscribeFormCreateResult"]],
updateSubscribeForm?: [{	by: ValueTypes["SubscribeFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SubscribeFormWhere"] | undefined | null | Variable<any, string>,	data: ValueTypes["SubscribeFormUpdateInput"] | Variable<any, string>},ValueTypes["SubscribeFormUpdateResult"]],
upsertSubscribeForm?: [{	by: ValueTypes["SubscribeFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SubscribeFormWhere"] | undefined | null | Variable<any, string>,	update: ValueTypes["SubscribeFormUpdateInput"] | Variable<any, string>,	create: ValueTypes["SubscribeFormCreateInput"] | Variable<any, string>},ValueTypes["SubscribeFormUpsertResult"]],
createSubscribeSubmission?: [{	data: ValueTypes["SubscribeSubmissionCreateInput"] | Variable<any, string>},ValueTypes["SubscribeSubmissionCreateResult"]],
	query?:ValueTypes["Query"],
		__typename?: boolean | `@${string}`
}>;
	["MutationTransactionOptions"]: {
	deferForeignKeyConstraints?: boolean | undefined | null | Variable<any, string>,
	deferUniqueConstraints?: boolean | undefined | null | Variable<any, string>
}
  }

export type ResolverInputTypes = {
    ["_Column"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	enumName?:boolean | `@${string}`,
	defaultValue?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ResolverInputTypes["_Rule"],
	validators?:ResolverInputTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_Field"]:AliasType<{
		name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ResolverInputTypes["_Rule"],
	validators?:ResolverInputTypes["_Validator"];
		['...on _Column']?: Omit<ResolverInputTypes["_Column"],keyof ResolverInputTypes["_Field"]>;
		['...on _Relation']?: Omit<ResolverInputTypes["_Relation"],keyof ResolverInputTypes["_Field"]>;
		__typename?: boolean | `@${string}`
}>;
	["_Rule"]: AliasType<{
	message?:ResolverInputTypes["_RuleMessage"],
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_RuleMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Validator"]: AliasType<{
	operation?:boolean | `@${string}`,
	arguments?:ResolverInputTypes["_Argument"],
		__typename?: boolean | `@${string}`
}>;
	["_Argument"]: AliasType<{
	_ValidatorArgument?:ResolverInputTypes["_ValidatorArgument"],
	_PathArgument?:ResolverInputTypes["_PathArgument"],
	_LiteralArgument?:ResolverInputTypes["_LiteralArgument"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidatorArgument"]: AliasType<{
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_PathArgument"]: AliasType<{
	path?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_LiteralArgument"]: AliasType<{
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Json custom scalar type */
["Json"]:unknown;
	["_Relation"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	side?:boolean | `@${string}`,
	targetEntity?:boolean | `@${string}`,
	ownedBy?:boolean | `@${string}`,
	inversedBy?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	onDelete?:boolean | `@${string}`,
	orphanRemoval?:boolean | `@${string}`,
	orderBy?:ResolverInputTypes["_OrderBy"],
	rules?:ResolverInputTypes["_Rule"],
	validators?:ResolverInputTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_RelationSide"]:_RelationSide;
	["_OnDeleteBehaviour"]:_OnDeleteBehaviour;
	["_OrderBy"]: AliasType<{
	path?:boolean | `@${string}`,
	direction?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_OrderByDirection"]:_OrderByDirection;
	["Query"]: AliasType<{
	schema?:ResolverInputTypes["_Schema"],
	s3DummyQuery?:boolean | `@${string}`,
getGeneral?: [{	by: ResolverInputTypes["GeneralUniqueWhere"],	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null},ResolverInputTypes["General"]],
listGeneral?: [{	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["General"]],
paginateGeneral?: [{	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralConnection"]],
getImage?: [{	by: ResolverInputTypes["ImageUniqueWhere"],	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
listImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Image"]],
paginateImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageConnection"]],
getGenericPage?: [{	by: ResolverInputTypes["GenericPageUniqueWhere"],	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null},ResolverInputTypes["GenericPage"]],
listGenericPage?: [{	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPage"]],
paginateGenericPage?: [{	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageConnection"]],
getLinkable?: [{	by: ResolverInputTypes["LinkableUniqueWhere"],	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
listLinkable?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkableOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Linkable"]],
paginateLinkable?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkableOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkableConnection"]],
getSeo?: [{	by: ResolverInputTypes["SeoUniqueWhere"],	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
listSeo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Seo"]],
paginateSeo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SeoConnection"]],
getContent?: [{	by: ResolverInputTypes["ContentUniqueWhere"],	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
listContent?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Content"]],
paginateContent?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentConnection"]],
getContentBlock?: [{	by: ResolverInputTypes["ContentBlockUniqueWhere"],	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null},ResolverInputTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ResolverInputTypes["ContentReferenceUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
listContentReference?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceConnection"]],
getGeneralLocale?: [{	by: ResolverInputTypes["GeneralLocaleUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
listGeneralLocale?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GeneralLocale"]],
paginateGeneralLocale?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralLocaleConnection"]],
getLocale?: [{	by: ResolverInputTypes["LocaleUniqueWhere"],	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
listLocale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Locale"]],
paginateLocale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LocaleConnection"]],
getGenericPageLocale?: [{	by: ResolverInputTypes["GenericPageLocaleUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
listGenericPageLocale?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPageLocale"]],
paginateGenericPageLocale?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageLocaleConnection"]],
getLink?: [{	by: ResolverInputTypes["LinkUniqueWhere"],	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
listLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Link"]],
paginateLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkConnection"]],
getLinkList?: [{	by: ResolverInputTypes["LinkListUniqueWhere"],	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null},ResolverInputTypes["LinkList"]],
listLinkList?: [{	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["LinkList"]],
paginateLinkList?: [{	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkListConnection"]],
getLinkListItem?: [{	by: ResolverInputTypes["LinkListItemUniqueWhere"],	filter?: ResolverInputTypes["LinkListItemWhere"] | undefined | null},ResolverInputTypes["LinkListItem"]],
listLinkListItem?: [{	filter?: ResolverInputTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["LinkListItem"]],
paginateLinkListItem?: [{	filter?: ResolverInputTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkListItemConnection"]],
getHeader?: [{	by: ResolverInputTypes["HeaderUniqueWhere"],	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null},ResolverInputTypes["Header"]],
listHeader?: [{	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Header"]],
paginateHeader?: [{	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderConnection"]],
getHeaderLocale?: [{	by: ResolverInputTypes["HeaderLocaleUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
listHeaderLocale?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HeaderLocale"]],
paginateHeaderLocale?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderLocaleConnection"]],
getFooter?: [{	by: ResolverInputTypes["FooterUniqueWhere"],	filter?: ResolverInputTypes["FooterWhere"] | undefined | null},ResolverInputTypes["Footer"]],
listFooter?: [{	filter?: ResolverInputTypes["FooterWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Footer"]],
paginateFooter?: [{	filter?: ResolverInputTypes["FooterWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterConnection"]],
getFooterLocale?: [{	by: ResolverInputTypes["FooterLocaleUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
listFooterLocale?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterLocale"]],
paginateFooterLocale?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterLocaleConnection"]],
getCaseStudy?: [{	by: ResolverInputTypes["CaseStudyUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyWhere"] | undefined | null},ResolverInputTypes["CaseStudy"]],
listCaseStudy?: [{	filter?: ResolverInputTypes["CaseStudyWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CaseStudy"]],
paginateCaseStudy?: [{	filter?: ResolverInputTypes["CaseStudyWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CaseStudyConnection"]],
getCaseStudyLocale?: [{	by: ResolverInputTypes["CaseStudyLocaleUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null},ResolverInputTypes["CaseStudyLocale"]],
listCaseStudyLocale?: [{	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CaseStudyLocale"]],
paginateCaseStudyLocale?: [{	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CaseStudyLocaleConnection"]],
getTag?: [{	by: ResolverInputTypes["TagUniqueWhere"],	filter?: ResolverInputTypes["TagWhere"] | undefined | null},ResolverInputTypes["Tag"]],
listTag?: [{	filter?: ResolverInputTypes["TagWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Tag"]],
paginateTag?: [{	filter?: ResolverInputTypes["TagWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TagConnection"]],
getTagLocale?: [{	by: ResolverInputTypes["TagLocaleUniqueWhere"],	filter?: ResolverInputTypes["TagLocaleWhere"] | undefined | null},ResolverInputTypes["TagLocale"]],
listTagLocale?: [{	filter?: ResolverInputTypes["TagLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TagLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TagLocale"]],
paginateTagLocale?: [{	filter?: ResolverInputTypes["TagLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TagLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TagLocaleConnection"]],
getCaseStudyTag?: [{	by: ResolverInputTypes["CaseStudyTagUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyTagWhere"] | undefined | null},ResolverInputTypes["CaseStudyTag"]],
listCaseStudyTag?: [{	filter?: ResolverInputTypes["CaseStudyTagWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyTagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CaseStudyTag"]],
paginateCaseStudyTag?: [{	filter?: ResolverInputTypes["CaseStudyTagWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyTagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CaseStudyTagConnection"]],
getCaseStudyList?: [{	by: ResolverInputTypes["CaseStudyListUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyListWhere"] | undefined | null},ResolverInputTypes["CaseStudyList"]],
listCaseStudyList?: [{	filter?: ResolverInputTypes["CaseStudyListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CaseStudyList"]],
paginateCaseStudyList?: [{	filter?: ResolverInputTypes["CaseStudyListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CaseStudyListConnection"]],
getCaseStudyListItem?: [{	by: ResolverInputTypes["CaseStudyListItemUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyListItemWhere"] | undefined | null},ResolverInputTypes["CaseStudyListItem"]],
listCaseStudyListItem?: [{	filter?: ResolverInputTypes["CaseStudyListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CaseStudyListItem"]],
paginateCaseStudyListItem?: [{	filter?: ResolverInputTypes["CaseStudyListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CaseStudyListItemConnection"]],
getImageList?: [{	by: ResolverInputTypes["ImageListUniqueWhere"],	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null},ResolverInputTypes["ImageList"]],
listImageList?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageList"]],
paginateImageList?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageListConnection"]],
getImageListItem?: [{	by: ResolverInputTypes["ImageListItemUniqueWhere"],	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null},ResolverInputTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageListItemConnection"]],
getInformationList?: [{	by: ResolverInputTypes["InformationListUniqueWhere"],	filter?: ResolverInputTypes["InformationListWhere"] | undefined | null},ResolverInputTypes["InformationList"]],
listInformationList?: [{	filter?: ResolverInputTypes["InformationListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["InformationListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["InformationList"]],
paginateInformationList?: [{	filter?: ResolverInputTypes["InformationListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["InformationListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["InformationListConnection"]],
getPieceOfInformation?: [{	by: ResolverInputTypes["PieceOfInformationUniqueWhere"],	filter?: ResolverInputTypes["PieceOfInformationWhere"] | undefined | null},ResolverInputTypes["PieceOfInformation"]],
listPieceOfInformation?: [{	filter?: ResolverInputTypes["PieceOfInformationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PieceOfInformationOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PieceOfInformation"]],
paginatePieceOfInformation?: [{	filter?: ResolverInputTypes["PieceOfInformationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PieceOfInformationOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PieceOfInformationConnection"]],
getPositionTag?: [{	by: ResolverInputTypes["PositionTagUniqueWhere"],	filter?: ResolverInputTypes["PositionTagWhere"] | undefined | null},ResolverInputTypes["PositionTag"]],
listPositionTag?: [{	filter?: ResolverInputTypes["PositionTagWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionTag"]],
paginatePositionTag?: [{	filter?: ResolverInputTypes["PositionTagWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionTagConnection"]],
getPositionTagLocale?: [{	by: ResolverInputTypes["PositionTagLocaleUniqueWhere"],	filter?: ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null},ResolverInputTypes["PositionTagLocale"]],
listPositionTagLocale?: [{	filter?: ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionTagLocale"]],
paginatePositionTagLocale?: [{	filter?: ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionTagLocaleConnection"]],
getPosition?: [{	by: ResolverInputTypes["PositionUniqueWhere"],	filter?: ResolverInputTypes["PositionWhere"] | undefined | null},ResolverInputTypes["Position"]],
listPosition?: [{	filter?: ResolverInputTypes["PositionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Position"]],
paginatePosition?: [{	filter?: ResolverInputTypes["PositionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionConnection"]],
getPositionLocale?: [{	by: ResolverInputTypes["PositionLocaleUniqueWhere"],	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null},ResolverInputTypes["PositionLocale"]],
listPositionLocale?: [{	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionLocale"]],
paginatePositionLocale?: [{	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionLocaleConnection"]],
getPositionTagList?: [{	by: ResolverInputTypes["PositionTagListUniqueWhere"],	filter?: ResolverInputTypes["PositionTagListWhere"] | undefined | null},ResolverInputTypes["PositionTagList"]],
listPositionTagList?: [{	filter?: ResolverInputTypes["PositionTagListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionTagList"]],
paginatePositionTagList?: [{	filter?: ResolverInputTypes["PositionTagListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionTagListConnection"]],
getPositionTagListItem?: [{	by: ResolverInputTypes["PositionTagListItemUniqueWhere"],	filter?: ResolverInputTypes["PositionTagListItemWhere"] | undefined | null},ResolverInputTypes["PositionTagListItem"]],
listPositionTagListItem?: [{	filter?: ResolverInputTypes["PositionTagListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionTagListItem"]],
paginatePositionTagListItem?: [{	filter?: ResolverInputTypes["PositionTagListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionTagListItemConnection"]],
getPositionCategory?: [{	by: ResolverInputTypes["PositionCategoryUniqueWhere"],	filter?: ResolverInputTypes["PositionCategoryWhere"] | undefined | null},ResolverInputTypes["PositionCategory"]],
listPositionCategory?: [{	filter?: ResolverInputTypes["PositionCategoryWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionCategory"]],
paginatePositionCategory?: [{	filter?: ResolverInputTypes["PositionCategoryWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionCategoryConnection"]],
getPositionCategoryLocale?: [{	by: ResolverInputTypes["PositionCategoryLocaleUniqueWhere"],	filter?: ResolverInputTypes["PositionCategoryLocaleWhere"] | undefined | null},ResolverInputTypes["PositionCategoryLocale"]],
listPositionCategoryLocale?: [{	filter?: ResolverInputTypes["PositionCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionCategoryLocale"]],
paginatePositionCategoryLocale?: [{	filter?: ResolverInputTypes["PositionCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionCategoryLocaleConnection"]],
getEmbed?: [{	by: ResolverInputTypes["EmbedUniqueWhere"],	filter?: ResolverInputTypes["EmbedWhere"] | undefined | null},ResolverInputTypes["Embed"]],
listEmbed?: [{	filter?: ResolverInputTypes["EmbedWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["EmbedOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Embed"]],
paginateEmbed?: [{	filter?: ResolverInputTypes["EmbedWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["EmbedOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["EmbedConnection"]],
getRedirect?: [{	by: ResolverInputTypes["RedirectUniqueWhere"],	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null},ResolverInputTypes["Redirect"]],
listRedirect?: [{	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RedirectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Redirect"]],
paginateRedirect?: [{	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RedirectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["RedirectConnection"]],
getTalk?: [{	by: ResolverInputTypes["TalkUniqueWhere"],	filter?: ResolverInputTypes["TalkWhere"] | undefined | null},ResolverInputTypes["Talk"]],
listTalk?: [{	filter?: ResolverInputTypes["TalkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Talk"]],
paginateTalk?: [{	filter?: ResolverInputTypes["TalkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TalkConnection"]],
getTalkList?: [{	by: ResolverInputTypes["TalkListUniqueWhere"],	filter?: ResolverInputTypes["TalkListWhere"] | undefined | null},ResolverInputTypes["TalkList"]],
listTalkList?: [{	filter?: ResolverInputTypes["TalkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TalkList"]],
paginateTalkList?: [{	filter?: ResolverInputTypes["TalkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TalkListConnection"]],
getTalkListItem?: [{	by: ResolverInputTypes["TalkListItemUniqueWhere"],	filter?: ResolverInputTypes["TalkListItemWhere"] | undefined | null},ResolverInputTypes["TalkListItem"]],
listTalkListItem?: [{	filter?: ResolverInputTypes["TalkListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TalkListItem"]],
paginateTalkListItem?: [{	filter?: ResolverInputTypes["TalkListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TalkListItemConnection"]],
getTalkLists?: [{	by: ResolverInputTypes["TalkListsUniqueWhere"],	filter?: ResolverInputTypes["TalkListsWhere"] | undefined | null},ResolverInputTypes["TalkLists"]],
listTalkLists?: [{	filter?: ResolverInputTypes["TalkListsWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkListsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TalkLists"]],
paginateTalkLists?: [{	filter?: ResolverInputTypes["TalkListsWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkListsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TalkListsConnection"]],
getBusinessCard?: [{	by: ResolverInputTypes["BusinessCardUniqueWhere"],	filter?: ResolverInputTypes["BusinessCardWhere"] | undefined | null},ResolverInputTypes["BusinessCard"]],
listBusinessCard?: [{	filter?: ResolverInputTypes["BusinessCardWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCardOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BusinessCard"]],
paginateBusinessCard?: [{	filter?: ResolverInputTypes["BusinessCardWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCardOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BusinessCardConnection"]],
getBusinessCardList?: [{	by: ResolverInputTypes["BusinessCardListUniqueWhere"],	filter?: ResolverInputTypes["BusinessCardListWhere"] | undefined | null},ResolverInputTypes["BusinessCardList"]],
listBusinessCardList?: [{	filter?: ResolverInputTypes["BusinessCardListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCardListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BusinessCardList"]],
paginateBusinessCardList?: [{	filter?: ResolverInputTypes["BusinessCardListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCardListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BusinessCardListConnection"]],
getBusinessCardListItem?: [{	by: ResolverInputTypes["BusinessCardListItemUniqueWhere"],	filter?: ResolverInputTypes["BusinessCardListItemWhere"] | undefined | null},ResolverInputTypes["BusinessCardListItem"]],
listBusinessCardListItem?: [{	filter?: ResolverInputTypes["BusinessCardListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCardListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BusinessCardListItem"]],
paginateBusinessCardListItem?: [{	filter?: ResolverInputTypes["BusinessCardListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCardListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BusinessCardListItemConnection"]],
getTestimonial?: [{	by: ResolverInputTypes["TestimonialUniqueWhere"],	filter?: ResolverInputTypes["TestimonialWhere"] | undefined | null},ResolverInputTypes["Testimonial"]],
listTestimonial?: [{	filter?: ResolverInputTypes["TestimonialWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Testimonial"]],
paginateTestimonial?: [{	filter?: ResolverInputTypes["TestimonialWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TestimonialConnection"]],
getTestimonialList?: [{	by: ResolverInputTypes["TestimonialListUniqueWhere"],	filter?: ResolverInputTypes["TestimonialListWhere"] | undefined | null},ResolverInputTypes["TestimonialList"]],
listTestimonialList?: [{	filter?: ResolverInputTypes["TestimonialListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TestimonialList"]],
paginateTestimonialList?: [{	filter?: ResolverInputTypes["TestimonialListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TestimonialListConnection"]],
getTestimonialListItem?: [{	by: ResolverInputTypes["TestimonialListItemUniqueWhere"],	filter?: ResolverInputTypes["TestimonialListItemWhere"] | undefined | null},ResolverInputTypes["TestimonialListItem"]],
listTestimonialListItem?: [{	filter?: ResolverInputTypes["TestimonialListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TestimonialListItem"]],
paginateTestimonialListItem?: [{	filter?: ResolverInputTypes["TestimonialListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TestimonialListItemConnection"]],
getContactRequestOption?: [{	by: ResolverInputTypes["ContactRequestOptionUniqueWhere"],	filter?: ResolverInputTypes["ContactRequestOptionWhere"] | undefined | null},ResolverInputTypes["ContactRequestOption"]],
listContactRequestOption?: [{	filter?: ResolverInputTypes["ContactRequestOptionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContactRequestOptionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContactRequestOption"]],
paginateContactRequestOption?: [{	filter?: ResolverInputTypes["ContactRequestOptionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContactRequestOptionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContactRequestOptionConnection"]],
getContactRequestOptionList?: [{	by: ResolverInputTypes["ContactRequestOptionListUniqueWhere"],	filter?: ResolverInputTypes["ContactRequestOptionListWhere"] | undefined | null},ResolverInputTypes["ContactRequestOptionList"]],
listContactRequestOptionList?: [{	filter?: ResolverInputTypes["ContactRequestOptionListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContactRequestOptionListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContactRequestOptionList"]],
paginateContactRequestOptionList?: [{	filter?: ResolverInputTypes["ContactRequestOptionListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContactRequestOptionListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContactRequestOptionListConnection"]],
getYoutubeVideo?: [{	by: ResolverInputTypes["YoutubeVideoUniqueWhere"],	filter?: ResolverInputTypes["YoutubeVideoWhere"] | undefined | null},ResolverInputTypes["YoutubeVideo"]],
listYoutubeVideo?: [{	filter?: ResolverInputTypes["YoutubeVideoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["YoutubeVideoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["YoutubeVideo"]],
paginateYoutubeVideo?: [{	filter?: ResolverInputTypes["YoutubeVideoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["YoutubeVideoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["YoutubeVideoConnection"]],
getYoutubeVideoList?: [{	by: ResolverInputTypes["YoutubeVideoListUniqueWhere"],	filter?: ResolverInputTypes["YoutubeVideoListWhere"] | undefined | null},ResolverInputTypes["YoutubeVideoList"]],
listYoutubeVideoList?: [{	filter?: ResolverInputTypes["YoutubeVideoListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["YoutubeVideoListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["YoutubeVideoList"]],
paginateYoutubeVideoList?: [{	filter?: ResolverInputTypes["YoutubeVideoListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["YoutubeVideoListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["YoutubeVideoListConnection"]],
getYoutubeVideoListItem?: [{	by: ResolverInputTypes["YoutubeVideoListItemUniqueWhere"],	filter?: ResolverInputTypes["YoutubeVideoListItemWhere"] | undefined | null},ResolverInputTypes["YoutubeVideoListItem"]],
listYoutubeVideoListItem?: [{	filter?: ResolverInputTypes["YoutubeVideoListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["YoutubeVideoListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["YoutubeVideoListItem"]],
paginateYoutubeVideoListItem?: [{	filter?: ResolverInputTypes["YoutubeVideoListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["YoutubeVideoListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["YoutubeVideoListItemConnection"]],
getTranslationCatalogue?: [{	by: ResolverInputTypes["TranslationCatalogueUniqueWhere"],	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
listTranslationCatalogue?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
paginateTranslationCatalogue?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationCatalogueConnection"]],
getTranslationCataloguesIdentifier?: [{	by: ResolverInputTypes["TranslationCataloguesIdentifierUniqueWhere"],	filter?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ResolverInputTypes["TranslationCataloguesIdentifier"]],
listTranslationCataloguesIdentifier?: [{	filter?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationCataloguesIdentifier"]],
paginateTranslationCataloguesIdentifier?: [{	filter?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationCataloguesIdentifierConnection"]],
getTranslationDomain?: [{	by: ResolverInputTypes["TranslationDomainUniqueWhere"],	filter?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null},ResolverInputTypes["TranslationDomain"]],
listTranslationDomain?: [{	filter?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationDomainOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationDomain"]],
paginateTranslationDomain?: [{	filter?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationDomainOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationDomainConnection"]],
getTranslationKey?: [{	by: ResolverInputTypes["TranslationKeyUniqueWhere"],	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null},ResolverInputTypes["TranslationKey"]],
listTranslationKey?: [{	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationKeyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationKey"]],
paginateTranslationKey?: [{	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationKeyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationKeyConnection"]],
getTranslationValue?: [{	by: ResolverInputTypes["TranslationValueUniqueWhere"],	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null},ResolverInputTypes["TranslationValue"]],
listTranslationValue?: [{	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationValue"]],
paginateTranslationValue?: [{	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationValueConnection"]],
validateCreateContactRequestSubmission?: [{	data: ResolverInputTypes["ContactRequestSubmissionCreateInput"]},ResolverInputTypes["_ValidationResult"]],
getSubscribeForm?: [{	by: ResolverInputTypes["SubscribeFormUniqueWhere"],	filter?: ResolverInputTypes["SubscribeFormWhere"] | undefined | null},ResolverInputTypes["SubscribeForm"]],
listSubscribeForm?: [{	filter?: ResolverInputTypes["SubscribeFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SubscribeFormOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SubscribeForm"]],
paginateSubscribeForm?: [{	filter?: ResolverInputTypes["SubscribeFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SubscribeFormOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SubscribeFormConnection"]],
validateCreateSubscribeForm?: [{	data: ResolverInputTypes["SubscribeFormCreateInput"]},ResolverInputTypes["_ValidationResult"]],
validateUpdateSubscribeForm?: [{	by: ResolverInputTypes["SubscribeFormUniqueWhere"],	data: ResolverInputTypes["SubscribeFormUpdateInput"]},ResolverInputTypes["_ValidationResult"]],
validateCreateSubscribeSubmission?: [{	data: ResolverInputTypes["SubscribeSubmissionCreateInput"]},ResolverInputTypes["_ValidationResult"]],
getContentReferencePositionCategoryItem?: [{	by: ResolverInputTypes["ContentReferencePositionCategoryItemUniqueWhere"],	filter?: ResolverInputTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null},ResolverInputTypes["ContentReferencePositionCategoryItem"]],
listContentReferencePositionCategoryItem?: [{	filter?: ResolverInputTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferencePositionCategoryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReferencePositionCategoryItem"]],
paginateContentReferencePositionCategoryItem?: [{	filter?: ResolverInputTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferencePositionCategoryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferencePositionCategoryItemConnection"]],
getVideo?: [{	by: ResolverInputTypes["VideoUniqueWhere"],	filter?: ResolverInputTypes["VideoWhere"] | undefined | null},ResolverInputTypes["Video"]],
listVideo?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["VideoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Video"]],
paginateVideo?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["VideoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["VideoConnection"]],
getContentReferenceCaseStudyItem?: [{	by: ResolverInputTypes["ContentReferenceCaseStudyItemUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null},ResolverInputTypes["ContentReferenceCaseStudyItem"]],
listContentReferenceCaseStudyItem?: [{	filter?: ResolverInputTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceCaseStudyItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReferenceCaseStudyItem"]],
paginateContentReferenceCaseStudyItem?: [{	filter?: ResolverInputTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceCaseStudyItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceCaseStudyItemConnection"]],
getContentReferenceTagItem?: [{	by: ResolverInputTypes["ContentReferenceTagItemUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceTagItemWhere"] | undefined | null},ResolverInputTypes["ContentReferenceTagItem"]],
listContentReferenceTagItem?: [{	filter?: ResolverInputTypes["ContentReferenceTagItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceTagItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReferenceTagItem"]],
paginateContentReferenceTagItem?: [{	filter?: ResolverInputTypes["ContentReferenceTagItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceTagItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceTagItemConnection"]],
getPositionList?: [{	by: ResolverInputTypes["PositionListUniqueWhere"],	filter?: ResolverInputTypes["PositionListWhere"] | undefined | null},ResolverInputTypes["PositionList"]],
listPositionList?: [{	filter?: ResolverInputTypes["PositionListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionList"]],
paginatePositionList?: [{	filter?: ResolverInputTypes["PositionListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionListConnection"]],
getPositionListItem?: [{	by: ResolverInputTypes["PositionListItemUniqueWhere"],	filter?: ResolverInputTypes["PositionListItemWhere"] | undefined | null},ResolverInputTypes["PositionListItem"]],
listPositionListItem?: [{	filter?: ResolverInputTypes["PositionListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionListItem"]],
paginatePositionListItem?: [{	filter?: ResolverInputTypes["PositionListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionListItemConnection"]],
getTestimonialFull?: [{	by: ResolverInputTypes["TestimonialFullUniqueWhere"],	filter?: ResolverInputTypes["TestimonialFullWhere"] | undefined | null},ResolverInputTypes["TestimonialFull"]],
listTestimonialFull?: [{	filter?: ResolverInputTypes["TestimonialFullWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialFullOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TestimonialFull"]],
paginateTestimonialFull?: [{	filter?: ResolverInputTypes["TestimonialFullWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialFullOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TestimonialFullConnection"]],
getTestimonialFullList?: [{	by: ResolverInputTypes["TestimonialFullListUniqueWhere"],	filter?: ResolverInputTypes["TestimonialFullListWhere"] | undefined | null},ResolverInputTypes["TestimonialFullList"]],
listTestimonialFullList?: [{	filter?: ResolverInputTypes["TestimonialFullListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialFullListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TestimonialFullList"]],
paginateTestimonialFullList?: [{	filter?: ResolverInputTypes["TestimonialFullListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialFullListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TestimonialFullListConnection"]],
getTestimonialFullListItem?: [{	by: ResolverInputTypes["TestimonialFullListItemUniqueWhere"],	filter?: ResolverInputTypes["TestimonialFullListItemWhere"] | undefined | null},ResolverInputTypes["TestimonialFullListItem"]],
listTestimonialFullListItem?: [{	filter?: ResolverInputTypes["TestimonialFullListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialFullListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TestimonialFullListItem"]],
paginateTestimonialFullListItem?: [{	filter?: ResolverInputTypes["TestimonialFullListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialFullListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TestimonialFullListItemConnection"]],
getSecondLevelLinkItem?: [{	by: ResolverInputTypes["SecondLevelLinkItemUniqueWhere"],	filter?: ResolverInputTypes["SecondLevelLinkItemWhere"] | undefined | null},ResolverInputTypes["SecondLevelLinkItem"]],
listSecondLevelLinkItem?: [{	filter?: ResolverInputTypes["SecondLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SecondLevelLinkItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SecondLevelLinkItem"]],
paginateSecondLevelLinkItem?: [{	filter?: ResolverInputTypes["SecondLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SecondLevelLinkItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SecondLevelLinkItemConnection"]],
getThirdLevelLinkItem?: [{	by: ResolverInputTypes["ThirdLevelLinkItemUniqueWhere"],	filter?: ResolverInputTypes["ThirdLevelLinkItemWhere"] | undefined | null},ResolverInputTypes["ThirdLevelLinkItem"]],
listThirdLevelLinkItem?: [{	filter?: ResolverInputTypes["ThirdLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ThirdLevelLinkItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ThirdLevelLinkItem"]],
paginateThirdLevelLinkItem?: [{	filter?: ResolverInputTypes["ThirdLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ThirdLevelLinkItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ThirdLevelLinkItemConnection"]],
getTopLevelLinkItem?: [{	by: ResolverInputTypes["TopLevelLinkItemUniqueWhere"],	filter?: ResolverInputTypes["TopLevelLinkItemWhere"] | undefined | null},ResolverInputTypes["TopLevelLinkItem"]],
listTopLevelLinkItem?: [{	filter?: ResolverInputTypes["TopLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TopLevelLinkItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TopLevelLinkItem"]],
paginateTopLevelLinkItem?: [{	filter?: ResolverInputTypes["TopLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TopLevelLinkItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TopLevelLinkItemConnection"]],
getAuthor?: [{	by: ResolverInputTypes["AuthorUniqueWhere"],	filter?: ResolverInputTypes["AuthorWhere"] | undefined | null},ResolverInputTypes["Author"]],
listAuthor?: [{	filter?: ResolverInputTypes["AuthorWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuthorOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Author"]],
paginateAuthor?: [{	filter?: ResolverInputTypes["AuthorWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuthorOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["AuthorConnection"]],
getAuthorLocale?: [{	by: ResolverInputTypes["AuthorLocaleUniqueWhere"],	filter?: ResolverInputTypes["AuthorLocaleWhere"] | undefined | null},ResolverInputTypes["AuthorLocale"]],
listAuthorLocale?: [{	filter?: ResolverInputTypes["AuthorLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuthorLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["AuthorLocale"]],
paginateAuthorLocale?: [{	filter?: ResolverInputTypes["AuthorLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuthorLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["AuthorLocaleConnection"]],
getBlogPost?: [{	by: ResolverInputTypes["BlogPostUniqueWhere"],	filter?: ResolverInputTypes["BlogPostWhere"] | undefined | null},ResolverInputTypes["BlogPost"]],
listBlogPost?: [{	filter?: ResolverInputTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BlogPost"]],
paginateBlogPost?: [{	filter?: ResolverInputTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BlogPostConnection"]],
getBlogPostLocale?: [{	by: ResolverInputTypes["BlogPostLocaleUniqueWhere"],	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null},ResolverInputTypes["BlogPostLocale"]],
listBlogPostLocale?: [{	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BlogPostLocale"]],
paginateBlogPostLocale?: [{	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BlogPostLocaleConnection"]],
getContentReferenceBlogPostItem?: [{	by: ResolverInputTypes["ContentReferenceBlogPostItemUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceBlogPostItemWhere"] | undefined | null},ResolverInputTypes["ContentReferenceBlogPostItem"]],
listContentReferenceBlogPostItem?: [{	filter?: ResolverInputTypes["ContentReferenceBlogPostItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceBlogPostItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReferenceBlogPostItem"]],
paginateContentReferenceBlogPostItem?: [{	filter?: ResolverInputTypes["ContentReferenceBlogPostItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceBlogPostItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceBlogPostItemConnection"]],
getRelevantBlogPostList?: [{	by: ResolverInputTypes["RelevantBlogPostListUniqueWhere"],	filter?: ResolverInputTypes["RelevantBlogPostListWhere"] | undefined | null},ResolverInputTypes["RelevantBlogPostList"]],
listRelevantBlogPostList?: [{	filter?: ResolverInputTypes["RelevantBlogPostListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RelevantBlogPostListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["RelevantBlogPostList"]],
paginateRelevantBlogPostList?: [{	filter?: ResolverInputTypes["RelevantBlogPostListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RelevantBlogPostListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["RelevantBlogPostListConnection"]],
getBlogPostCommon?: [{	by: ResolverInputTypes["BlogPostCommonUniqueWhere"],	filter?: ResolverInputTypes["BlogPostCommonWhere"] | undefined | null},ResolverInputTypes["BlogPostCommon"]],
listBlogPostCommon?: [{	filter?: ResolverInputTypes["BlogPostCommonWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostCommonOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BlogPostCommon"]],
paginateBlogPostCommon?: [{	filter?: ResolverInputTypes["BlogPostCommonWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostCommonOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BlogPostCommonConnection"]],
getBlogPostCommonLocale?: [{	by: ResolverInputTypes["BlogPostCommonLocaleUniqueWhere"],	filter?: ResolverInputTypes["BlogPostCommonLocaleWhere"] | undefined | null},ResolverInputTypes["BlogPostCommonLocale"]],
listBlogPostCommonLocale?: [{	filter?: ResolverInputTypes["BlogPostCommonLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostCommonLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BlogPostCommonLocale"]],
paginateBlogPostCommonLocale?: [{	filter?: ResolverInputTypes["BlogPostCommonLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostCommonLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BlogPostCommonLocaleConnection"]],
	transaction?:ResolverInputTypes["QueryTransaction"],
	_info?:ResolverInputTypes["Info"],
		__typename?: boolean | `@${string}`
}>;
	["_Schema"]: AliasType<{
	enums?:ResolverInputTypes["_Enum"],
	entities?:ResolverInputTypes["_Entity"],
		__typename?: boolean | `@${string}`
}>;
	["_Enum"]: AliasType<{
	name?:boolean | `@${string}`,
	values?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Entity"]: AliasType<{
	name?:boolean | `@${string}`,
	customPrimaryAllowed?:boolean | `@${string}`,
	fields?:ResolverInputTypes["_Field"],
	unique?:ResolverInputTypes["_UniqueConstraint"],
		__typename?: boolean | `@${string}`
}>;
	["_UniqueConstraint"]: AliasType<{
	fields?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["General"]: AliasType<{
	_meta?:ResolverInputTypes["GeneralMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GeneralLocale"]],
localesByLocale?: [{	by: ResolverInputTypes["GeneralLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
localesBySeo?: [{	by: ResolverInputTypes["GeneralLocalesBySeoUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
localesByContent?: [{	by: ResolverInputTypes["GeneralLocalesByContentUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GeneralMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	unique?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FieldMeta"]: AliasType<{
	readable?:boolean | `@${string}`,
	updatable?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UUID"]:unknown;
	["One"]:One;
	["GeneralLocale"]: AliasType<{
	_meta?:ResolverInputTypes["GeneralLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null},ResolverInputTypes["General"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
seo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
homeLinkable?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
caseStudiesLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
positionsLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
	facebookUrl?:boolean | `@${string}`,
	twitterUrl?:boolean | `@${string}`,
	instagramUrl?:boolean | `@${string}`,
	youtubeUrl?:boolean | `@${string}`,
	linkedinUrl?:boolean | `@${string}`,
	formPrivacyPolicy?:boolean | `@${string}`,
	formMarketingConsent?:boolean | `@${string}`,
	acceptButtonLabel?:boolean | `@${string}`,
	rejectButtonLabel?:boolean | `@${string}`,
content?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	seo?:ResolverInputTypes["FieldMeta"],
	homeLinkable?:ResolverInputTypes["FieldMeta"],
	caseStudiesLink?:ResolverInputTypes["FieldMeta"],
	positionsLink?:ResolverInputTypes["FieldMeta"],
	facebookUrl?:ResolverInputTypes["FieldMeta"],
	twitterUrl?:ResolverInputTypes["FieldMeta"],
	instagramUrl?:ResolverInputTypes["FieldMeta"],
	youtubeUrl?:ResolverInputTypes["FieldMeta"],
	linkedinUrl?:ResolverInputTypes["FieldMeta"],
	formPrivacyPolicy?:ResolverInputTypes["FieldMeta"],
	formMarketingConsent?:ResolverInputTypes["FieldMeta"],
	acceptButtonLabel?:ResolverInputTypes["FieldMeta"],
	rejectButtonLabel?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	unique?: ResolverInputTypes["OneCondition"] | undefined | null,
	locales?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["GeneralWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["GeneralWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["GeneralWhere"] | undefined | null
};
	["UUIDCondition"]: {
	and?: Array<ResolverInputTypes["UUIDCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["UUIDCondition"]> | undefined | null,
	not?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["UUID"] | undefined | null,
	notEq?: ResolverInputTypes["UUID"] | undefined | null,
	in?: Array<ResolverInputTypes["UUID"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["UUID"]> | undefined | null,
	lt?: ResolverInputTypes["UUID"] | undefined | null,
	lte?: ResolverInputTypes["UUID"] | undefined | null,
	gt?: ResolverInputTypes["UUID"] | undefined | null,
	gte?: ResolverInputTypes["UUID"] | undefined | null
};
	["OneCondition"]: {
	and?: Array<ResolverInputTypes["OneCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["OneCondition"]> | undefined | null,
	not?: ResolverInputTypes["OneCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["One"] | undefined | null,
	notEq?: ResolverInputTypes["One"] | undefined | null,
	in?: Array<ResolverInputTypes["One"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["One"]> | undefined | null,
	lt?: ResolverInputTypes["One"] | undefined | null,
	lte?: ResolverInputTypes["One"] | undefined | null,
	gt?: ResolverInputTypes["One"] | undefined | null,
	gte?: ResolverInputTypes["One"] | undefined | null
};
	["GeneralLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	root?: ResolverInputTypes["GeneralWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoWhere"] | undefined | null,
	homeLinkable?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	caseStudiesLink?: ResolverInputTypes["LinkWhere"] | undefined | null,
	positionsLink?: ResolverInputTypes["LinkWhere"] | undefined | null,
	facebookUrl?: ResolverInputTypes["StringCondition"] | undefined | null,
	twitterUrl?: ResolverInputTypes["StringCondition"] | undefined | null,
	instagramUrl?: ResolverInputTypes["StringCondition"] | undefined | null,
	youtubeUrl?: ResolverInputTypes["StringCondition"] | undefined | null,
	linkedinUrl?: ResolverInputTypes["StringCondition"] | undefined | null,
	formPrivacyPolicy?: ResolverInputTypes["StringCondition"] | undefined | null,
	formMarketingConsent?: ResolverInputTypes["StringCondition"] | undefined | null,
	acceptButtonLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	rejectButtonLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	content?: ResolverInputTypes["ContentWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["GeneralLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["GeneralLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null
};
	["LocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	code?: ResolverInputTypes["StringCondition"] | undefined | null,
	label?: ResolverInputTypes["StringCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	general?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,
	genericPages?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,
	headers?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,
	footers?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,
	caseStudies?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null,
	tags?: ResolverInputTypes["TagLocaleWhere"] | undefined | null,
	positionTags?: ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null,
	positions?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null,
	positionCategories?: ResolverInputTypes["PositionCategoryLocaleWhere"] | undefined | null,
	authors?: ResolverInputTypes["AuthorLocaleWhere"] | undefined | null,
	blogPosts?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null,
	blogPostCommon?: ResolverInputTypes["BlogPostCommonLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["LocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["LocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["LocaleWhere"] | undefined | null
};
	["StringCondition"]: {
	and?: Array<ResolverInputTypes["StringCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["StringCondition"]> | undefined | null,
	not?: ResolverInputTypes["StringCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: string | undefined | null,
	notEq?: string | undefined | null,
	in?: Array<string> | undefined | null,
	notIn?: Array<string> | undefined | null,
	lt?: string | undefined | null,
	lte?: string | undefined | null,
	gt?: string | undefined | null,
	gte?: string | undefined | null,
	contains?: string | undefined | null,
	startsWith?: string | undefined | null,
	endsWith?: string | undefined | null,
	containsCI?: string | undefined | null,
	startsWithCI?: string | undefined | null,
	endsWithCI?: string | undefined | null
};
	["IntCondition"]: {
	and?: Array<ResolverInputTypes["IntCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["IntCondition"]> | undefined | null,
	not?: ResolverInputTypes["IntCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: number | undefined | null,
	notEq?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null
};
	["GenericPageLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["GenericPageWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null
};
	["GenericPageWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	locales?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,
	publishedAt?: ResolverInputTypes["DateTimeCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["GenericPageWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["GenericPageWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["GenericPageWhere"] | undefined | null
};
	["DateTimeCondition"]: {
	and?: Array<ResolverInputTypes["DateTimeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["DateTimeCondition"]> | undefined | null,
	not?: ResolverInputTypes["DateTimeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["DateTime"] | undefined | null,
	notEq?: ResolverInputTypes["DateTime"] | undefined | null,
	in?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	lt?: ResolverInputTypes["DateTime"] | undefined | null,
	lte?: ResolverInputTypes["DateTime"] | undefined | null,
	gt?: ResolverInputTypes["DateTime"] | undefined | null,
	gte?: ResolverInputTypes["DateTime"] | undefined | null
};
	["DateTime"]:unknown;
	["ContentWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	blocks?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContentWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContentWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContentWhere"] | undefined | null
};
	["ContentBlockWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	json?: ResolverInputTypes["StringCondition"] | undefined | null,
	content?: ResolverInputTypes["ContentWhere"] | undefined | null,
	references?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContentBlockWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContentBlockWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContentBlockWhere"] | undefined | null
};
	["ContentReferenceWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	type?: ResolverInputTypes["ContentReferenceTypeCondition"] | undefined | null,
	primaryText?: ResolverInputTypes["StringCondition"] | undefined | null,
	block?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	secondaryText?: ResolverInputTypes["StringCondition"] | undefined | null,
	link?: ResolverInputTypes["LinkWhere"] | undefined | null,
	featuredCaseStudies?: ResolverInputTypes["CaseStudyListWhere"] | undefined | null,
	images?: ResolverInputTypes["ImageListWhere"] | undefined | null,
	otherImages?: ResolverInputTypes["ImageListWhere"] | undefined | null,
	informationList?: ResolverInputTypes["InformationListWhere"] | undefined | null,
	tertiaryText?: ResolverInputTypes["StringCondition"] | undefined | null,
	embed?: ResolverInputTypes["EmbedWhere"] | undefined | null,
	links?: ResolverInputTypes["LinkListWhere"] | undefined | null,
	talkLists?: ResolverInputTypes["TalkListsWhere"] | undefined | null,
	businessCardList?: ResolverInputTypes["BusinessCardListWhere"] | undefined | null,
	testimonials?: ResolverInputTypes["TestimonialListWhere"] | undefined | null,
	background?: ResolverInputTypes["ContentBackgroundTypeCondition"] | undefined | null,
	otherImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	caseStudy?: ResolverInputTypes["CaseStudyWhere"] | undefined | null,
	alternative?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	contactRequestOptions?: ResolverInputTypes["ContactRequestOptionListWhere"] | undefined | null,
	youtubeVideo?: ResolverInputTypes["YoutubeVideoWhere"] | undefined | null,
	subscribeForm?: ResolverInputTypes["SubscribeFormWhere"] | undefined | null,
	video?: ResolverInputTypes["VideoWhere"] | undefined | null,
	otherVideo?: ResolverInputTypes["VideoWhere"] | undefined | null,
	positionCategories?: ResolverInputTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null,
	caseStudies?: ResolverInputTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null,
	tags?: ResolverInputTypes["ContentReferenceTagItemWhere"] | undefined | null,
	positions?: ResolverInputTypes["PositionListWhere"] | undefined | null,
	testimonialsFull?: ResolverInputTypes["TestimonialFullListWhere"] | undefined | null,
	blogPosts?: ResolverInputTypes["ContentReferenceBlogPostItemWhere"] | undefined | null,
	verticalMargin?: ResolverInputTypes["ContentVerticalMarginCondition"] | undefined | null,
	variant?: ResolverInputTypes["StringCondition"] | undefined | null,
	content?: ResolverInputTypes["ContentWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContentReferenceWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContentReferenceWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null
};
	["ContentReferenceTypeCondition"]: {
	and?: Array<ResolverInputTypes["ContentReferenceTypeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["ContentReferenceTypeCondition"]> | undefined | null,
	not?: ResolverInputTypes["ContentReferenceTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["ContentReferenceType"] | undefined | null,
	notEq?: ResolverInputTypes["ContentReferenceType"] | undefined | null,
	in?: Array<ResolverInputTypes["ContentReferenceType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ContentReferenceType"]> | undefined | null,
	lt?: ResolverInputTypes["ContentReferenceType"] | undefined | null,
	lte?: ResolverInputTypes["ContentReferenceType"] | undefined | null,
	gt?: ResolverInputTypes["ContentReferenceType"] | undefined | null,
	gte?: ResolverInputTypes["ContentReferenceType"] | undefined | null
};
	["ContentReferenceType"]:ContentReferenceType;
	["ImageWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	url?: ResolverInputTypes["StringCondition"] | undefined | null,
	width?: ResolverInputTypes["IntCondition"] | undefined | null,
	height?: ResolverInputTypes["IntCondition"] | undefined | null,
	size?: ResolverInputTypes["IntCondition"] | undefined | null,
	type?: ResolverInputTypes["StringCondition"] | undefined | null,
	alt?: ResolverInputTypes["StringCondition"] | undefined | null,
	fileName?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["ImageWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ImageWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ImageWhere"] | undefined | null
};
	["LinkWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	externalLink?: ResolverInputTypes["StringCondition"] | undefined | null,
	internalLink?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	type?: ResolverInputTypes["LinkTypeCondition"] | undefined | null,
	isTargetBlank?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["LinkWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["LinkWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["LinkWhere"] | undefined | null
};
	["LinkableWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	url?: ResolverInputTypes["StringCondition"] | undefined | null,
	genericPage?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,
	caseStudy?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null,
	position?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null,
	redirect?: ResolverInputTypes["RedirectWhere"] | undefined | null,
	blogPost?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["LinkableWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["LinkableWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["LinkableWhere"] | undefined | null
};
	["CaseStudyLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["CaseStudyWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoWhere"] | undefined | null,
	note?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null
};
	["CaseStudyWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	locales?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null,
	tags?: ResolverInputTypes["CaseStudyTagWhere"] | undefined | null,
	wideImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	narrowImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	publishedAt?: ResolverInputTypes["DateTimeCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["CaseStudyWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["CaseStudyWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["CaseStudyWhere"] | undefined | null
};
	["CaseStudyTagWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	caseStudy?: ResolverInputTypes["CaseStudyWhere"] | undefined | null,
	tag?: ResolverInputTypes["TagWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["CaseStudyTagWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["CaseStudyTagWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["CaseStudyTagWhere"] | undefined | null
};
	["TagWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	locales?: ResolverInputTypes["TagLocaleWhere"] | undefined | null,
	caseStudyTags?: ResolverInputTypes["CaseStudyTagWhere"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["TagWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TagWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TagWhere"] | undefined | null
};
	["TagLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	slug?: ResolverInputTypes["StringCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["TagWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TagLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TagLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TagLocaleWhere"] | undefined | null
};
	["SeoWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	description?: ResolverInputTypes["StringCondition"] | undefined | null,
	ogTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	ogDescription?: ResolverInputTypes["StringCondition"] | undefined | null,
	ogImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	noIndex?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	noFollow?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["SeoWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["SeoWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["SeoWhere"] | undefined | null
};
	["BooleanCondition"]: {
	and?: Array<ResolverInputTypes["BooleanCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["BooleanCondition"]> | undefined | null,
	not?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: boolean | undefined | null,
	notEq?: boolean | undefined | null,
	in?: Array<boolean> | undefined | null,
	notIn?: Array<boolean> | undefined | null,
	lt?: boolean | undefined | null,
	lte?: boolean | undefined | null,
	gt?: boolean | undefined | null,
	gte?: boolean | undefined | null
};
	["PositionLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	link?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	root?: ResolverInputTypes["PositionWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentWhere"] | undefined | null,
	summary?: ResolverInputTypes["StringCondition"] | undefined | null,
	salaryRange?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["PositionLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["PositionLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null
};
	["PositionWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	tags?: ResolverInputTypes["PositionTagListWhere"] | undefined | null,
	locales?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null,
	orderInCategory?: ResolverInputTypes["IntCondition"] | undefined | null,
	category?: ResolverInputTypes["PositionCategoryWhere"] | undefined | null,
	publishedAt?: ResolverInputTypes["DateTimeCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["PositionWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["PositionWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["PositionWhere"] | undefined | null
};
	["PositionTagListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["PositionTagListItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["PositionTagListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["PositionTagListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["PositionTagListWhere"] | undefined | null
};
	["PositionTagListItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["PositionTagListWhere"] | undefined | null,
	tag?: ResolverInputTypes["PositionTagWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["PositionTagListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["PositionTagListItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["PositionTagListItemWhere"] | undefined | null
};
	["PositionTagWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	locales?: ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["PositionTagWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["PositionTagWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["PositionTagWhere"] | undefined | null
};
	["PositionTagLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	slug?: ResolverInputTypes["StringCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["PositionTagWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null
};
	["PositionCategoryWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	positions?: ResolverInputTypes["PositionWhere"] | undefined | null,
	locales?: ResolverInputTypes["PositionCategoryLocaleWhere"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["PositionCategoryWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["PositionCategoryWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["PositionCategoryWhere"] | undefined | null
};
	["PositionCategoryLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["PositionCategoryWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["PositionCategoryLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["PositionCategoryLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["PositionCategoryLocaleWhere"] | undefined | null
};
	["RedirectWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	link?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	target?: ResolverInputTypes["LinkWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["RedirectWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["RedirectWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["RedirectWhere"] | undefined | null
};
	["BlogPostLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["BlogPostWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	lead?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null
};
	["BlogPostWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	publishedAt?: ResolverInputTypes["DateTimeCondition"] | undefined | null,
	author?: ResolverInputTypes["AuthorWhere"] | undefined | null,
	wideImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	narrowImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	locales?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null,
	relevantBlogPosts?: ResolverInputTypes["RelevantBlogPostListWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["BlogPostWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["BlogPostWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["BlogPostWhere"] | undefined | null
};
	["AuthorWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	locales?: ResolverInputTypes["AuthorLocaleWhere"] | undefined | null,
	blogPosts?: ResolverInputTypes["BlogPostWhere"] | undefined | null,
	name?: ResolverInputTypes["StringCondition"] | undefined | null,
	profileUrl?: ResolverInputTypes["StringCondition"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	avatarImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["AuthorWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["AuthorWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["AuthorWhere"] | undefined | null
};
	["AuthorLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	role?: ResolverInputTypes["StringCondition"] | undefined | null,
	bio?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["AuthorWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["AuthorLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["AuthorLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["AuthorLocaleWhere"] | undefined | null
};
	["RelevantBlogPostListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	blogPost?: ResolverInputTypes["BlogPostWhere"] | undefined | null,
	relevantBlogPost?: ResolverInputTypes["BlogPostWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["RelevantBlogPostListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["RelevantBlogPostListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["RelevantBlogPostListWhere"] | undefined | null
};
	["LinkTypeCondition"]: {
	and?: Array<ResolverInputTypes["LinkTypeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["LinkTypeCondition"]> | undefined | null,
	not?: ResolverInputTypes["LinkTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["LinkType"] | undefined | null,
	notEq?: ResolverInputTypes["LinkType"] | undefined | null,
	in?: Array<ResolverInputTypes["LinkType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["LinkType"]> | undefined | null,
	lt?: ResolverInputTypes["LinkType"] | undefined | null,
	lte?: ResolverInputTypes["LinkType"] | undefined | null,
	gt?: ResolverInputTypes["LinkType"] | undefined | null,
	gte?: ResolverInputTypes["LinkType"] | undefined | null
};
	["LinkType"]:LinkType;
	["CaseStudyListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["CaseStudyListItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["CaseStudyListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["CaseStudyListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["CaseStudyListWhere"] | undefined | null
};
	["CaseStudyListItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["CaseStudyListWhere"] | undefined | null,
	caseStudy?: ResolverInputTypes["CaseStudyWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["CaseStudyListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["CaseStudyListItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["CaseStudyListItemWhere"] | undefined | null
};
	["ImageListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ImageListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ImageListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ImageListWhere"] | undefined | null
};
	["ImageListItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["ImageListWhere"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ImageListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ImageListItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ImageListItemWhere"] | undefined | null
};
	["InformationListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["PieceOfInformationWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["InformationListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["InformationListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["InformationListWhere"] | undefined | null
};
	["PieceOfInformationWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	description?: ResolverInputTypes["StringCondition"] | undefined | null,
	list?: ResolverInputTypes["InformationListWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["PieceOfInformationWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["PieceOfInformationWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["PieceOfInformationWhere"] | undefined | null
};
	["EmbedWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	type?: ResolverInputTypes["EmbedTypeCondition"] | undefined | null,
	embedId?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["EmbedWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["EmbedWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["EmbedWhere"] | undefined | null
};
	["EmbedTypeCondition"]: {
	and?: Array<ResolverInputTypes["EmbedTypeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["EmbedTypeCondition"]> | undefined | null,
	not?: ResolverInputTypes["EmbedTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["EmbedType"] | undefined | null,
	notEq?: ResolverInputTypes["EmbedType"] | undefined | null,
	in?: Array<ResolverInputTypes["EmbedType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["EmbedType"]> | undefined | null,
	lt?: ResolverInputTypes["EmbedType"] | undefined | null,
	lte?: ResolverInputTypes["EmbedType"] | undefined | null,
	gt?: ResolverInputTypes["EmbedType"] | undefined | null,
	gte?: ResolverInputTypes["EmbedType"] | undefined | null
};
	["EmbedType"]:EmbedType;
	["LinkListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["LinkListItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["LinkListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["LinkListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["LinkListWhere"] | undefined | null
};
	["LinkListItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["LinkListWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["LinkListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["LinkListItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["LinkListItemWhere"] | undefined | null
};
	["TalkListsWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["TalkListWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TalkListsWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TalkListsWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TalkListsWhere"] | undefined | null
};
	["TalkListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	list?: ResolverInputTypes["TalkListsWhere"] | undefined | null,
	items?: ResolverInputTypes["TalkListItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TalkListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TalkListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TalkListWhere"] | undefined | null
};
	["TalkListItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["TalkListWhere"] | undefined | null,
	talk?: ResolverInputTypes["TalkWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TalkListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TalkListItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TalkListItemWhere"] | undefined | null
};
	["TalkWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	slug?: ResolverInputTypes["StringCondition"] | undefined | null,
	date?: ResolverInputTypes["DateTimeCondition"] | undefined | null,
	note?: ResolverInputTypes["StringCondition"] | undefined | null,
	content?: ResolverInputTypes["StringCondition"] | undefined | null,
	coverImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	otherImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TalkWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TalkWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TalkWhere"] | undefined | null
};
	["BusinessCardListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["BusinessCardListItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["BusinessCardListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["BusinessCardListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["BusinessCardListWhere"] | undefined | null
};
	["BusinessCardListItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["BusinessCardListWhere"] | undefined | null,
	businessCard?: ResolverInputTypes["BusinessCardWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["BusinessCardListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["BusinessCardListItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["BusinessCardListItemWhere"] | undefined | null
};
	["BusinessCardWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	name?: ResolverInputTypes["StringCondition"] | undefined | null,
	role?: ResolverInputTypes["StringCondition"] | undefined | null,
	email?: ResolverInputTypes["StringCondition"] | undefined | null,
	avatar?: ResolverInputTypes["ImageWhere"] | undefined | null,
	phone?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["BusinessCardWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["BusinessCardWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["BusinessCardWhere"] | undefined | null
};
	["TestimonialListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["TestimonialListItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TestimonialListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TestimonialListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TestimonialListWhere"] | undefined | null
};
	["TestimonialListItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["TestimonialListWhere"] | undefined | null,
	testimonial?: ResolverInputTypes["TestimonialWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TestimonialListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TestimonialListItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TestimonialListItemWhere"] | undefined | null
};
	["TestimonialWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	content?: ResolverInputTypes["StringCondition"] | undefined | null,
	note?: ResolverInputTypes["StringCondition"] | undefined | null,
	avatar?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TestimonialWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TestimonialWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TestimonialWhere"] | undefined | null
};
	["ContentBackgroundTypeCondition"]: {
	and?: Array<ResolverInputTypes["ContentBackgroundTypeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["ContentBackgroundTypeCondition"]> | undefined | null,
	not?: ResolverInputTypes["ContentBackgroundTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["ContentBackgroundType"] | undefined | null,
	notEq?: ResolverInputTypes["ContentBackgroundType"] | undefined | null,
	in?: Array<ResolverInputTypes["ContentBackgroundType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ContentBackgroundType"]> | undefined | null,
	lt?: ResolverInputTypes["ContentBackgroundType"] | undefined | null,
	lte?: ResolverInputTypes["ContentBackgroundType"] | undefined | null,
	gt?: ResolverInputTypes["ContentBackgroundType"] | undefined | null,
	gte?: ResolverInputTypes["ContentBackgroundType"] | undefined | null
};
	["ContentBackgroundType"]:ContentBackgroundType;
	["ContactRequestOptionListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["ContactRequestOptionWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContactRequestOptionListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContactRequestOptionListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContactRequestOptionListWhere"] | undefined | null
};
	["ContactRequestOptionWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	label?: ResolverInputTypes["StringCondition"] | undefined | null,
	description?: ResolverInputTypes["StringCondition"] | undefined | null,
	list?: ResolverInputTypes["ContactRequestOptionListWhere"] | undefined | null,
	code?: ResolverInputTypes["StringCondition"] | undefined | null,
	type?: ResolverInputTypes["ContactRequestOptionTypeCondition"] | undefined | null,
	subscribeForm?: ResolverInputTypes["SubscribeFormWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContactRequestOptionWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContactRequestOptionWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContactRequestOptionWhere"] | undefined | null
};
	["ContactRequestOptionTypeCondition"]: {
	and?: Array<ResolverInputTypes["ContactRequestOptionTypeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["ContactRequestOptionTypeCondition"]> | undefined | null,
	not?: ResolverInputTypes["ContactRequestOptionTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["ContactRequestOptionType"] | undefined | null,
	notEq?: ResolverInputTypes["ContactRequestOptionType"] | undefined | null,
	in?: Array<ResolverInputTypes["ContactRequestOptionType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ContactRequestOptionType"]> | undefined | null,
	lt?: ResolverInputTypes["ContactRequestOptionType"] | undefined | null,
	lte?: ResolverInputTypes["ContactRequestOptionType"] | undefined | null,
	gt?: ResolverInputTypes["ContactRequestOptionType"] | undefined | null,
	gte?: ResolverInputTypes["ContactRequestOptionType"] | undefined | null
};
	["ContactRequestOptionType"]:ContactRequestOptionType;
	["SubscribeFormWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	name?: ResolverInputTypes["StringCondition"] | undefined | null,
	datacruitJobId?: ResolverInputTypes["IntCondition"] | undefined | null,
	datacruitReferenceNumber?: ResolverInputTypes["StringCondition"] | undefined | null,
	lastUpdateAt?: ResolverInputTypes["DateTimeCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["SubscribeFormWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["SubscribeFormWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["SubscribeFormWhere"] | undefined | null
};
	["YoutubeVideoWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	videoId?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["YoutubeVideoWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["YoutubeVideoWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["YoutubeVideoWhere"] | undefined | null
};
	["VideoWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	src?: ResolverInputTypes["StringCondition"] | undefined | null,
	height?: ResolverInputTypes["IntCondition"] | undefined | null,
	width?: ResolverInputTypes["IntCondition"] | undefined | null,
	duration?: ResolverInputTypes["FloatCondition"] | undefined | null,
	size?: ResolverInputTypes["IntCondition"] | undefined | null,
	type?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["VideoWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["VideoWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["VideoWhere"] | undefined | null
};
	["FloatCondition"]: {
	and?: Array<ResolverInputTypes["FloatCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["FloatCondition"]> | undefined | null,
	not?: ResolverInputTypes["FloatCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: number | undefined | null,
	notEq?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null
};
	["ContentReferencePositionCategoryItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	item?: ResolverInputTypes["PositionCategoryWhere"] | undefined | null,
	reference?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null
};
	["ContentReferenceCaseStudyItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	item?: ResolverInputTypes["CaseStudyWhere"] | undefined | null,
	reference?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null
};
	["ContentReferenceTagItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	item?: ResolverInputTypes["TagWhere"] | undefined | null,
	reference?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContentReferenceTagItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContentReferenceTagItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContentReferenceTagItemWhere"] | undefined | null
};
	["PositionListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["PositionListItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["PositionListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["PositionListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["PositionListWhere"] | undefined | null
};
	["PositionListItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["PositionListWhere"] | undefined | null,
	position?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["PositionListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["PositionListItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["PositionListItemWhere"] | undefined | null
};
	["TestimonialFullListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["TestimonialFullListItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TestimonialFullListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TestimonialFullListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TestimonialFullListWhere"] | undefined | null
};
	["TestimonialFullListItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["TestimonialFullListWhere"] | undefined | null,
	testimonial?: ResolverInputTypes["TestimonialFullWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TestimonialFullListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TestimonialFullListItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TestimonialFullListItemWhere"] | undefined | null
};
	["TestimonialFullWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	content?: ResolverInputTypes["StringCondition"] | undefined | null,
	name?: ResolverInputTypes["StringCondition"] | undefined | null,
	note?: ResolverInputTypes["StringCondition"] | undefined | null,
	avatar?: ResolverInputTypes["ImageWhere"] | undefined | null,
	imageVariant?: ResolverInputTypes["ImageVariantCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["TestimonialFullWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TestimonialFullWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TestimonialFullWhere"] | undefined | null
};
	["ImageVariantCondition"]: {
	and?: Array<ResolverInputTypes["ImageVariantCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["ImageVariantCondition"]> | undefined | null,
	not?: ResolverInputTypes["ImageVariantCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["ImageVariant"] | undefined | null,
	notEq?: ResolverInputTypes["ImageVariant"] | undefined | null,
	in?: Array<ResolverInputTypes["ImageVariant"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ImageVariant"]> | undefined | null,
	lt?: ResolverInputTypes["ImageVariant"] | undefined | null,
	lte?: ResolverInputTypes["ImageVariant"] | undefined | null,
	gt?: ResolverInputTypes["ImageVariant"] | undefined | null,
	gte?: ResolverInputTypes["ImageVariant"] | undefined | null
};
	["ImageVariant"]:ImageVariant;
	["ContentReferenceBlogPostItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	publishedAt?: ResolverInputTypes["DateCondition"] | undefined | null,
	item?: ResolverInputTypes["BlogPostWhere"] | undefined | null,
	reference?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContentReferenceBlogPostItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContentReferenceBlogPostItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContentReferenceBlogPostItemWhere"] | undefined | null
};
	["DateCondition"]: {
	and?: Array<ResolverInputTypes["DateCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["DateCondition"]> | undefined | null,
	not?: ResolverInputTypes["DateCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["Date"] | undefined | null,
	notEq?: ResolverInputTypes["Date"] | undefined | null,
	in?: Array<ResolverInputTypes["Date"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["Date"]> | undefined | null,
	lt?: ResolverInputTypes["Date"] | undefined | null,
	lte?: ResolverInputTypes["Date"] | undefined | null,
	gt?: ResolverInputTypes["Date"] | undefined | null,
	gte?: ResolverInputTypes["Date"] | undefined | null
};
	["Date"]:unknown;
	["ContentVerticalMarginCondition"]: {
	and?: Array<ResolverInputTypes["ContentVerticalMarginCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["ContentVerticalMarginCondition"]> | undefined | null,
	not?: ResolverInputTypes["ContentVerticalMarginCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["ContentVerticalMargin"] | undefined | null,
	notEq?: ResolverInputTypes["ContentVerticalMargin"] | undefined | null,
	in?: Array<ResolverInputTypes["ContentVerticalMargin"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ContentVerticalMargin"]> | undefined | null,
	lt?: ResolverInputTypes["ContentVerticalMargin"] | undefined | null,
	lte?: ResolverInputTypes["ContentVerticalMargin"] | undefined | null,
	gt?: ResolverInputTypes["ContentVerticalMargin"] | undefined | null,
	gte?: ResolverInputTypes["ContentVerticalMargin"] | undefined | null
};
	["ContentVerticalMargin"]:ContentVerticalMargin;
	["HeaderLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	email?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["HeaderWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	linkList?: ResolverInputTypes["LinkListWhere"] | undefined | null,
	socialLinkList?: ResolverInputTypes["LinkListWhere"] | undefined | null,
	topLevelLinkItems?: ResolverInputTypes["TopLevelLinkItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["HeaderLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["HeaderLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null
};
	["HeaderWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	unique?: ResolverInputTypes["OneCondition"] | undefined | null,
	locales?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["HeaderWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["HeaderWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["HeaderWhere"] | undefined | null
};
	["TopLevelLinkItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	isLink?: ResolverInputTypes["IsLinkCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	secondLevelLinkItems?: ResolverInputTypes["SecondLevelLinkItemWhere"] | undefined | null,
	header?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TopLevelLinkItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TopLevelLinkItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TopLevelLinkItemWhere"] | undefined | null
};
	["IsLinkCondition"]: {
	and?: Array<ResolverInputTypes["IsLinkCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["IsLinkCondition"]> | undefined | null,
	not?: ResolverInputTypes["IsLinkCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["IsLink"] | undefined | null,
	notEq?: ResolverInputTypes["IsLink"] | undefined | null,
	in?: Array<ResolverInputTypes["IsLink"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["IsLink"]> | undefined | null,
	lt?: ResolverInputTypes["IsLink"] | undefined | null,
	lte?: ResolverInputTypes["IsLink"] | undefined | null,
	gt?: ResolverInputTypes["IsLink"] | undefined | null,
	gte?: ResolverInputTypes["IsLink"] | undefined | null
};
	["IsLink"]:IsLink;
	["SecondLevelLinkItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	isLink?: ResolverInputTypes["IsLinkCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	topLevelLinkItem?: ResolverInputTypes["TopLevelLinkItemWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkWhere"] | undefined | null,
	thirdLevelLinkItems?: ResolverInputTypes["ThirdLevelLinkItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["SecondLevelLinkItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["SecondLevelLinkItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["SecondLevelLinkItemWhere"] | undefined | null
};
	["ThirdLevelLinkItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	secondLevelLinkItem?: ResolverInputTypes["SecondLevelLinkItemWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ThirdLevelLinkItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ThirdLevelLinkItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ThirdLevelLinkItemWhere"] | undefined | null
};
	["FooterLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	email?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["FooterWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	address?: ResolverInputTypes["StringCondition"] | undefined | null,
	phone?: ResolverInputTypes["StringCondition"] | undefined | null,
	legal?: ResolverInputTypes["StringCondition"] | undefined | null,
	navigation?: ResolverInputTypes["LinkListWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["FooterLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FooterLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null
};
	["FooterWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	unique?: ResolverInputTypes["OneCondition"] | undefined | null,
	locales?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["FooterWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FooterWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FooterWhere"] | undefined | null
};
	["BlogPostCommonLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	root?: ResolverInputTypes["BlogPostCommonWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	ctaLink?: ResolverInputTypes["LinkWhere"] | undefined | null,
	ctaTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["BlogPostCommonLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["BlogPostCommonLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["BlogPostCommonLocaleWhere"] | undefined | null
};
	["BlogPostCommonWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	unique?: ResolverInputTypes["OneCondition"] | undefined | null,
	locales?: ResolverInputTypes["BlogPostCommonLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["BlogPostCommonWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["BlogPostCommonWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["BlogPostCommonWhere"] | undefined | null
};
	["Locale"]: AliasType<{
	_meta?:ResolverInputTypes["LocaleMeta"],
	id?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
general?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GeneralLocale"]],
genericPages?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPageLocale"]],
headers?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HeaderLocale"]],
footers?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterLocale"]],
caseStudies?: [{	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CaseStudyLocale"]],
tags?: [{	filter?: ResolverInputTypes["TagLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TagLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TagLocale"]],
positionTags?: [{	filter?: ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionTagLocale"]],
positions?: [{	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionLocale"]],
positionCategories?: [{	filter?: ResolverInputTypes["PositionCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionCategoryLocale"]],
authors?: [{	filter?: ResolverInputTypes["AuthorLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuthorLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["AuthorLocale"]],
blogPosts?: [{	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BlogPostLocale"]],
blogPostCommon?: [{	filter?: ResolverInputTypes["BlogPostCommonLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostCommonLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BlogPostCommonLocale"]],
generalByRoot?: [{	by: ResolverInputTypes["LocaleGeneralByRootUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
generalBySeo?: [{	by: ResolverInputTypes["LocaleGeneralBySeoUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
generalByContent?: [{	by: ResolverInputTypes["LocaleGeneralByContentUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
genericPagesByRoot?: [{	by: ResolverInputTypes["LocaleGenericPagesByRootUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
genericPagesByContent?: [{	by: ResolverInputTypes["LocaleGenericPagesByContentUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
genericPagesByLink?: [{	by: ResolverInputTypes["LocaleGenericPagesByLinkUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
genericPagesBySeo?: [{	by: ResolverInputTypes["LocaleGenericPagesBySeoUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
headersByRoot?: [{	by: ResolverInputTypes["LocaleHeadersByRootUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
headersByLinkList?: [{	by: ResolverInputTypes["LocaleHeadersByLinkListUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
headersBySocialLinkList?: [{	by: ResolverInputTypes["LocaleHeadersBySocialLinkListUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
headersByTopLevelLinkItems?: [{	by: ResolverInputTypes["LocaleHeadersByTopLevelLinkItemsUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
footersByRoot?: [{	by: ResolverInputTypes["LocaleFootersByRootUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
footersByNavigation?: [{	by: ResolverInputTypes["LocaleFootersByNavigationUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
caseStudiesByRoot?: [{	by: ResolverInputTypes["LocaleCaseStudiesByRootUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null},ResolverInputTypes["CaseStudyLocale"]],
caseStudiesByContent?: [{	by: ResolverInputTypes["LocaleCaseStudiesByContentUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null},ResolverInputTypes["CaseStudyLocale"]],
caseStudiesByLink?: [{	by: ResolverInputTypes["LocaleCaseStudiesByLinkUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null},ResolverInputTypes["CaseStudyLocale"]],
caseStudiesBySeo?: [{	by: ResolverInputTypes["LocaleCaseStudiesBySeoUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null},ResolverInputTypes["CaseStudyLocale"]],
tagsBySlug?: [{	by: ResolverInputTypes["LocaleTagsBySlugUniqueWhere"],	filter?: ResolverInputTypes["TagLocaleWhere"] | undefined | null},ResolverInputTypes["TagLocale"]],
tagsByRoot?: [{	by: ResolverInputTypes["LocaleTagsByRootUniqueWhere"],	filter?: ResolverInputTypes["TagLocaleWhere"] | undefined | null},ResolverInputTypes["TagLocale"]],
positionTagsBySlug?: [{	by: ResolverInputTypes["LocalePositionTagsBySlugUniqueWhere"],	filter?: ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null},ResolverInputTypes["PositionTagLocale"]],
positionTagsByRoot?: [{	by: ResolverInputTypes["LocalePositionTagsByRootUniqueWhere"],	filter?: ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null},ResolverInputTypes["PositionTagLocale"]],
positionsByRoot?: [{	by: ResolverInputTypes["LocalePositionsByRootUniqueWhere"],	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null},ResolverInputTypes["PositionLocale"]],
positionsByLink?: [{	by: ResolverInputTypes["LocalePositionsByLinkUniqueWhere"],	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null},ResolverInputTypes["PositionLocale"]],
positionsBySeo?: [{	by: ResolverInputTypes["LocalePositionsBySeoUniqueWhere"],	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null},ResolverInputTypes["PositionLocale"]],
positionsByContent?: [{	by: ResolverInputTypes["LocalePositionsByContentUniqueWhere"],	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null},ResolverInputTypes["PositionLocale"]],
positionCategoriesByRoot?: [{	by: ResolverInputTypes["LocalePositionCategoriesByRootUniqueWhere"],	filter?: ResolverInputTypes["PositionCategoryLocaleWhere"] | undefined | null},ResolverInputTypes["PositionCategoryLocale"]],
authorsByRoot?: [{	by: ResolverInputTypes["LocaleAuthorsByRootUniqueWhere"],	filter?: ResolverInputTypes["AuthorLocaleWhere"] | undefined | null},ResolverInputTypes["AuthorLocale"]],
blogPostsByRoot?: [{	by: ResolverInputTypes["LocaleBlogPostsByRootUniqueWhere"],	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null},ResolverInputTypes["BlogPostLocale"]],
blogPostsByContent?: [{	by: ResolverInputTypes["LocaleBlogPostsByContentUniqueWhere"],	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null},ResolverInputTypes["BlogPostLocale"]],
blogPostsBySeo?: [{	by: ResolverInputTypes["LocaleBlogPostsBySeoUniqueWhere"],	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null},ResolverInputTypes["BlogPostLocale"]],
blogPostsByLink?: [{	by: ResolverInputTypes["LocaleBlogPostsByLinkUniqueWhere"],	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null},ResolverInputTypes["BlogPostLocale"]],
blogPostCommonByRoot?: [{	by: ResolverInputTypes["LocaleBlogPostCommonByRootUniqueWhere"],	filter?: ResolverInputTypes["BlogPostCommonLocaleWhere"] | undefined | null},ResolverInputTypes["BlogPostCommonLocale"]],
paginateGeneral?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralLocaleConnection"]],
paginateGenericPages?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageLocaleConnection"]],
paginateHeaders?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderLocaleConnection"]],
paginateFooters?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterLocaleConnection"]],
paginateCaseStudies?: [{	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CaseStudyLocaleConnection"]],
paginateTags?: [{	filter?: ResolverInputTypes["TagLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TagLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TagLocaleConnection"]],
paginatePositionTags?: [{	filter?: ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionTagLocaleConnection"]],
paginatePositions?: [{	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionLocaleConnection"]],
paginatePositionCategories?: [{	filter?: ResolverInputTypes["PositionCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionCategoryLocaleConnection"]],
paginateAuthors?: [{	filter?: ResolverInputTypes["AuthorLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuthorLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["AuthorLocaleConnection"]],
paginateBlogPosts?: [{	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BlogPostLocaleConnection"]],
paginateBlogPostCommon?: [{	filter?: ResolverInputTypes["BlogPostCommonLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostCommonLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BlogPostCommonLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["LocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	code?:ResolverInputTypes["FieldMeta"],
	label?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	general?:ResolverInputTypes["FieldMeta"],
	genericPages?:ResolverInputTypes["FieldMeta"],
	headers?:ResolverInputTypes["FieldMeta"],
	footers?:ResolverInputTypes["FieldMeta"],
	caseStudies?:ResolverInputTypes["FieldMeta"],
	tags?:ResolverInputTypes["FieldMeta"],
	positionTags?:ResolverInputTypes["FieldMeta"],
	positions?:ResolverInputTypes["FieldMeta"],
	positionCategories?:ResolverInputTypes["FieldMeta"],
	authors?:ResolverInputTypes["FieldMeta"],
	blogPosts?:ResolverInputTypes["FieldMeta"],
	blogPostCommon?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["GeneralOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	seo?: ResolverInputTypes["SeoOrderBy"] | undefined | null,
	homeLinkable?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	caseStudiesLink?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	positionsLink?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	facebookUrl?: ResolverInputTypes["OrderDirection"] | undefined | null,
	twitterUrl?: ResolverInputTypes["OrderDirection"] | undefined | null,
	instagramUrl?: ResolverInputTypes["OrderDirection"] | undefined | null,
	youtubeUrl?: ResolverInputTypes["OrderDirection"] | undefined | null,
	linkedinUrl?: ResolverInputTypes["OrderDirection"] | undefined | null,
	formPrivacyPolicy?: ResolverInputTypes["OrderDirection"] | undefined | null,
	formMarketingConsent?: ResolverInputTypes["OrderDirection"] | undefined | null,
	acceptButtonLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	rejectButtonLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	content?: ResolverInputTypes["ContentOrderBy"] | undefined | null
};
	["OrderDirection"]:OrderDirection;
	["GeneralOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	unique?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["LocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	code?: ResolverInputTypes["OrderDirection"] | undefined | null,
	label?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["SeoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	description?: ResolverInputTypes["OrderDirection"] | undefined | null,
	ogTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	ogDescription?: ResolverInputTypes["OrderDirection"] | undefined | null,
	ogImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	noIndex?: ResolverInputTypes["OrderDirection"] | undefined | null,
	noFollow?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ImageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	url?: ResolverInputTypes["OrderDirection"] | undefined | null,
	width?: ResolverInputTypes["OrderDirection"] | undefined | null,
	height?: ResolverInputTypes["OrderDirection"] | undefined | null,
	size?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	alt?: ResolverInputTypes["OrderDirection"] | undefined | null,
	fileName?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["LinkableOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	url?: ResolverInputTypes["OrderDirection"] | undefined | null,
	genericPage?: ResolverInputTypes["GenericPageLocaleOrderBy"] | undefined | null,
	caseStudy?: ResolverInputTypes["CaseStudyLocaleOrderBy"] | undefined | null,
	position?: ResolverInputTypes["PositionLocaleOrderBy"] | undefined | null,
	redirect?: ResolverInputTypes["RedirectOrderBy"] | undefined | null,
	blogPost?: ResolverInputTypes["BlogPostLocaleOrderBy"] | undefined | null
};
	["GenericPageLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["GenericPageOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	content?: ResolverInputTypes["ContentOrderBy"] | undefined | null,
	link?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	seo?: ResolverInputTypes["SeoOrderBy"] | undefined | null
};
	["GenericPageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	publishedAt?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ContentOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["CaseStudyLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["CaseStudyOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	content?: ResolverInputTypes["ContentOrderBy"] | undefined | null,
	link?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	seo?: ResolverInputTypes["SeoOrderBy"] | undefined | null,
	note?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["CaseStudyOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	wideImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	narrowImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	publishedAt?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["PositionLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	link?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	root?: ResolverInputTypes["PositionOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	seo?: ResolverInputTypes["SeoOrderBy"] | undefined | null,
	content?: ResolverInputTypes["ContentOrderBy"] | undefined | null,
	summary?: ResolverInputTypes["OrderDirection"] | undefined | null,
	salaryRange?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["PositionOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	tags?: ResolverInputTypes["PositionTagListOrderBy"] | undefined | null,
	orderInCategory?: ResolverInputTypes["OrderDirection"] | undefined | null,
	category?: ResolverInputTypes["PositionCategoryOrderBy"] | undefined | null,
	publishedAt?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["PositionTagListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["PositionCategoryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["RedirectOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	link?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	target?: ResolverInputTypes["LinkOrderBy"] | undefined | null
};
	["LinkOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	externalLink?: ResolverInputTypes["OrderDirection"] | undefined | null,
	internalLink?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	isTargetBlank?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["BlogPostLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["BlogPostOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	content?: ResolverInputTypes["ContentOrderBy"] | undefined | null,
	seo?: ResolverInputTypes["SeoOrderBy"] | undefined | null,
	link?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	lead?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["BlogPostOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	publishedAt?: ResolverInputTypes["OrderDirection"] | undefined | null,
	author?: ResolverInputTypes["AuthorOrderBy"] | undefined | null,
	wideImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	narrowImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["AuthorOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	name?: ResolverInputTypes["OrderDirection"] | undefined | null,
	profileUrl?: ResolverInputTypes["OrderDirection"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	avatarImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["GenericPageLocale"]: AliasType<{
	_meta?:ResolverInputTypes["GenericPageLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null},ResolverInputTypes["GenericPage"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
content?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
link?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
seo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	seo?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPage"]: AliasType<{
	_meta?:ResolverInputTypes["GenericPageMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPageLocale"]],
	publishedAt?:boolean | `@${string}`,
localesByLocale?: [{	by: ResolverInputTypes["GenericPageLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
localesByContent?: [{	by: ResolverInputTypes["GenericPageLocalesByContentUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
localesByLink?: [{	by: ResolverInputTypes["GenericPageLocalesByLinkUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
localesBySeo?: [{	by: ResolverInputTypes["GenericPageLocalesBySeoUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	publishedAt?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["LocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	general?: ResolverInputTypes["GeneralLocaleUniqueWhere"] | undefined | null,
	genericPages?: ResolverInputTypes["GenericPageLocaleUniqueWhere"] | undefined | null,
	headers?: ResolverInputTypes["HeaderLocaleUniqueWhere"] | undefined | null,
	footers?: ResolverInputTypes["FooterLocaleUniqueWhere"] | undefined | null,
	caseStudies?: ResolverInputTypes["CaseStudyLocaleUniqueWhere"] | undefined | null,
	tags?: ResolverInputTypes["TagLocaleUniqueWhere"] | undefined | null,
	positionTags?: ResolverInputTypes["PositionTagLocaleUniqueWhere"] | undefined | null,
	positions?: ResolverInputTypes["PositionLocaleUniqueWhere"] | undefined | null,
	positionCategories?: ResolverInputTypes["PositionCategoryLocaleUniqueWhere"] | undefined | null,
	authors?: ResolverInputTypes["AuthorLocaleUniqueWhere"] | undefined | null,
	blogPosts?: ResolverInputTypes["BlogPostLocaleUniqueWhere"] | undefined | null,
	blogPostCommon?: ResolverInputTypes["BlogPostCommonLocaleUniqueWhere"] | undefined | null
};
	["GeneralLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["GeneralUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["GeneralUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	unique?: ResolverInputTypes["One"] | undefined | null,
	locales?: ResolverInputTypes["GeneralLocaleUniqueWhere"] | undefined | null
};
	["SeoUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	ogImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["ImageUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["ContentUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	blocks?: ResolverInputTypes["ContentBlockUniqueWhere"] | undefined | null
};
	["ContentBlockUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	references?: ResolverInputTypes["ContentReferenceUniqueWhere"] | undefined | null
};
	["ContentReferenceUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null,
	featuredCaseStudies?: ResolverInputTypes["CaseStudyListUniqueWhere"] | undefined | null,
	images?: ResolverInputTypes["ImageListUniqueWhere"] | undefined | null,
	otherImages?: ResolverInputTypes["ImageListUniqueWhere"] | undefined | null,
	informationList?: ResolverInputTypes["InformationListUniqueWhere"] | undefined | null,
	embed?: ResolverInputTypes["EmbedUniqueWhere"] | undefined | null,
	links?: ResolverInputTypes["LinkListUniqueWhere"] | undefined | null,
	talkLists?: ResolverInputTypes["TalkListsUniqueWhere"] | undefined | null,
	businessCardList?: ResolverInputTypes["BusinessCardListUniqueWhere"] | undefined | null,
	testimonials?: ResolverInputTypes["TestimonialListUniqueWhere"] | undefined | null,
	contactRequestOptions?: ResolverInputTypes["ContactRequestOptionListUniqueWhere"] | undefined | null,
	youtubeVideo?: ResolverInputTypes["YoutubeVideoUniqueWhere"] | undefined | null,
	positionCategories?: ResolverInputTypes["ContentReferencePositionCategoryItemUniqueWhere"] | undefined | null,
	caseStudies?: ResolverInputTypes["ContentReferenceCaseStudyItemUniqueWhere"] | undefined | null,
	tags?: ResolverInputTypes["ContentReferenceTagItemUniqueWhere"] | undefined | null,
	positions?: ResolverInputTypes["PositionListUniqueWhere"] | undefined | null,
	testimonialsFull?: ResolverInputTypes["TestimonialFullListUniqueWhere"] | undefined | null,
	blogPosts?: ResolverInputTypes["ContentReferenceBlogPostItemUniqueWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["LinkUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["CaseStudyListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["CaseStudyListItemUniqueWhere"] | undefined | null
};
	["CaseStudyListItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["ImageListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["ImageListItemUniqueWhere"] | undefined | null
};
	["ImageListItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["InformationListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["PieceOfInformationUniqueWhere"] | undefined | null
};
	["PieceOfInformationUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["EmbedUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["LinkListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["LinkListItemUniqueWhere"] | undefined | null
};
	["LinkListItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["TalkListsUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["TalkListUniqueWhere"] | undefined | null
};
	["TalkListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["TalkListItemUniqueWhere"] | undefined | null
};
	["TalkListItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["BusinessCardListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["BusinessCardListItemUniqueWhere"] | undefined | null
};
	["BusinessCardListItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	businessCard?: ResolverInputTypes["BusinessCardUniqueWhere"] | undefined | null
};
	["BusinessCardUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["TestimonialListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["TestimonialListItemUniqueWhere"] | undefined | null
};
	["TestimonialListItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	testimonial?: ResolverInputTypes["TestimonialUniqueWhere"] | undefined | null
};
	["TestimonialUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["ContactRequestOptionListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["ContactRequestOptionUniqueWhere"] | undefined | null
};
	["ContactRequestOptionUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["YoutubeVideoUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["ContentReferencePositionCategoryItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	item?: ResolverInputTypes["PositionCategoryUniqueWhere"] | undefined | null
};
	["PositionCategoryUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	positions?: ResolverInputTypes["PositionUniqueWhere"] | undefined | null,
	locales?: ResolverInputTypes["PositionCategoryLocaleUniqueWhere"] | undefined | null
};
	["PositionUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	tags?: ResolverInputTypes["PositionTagListUniqueWhere"] | undefined | null,
	locales?: ResolverInputTypes["PositionLocaleUniqueWhere"] | undefined | null
};
	["PositionTagListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["PositionTagListItemUniqueWhere"] | undefined | null
};
	["PositionTagListItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["PositionLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["PositionUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["LinkableUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	url?: string | undefined | null,
	genericPage?: ResolverInputTypes["GenericPageLocaleUniqueWhere"] | undefined | null,
	caseStudy?: ResolverInputTypes["CaseStudyLocaleUniqueWhere"] | undefined | null,
	position?: ResolverInputTypes["PositionLocaleUniqueWhere"] | undefined | null,
	redirect?: ResolverInputTypes["RedirectUniqueWhere"] | undefined | null,
	blogPost?: ResolverInputTypes["BlogPostLocaleUniqueWhere"] | undefined | null
};
	["GenericPageLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["GenericPageUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["GenericPageUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	locales?: ResolverInputTypes["GenericPageLocaleUniqueWhere"] | undefined | null
};
	["CaseStudyLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["CaseStudyUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["CaseStudyUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	locales?: ResolverInputTypes["CaseStudyLocaleUniqueWhere"] | undefined | null,
	tags?: ResolverInputTypes["CaseStudyTagUniqueWhere"] | undefined | null
};
	["CaseStudyTagUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["RedirectUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null,
	target?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["BlogPostLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["BlogPostUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["BlogPostUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	locales?: ResolverInputTypes["BlogPostLocaleUniqueWhere"] | undefined | null,
	relevantBlogPosts?: ResolverInputTypes["RelevantBlogPostListUniqueWhere"] | undefined | null
};
	["RelevantBlogPostListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	blogPost?: ResolverInputTypes["BlogPostUniqueWhere"] | undefined | null,
	relevantBlogPost?: ResolverInputTypes["BlogPostUniqueWhere"] | undefined | null
};
	["PositionCategoryLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["PositionCategoryUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["ContentReferenceCaseStudyItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	item?: ResolverInputTypes["CaseStudyUniqueWhere"] | undefined | null
};
	["ContentReferenceTagItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	item?: ResolverInputTypes["TagUniqueWhere"] | undefined | null
};
	["TagUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	locales?: ResolverInputTypes["TagLocaleUniqueWhere"] | undefined | null,
	caseStudyTags?: ResolverInputTypes["CaseStudyTagUniqueWhere"] | undefined | null
};
	["TagLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	slug?: string | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	root?: ResolverInputTypes["TagUniqueWhere"] | undefined | null
};
	["PositionListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["PositionListItemUniqueWhere"] | undefined | null
};
	["PositionListItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["TestimonialFullListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["TestimonialFullListItemUniqueWhere"] | undefined | null
};
	["TestimonialFullListItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	testimonial?: ResolverInputTypes["TestimonialFullUniqueWhere"] | undefined | null
};
	["TestimonialFullUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["ContentReferenceBlogPostItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	item?: ResolverInputTypes["BlogPostUniqueWhere"] | undefined | null
};
	["HeaderLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["HeaderUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	linkList?: ResolverInputTypes["LinkListUniqueWhere"] | undefined | null,
	socialLinkList?: ResolverInputTypes["LinkListUniqueWhere"] | undefined | null,
	topLevelLinkItems?: ResolverInputTypes["TopLevelLinkItemUniqueWhere"] | undefined | null
};
	["HeaderUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	unique?: ResolverInputTypes["One"] | undefined | null,
	locales?: ResolverInputTypes["HeaderLocaleUniqueWhere"] | undefined | null
};
	["TopLevelLinkItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	secondLevelLinkItems?: ResolverInputTypes["SecondLevelLinkItemUniqueWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["SecondLevelLinkItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null,
	thirdLevelLinkItems?: ResolverInputTypes["ThirdLevelLinkItemUniqueWhere"] | undefined | null
};
	["ThirdLevelLinkItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["FooterLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["FooterUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	navigation?: ResolverInputTypes["LinkListUniqueWhere"] | undefined | null
};
	["FooterUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	unique?: ResolverInputTypes["One"] | undefined | null,
	locales?: ResolverInputTypes["FooterLocaleUniqueWhere"] | undefined | null
};
	["PositionTagLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	slug?: string | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	root?: ResolverInputTypes["PositionTagUniqueWhere"] | undefined | null
};
	["PositionTagUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	locales?: ResolverInputTypes["PositionTagLocaleUniqueWhere"] | undefined | null
};
	["AuthorLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["AuthorUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["AuthorUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	locales?: ResolverInputTypes["AuthorLocaleUniqueWhere"] | undefined | null,
	blogPosts?: ResolverInputTypes["BlogPostUniqueWhere"] | undefined | null
};
	["BlogPostCommonLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["BlogPostCommonUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["BlogPostCommonUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	unique?: ResolverInputTypes["One"] | undefined | null,
	locales?: ResolverInputTypes["BlogPostCommonLocaleUniqueWhere"] | undefined | null
};
	["GenericPageLocalesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["GenericPageLocalesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["GenericPageLocalesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["GenericPageLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["GenericPageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PageInfo"]: AliasType<{
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["GenericPageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Content"]: AliasType<{
	_meta?:ResolverInputTypes["ContentMeta"],
	id?:boolean | `@${string}`,
blocks?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentBlock"]],
blocksByReferences?: [{	by: ResolverInputTypes["ContentBlocksByReferencesUniqueWhere"],	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null},ResolverInputTypes["ContentBlock"]],
paginateBlocks?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentBlockConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	blocks?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlock"]: AliasType<{
	_meta?:ResolverInputTypes["ContentBlockMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	json?:boolean | `@${string}`,
content?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
references?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByLink?: [{	by: ResolverInputTypes["ContentBlockReferencesByLinkUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByFeaturedCaseStudies?: [{	by: ResolverInputTypes["ContentBlockReferencesByFeaturedCaseStudiesUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByImages?: [{	by: ResolverInputTypes["ContentBlockReferencesByImagesUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByOtherImages?: [{	by: ResolverInputTypes["ContentBlockReferencesByOtherImagesUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByInformationList?: [{	by: ResolverInputTypes["ContentBlockReferencesByInformationListUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByEmbed?: [{	by: ResolverInputTypes["ContentBlockReferencesByEmbedUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByLinks?: [{	by: ResolverInputTypes["ContentBlockReferencesByLinksUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByTalkLists?: [{	by: ResolverInputTypes["ContentBlockReferencesByTalkListsUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByBusinessCardList?: [{	by: ResolverInputTypes["ContentBlockReferencesByBusinessCardListUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByTestimonials?: [{	by: ResolverInputTypes["ContentBlockReferencesByTestimonialsUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByContactRequestOptions?: [{	by: ResolverInputTypes["ContentBlockReferencesByContactRequestOptionsUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByYoutubeVideo?: [{	by: ResolverInputTypes["ContentBlockReferencesByYoutubeVideoUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByPositionCategories?: [{	by: ResolverInputTypes["ContentBlockReferencesByPositionCategoriesUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByCaseStudies?: [{	by: ResolverInputTypes["ContentBlockReferencesByCaseStudiesUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByTags?: [{	by: ResolverInputTypes["ContentBlockReferencesByTagsUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByPositions?: [{	by: ResolverInputTypes["ContentBlockReferencesByPositionsUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByTestimonialsFull?: [{	by: ResolverInputTypes["ContentBlockReferencesByTestimonialsFullUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByBlogPosts?: [{	by: ResolverInputTypes["ContentBlockReferencesByBlogPostsUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByContent?: [{	by: ResolverInputTypes["ContentBlockReferencesByContentUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
paginateReferences?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	json?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
	references?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReference"]: AliasType<{
	_meta?:ResolverInputTypes["ContentReferenceMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	primaryText?:boolean | `@${string}`,
block?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null},ResolverInputTypes["ContentBlock"]],
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
	secondaryText?:boolean | `@${string}`,
link?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
featuredCaseStudies?: [{	filter?: ResolverInputTypes["CaseStudyListWhere"] | undefined | null},ResolverInputTypes["CaseStudyList"]],
images?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null},ResolverInputTypes["ImageList"]],
otherImages?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null},ResolverInputTypes["ImageList"]],
informationList?: [{	filter?: ResolverInputTypes["InformationListWhere"] | undefined | null},ResolverInputTypes["InformationList"]],
	tertiaryText?:boolean | `@${string}`,
embed?: [{	filter?: ResolverInputTypes["EmbedWhere"] | undefined | null},ResolverInputTypes["Embed"]],
links?: [{	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null},ResolverInputTypes["LinkList"]],
talkLists?: [{	filter?: ResolverInputTypes["TalkListsWhere"] | undefined | null},ResolverInputTypes["TalkLists"]],
businessCardList?: [{	filter?: ResolverInputTypes["BusinessCardListWhere"] | undefined | null},ResolverInputTypes["BusinessCardList"]],
testimonials?: [{	filter?: ResolverInputTypes["TestimonialListWhere"] | undefined | null},ResolverInputTypes["TestimonialList"]],
	background?:boolean | `@${string}`,
otherImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
caseStudy?: [{	filter?: ResolverInputTypes["CaseStudyWhere"] | undefined | null},ResolverInputTypes["CaseStudy"]],
	alternative?:boolean | `@${string}`,
contactRequestOptions?: [{	filter?: ResolverInputTypes["ContactRequestOptionListWhere"] | undefined | null},ResolverInputTypes["ContactRequestOptionList"]],
youtubeVideo?: [{	filter?: ResolverInputTypes["YoutubeVideoWhere"] | undefined | null},ResolverInputTypes["YoutubeVideo"]],
subscribeForm?: [{	filter?: ResolverInputTypes["SubscribeFormWhere"] | undefined | null},ResolverInputTypes["SubscribeForm"]],
video?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null},ResolverInputTypes["Video"]],
otherVideo?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null},ResolverInputTypes["Video"]],
positionCategories?: [{	filter?: ResolverInputTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferencePositionCategoryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReferencePositionCategoryItem"]],
caseStudies?: [{	filter?: ResolverInputTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceCaseStudyItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReferenceCaseStudyItem"]],
tags?: [{	filter?: ResolverInputTypes["ContentReferenceTagItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceTagItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReferenceTagItem"]],
positions?: [{	filter?: ResolverInputTypes["PositionListWhere"] | undefined | null},ResolverInputTypes["PositionList"]],
testimonialsFull?: [{	filter?: ResolverInputTypes["TestimonialFullListWhere"] | undefined | null},ResolverInputTypes["TestimonialFullList"]],
blogPosts?: [{	filter?: ResolverInputTypes["ContentReferenceBlogPostItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceBlogPostItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReferenceBlogPostItem"]],
	verticalMargin?:boolean | `@${string}`,
	variant?:boolean | `@${string}`,
content?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
positionCategoriesByItem?: [{	by: ResolverInputTypes["ContentReferencePositionCategoriesByItemUniqueWhere"],	filter?: ResolverInputTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null},ResolverInputTypes["ContentReferencePositionCategoryItem"]],
caseStudiesByItem?: [{	by: ResolverInputTypes["ContentReferenceCaseStudiesByItemUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null},ResolverInputTypes["ContentReferenceCaseStudyItem"]],
tagsByItem?: [{	by: ResolverInputTypes["ContentReferenceTagsByItemUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceTagItemWhere"] | undefined | null},ResolverInputTypes["ContentReferenceTagItem"]],
blogPostsByItem?: [{	by: ResolverInputTypes["ContentReferenceBlogPostsByItemUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceBlogPostItemWhere"] | undefined | null},ResolverInputTypes["ContentReferenceBlogPostItem"]],
paginatePositionCategories?: [{	filter?: ResolverInputTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferencePositionCategoryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferencePositionCategoryItemConnection"]],
paginateCaseStudies?: [{	filter?: ResolverInputTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceCaseStudyItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceCaseStudyItemConnection"]],
paginateTags?: [{	filter?: ResolverInputTypes["ContentReferenceTagItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceTagItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceTagItemConnection"]],
paginateBlogPosts?: [{	filter?: ResolverInputTypes["ContentReferenceBlogPostItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceBlogPostItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceBlogPostItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	primaryText?:ResolverInputTypes["FieldMeta"],
	block?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
	secondaryText?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	featuredCaseStudies?:ResolverInputTypes["FieldMeta"],
	images?:ResolverInputTypes["FieldMeta"],
	otherImages?:ResolverInputTypes["FieldMeta"],
	informationList?:ResolverInputTypes["FieldMeta"],
	tertiaryText?:ResolverInputTypes["FieldMeta"],
	embed?:ResolverInputTypes["FieldMeta"],
	links?:ResolverInputTypes["FieldMeta"],
	talkLists?:ResolverInputTypes["FieldMeta"],
	businessCardList?:ResolverInputTypes["FieldMeta"],
	testimonials?:ResolverInputTypes["FieldMeta"],
	background?:ResolverInputTypes["FieldMeta"],
	otherImage?:ResolverInputTypes["FieldMeta"],
	caseStudy?:ResolverInputTypes["FieldMeta"],
	alternative?:ResolverInputTypes["FieldMeta"],
	contactRequestOptions?:ResolverInputTypes["FieldMeta"],
	youtubeVideo?:ResolverInputTypes["FieldMeta"],
	subscribeForm?:ResolverInputTypes["FieldMeta"],
	video?:ResolverInputTypes["FieldMeta"],
	otherVideo?:ResolverInputTypes["FieldMeta"],
	positionCategories?:ResolverInputTypes["FieldMeta"],
	caseStudies?:ResolverInputTypes["FieldMeta"],
	tags?:ResolverInputTypes["FieldMeta"],
	positions?:ResolverInputTypes["FieldMeta"],
	testimonialsFull?:ResolverInputTypes["FieldMeta"],
	blogPosts?:ResolverInputTypes["FieldMeta"],
	verticalMargin?:ResolverInputTypes["FieldMeta"],
	variant?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Image"]: AliasType<{
	_meta?:ResolverInputTypes["ImageMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	alt?:boolean | `@${string}`,
	fileName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ImageMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	url?:ResolverInputTypes["FieldMeta"],
	width?:ResolverInputTypes["FieldMeta"],
	height?:ResolverInputTypes["FieldMeta"],
	size?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	alt?:ResolverInputTypes["FieldMeta"],
	fileName?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Link"]: AliasType<{
	_meta?:ResolverInputTypes["LinkMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	externalLink?:boolean | `@${string}`,
internalLink?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
	type?:boolean | `@${string}`,
	isTargetBlank?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LinkMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	externalLink?:ResolverInputTypes["FieldMeta"],
	internalLink?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	isTargetBlank?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Linkable"]: AliasType<{
	_meta?:ResolverInputTypes["LinkableMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
genericPage?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
caseStudy?: [{	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null},ResolverInputTypes["CaseStudyLocale"]],
position?: [{	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null},ResolverInputTypes["PositionLocale"]],
redirect?: [{	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null},ResolverInputTypes["Redirect"]],
blogPost?: [{	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null},ResolverInputTypes["BlogPostLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkableMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	url?:ResolverInputTypes["FieldMeta"],
	genericPage?:ResolverInputTypes["FieldMeta"],
	caseStudy?:ResolverInputTypes["FieldMeta"],
	position?:ResolverInputTypes["FieldMeta"],
	redirect?:ResolverInputTypes["FieldMeta"],
	blogPost?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyLocale"]: AliasType<{
	_meta?:ResolverInputTypes["CaseStudyLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["CaseStudyWhere"] | undefined | null},ResolverInputTypes["CaseStudy"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
content?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
link?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
seo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
	note?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	seo?:ResolverInputTypes["FieldMeta"],
	note?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudy"]: AliasType<{
	_meta?:ResolverInputTypes["CaseStudyMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CaseStudyLocale"]],
tags?: [{	filter?: ResolverInputTypes["CaseStudyTagWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyTagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CaseStudyTag"]],
wideImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
narrowImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
	order?:boolean | `@${string}`,
	publishedAt?:boolean | `@${string}`,
localesByLocale?: [{	by: ResolverInputTypes["CaseStudyLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null},ResolverInputTypes["CaseStudyLocale"]],
localesByContent?: [{	by: ResolverInputTypes["CaseStudyLocalesByContentUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null},ResolverInputTypes["CaseStudyLocale"]],
localesByLink?: [{	by: ResolverInputTypes["CaseStudyLocalesByLinkUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null},ResolverInputTypes["CaseStudyLocale"]],
localesBySeo?: [{	by: ResolverInputTypes["CaseStudyLocalesBySeoUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null},ResolverInputTypes["CaseStudyLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CaseStudyLocaleConnection"]],
paginateTags?: [{	filter?: ResolverInputTypes["CaseStudyTagWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyTagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CaseStudyTagConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	tags?:ResolverInputTypes["FieldMeta"],
	wideImage?:ResolverInputTypes["FieldMeta"],
	narrowImage?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	publishedAt?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyTag"]: AliasType<{
	_meta?:ResolverInputTypes["CaseStudyTagMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
caseStudy?: [{	filter?: ResolverInputTypes["CaseStudyWhere"] | undefined | null},ResolverInputTypes["CaseStudy"]],
tag?: [{	filter?: ResolverInputTypes["TagWhere"] | undefined | null},ResolverInputTypes["Tag"]],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyTagMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	caseStudy?:ResolverInputTypes["FieldMeta"],
	tag?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Tag"]: AliasType<{
	_meta?:ResolverInputTypes["TagMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["TagLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TagLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TagLocale"]],
caseStudyTags?: [{	filter?: ResolverInputTypes["CaseStudyTagWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyTagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CaseStudyTag"]],
	order?:boolean | `@${string}`,
localesByLocale?: [{	by: ResolverInputTypes["TagLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["TagLocaleWhere"] | undefined | null},ResolverInputTypes["TagLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["TagLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TagLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TagLocaleConnection"]],
paginateCaseStudyTags?: [{	filter?: ResolverInputTypes["CaseStudyTagWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyTagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CaseStudyTagConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TagMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	caseStudyTags?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TagLocale"]: AliasType<{
	_meta?:ResolverInputTypes["TagLocaleMeta"],
	id?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["TagWhere"] | undefined | null},ResolverInputTypes["Tag"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["TagLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	slug?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TagLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	slug?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["TagOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null
};
	["TagOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["CaseStudyTagOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	caseStudy?: ResolverInputTypes["CaseStudyOrderBy"] | undefined | null,
	tag?: ResolverInputTypes["TagOrderBy"] | undefined | null
};
	["TagLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["TagLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TagLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TagLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["TagLocale"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyTagConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["CaseStudyTagEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyTagEdge"]: AliasType<{
	node?:ResolverInputTypes["CaseStudyTag"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["CaseStudyLocalesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["CaseStudyLocalesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["CaseStudyLocalesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["CaseStudyLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["CaseStudyLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["CaseStudyLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Seo"]: AliasType<{
	_meta?:ResolverInputTypes["SeoMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	ogTitle?:boolean | `@${string}`,
	ogDescription?:boolean | `@${string}`,
ogImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
	noIndex?:boolean | `@${string}`,
	noFollow?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SeoMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	description?:ResolverInputTypes["FieldMeta"],
	ogTitle?:ResolverInputTypes["FieldMeta"],
	ogDescription?:ResolverInputTypes["FieldMeta"],
	ogImage?:ResolverInputTypes["FieldMeta"],
	noIndex?:ResolverInputTypes["FieldMeta"],
	noFollow?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionLocale"]: AliasType<{
	_meta?:ResolverInputTypes["PositionLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
link?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
root?: [{	filter?: ResolverInputTypes["PositionWhere"] | undefined | null},ResolverInputTypes["Position"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
seo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
content?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
	summary?:boolean | `@${string}`,
	salaryRange?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PositionLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	seo?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
	summary?:ResolverInputTypes["FieldMeta"],
	salaryRange?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Position"]: AliasType<{
	_meta?:ResolverInputTypes["PositionMeta"],
	id?:boolean | `@${string}`,
tags?: [{	filter?: ResolverInputTypes["PositionTagListWhere"] | undefined | null},ResolverInputTypes["PositionTagList"]],
locales?: [{	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionLocale"]],
	orderInCategory?:boolean | `@${string}`,
category?: [{	filter?: ResolverInputTypes["PositionCategoryWhere"] | undefined | null},ResolverInputTypes["PositionCategory"]],
	publishedAt?:boolean | `@${string}`,
localesByLocale?: [{	by: ResolverInputTypes["PositionLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null},ResolverInputTypes["PositionLocale"]],
localesByLink?: [{	by: ResolverInputTypes["PositionLocalesByLinkUniqueWhere"],	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null},ResolverInputTypes["PositionLocale"]],
localesBySeo?: [{	by: ResolverInputTypes["PositionLocalesBySeoUniqueWhere"],	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null},ResolverInputTypes["PositionLocale"]],
localesByContent?: [{	by: ResolverInputTypes["PositionLocalesByContentUniqueWhere"],	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null},ResolverInputTypes["PositionLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	tags?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	orderInCategory?:ResolverInputTypes["FieldMeta"],
	category?:ResolverInputTypes["FieldMeta"],
	publishedAt?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagList"]: AliasType<{
	_meta?:ResolverInputTypes["PositionTagListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["PositionTagListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionTagListItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["PositionTagListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionTagListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagListItem"]: AliasType<{
	_meta?:ResolverInputTypes["PositionTagListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["PositionTagListWhere"] | undefined | null},ResolverInputTypes["PositionTagList"]],
tag?: [{	filter?: ResolverInputTypes["PositionTagWhere"] | undefined | null},ResolverInputTypes["PositionTag"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagListItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	tag?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTag"]: AliasType<{
	_meta?:ResolverInputTypes["PositionTagMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionTagLocale"]],
localesByLocale?: [{	by: ResolverInputTypes["PositionTagLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null},ResolverInputTypes["PositionTagLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionTagLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagLocale"]: AliasType<{
	_meta?:ResolverInputTypes["PositionTagLocaleMeta"],
	id?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["PositionTagWhere"] | undefined | null},ResolverInputTypes["PositionTag"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	slug?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	slug?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["PositionTagOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null
};
	["PositionTagOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["PositionTagLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["PositionTagLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["PositionTagLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["PositionTagLocale"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["PositionTagListOrderBy"] | undefined | null,
	tag?: ResolverInputTypes["PositionTagOrderBy"] | undefined | null
};
	["PositionTagListItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["PositionTagListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagListItemEdge"]: AliasType<{
	node?:ResolverInputTypes["PositionTagListItem"],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategory"]: AliasType<{
	_meta?:ResolverInputTypes["PositionCategoryMeta"],
	id?:boolean | `@${string}`,
positions?: [{	filter?: ResolverInputTypes["PositionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Position"]],
locales?: [{	filter?: ResolverInputTypes["PositionCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionCategoryLocale"]],
	order?:boolean | `@${string}`,
positionsByTags?: [{	by: ResolverInputTypes["PositionCategoryPositionsByTagsUniqueWhere"],	filter?: ResolverInputTypes["PositionWhere"] | undefined | null},ResolverInputTypes["Position"]],
positionsByLocales?: [{	by: ResolverInputTypes["PositionCategoryPositionsByLocalesUniqueWhere"],	filter?: ResolverInputTypes["PositionWhere"] | undefined | null},ResolverInputTypes["Position"]],
localesByLocale?: [{	by: ResolverInputTypes["PositionCategoryLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["PositionCategoryLocaleWhere"] | undefined | null},ResolverInputTypes["PositionCategoryLocale"]],
paginatePositions?: [{	filter?: ResolverInputTypes["PositionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionConnection"]],
paginateLocales?: [{	filter?: ResolverInputTypes["PositionCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionCategoryLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategoryMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	positions?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategoryLocale"]: AliasType<{
	_meta?:ResolverInputTypes["PositionCategoryLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["PositionCategoryWhere"] | undefined | null},ResolverInputTypes["PositionCategory"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategoryLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategoryLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["PositionCategoryOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null
};
	["PositionCategoryPositionsByTagsUniqueWhere"]: {
	tags?: ResolverInputTypes["PositionTagListUniqueWhere"] | undefined | null
};
	["PositionCategoryPositionsByLocalesUniqueWhere"]: {
	locales?: ResolverInputTypes["PositionLocaleUniqueWhere"] | undefined | null
};
	["PositionCategoryLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["PositionConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["PositionEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionEdge"]: AliasType<{
	node?:ResolverInputTypes["Position"],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategoryLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["PositionCategoryLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategoryLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["PositionCategoryLocale"],
		__typename?: boolean | `@${string}`
}>;
	["PositionLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["PositionLocalesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["PositionLocalesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["PositionLocalesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["PositionLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["PositionLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["PositionLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Redirect"]: AliasType<{
	_meta?:ResolverInputTypes["RedirectMeta"],
	id?:boolean | `@${string}`,
link?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
target?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["RedirectMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	target?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocale"]: AliasType<{
	_meta?:ResolverInputTypes["BlogPostLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["BlogPostWhere"] | undefined | null},ResolverInputTypes["BlogPost"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
content?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
seo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
link?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
	lead?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
	seo?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	lead?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPost"]: AliasType<{
	_meta?:ResolverInputTypes["BlogPostMeta"],
	id?:boolean | `@${string}`,
	publishedAt?:boolean | `@${string}`,
author?: [{	filter?: ResolverInputTypes["AuthorWhere"] | undefined | null},ResolverInputTypes["Author"]],
wideImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
narrowImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
locales?: [{	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BlogPostLocale"]],
relevantBlogPosts?: [{	filter?: ResolverInputTypes["RelevantBlogPostListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RelevantBlogPostListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["RelevantBlogPostList"]],
localesByLocale?: [{	by: ResolverInputTypes["BlogPostLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null},ResolverInputTypes["BlogPostLocale"]],
localesByContent?: [{	by: ResolverInputTypes["BlogPostLocalesByContentUniqueWhere"],	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null},ResolverInputTypes["BlogPostLocale"]],
localesBySeo?: [{	by: ResolverInputTypes["BlogPostLocalesBySeoUniqueWhere"],	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null},ResolverInputTypes["BlogPostLocale"]],
localesByLink?: [{	by: ResolverInputTypes["BlogPostLocalesByLinkUniqueWhere"],	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null},ResolverInputTypes["BlogPostLocale"]],
relevantBlogPostsByRelevantBlogPost?: [{	by: ResolverInputTypes["BlogPostRelevantBlogPostsByRelevantBlogPostUniqueWhere"],	filter?: ResolverInputTypes["RelevantBlogPostListWhere"] | undefined | null},ResolverInputTypes["RelevantBlogPostList"]],
paginateLocales?: [{	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BlogPostLocaleConnection"]],
paginateRelevantBlogPosts?: [{	filter?: ResolverInputTypes["RelevantBlogPostListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RelevantBlogPostListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["RelevantBlogPostListConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	publishedAt?:ResolverInputTypes["FieldMeta"],
	author?:ResolverInputTypes["FieldMeta"],
	wideImage?:ResolverInputTypes["FieldMeta"],
	narrowImage?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	relevantBlogPosts?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Author"]: AliasType<{
	_meta?:ResolverInputTypes["AuthorMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["AuthorLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuthorLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["AuthorLocale"]],
blogPosts?: [{	filter?: ResolverInputTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BlogPost"]],
	name?:boolean | `@${string}`,
	profileUrl?:boolean | `@${string}`,
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
avatarImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
localesByLocale?: [{	by: ResolverInputTypes["AuthorLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["AuthorLocaleWhere"] | undefined | null},ResolverInputTypes["AuthorLocale"]],
blogPostsByLocales?: [{	by: ResolverInputTypes["AuthorBlogPostsByLocalesUniqueWhere"],	filter?: ResolverInputTypes["BlogPostWhere"] | undefined | null},ResolverInputTypes["BlogPost"]],
blogPostsByRelevantBlogPosts?: [{	by: ResolverInputTypes["AuthorBlogPostsByRelevantBlogPostsUniqueWhere"],	filter?: ResolverInputTypes["BlogPostWhere"] | undefined | null},ResolverInputTypes["BlogPost"]],
paginateLocales?: [{	filter?: ResolverInputTypes["AuthorLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuthorLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["AuthorLocaleConnection"]],
paginateBlogPosts?: [{	filter?: ResolverInputTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BlogPostConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["AuthorMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	blogPosts?:ResolverInputTypes["FieldMeta"],
	name?:ResolverInputTypes["FieldMeta"],
	profileUrl?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
	avatarImage?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AuthorLocale"]: AliasType<{
	_meta?:ResolverInputTypes["AuthorLocaleMeta"],
	id?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	bio?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["AuthorWhere"] | undefined | null},ResolverInputTypes["Author"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["AuthorLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	role?:ResolverInputTypes["FieldMeta"],
	bio?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AuthorLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	role?: ResolverInputTypes["OrderDirection"] | undefined | null,
	bio?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["AuthorOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null
};
	["AuthorLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["AuthorBlogPostsByLocalesUniqueWhere"]: {
	locales?: ResolverInputTypes["BlogPostLocaleUniqueWhere"] | undefined | null
};
	["AuthorBlogPostsByRelevantBlogPostsUniqueWhere"]: {
	relevantBlogPosts?: ResolverInputTypes["RelevantBlogPostListUniqueWhere"] | undefined | null
};
	["AuthorLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["AuthorLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AuthorLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["AuthorLocale"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["BlogPostEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostEdge"]: AliasType<{
	node?:ResolverInputTypes["BlogPost"],
		__typename?: boolean | `@${string}`
}>;
	["RelevantBlogPostList"]: AliasType<{
	_meta?:ResolverInputTypes["RelevantBlogPostListMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
blogPost?: [{	filter?: ResolverInputTypes["BlogPostWhere"] | undefined | null},ResolverInputTypes["BlogPost"]],
relevantBlogPost?: [{	filter?: ResolverInputTypes["BlogPostWhere"] | undefined | null},ResolverInputTypes["BlogPost"]],
		__typename?: boolean | `@${string}`
}>;
	["RelevantBlogPostListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	blogPost?:ResolverInputTypes["FieldMeta"],
	relevantBlogPost?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["RelevantBlogPostListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	blogPost?: ResolverInputTypes["BlogPostOrderBy"] | undefined | null,
	relevantBlogPost?: ResolverInputTypes["BlogPostOrderBy"] | undefined | null
};
	["BlogPostLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["BlogPostLocalesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["BlogPostLocalesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["BlogPostLocalesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["BlogPostRelevantBlogPostsByRelevantBlogPostUniqueWhere"]: {
	relevantBlogPost?: ResolverInputTypes["BlogPostUniqueWhere"] | undefined | null
};
	["BlogPostLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["BlogPostLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["BlogPostLocale"],
		__typename?: boolean | `@${string}`
}>;
	["RelevantBlogPostListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["RelevantBlogPostListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["RelevantBlogPostListEdge"]: AliasType<{
	node?:ResolverInputTypes["RelevantBlogPostList"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyList"]: AliasType<{
	_meta?:ResolverInputTypes["CaseStudyListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["CaseStudyListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CaseStudyListItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["CaseStudyListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CaseStudyListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyListItem"]: AliasType<{
	_meta?:ResolverInputTypes["CaseStudyListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["CaseStudyListWhere"] | undefined | null},ResolverInputTypes["CaseStudyList"]],
caseStudy?: [{	filter?: ResolverInputTypes["CaseStudyWhere"] | undefined | null},ResolverInputTypes["CaseStudy"]],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyListItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	caseStudy?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["CaseStudyListOrderBy"] | undefined | null,
	caseStudy?: ResolverInputTypes["CaseStudyOrderBy"] | undefined | null
};
	["CaseStudyListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["CaseStudyListItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["CaseStudyListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyListItemEdge"]: AliasType<{
	node?:ResolverInputTypes["CaseStudyListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ImageList"]: AliasType<{
	_meta?:ResolverInputTypes["ImageListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageListItem"]],
itemsByImage?: [{	by: ResolverInputTypes["ImageListItemsByImageUniqueWhere"],	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null},ResolverInputTypes["ImageListItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItem"]: AliasType<{
	_meta?:ResolverInputTypes["ImageListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null},ResolverInputTypes["ImageList"]],
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["ImageListOrderBy"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["ImageListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ImageListItemsByImageUniqueWhere"]: {
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["ImageListItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ImageListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemEdge"]: AliasType<{
	node?:ResolverInputTypes["ImageListItem"],
		__typename?: boolean | `@${string}`
}>;
	["InformationList"]: AliasType<{
	_meta?:ResolverInputTypes["InformationListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["PieceOfInformationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PieceOfInformationOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PieceOfInformation"]],
paginateItems?: [{	filter?: ResolverInputTypes["PieceOfInformationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PieceOfInformationOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PieceOfInformationConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["InformationListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PieceOfInformation"]: AliasType<{
	_meta?:ResolverInputTypes["PieceOfInformationMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["InformationListWhere"] | undefined | null},ResolverInputTypes["InformationList"]],
		__typename?: boolean | `@${string}`
}>;
	["PieceOfInformationMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	description?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PieceOfInformationOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	description?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["InformationListOrderBy"] | undefined | null
};
	["InformationListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["PieceOfInformationConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["PieceOfInformationEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PieceOfInformationEdge"]: AliasType<{
	node?:ResolverInputTypes["PieceOfInformation"],
		__typename?: boolean | `@${string}`
}>;
	["Embed"]: AliasType<{
	_meta?:ResolverInputTypes["EmbedMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	embedId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EmbedMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	embedId?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkList"]: AliasType<{
	_meta?:ResolverInputTypes["LinkListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["LinkListItem"]],
itemsByLink?: [{	by: ResolverInputTypes["LinkListItemsByLinkUniqueWhere"],	filter?: ResolverInputTypes["LinkListItemWhere"] | undefined | null},ResolverInputTypes["LinkListItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItem"]: AliasType<{
	_meta?:ResolverInputTypes["LinkListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null},ResolverInputTypes["LinkList"]],
link?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["LinkListOrderBy"] | undefined | null,
	link?: ResolverInputTypes["LinkOrderBy"] | undefined | null
};
	["LinkListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["LinkListItemsByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["LinkListItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["LinkListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItemEdge"]: AliasType<{
	node?:ResolverInputTypes["LinkListItem"],
		__typename?: boolean | `@${string}`
}>;
	["TalkLists"]: AliasType<{
	_meta?:ResolverInputTypes["TalkListsMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["TalkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TalkList"]],
itemsByItems?: [{	by: ResolverInputTypes["TalkListsItemsByItemsUniqueWhere"],	filter?: ResolverInputTypes["TalkListWhere"] | undefined | null},ResolverInputTypes["TalkList"]],
paginateItems?: [{	filter?: ResolverInputTypes["TalkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TalkListConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TalkListsMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TalkList"]: AliasType<{
	_meta?:ResolverInputTypes["TalkListMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["TalkListsWhere"] | undefined | null},ResolverInputTypes["TalkLists"]],
items?: [{	filter?: ResolverInputTypes["TalkListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TalkListItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["TalkListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TalkListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TalkListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TalkListItem"]: AliasType<{
	_meta?:ResolverInputTypes["TalkListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["TalkListWhere"] | undefined | null},ResolverInputTypes["TalkList"]],
talk?: [{	filter?: ResolverInputTypes["TalkWhere"] | undefined | null},ResolverInputTypes["Talk"]],
		__typename?: boolean | `@${string}`
}>;
	["TalkListItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	talk?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Talk"]: AliasType<{
	_meta?:ResolverInputTypes["TalkMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
coverImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
otherImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["TalkMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	slug?:ResolverInputTypes["FieldMeta"],
	date?:ResolverInputTypes["FieldMeta"],
	note?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
	coverImage?:ResolverInputTypes["FieldMeta"],
	otherImage?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TalkListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["TalkListOrderBy"] | undefined | null,
	talk?: ResolverInputTypes["TalkOrderBy"] | undefined | null
};
	["TalkListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["TalkListsOrderBy"] | undefined | null
};
	["TalkListsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["TalkOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	slug?: ResolverInputTypes["OrderDirection"] | undefined | null,
	date?: ResolverInputTypes["OrderDirection"] | undefined | null,
	note?: ResolverInputTypes["OrderDirection"] | undefined | null,
	content?: ResolverInputTypes["OrderDirection"] | undefined | null,
	coverImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	otherImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["TalkListItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TalkListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TalkListItemEdge"]: AliasType<{
	node?:ResolverInputTypes["TalkListItem"],
		__typename?: boolean | `@${string}`
}>;
	["TalkListsItemsByItemsUniqueWhere"]: {
	items?: ResolverInputTypes["TalkListItemUniqueWhere"] | undefined | null
};
	["TalkListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TalkListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TalkListEdge"]: AliasType<{
	node?:ResolverInputTypes["TalkList"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardList"]: AliasType<{
	_meta?:ResolverInputTypes["BusinessCardListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["BusinessCardListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCardListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BusinessCardListItem"]],
itemsByBusinessCard?: [{	by: ResolverInputTypes["BusinessCardListItemsByBusinessCardUniqueWhere"],	filter?: ResolverInputTypes["BusinessCardListItemWhere"] | undefined | null},ResolverInputTypes["BusinessCardListItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["BusinessCardListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCardListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BusinessCardListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardListItem"]: AliasType<{
	_meta?:ResolverInputTypes["BusinessCardListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["BusinessCardListWhere"] | undefined | null},ResolverInputTypes["BusinessCardList"]],
businessCard?: [{	filter?: ResolverInputTypes["BusinessCardWhere"] | undefined | null},ResolverInputTypes["BusinessCard"]],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardListItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	businessCard?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCard"]: AliasType<{
	_meta?:ResolverInputTypes["BusinessCardMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
avatar?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
	phone?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	name?:ResolverInputTypes["FieldMeta"],
	role?:ResolverInputTypes["FieldMeta"],
	email?:ResolverInputTypes["FieldMeta"],
	avatar?:ResolverInputTypes["FieldMeta"],
	phone?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["BusinessCardListOrderBy"] | undefined | null,
	businessCard?: ResolverInputTypes["BusinessCardOrderBy"] | undefined | null
};
	["BusinessCardListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["BusinessCardOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	name?: ResolverInputTypes["OrderDirection"] | undefined | null,
	role?: ResolverInputTypes["OrderDirection"] | undefined | null,
	email?: ResolverInputTypes["OrderDirection"] | undefined | null,
	avatar?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	phone?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["BusinessCardListItemsByBusinessCardUniqueWhere"]: {
	businessCard?: ResolverInputTypes["BusinessCardUniqueWhere"] | undefined | null
};
	["BusinessCardListItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["BusinessCardListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardListItemEdge"]: AliasType<{
	node?:ResolverInputTypes["BusinessCardListItem"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialList"]: AliasType<{
	_meta?:ResolverInputTypes["TestimonialListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["TestimonialListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TestimonialListItem"]],
itemsByTestimonial?: [{	by: ResolverInputTypes["TestimonialListItemsByTestimonialUniqueWhere"],	filter?: ResolverInputTypes["TestimonialListItemWhere"] | undefined | null},ResolverInputTypes["TestimonialListItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["TestimonialListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TestimonialListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialListItem"]: AliasType<{
	_meta?:ResolverInputTypes["TestimonialListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["TestimonialListWhere"] | undefined | null},ResolverInputTypes["TestimonialList"]],
testimonial?: [{	filter?: ResolverInputTypes["TestimonialWhere"] | undefined | null},ResolverInputTypes["Testimonial"]],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialListItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	testimonial?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Testimonial"]: AliasType<{
	_meta?:ResolverInputTypes["TestimonialMeta"],
	id?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
avatar?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
	note?:ResolverInputTypes["FieldMeta"],
	avatar?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["TestimonialListOrderBy"] | undefined | null,
	testimonial?: ResolverInputTypes["TestimonialOrderBy"] | undefined | null
};
	["TestimonialListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["TestimonialOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	content?: ResolverInputTypes["OrderDirection"] | undefined | null,
	note?: ResolverInputTypes["OrderDirection"] | undefined | null,
	avatar?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["TestimonialListItemsByTestimonialUniqueWhere"]: {
	testimonial?: ResolverInputTypes["TestimonialUniqueWhere"] | undefined | null
};
	["TestimonialListItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TestimonialListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialListItemEdge"]: AliasType<{
	node?:ResolverInputTypes["TestimonialListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestOptionList"]: AliasType<{
	_meta?:ResolverInputTypes["ContactRequestOptionListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["ContactRequestOptionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContactRequestOptionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContactRequestOption"]],
paginateItems?: [{	filter?: ResolverInputTypes["ContactRequestOptionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContactRequestOptionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContactRequestOptionConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestOptionListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestOption"]: AliasType<{
	_meta?:ResolverInputTypes["ContactRequestOptionMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["ContactRequestOptionListWhere"] | undefined | null},ResolverInputTypes["ContactRequestOptionList"]],
	code?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
subscribeForm?: [{	filter?: ResolverInputTypes["SubscribeFormWhere"] | undefined | null},ResolverInputTypes["SubscribeForm"]],
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestOptionMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	label?:ResolverInputTypes["FieldMeta"],
	description?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	code?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	subscribeForm?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SubscribeForm"]: AliasType<{
	_meta?:ResolverInputTypes["SubscribeFormMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	datacruitJobId?:boolean | `@${string}`,
	datacruitReferenceNumber?:boolean | `@${string}`,
	lastUpdateAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscribeFormMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	name?:ResolverInputTypes["FieldMeta"],
	datacruitJobId?:ResolverInputTypes["FieldMeta"],
	datacruitReferenceNumber?:ResolverInputTypes["FieldMeta"],
	lastUpdateAt?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestOptionOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	label?: ResolverInputTypes["OrderDirection"] | undefined | null,
	description?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["ContactRequestOptionListOrderBy"] | undefined | null,
	code?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	subscribeForm?: ResolverInputTypes["SubscribeFormOrderBy"] | undefined | null
};
	["ContactRequestOptionListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["SubscribeFormOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	name?: ResolverInputTypes["OrderDirection"] | undefined | null,
	datacruitJobId?: ResolverInputTypes["OrderDirection"] | undefined | null,
	datacruitReferenceNumber?: ResolverInputTypes["OrderDirection"] | undefined | null,
	lastUpdateAt?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ContactRequestOptionConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContactRequestOptionEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestOptionEdge"]: AliasType<{
	node?:ResolverInputTypes["ContactRequestOption"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideo"]: AliasType<{
	_meta?:ResolverInputTypes["YoutubeVideoMeta"],
	id?:boolean | `@${string}`,
	videoId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	videoId?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Video"]: AliasType<{
	_meta?:ResolverInputTypes["VideoMeta"],
	id?:boolean | `@${string}`,
	src?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["VideoMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	src?:ResolverInputTypes["FieldMeta"],
	height?:ResolverInputTypes["FieldMeta"],
	width?:ResolverInputTypes["FieldMeta"],
	duration?:ResolverInputTypes["FieldMeta"],
	size?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferencePositionCategoryItem"]: AliasType<{
	_meta?:ResolverInputTypes["ContentReferencePositionCategoryItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
item?: [{	filter?: ResolverInputTypes["PositionCategoryWhere"] | undefined | null},ResolverInputTypes["PositionCategory"]],
reference?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferencePositionCategoryItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	item?:ResolverInputTypes["FieldMeta"],
	reference?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferencePositionCategoryItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	item?: ResolverInputTypes["PositionCategoryOrderBy"] | undefined | null,
	reference?: ResolverInputTypes["ContentReferenceOrderBy"] | undefined | null
};
	["ContentReferenceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	primaryText?: ResolverInputTypes["OrderDirection"] | undefined | null,
	block?: ResolverInputTypes["ContentBlockOrderBy"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	secondaryText?: ResolverInputTypes["OrderDirection"] | undefined | null,
	link?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	featuredCaseStudies?: ResolverInputTypes["CaseStudyListOrderBy"] | undefined | null,
	images?: ResolverInputTypes["ImageListOrderBy"] | undefined | null,
	otherImages?: ResolverInputTypes["ImageListOrderBy"] | undefined | null,
	informationList?: ResolverInputTypes["InformationListOrderBy"] | undefined | null,
	tertiaryText?: ResolverInputTypes["OrderDirection"] | undefined | null,
	embed?: ResolverInputTypes["EmbedOrderBy"] | undefined | null,
	links?: ResolverInputTypes["LinkListOrderBy"] | undefined | null,
	talkLists?: ResolverInputTypes["TalkListsOrderBy"] | undefined | null,
	businessCardList?: ResolverInputTypes["BusinessCardListOrderBy"] | undefined | null,
	testimonials?: ResolverInputTypes["TestimonialListOrderBy"] | undefined | null,
	background?: ResolverInputTypes["OrderDirection"] | undefined | null,
	otherImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	caseStudy?: ResolverInputTypes["CaseStudyOrderBy"] | undefined | null,
	alternative?: ResolverInputTypes["OrderDirection"] | undefined | null,
	contactRequestOptions?: ResolverInputTypes["ContactRequestOptionListOrderBy"] | undefined | null,
	youtubeVideo?: ResolverInputTypes["YoutubeVideoOrderBy"] | undefined | null,
	subscribeForm?: ResolverInputTypes["SubscribeFormOrderBy"] | undefined | null,
	video?: ResolverInputTypes["VideoOrderBy"] | undefined | null,
	otherVideo?: ResolverInputTypes["VideoOrderBy"] | undefined | null,
	positions?: ResolverInputTypes["PositionListOrderBy"] | undefined | null,
	testimonialsFull?: ResolverInputTypes["TestimonialFullListOrderBy"] | undefined | null,
	verticalMargin?: ResolverInputTypes["OrderDirection"] | undefined | null,
	variant?: ResolverInputTypes["OrderDirection"] | undefined | null,
	content?: ResolverInputTypes["ContentOrderBy"] | undefined | null
};
	["ContentBlockOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	json?: ResolverInputTypes["OrderDirection"] | undefined | null,
	content?: ResolverInputTypes["ContentOrderBy"] | undefined | null
};
	["EmbedOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	embedId?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["YoutubeVideoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	videoId?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["VideoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	src?: ResolverInputTypes["OrderDirection"] | undefined | null,
	height?: ResolverInputTypes["OrderDirection"] | undefined | null,
	width?: ResolverInputTypes["OrderDirection"] | undefined | null,
	duration?: ResolverInputTypes["OrderDirection"] | undefined | null,
	size?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["PositionListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["TestimonialFullListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ContentReferenceCaseStudyItem"]: AliasType<{
	_meta?:ResolverInputTypes["ContentReferenceCaseStudyItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
item?: [{	filter?: ResolverInputTypes["CaseStudyWhere"] | undefined | null},ResolverInputTypes["CaseStudy"]],
reference?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceCaseStudyItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	item?:ResolverInputTypes["FieldMeta"],
	reference?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceCaseStudyItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	item?: ResolverInputTypes["CaseStudyOrderBy"] | undefined | null,
	reference?: ResolverInputTypes["ContentReferenceOrderBy"] | undefined | null
};
	["ContentReferenceTagItem"]: AliasType<{
	_meta?:ResolverInputTypes["ContentReferenceTagItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
item?: [{	filter?: ResolverInputTypes["TagWhere"] | undefined | null},ResolverInputTypes["Tag"]],
reference?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceTagItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	item?:ResolverInputTypes["FieldMeta"],
	reference?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceTagItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	item?: ResolverInputTypes["TagOrderBy"] | undefined | null,
	reference?: ResolverInputTypes["ContentReferenceOrderBy"] | undefined | null
};
	["PositionList"]: AliasType<{
	_meta?:ResolverInputTypes["PositionListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["PositionListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionListItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["PositionListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionListItem"]: AliasType<{
	_meta?:ResolverInputTypes["PositionListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["PositionListWhere"] | undefined | null},ResolverInputTypes["PositionList"]],
position?: [{	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null},ResolverInputTypes["PositionLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["PositionListItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	position?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["PositionListOrderBy"] | undefined | null,
	position?: ResolverInputTypes["PositionLocaleOrderBy"] | undefined | null
};
	["PositionListItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["PositionListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionListItemEdge"]: AliasType<{
	node?:ResolverInputTypes["PositionListItem"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullList"]: AliasType<{
	_meta?:ResolverInputTypes["TestimonialFullListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["TestimonialFullListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialFullListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TestimonialFullListItem"]],
itemsByTestimonial?: [{	by: ResolverInputTypes["TestimonialFullListItemsByTestimonialUniqueWhere"],	filter?: ResolverInputTypes["TestimonialFullListItemWhere"] | undefined | null},ResolverInputTypes["TestimonialFullListItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["TestimonialFullListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialFullListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TestimonialFullListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullListItem"]: AliasType<{
	_meta?:ResolverInputTypes["TestimonialFullListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["TestimonialFullListWhere"] | undefined | null},ResolverInputTypes["TestimonialFullList"]],
testimonial?: [{	filter?: ResolverInputTypes["TestimonialFullWhere"] | undefined | null},ResolverInputTypes["TestimonialFull"]],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullListItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	testimonial?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFull"]: AliasType<{
	_meta?:ResolverInputTypes["TestimonialFullMeta"],
	id?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
avatar?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
	imageVariant?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
	name?:ResolverInputTypes["FieldMeta"],
	note?:ResolverInputTypes["FieldMeta"],
	avatar?:ResolverInputTypes["FieldMeta"],
	imageVariant?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["TestimonialFullListOrderBy"] | undefined | null,
	testimonial?: ResolverInputTypes["TestimonialFullOrderBy"] | undefined | null
};
	["TestimonialFullOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	content?: ResolverInputTypes["OrderDirection"] | undefined | null,
	name?: ResolverInputTypes["OrderDirection"] | undefined | null,
	note?: ResolverInputTypes["OrderDirection"] | undefined | null,
	avatar?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	imageVariant?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["TestimonialFullListItemsByTestimonialUniqueWhere"]: {
	testimonial?: ResolverInputTypes["TestimonialFullUniqueWhere"] | undefined | null
};
	["TestimonialFullListItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TestimonialFullListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullListItemEdge"]: AliasType<{
	node?:ResolverInputTypes["TestimonialFullListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceBlogPostItem"]: AliasType<{
	_meta?:ResolverInputTypes["ContentReferenceBlogPostItemMeta"],
	id?:boolean | `@${string}`,
	publishedAt?:boolean | `@${string}`,
item?: [{	filter?: ResolverInputTypes["BlogPostWhere"] | undefined | null},ResolverInputTypes["BlogPost"]],
reference?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceBlogPostItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	publishedAt?:ResolverInputTypes["FieldMeta"],
	item?:ResolverInputTypes["FieldMeta"],
	reference?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceBlogPostItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	publishedAt?: ResolverInputTypes["OrderDirection"] | undefined | null,
	item?: ResolverInputTypes["BlogPostOrderBy"] | undefined | null,
	reference?: ResolverInputTypes["ContentReferenceOrderBy"] | undefined | null
};
	["ContentReferencePositionCategoriesByItemUniqueWhere"]: {
	item?: ResolverInputTypes["PositionCategoryUniqueWhere"] | undefined | null
};
	["ContentReferenceCaseStudiesByItemUniqueWhere"]: {
	item?: ResolverInputTypes["CaseStudyUniqueWhere"] | undefined | null
};
	["ContentReferenceTagsByItemUniqueWhere"]: {
	item?: ResolverInputTypes["TagUniqueWhere"] | undefined | null
};
	["ContentReferenceBlogPostsByItemUniqueWhere"]: {
	item?: ResolverInputTypes["BlogPostUniqueWhere"] | undefined | null
};
	["ContentReferencePositionCategoryItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContentReferencePositionCategoryItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferencePositionCategoryItemEdge"]: AliasType<{
	node?:ResolverInputTypes["ContentReferencePositionCategoryItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceCaseStudyItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContentReferenceCaseStudyItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceCaseStudyItemEdge"]: AliasType<{
	node?:ResolverInputTypes["ContentReferenceCaseStudyItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceTagItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContentReferenceTagItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceTagItemEdge"]: AliasType<{
	node?:ResolverInputTypes["ContentReferenceTagItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceBlogPostItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContentReferenceBlogPostItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceBlogPostItemEdge"]: AliasType<{
	node?:ResolverInputTypes["ContentReferenceBlogPostItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockReferencesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByFeaturedCaseStudiesUniqueWhere"]: {
	featuredCaseStudies?: ResolverInputTypes["CaseStudyListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByImagesUniqueWhere"]: {
	images?: ResolverInputTypes["ImageListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByOtherImagesUniqueWhere"]: {
	otherImages?: ResolverInputTypes["ImageListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByInformationListUniqueWhere"]: {
	informationList?: ResolverInputTypes["InformationListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByEmbedUniqueWhere"]: {
	embed?: ResolverInputTypes["EmbedUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByLinksUniqueWhere"]: {
	links?: ResolverInputTypes["LinkListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByTalkListsUniqueWhere"]: {
	talkLists?: ResolverInputTypes["TalkListsUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByBusinessCardListUniqueWhere"]: {
	businessCardList?: ResolverInputTypes["BusinessCardListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByTestimonialsUniqueWhere"]: {
	testimonials?: ResolverInputTypes["TestimonialListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByContactRequestOptionsUniqueWhere"]: {
	contactRequestOptions?: ResolverInputTypes["ContactRequestOptionListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByYoutubeVideoUniqueWhere"]: {
	youtubeVideo?: ResolverInputTypes["YoutubeVideoUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByPositionCategoriesUniqueWhere"]: {
	positionCategories?: ResolverInputTypes["ContentReferencePositionCategoryItemUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByCaseStudiesUniqueWhere"]: {
	caseStudies?: ResolverInputTypes["ContentReferenceCaseStudyItemUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByTagsUniqueWhere"]: {
	tags?: ResolverInputTypes["ContentReferenceTagItemUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByPositionsUniqueWhere"]: {
	positions?: ResolverInputTypes["PositionListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByTestimonialsFullUniqueWhere"]: {
	testimonialsFull?: ResolverInputTypes["TestimonialFullListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByBlogPostsUniqueWhere"]: {
	blogPosts?: ResolverInputTypes["ContentReferenceBlogPostItemUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["ContentReferenceConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContentReferenceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceEdge"]: AliasType<{
	node?:ResolverInputTypes["ContentReference"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlocksByReferencesUniqueWhere"]: {
	references?: ResolverInputTypes["ContentReferenceUniqueWhere"] | undefined | null
};
	["ContentBlockConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContentBlockEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockEdge"]: AliasType<{
	node?:ResolverInputTypes["ContentBlock"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocale"]: AliasType<{
	_meta?:ResolverInputTypes["HeaderLocaleMeta"],
	id?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null},ResolverInputTypes["Header"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
linkList?: [{	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null},ResolverInputTypes["LinkList"]],
socialLinkList?: [{	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null},ResolverInputTypes["LinkList"]],
topLevelLinkItems?: [{	filter?: ResolverInputTypes["TopLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TopLevelLinkItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TopLevelLinkItem"]],
topLevelLinkItemsBySecondLevelLinkItems?: [{	by: ResolverInputTypes["HeaderLocaleTopLevelLinkItemsBySecondLevelLinkItemsUniqueWhere"],	filter?: ResolverInputTypes["TopLevelLinkItemWhere"] | undefined | null},ResolverInputTypes["TopLevelLinkItem"]],
topLevelLinkItemsByLink?: [{	by: ResolverInputTypes["HeaderLocaleTopLevelLinkItemsByLinkUniqueWhere"],	filter?: ResolverInputTypes["TopLevelLinkItemWhere"] | undefined | null},ResolverInputTypes["TopLevelLinkItem"]],
paginateTopLevelLinkItems?: [{	filter?: ResolverInputTypes["TopLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TopLevelLinkItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TopLevelLinkItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	email?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	linkList?:ResolverInputTypes["FieldMeta"],
	socialLinkList?:ResolverInputTypes["FieldMeta"],
	topLevelLinkItems?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Header"]: AliasType<{
	_meta?:ResolverInputTypes["HeaderMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HeaderLocale"]],
localesByLocale?: [{	by: ResolverInputTypes["HeaderLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
localesByLinkList?: [{	by: ResolverInputTypes["HeaderLocalesByLinkListUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
localesBySocialLinkList?: [{	by: ResolverInputTypes["HeaderLocalesBySocialLinkListUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
localesByTopLevelLinkItems?: [{	by: ResolverInputTypes["HeaderLocalesByTopLevelLinkItemsUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["HeaderMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	unique?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	email?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["HeaderOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	linkList?: ResolverInputTypes["LinkListOrderBy"] | undefined | null,
	socialLinkList?: ResolverInputTypes["LinkListOrderBy"] | undefined | null
};
	["HeaderOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	unique?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["HeaderLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["HeaderLocalesByLinkListUniqueWhere"]: {
	linkList?: ResolverInputTypes["LinkListUniqueWhere"] | undefined | null
};
	["HeaderLocalesBySocialLinkListUniqueWhere"]: {
	socialLinkList?: ResolverInputTypes["LinkListUniqueWhere"] | undefined | null
};
	["HeaderLocalesByTopLevelLinkItemsUniqueWhere"]: {
	topLevelLinkItems?: ResolverInputTypes["TopLevelLinkItemUniqueWhere"] | undefined | null
};
	["HeaderLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["HeaderLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["HeaderLocale"],
		__typename?: boolean | `@${string}`
}>;
	["TopLevelLinkItem"]: AliasType<{
	_meta?:ResolverInputTypes["TopLevelLinkItemMeta"],
	id?:boolean | `@${string}`,
	isLink?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
secondLevelLinkItems?: [{	filter?: ResolverInputTypes["SecondLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SecondLevelLinkItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SecondLevelLinkItem"]],
header?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
link?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
secondLevelLinkItemsByLink?: [{	by: ResolverInputTypes["TopLevelLinkItemSecondLevelLinkItemsByLinkUniqueWhere"],	filter?: ResolverInputTypes["SecondLevelLinkItemWhere"] | undefined | null},ResolverInputTypes["SecondLevelLinkItem"]],
secondLevelLinkItemsByThirdLevelLinkItems?: [{	by: ResolverInputTypes["TopLevelLinkItemSecondLevelLinkItemsByThirdLevelLinkItemsUniqueWhere"],	filter?: ResolverInputTypes["SecondLevelLinkItemWhere"] | undefined | null},ResolverInputTypes["SecondLevelLinkItem"]],
paginateSecondLevelLinkItems?: [{	filter?: ResolverInputTypes["SecondLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SecondLevelLinkItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SecondLevelLinkItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TopLevelLinkItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	isLink?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	secondLevelLinkItems?:ResolverInputTypes["FieldMeta"],
	header?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SecondLevelLinkItem"]: AliasType<{
	_meta?:ResolverInputTypes["SecondLevelLinkItemMeta"],
	id?:boolean | `@${string}`,
	isLink?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
topLevelLinkItem?: [{	filter?: ResolverInputTypes["TopLevelLinkItemWhere"] | undefined | null},ResolverInputTypes["TopLevelLinkItem"]],
link?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
thirdLevelLinkItems?: [{	filter?: ResolverInputTypes["ThirdLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ThirdLevelLinkItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ThirdLevelLinkItem"]],
thirdLevelLinkItemsByLink?: [{	by: ResolverInputTypes["SecondLevelLinkItemThirdLevelLinkItemsByLinkUniqueWhere"],	filter?: ResolverInputTypes["ThirdLevelLinkItemWhere"] | undefined | null},ResolverInputTypes["ThirdLevelLinkItem"]],
paginateThirdLevelLinkItems?: [{	filter?: ResolverInputTypes["ThirdLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ThirdLevelLinkItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ThirdLevelLinkItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["SecondLevelLinkItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	isLink?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	topLevelLinkItem?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	thirdLevelLinkItems?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ThirdLevelLinkItem"]: AliasType<{
	_meta?:ResolverInputTypes["ThirdLevelLinkItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
secondLevelLinkItem?: [{	filter?: ResolverInputTypes["SecondLevelLinkItemWhere"] | undefined | null},ResolverInputTypes["SecondLevelLinkItem"]],
link?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["ThirdLevelLinkItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	secondLevelLinkItem?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ThirdLevelLinkItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	secondLevelLinkItem?: ResolverInputTypes["SecondLevelLinkItemOrderBy"] | undefined | null,
	link?: ResolverInputTypes["LinkOrderBy"] | undefined | null
};
	["SecondLevelLinkItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	isLink?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	topLevelLinkItem?: ResolverInputTypes["TopLevelLinkItemOrderBy"] | undefined | null,
	link?: ResolverInputTypes["LinkOrderBy"] | undefined | null
};
	["TopLevelLinkItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	isLink?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	header?: ResolverInputTypes["HeaderLocaleOrderBy"] | undefined | null,
	link?: ResolverInputTypes["LinkOrderBy"] | undefined | null
};
	["SecondLevelLinkItemThirdLevelLinkItemsByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["ThirdLevelLinkItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ThirdLevelLinkItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ThirdLevelLinkItemEdge"]: AliasType<{
	node?:ResolverInputTypes["ThirdLevelLinkItem"],
		__typename?: boolean | `@${string}`
}>;
	["TopLevelLinkItemSecondLevelLinkItemsByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["TopLevelLinkItemSecondLevelLinkItemsByThirdLevelLinkItemsUniqueWhere"]: {
	thirdLevelLinkItems?: ResolverInputTypes["ThirdLevelLinkItemUniqueWhere"] | undefined | null
};
	["SecondLevelLinkItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["SecondLevelLinkItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SecondLevelLinkItemEdge"]: AliasType<{
	node?:ResolverInputTypes["SecondLevelLinkItem"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleTopLevelLinkItemsBySecondLevelLinkItemsUniqueWhere"]: {
	secondLevelLinkItems?: ResolverInputTypes["SecondLevelLinkItemUniqueWhere"] | undefined | null
};
	["HeaderLocaleTopLevelLinkItemsByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["TopLevelLinkItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TopLevelLinkItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TopLevelLinkItemEdge"]: AliasType<{
	node?:ResolverInputTypes["TopLevelLinkItem"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocale"]: AliasType<{
	_meta?:ResolverInputTypes["FooterLocaleMeta"],
	id?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["FooterWhere"] | undefined | null},ResolverInputTypes["Footer"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
	address?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	legal?:boolean | `@${string}`,
navigation?: [{	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null},ResolverInputTypes["LinkList"]],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	email?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	address?:ResolverInputTypes["FieldMeta"],
	phone?:ResolverInputTypes["FieldMeta"],
	legal?:ResolverInputTypes["FieldMeta"],
	navigation?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Footer"]: AliasType<{
	_meta?:ResolverInputTypes["FooterMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterLocale"]],
localesByLocale?: [{	by: ResolverInputTypes["FooterLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
localesByNavigation?: [{	by: ResolverInputTypes["FooterLocalesByNavigationUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["FooterMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	unique?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	email?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["FooterOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	address?: ResolverInputTypes["OrderDirection"] | undefined | null,
	phone?: ResolverInputTypes["OrderDirection"] | undefined | null,
	legal?: ResolverInputTypes["OrderDirection"] | undefined | null,
	navigation?: ResolverInputTypes["LinkListOrderBy"] | undefined | null
};
	["FooterOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	unique?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["FooterLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["FooterLocalesByNavigationUniqueWhere"]: {
	navigation?: ResolverInputTypes["LinkListUniqueWhere"] | undefined | null
};
	["FooterLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FooterLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["FooterLocale"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostCommonLocale"]: AliasType<{
	_meta?:ResolverInputTypes["BlogPostCommonLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["BlogPostCommonWhere"] | undefined | null},ResolverInputTypes["BlogPostCommon"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
ctaLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
	ctaTitle?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogPostCommonLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	ctaLink?:ResolverInputTypes["FieldMeta"],
	ctaTitle?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostCommon"]: AliasType<{
	_meta?:ResolverInputTypes["BlogPostCommonMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["BlogPostCommonLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostCommonLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BlogPostCommonLocale"]],
localesByLocale?: [{	by: ResolverInputTypes["BlogPostCommonLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["BlogPostCommonLocaleWhere"] | undefined | null},ResolverInputTypes["BlogPostCommonLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["BlogPostCommonLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostCommonLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BlogPostCommonLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostCommonMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	unique?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostCommonLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["BlogPostCommonOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	ctaLink?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	ctaTitle?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["BlogPostCommonOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	unique?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["BlogPostCommonLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["BlogPostCommonLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["BlogPostCommonLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostCommonLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["BlogPostCommonLocale"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleGeneralByRootUniqueWhere"]: {
	root?: ResolverInputTypes["GeneralUniqueWhere"] | undefined | null
};
	["LocaleGeneralBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["LocaleGeneralByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["LocaleGenericPagesByRootUniqueWhere"]: {
	root?: ResolverInputTypes["GenericPageUniqueWhere"] | undefined | null
};
	["LocaleGenericPagesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["LocaleGenericPagesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["LocaleGenericPagesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["LocaleHeadersByRootUniqueWhere"]: {
	root?: ResolverInputTypes["HeaderUniqueWhere"] | undefined | null
};
	["LocaleHeadersByLinkListUniqueWhere"]: {
	linkList?: ResolverInputTypes["LinkListUniqueWhere"] | undefined | null
};
	["LocaleHeadersBySocialLinkListUniqueWhere"]: {
	socialLinkList?: ResolverInputTypes["LinkListUniqueWhere"] | undefined | null
};
	["LocaleHeadersByTopLevelLinkItemsUniqueWhere"]: {
	topLevelLinkItems?: ResolverInputTypes["TopLevelLinkItemUniqueWhere"] | undefined | null
};
	["LocaleFootersByRootUniqueWhere"]: {
	root?: ResolverInputTypes["FooterUniqueWhere"] | undefined | null
};
	["LocaleFootersByNavigationUniqueWhere"]: {
	navigation?: ResolverInputTypes["LinkListUniqueWhere"] | undefined | null
};
	["LocaleCaseStudiesByRootUniqueWhere"]: {
	root?: ResolverInputTypes["CaseStudyUniqueWhere"] | undefined | null
};
	["LocaleCaseStudiesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["LocaleCaseStudiesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["LocaleCaseStudiesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["LocaleTagsBySlugUniqueWhere"]: {
	slug?: string | undefined | null
};
	["LocaleTagsByRootUniqueWhere"]: {
	root?: ResolverInputTypes["TagUniqueWhere"] | undefined | null
};
	["LocalePositionTagsBySlugUniqueWhere"]: {
	slug?: string | undefined | null
};
	["LocalePositionTagsByRootUniqueWhere"]: {
	root?: ResolverInputTypes["PositionTagUniqueWhere"] | undefined | null
};
	["LocalePositionsByRootUniqueWhere"]: {
	root?: ResolverInputTypes["PositionUniqueWhere"] | undefined | null
};
	["LocalePositionsByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["LocalePositionsBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["LocalePositionsByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["LocalePositionCategoriesByRootUniqueWhere"]: {
	root?: ResolverInputTypes["PositionCategoryUniqueWhere"] | undefined | null
};
	["LocaleAuthorsByRootUniqueWhere"]: {
	root?: ResolverInputTypes["AuthorUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsByRootUniqueWhere"]: {
	root?: ResolverInputTypes["BlogPostUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["LocaleBlogPostCommonByRootUniqueWhere"]: {
	root?: ResolverInputTypes["BlogPostCommonUniqueWhere"] | undefined | null
};
	["GeneralLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["GeneralLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["GeneralLocale"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["GeneralLocalesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["GeneralLocalesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["GeneralConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["GeneralEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralEdge"]: AliasType<{
	node?:ResolverInputTypes["General"],
		__typename?: boolean | `@${string}`
}>;
	["ImageConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ImageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageEdge"]: AliasType<{
	node?:ResolverInputTypes["Image"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["GenericPageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageEdge"]: AliasType<{
	node?:ResolverInputTypes["GenericPage"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["LinkableEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableEdge"]: AliasType<{
	node?:ResolverInputTypes["Linkable"],
		__typename?: boolean | `@${string}`
}>;
	["SeoConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["SeoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SeoEdge"]: AliasType<{
	node?:ResolverInputTypes["Seo"],
		__typename?: boolean | `@${string}`
}>;
	["ContentConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContentEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentEdge"]: AliasType<{
	node?:ResolverInputTypes["Content"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["LocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["Locale"],
		__typename?: boolean | `@${string}`
}>;
	["LinkConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["LinkEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkEdge"]: AliasType<{
	node?:ResolverInputTypes["Link"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["LinkListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListEdge"]: AliasType<{
	node?:ResolverInputTypes["LinkList"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["HeaderEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderEdge"]: AliasType<{
	node?:ResolverInputTypes["Header"],
		__typename?: boolean | `@${string}`
}>;
	["FooterConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FooterEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FooterEdge"]: AliasType<{
	node?:ResolverInputTypes["Footer"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["CaseStudyEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyEdge"]: AliasType<{
	node?:ResolverInputTypes["CaseStudy"],
		__typename?: boolean | `@${string}`
}>;
	["TagConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TagEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TagEdge"]: AliasType<{
	node?:ResolverInputTypes["Tag"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["CaseStudyListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CaseStudyListEdge"]: AliasType<{
	node?:ResolverInputTypes["CaseStudyList"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ImageListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListEdge"]: AliasType<{
	node?:ResolverInputTypes["ImageList"],
		__typename?: boolean | `@${string}`
}>;
	["InformationListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["InformationListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["InformationListEdge"]: AliasType<{
	node?:ResolverInputTypes["InformationList"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["PositionTagEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagEdge"]: AliasType<{
	node?:ResolverInputTypes["PositionTag"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["PositionTagListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionTagListEdge"]: AliasType<{
	node?:ResolverInputTypes["PositionTagList"],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategoryConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["PositionCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionCategoryEdge"]: AliasType<{
	node?:ResolverInputTypes["PositionCategory"],
		__typename?: boolean | `@${string}`
}>;
	["EmbedConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["EmbedEdge"],
		__typename?: boolean | `@${string}`
}>;
	["EmbedEdge"]: AliasType<{
	node?:ResolverInputTypes["Embed"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["RedirectEdge"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectEdge"]: AliasType<{
	node?:ResolverInputTypes["Redirect"],
		__typename?: boolean | `@${string}`
}>;
	["TalkUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	slug?: string | undefined | null
};
	["TalkConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TalkEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TalkEdge"]: AliasType<{
	node?:ResolverInputTypes["Talk"],
		__typename?: boolean | `@${string}`
}>;
	["TalkListsConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TalkListsEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TalkListsEdge"]: AliasType<{
	node?:ResolverInputTypes["TalkLists"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["BusinessCardEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardEdge"]: AliasType<{
	node?:ResolverInputTypes["BusinessCard"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["BusinessCardListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCardListEdge"]: AliasType<{
	node?:ResolverInputTypes["BusinessCardList"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TestimonialEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialEdge"]: AliasType<{
	node?:ResolverInputTypes["Testimonial"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TestimonialListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialListEdge"]: AliasType<{
	node?:ResolverInputTypes["TestimonialList"],
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestOptionListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContactRequestOptionListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestOptionListEdge"]: AliasType<{
	node?:ResolverInputTypes["ContactRequestOptionList"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["YoutubeVideoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoEdge"]: AliasType<{
	node?:ResolverInputTypes["YoutubeVideo"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoList"]: AliasType<{
	_meta?:ResolverInputTypes["YoutubeVideoListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["YoutubeVideoListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["YoutubeVideoListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["YoutubeVideoListItem"]],
itemsByYoutube?: [{	by: ResolverInputTypes["YoutubeVideoListItemsByYoutubeUniqueWhere"],	filter?: ResolverInputTypes["YoutubeVideoListItemWhere"] | undefined | null},ResolverInputTypes["YoutubeVideoListItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["YoutubeVideoListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["YoutubeVideoListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["YoutubeVideoListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoListItem"]: AliasType<{
	_meta?:ResolverInputTypes["YoutubeVideoListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["YoutubeVideoListWhere"] | undefined | null},ResolverInputTypes["YoutubeVideoList"]],
youtube?: [{	filter?: ResolverInputTypes["YoutubeVideoWhere"] | undefined | null},ResolverInputTypes["YoutubeVideo"]],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoListItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	youtube?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["YoutubeVideoListItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["YoutubeVideoListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["YoutubeVideoListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["YoutubeVideoListWhere"] | undefined | null
};
	["YoutubeVideoListItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["YoutubeVideoListWhere"] | undefined | null,
	youtube?: ResolverInputTypes["YoutubeVideoWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["YoutubeVideoListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["YoutubeVideoListItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["YoutubeVideoListItemWhere"] | undefined | null
};
	["YoutubeVideoListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["YoutubeVideoListOrderBy"] | undefined | null,
	youtube?: ResolverInputTypes["YoutubeVideoOrderBy"] | undefined | null
};
	["YoutubeVideoListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["YoutubeVideoListItemsByYoutubeUniqueWhere"]: {
	youtube?: ResolverInputTypes["YoutubeVideoUniqueWhere"] | undefined | null
};
	["YoutubeVideoListItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["YoutubeVideoListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoListItemEdge"]: AliasType<{
	node?:ResolverInputTypes["YoutubeVideoListItem"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["YoutubeVideoListItemUniqueWhere"] | undefined | null
};
	["YoutubeVideoListItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	youtube?: ResolverInputTypes["YoutubeVideoUniqueWhere"] | undefined | null
};
	["YoutubeVideoListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["YoutubeVideoListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["YoutubeVideoListEdge"]: AliasType<{
	node?:ResolverInputTypes["YoutubeVideoList"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogue"]: AliasType<{
	_meta?:ResolverInputTypes["TranslationCatalogueMeta"],
	id?:boolean | `@${string}`,
domain?: [{	filter?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null},ResolverInputTypes["TranslationDomain"]],
identifier?: [{	filter?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ResolverInputTypes["TranslationCataloguesIdentifier"]],
fallback?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
values?: [{	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationValue"]],
valuesByKey?: [{	by: ResolverInputTypes["TranslationCatalogueValuesByKeyUniqueWhere"],	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null},ResolverInputTypes["TranslationValue"]],
paginateValues?: [{	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationValueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	domain?:ResolverInputTypes["FieldMeta"],
	identifier?:ResolverInputTypes["FieldMeta"],
	fallback?:ResolverInputTypes["FieldMeta"],
	values?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomain"]: AliasType<{
	_meta?:ResolverInputTypes["TranslationDomainMeta"],
	id?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
catalogues?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
keys?: [{	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationKeyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationKey"]],
cataloguesByIdentifier?: [{	by: ResolverInputTypes["TranslationDomainCataloguesByIdentifierUniqueWhere"],	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
cataloguesByValues?: [{	by: ResolverInputTypes["TranslationDomainCataloguesByValuesUniqueWhere"],	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
keysByIdentifier?: [{	by: ResolverInputTypes["TranslationDomainKeysByIdentifierUniqueWhere"],	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null},ResolverInputTypes["TranslationKey"]],
keysByValues?: [{	by: ResolverInputTypes["TranslationDomainKeysByValuesUniqueWhere"],	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null},ResolverInputTypes["TranslationKey"]],
paginateCatalogues?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationCatalogueConnection"]],
paginateKeys?: [{	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationKeyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationKeyConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	identifier?:ResolverInputTypes["FieldMeta"],
	name?:ResolverInputTypes["FieldMeta"],
	catalogues?:ResolverInputTypes["FieldMeta"],
	keys?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	domain?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null,
	identifier?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,
	fallback?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,
	values?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null
};
	["TranslationDomainWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	identifier?: ResolverInputTypes["StringCondition"] | undefined | null,
	name?: ResolverInputTypes["StringCondition"] | undefined | null,
	catalogues?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,
	keys?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TranslationDomainWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TranslationDomainWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null
};
	["TranslationKeyWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	identifier?: ResolverInputTypes["StringCondition"] | undefined | null,
	contentType?: ResolverInputTypes["TranslationContentTypeCondition"] | undefined | null,
	note?: ResolverInputTypes["StringCondition"] | undefined | null,
	domain?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null,
	values?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TranslationKeyWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TranslationKeyWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null
};
	["TranslationContentTypeCondition"]: {
	and?: Array<ResolverInputTypes["TranslationContentTypeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["TranslationContentTypeCondition"]> | undefined | null,
	not?: ResolverInputTypes["TranslationContentTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["TranslationContentType"] | undefined | null,
	notEq?: ResolverInputTypes["TranslationContentType"] | undefined | null,
	in?: Array<ResolverInputTypes["TranslationContentType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["TranslationContentType"]> | undefined | null,
	lt?: ResolverInputTypes["TranslationContentType"] | undefined | null,
	lte?: ResolverInputTypes["TranslationContentType"] | undefined | null,
	gt?: ResolverInputTypes["TranslationContentType"] | undefined | null,
	gte?: ResolverInputTypes["TranslationContentType"] | undefined | null
};
	["TranslationContentType"]:TranslationContentType;
	["TranslationValueWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	value?: ResolverInputTypes["StringCondition"] | undefined | null,
	catalogue?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,
	key?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TranslationValueWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TranslationValueWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TranslationValueWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	name?: ResolverInputTypes["StringCondition"] | undefined | null,
	code?: ResolverInputTypes["StringCondition"] | undefined | null,
	catalogue?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null
};
	["TranslationCatalogueOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	domain?: ResolverInputTypes["TranslationDomainOrderBy"] | undefined | null,
	identifier?: ResolverInputTypes["TranslationCataloguesIdentifierOrderBy"] | undefined | null,
	fallback?: ResolverInputTypes["TranslationCatalogueOrderBy"] | undefined | null
};
	["TranslationDomainOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	identifier?: ResolverInputTypes["OrderDirection"] | undefined | null,
	name?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["TranslationCataloguesIdentifierOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	name?: ResolverInputTypes["OrderDirection"] | undefined | null,
	code?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["TranslationKey"]: AliasType<{
	_meta?:ResolverInputTypes["TranslationKeyMeta"],
	id?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
domain?: [{	filter?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null},ResolverInputTypes["TranslationDomain"]],
values?: [{	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationValue"]],
valuesByCatalogue?: [{	by: ResolverInputTypes["TranslationKeyValuesByCatalogueUniqueWhere"],	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null},ResolverInputTypes["TranslationValue"]],
paginateValues?: [{	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationValueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	identifier?:ResolverInputTypes["FieldMeta"],
	contentType?:ResolverInputTypes["FieldMeta"],
	note?:ResolverInputTypes["FieldMeta"],
	domain?:ResolverInputTypes["FieldMeta"],
	values?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValue"]: AliasType<{
	_meta?:ResolverInputTypes["TranslationValueMeta"],
	id?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
catalogue?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
key?: [{	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null},ResolverInputTypes["TranslationKey"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	value?:ResolverInputTypes["FieldMeta"],
	catalogue?:ResolverInputTypes["FieldMeta"],
	key?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	value?: ResolverInputTypes["OrderDirection"] | undefined | null,
	catalogue?: ResolverInputTypes["TranslationCatalogueOrderBy"] | undefined | null,
	key?: ResolverInputTypes["TranslationKeyOrderBy"] | undefined | null
};
	["TranslationKeyOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	identifier?: ResolverInputTypes["OrderDirection"] | undefined | null,
	contentType?: ResolverInputTypes["OrderDirection"] | undefined | null,
	note?: ResolverInputTypes["OrderDirection"] | undefined | null,
	domain?: ResolverInputTypes["TranslationDomainOrderBy"] | undefined | null
};
	["TranslationKeyValuesByCatalogueUniqueWhere"]: {
	catalogue?: ResolverInputTypes["TranslationCatalogueUniqueWhere"] | undefined | null
};
	["TranslationCatalogueUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	domain?: ResolverInputTypes["TranslationDomainUniqueWhere"] | undefined | null,
	identifier?: ResolverInputTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined | null,
	values?: ResolverInputTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationDomainUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	identifier?: string | undefined | null,
	catalogues?: ResolverInputTypes["TranslationCatalogueUniqueWhere"] | undefined | null,
	keys?: ResolverInputTypes["TranslationKeyUniqueWhere"] | undefined | null
};
	["TranslationKeyUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	domain?: ResolverInputTypes["TranslationDomainUniqueWhere"] | undefined | null,
	identifier?: string | undefined | null,
	values?: ResolverInputTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationValueUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	catalogue?: ResolverInputTypes["TranslationCatalogueUniqueWhere"] | undefined | null,
	key?: ResolverInputTypes["TranslationKeyUniqueWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	catalogue?: ResolverInputTypes["TranslationCatalogueUniqueWhere"] | undefined | null
};
	["TranslationValueConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TranslationValueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueEdge"]: AliasType<{
	node?:ResolverInputTypes["TranslationValue"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
	identifier?: ResolverInputTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined | null
};
	["TranslationDomainCataloguesByValuesUniqueWhere"]: {
	values?: ResolverInputTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationDomainKeysByIdentifierUniqueWhere"]: {
	identifier?: string | undefined | null
};
	["TranslationDomainKeysByValuesUniqueWhere"]: {
	values?: ResolverInputTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationCatalogueConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TranslationCatalogueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueEdge"]: AliasType<{
	node?:ResolverInputTypes["TranslationCatalogue"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TranslationKeyEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyEdge"]: AliasType<{
	node?:ResolverInputTypes["TranslationKey"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifier"]: AliasType<{
	_meta?:ResolverInputTypes["TranslationCataloguesIdentifierMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
catalogue?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
catalogueByDomain?: [{	by: ResolverInputTypes["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"],	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
catalogueByValues?: [{	by: ResolverInputTypes["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"],	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
paginateCatalogue?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationCatalogueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	name?:ResolverInputTypes["FieldMeta"],
	code?:ResolverInputTypes["FieldMeta"],
	catalogue?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: {
	domain?: ResolverInputTypes["TranslationDomainUniqueWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: {
	values?: ResolverInputTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationCatalogueValuesByKeyUniqueWhere"]: {
	key?: ResolverInputTypes["TranslationKeyUniqueWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TranslationCataloguesIdentifierEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierEdge"]: AliasType<{
	node?:ResolverInputTypes["TranslationCataloguesIdentifier"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TranslationDomainEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainEdge"]: AliasType<{
	node?:ResolverInputTypes["TranslationDomain"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidationResult"]: AliasType<{
	valid?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_ValidationError"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidationError"]: AliasType<{
	path?:ResolverInputTypes["_PathFragment"],
	message?:ResolverInputTypes["_ValidationMessage"],
		__typename?: boolean | `@${string}`
}>;
	["_PathFragment"]: AliasType<{
	_FieldPathFragment?:ResolverInputTypes["_FieldPathFragment"],
	_IndexPathFragment?:ResolverInputTypes["_IndexPathFragment"],
		__typename?: boolean | `@${string}`
}>;
	["_FieldPathFragment"]: AliasType<{
	field?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_IndexPathFragment"]: AliasType<{
	index?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_ValidationMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestSubmissionCreateInput"]: {
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	requestOption?: string | undefined | null,
	givenName?: string | undefined | null,
	familyName?: string | undefined | null,
	text?: string | undefined | null,
	locale?: ResolverInputTypes["ContactRequestSubmissionCreateLocaleEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	marketingConsent?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["ContactRequestSubmissionCreateLocaleEntityRelationInput"]: {
	connect?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["SubscribeFormUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	datacruitJobId?: number | undefined | null
};
	["SubscribeFormConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["SubscribeFormEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SubscribeFormEdge"]: AliasType<{
	node?:ResolverInputTypes["SubscribeForm"],
		__typename?: boolean | `@${string}`
}>;
	["SubscribeFormCreateInput"]: {
	submissions?: Array<ResolverInputTypes["SubscribeFormCreateSubmissionsEntityRelationInput"]> | undefined | null,
	name?: string | undefined | null,
	datacruitJobId?: number | undefined | null,
	datacruitReferenceNumber?: string | undefined | null,
	lastUpdateAt?: ResolverInputTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["SubscribeFormCreateSubmissionsEntityRelationInput"]: {
	create?: ResolverInputTypes["SubscribeSubmissionWithoutFormCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["SubscribeSubmissionWithoutFormCreateInput"]: {
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	email?: string | undefined | null,
	locale?: ResolverInputTypes["SubscribeSubmissionCreateLocaleEntityRelationInput"] | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	datacruitId?: string | undefined | null,
	consent?: boolean | undefined | null,
	url?: string | undefined | null,
	attachmentFileName?: string | undefined | null,
	message?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["SubscribeSubmissionCreateLocaleEntityRelationInput"]: {
	connect?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["SubscribeFormUpdateInput"]: {
	name?: string | undefined | null,
	datacruitJobId?: number | undefined | null,
	datacruitReferenceNumber?: string | undefined | null,
	lastUpdateAt?: ResolverInputTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["SubscribeSubmissionCreateInput"]: {
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	email?: string | undefined | null,
	form?: ResolverInputTypes["SubscribeSubmissionCreateFormEntityRelationInput"] | undefined | null,
	locale?: ResolverInputTypes["SubscribeSubmissionCreateLocaleEntityRelationInput"] | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	datacruitId?: string | undefined | null,
	consent?: boolean | undefined | null,
	url?: string | undefined | null,
	attachmentFileName?: string | undefined | null,
	message?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["SubscribeSubmissionCreateFormEntityRelationInput"]: {
	connect?: ResolverInputTypes["SubscribeFormUniqueWhere"] | undefined | null,
	create?: ResolverInputTypes["SubscribeFormWithoutSubmissionsCreateInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["SubscribeSubmissionConnectOrCreateFormRelationInput"] | undefined | null
};
	["SubscribeFormWithoutSubmissionsCreateInput"]: {
	name?: string | undefined | null,
	datacruitJobId?: number | undefined | null,
	datacruitReferenceNumber?: string | undefined | null,
	lastUpdateAt?: ResolverInputTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["SubscribeSubmissionConnectOrCreateFormRelationInput"]: {
	connect: ResolverInputTypes["SubscribeFormUniqueWhere"],
	create: ResolverInputTypes["SubscribeFormWithoutSubmissionsCreateInput"]
};
	["VideoUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["VideoConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["VideoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VideoEdge"]: AliasType<{
	node?:ResolverInputTypes["Video"],
		__typename?: boolean | `@${string}`
}>;
	["PositionListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["PositionListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionListEdge"]: AliasType<{
	node?:ResolverInputTypes["PositionList"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TestimonialFullEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullEdge"]: AliasType<{
	node?:ResolverInputTypes["TestimonialFull"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TestimonialFullListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TestimonialFullListEdge"]: AliasType<{
	node?:ResolverInputTypes["TestimonialFullList"],
		__typename?: boolean | `@${string}`
}>;
	["AuthorConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["AuthorEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AuthorEdge"]: AliasType<{
	node?:ResolverInputTypes["Author"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostCommonConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["BlogPostCommonEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostCommonEdge"]: AliasType<{
	node?:ResolverInputTypes["BlogPostCommon"],
		__typename?: boolean | `@${string}`
}>;
	["QueryTransaction"]: AliasType<{
	schema?:ResolverInputTypes["_Schema"],
	s3DummyQuery?:boolean | `@${string}`,
getGeneral?: [{	by: ResolverInputTypes["GeneralUniqueWhere"],	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null},ResolverInputTypes["General"]],
listGeneral?: [{	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["General"]],
paginateGeneral?: [{	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralConnection"]],
getImage?: [{	by: ResolverInputTypes["ImageUniqueWhere"],	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
listImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Image"]],
paginateImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageConnection"]],
getGenericPage?: [{	by: ResolverInputTypes["GenericPageUniqueWhere"],	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null},ResolverInputTypes["GenericPage"]],
listGenericPage?: [{	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPage"]],
paginateGenericPage?: [{	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageConnection"]],
getLinkable?: [{	by: ResolverInputTypes["LinkableUniqueWhere"],	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
listLinkable?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkableOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Linkable"]],
paginateLinkable?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkableOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkableConnection"]],
getSeo?: [{	by: ResolverInputTypes["SeoUniqueWhere"],	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
listSeo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Seo"]],
paginateSeo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SeoConnection"]],
getContent?: [{	by: ResolverInputTypes["ContentUniqueWhere"],	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
listContent?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Content"]],
paginateContent?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentConnection"]],
getContentBlock?: [{	by: ResolverInputTypes["ContentBlockUniqueWhere"],	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null},ResolverInputTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ResolverInputTypes["ContentReferenceUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
listContentReference?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceConnection"]],
getGeneralLocale?: [{	by: ResolverInputTypes["GeneralLocaleUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
listGeneralLocale?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GeneralLocale"]],
paginateGeneralLocale?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralLocaleConnection"]],
getLocale?: [{	by: ResolverInputTypes["LocaleUniqueWhere"],	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
listLocale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Locale"]],
paginateLocale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LocaleConnection"]],
getGenericPageLocale?: [{	by: ResolverInputTypes["GenericPageLocaleUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
listGenericPageLocale?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPageLocale"]],
paginateGenericPageLocale?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageLocaleConnection"]],
getLink?: [{	by: ResolverInputTypes["LinkUniqueWhere"],	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
listLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Link"]],
paginateLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkConnection"]],
getLinkList?: [{	by: ResolverInputTypes["LinkListUniqueWhere"],	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null},ResolverInputTypes["LinkList"]],
listLinkList?: [{	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["LinkList"]],
paginateLinkList?: [{	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkListConnection"]],
getLinkListItem?: [{	by: ResolverInputTypes["LinkListItemUniqueWhere"],	filter?: ResolverInputTypes["LinkListItemWhere"] | undefined | null},ResolverInputTypes["LinkListItem"]],
listLinkListItem?: [{	filter?: ResolverInputTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["LinkListItem"]],
paginateLinkListItem?: [{	filter?: ResolverInputTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkListItemConnection"]],
getHeader?: [{	by: ResolverInputTypes["HeaderUniqueWhere"],	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null},ResolverInputTypes["Header"]],
listHeader?: [{	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Header"]],
paginateHeader?: [{	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderConnection"]],
getHeaderLocale?: [{	by: ResolverInputTypes["HeaderLocaleUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
listHeaderLocale?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HeaderLocale"]],
paginateHeaderLocale?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderLocaleConnection"]],
getFooter?: [{	by: ResolverInputTypes["FooterUniqueWhere"],	filter?: ResolverInputTypes["FooterWhere"] | undefined | null},ResolverInputTypes["Footer"]],
listFooter?: [{	filter?: ResolverInputTypes["FooterWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Footer"]],
paginateFooter?: [{	filter?: ResolverInputTypes["FooterWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterConnection"]],
getFooterLocale?: [{	by: ResolverInputTypes["FooterLocaleUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
listFooterLocale?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterLocale"]],
paginateFooterLocale?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterLocaleConnection"]],
getCaseStudy?: [{	by: ResolverInputTypes["CaseStudyUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyWhere"] | undefined | null},ResolverInputTypes["CaseStudy"]],
listCaseStudy?: [{	filter?: ResolverInputTypes["CaseStudyWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CaseStudy"]],
paginateCaseStudy?: [{	filter?: ResolverInputTypes["CaseStudyWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CaseStudyConnection"]],
getCaseStudyLocale?: [{	by: ResolverInputTypes["CaseStudyLocaleUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null},ResolverInputTypes["CaseStudyLocale"]],
listCaseStudyLocale?: [{	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CaseStudyLocale"]],
paginateCaseStudyLocale?: [{	filter?: ResolverInputTypes["CaseStudyLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CaseStudyLocaleConnection"]],
getTag?: [{	by: ResolverInputTypes["TagUniqueWhere"],	filter?: ResolverInputTypes["TagWhere"] | undefined | null},ResolverInputTypes["Tag"]],
listTag?: [{	filter?: ResolverInputTypes["TagWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Tag"]],
paginateTag?: [{	filter?: ResolverInputTypes["TagWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TagConnection"]],
getTagLocale?: [{	by: ResolverInputTypes["TagLocaleUniqueWhere"],	filter?: ResolverInputTypes["TagLocaleWhere"] | undefined | null},ResolverInputTypes["TagLocale"]],
listTagLocale?: [{	filter?: ResolverInputTypes["TagLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TagLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TagLocale"]],
paginateTagLocale?: [{	filter?: ResolverInputTypes["TagLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TagLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TagLocaleConnection"]],
getCaseStudyTag?: [{	by: ResolverInputTypes["CaseStudyTagUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyTagWhere"] | undefined | null},ResolverInputTypes["CaseStudyTag"]],
listCaseStudyTag?: [{	filter?: ResolverInputTypes["CaseStudyTagWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyTagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CaseStudyTag"]],
paginateCaseStudyTag?: [{	filter?: ResolverInputTypes["CaseStudyTagWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyTagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CaseStudyTagConnection"]],
getCaseStudyList?: [{	by: ResolverInputTypes["CaseStudyListUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyListWhere"] | undefined | null},ResolverInputTypes["CaseStudyList"]],
listCaseStudyList?: [{	filter?: ResolverInputTypes["CaseStudyListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CaseStudyList"]],
paginateCaseStudyList?: [{	filter?: ResolverInputTypes["CaseStudyListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CaseStudyListConnection"]],
getCaseStudyListItem?: [{	by: ResolverInputTypes["CaseStudyListItemUniqueWhere"],	filter?: ResolverInputTypes["CaseStudyListItemWhere"] | undefined | null},ResolverInputTypes["CaseStudyListItem"]],
listCaseStudyListItem?: [{	filter?: ResolverInputTypes["CaseStudyListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CaseStudyListItem"]],
paginateCaseStudyListItem?: [{	filter?: ResolverInputTypes["CaseStudyListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CaseStudyListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CaseStudyListItemConnection"]],
getImageList?: [{	by: ResolverInputTypes["ImageListUniqueWhere"],	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null},ResolverInputTypes["ImageList"]],
listImageList?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageList"]],
paginateImageList?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageListConnection"]],
getImageListItem?: [{	by: ResolverInputTypes["ImageListItemUniqueWhere"],	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null},ResolverInputTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageListItemConnection"]],
getInformationList?: [{	by: ResolverInputTypes["InformationListUniqueWhere"],	filter?: ResolverInputTypes["InformationListWhere"] | undefined | null},ResolverInputTypes["InformationList"]],
listInformationList?: [{	filter?: ResolverInputTypes["InformationListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["InformationListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["InformationList"]],
paginateInformationList?: [{	filter?: ResolverInputTypes["InformationListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["InformationListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["InformationListConnection"]],
getPieceOfInformation?: [{	by: ResolverInputTypes["PieceOfInformationUniqueWhere"],	filter?: ResolverInputTypes["PieceOfInformationWhere"] | undefined | null},ResolverInputTypes["PieceOfInformation"]],
listPieceOfInformation?: [{	filter?: ResolverInputTypes["PieceOfInformationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PieceOfInformationOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PieceOfInformation"]],
paginatePieceOfInformation?: [{	filter?: ResolverInputTypes["PieceOfInformationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PieceOfInformationOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PieceOfInformationConnection"]],
getPositionTag?: [{	by: ResolverInputTypes["PositionTagUniqueWhere"],	filter?: ResolverInputTypes["PositionTagWhere"] | undefined | null},ResolverInputTypes["PositionTag"]],
listPositionTag?: [{	filter?: ResolverInputTypes["PositionTagWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionTag"]],
paginatePositionTag?: [{	filter?: ResolverInputTypes["PositionTagWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionTagConnection"]],
getPositionTagLocale?: [{	by: ResolverInputTypes["PositionTagLocaleUniqueWhere"],	filter?: ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null},ResolverInputTypes["PositionTagLocale"]],
listPositionTagLocale?: [{	filter?: ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionTagLocale"]],
paginatePositionTagLocale?: [{	filter?: ResolverInputTypes["PositionTagLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionTagLocaleConnection"]],
getPosition?: [{	by: ResolverInputTypes["PositionUniqueWhere"],	filter?: ResolverInputTypes["PositionWhere"] | undefined | null},ResolverInputTypes["Position"]],
listPosition?: [{	filter?: ResolverInputTypes["PositionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Position"]],
paginatePosition?: [{	filter?: ResolverInputTypes["PositionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionConnection"]],
getPositionLocale?: [{	by: ResolverInputTypes["PositionLocaleUniqueWhere"],	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null},ResolverInputTypes["PositionLocale"]],
listPositionLocale?: [{	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionLocale"]],
paginatePositionLocale?: [{	filter?: ResolverInputTypes["PositionLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionLocaleConnection"]],
getPositionTagList?: [{	by: ResolverInputTypes["PositionTagListUniqueWhere"],	filter?: ResolverInputTypes["PositionTagListWhere"] | undefined | null},ResolverInputTypes["PositionTagList"]],
listPositionTagList?: [{	filter?: ResolverInputTypes["PositionTagListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionTagList"]],
paginatePositionTagList?: [{	filter?: ResolverInputTypes["PositionTagListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionTagListConnection"]],
getPositionTagListItem?: [{	by: ResolverInputTypes["PositionTagListItemUniqueWhere"],	filter?: ResolverInputTypes["PositionTagListItemWhere"] | undefined | null},ResolverInputTypes["PositionTagListItem"]],
listPositionTagListItem?: [{	filter?: ResolverInputTypes["PositionTagListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionTagListItem"]],
paginatePositionTagListItem?: [{	filter?: ResolverInputTypes["PositionTagListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionTagListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionTagListItemConnection"]],
getPositionCategory?: [{	by: ResolverInputTypes["PositionCategoryUniqueWhere"],	filter?: ResolverInputTypes["PositionCategoryWhere"] | undefined | null},ResolverInputTypes["PositionCategory"]],
listPositionCategory?: [{	filter?: ResolverInputTypes["PositionCategoryWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionCategory"]],
paginatePositionCategory?: [{	filter?: ResolverInputTypes["PositionCategoryWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionCategoryConnection"]],
getPositionCategoryLocale?: [{	by: ResolverInputTypes["PositionCategoryLocaleUniqueWhere"],	filter?: ResolverInputTypes["PositionCategoryLocaleWhere"] | undefined | null},ResolverInputTypes["PositionCategoryLocale"]],
listPositionCategoryLocale?: [{	filter?: ResolverInputTypes["PositionCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionCategoryLocale"]],
paginatePositionCategoryLocale?: [{	filter?: ResolverInputTypes["PositionCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionCategoryLocaleConnection"]],
getEmbed?: [{	by: ResolverInputTypes["EmbedUniqueWhere"],	filter?: ResolverInputTypes["EmbedWhere"] | undefined | null},ResolverInputTypes["Embed"]],
listEmbed?: [{	filter?: ResolverInputTypes["EmbedWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["EmbedOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Embed"]],
paginateEmbed?: [{	filter?: ResolverInputTypes["EmbedWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["EmbedOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["EmbedConnection"]],
getRedirect?: [{	by: ResolverInputTypes["RedirectUniqueWhere"],	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null},ResolverInputTypes["Redirect"]],
listRedirect?: [{	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RedirectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Redirect"]],
paginateRedirect?: [{	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RedirectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["RedirectConnection"]],
getTalk?: [{	by: ResolverInputTypes["TalkUniqueWhere"],	filter?: ResolverInputTypes["TalkWhere"] | undefined | null},ResolverInputTypes["Talk"]],
listTalk?: [{	filter?: ResolverInputTypes["TalkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Talk"]],
paginateTalk?: [{	filter?: ResolverInputTypes["TalkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TalkConnection"]],
getTalkList?: [{	by: ResolverInputTypes["TalkListUniqueWhere"],	filter?: ResolverInputTypes["TalkListWhere"] | undefined | null},ResolverInputTypes["TalkList"]],
listTalkList?: [{	filter?: ResolverInputTypes["TalkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TalkList"]],
paginateTalkList?: [{	filter?: ResolverInputTypes["TalkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TalkListConnection"]],
getTalkListItem?: [{	by: ResolverInputTypes["TalkListItemUniqueWhere"],	filter?: ResolverInputTypes["TalkListItemWhere"] | undefined | null},ResolverInputTypes["TalkListItem"]],
listTalkListItem?: [{	filter?: ResolverInputTypes["TalkListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TalkListItem"]],
paginateTalkListItem?: [{	filter?: ResolverInputTypes["TalkListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TalkListItemConnection"]],
getTalkLists?: [{	by: ResolverInputTypes["TalkListsUniqueWhere"],	filter?: ResolverInputTypes["TalkListsWhere"] | undefined | null},ResolverInputTypes["TalkLists"]],
listTalkLists?: [{	filter?: ResolverInputTypes["TalkListsWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkListsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TalkLists"]],
paginateTalkLists?: [{	filter?: ResolverInputTypes["TalkListsWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TalkListsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TalkListsConnection"]],
getBusinessCard?: [{	by: ResolverInputTypes["BusinessCardUniqueWhere"],	filter?: ResolverInputTypes["BusinessCardWhere"] | undefined | null},ResolverInputTypes["BusinessCard"]],
listBusinessCard?: [{	filter?: ResolverInputTypes["BusinessCardWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCardOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BusinessCard"]],
paginateBusinessCard?: [{	filter?: ResolverInputTypes["BusinessCardWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCardOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BusinessCardConnection"]],
getBusinessCardList?: [{	by: ResolverInputTypes["BusinessCardListUniqueWhere"],	filter?: ResolverInputTypes["BusinessCardListWhere"] | undefined | null},ResolverInputTypes["BusinessCardList"]],
listBusinessCardList?: [{	filter?: ResolverInputTypes["BusinessCardListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCardListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BusinessCardList"]],
paginateBusinessCardList?: [{	filter?: ResolverInputTypes["BusinessCardListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCardListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BusinessCardListConnection"]],
getBusinessCardListItem?: [{	by: ResolverInputTypes["BusinessCardListItemUniqueWhere"],	filter?: ResolverInputTypes["BusinessCardListItemWhere"] | undefined | null},ResolverInputTypes["BusinessCardListItem"]],
listBusinessCardListItem?: [{	filter?: ResolverInputTypes["BusinessCardListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCardListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BusinessCardListItem"]],
paginateBusinessCardListItem?: [{	filter?: ResolverInputTypes["BusinessCardListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCardListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BusinessCardListItemConnection"]],
getTestimonial?: [{	by: ResolverInputTypes["TestimonialUniqueWhere"],	filter?: ResolverInputTypes["TestimonialWhere"] | undefined | null},ResolverInputTypes["Testimonial"]],
listTestimonial?: [{	filter?: ResolverInputTypes["TestimonialWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Testimonial"]],
paginateTestimonial?: [{	filter?: ResolverInputTypes["TestimonialWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TestimonialConnection"]],
getTestimonialList?: [{	by: ResolverInputTypes["TestimonialListUniqueWhere"],	filter?: ResolverInputTypes["TestimonialListWhere"] | undefined | null},ResolverInputTypes["TestimonialList"]],
listTestimonialList?: [{	filter?: ResolverInputTypes["TestimonialListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TestimonialList"]],
paginateTestimonialList?: [{	filter?: ResolverInputTypes["TestimonialListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TestimonialListConnection"]],
getTestimonialListItem?: [{	by: ResolverInputTypes["TestimonialListItemUniqueWhere"],	filter?: ResolverInputTypes["TestimonialListItemWhere"] | undefined | null},ResolverInputTypes["TestimonialListItem"]],
listTestimonialListItem?: [{	filter?: ResolverInputTypes["TestimonialListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TestimonialListItem"]],
paginateTestimonialListItem?: [{	filter?: ResolverInputTypes["TestimonialListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TestimonialListItemConnection"]],
getContactRequestOption?: [{	by: ResolverInputTypes["ContactRequestOptionUniqueWhere"],	filter?: ResolverInputTypes["ContactRequestOptionWhere"] | undefined | null},ResolverInputTypes["ContactRequestOption"]],
listContactRequestOption?: [{	filter?: ResolverInputTypes["ContactRequestOptionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContactRequestOptionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContactRequestOption"]],
paginateContactRequestOption?: [{	filter?: ResolverInputTypes["ContactRequestOptionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContactRequestOptionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContactRequestOptionConnection"]],
getContactRequestOptionList?: [{	by: ResolverInputTypes["ContactRequestOptionListUniqueWhere"],	filter?: ResolverInputTypes["ContactRequestOptionListWhere"] | undefined | null},ResolverInputTypes["ContactRequestOptionList"]],
listContactRequestOptionList?: [{	filter?: ResolverInputTypes["ContactRequestOptionListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContactRequestOptionListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContactRequestOptionList"]],
paginateContactRequestOptionList?: [{	filter?: ResolverInputTypes["ContactRequestOptionListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContactRequestOptionListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContactRequestOptionListConnection"]],
getYoutubeVideo?: [{	by: ResolverInputTypes["YoutubeVideoUniqueWhere"],	filter?: ResolverInputTypes["YoutubeVideoWhere"] | undefined | null},ResolverInputTypes["YoutubeVideo"]],
listYoutubeVideo?: [{	filter?: ResolverInputTypes["YoutubeVideoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["YoutubeVideoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["YoutubeVideo"]],
paginateYoutubeVideo?: [{	filter?: ResolverInputTypes["YoutubeVideoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["YoutubeVideoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["YoutubeVideoConnection"]],
getYoutubeVideoList?: [{	by: ResolverInputTypes["YoutubeVideoListUniqueWhere"],	filter?: ResolverInputTypes["YoutubeVideoListWhere"] | undefined | null},ResolverInputTypes["YoutubeVideoList"]],
listYoutubeVideoList?: [{	filter?: ResolverInputTypes["YoutubeVideoListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["YoutubeVideoListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["YoutubeVideoList"]],
paginateYoutubeVideoList?: [{	filter?: ResolverInputTypes["YoutubeVideoListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["YoutubeVideoListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["YoutubeVideoListConnection"]],
getYoutubeVideoListItem?: [{	by: ResolverInputTypes["YoutubeVideoListItemUniqueWhere"],	filter?: ResolverInputTypes["YoutubeVideoListItemWhere"] | undefined | null},ResolverInputTypes["YoutubeVideoListItem"]],
listYoutubeVideoListItem?: [{	filter?: ResolverInputTypes["YoutubeVideoListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["YoutubeVideoListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["YoutubeVideoListItem"]],
paginateYoutubeVideoListItem?: [{	filter?: ResolverInputTypes["YoutubeVideoListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["YoutubeVideoListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["YoutubeVideoListItemConnection"]],
getTranslationCatalogue?: [{	by: ResolverInputTypes["TranslationCatalogueUniqueWhere"],	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
listTranslationCatalogue?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
paginateTranslationCatalogue?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationCatalogueConnection"]],
getTranslationCataloguesIdentifier?: [{	by: ResolverInputTypes["TranslationCataloguesIdentifierUniqueWhere"],	filter?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ResolverInputTypes["TranslationCataloguesIdentifier"]],
listTranslationCataloguesIdentifier?: [{	filter?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationCataloguesIdentifier"]],
paginateTranslationCataloguesIdentifier?: [{	filter?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationCataloguesIdentifierConnection"]],
getTranslationDomain?: [{	by: ResolverInputTypes["TranslationDomainUniqueWhere"],	filter?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null},ResolverInputTypes["TranslationDomain"]],
listTranslationDomain?: [{	filter?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationDomainOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationDomain"]],
paginateTranslationDomain?: [{	filter?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationDomainOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationDomainConnection"]],
getTranslationKey?: [{	by: ResolverInputTypes["TranslationKeyUniqueWhere"],	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null},ResolverInputTypes["TranslationKey"]],
listTranslationKey?: [{	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationKeyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationKey"]],
paginateTranslationKey?: [{	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationKeyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationKeyConnection"]],
getTranslationValue?: [{	by: ResolverInputTypes["TranslationValueUniqueWhere"],	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null},ResolverInputTypes["TranslationValue"]],
listTranslationValue?: [{	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationValue"]],
paginateTranslationValue?: [{	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationValueConnection"]],
validateCreateContactRequestSubmission?: [{	data: ResolverInputTypes["ContactRequestSubmissionCreateInput"]},ResolverInputTypes["_ValidationResult"]],
getSubscribeForm?: [{	by: ResolverInputTypes["SubscribeFormUniqueWhere"],	filter?: ResolverInputTypes["SubscribeFormWhere"] | undefined | null},ResolverInputTypes["SubscribeForm"]],
listSubscribeForm?: [{	filter?: ResolverInputTypes["SubscribeFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SubscribeFormOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SubscribeForm"]],
paginateSubscribeForm?: [{	filter?: ResolverInputTypes["SubscribeFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SubscribeFormOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SubscribeFormConnection"]],
validateCreateSubscribeForm?: [{	data: ResolverInputTypes["SubscribeFormCreateInput"]},ResolverInputTypes["_ValidationResult"]],
validateUpdateSubscribeForm?: [{	by: ResolverInputTypes["SubscribeFormUniqueWhere"],	data: ResolverInputTypes["SubscribeFormUpdateInput"]},ResolverInputTypes["_ValidationResult"]],
validateCreateSubscribeSubmission?: [{	data: ResolverInputTypes["SubscribeSubmissionCreateInput"]},ResolverInputTypes["_ValidationResult"]],
getContentReferencePositionCategoryItem?: [{	by: ResolverInputTypes["ContentReferencePositionCategoryItemUniqueWhere"],	filter?: ResolverInputTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null},ResolverInputTypes["ContentReferencePositionCategoryItem"]],
listContentReferencePositionCategoryItem?: [{	filter?: ResolverInputTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferencePositionCategoryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReferencePositionCategoryItem"]],
paginateContentReferencePositionCategoryItem?: [{	filter?: ResolverInputTypes["ContentReferencePositionCategoryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferencePositionCategoryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferencePositionCategoryItemConnection"]],
getVideo?: [{	by: ResolverInputTypes["VideoUniqueWhere"],	filter?: ResolverInputTypes["VideoWhere"] | undefined | null},ResolverInputTypes["Video"]],
listVideo?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["VideoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Video"]],
paginateVideo?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["VideoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["VideoConnection"]],
getContentReferenceCaseStudyItem?: [{	by: ResolverInputTypes["ContentReferenceCaseStudyItemUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null},ResolverInputTypes["ContentReferenceCaseStudyItem"]],
listContentReferenceCaseStudyItem?: [{	filter?: ResolverInputTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceCaseStudyItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReferenceCaseStudyItem"]],
paginateContentReferenceCaseStudyItem?: [{	filter?: ResolverInputTypes["ContentReferenceCaseStudyItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceCaseStudyItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceCaseStudyItemConnection"]],
getContentReferenceTagItem?: [{	by: ResolverInputTypes["ContentReferenceTagItemUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceTagItemWhere"] | undefined | null},ResolverInputTypes["ContentReferenceTagItem"]],
listContentReferenceTagItem?: [{	filter?: ResolverInputTypes["ContentReferenceTagItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceTagItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReferenceTagItem"]],
paginateContentReferenceTagItem?: [{	filter?: ResolverInputTypes["ContentReferenceTagItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceTagItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceTagItemConnection"]],
getPositionList?: [{	by: ResolverInputTypes["PositionListUniqueWhere"],	filter?: ResolverInputTypes["PositionListWhere"] | undefined | null},ResolverInputTypes["PositionList"]],
listPositionList?: [{	filter?: ResolverInputTypes["PositionListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionList"]],
paginatePositionList?: [{	filter?: ResolverInputTypes["PositionListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionListConnection"]],
getPositionListItem?: [{	by: ResolverInputTypes["PositionListItemUniqueWhere"],	filter?: ResolverInputTypes["PositionListItemWhere"] | undefined | null},ResolverInputTypes["PositionListItem"]],
listPositionListItem?: [{	filter?: ResolverInputTypes["PositionListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["PositionListItem"]],
paginatePositionListItem?: [{	filter?: ResolverInputTypes["PositionListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PositionListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["PositionListItemConnection"]],
getTestimonialFull?: [{	by: ResolverInputTypes["TestimonialFullUniqueWhere"],	filter?: ResolverInputTypes["TestimonialFullWhere"] | undefined | null},ResolverInputTypes["TestimonialFull"]],
listTestimonialFull?: [{	filter?: ResolverInputTypes["TestimonialFullWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialFullOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TestimonialFull"]],
paginateTestimonialFull?: [{	filter?: ResolverInputTypes["TestimonialFullWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialFullOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TestimonialFullConnection"]],
getTestimonialFullList?: [{	by: ResolverInputTypes["TestimonialFullListUniqueWhere"],	filter?: ResolverInputTypes["TestimonialFullListWhere"] | undefined | null},ResolverInputTypes["TestimonialFullList"]],
listTestimonialFullList?: [{	filter?: ResolverInputTypes["TestimonialFullListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialFullListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TestimonialFullList"]],
paginateTestimonialFullList?: [{	filter?: ResolverInputTypes["TestimonialFullListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialFullListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TestimonialFullListConnection"]],
getTestimonialFullListItem?: [{	by: ResolverInputTypes["TestimonialFullListItemUniqueWhere"],	filter?: ResolverInputTypes["TestimonialFullListItemWhere"] | undefined | null},ResolverInputTypes["TestimonialFullListItem"]],
listTestimonialFullListItem?: [{	filter?: ResolverInputTypes["TestimonialFullListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialFullListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TestimonialFullListItem"]],
paginateTestimonialFullListItem?: [{	filter?: ResolverInputTypes["TestimonialFullListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TestimonialFullListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TestimonialFullListItemConnection"]],
getSecondLevelLinkItem?: [{	by: ResolverInputTypes["SecondLevelLinkItemUniqueWhere"],	filter?: ResolverInputTypes["SecondLevelLinkItemWhere"] | undefined | null},ResolverInputTypes["SecondLevelLinkItem"]],
listSecondLevelLinkItem?: [{	filter?: ResolverInputTypes["SecondLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SecondLevelLinkItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SecondLevelLinkItem"]],
paginateSecondLevelLinkItem?: [{	filter?: ResolverInputTypes["SecondLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SecondLevelLinkItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SecondLevelLinkItemConnection"]],
getThirdLevelLinkItem?: [{	by: ResolverInputTypes["ThirdLevelLinkItemUniqueWhere"],	filter?: ResolverInputTypes["ThirdLevelLinkItemWhere"] | undefined | null},ResolverInputTypes["ThirdLevelLinkItem"]],
listThirdLevelLinkItem?: [{	filter?: ResolverInputTypes["ThirdLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ThirdLevelLinkItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ThirdLevelLinkItem"]],
paginateThirdLevelLinkItem?: [{	filter?: ResolverInputTypes["ThirdLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ThirdLevelLinkItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ThirdLevelLinkItemConnection"]],
getTopLevelLinkItem?: [{	by: ResolverInputTypes["TopLevelLinkItemUniqueWhere"],	filter?: ResolverInputTypes["TopLevelLinkItemWhere"] | undefined | null},ResolverInputTypes["TopLevelLinkItem"]],
listTopLevelLinkItem?: [{	filter?: ResolverInputTypes["TopLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TopLevelLinkItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TopLevelLinkItem"]],
paginateTopLevelLinkItem?: [{	filter?: ResolverInputTypes["TopLevelLinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TopLevelLinkItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TopLevelLinkItemConnection"]],
getAuthor?: [{	by: ResolverInputTypes["AuthorUniqueWhere"],	filter?: ResolverInputTypes["AuthorWhere"] | undefined | null},ResolverInputTypes["Author"]],
listAuthor?: [{	filter?: ResolverInputTypes["AuthorWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuthorOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Author"]],
paginateAuthor?: [{	filter?: ResolverInputTypes["AuthorWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuthorOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["AuthorConnection"]],
getAuthorLocale?: [{	by: ResolverInputTypes["AuthorLocaleUniqueWhere"],	filter?: ResolverInputTypes["AuthorLocaleWhere"] | undefined | null},ResolverInputTypes["AuthorLocale"]],
listAuthorLocale?: [{	filter?: ResolverInputTypes["AuthorLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuthorLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["AuthorLocale"]],
paginateAuthorLocale?: [{	filter?: ResolverInputTypes["AuthorLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuthorLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["AuthorLocaleConnection"]],
getBlogPost?: [{	by: ResolverInputTypes["BlogPostUniqueWhere"],	filter?: ResolverInputTypes["BlogPostWhere"] | undefined | null},ResolverInputTypes["BlogPost"]],
listBlogPost?: [{	filter?: ResolverInputTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BlogPost"]],
paginateBlogPost?: [{	filter?: ResolverInputTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BlogPostConnection"]],
getBlogPostLocale?: [{	by: ResolverInputTypes["BlogPostLocaleUniqueWhere"],	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null},ResolverInputTypes["BlogPostLocale"]],
listBlogPostLocale?: [{	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BlogPostLocale"]],
paginateBlogPostLocale?: [{	filter?: ResolverInputTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BlogPostLocaleConnection"]],
getContentReferenceBlogPostItem?: [{	by: ResolverInputTypes["ContentReferenceBlogPostItemUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceBlogPostItemWhere"] | undefined | null},ResolverInputTypes["ContentReferenceBlogPostItem"]],
listContentReferenceBlogPostItem?: [{	filter?: ResolverInputTypes["ContentReferenceBlogPostItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceBlogPostItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReferenceBlogPostItem"]],
paginateContentReferenceBlogPostItem?: [{	filter?: ResolverInputTypes["ContentReferenceBlogPostItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceBlogPostItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceBlogPostItemConnection"]],
getRelevantBlogPostList?: [{	by: ResolverInputTypes["RelevantBlogPostListUniqueWhere"],	filter?: ResolverInputTypes["RelevantBlogPostListWhere"] | undefined | null},ResolverInputTypes["RelevantBlogPostList"]],
listRelevantBlogPostList?: [{	filter?: ResolverInputTypes["RelevantBlogPostListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RelevantBlogPostListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["RelevantBlogPostList"]],
paginateRelevantBlogPostList?: [{	filter?: ResolverInputTypes["RelevantBlogPostListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RelevantBlogPostListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["RelevantBlogPostListConnection"]],
getBlogPostCommon?: [{	by: ResolverInputTypes["BlogPostCommonUniqueWhere"],	filter?: ResolverInputTypes["BlogPostCommonWhere"] | undefined | null},ResolverInputTypes["BlogPostCommon"]],
listBlogPostCommon?: [{	filter?: ResolverInputTypes["BlogPostCommonWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostCommonOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BlogPostCommon"]],
paginateBlogPostCommon?: [{	filter?: ResolverInputTypes["BlogPostCommonWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostCommonOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BlogPostCommonConnection"]],
getBlogPostCommonLocale?: [{	by: ResolverInputTypes["BlogPostCommonLocaleUniqueWhere"],	filter?: ResolverInputTypes["BlogPostCommonLocaleWhere"] | undefined | null},ResolverInputTypes["BlogPostCommonLocale"]],
listBlogPostCommonLocale?: [{	filter?: ResolverInputTypes["BlogPostCommonLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostCommonLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BlogPostCommonLocale"]],
paginateBlogPostCommonLocale?: [{	filter?: ResolverInputTypes["BlogPostCommonLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BlogPostCommonLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BlogPostCommonLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Info"]: AliasType<{
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
generateUploadUrl?: [{	input?: ResolverInputTypes["S3GenerateSignedUploadInput"] | undefined | null},ResolverInputTypes["S3SignedUpload"]],
generateReadUrl?: [{	objectKey: string,	expiration?: number | undefined | null},ResolverInputTypes["S3SignedRead"]],
createContactRequestSubmission?: [{	data: ResolverInputTypes["ContactRequestSubmissionCreateInput"]},ResolverInputTypes["ContactRequestSubmissionCreateResult"]],
createSubscribeForm?: [{	data: ResolverInputTypes["SubscribeFormCreateInput"]},ResolverInputTypes["SubscribeFormCreateResult"]],
updateSubscribeForm?: [{	by: ResolverInputTypes["SubscribeFormUniqueWhere"],	filter?: ResolverInputTypes["SubscribeFormWhere"] | undefined | null,	data: ResolverInputTypes["SubscribeFormUpdateInput"]},ResolverInputTypes["SubscribeFormUpdateResult"]],
upsertSubscribeForm?: [{	by: ResolverInputTypes["SubscribeFormUniqueWhere"],	filter?: ResolverInputTypes["SubscribeFormWhere"] | undefined | null,	update: ResolverInputTypes["SubscribeFormUpdateInput"],	create: ResolverInputTypes["SubscribeFormCreateInput"]},ResolverInputTypes["SubscribeFormUpsertResult"]],
createSubscribeSubmission?: [{	data: ResolverInputTypes["SubscribeSubmissionCreateInput"]},ResolverInputTypes["SubscribeSubmissionCreateResult"]],
transaction?: [{	options?: ResolverInputTypes["MutationTransactionOptions"] | undefined | null},ResolverInputTypes["MutationTransaction"]],
	query?:ResolverInputTypes["Query"],
		__typename?: boolean | `@${string}`
}>;
	["S3SignedUpload"]: AliasType<{
	url?:boolean | `@${string}`,
	headers?:ResolverInputTypes["S3Header"],
	method?:boolean | `@${string}`,
	objectKey?:boolean | `@${string}`,
	bucket?:boolean | `@${string}`,
	publicUrl?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["S3Header"]: AliasType<{
	key?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["S3GenerateSignedUploadInput"]: {
	contentType?: string | undefined | null,
	/** If not provided, extension is detected from a content-type. */
	extension?: string | undefined | null,
	/** Signed URL expiration. */
	expiration?: number | undefined | null,
	/** Uploaded file size. Required when you enable ACL size limits. */
	size?: number | undefined | null,
	/** Can be used as a "directory". */
	prefix?: string | undefined | null,
	/** Suffix after generated id and before the extension. */
	suffix?: string | undefined | null,
	/** This only affects Content-disposition header. Does not affect actual object key. */
	fileName?: string | undefined | null,
	contentDisposition?: ResolverInputTypes["S33ContentDisposition"] | undefined | null,
	/** If not supported by S3 provider, an error is thrown. */
	acl?: ResolverInputTypes["S3Acl"] | undefined | null
};
	["S33ContentDisposition"]:S33ContentDisposition;
	["S3Acl"]:S3Acl;
	["S3SignedRead"]: AliasType<{
	url?:boolean | `@${string}`,
	headers?:ResolverInputTypes["S3Header"],
	method?:boolean | `@${string}`,
	objectKey?:boolean | `@${string}`,
	bucket?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ContactRequestSubmissionCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	validation?:ResolverInputTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["MutationResult"]:AliasType<{
		ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"];
		['...on ContactRequestSubmissionCreateResult']?: Omit<ResolverInputTypes["ContactRequestSubmissionCreateResult"],keyof ResolverInputTypes["MutationResult"]>;
		['...on SubscribeFormCreateResult']?: Omit<ResolverInputTypes["SubscribeFormCreateResult"],keyof ResolverInputTypes["MutationResult"]>;
		['...on SubscribeFormUpdateResult']?: Omit<ResolverInputTypes["SubscribeFormUpdateResult"],keyof ResolverInputTypes["MutationResult"]>;
		['...on SubscribeFormUpsertResult']?: Omit<ResolverInputTypes["SubscribeFormUpsertResult"],keyof ResolverInputTypes["MutationResult"]>;
		['...on SubscribeSubmissionCreateResult']?: Omit<ResolverInputTypes["SubscribeSubmissionCreateResult"],keyof ResolverInputTypes["MutationResult"]>;
		__typename?: boolean | `@${string}`
}>;
	["_MutationError"]: AliasType<{
	path?:ResolverInputTypes["_PathFragment"],
	paths?:ResolverInputTypes["_PathFragment"],
	type?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_MutationErrorType"]:_MutationErrorType;
	["SubscribeFormCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	node?:ResolverInputTypes["SubscribeForm"],
	validation?:ResolverInputTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["SubscribeFormUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	node?:ResolverInputTypes["SubscribeForm"],
	validation?:ResolverInputTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["SubscribeFormUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	node?:ResolverInputTypes["SubscribeForm"],
	validation?:ResolverInputTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["SubscribeSubmissionCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	validation?:ResolverInputTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["MutationTransaction"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	validation?:ResolverInputTypes["_ValidationResult"],
generateUploadUrl?: [{	input?: ResolverInputTypes["S3GenerateSignedUploadInput"] | undefined | null},ResolverInputTypes["S3SignedUpload"]],
generateReadUrl?: [{	objectKey: string,	expiration?: number | undefined | null},ResolverInputTypes["S3SignedRead"]],
createContactRequestSubmission?: [{	data: ResolverInputTypes["ContactRequestSubmissionCreateInput"]},ResolverInputTypes["ContactRequestSubmissionCreateResult"]],
createSubscribeForm?: [{	data: ResolverInputTypes["SubscribeFormCreateInput"]},ResolverInputTypes["SubscribeFormCreateResult"]],
updateSubscribeForm?: [{	by: ResolverInputTypes["SubscribeFormUniqueWhere"],	filter?: ResolverInputTypes["SubscribeFormWhere"] | undefined | null,	data: ResolverInputTypes["SubscribeFormUpdateInput"]},ResolverInputTypes["SubscribeFormUpdateResult"]],
upsertSubscribeForm?: [{	by: ResolverInputTypes["SubscribeFormUniqueWhere"],	filter?: ResolverInputTypes["SubscribeFormWhere"] | undefined | null,	update: ResolverInputTypes["SubscribeFormUpdateInput"],	create: ResolverInputTypes["SubscribeFormCreateInput"]},ResolverInputTypes["SubscribeFormUpsertResult"]],
createSubscribeSubmission?: [{	data: ResolverInputTypes["SubscribeSubmissionCreateInput"]},ResolverInputTypes["SubscribeSubmissionCreateResult"]],
	query?:ResolverInputTypes["Query"],
		__typename?: boolean | `@${string}`
}>;
	["MutationTransactionOptions"]: {
	deferForeignKeyConstraints?: boolean | undefined | null,
	deferUniqueConstraints?: boolean | undefined | null
}
  }

export type ModelTypes = {
    ["_Column"]: {
		name: string,
	type: string,
	enumName?: string | undefined,
	defaultValue?: ModelTypes["Json"] | undefined,
	nullable: boolean,
	rules: Array<ModelTypes["_Rule"]>,
	validators: Array<ModelTypes["_Validator"]>
};
	["_Field"]: ModelTypes["_Column"] | ModelTypes["_Relation"];
	["_Rule"]: {
		message?: ModelTypes["_RuleMessage"] | undefined,
	validator: number
};
	["_RuleMessage"]: {
		text?: string | undefined
};
	["_Validator"]: {
		operation: string,
	arguments: Array<ModelTypes["_Argument"]>
};
	["_Argument"]:ModelTypes["_ValidatorArgument"] | ModelTypes["_PathArgument"] | ModelTypes["_LiteralArgument"];
	["_ValidatorArgument"]: {
		validator: number
};
	["_PathArgument"]: {
		path: Array<string>
};
	["_LiteralArgument"]: {
		value?: ModelTypes["Json"] | undefined
};
	/** Json custom scalar type */
["Json"]:any;
	["_Relation"]: {
		name: string,
	type: string,
	side: ModelTypes["_RelationSide"],
	targetEntity: string,
	ownedBy?: string | undefined,
	inversedBy?: string | undefined,
	nullable?: boolean | undefined,
	onDelete?: ModelTypes["_OnDeleteBehaviour"] | undefined,
	orphanRemoval?: boolean | undefined,
	orderBy?: Array<ModelTypes["_OrderBy"]> | undefined,
	rules: Array<ModelTypes["_Rule"]>,
	validators: Array<ModelTypes["_Validator"]>
};
	["_RelationSide"]:_RelationSide;
	["_OnDeleteBehaviour"]:_OnDeleteBehaviour;
	["_OrderBy"]: {
		path: Array<string>,
	direction: ModelTypes["_OrderByDirection"]
};
	["_OrderByDirection"]:_OrderByDirection;
	["Query"]: {
		schema?: ModelTypes["_Schema"] | undefined,
	s3DummyQuery?: string | undefined,
	getGeneral?: ModelTypes["General"] | undefined,
	listGeneral: Array<ModelTypes["General"]>,
	paginateGeneral: ModelTypes["GeneralConnection"],
	getImage?: ModelTypes["Image"] | undefined,
	listImage: Array<ModelTypes["Image"]>,
	paginateImage: ModelTypes["ImageConnection"],
	getGenericPage?: ModelTypes["GenericPage"] | undefined,
	listGenericPage: Array<ModelTypes["GenericPage"]>,
	paginateGenericPage: ModelTypes["GenericPageConnection"],
	getLinkable?: ModelTypes["Linkable"] | undefined,
	listLinkable: Array<ModelTypes["Linkable"]>,
	paginateLinkable: ModelTypes["LinkableConnection"],
	getSeo?: ModelTypes["Seo"] | undefined,
	listSeo: Array<ModelTypes["Seo"]>,
	paginateSeo: ModelTypes["SeoConnection"],
	getContent?: ModelTypes["Content"] | undefined,
	listContent: Array<ModelTypes["Content"]>,
	paginateContent: ModelTypes["ContentConnection"],
	getContentBlock?: ModelTypes["ContentBlock"] | undefined,
	listContentBlock: Array<ModelTypes["ContentBlock"]>,
	paginateContentBlock: ModelTypes["ContentBlockConnection"],
	getContentReference?: ModelTypes["ContentReference"] | undefined,
	listContentReference: Array<ModelTypes["ContentReference"]>,
	paginateContentReference: ModelTypes["ContentReferenceConnection"],
	getGeneralLocale?: ModelTypes["GeneralLocale"] | undefined,
	listGeneralLocale: Array<ModelTypes["GeneralLocale"]>,
	paginateGeneralLocale: ModelTypes["GeneralLocaleConnection"],
	getLocale?: ModelTypes["Locale"] | undefined,
	listLocale: Array<ModelTypes["Locale"]>,
	paginateLocale: ModelTypes["LocaleConnection"],
	getGenericPageLocale?: ModelTypes["GenericPageLocale"] | undefined,
	listGenericPageLocale: Array<ModelTypes["GenericPageLocale"]>,
	paginateGenericPageLocale: ModelTypes["GenericPageLocaleConnection"],
	getLink?: ModelTypes["Link"] | undefined,
	listLink: Array<ModelTypes["Link"]>,
	paginateLink: ModelTypes["LinkConnection"],
	getLinkList?: ModelTypes["LinkList"] | undefined,
	listLinkList: Array<ModelTypes["LinkList"]>,
	paginateLinkList: ModelTypes["LinkListConnection"],
	getLinkListItem?: ModelTypes["LinkListItem"] | undefined,
	listLinkListItem: Array<ModelTypes["LinkListItem"]>,
	paginateLinkListItem: ModelTypes["LinkListItemConnection"],
	getHeader?: ModelTypes["Header"] | undefined,
	listHeader: Array<ModelTypes["Header"]>,
	paginateHeader: ModelTypes["HeaderConnection"],
	getHeaderLocale?: ModelTypes["HeaderLocale"] | undefined,
	listHeaderLocale: Array<ModelTypes["HeaderLocale"]>,
	paginateHeaderLocale: ModelTypes["HeaderLocaleConnection"],
	getFooter?: ModelTypes["Footer"] | undefined,
	listFooter: Array<ModelTypes["Footer"]>,
	paginateFooter: ModelTypes["FooterConnection"],
	getFooterLocale?: ModelTypes["FooterLocale"] | undefined,
	listFooterLocale: Array<ModelTypes["FooterLocale"]>,
	paginateFooterLocale: ModelTypes["FooterLocaleConnection"],
	getCaseStudy?: ModelTypes["CaseStudy"] | undefined,
	listCaseStudy: Array<ModelTypes["CaseStudy"]>,
	paginateCaseStudy: ModelTypes["CaseStudyConnection"],
	getCaseStudyLocale?: ModelTypes["CaseStudyLocale"] | undefined,
	listCaseStudyLocale: Array<ModelTypes["CaseStudyLocale"]>,
	paginateCaseStudyLocale: ModelTypes["CaseStudyLocaleConnection"],
	getTag?: ModelTypes["Tag"] | undefined,
	listTag: Array<ModelTypes["Tag"]>,
	paginateTag: ModelTypes["TagConnection"],
	getTagLocale?: ModelTypes["TagLocale"] | undefined,
	listTagLocale: Array<ModelTypes["TagLocale"]>,
	paginateTagLocale: ModelTypes["TagLocaleConnection"],
	getCaseStudyTag?: ModelTypes["CaseStudyTag"] | undefined,
	listCaseStudyTag: Array<ModelTypes["CaseStudyTag"]>,
	paginateCaseStudyTag: ModelTypes["CaseStudyTagConnection"],
	getCaseStudyList?: ModelTypes["CaseStudyList"] | undefined,
	listCaseStudyList: Array<ModelTypes["CaseStudyList"]>,
	paginateCaseStudyList: ModelTypes["CaseStudyListConnection"],
	getCaseStudyListItem?: ModelTypes["CaseStudyListItem"] | undefined,
	listCaseStudyListItem: Array<ModelTypes["CaseStudyListItem"]>,
	paginateCaseStudyListItem: ModelTypes["CaseStudyListItemConnection"],
	getImageList?: ModelTypes["ImageList"] | undefined,
	listImageList: Array<ModelTypes["ImageList"]>,
	paginateImageList: ModelTypes["ImageListConnection"],
	getImageListItem?: ModelTypes["ImageListItem"] | undefined,
	listImageListItem: Array<ModelTypes["ImageListItem"]>,
	paginateImageListItem: ModelTypes["ImageListItemConnection"],
	getInformationList?: ModelTypes["InformationList"] | undefined,
	listInformationList: Array<ModelTypes["InformationList"]>,
	paginateInformationList: ModelTypes["InformationListConnection"],
	getPieceOfInformation?: ModelTypes["PieceOfInformation"] | undefined,
	listPieceOfInformation: Array<ModelTypes["PieceOfInformation"]>,
	paginatePieceOfInformation: ModelTypes["PieceOfInformationConnection"],
	getPositionTag?: ModelTypes["PositionTag"] | undefined,
	listPositionTag: Array<ModelTypes["PositionTag"]>,
	paginatePositionTag: ModelTypes["PositionTagConnection"],
	getPositionTagLocale?: ModelTypes["PositionTagLocale"] | undefined,
	listPositionTagLocale: Array<ModelTypes["PositionTagLocale"]>,
	paginatePositionTagLocale: ModelTypes["PositionTagLocaleConnection"],
	getPosition?: ModelTypes["Position"] | undefined,
	listPosition: Array<ModelTypes["Position"]>,
	paginatePosition: ModelTypes["PositionConnection"],
	getPositionLocale?: ModelTypes["PositionLocale"] | undefined,
	listPositionLocale: Array<ModelTypes["PositionLocale"]>,
	paginatePositionLocale: ModelTypes["PositionLocaleConnection"],
	getPositionTagList?: ModelTypes["PositionTagList"] | undefined,
	listPositionTagList: Array<ModelTypes["PositionTagList"]>,
	paginatePositionTagList: ModelTypes["PositionTagListConnection"],
	getPositionTagListItem?: ModelTypes["PositionTagListItem"] | undefined,
	listPositionTagListItem: Array<ModelTypes["PositionTagListItem"]>,
	paginatePositionTagListItem: ModelTypes["PositionTagListItemConnection"],
	getPositionCategory?: ModelTypes["PositionCategory"] | undefined,
	listPositionCategory: Array<ModelTypes["PositionCategory"]>,
	paginatePositionCategory: ModelTypes["PositionCategoryConnection"],
	getPositionCategoryLocale?: ModelTypes["PositionCategoryLocale"] | undefined,
	listPositionCategoryLocale: Array<ModelTypes["PositionCategoryLocale"]>,
	paginatePositionCategoryLocale: ModelTypes["PositionCategoryLocaleConnection"],
	getEmbed?: ModelTypes["Embed"] | undefined,
	listEmbed: Array<ModelTypes["Embed"]>,
	paginateEmbed: ModelTypes["EmbedConnection"],
	getRedirect?: ModelTypes["Redirect"] | undefined,
	listRedirect: Array<ModelTypes["Redirect"]>,
	paginateRedirect: ModelTypes["RedirectConnection"],
	getTalk?: ModelTypes["Talk"] | undefined,
	listTalk: Array<ModelTypes["Talk"]>,
	paginateTalk: ModelTypes["TalkConnection"],
	getTalkList?: ModelTypes["TalkList"] | undefined,
	listTalkList: Array<ModelTypes["TalkList"]>,
	paginateTalkList: ModelTypes["TalkListConnection"],
	getTalkListItem?: ModelTypes["TalkListItem"] | undefined,
	listTalkListItem: Array<ModelTypes["TalkListItem"]>,
	paginateTalkListItem: ModelTypes["TalkListItemConnection"],
	getTalkLists?: ModelTypes["TalkLists"] | undefined,
	listTalkLists: Array<ModelTypes["TalkLists"]>,
	paginateTalkLists: ModelTypes["TalkListsConnection"],
	getBusinessCard?: ModelTypes["BusinessCard"] | undefined,
	listBusinessCard: Array<ModelTypes["BusinessCard"]>,
	paginateBusinessCard: ModelTypes["BusinessCardConnection"],
	getBusinessCardList?: ModelTypes["BusinessCardList"] | undefined,
	listBusinessCardList: Array<ModelTypes["BusinessCardList"]>,
	paginateBusinessCardList: ModelTypes["BusinessCardListConnection"],
	getBusinessCardListItem?: ModelTypes["BusinessCardListItem"] | undefined,
	listBusinessCardListItem: Array<ModelTypes["BusinessCardListItem"]>,
	paginateBusinessCardListItem: ModelTypes["BusinessCardListItemConnection"],
	getTestimonial?: ModelTypes["Testimonial"] | undefined,
	listTestimonial: Array<ModelTypes["Testimonial"]>,
	paginateTestimonial: ModelTypes["TestimonialConnection"],
	getTestimonialList?: ModelTypes["TestimonialList"] | undefined,
	listTestimonialList: Array<ModelTypes["TestimonialList"]>,
	paginateTestimonialList: ModelTypes["TestimonialListConnection"],
	getTestimonialListItem?: ModelTypes["TestimonialListItem"] | undefined,
	listTestimonialListItem: Array<ModelTypes["TestimonialListItem"]>,
	paginateTestimonialListItem: ModelTypes["TestimonialListItemConnection"],
	getContactRequestOption?: ModelTypes["ContactRequestOption"] | undefined,
	listContactRequestOption: Array<ModelTypes["ContactRequestOption"]>,
	paginateContactRequestOption: ModelTypes["ContactRequestOptionConnection"],
	getContactRequestOptionList?: ModelTypes["ContactRequestOptionList"] | undefined,
	listContactRequestOptionList: Array<ModelTypes["ContactRequestOptionList"]>,
	paginateContactRequestOptionList: ModelTypes["ContactRequestOptionListConnection"],
	getYoutubeVideo?: ModelTypes["YoutubeVideo"] | undefined,
	listYoutubeVideo: Array<ModelTypes["YoutubeVideo"]>,
	paginateYoutubeVideo: ModelTypes["YoutubeVideoConnection"],
	getYoutubeVideoList?: ModelTypes["YoutubeVideoList"] | undefined,
	listYoutubeVideoList: Array<ModelTypes["YoutubeVideoList"]>,
	paginateYoutubeVideoList: ModelTypes["YoutubeVideoListConnection"],
	getYoutubeVideoListItem?: ModelTypes["YoutubeVideoListItem"] | undefined,
	listYoutubeVideoListItem: Array<ModelTypes["YoutubeVideoListItem"]>,
	paginateYoutubeVideoListItem: ModelTypes["YoutubeVideoListItemConnection"],
	getTranslationCatalogue?: ModelTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<ModelTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: ModelTypes["TranslationCatalogueConnection"],
	getTranslationCataloguesIdentifier?: ModelTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<ModelTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: ModelTypes["TranslationCataloguesIdentifierConnection"],
	getTranslationDomain?: ModelTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<ModelTypes["TranslationDomain"]>,
	paginateTranslationDomain: ModelTypes["TranslationDomainConnection"],
	getTranslationKey?: ModelTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<ModelTypes["TranslationKey"]>,
	paginateTranslationKey: ModelTypes["TranslationKeyConnection"],
	getTranslationValue?: ModelTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<ModelTypes["TranslationValue"]>,
	paginateTranslationValue: ModelTypes["TranslationValueConnection"],
	validateCreateContactRequestSubmission: ModelTypes["_ValidationResult"],
	getSubscribeForm?: ModelTypes["SubscribeForm"] | undefined,
	listSubscribeForm: Array<ModelTypes["SubscribeForm"]>,
	paginateSubscribeForm: ModelTypes["SubscribeFormConnection"],
	validateCreateSubscribeForm: ModelTypes["_ValidationResult"],
	validateUpdateSubscribeForm: ModelTypes["_ValidationResult"],
	validateCreateSubscribeSubmission: ModelTypes["_ValidationResult"],
	getContentReferencePositionCategoryItem?: ModelTypes["ContentReferencePositionCategoryItem"] | undefined,
	listContentReferencePositionCategoryItem: Array<ModelTypes["ContentReferencePositionCategoryItem"]>,
	paginateContentReferencePositionCategoryItem: ModelTypes["ContentReferencePositionCategoryItemConnection"],
	getVideo?: ModelTypes["Video"] | undefined,
	listVideo: Array<ModelTypes["Video"]>,
	paginateVideo: ModelTypes["VideoConnection"],
	getContentReferenceCaseStudyItem?: ModelTypes["ContentReferenceCaseStudyItem"] | undefined,
	listContentReferenceCaseStudyItem: Array<ModelTypes["ContentReferenceCaseStudyItem"]>,
	paginateContentReferenceCaseStudyItem: ModelTypes["ContentReferenceCaseStudyItemConnection"],
	getContentReferenceTagItem?: ModelTypes["ContentReferenceTagItem"] | undefined,
	listContentReferenceTagItem: Array<ModelTypes["ContentReferenceTagItem"]>,
	paginateContentReferenceTagItem: ModelTypes["ContentReferenceTagItemConnection"],
	getPositionList?: ModelTypes["PositionList"] | undefined,
	listPositionList: Array<ModelTypes["PositionList"]>,
	paginatePositionList: ModelTypes["PositionListConnection"],
	getPositionListItem?: ModelTypes["PositionListItem"] | undefined,
	listPositionListItem: Array<ModelTypes["PositionListItem"]>,
	paginatePositionListItem: ModelTypes["PositionListItemConnection"],
	getTestimonialFull?: ModelTypes["TestimonialFull"] | undefined,
	listTestimonialFull: Array<ModelTypes["TestimonialFull"]>,
	paginateTestimonialFull: ModelTypes["TestimonialFullConnection"],
	getTestimonialFullList?: ModelTypes["TestimonialFullList"] | undefined,
	listTestimonialFullList: Array<ModelTypes["TestimonialFullList"]>,
	paginateTestimonialFullList: ModelTypes["TestimonialFullListConnection"],
	getTestimonialFullListItem?: ModelTypes["TestimonialFullListItem"] | undefined,
	listTestimonialFullListItem: Array<ModelTypes["TestimonialFullListItem"]>,
	paginateTestimonialFullListItem: ModelTypes["TestimonialFullListItemConnection"],
	getSecondLevelLinkItem?: ModelTypes["SecondLevelLinkItem"] | undefined,
	listSecondLevelLinkItem: Array<ModelTypes["SecondLevelLinkItem"]>,
	paginateSecondLevelLinkItem: ModelTypes["SecondLevelLinkItemConnection"],
	getThirdLevelLinkItem?: ModelTypes["ThirdLevelLinkItem"] | undefined,
	listThirdLevelLinkItem: Array<ModelTypes["ThirdLevelLinkItem"]>,
	paginateThirdLevelLinkItem: ModelTypes["ThirdLevelLinkItemConnection"],
	getTopLevelLinkItem?: ModelTypes["TopLevelLinkItem"] | undefined,
	listTopLevelLinkItem: Array<ModelTypes["TopLevelLinkItem"]>,
	paginateTopLevelLinkItem: ModelTypes["TopLevelLinkItemConnection"],
	getAuthor?: ModelTypes["Author"] | undefined,
	listAuthor: Array<ModelTypes["Author"]>,
	paginateAuthor: ModelTypes["AuthorConnection"],
	getAuthorLocale?: ModelTypes["AuthorLocale"] | undefined,
	listAuthorLocale: Array<ModelTypes["AuthorLocale"]>,
	paginateAuthorLocale: ModelTypes["AuthorLocaleConnection"],
	getBlogPost?: ModelTypes["BlogPost"] | undefined,
	listBlogPost: Array<ModelTypes["BlogPost"]>,
	paginateBlogPost: ModelTypes["BlogPostConnection"],
	getBlogPostLocale?: ModelTypes["BlogPostLocale"] | undefined,
	listBlogPostLocale: Array<ModelTypes["BlogPostLocale"]>,
	paginateBlogPostLocale: ModelTypes["BlogPostLocaleConnection"],
	getContentReferenceBlogPostItem?: ModelTypes["ContentReferenceBlogPostItem"] | undefined,
	listContentReferenceBlogPostItem: Array<ModelTypes["ContentReferenceBlogPostItem"]>,
	paginateContentReferenceBlogPostItem: ModelTypes["ContentReferenceBlogPostItemConnection"],
	getRelevantBlogPostList?: ModelTypes["RelevantBlogPostList"] | undefined,
	listRelevantBlogPostList: Array<ModelTypes["RelevantBlogPostList"]>,
	paginateRelevantBlogPostList: ModelTypes["RelevantBlogPostListConnection"],
	getBlogPostCommon?: ModelTypes["BlogPostCommon"] | undefined,
	listBlogPostCommon: Array<ModelTypes["BlogPostCommon"]>,
	paginateBlogPostCommon: ModelTypes["BlogPostCommonConnection"],
	getBlogPostCommonLocale?: ModelTypes["BlogPostCommonLocale"] | undefined,
	listBlogPostCommonLocale: Array<ModelTypes["BlogPostCommonLocale"]>,
	paginateBlogPostCommonLocale: ModelTypes["BlogPostCommonLocaleConnection"],
	transaction?: ModelTypes["QueryTransaction"] | undefined,
	_info?: ModelTypes["Info"] | undefined
};
	["_Schema"]: {
		enums: Array<ModelTypes["_Enum"]>,
	entities: Array<ModelTypes["_Entity"]>
};
	["_Enum"]: {
		name: string,
	values: Array<string>
};
	["_Entity"]: {
		name: string,
	customPrimaryAllowed: boolean,
	fields: Array<ModelTypes["_Field"]>,
	unique: Array<ModelTypes["_UniqueConstraint"]>
};
	["_UniqueConstraint"]: {
		fields: Array<string>
};
	["General"]: {
		_meta?: ModelTypes["GeneralMeta"] | undefined,
	id: ModelTypes["UUID"],
	unique: ModelTypes["One"],
	locales: Array<ModelTypes["GeneralLocale"]>,
	localesByLocale?: ModelTypes["GeneralLocale"] | undefined,
	localesBySeo?: ModelTypes["GeneralLocale"] | undefined,
	localesByContent?: ModelTypes["GeneralLocale"] | undefined,
	paginateLocales: ModelTypes["GeneralLocaleConnection"]
};
	["GeneralMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	unique?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined
};
	["FieldMeta"]: {
		readable?: boolean | undefined,
	updatable?: boolean | undefined
};
	["UUID"]:any;
	["One"]:One;
	["GeneralLocale"]: {
		_meta?: ModelTypes["GeneralLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	root?: ModelTypes["General"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	seo?: ModelTypes["Seo"] | undefined,
	homeLinkable?: ModelTypes["Linkable"] | undefined,
	caseStudiesLink?: ModelTypes["Link"] | undefined,
	positionsLink?: ModelTypes["Link"] | undefined,
	facebookUrl?: string | undefined,
	twitterUrl?: string | undefined,
	instagramUrl?: string | undefined,
	youtubeUrl?: string | undefined,
	linkedinUrl?: string | undefined,
	formPrivacyPolicy?: string | undefined,
	formMarketingConsent?: string | undefined,
	acceptButtonLabel: string,
	rejectButtonLabel: string,
	content?: ModelTypes["Content"] | undefined
};
	["GeneralLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	seo?: ModelTypes["FieldMeta"] | undefined,
	homeLinkable?: ModelTypes["FieldMeta"] | undefined,
	caseStudiesLink?: ModelTypes["FieldMeta"] | undefined,
	positionsLink?: ModelTypes["FieldMeta"] | undefined,
	facebookUrl?: ModelTypes["FieldMeta"] | undefined,
	twitterUrl?: ModelTypes["FieldMeta"] | undefined,
	instagramUrl?: ModelTypes["FieldMeta"] | undefined,
	youtubeUrl?: ModelTypes["FieldMeta"] | undefined,
	linkedinUrl?: ModelTypes["FieldMeta"] | undefined,
	formPrivacyPolicy?: ModelTypes["FieldMeta"] | undefined,
	formMarketingConsent?: ModelTypes["FieldMeta"] | undefined,
	acceptButtonLabel?: ModelTypes["FieldMeta"] | undefined,
	rejectButtonLabel?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined
};
	["GeneralWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	unique?: ModelTypes["OneCondition"] | undefined,
	locales?: ModelTypes["GeneralLocaleWhere"] | undefined,
	and?: Array<ModelTypes["GeneralWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["GeneralWhere"] | undefined> | undefined,
	not?: ModelTypes["GeneralWhere"] | undefined
};
	["UUIDCondition"]: {
	and?: Array<ModelTypes["UUIDCondition"]> | undefined,
	or?: Array<ModelTypes["UUIDCondition"]> | undefined,
	not?: ModelTypes["UUIDCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["UUID"] | undefined,
	notEq?: ModelTypes["UUID"] | undefined,
	in?: Array<ModelTypes["UUID"]> | undefined,
	notIn?: Array<ModelTypes["UUID"]> | undefined,
	lt?: ModelTypes["UUID"] | undefined,
	lte?: ModelTypes["UUID"] | undefined,
	gt?: ModelTypes["UUID"] | undefined,
	gte?: ModelTypes["UUID"] | undefined
};
	["OneCondition"]: {
	and?: Array<ModelTypes["OneCondition"]> | undefined,
	or?: Array<ModelTypes["OneCondition"]> | undefined,
	not?: ModelTypes["OneCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["One"] | undefined,
	notEq?: ModelTypes["One"] | undefined,
	in?: Array<ModelTypes["One"]> | undefined,
	notIn?: Array<ModelTypes["One"]> | undefined,
	lt?: ModelTypes["One"] | undefined,
	lte?: ModelTypes["One"] | undefined,
	gt?: ModelTypes["One"] | undefined,
	gte?: ModelTypes["One"] | undefined
};
	["GeneralLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	root?: ModelTypes["GeneralWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	seo?: ModelTypes["SeoWhere"] | undefined,
	homeLinkable?: ModelTypes["LinkableWhere"] | undefined,
	caseStudiesLink?: ModelTypes["LinkWhere"] | undefined,
	positionsLink?: ModelTypes["LinkWhere"] | undefined,
	facebookUrl?: ModelTypes["StringCondition"] | undefined,
	twitterUrl?: ModelTypes["StringCondition"] | undefined,
	instagramUrl?: ModelTypes["StringCondition"] | undefined,
	youtubeUrl?: ModelTypes["StringCondition"] | undefined,
	linkedinUrl?: ModelTypes["StringCondition"] | undefined,
	formPrivacyPolicy?: ModelTypes["StringCondition"] | undefined,
	formMarketingConsent?: ModelTypes["StringCondition"] | undefined,
	acceptButtonLabel?: ModelTypes["StringCondition"] | undefined,
	rejectButtonLabel?: ModelTypes["StringCondition"] | undefined,
	content?: ModelTypes["ContentWhere"] | undefined,
	and?: Array<ModelTypes["GeneralLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["GeneralLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["GeneralLocaleWhere"] | undefined
};
	["LocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	code?: ModelTypes["StringCondition"] | undefined,
	label?: ModelTypes["StringCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	general?: ModelTypes["GeneralLocaleWhere"] | undefined,
	genericPages?: ModelTypes["GenericPageLocaleWhere"] | undefined,
	headers?: ModelTypes["HeaderLocaleWhere"] | undefined,
	footers?: ModelTypes["FooterLocaleWhere"] | undefined,
	caseStudies?: ModelTypes["CaseStudyLocaleWhere"] | undefined,
	tags?: ModelTypes["TagLocaleWhere"] | undefined,
	positionTags?: ModelTypes["PositionTagLocaleWhere"] | undefined,
	positions?: ModelTypes["PositionLocaleWhere"] | undefined,
	positionCategories?: ModelTypes["PositionCategoryLocaleWhere"] | undefined,
	authors?: ModelTypes["AuthorLocaleWhere"] | undefined,
	blogPosts?: ModelTypes["BlogPostLocaleWhere"] | undefined,
	blogPostCommon?: ModelTypes["BlogPostCommonLocaleWhere"] | undefined,
	and?: Array<ModelTypes["LocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["LocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["LocaleWhere"] | undefined
};
	["StringCondition"]: {
	and?: Array<ModelTypes["StringCondition"]> | undefined,
	or?: Array<ModelTypes["StringCondition"]> | undefined,
	not?: ModelTypes["StringCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: string | undefined,
	notEq?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	containsCI?: string | undefined,
	startsWithCI?: string | undefined,
	endsWithCI?: string | undefined
};
	["IntCondition"]: {
	and?: Array<ModelTypes["IntCondition"]> | undefined,
	or?: Array<ModelTypes["IntCondition"]> | undefined,
	not?: ModelTypes["IntCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["GenericPageLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["GenericPageWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	content?: ModelTypes["ContentWhere"] | undefined,
	link?: ModelTypes["LinkableWhere"] | undefined,
	seo?: ModelTypes["SeoWhere"] | undefined,
	and?: Array<ModelTypes["GenericPageLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["GenericPageLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["GenericPageLocaleWhere"] | undefined
};
	["GenericPageWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	locales?: ModelTypes["GenericPageLocaleWhere"] | undefined,
	publishedAt?: ModelTypes["DateTimeCondition"] | undefined,
	and?: Array<ModelTypes["GenericPageWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["GenericPageWhere"] | undefined> | undefined,
	not?: ModelTypes["GenericPageWhere"] | undefined
};
	["DateTimeCondition"]: {
	and?: Array<ModelTypes["DateTimeCondition"]> | undefined,
	or?: Array<ModelTypes["DateTimeCondition"]> | undefined,
	not?: ModelTypes["DateTimeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["DateTime"] | undefined,
	notEq?: ModelTypes["DateTime"] | undefined,
	in?: Array<ModelTypes["DateTime"]> | undefined,
	notIn?: Array<ModelTypes["DateTime"]> | undefined,
	lt?: ModelTypes["DateTime"] | undefined,
	lte?: ModelTypes["DateTime"] | undefined,
	gt?: ModelTypes["DateTime"] | undefined,
	gte?: ModelTypes["DateTime"] | undefined
};
	["DateTime"]:any;
	["ContentWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	blocks?: ModelTypes["ContentBlockWhere"] | undefined,
	and?: Array<ModelTypes["ContentWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContentWhere"] | undefined> | undefined,
	not?: ModelTypes["ContentWhere"] | undefined
};
	["ContentBlockWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	json?: ModelTypes["StringCondition"] | undefined,
	content?: ModelTypes["ContentWhere"] | undefined,
	references?: ModelTypes["ContentReferenceWhere"] | undefined,
	and?: Array<ModelTypes["ContentBlockWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContentBlockWhere"] | undefined> | undefined,
	not?: ModelTypes["ContentBlockWhere"] | undefined
};
	["ContentReferenceWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	type?: ModelTypes["ContentReferenceTypeCondition"] | undefined,
	primaryText?: ModelTypes["StringCondition"] | undefined,
	block?: ModelTypes["ContentBlockWhere"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	secondaryText?: ModelTypes["StringCondition"] | undefined,
	link?: ModelTypes["LinkWhere"] | undefined,
	featuredCaseStudies?: ModelTypes["CaseStudyListWhere"] | undefined,
	images?: ModelTypes["ImageListWhere"] | undefined,
	otherImages?: ModelTypes["ImageListWhere"] | undefined,
	informationList?: ModelTypes["InformationListWhere"] | undefined,
	tertiaryText?: ModelTypes["StringCondition"] | undefined,
	embed?: ModelTypes["EmbedWhere"] | undefined,
	links?: ModelTypes["LinkListWhere"] | undefined,
	talkLists?: ModelTypes["TalkListsWhere"] | undefined,
	businessCardList?: ModelTypes["BusinessCardListWhere"] | undefined,
	testimonials?: ModelTypes["TestimonialListWhere"] | undefined,
	background?: ModelTypes["ContentBackgroundTypeCondition"] | undefined,
	otherImage?: ModelTypes["ImageWhere"] | undefined,
	caseStudy?: ModelTypes["CaseStudyWhere"] | undefined,
	alternative?: ModelTypes["BooleanCondition"] | undefined,
	contactRequestOptions?: ModelTypes["ContactRequestOptionListWhere"] | undefined,
	youtubeVideo?: ModelTypes["YoutubeVideoWhere"] | undefined,
	subscribeForm?: ModelTypes["SubscribeFormWhere"] | undefined,
	video?: ModelTypes["VideoWhere"] | undefined,
	otherVideo?: ModelTypes["VideoWhere"] | undefined,
	positionCategories?: ModelTypes["ContentReferencePositionCategoryItemWhere"] | undefined,
	caseStudies?: ModelTypes["ContentReferenceCaseStudyItemWhere"] | undefined,
	tags?: ModelTypes["ContentReferenceTagItemWhere"] | undefined,
	positions?: ModelTypes["PositionListWhere"] | undefined,
	testimonialsFull?: ModelTypes["TestimonialFullListWhere"] | undefined,
	blogPosts?: ModelTypes["ContentReferenceBlogPostItemWhere"] | undefined,
	verticalMargin?: ModelTypes["ContentVerticalMarginCondition"] | undefined,
	variant?: ModelTypes["StringCondition"] | undefined,
	content?: ModelTypes["ContentWhere"] | undefined,
	and?: Array<ModelTypes["ContentReferenceWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContentReferenceWhere"] | undefined> | undefined,
	not?: ModelTypes["ContentReferenceWhere"] | undefined
};
	["ContentReferenceTypeCondition"]: {
	and?: Array<ModelTypes["ContentReferenceTypeCondition"]> | undefined,
	or?: Array<ModelTypes["ContentReferenceTypeCondition"]> | undefined,
	not?: ModelTypes["ContentReferenceTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["ContentReferenceType"] | undefined,
	notEq?: ModelTypes["ContentReferenceType"] | undefined,
	in?: Array<ModelTypes["ContentReferenceType"]> | undefined,
	notIn?: Array<ModelTypes["ContentReferenceType"]> | undefined,
	lt?: ModelTypes["ContentReferenceType"] | undefined,
	lte?: ModelTypes["ContentReferenceType"] | undefined,
	gt?: ModelTypes["ContentReferenceType"] | undefined,
	gte?: ModelTypes["ContentReferenceType"] | undefined
};
	["ContentReferenceType"]:ContentReferenceType;
	["ImageWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	url?: ModelTypes["StringCondition"] | undefined,
	width?: ModelTypes["IntCondition"] | undefined,
	height?: ModelTypes["IntCondition"] | undefined,
	size?: ModelTypes["IntCondition"] | undefined,
	type?: ModelTypes["StringCondition"] | undefined,
	alt?: ModelTypes["StringCondition"] | undefined,
	fileName?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["ImageWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ImageWhere"] | undefined> | undefined,
	not?: ModelTypes["ImageWhere"] | undefined
};
	["LinkWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	externalLink?: ModelTypes["StringCondition"] | undefined,
	internalLink?: ModelTypes["LinkableWhere"] | undefined,
	type?: ModelTypes["LinkTypeCondition"] | undefined,
	isTargetBlank?: ModelTypes["BooleanCondition"] | undefined,
	and?: Array<ModelTypes["LinkWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["LinkWhere"] | undefined> | undefined,
	not?: ModelTypes["LinkWhere"] | undefined
};
	["LinkableWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	url?: ModelTypes["StringCondition"] | undefined,
	genericPage?: ModelTypes["GenericPageLocaleWhere"] | undefined,
	caseStudy?: ModelTypes["CaseStudyLocaleWhere"] | undefined,
	position?: ModelTypes["PositionLocaleWhere"] | undefined,
	redirect?: ModelTypes["RedirectWhere"] | undefined,
	blogPost?: ModelTypes["BlogPostLocaleWhere"] | undefined,
	and?: Array<ModelTypes["LinkableWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["LinkableWhere"] | undefined> | undefined,
	not?: ModelTypes["LinkableWhere"] | undefined
};
	["CaseStudyLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["CaseStudyWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	content?: ModelTypes["ContentWhere"] | undefined,
	link?: ModelTypes["LinkableWhere"] | undefined,
	seo?: ModelTypes["SeoWhere"] | undefined,
	note?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["CaseStudyLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["CaseStudyLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["CaseStudyLocaleWhere"] | undefined
};
	["CaseStudyWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	locales?: ModelTypes["CaseStudyLocaleWhere"] | undefined,
	tags?: ModelTypes["CaseStudyTagWhere"] | undefined,
	wideImage?: ModelTypes["ImageWhere"] | undefined,
	narrowImage?: ModelTypes["ImageWhere"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	publishedAt?: ModelTypes["DateTimeCondition"] | undefined,
	and?: Array<ModelTypes["CaseStudyWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["CaseStudyWhere"] | undefined> | undefined,
	not?: ModelTypes["CaseStudyWhere"] | undefined
};
	["CaseStudyTagWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	caseStudy?: ModelTypes["CaseStudyWhere"] | undefined,
	tag?: ModelTypes["TagWhere"] | undefined,
	and?: Array<ModelTypes["CaseStudyTagWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["CaseStudyTagWhere"] | undefined> | undefined,
	not?: ModelTypes["CaseStudyTagWhere"] | undefined
};
	["TagWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	locales?: ModelTypes["TagLocaleWhere"] | undefined,
	caseStudyTags?: ModelTypes["CaseStudyTagWhere"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	and?: Array<ModelTypes["TagWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TagWhere"] | undefined> | undefined,
	not?: ModelTypes["TagWhere"] | undefined
};
	["TagLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	slug?: ModelTypes["StringCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["TagWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	and?: Array<ModelTypes["TagLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TagLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["TagLocaleWhere"] | undefined
};
	["SeoWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	description?: ModelTypes["StringCondition"] | undefined,
	ogTitle?: ModelTypes["StringCondition"] | undefined,
	ogDescription?: ModelTypes["StringCondition"] | undefined,
	ogImage?: ModelTypes["ImageWhere"] | undefined,
	noIndex?: ModelTypes["BooleanCondition"] | undefined,
	noFollow?: ModelTypes["BooleanCondition"] | undefined,
	and?: Array<ModelTypes["SeoWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["SeoWhere"] | undefined> | undefined,
	not?: ModelTypes["SeoWhere"] | undefined
};
	["BooleanCondition"]: {
	and?: Array<ModelTypes["BooleanCondition"]> | undefined,
	or?: Array<ModelTypes["BooleanCondition"]> | undefined,
	not?: ModelTypes["BooleanCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: boolean | undefined,
	notEq?: boolean | undefined,
	in?: Array<boolean> | undefined,
	notIn?: Array<boolean> | undefined,
	lt?: boolean | undefined,
	lte?: boolean | undefined,
	gt?: boolean | undefined,
	gte?: boolean | undefined
};
	["PositionLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	link?: ModelTypes["LinkableWhere"] | undefined,
	root?: ModelTypes["PositionWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	seo?: ModelTypes["SeoWhere"] | undefined,
	content?: ModelTypes["ContentWhere"] | undefined,
	summary?: ModelTypes["StringCondition"] | undefined,
	salaryRange?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["PositionLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["PositionLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["PositionLocaleWhere"] | undefined
};
	["PositionWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	tags?: ModelTypes["PositionTagListWhere"] | undefined,
	locales?: ModelTypes["PositionLocaleWhere"] | undefined,
	orderInCategory?: ModelTypes["IntCondition"] | undefined,
	category?: ModelTypes["PositionCategoryWhere"] | undefined,
	publishedAt?: ModelTypes["DateTimeCondition"] | undefined,
	and?: Array<ModelTypes["PositionWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["PositionWhere"] | undefined> | undefined,
	not?: ModelTypes["PositionWhere"] | undefined
};
	["PositionTagListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["PositionTagListItemWhere"] | undefined,
	and?: Array<ModelTypes["PositionTagListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["PositionTagListWhere"] | undefined> | undefined,
	not?: ModelTypes["PositionTagListWhere"] | undefined
};
	["PositionTagListItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["PositionTagListWhere"] | undefined,
	tag?: ModelTypes["PositionTagWhere"] | undefined,
	and?: Array<ModelTypes["PositionTagListItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["PositionTagListItemWhere"] | undefined> | undefined,
	not?: ModelTypes["PositionTagListItemWhere"] | undefined
};
	["PositionTagWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	locales?: ModelTypes["PositionTagLocaleWhere"] | undefined,
	and?: Array<ModelTypes["PositionTagWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["PositionTagWhere"] | undefined> | undefined,
	not?: ModelTypes["PositionTagWhere"] | undefined
};
	["PositionTagLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	slug?: ModelTypes["StringCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["PositionTagWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	and?: Array<ModelTypes["PositionTagLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["PositionTagLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["PositionTagLocaleWhere"] | undefined
};
	["PositionCategoryWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	positions?: ModelTypes["PositionWhere"] | undefined,
	locales?: ModelTypes["PositionCategoryLocaleWhere"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	and?: Array<ModelTypes["PositionCategoryWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["PositionCategoryWhere"] | undefined> | undefined,
	not?: ModelTypes["PositionCategoryWhere"] | undefined
};
	["PositionCategoryLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["PositionCategoryWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	and?: Array<ModelTypes["PositionCategoryLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["PositionCategoryLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["PositionCategoryLocaleWhere"] | undefined
};
	["RedirectWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	link?: ModelTypes["LinkableWhere"] | undefined,
	target?: ModelTypes["LinkWhere"] | undefined,
	and?: Array<ModelTypes["RedirectWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["RedirectWhere"] | undefined> | undefined,
	not?: ModelTypes["RedirectWhere"] | undefined
};
	["BlogPostLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["BlogPostWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	content?: ModelTypes["ContentWhere"] | undefined,
	seo?: ModelTypes["SeoWhere"] | undefined,
	link?: ModelTypes["LinkableWhere"] | undefined,
	lead?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["BlogPostLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["BlogPostLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["BlogPostLocaleWhere"] | undefined
};
	["BlogPostWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	publishedAt?: ModelTypes["DateTimeCondition"] | undefined,
	author?: ModelTypes["AuthorWhere"] | undefined,
	wideImage?: ModelTypes["ImageWhere"] | undefined,
	narrowImage?: ModelTypes["ImageWhere"] | undefined,
	locales?: ModelTypes["BlogPostLocaleWhere"] | undefined,
	relevantBlogPosts?: ModelTypes["RelevantBlogPostListWhere"] | undefined,
	and?: Array<ModelTypes["BlogPostWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["BlogPostWhere"] | undefined> | undefined,
	not?: ModelTypes["BlogPostWhere"] | undefined
};
	["AuthorWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	locales?: ModelTypes["AuthorLocaleWhere"] | undefined,
	blogPosts?: ModelTypes["BlogPostWhere"] | undefined,
	name?: ModelTypes["StringCondition"] | undefined,
	profileUrl?: ModelTypes["StringCondition"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	avatarImage?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["AuthorWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["AuthorWhere"] | undefined> | undefined,
	not?: ModelTypes["AuthorWhere"] | undefined
};
	["AuthorLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	role?: ModelTypes["StringCondition"] | undefined,
	bio?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["AuthorWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	and?: Array<ModelTypes["AuthorLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["AuthorLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["AuthorLocaleWhere"] | undefined
};
	["RelevantBlogPostListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	blogPost?: ModelTypes["BlogPostWhere"] | undefined,
	relevantBlogPost?: ModelTypes["BlogPostWhere"] | undefined,
	and?: Array<ModelTypes["RelevantBlogPostListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["RelevantBlogPostListWhere"] | undefined> | undefined,
	not?: ModelTypes["RelevantBlogPostListWhere"] | undefined
};
	["LinkTypeCondition"]: {
	and?: Array<ModelTypes["LinkTypeCondition"]> | undefined,
	or?: Array<ModelTypes["LinkTypeCondition"]> | undefined,
	not?: ModelTypes["LinkTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["LinkType"] | undefined,
	notEq?: ModelTypes["LinkType"] | undefined,
	in?: Array<ModelTypes["LinkType"]> | undefined,
	notIn?: Array<ModelTypes["LinkType"]> | undefined,
	lt?: ModelTypes["LinkType"] | undefined,
	lte?: ModelTypes["LinkType"] | undefined,
	gt?: ModelTypes["LinkType"] | undefined,
	gte?: ModelTypes["LinkType"] | undefined
};
	["LinkType"]:LinkType;
	["CaseStudyListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["CaseStudyListItemWhere"] | undefined,
	and?: Array<ModelTypes["CaseStudyListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["CaseStudyListWhere"] | undefined> | undefined,
	not?: ModelTypes["CaseStudyListWhere"] | undefined
};
	["CaseStudyListItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["CaseStudyListWhere"] | undefined,
	caseStudy?: ModelTypes["CaseStudyWhere"] | undefined,
	and?: Array<ModelTypes["CaseStudyListItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["CaseStudyListItemWhere"] | undefined> | undefined,
	not?: ModelTypes["CaseStudyListItemWhere"] | undefined
};
	["ImageListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["ImageListItemWhere"] | undefined,
	and?: Array<ModelTypes["ImageListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ImageListWhere"] | undefined> | undefined,
	not?: ModelTypes["ImageListWhere"] | undefined
};
	["ImageListItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["ImageListWhere"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["ImageListItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ImageListItemWhere"] | undefined> | undefined,
	not?: ModelTypes["ImageListItemWhere"] | undefined
};
	["InformationListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["PieceOfInformationWhere"] | undefined,
	and?: Array<ModelTypes["InformationListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["InformationListWhere"] | undefined> | undefined,
	not?: ModelTypes["InformationListWhere"] | undefined
};
	["PieceOfInformationWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	description?: ModelTypes["StringCondition"] | undefined,
	list?: ModelTypes["InformationListWhere"] | undefined,
	and?: Array<ModelTypes["PieceOfInformationWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["PieceOfInformationWhere"] | undefined> | undefined,
	not?: ModelTypes["PieceOfInformationWhere"] | undefined
};
	["EmbedWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	type?: ModelTypes["EmbedTypeCondition"] | undefined,
	embedId?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["EmbedWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["EmbedWhere"] | undefined> | undefined,
	not?: ModelTypes["EmbedWhere"] | undefined
};
	["EmbedTypeCondition"]: {
	and?: Array<ModelTypes["EmbedTypeCondition"]> | undefined,
	or?: Array<ModelTypes["EmbedTypeCondition"]> | undefined,
	not?: ModelTypes["EmbedTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["EmbedType"] | undefined,
	notEq?: ModelTypes["EmbedType"] | undefined,
	in?: Array<ModelTypes["EmbedType"]> | undefined,
	notIn?: Array<ModelTypes["EmbedType"]> | undefined,
	lt?: ModelTypes["EmbedType"] | undefined,
	lte?: ModelTypes["EmbedType"] | undefined,
	gt?: ModelTypes["EmbedType"] | undefined,
	gte?: ModelTypes["EmbedType"] | undefined
};
	["EmbedType"]:EmbedType;
	["LinkListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["LinkListItemWhere"] | undefined,
	and?: Array<ModelTypes["LinkListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["LinkListWhere"] | undefined> | undefined,
	not?: ModelTypes["LinkListWhere"] | undefined
};
	["LinkListItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["LinkListWhere"] | undefined,
	link?: ModelTypes["LinkWhere"] | undefined,
	and?: Array<ModelTypes["LinkListItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["LinkListItemWhere"] | undefined> | undefined,
	not?: ModelTypes["LinkListItemWhere"] | undefined
};
	["TalkListsWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["TalkListWhere"] | undefined,
	and?: Array<ModelTypes["TalkListsWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TalkListsWhere"] | undefined> | undefined,
	not?: ModelTypes["TalkListsWhere"] | undefined
};
	["TalkListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	list?: ModelTypes["TalkListsWhere"] | undefined,
	items?: ModelTypes["TalkListItemWhere"] | undefined,
	and?: Array<ModelTypes["TalkListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TalkListWhere"] | undefined> | undefined,
	not?: ModelTypes["TalkListWhere"] | undefined
};
	["TalkListItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["TalkListWhere"] | undefined,
	talk?: ModelTypes["TalkWhere"] | undefined,
	and?: Array<ModelTypes["TalkListItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TalkListItemWhere"] | undefined> | undefined,
	not?: ModelTypes["TalkListItemWhere"] | undefined
};
	["TalkWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	slug?: ModelTypes["StringCondition"] | undefined,
	date?: ModelTypes["DateTimeCondition"] | undefined,
	note?: ModelTypes["StringCondition"] | undefined,
	content?: ModelTypes["StringCondition"] | undefined,
	coverImage?: ModelTypes["ImageWhere"] | undefined,
	otherImage?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["TalkWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TalkWhere"] | undefined> | undefined,
	not?: ModelTypes["TalkWhere"] | undefined
};
	["BusinessCardListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["BusinessCardListItemWhere"] | undefined,
	and?: Array<ModelTypes["BusinessCardListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["BusinessCardListWhere"] | undefined> | undefined,
	not?: ModelTypes["BusinessCardListWhere"] | undefined
};
	["BusinessCardListItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["BusinessCardListWhere"] | undefined,
	businessCard?: ModelTypes["BusinessCardWhere"] | undefined,
	and?: Array<ModelTypes["BusinessCardListItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["BusinessCardListItemWhere"] | undefined> | undefined,
	not?: ModelTypes["BusinessCardListItemWhere"] | undefined
};
	["BusinessCardWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	name?: ModelTypes["StringCondition"] | undefined,
	role?: ModelTypes["StringCondition"] | undefined,
	email?: ModelTypes["StringCondition"] | undefined,
	avatar?: ModelTypes["ImageWhere"] | undefined,
	phone?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["BusinessCardWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["BusinessCardWhere"] | undefined> | undefined,
	not?: ModelTypes["BusinessCardWhere"] | undefined
};
	["TestimonialListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["TestimonialListItemWhere"] | undefined,
	and?: Array<ModelTypes["TestimonialListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TestimonialListWhere"] | undefined> | undefined,
	not?: ModelTypes["TestimonialListWhere"] | undefined
};
	["TestimonialListItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["TestimonialListWhere"] | undefined,
	testimonial?: ModelTypes["TestimonialWhere"] | undefined,
	and?: Array<ModelTypes["TestimonialListItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TestimonialListItemWhere"] | undefined> | undefined,
	not?: ModelTypes["TestimonialListItemWhere"] | undefined
};
	["TestimonialWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	content?: ModelTypes["StringCondition"] | undefined,
	note?: ModelTypes["StringCondition"] | undefined,
	avatar?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["TestimonialWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TestimonialWhere"] | undefined> | undefined,
	not?: ModelTypes["TestimonialWhere"] | undefined
};
	["ContentBackgroundTypeCondition"]: {
	and?: Array<ModelTypes["ContentBackgroundTypeCondition"]> | undefined,
	or?: Array<ModelTypes["ContentBackgroundTypeCondition"]> | undefined,
	not?: ModelTypes["ContentBackgroundTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["ContentBackgroundType"] | undefined,
	notEq?: ModelTypes["ContentBackgroundType"] | undefined,
	in?: Array<ModelTypes["ContentBackgroundType"]> | undefined,
	notIn?: Array<ModelTypes["ContentBackgroundType"]> | undefined,
	lt?: ModelTypes["ContentBackgroundType"] | undefined,
	lte?: ModelTypes["ContentBackgroundType"] | undefined,
	gt?: ModelTypes["ContentBackgroundType"] | undefined,
	gte?: ModelTypes["ContentBackgroundType"] | undefined
};
	["ContentBackgroundType"]:ContentBackgroundType;
	["ContactRequestOptionListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["ContactRequestOptionWhere"] | undefined,
	and?: Array<ModelTypes["ContactRequestOptionListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContactRequestOptionListWhere"] | undefined> | undefined,
	not?: ModelTypes["ContactRequestOptionListWhere"] | undefined
};
	["ContactRequestOptionWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	label?: ModelTypes["StringCondition"] | undefined,
	description?: ModelTypes["StringCondition"] | undefined,
	list?: ModelTypes["ContactRequestOptionListWhere"] | undefined,
	code?: ModelTypes["StringCondition"] | undefined,
	type?: ModelTypes["ContactRequestOptionTypeCondition"] | undefined,
	subscribeForm?: ModelTypes["SubscribeFormWhere"] | undefined,
	and?: Array<ModelTypes["ContactRequestOptionWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContactRequestOptionWhere"] | undefined> | undefined,
	not?: ModelTypes["ContactRequestOptionWhere"] | undefined
};
	["ContactRequestOptionTypeCondition"]: {
	and?: Array<ModelTypes["ContactRequestOptionTypeCondition"]> | undefined,
	or?: Array<ModelTypes["ContactRequestOptionTypeCondition"]> | undefined,
	not?: ModelTypes["ContactRequestOptionTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["ContactRequestOptionType"] | undefined,
	notEq?: ModelTypes["ContactRequestOptionType"] | undefined,
	in?: Array<ModelTypes["ContactRequestOptionType"]> | undefined,
	notIn?: Array<ModelTypes["ContactRequestOptionType"]> | undefined,
	lt?: ModelTypes["ContactRequestOptionType"] | undefined,
	lte?: ModelTypes["ContactRequestOptionType"] | undefined,
	gt?: ModelTypes["ContactRequestOptionType"] | undefined,
	gte?: ModelTypes["ContactRequestOptionType"] | undefined
};
	["ContactRequestOptionType"]:ContactRequestOptionType;
	["SubscribeFormWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	name?: ModelTypes["StringCondition"] | undefined,
	datacruitJobId?: ModelTypes["IntCondition"] | undefined,
	datacruitReferenceNumber?: ModelTypes["StringCondition"] | undefined,
	lastUpdateAt?: ModelTypes["DateTimeCondition"] | undefined,
	and?: Array<ModelTypes["SubscribeFormWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["SubscribeFormWhere"] | undefined> | undefined,
	not?: ModelTypes["SubscribeFormWhere"] | undefined
};
	["YoutubeVideoWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	videoId?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["YoutubeVideoWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["YoutubeVideoWhere"] | undefined> | undefined,
	not?: ModelTypes["YoutubeVideoWhere"] | undefined
};
	["VideoWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	src?: ModelTypes["StringCondition"] | undefined,
	height?: ModelTypes["IntCondition"] | undefined,
	width?: ModelTypes["IntCondition"] | undefined,
	duration?: ModelTypes["FloatCondition"] | undefined,
	size?: ModelTypes["IntCondition"] | undefined,
	type?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["VideoWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["VideoWhere"] | undefined> | undefined,
	not?: ModelTypes["VideoWhere"] | undefined
};
	["FloatCondition"]: {
	and?: Array<ModelTypes["FloatCondition"]> | undefined,
	or?: Array<ModelTypes["FloatCondition"]> | undefined,
	not?: ModelTypes["FloatCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["ContentReferencePositionCategoryItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	item?: ModelTypes["PositionCategoryWhere"] | undefined,
	reference?: ModelTypes["ContentReferenceWhere"] | undefined,
	and?: Array<ModelTypes["ContentReferencePositionCategoryItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContentReferencePositionCategoryItemWhere"] | undefined> | undefined,
	not?: ModelTypes["ContentReferencePositionCategoryItemWhere"] | undefined
};
	["ContentReferenceCaseStudyItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	item?: ModelTypes["CaseStudyWhere"] | undefined,
	reference?: ModelTypes["ContentReferenceWhere"] | undefined,
	and?: Array<ModelTypes["ContentReferenceCaseStudyItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContentReferenceCaseStudyItemWhere"] | undefined> | undefined,
	not?: ModelTypes["ContentReferenceCaseStudyItemWhere"] | undefined
};
	["ContentReferenceTagItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	item?: ModelTypes["TagWhere"] | undefined,
	reference?: ModelTypes["ContentReferenceWhere"] | undefined,
	and?: Array<ModelTypes["ContentReferenceTagItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContentReferenceTagItemWhere"] | undefined> | undefined,
	not?: ModelTypes["ContentReferenceTagItemWhere"] | undefined
};
	["PositionListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["PositionListItemWhere"] | undefined,
	and?: Array<ModelTypes["PositionListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["PositionListWhere"] | undefined> | undefined,
	not?: ModelTypes["PositionListWhere"] | undefined
};
	["PositionListItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["PositionListWhere"] | undefined,
	position?: ModelTypes["PositionLocaleWhere"] | undefined,
	and?: Array<ModelTypes["PositionListItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["PositionListItemWhere"] | undefined> | undefined,
	not?: ModelTypes["PositionListItemWhere"] | undefined
};
	["TestimonialFullListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["TestimonialFullListItemWhere"] | undefined,
	and?: Array<ModelTypes["TestimonialFullListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TestimonialFullListWhere"] | undefined> | undefined,
	not?: ModelTypes["TestimonialFullListWhere"] | undefined
};
	["TestimonialFullListItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["TestimonialFullListWhere"] | undefined,
	testimonial?: ModelTypes["TestimonialFullWhere"] | undefined,
	and?: Array<ModelTypes["TestimonialFullListItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TestimonialFullListItemWhere"] | undefined> | undefined,
	not?: ModelTypes["TestimonialFullListItemWhere"] | undefined
};
	["TestimonialFullWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	content?: ModelTypes["StringCondition"] | undefined,
	name?: ModelTypes["StringCondition"] | undefined,
	note?: ModelTypes["StringCondition"] | undefined,
	avatar?: ModelTypes["ImageWhere"] | undefined,
	imageVariant?: ModelTypes["ImageVariantCondition"] | undefined,
	and?: Array<ModelTypes["TestimonialFullWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TestimonialFullWhere"] | undefined> | undefined,
	not?: ModelTypes["TestimonialFullWhere"] | undefined
};
	["ImageVariantCondition"]: {
	and?: Array<ModelTypes["ImageVariantCondition"]> | undefined,
	or?: Array<ModelTypes["ImageVariantCondition"]> | undefined,
	not?: ModelTypes["ImageVariantCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["ImageVariant"] | undefined,
	notEq?: ModelTypes["ImageVariant"] | undefined,
	in?: Array<ModelTypes["ImageVariant"]> | undefined,
	notIn?: Array<ModelTypes["ImageVariant"]> | undefined,
	lt?: ModelTypes["ImageVariant"] | undefined,
	lte?: ModelTypes["ImageVariant"] | undefined,
	gt?: ModelTypes["ImageVariant"] | undefined,
	gte?: ModelTypes["ImageVariant"] | undefined
};
	["ImageVariant"]:ImageVariant;
	["ContentReferenceBlogPostItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	publishedAt?: ModelTypes["DateCondition"] | undefined,
	item?: ModelTypes["BlogPostWhere"] | undefined,
	reference?: ModelTypes["ContentReferenceWhere"] | undefined,
	and?: Array<ModelTypes["ContentReferenceBlogPostItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContentReferenceBlogPostItemWhere"] | undefined> | undefined,
	not?: ModelTypes["ContentReferenceBlogPostItemWhere"] | undefined
};
	["DateCondition"]: {
	and?: Array<ModelTypes["DateCondition"]> | undefined,
	or?: Array<ModelTypes["DateCondition"]> | undefined,
	not?: ModelTypes["DateCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["Date"] | undefined,
	notEq?: ModelTypes["Date"] | undefined,
	in?: Array<ModelTypes["Date"]> | undefined,
	notIn?: Array<ModelTypes["Date"]> | undefined,
	lt?: ModelTypes["Date"] | undefined,
	lte?: ModelTypes["Date"] | undefined,
	gt?: ModelTypes["Date"] | undefined,
	gte?: ModelTypes["Date"] | undefined
};
	["Date"]:any;
	["ContentVerticalMarginCondition"]: {
	and?: Array<ModelTypes["ContentVerticalMarginCondition"]> | undefined,
	or?: Array<ModelTypes["ContentVerticalMarginCondition"]> | undefined,
	not?: ModelTypes["ContentVerticalMarginCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["ContentVerticalMargin"] | undefined,
	notEq?: ModelTypes["ContentVerticalMargin"] | undefined,
	in?: Array<ModelTypes["ContentVerticalMargin"]> | undefined,
	notIn?: Array<ModelTypes["ContentVerticalMargin"]> | undefined,
	lt?: ModelTypes["ContentVerticalMargin"] | undefined,
	lte?: ModelTypes["ContentVerticalMargin"] | undefined,
	gt?: ModelTypes["ContentVerticalMargin"] | undefined,
	gte?: ModelTypes["ContentVerticalMargin"] | undefined
};
	["ContentVerticalMargin"]:ContentVerticalMargin;
	["HeaderLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	email?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["HeaderWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	linkList?: ModelTypes["LinkListWhere"] | undefined,
	socialLinkList?: ModelTypes["LinkListWhere"] | undefined,
	topLevelLinkItems?: ModelTypes["TopLevelLinkItemWhere"] | undefined,
	and?: Array<ModelTypes["HeaderLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["HeaderLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["HeaderLocaleWhere"] | undefined
};
	["HeaderWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	unique?: ModelTypes["OneCondition"] | undefined,
	locales?: ModelTypes["HeaderLocaleWhere"] | undefined,
	and?: Array<ModelTypes["HeaderWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["HeaderWhere"] | undefined> | undefined,
	not?: ModelTypes["HeaderWhere"] | undefined
};
	["TopLevelLinkItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	isLink?: ModelTypes["IsLinkCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	secondLevelLinkItems?: ModelTypes["SecondLevelLinkItemWhere"] | undefined,
	header?: ModelTypes["HeaderLocaleWhere"] | undefined,
	link?: ModelTypes["LinkWhere"] | undefined,
	and?: Array<ModelTypes["TopLevelLinkItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TopLevelLinkItemWhere"] | undefined> | undefined,
	not?: ModelTypes["TopLevelLinkItemWhere"] | undefined
};
	["IsLinkCondition"]: {
	and?: Array<ModelTypes["IsLinkCondition"]> | undefined,
	or?: Array<ModelTypes["IsLinkCondition"]> | undefined,
	not?: ModelTypes["IsLinkCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["IsLink"] | undefined,
	notEq?: ModelTypes["IsLink"] | undefined,
	in?: Array<ModelTypes["IsLink"]> | undefined,
	notIn?: Array<ModelTypes["IsLink"]> | undefined,
	lt?: ModelTypes["IsLink"] | undefined,
	lte?: ModelTypes["IsLink"] | undefined,
	gt?: ModelTypes["IsLink"] | undefined,
	gte?: ModelTypes["IsLink"] | undefined
};
	["IsLink"]:IsLink;
	["SecondLevelLinkItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	isLink?: ModelTypes["IsLinkCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	topLevelLinkItem?: ModelTypes["TopLevelLinkItemWhere"] | undefined,
	link?: ModelTypes["LinkWhere"] | undefined,
	thirdLevelLinkItems?: ModelTypes["ThirdLevelLinkItemWhere"] | undefined,
	and?: Array<ModelTypes["SecondLevelLinkItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["SecondLevelLinkItemWhere"] | undefined> | undefined,
	not?: ModelTypes["SecondLevelLinkItemWhere"] | undefined
};
	["ThirdLevelLinkItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	secondLevelLinkItem?: ModelTypes["SecondLevelLinkItemWhere"] | undefined,
	link?: ModelTypes["LinkWhere"] | undefined,
	and?: Array<ModelTypes["ThirdLevelLinkItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ThirdLevelLinkItemWhere"] | undefined> | undefined,
	not?: ModelTypes["ThirdLevelLinkItemWhere"] | undefined
};
	["FooterLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	email?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["FooterWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	address?: ModelTypes["StringCondition"] | undefined,
	phone?: ModelTypes["StringCondition"] | undefined,
	legal?: ModelTypes["StringCondition"] | undefined,
	navigation?: ModelTypes["LinkListWhere"] | undefined,
	and?: Array<ModelTypes["FooterLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FooterLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["FooterLocaleWhere"] | undefined
};
	["FooterWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	unique?: ModelTypes["OneCondition"] | undefined,
	locales?: ModelTypes["FooterLocaleWhere"] | undefined,
	and?: Array<ModelTypes["FooterWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FooterWhere"] | undefined> | undefined,
	not?: ModelTypes["FooterWhere"] | undefined
};
	["BlogPostCommonLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	root?: ModelTypes["BlogPostCommonWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	ctaLink?: ModelTypes["LinkWhere"] | undefined,
	ctaTitle?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["BlogPostCommonLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["BlogPostCommonLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["BlogPostCommonLocaleWhere"] | undefined
};
	["BlogPostCommonWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	unique?: ModelTypes["OneCondition"] | undefined,
	locales?: ModelTypes["BlogPostCommonLocaleWhere"] | undefined,
	and?: Array<ModelTypes["BlogPostCommonWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["BlogPostCommonWhere"] | undefined> | undefined,
	not?: ModelTypes["BlogPostCommonWhere"] | undefined
};
	["Locale"]: {
		_meta?: ModelTypes["LocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	code: string,
	label?: string | undefined,
	order: number,
	general: Array<ModelTypes["GeneralLocale"]>,
	genericPages: Array<ModelTypes["GenericPageLocale"]>,
	headers: Array<ModelTypes["HeaderLocale"]>,
	footers: Array<ModelTypes["FooterLocale"]>,
	caseStudies: Array<ModelTypes["CaseStudyLocale"]>,
	tags: Array<ModelTypes["TagLocale"]>,
	positionTags: Array<ModelTypes["PositionTagLocale"]>,
	positions: Array<ModelTypes["PositionLocale"]>,
	positionCategories: Array<ModelTypes["PositionCategoryLocale"]>,
	authors: Array<ModelTypes["AuthorLocale"]>,
	blogPosts: Array<ModelTypes["BlogPostLocale"]>,
	blogPostCommon: Array<ModelTypes["BlogPostCommonLocale"]>,
	generalByRoot?: ModelTypes["GeneralLocale"] | undefined,
	generalBySeo?: ModelTypes["GeneralLocale"] | undefined,
	generalByContent?: ModelTypes["GeneralLocale"] | undefined,
	genericPagesByRoot?: ModelTypes["GenericPageLocale"] | undefined,
	genericPagesByContent?: ModelTypes["GenericPageLocale"] | undefined,
	genericPagesByLink?: ModelTypes["GenericPageLocale"] | undefined,
	genericPagesBySeo?: ModelTypes["GenericPageLocale"] | undefined,
	headersByRoot?: ModelTypes["HeaderLocale"] | undefined,
	headersByLinkList?: ModelTypes["HeaderLocale"] | undefined,
	headersBySocialLinkList?: ModelTypes["HeaderLocale"] | undefined,
	headersByTopLevelLinkItems?: ModelTypes["HeaderLocale"] | undefined,
	footersByRoot?: ModelTypes["FooterLocale"] | undefined,
	footersByNavigation?: ModelTypes["FooterLocale"] | undefined,
	caseStudiesByRoot?: ModelTypes["CaseStudyLocale"] | undefined,
	caseStudiesByContent?: ModelTypes["CaseStudyLocale"] | undefined,
	caseStudiesByLink?: ModelTypes["CaseStudyLocale"] | undefined,
	caseStudiesBySeo?: ModelTypes["CaseStudyLocale"] | undefined,
	tagsBySlug?: ModelTypes["TagLocale"] | undefined,
	tagsByRoot?: ModelTypes["TagLocale"] | undefined,
	positionTagsBySlug?: ModelTypes["PositionTagLocale"] | undefined,
	positionTagsByRoot?: ModelTypes["PositionTagLocale"] | undefined,
	positionsByRoot?: ModelTypes["PositionLocale"] | undefined,
	positionsByLink?: ModelTypes["PositionLocale"] | undefined,
	positionsBySeo?: ModelTypes["PositionLocale"] | undefined,
	positionsByContent?: ModelTypes["PositionLocale"] | undefined,
	positionCategoriesByRoot?: ModelTypes["PositionCategoryLocale"] | undefined,
	authorsByRoot?: ModelTypes["AuthorLocale"] | undefined,
	blogPostsByRoot?: ModelTypes["BlogPostLocale"] | undefined,
	blogPostsByContent?: ModelTypes["BlogPostLocale"] | undefined,
	blogPostsBySeo?: ModelTypes["BlogPostLocale"] | undefined,
	blogPostsByLink?: ModelTypes["BlogPostLocale"] | undefined,
	blogPostCommonByRoot?: ModelTypes["BlogPostCommonLocale"] | undefined,
	paginateGeneral: ModelTypes["GeneralLocaleConnection"],
	paginateGenericPages: ModelTypes["GenericPageLocaleConnection"],
	paginateHeaders: ModelTypes["HeaderLocaleConnection"],
	paginateFooters: ModelTypes["FooterLocaleConnection"],
	paginateCaseStudies: ModelTypes["CaseStudyLocaleConnection"],
	paginateTags: ModelTypes["TagLocaleConnection"],
	paginatePositionTags: ModelTypes["PositionTagLocaleConnection"],
	paginatePositions: ModelTypes["PositionLocaleConnection"],
	paginatePositionCategories: ModelTypes["PositionCategoryLocaleConnection"],
	paginateAuthors: ModelTypes["AuthorLocaleConnection"],
	paginateBlogPosts: ModelTypes["BlogPostLocaleConnection"],
	paginateBlogPostCommon: ModelTypes["BlogPostCommonLocaleConnection"]
};
	["LocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	code?: ModelTypes["FieldMeta"] | undefined,
	label?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	general?: ModelTypes["FieldMeta"] | undefined,
	genericPages?: ModelTypes["FieldMeta"] | undefined,
	headers?: ModelTypes["FieldMeta"] | undefined,
	footers?: ModelTypes["FieldMeta"] | undefined,
	caseStudies?: ModelTypes["FieldMeta"] | undefined,
	tags?: ModelTypes["FieldMeta"] | undefined,
	positionTags?: ModelTypes["FieldMeta"] | undefined,
	positions?: ModelTypes["FieldMeta"] | undefined,
	positionCategories?: ModelTypes["FieldMeta"] | undefined,
	authors?: ModelTypes["FieldMeta"] | undefined,
	blogPosts?: ModelTypes["FieldMeta"] | undefined,
	blogPostCommon?: ModelTypes["FieldMeta"] | undefined
};
	["GeneralLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["GeneralOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	seo?: ModelTypes["SeoOrderBy"] | undefined,
	homeLinkable?: ModelTypes["LinkableOrderBy"] | undefined,
	caseStudiesLink?: ModelTypes["LinkOrderBy"] | undefined,
	positionsLink?: ModelTypes["LinkOrderBy"] | undefined,
	facebookUrl?: ModelTypes["OrderDirection"] | undefined,
	twitterUrl?: ModelTypes["OrderDirection"] | undefined,
	instagramUrl?: ModelTypes["OrderDirection"] | undefined,
	youtubeUrl?: ModelTypes["OrderDirection"] | undefined,
	linkedinUrl?: ModelTypes["OrderDirection"] | undefined,
	formPrivacyPolicy?: ModelTypes["OrderDirection"] | undefined,
	formMarketingConsent?: ModelTypes["OrderDirection"] | undefined,
	acceptButtonLabel?: ModelTypes["OrderDirection"] | undefined,
	rejectButtonLabel?: ModelTypes["OrderDirection"] | undefined,
	content?: ModelTypes["ContentOrderBy"] | undefined
};
	["OrderDirection"]:OrderDirection;
	["GeneralOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	unique?: ModelTypes["OrderDirection"] | undefined
};
	["LocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	code?: ModelTypes["OrderDirection"] | undefined,
	label?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined
};
	["SeoOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	description?: ModelTypes["OrderDirection"] | undefined,
	ogTitle?: ModelTypes["OrderDirection"] | undefined,
	ogDescription?: ModelTypes["OrderDirection"] | undefined,
	ogImage?: ModelTypes["ImageOrderBy"] | undefined,
	noIndex?: ModelTypes["OrderDirection"] | undefined,
	noFollow?: ModelTypes["OrderDirection"] | undefined
};
	["ImageOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	url?: ModelTypes["OrderDirection"] | undefined,
	width?: ModelTypes["OrderDirection"] | undefined,
	height?: ModelTypes["OrderDirection"] | undefined,
	size?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	alt?: ModelTypes["OrderDirection"] | undefined,
	fileName?: ModelTypes["OrderDirection"] | undefined
};
	["LinkableOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	url?: ModelTypes["OrderDirection"] | undefined,
	genericPage?: ModelTypes["GenericPageLocaleOrderBy"] | undefined,
	caseStudy?: ModelTypes["CaseStudyLocaleOrderBy"] | undefined,
	position?: ModelTypes["PositionLocaleOrderBy"] | undefined,
	redirect?: ModelTypes["RedirectOrderBy"] | undefined,
	blogPost?: ModelTypes["BlogPostLocaleOrderBy"] | undefined
};
	["GenericPageLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["GenericPageOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	content?: ModelTypes["ContentOrderBy"] | undefined,
	link?: ModelTypes["LinkableOrderBy"] | undefined,
	seo?: ModelTypes["SeoOrderBy"] | undefined
};
	["GenericPageOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	publishedAt?: ModelTypes["OrderDirection"] | undefined
};
	["ContentOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["CaseStudyLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["CaseStudyOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	content?: ModelTypes["ContentOrderBy"] | undefined,
	link?: ModelTypes["LinkableOrderBy"] | undefined,
	seo?: ModelTypes["SeoOrderBy"] | undefined,
	note?: ModelTypes["OrderDirection"] | undefined
};
	["CaseStudyOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	wideImage?: ModelTypes["ImageOrderBy"] | undefined,
	narrowImage?: ModelTypes["ImageOrderBy"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	publishedAt?: ModelTypes["OrderDirection"] | undefined
};
	["PositionLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	link?: ModelTypes["LinkableOrderBy"] | undefined,
	root?: ModelTypes["PositionOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	seo?: ModelTypes["SeoOrderBy"] | undefined,
	content?: ModelTypes["ContentOrderBy"] | undefined,
	summary?: ModelTypes["OrderDirection"] | undefined,
	salaryRange?: ModelTypes["OrderDirection"] | undefined
};
	["PositionOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	tags?: ModelTypes["PositionTagListOrderBy"] | undefined,
	orderInCategory?: ModelTypes["OrderDirection"] | undefined,
	category?: ModelTypes["PositionCategoryOrderBy"] | undefined,
	publishedAt?: ModelTypes["OrderDirection"] | undefined
};
	["PositionTagListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["PositionCategoryOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined
};
	["RedirectOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	link?: ModelTypes["LinkableOrderBy"] | undefined,
	target?: ModelTypes["LinkOrderBy"] | undefined
};
	["LinkOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	externalLink?: ModelTypes["OrderDirection"] | undefined,
	internalLink?: ModelTypes["LinkableOrderBy"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	isTargetBlank?: ModelTypes["OrderDirection"] | undefined
};
	["BlogPostLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["BlogPostOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	content?: ModelTypes["ContentOrderBy"] | undefined,
	seo?: ModelTypes["SeoOrderBy"] | undefined,
	link?: ModelTypes["LinkableOrderBy"] | undefined,
	lead?: ModelTypes["OrderDirection"] | undefined
};
	["BlogPostOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	publishedAt?: ModelTypes["OrderDirection"] | undefined,
	author?: ModelTypes["AuthorOrderBy"] | undefined,
	wideImage?: ModelTypes["ImageOrderBy"] | undefined,
	narrowImage?: ModelTypes["ImageOrderBy"] | undefined
};
	["AuthorOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	name?: ModelTypes["OrderDirection"] | undefined,
	profileUrl?: ModelTypes["OrderDirection"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined,
	avatarImage?: ModelTypes["ImageOrderBy"] | undefined
};
	["GenericPageLocale"]: {
		_meta?: ModelTypes["GenericPageLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	root?: ModelTypes["GenericPage"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	content?: ModelTypes["Content"] | undefined,
	link?: ModelTypes["Linkable"] | undefined,
	seo?: ModelTypes["Seo"] | undefined
};
	["GenericPageLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	seo?: ModelTypes["FieldMeta"] | undefined
};
	["GenericPage"]: {
		_meta?: ModelTypes["GenericPageMeta"] | undefined,
	id: ModelTypes["UUID"],
	locales: Array<ModelTypes["GenericPageLocale"]>,
	publishedAt?: ModelTypes["DateTime"] | undefined,
	localesByLocale?: ModelTypes["GenericPageLocale"] | undefined,
	localesByContent?: ModelTypes["GenericPageLocale"] | undefined,
	localesByLink?: ModelTypes["GenericPageLocale"] | undefined,
	localesBySeo?: ModelTypes["GenericPageLocale"] | undefined,
	paginateLocales: ModelTypes["GenericPageLocaleConnection"]
};
	["GenericPageMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	publishedAt?: ModelTypes["FieldMeta"] | undefined
};
	["GenericPageLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["LocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	code?: string | undefined,
	general?: ModelTypes["GeneralLocaleUniqueWhere"] | undefined,
	genericPages?: ModelTypes["GenericPageLocaleUniqueWhere"] | undefined,
	headers?: ModelTypes["HeaderLocaleUniqueWhere"] | undefined,
	footers?: ModelTypes["FooterLocaleUniqueWhere"] | undefined,
	caseStudies?: ModelTypes["CaseStudyLocaleUniqueWhere"] | undefined,
	tags?: ModelTypes["TagLocaleUniqueWhere"] | undefined,
	positionTags?: ModelTypes["PositionTagLocaleUniqueWhere"] | undefined,
	positions?: ModelTypes["PositionLocaleUniqueWhere"] | undefined,
	positionCategories?: ModelTypes["PositionCategoryLocaleUniqueWhere"] | undefined,
	authors?: ModelTypes["AuthorLocaleUniqueWhere"] | undefined,
	blogPosts?: ModelTypes["BlogPostLocaleUniqueWhere"] | undefined,
	blogPostCommon?: ModelTypes["BlogPostCommonLocaleUniqueWhere"] | undefined
};
	["GeneralLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["GeneralUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	seo?: ModelTypes["SeoUniqueWhere"] | undefined,
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["GeneralUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	unique?: ModelTypes["One"] | undefined,
	locales?: ModelTypes["GeneralLocaleUniqueWhere"] | undefined
};
	["SeoUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	ogImage?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["ImageUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["ContentUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	blocks?: ModelTypes["ContentBlockUniqueWhere"] | undefined
};
	["ContentBlockUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	references?: ModelTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentReferenceUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	link?: ModelTypes["LinkUniqueWhere"] | undefined,
	featuredCaseStudies?: ModelTypes["CaseStudyListUniqueWhere"] | undefined,
	images?: ModelTypes["ImageListUniqueWhere"] | undefined,
	otherImages?: ModelTypes["ImageListUniqueWhere"] | undefined,
	informationList?: ModelTypes["InformationListUniqueWhere"] | undefined,
	embed?: ModelTypes["EmbedUniqueWhere"] | undefined,
	links?: ModelTypes["LinkListUniqueWhere"] | undefined,
	talkLists?: ModelTypes["TalkListsUniqueWhere"] | undefined,
	businessCardList?: ModelTypes["BusinessCardListUniqueWhere"] | undefined,
	testimonials?: ModelTypes["TestimonialListUniqueWhere"] | undefined,
	contactRequestOptions?: ModelTypes["ContactRequestOptionListUniqueWhere"] | undefined,
	youtubeVideo?: ModelTypes["YoutubeVideoUniqueWhere"] | undefined,
	positionCategories?: ModelTypes["ContentReferencePositionCategoryItemUniqueWhere"] | undefined,
	caseStudies?: ModelTypes["ContentReferenceCaseStudyItemUniqueWhere"] | undefined,
	tags?: ModelTypes["ContentReferenceTagItemUniqueWhere"] | undefined,
	positions?: ModelTypes["PositionListUniqueWhere"] | undefined,
	testimonialsFull?: ModelTypes["TestimonialFullListUniqueWhere"] | undefined,
	blogPosts?: ModelTypes["ContentReferenceBlogPostItemUniqueWhere"] | undefined,
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["LinkUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["CaseStudyListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["CaseStudyListItemUniqueWhere"] | undefined
};
	["CaseStudyListItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["ImageListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["ImageListItemUniqueWhere"] | undefined
};
	["ImageListItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["InformationListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["PieceOfInformationUniqueWhere"] | undefined
};
	["PieceOfInformationUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["EmbedUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["LinkListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["LinkListItemUniqueWhere"] | undefined
};
	["LinkListItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	link?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["TalkListsUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["TalkListUniqueWhere"] | undefined
};
	["TalkListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["TalkListItemUniqueWhere"] | undefined
};
	["TalkListItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["BusinessCardListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["BusinessCardListItemUniqueWhere"] | undefined
};
	["BusinessCardListItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	businessCard?: ModelTypes["BusinessCardUniqueWhere"] | undefined
};
	["BusinessCardUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["TestimonialListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["TestimonialListItemUniqueWhere"] | undefined
};
	["TestimonialListItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	testimonial?: ModelTypes["TestimonialUniqueWhere"] | undefined
};
	["TestimonialUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["ContactRequestOptionListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["ContactRequestOptionUniqueWhere"] | undefined
};
	["ContactRequestOptionUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["YoutubeVideoUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["ContentReferencePositionCategoryItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	item?: ModelTypes["PositionCategoryUniqueWhere"] | undefined
};
	["PositionCategoryUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	positions?: ModelTypes["PositionUniqueWhere"] | undefined,
	locales?: ModelTypes["PositionCategoryLocaleUniqueWhere"] | undefined
};
	["PositionUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	tags?: ModelTypes["PositionTagListUniqueWhere"] | undefined,
	locales?: ModelTypes["PositionLocaleUniqueWhere"] | undefined
};
	["PositionTagListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["PositionTagListItemUniqueWhere"] | undefined
};
	["PositionTagListItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["PositionLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["PositionUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	link?: ModelTypes["LinkableUniqueWhere"] | undefined,
	seo?: ModelTypes["SeoUniqueWhere"] | undefined,
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["LinkableUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	url?: string | undefined,
	genericPage?: ModelTypes["GenericPageLocaleUniqueWhere"] | undefined,
	caseStudy?: ModelTypes["CaseStudyLocaleUniqueWhere"] | undefined,
	position?: ModelTypes["PositionLocaleUniqueWhere"] | undefined,
	redirect?: ModelTypes["RedirectUniqueWhere"] | undefined,
	blogPost?: ModelTypes["BlogPostLocaleUniqueWhere"] | undefined
};
	["GenericPageLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["GenericPageUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	content?: ModelTypes["ContentUniqueWhere"] | undefined,
	link?: ModelTypes["LinkableUniqueWhere"] | undefined,
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["GenericPageUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	locales?: ModelTypes["GenericPageLocaleUniqueWhere"] | undefined
};
	["CaseStudyLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["CaseStudyUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	content?: ModelTypes["ContentUniqueWhere"] | undefined,
	link?: ModelTypes["LinkableUniqueWhere"] | undefined,
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["CaseStudyUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	locales?: ModelTypes["CaseStudyLocaleUniqueWhere"] | undefined,
	tags?: ModelTypes["CaseStudyTagUniqueWhere"] | undefined
};
	["CaseStudyTagUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["RedirectUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	link?: ModelTypes["LinkableUniqueWhere"] | undefined,
	target?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["BlogPostLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["BlogPostUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	content?: ModelTypes["ContentUniqueWhere"] | undefined,
	seo?: ModelTypes["SeoUniqueWhere"] | undefined,
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["BlogPostUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	locales?: ModelTypes["BlogPostLocaleUniqueWhere"] | undefined,
	relevantBlogPosts?: ModelTypes["RelevantBlogPostListUniqueWhere"] | undefined
};
	["RelevantBlogPostListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	blogPost?: ModelTypes["BlogPostUniqueWhere"] | undefined,
	relevantBlogPost?: ModelTypes["BlogPostUniqueWhere"] | undefined
};
	["PositionCategoryLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["PositionCategoryUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["ContentReferenceCaseStudyItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	item?: ModelTypes["CaseStudyUniqueWhere"] | undefined
};
	["ContentReferenceTagItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	item?: ModelTypes["TagUniqueWhere"] | undefined
};
	["TagUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	locales?: ModelTypes["TagLocaleUniqueWhere"] | undefined,
	caseStudyTags?: ModelTypes["CaseStudyTagUniqueWhere"] | undefined
};
	["TagLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	slug?: string | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	root?: ModelTypes["TagUniqueWhere"] | undefined
};
	["PositionListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["PositionListItemUniqueWhere"] | undefined
};
	["PositionListItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["TestimonialFullListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["TestimonialFullListItemUniqueWhere"] | undefined
};
	["TestimonialFullListItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	testimonial?: ModelTypes["TestimonialFullUniqueWhere"] | undefined
};
	["TestimonialFullUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["ContentReferenceBlogPostItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	item?: ModelTypes["BlogPostUniqueWhere"] | undefined
};
	["HeaderLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["HeaderUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	linkList?: ModelTypes["LinkListUniqueWhere"] | undefined,
	socialLinkList?: ModelTypes["LinkListUniqueWhere"] | undefined,
	topLevelLinkItems?: ModelTypes["TopLevelLinkItemUniqueWhere"] | undefined
};
	["HeaderUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	unique?: ModelTypes["One"] | undefined,
	locales?: ModelTypes["HeaderLocaleUniqueWhere"] | undefined
};
	["TopLevelLinkItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	secondLevelLinkItems?: ModelTypes["SecondLevelLinkItemUniqueWhere"] | undefined,
	link?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["SecondLevelLinkItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	link?: ModelTypes["LinkUniqueWhere"] | undefined,
	thirdLevelLinkItems?: ModelTypes["ThirdLevelLinkItemUniqueWhere"] | undefined
};
	["ThirdLevelLinkItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	link?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["FooterLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["FooterUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	navigation?: ModelTypes["LinkListUniqueWhere"] | undefined
};
	["FooterUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	unique?: ModelTypes["One"] | undefined,
	locales?: ModelTypes["FooterLocaleUniqueWhere"] | undefined
};
	["PositionTagLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	slug?: string | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	root?: ModelTypes["PositionTagUniqueWhere"] | undefined
};
	["PositionTagUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	locales?: ModelTypes["PositionTagLocaleUniqueWhere"] | undefined
};
	["AuthorLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["AuthorUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["AuthorUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	locales?: ModelTypes["AuthorLocaleUniqueWhere"] | undefined,
	blogPosts?: ModelTypes["BlogPostUniqueWhere"] | undefined
};
	["BlogPostCommonLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["BlogPostCommonUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["BlogPostCommonUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	unique?: ModelTypes["One"] | undefined,
	locales?: ModelTypes["BlogPostCommonLocaleUniqueWhere"] | undefined
};
	["GenericPageLocalesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["GenericPageLocalesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["GenericPageLocalesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["GenericPageLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["GenericPageLocaleEdge"]>
};
	["PageInfo"]: {
		totalCount: number
};
	["GenericPageLocaleEdge"]: {
		node: ModelTypes["GenericPageLocale"]
};
	["Content"]: {
		_meta?: ModelTypes["ContentMeta"] | undefined,
	id: ModelTypes["UUID"],
	blocks: Array<ModelTypes["ContentBlock"]>,
	blocksByReferences?: ModelTypes["ContentBlock"] | undefined,
	paginateBlocks: ModelTypes["ContentBlockConnection"]
};
	["ContentMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	blocks?: ModelTypes["FieldMeta"] | undefined
};
	["ContentBlock"]: {
		_meta?: ModelTypes["ContentBlockMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	json: string,
	content?: ModelTypes["Content"] | undefined,
	references: Array<ModelTypes["ContentReference"]>,
	referencesByLink?: ModelTypes["ContentReference"] | undefined,
	referencesByFeaturedCaseStudies?: ModelTypes["ContentReference"] | undefined,
	referencesByImages?: ModelTypes["ContentReference"] | undefined,
	referencesByOtherImages?: ModelTypes["ContentReference"] | undefined,
	referencesByInformationList?: ModelTypes["ContentReference"] | undefined,
	referencesByEmbed?: ModelTypes["ContentReference"] | undefined,
	referencesByLinks?: ModelTypes["ContentReference"] | undefined,
	referencesByTalkLists?: ModelTypes["ContentReference"] | undefined,
	referencesByBusinessCardList?: ModelTypes["ContentReference"] | undefined,
	referencesByTestimonials?: ModelTypes["ContentReference"] | undefined,
	referencesByContactRequestOptions?: ModelTypes["ContentReference"] | undefined,
	referencesByYoutubeVideo?: ModelTypes["ContentReference"] | undefined,
	referencesByPositionCategories?: ModelTypes["ContentReference"] | undefined,
	referencesByCaseStudies?: ModelTypes["ContentReference"] | undefined,
	referencesByTags?: ModelTypes["ContentReference"] | undefined,
	referencesByPositions?: ModelTypes["ContentReference"] | undefined,
	referencesByTestimonialsFull?: ModelTypes["ContentReference"] | undefined,
	referencesByBlogPosts?: ModelTypes["ContentReference"] | undefined,
	referencesByContent?: ModelTypes["ContentReference"] | undefined,
	paginateReferences: ModelTypes["ContentReferenceConnection"]
};
	["ContentBlockMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	json?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined,
	references?: ModelTypes["FieldMeta"] | undefined
};
	["ContentReference"]: {
		_meta?: ModelTypes["ContentReferenceMeta"] | undefined,
	id: ModelTypes["UUID"],
	type: ModelTypes["ContentReferenceType"],
	primaryText?: string | undefined,
	block?: ModelTypes["ContentBlock"] | undefined,
	image?: ModelTypes["Image"] | undefined,
	secondaryText?: string | undefined,
	link?: ModelTypes["Link"] | undefined,
	featuredCaseStudies?: ModelTypes["CaseStudyList"] | undefined,
	images?: ModelTypes["ImageList"] | undefined,
	otherImages?: ModelTypes["ImageList"] | undefined,
	informationList?: ModelTypes["InformationList"] | undefined,
	tertiaryText?: string | undefined,
	embed?: ModelTypes["Embed"] | undefined,
	links?: ModelTypes["LinkList"] | undefined,
	talkLists?: ModelTypes["TalkLists"] | undefined,
	businessCardList?: ModelTypes["BusinessCardList"] | undefined,
	testimonials?: ModelTypes["TestimonialList"] | undefined,
	background: ModelTypes["ContentBackgroundType"],
	otherImage?: ModelTypes["Image"] | undefined,
	caseStudy?: ModelTypes["CaseStudy"] | undefined,
	alternative: boolean,
	contactRequestOptions?: ModelTypes["ContactRequestOptionList"] | undefined,
	youtubeVideo?: ModelTypes["YoutubeVideo"] | undefined,
	subscribeForm?: ModelTypes["SubscribeForm"] | undefined,
	video?: ModelTypes["Video"] | undefined,
	otherVideo?: ModelTypes["Video"] | undefined,
	positionCategories: Array<ModelTypes["ContentReferencePositionCategoryItem"]>,
	caseStudies: Array<ModelTypes["ContentReferenceCaseStudyItem"]>,
	tags: Array<ModelTypes["ContentReferenceTagItem"]>,
	positions?: ModelTypes["PositionList"] | undefined,
	testimonialsFull?: ModelTypes["TestimonialFullList"] | undefined,
	blogPosts: Array<ModelTypes["ContentReferenceBlogPostItem"]>,
	verticalMargin: ModelTypes["ContentVerticalMargin"],
	variant?: string | undefined,
	content?: ModelTypes["Content"] | undefined,
	positionCategoriesByItem?: ModelTypes["ContentReferencePositionCategoryItem"] | undefined,
	caseStudiesByItem?: ModelTypes["ContentReferenceCaseStudyItem"] | undefined,
	tagsByItem?: ModelTypes["ContentReferenceTagItem"] | undefined,
	blogPostsByItem?: ModelTypes["ContentReferenceBlogPostItem"] | undefined,
	paginatePositionCategories: ModelTypes["ContentReferencePositionCategoryItemConnection"],
	paginateCaseStudies: ModelTypes["ContentReferenceCaseStudyItemConnection"],
	paginateTags: ModelTypes["ContentReferenceTagItemConnection"],
	paginateBlogPosts: ModelTypes["ContentReferenceBlogPostItemConnection"]
};
	["ContentReferenceMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	primaryText?: ModelTypes["FieldMeta"] | undefined,
	block?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined,
	secondaryText?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	featuredCaseStudies?: ModelTypes["FieldMeta"] | undefined,
	images?: ModelTypes["FieldMeta"] | undefined,
	otherImages?: ModelTypes["FieldMeta"] | undefined,
	informationList?: ModelTypes["FieldMeta"] | undefined,
	tertiaryText?: ModelTypes["FieldMeta"] | undefined,
	embed?: ModelTypes["FieldMeta"] | undefined,
	links?: ModelTypes["FieldMeta"] | undefined,
	talkLists?: ModelTypes["FieldMeta"] | undefined,
	businessCardList?: ModelTypes["FieldMeta"] | undefined,
	testimonials?: ModelTypes["FieldMeta"] | undefined,
	background?: ModelTypes["FieldMeta"] | undefined,
	otherImage?: ModelTypes["FieldMeta"] | undefined,
	caseStudy?: ModelTypes["FieldMeta"] | undefined,
	alternative?: ModelTypes["FieldMeta"] | undefined,
	contactRequestOptions?: ModelTypes["FieldMeta"] | undefined,
	youtubeVideo?: ModelTypes["FieldMeta"] | undefined,
	subscribeForm?: ModelTypes["FieldMeta"] | undefined,
	video?: ModelTypes["FieldMeta"] | undefined,
	otherVideo?: ModelTypes["FieldMeta"] | undefined,
	positionCategories?: ModelTypes["FieldMeta"] | undefined,
	caseStudies?: ModelTypes["FieldMeta"] | undefined,
	tags?: ModelTypes["FieldMeta"] | undefined,
	positions?: ModelTypes["FieldMeta"] | undefined,
	testimonialsFull?: ModelTypes["FieldMeta"] | undefined,
	blogPosts?: ModelTypes["FieldMeta"] | undefined,
	verticalMargin?: ModelTypes["FieldMeta"] | undefined,
	variant?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined
};
	["Image"]: {
		_meta?: ModelTypes["ImageMeta"] | undefined,
	id: ModelTypes["UUID"],
	url: string,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	alt?: string | undefined,
	fileName?: string | undefined
};
	["ImageMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	url?: ModelTypes["FieldMeta"] | undefined,
	width?: ModelTypes["FieldMeta"] | undefined,
	height?: ModelTypes["FieldMeta"] | undefined,
	size?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	alt?: ModelTypes["FieldMeta"] | undefined,
	fileName?: ModelTypes["FieldMeta"] | undefined
};
	["Link"]: {
		_meta?: ModelTypes["LinkMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	externalLink?: string | undefined,
	internalLink?: ModelTypes["Linkable"] | undefined,
	type: ModelTypes["LinkType"],
	isTargetBlank: boolean
};
	["LinkMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	externalLink?: ModelTypes["FieldMeta"] | undefined,
	internalLink?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	isTargetBlank?: ModelTypes["FieldMeta"] | undefined
};
	["Linkable"]: {
		_meta?: ModelTypes["LinkableMeta"] | undefined,
	id: ModelTypes["UUID"],
	url: string,
	genericPage?: ModelTypes["GenericPageLocale"] | undefined,
	caseStudy?: ModelTypes["CaseStudyLocale"] | undefined,
	position?: ModelTypes["PositionLocale"] | undefined,
	redirect?: ModelTypes["Redirect"] | undefined,
	blogPost?: ModelTypes["BlogPostLocale"] | undefined
};
	["LinkableMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	url?: ModelTypes["FieldMeta"] | undefined,
	genericPage?: ModelTypes["FieldMeta"] | undefined,
	caseStudy?: ModelTypes["FieldMeta"] | undefined,
	position?: ModelTypes["FieldMeta"] | undefined,
	redirect?: ModelTypes["FieldMeta"] | undefined,
	blogPost?: ModelTypes["FieldMeta"] | undefined
};
	["CaseStudyLocale"]: {
		_meta?: ModelTypes["CaseStudyLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	root?: ModelTypes["CaseStudy"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	content?: ModelTypes["Content"] | undefined,
	link?: ModelTypes["Linkable"] | undefined,
	seo?: ModelTypes["Seo"] | undefined,
	note?: string | undefined
};
	["CaseStudyLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	seo?: ModelTypes["FieldMeta"] | undefined,
	note?: ModelTypes["FieldMeta"] | undefined
};
	["CaseStudy"]: {
		_meta?: ModelTypes["CaseStudyMeta"] | undefined,
	id: ModelTypes["UUID"],
	locales: Array<ModelTypes["CaseStudyLocale"]>,
	tags: Array<ModelTypes["CaseStudyTag"]>,
	wideImage?: ModelTypes["Image"] | undefined,
	narrowImage?: ModelTypes["Image"] | undefined,
	order: number,
	publishedAt?: ModelTypes["DateTime"] | undefined,
	localesByLocale?: ModelTypes["CaseStudyLocale"] | undefined,
	localesByContent?: ModelTypes["CaseStudyLocale"] | undefined,
	localesByLink?: ModelTypes["CaseStudyLocale"] | undefined,
	localesBySeo?: ModelTypes["CaseStudyLocale"] | undefined,
	paginateLocales: ModelTypes["CaseStudyLocaleConnection"],
	paginateTags: ModelTypes["CaseStudyTagConnection"]
};
	["CaseStudyMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	tags?: ModelTypes["FieldMeta"] | undefined,
	wideImage?: ModelTypes["FieldMeta"] | undefined,
	narrowImage?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	publishedAt?: ModelTypes["FieldMeta"] | undefined
};
	["CaseStudyTag"]: {
		_meta?: ModelTypes["CaseStudyTagMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	caseStudy?: ModelTypes["CaseStudy"] | undefined,
	tag?: ModelTypes["Tag"] | undefined
};
	["CaseStudyTagMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	caseStudy?: ModelTypes["FieldMeta"] | undefined,
	tag?: ModelTypes["FieldMeta"] | undefined
};
	["Tag"]: {
		_meta?: ModelTypes["TagMeta"] | undefined,
	id: ModelTypes["UUID"],
	locales: Array<ModelTypes["TagLocale"]>,
	caseStudyTags: Array<ModelTypes["CaseStudyTag"]>,
	order: number,
	localesByLocale?: ModelTypes["TagLocale"] | undefined,
	paginateLocales: ModelTypes["TagLocaleConnection"],
	paginateCaseStudyTags: ModelTypes["CaseStudyTagConnection"]
};
	["TagMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	caseStudyTags?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined
};
	["TagLocale"]: {
		_meta?: ModelTypes["TagLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	slug: string,
	title?: string | undefined,
	root?: ModelTypes["Tag"] | undefined,
	locale?: ModelTypes["Locale"] | undefined
};
	["TagLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	slug?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined
};
	["TagLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	slug?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["TagOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined
};
	["TagOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined
};
	["CaseStudyTagOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	caseStudy?: ModelTypes["CaseStudyOrderBy"] | undefined,
	tag?: ModelTypes["TagOrderBy"] | undefined
};
	["TagLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["TagLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TagLocaleEdge"]>
};
	["TagLocaleEdge"]: {
		node: ModelTypes["TagLocale"]
};
	["CaseStudyTagConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["CaseStudyTagEdge"]>
};
	["CaseStudyTagEdge"]: {
		node: ModelTypes["CaseStudyTag"]
};
	["CaseStudyLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["CaseStudyLocalesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["CaseStudyLocalesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["CaseStudyLocalesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["CaseStudyLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["CaseStudyLocaleEdge"]>
};
	["CaseStudyLocaleEdge"]: {
		node: ModelTypes["CaseStudyLocale"]
};
	["Seo"]: {
		_meta?: ModelTypes["SeoMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	description?: string | undefined,
	ogTitle?: string | undefined,
	ogDescription?: string | undefined,
	ogImage?: ModelTypes["Image"] | undefined,
	noIndex?: boolean | undefined,
	noFollow?: boolean | undefined
};
	["SeoMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	description?: ModelTypes["FieldMeta"] | undefined,
	ogTitle?: ModelTypes["FieldMeta"] | undefined,
	ogDescription?: ModelTypes["FieldMeta"] | undefined,
	ogImage?: ModelTypes["FieldMeta"] | undefined,
	noIndex?: ModelTypes["FieldMeta"] | undefined,
	noFollow?: ModelTypes["FieldMeta"] | undefined
};
	["PositionLocale"]: {
		_meta?: ModelTypes["PositionLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	link?: ModelTypes["Linkable"] | undefined,
	root?: ModelTypes["Position"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	seo?: ModelTypes["Seo"] | undefined,
	content?: ModelTypes["Content"] | undefined,
	summary?: string | undefined,
	salaryRange?: string | undefined
};
	["PositionLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	seo?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined,
	summary?: ModelTypes["FieldMeta"] | undefined,
	salaryRange?: ModelTypes["FieldMeta"] | undefined
};
	["Position"]: {
		_meta?: ModelTypes["PositionMeta"] | undefined,
	id: ModelTypes["UUID"],
	tags?: ModelTypes["PositionTagList"] | undefined,
	locales: Array<ModelTypes["PositionLocale"]>,
	orderInCategory: number,
	category?: ModelTypes["PositionCategory"] | undefined,
	publishedAt?: ModelTypes["DateTime"] | undefined,
	localesByLocale?: ModelTypes["PositionLocale"] | undefined,
	localesByLink?: ModelTypes["PositionLocale"] | undefined,
	localesBySeo?: ModelTypes["PositionLocale"] | undefined,
	localesByContent?: ModelTypes["PositionLocale"] | undefined,
	paginateLocales: ModelTypes["PositionLocaleConnection"]
};
	["PositionMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	tags?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	orderInCategory?: ModelTypes["FieldMeta"] | undefined,
	category?: ModelTypes["FieldMeta"] | undefined,
	publishedAt?: ModelTypes["FieldMeta"] | undefined
};
	["PositionTagList"]: {
		_meta?: ModelTypes["PositionTagListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["PositionTagListItem"]>,
	paginateItems: ModelTypes["PositionTagListItemConnection"]
};
	["PositionTagListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["PositionTagListItem"]: {
		_meta?: ModelTypes["PositionTagListItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	list?: ModelTypes["PositionTagList"] | undefined,
	tag?: ModelTypes["PositionTag"] | undefined
};
	["PositionTagListItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	tag?: ModelTypes["FieldMeta"] | undefined
};
	["PositionTag"]: {
		_meta?: ModelTypes["PositionTagMeta"] | undefined,
	id: ModelTypes["UUID"],
	locales: Array<ModelTypes["PositionTagLocale"]>,
	localesByLocale?: ModelTypes["PositionTagLocale"] | undefined,
	paginateLocales: ModelTypes["PositionTagLocaleConnection"]
};
	["PositionTagMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined
};
	["PositionTagLocale"]: {
		_meta?: ModelTypes["PositionTagLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	slug: string,
	title?: string | undefined,
	root?: ModelTypes["PositionTag"] | undefined,
	locale?: ModelTypes["Locale"] | undefined
};
	["PositionTagLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	slug?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined
};
	["PositionTagLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	slug?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["PositionTagOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined
};
	["PositionTagOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["PositionTagLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["PositionTagLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["PositionTagLocaleEdge"]>
};
	["PositionTagLocaleEdge"]: {
		node: ModelTypes["PositionTagLocale"]
};
	["PositionTagListItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["PositionTagListOrderBy"] | undefined,
	tag?: ModelTypes["PositionTagOrderBy"] | undefined
};
	["PositionTagListItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["PositionTagListItemEdge"]>
};
	["PositionTagListItemEdge"]: {
		node: ModelTypes["PositionTagListItem"]
};
	["PositionCategory"]: {
		_meta?: ModelTypes["PositionCategoryMeta"] | undefined,
	id: ModelTypes["UUID"],
	positions: Array<ModelTypes["Position"]>,
	locales: Array<ModelTypes["PositionCategoryLocale"]>,
	order: number,
	positionsByTags?: ModelTypes["Position"] | undefined,
	positionsByLocales?: ModelTypes["Position"] | undefined,
	localesByLocale?: ModelTypes["PositionCategoryLocale"] | undefined,
	paginatePositions: ModelTypes["PositionConnection"],
	paginateLocales: ModelTypes["PositionCategoryLocaleConnection"]
};
	["PositionCategoryMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	positions?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined
};
	["PositionCategoryLocale"]: {
		_meta?: ModelTypes["PositionCategoryLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	root?: ModelTypes["PositionCategory"] | undefined,
	locale?: ModelTypes["Locale"] | undefined
};
	["PositionCategoryLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined
};
	["PositionCategoryLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["PositionCategoryOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined
};
	["PositionCategoryPositionsByTagsUniqueWhere"]: {
	tags?: ModelTypes["PositionTagListUniqueWhere"] | undefined
};
	["PositionCategoryPositionsByLocalesUniqueWhere"]: {
	locales?: ModelTypes["PositionLocaleUniqueWhere"] | undefined
};
	["PositionCategoryLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["PositionConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["PositionEdge"]>
};
	["PositionEdge"]: {
		node: ModelTypes["Position"]
};
	["PositionCategoryLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["PositionCategoryLocaleEdge"]>
};
	["PositionCategoryLocaleEdge"]: {
		node: ModelTypes["PositionCategoryLocale"]
};
	["PositionLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["PositionLocalesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["PositionLocalesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["PositionLocalesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["PositionLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["PositionLocaleEdge"]>
};
	["PositionLocaleEdge"]: {
		node: ModelTypes["PositionLocale"]
};
	["Redirect"]: {
		_meta?: ModelTypes["RedirectMeta"] | undefined,
	id: ModelTypes["UUID"],
	link?: ModelTypes["Linkable"] | undefined,
	target?: ModelTypes["Link"] | undefined
};
	["RedirectMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	target?: ModelTypes["FieldMeta"] | undefined
};
	["BlogPostLocale"]: {
		_meta?: ModelTypes["BlogPostLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	title: string,
	root?: ModelTypes["BlogPost"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	content?: ModelTypes["Content"] | undefined,
	seo?: ModelTypes["Seo"] | undefined,
	link?: ModelTypes["Linkable"] | undefined,
	lead?: string | undefined
};
	["BlogPostLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined,
	seo?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	lead?: ModelTypes["FieldMeta"] | undefined
};
	["BlogPost"]: {
		_meta?: ModelTypes["BlogPostMeta"] | undefined,
	id: ModelTypes["UUID"],
	publishedAt?: ModelTypes["DateTime"] | undefined,
	author?: ModelTypes["Author"] | undefined,
	wideImage?: ModelTypes["Image"] | undefined,
	narrowImage?: ModelTypes["Image"] | undefined,
	locales: Array<ModelTypes["BlogPostLocale"]>,
	relevantBlogPosts: Array<ModelTypes["RelevantBlogPostList"]>,
	localesByLocale?: ModelTypes["BlogPostLocale"] | undefined,
	localesByContent?: ModelTypes["BlogPostLocale"] | undefined,
	localesBySeo?: ModelTypes["BlogPostLocale"] | undefined,
	localesByLink?: ModelTypes["BlogPostLocale"] | undefined,
	relevantBlogPostsByRelevantBlogPost?: ModelTypes["RelevantBlogPostList"] | undefined,
	paginateLocales: ModelTypes["BlogPostLocaleConnection"],
	paginateRelevantBlogPosts: ModelTypes["RelevantBlogPostListConnection"]
};
	["BlogPostMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	publishedAt?: ModelTypes["FieldMeta"] | undefined,
	author?: ModelTypes["FieldMeta"] | undefined,
	wideImage?: ModelTypes["FieldMeta"] | undefined,
	narrowImage?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	relevantBlogPosts?: ModelTypes["FieldMeta"] | undefined
};
	["Author"]: {
		_meta?: ModelTypes["AuthorMeta"] | undefined,
	id: ModelTypes["UUID"],
	locales: Array<ModelTypes["AuthorLocale"]>,
	blogPosts: Array<ModelTypes["BlogPost"]>,
	name: string,
	profileUrl?: string | undefined,
	image?: ModelTypes["Image"] | undefined,
	avatarImage?: ModelTypes["Image"] | undefined,
	localesByLocale?: ModelTypes["AuthorLocale"] | undefined,
	blogPostsByLocales?: ModelTypes["BlogPost"] | undefined,
	blogPostsByRelevantBlogPosts?: ModelTypes["BlogPost"] | undefined,
	paginateLocales: ModelTypes["AuthorLocaleConnection"],
	paginateBlogPosts: ModelTypes["BlogPostConnection"]
};
	["AuthorMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	blogPosts?: ModelTypes["FieldMeta"] | undefined,
	name?: ModelTypes["FieldMeta"] | undefined,
	profileUrl?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined,
	avatarImage?: ModelTypes["FieldMeta"] | undefined
};
	["AuthorLocale"]: {
		_meta?: ModelTypes["AuthorLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	role?: string | undefined,
	bio?: string | undefined,
	root?: ModelTypes["Author"] | undefined,
	locale?: ModelTypes["Locale"] | undefined
};
	["AuthorLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	role?: ModelTypes["FieldMeta"] | undefined,
	bio?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined
};
	["AuthorLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	role?: ModelTypes["OrderDirection"] | undefined,
	bio?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["AuthorOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined
};
	["AuthorLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["AuthorBlogPostsByLocalesUniqueWhere"]: {
	locales?: ModelTypes["BlogPostLocaleUniqueWhere"] | undefined
};
	["AuthorBlogPostsByRelevantBlogPostsUniqueWhere"]: {
	relevantBlogPosts?: ModelTypes["RelevantBlogPostListUniqueWhere"] | undefined
};
	["AuthorLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["AuthorLocaleEdge"]>
};
	["AuthorLocaleEdge"]: {
		node: ModelTypes["AuthorLocale"]
};
	["BlogPostConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["BlogPostEdge"]>
};
	["BlogPostEdge"]: {
		node: ModelTypes["BlogPost"]
};
	["RelevantBlogPostList"]: {
		_meta?: ModelTypes["RelevantBlogPostListMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	blogPost?: ModelTypes["BlogPost"] | undefined,
	relevantBlogPost?: ModelTypes["BlogPost"] | undefined
};
	["RelevantBlogPostListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	blogPost?: ModelTypes["FieldMeta"] | undefined,
	relevantBlogPost?: ModelTypes["FieldMeta"] | undefined
};
	["RelevantBlogPostListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	blogPost?: ModelTypes["BlogPostOrderBy"] | undefined,
	relevantBlogPost?: ModelTypes["BlogPostOrderBy"] | undefined
};
	["BlogPostLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["BlogPostLocalesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["BlogPostLocalesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["BlogPostLocalesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["BlogPostRelevantBlogPostsByRelevantBlogPostUniqueWhere"]: {
	relevantBlogPost?: ModelTypes["BlogPostUniqueWhere"] | undefined
};
	["BlogPostLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["BlogPostLocaleEdge"]>
};
	["BlogPostLocaleEdge"]: {
		node: ModelTypes["BlogPostLocale"]
};
	["RelevantBlogPostListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["RelevantBlogPostListEdge"]>
};
	["RelevantBlogPostListEdge"]: {
		node: ModelTypes["RelevantBlogPostList"]
};
	["CaseStudyList"]: {
		_meta?: ModelTypes["CaseStudyListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["CaseStudyListItem"]>,
	paginateItems: ModelTypes["CaseStudyListItemConnection"]
};
	["CaseStudyListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["CaseStudyListItem"]: {
		_meta?: ModelTypes["CaseStudyListItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	list?: ModelTypes["CaseStudyList"] | undefined,
	caseStudy?: ModelTypes["CaseStudy"] | undefined
};
	["CaseStudyListItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	caseStudy?: ModelTypes["FieldMeta"] | undefined
};
	["CaseStudyListItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["CaseStudyListOrderBy"] | undefined,
	caseStudy?: ModelTypes["CaseStudyOrderBy"] | undefined
};
	["CaseStudyListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["CaseStudyListItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["CaseStudyListItemEdge"]>
};
	["CaseStudyListItemEdge"]: {
		node: ModelTypes["CaseStudyListItem"]
};
	["ImageList"]: {
		_meta?: ModelTypes["ImageListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["ImageListItem"]>,
	itemsByImage?: ModelTypes["ImageListItem"] | undefined,
	paginateItems: ModelTypes["ImageListItemConnection"]
};
	["ImageListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["ImageListItem"]: {
		_meta?: ModelTypes["ImageListItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	list?: ModelTypes["ImageList"] | undefined,
	image?: ModelTypes["Image"] | undefined
};
	["ImageListItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined
};
	["ImageListItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["ImageListOrderBy"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined
};
	["ImageListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["ImageListItemsByImageUniqueWhere"]: {
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["ImageListItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ImageListItemEdge"]>
};
	["ImageListItemEdge"]: {
		node: ModelTypes["ImageListItem"]
};
	["InformationList"]: {
		_meta?: ModelTypes["InformationListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["PieceOfInformation"]>,
	paginateItems: ModelTypes["PieceOfInformationConnection"]
};
	["InformationListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["PieceOfInformation"]: {
		_meta?: ModelTypes["PieceOfInformationMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	title?: string | undefined,
	description?: string | undefined,
	list?: ModelTypes["InformationList"] | undefined
};
	["PieceOfInformationMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	description?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined
};
	["PieceOfInformationOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	description?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["InformationListOrderBy"] | undefined
};
	["InformationListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["PieceOfInformationConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["PieceOfInformationEdge"]>
};
	["PieceOfInformationEdge"]: {
		node: ModelTypes["PieceOfInformation"]
};
	["Embed"]: {
		_meta?: ModelTypes["EmbedMeta"] | undefined,
	id: ModelTypes["UUID"],
	type: ModelTypes["EmbedType"],
	embedId?: string | undefined
};
	["EmbedMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	embedId?: ModelTypes["FieldMeta"] | undefined
};
	["LinkList"]: {
		_meta?: ModelTypes["LinkListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["LinkListItem"]>,
	itemsByLink?: ModelTypes["LinkListItem"] | undefined,
	paginateItems: ModelTypes["LinkListItemConnection"]
};
	["LinkListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["LinkListItem"]: {
		_meta?: ModelTypes["LinkListItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	list?: ModelTypes["LinkList"] | undefined,
	link?: ModelTypes["Link"] | undefined
};
	["LinkListItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined
};
	["LinkListItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["LinkListOrderBy"] | undefined,
	link?: ModelTypes["LinkOrderBy"] | undefined
};
	["LinkListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["LinkListItemsByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["LinkListItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["LinkListItemEdge"]>
};
	["LinkListItemEdge"]: {
		node: ModelTypes["LinkListItem"]
};
	["TalkLists"]: {
		_meta?: ModelTypes["TalkListsMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["TalkList"]>,
	itemsByItems?: ModelTypes["TalkList"] | undefined,
	paginateItems: ModelTypes["TalkListConnection"]
};
	["TalkListsMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["TalkList"]: {
		_meta?: ModelTypes["TalkListMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	title?: string | undefined,
	list?: ModelTypes["TalkLists"] | undefined,
	items: Array<ModelTypes["TalkListItem"]>,
	paginateItems: ModelTypes["TalkListItemConnection"]
};
	["TalkListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["TalkListItem"]: {
		_meta?: ModelTypes["TalkListItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	list?: ModelTypes["TalkList"] | undefined,
	talk?: ModelTypes["Talk"] | undefined
};
	["TalkListItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	talk?: ModelTypes["FieldMeta"] | undefined
};
	["Talk"]: {
		_meta?: ModelTypes["TalkMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	slug?: string | undefined,
	date?: ModelTypes["DateTime"] | undefined,
	note?: string | undefined,
	content?: string | undefined,
	coverImage?: ModelTypes["Image"] | undefined,
	otherImage?: ModelTypes["Image"] | undefined
};
	["TalkMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	slug?: ModelTypes["FieldMeta"] | undefined,
	date?: ModelTypes["FieldMeta"] | undefined,
	note?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined,
	coverImage?: ModelTypes["FieldMeta"] | undefined,
	otherImage?: ModelTypes["FieldMeta"] | undefined
};
	["TalkListItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["TalkListOrderBy"] | undefined,
	talk?: ModelTypes["TalkOrderBy"] | undefined
};
	["TalkListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["TalkListsOrderBy"] | undefined
};
	["TalkListsOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["TalkOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	slug?: ModelTypes["OrderDirection"] | undefined,
	date?: ModelTypes["OrderDirection"] | undefined,
	note?: ModelTypes["OrderDirection"] | undefined,
	content?: ModelTypes["OrderDirection"] | undefined,
	coverImage?: ModelTypes["ImageOrderBy"] | undefined,
	otherImage?: ModelTypes["ImageOrderBy"] | undefined
};
	["TalkListItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TalkListItemEdge"]>
};
	["TalkListItemEdge"]: {
		node: ModelTypes["TalkListItem"]
};
	["TalkListsItemsByItemsUniqueWhere"]: {
	items?: ModelTypes["TalkListItemUniqueWhere"] | undefined
};
	["TalkListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TalkListEdge"]>
};
	["TalkListEdge"]: {
		node: ModelTypes["TalkList"]
};
	["BusinessCardList"]: {
		_meta?: ModelTypes["BusinessCardListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["BusinessCardListItem"]>,
	itemsByBusinessCard?: ModelTypes["BusinessCardListItem"] | undefined,
	paginateItems: ModelTypes["BusinessCardListItemConnection"]
};
	["BusinessCardListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["BusinessCardListItem"]: {
		_meta?: ModelTypes["BusinessCardListItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	list?: ModelTypes["BusinessCardList"] | undefined,
	businessCard?: ModelTypes["BusinessCard"] | undefined
};
	["BusinessCardListItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	businessCard?: ModelTypes["FieldMeta"] | undefined
};
	["BusinessCard"]: {
		_meta?: ModelTypes["BusinessCardMeta"] | undefined,
	id: ModelTypes["UUID"],
	name?: string | undefined,
	role?: string | undefined,
	email?: string | undefined,
	avatar?: ModelTypes["Image"] | undefined,
	phone?: string | undefined
};
	["BusinessCardMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	name?: ModelTypes["FieldMeta"] | undefined,
	role?: ModelTypes["FieldMeta"] | undefined,
	email?: ModelTypes["FieldMeta"] | undefined,
	avatar?: ModelTypes["FieldMeta"] | undefined,
	phone?: ModelTypes["FieldMeta"] | undefined
};
	["BusinessCardListItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["BusinessCardListOrderBy"] | undefined,
	businessCard?: ModelTypes["BusinessCardOrderBy"] | undefined
};
	["BusinessCardListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["BusinessCardOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	name?: ModelTypes["OrderDirection"] | undefined,
	role?: ModelTypes["OrderDirection"] | undefined,
	email?: ModelTypes["OrderDirection"] | undefined,
	avatar?: ModelTypes["ImageOrderBy"] | undefined,
	phone?: ModelTypes["OrderDirection"] | undefined
};
	["BusinessCardListItemsByBusinessCardUniqueWhere"]: {
	businessCard?: ModelTypes["BusinessCardUniqueWhere"] | undefined
};
	["BusinessCardListItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["BusinessCardListItemEdge"]>
};
	["BusinessCardListItemEdge"]: {
		node: ModelTypes["BusinessCardListItem"]
};
	["TestimonialList"]: {
		_meta?: ModelTypes["TestimonialListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["TestimonialListItem"]>,
	itemsByTestimonial?: ModelTypes["TestimonialListItem"] | undefined,
	paginateItems: ModelTypes["TestimonialListItemConnection"]
};
	["TestimonialListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["TestimonialListItem"]: {
		_meta?: ModelTypes["TestimonialListItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	list?: ModelTypes["TestimonialList"] | undefined,
	testimonial?: ModelTypes["Testimonial"] | undefined
};
	["TestimonialListItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	testimonial?: ModelTypes["FieldMeta"] | undefined
};
	["Testimonial"]: {
		_meta?: ModelTypes["TestimonialMeta"] | undefined,
	id: ModelTypes["UUID"],
	content?: string | undefined,
	note?: string | undefined,
	avatar?: ModelTypes["Image"] | undefined
};
	["TestimonialMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined,
	note?: ModelTypes["FieldMeta"] | undefined,
	avatar?: ModelTypes["FieldMeta"] | undefined
};
	["TestimonialListItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["TestimonialListOrderBy"] | undefined,
	testimonial?: ModelTypes["TestimonialOrderBy"] | undefined
};
	["TestimonialListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["TestimonialOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	content?: ModelTypes["OrderDirection"] | undefined,
	note?: ModelTypes["OrderDirection"] | undefined,
	avatar?: ModelTypes["ImageOrderBy"] | undefined
};
	["TestimonialListItemsByTestimonialUniqueWhere"]: {
	testimonial?: ModelTypes["TestimonialUniqueWhere"] | undefined
};
	["TestimonialListItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TestimonialListItemEdge"]>
};
	["TestimonialListItemEdge"]: {
		node: ModelTypes["TestimonialListItem"]
};
	["ContactRequestOptionList"]: {
		_meta?: ModelTypes["ContactRequestOptionListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["ContactRequestOption"]>,
	paginateItems: ModelTypes["ContactRequestOptionConnection"]
};
	["ContactRequestOptionListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["ContactRequestOption"]: {
		_meta?: ModelTypes["ContactRequestOptionMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	label?: string | undefined,
	description?: string | undefined,
	list?: ModelTypes["ContactRequestOptionList"] | undefined,
	code?: string | undefined,
	type: ModelTypes["ContactRequestOptionType"],
	subscribeForm?: ModelTypes["SubscribeForm"] | undefined
};
	["ContactRequestOptionMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	label?: ModelTypes["FieldMeta"] | undefined,
	description?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	code?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	subscribeForm?: ModelTypes["FieldMeta"] | undefined
};
	["SubscribeForm"]: {
		_meta?: ModelTypes["SubscribeFormMeta"] | undefined,
	id: ModelTypes["UUID"],
	name?: string | undefined,
	datacruitJobId?: number | undefined,
	datacruitReferenceNumber?: string | undefined,
	lastUpdateAt?: ModelTypes["DateTime"] | undefined
};
	["SubscribeFormMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	name?: ModelTypes["FieldMeta"] | undefined,
	datacruitJobId?: ModelTypes["FieldMeta"] | undefined,
	datacruitReferenceNumber?: ModelTypes["FieldMeta"] | undefined,
	lastUpdateAt?: ModelTypes["FieldMeta"] | undefined
};
	["ContactRequestOptionOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	label?: ModelTypes["OrderDirection"] | undefined,
	description?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["ContactRequestOptionListOrderBy"] | undefined,
	code?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	subscribeForm?: ModelTypes["SubscribeFormOrderBy"] | undefined
};
	["ContactRequestOptionListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["SubscribeFormOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	name?: ModelTypes["OrderDirection"] | undefined,
	datacruitJobId?: ModelTypes["OrderDirection"] | undefined,
	datacruitReferenceNumber?: ModelTypes["OrderDirection"] | undefined,
	lastUpdateAt?: ModelTypes["OrderDirection"] | undefined
};
	["ContactRequestOptionConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContactRequestOptionEdge"]>
};
	["ContactRequestOptionEdge"]: {
		node: ModelTypes["ContactRequestOption"]
};
	["YoutubeVideo"]: {
		_meta?: ModelTypes["YoutubeVideoMeta"] | undefined,
	id: ModelTypes["UUID"],
	videoId: string
};
	["YoutubeVideoMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	videoId?: ModelTypes["FieldMeta"] | undefined
};
	["Video"]: {
		_meta?: ModelTypes["VideoMeta"] | undefined,
	id: ModelTypes["UUID"],
	src?: string | undefined,
	height?: number | undefined,
	width?: number | undefined,
	duration?: number | undefined,
	size?: number | undefined,
	type?: string | undefined
};
	["VideoMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	src?: ModelTypes["FieldMeta"] | undefined,
	height?: ModelTypes["FieldMeta"] | undefined,
	width?: ModelTypes["FieldMeta"] | undefined,
	duration?: ModelTypes["FieldMeta"] | undefined,
	size?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined
};
	["ContentReferencePositionCategoryItem"]: {
		_meta?: ModelTypes["ContentReferencePositionCategoryItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	item?: ModelTypes["PositionCategory"] | undefined,
	reference?: ModelTypes["ContentReference"] | undefined
};
	["ContentReferencePositionCategoryItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	item?: ModelTypes["FieldMeta"] | undefined,
	reference?: ModelTypes["FieldMeta"] | undefined
};
	["ContentReferencePositionCategoryItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	item?: ModelTypes["PositionCategoryOrderBy"] | undefined,
	reference?: ModelTypes["ContentReferenceOrderBy"] | undefined
};
	["ContentReferenceOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	primaryText?: ModelTypes["OrderDirection"] | undefined,
	block?: ModelTypes["ContentBlockOrderBy"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined,
	secondaryText?: ModelTypes["OrderDirection"] | undefined,
	link?: ModelTypes["LinkOrderBy"] | undefined,
	featuredCaseStudies?: ModelTypes["CaseStudyListOrderBy"] | undefined,
	images?: ModelTypes["ImageListOrderBy"] | undefined,
	otherImages?: ModelTypes["ImageListOrderBy"] | undefined,
	informationList?: ModelTypes["InformationListOrderBy"] | undefined,
	tertiaryText?: ModelTypes["OrderDirection"] | undefined,
	embed?: ModelTypes["EmbedOrderBy"] | undefined,
	links?: ModelTypes["LinkListOrderBy"] | undefined,
	talkLists?: ModelTypes["TalkListsOrderBy"] | undefined,
	businessCardList?: ModelTypes["BusinessCardListOrderBy"] | undefined,
	testimonials?: ModelTypes["TestimonialListOrderBy"] | undefined,
	background?: ModelTypes["OrderDirection"] | undefined,
	otherImage?: ModelTypes["ImageOrderBy"] | undefined,
	caseStudy?: ModelTypes["CaseStudyOrderBy"] | undefined,
	alternative?: ModelTypes["OrderDirection"] | undefined,
	contactRequestOptions?: ModelTypes["ContactRequestOptionListOrderBy"] | undefined,
	youtubeVideo?: ModelTypes["YoutubeVideoOrderBy"] | undefined,
	subscribeForm?: ModelTypes["SubscribeFormOrderBy"] | undefined,
	video?: ModelTypes["VideoOrderBy"] | undefined,
	otherVideo?: ModelTypes["VideoOrderBy"] | undefined,
	positions?: ModelTypes["PositionListOrderBy"] | undefined,
	testimonialsFull?: ModelTypes["TestimonialFullListOrderBy"] | undefined,
	verticalMargin?: ModelTypes["OrderDirection"] | undefined,
	variant?: ModelTypes["OrderDirection"] | undefined,
	content?: ModelTypes["ContentOrderBy"] | undefined
};
	["ContentBlockOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	json?: ModelTypes["OrderDirection"] | undefined,
	content?: ModelTypes["ContentOrderBy"] | undefined
};
	["EmbedOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	embedId?: ModelTypes["OrderDirection"] | undefined
};
	["YoutubeVideoOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	videoId?: ModelTypes["OrderDirection"] | undefined
};
	["VideoOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	src?: ModelTypes["OrderDirection"] | undefined,
	height?: ModelTypes["OrderDirection"] | undefined,
	width?: ModelTypes["OrderDirection"] | undefined,
	duration?: ModelTypes["OrderDirection"] | undefined,
	size?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined
};
	["PositionListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["TestimonialFullListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["ContentReferenceCaseStudyItem"]: {
		_meta?: ModelTypes["ContentReferenceCaseStudyItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	item?: ModelTypes["CaseStudy"] | undefined,
	reference?: ModelTypes["ContentReference"] | undefined
};
	["ContentReferenceCaseStudyItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	item?: ModelTypes["FieldMeta"] | undefined,
	reference?: ModelTypes["FieldMeta"] | undefined
};
	["ContentReferenceCaseStudyItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	item?: ModelTypes["CaseStudyOrderBy"] | undefined,
	reference?: ModelTypes["ContentReferenceOrderBy"] | undefined
};
	["ContentReferenceTagItem"]: {
		_meta?: ModelTypes["ContentReferenceTagItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	item?: ModelTypes["Tag"] | undefined,
	reference?: ModelTypes["ContentReference"] | undefined
};
	["ContentReferenceTagItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	item?: ModelTypes["FieldMeta"] | undefined,
	reference?: ModelTypes["FieldMeta"] | undefined
};
	["ContentReferenceTagItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	item?: ModelTypes["TagOrderBy"] | undefined,
	reference?: ModelTypes["ContentReferenceOrderBy"] | undefined
};
	["PositionList"]: {
		_meta?: ModelTypes["PositionListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["PositionListItem"]>,
	paginateItems: ModelTypes["PositionListItemConnection"]
};
	["PositionListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["PositionListItem"]: {
		_meta?: ModelTypes["PositionListItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	list?: ModelTypes["PositionList"] | undefined,
	position?: ModelTypes["PositionLocale"] | undefined
};
	["PositionListItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	position?: ModelTypes["FieldMeta"] | undefined
};
	["PositionListItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["PositionListOrderBy"] | undefined,
	position?: ModelTypes["PositionLocaleOrderBy"] | undefined
};
	["PositionListItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["PositionListItemEdge"]>
};
	["PositionListItemEdge"]: {
		node: ModelTypes["PositionListItem"]
};
	["TestimonialFullList"]: {
		_meta?: ModelTypes["TestimonialFullListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["TestimonialFullListItem"]>,
	itemsByTestimonial?: ModelTypes["TestimonialFullListItem"] | undefined,
	paginateItems: ModelTypes["TestimonialFullListItemConnection"]
};
	["TestimonialFullListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["TestimonialFullListItem"]: {
		_meta?: ModelTypes["TestimonialFullListItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	list?: ModelTypes["TestimonialFullList"] | undefined,
	testimonial?: ModelTypes["TestimonialFull"] | undefined
};
	["TestimonialFullListItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	testimonial?: ModelTypes["FieldMeta"] | undefined
};
	["TestimonialFull"]: {
		_meta?: ModelTypes["TestimonialFullMeta"] | undefined,
	id: ModelTypes["UUID"],
	content: string,
	name: string,
	note?: string | undefined,
	avatar?: ModelTypes["Image"] | undefined,
	imageVariant: ModelTypes["ImageVariant"]
};
	["TestimonialFullMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined,
	name?: ModelTypes["FieldMeta"] | undefined,
	note?: ModelTypes["FieldMeta"] | undefined,
	avatar?: ModelTypes["FieldMeta"] | undefined,
	imageVariant?: ModelTypes["FieldMeta"] | undefined
};
	["TestimonialFullListItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["TestimonialFullListOrderBy"] | undefined,
	testimonial?: ModelTypes["TestimonialFullOrderBy"] | undefined
};
	["TestimonialFullOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	content?: ModelTypes["OrderDirection"] | undefined,
	name?: ModelTypes["OrderDirection"] | undefined,
	note?: ModelTypes["OrderDirection"] | undefined,
	avatar?: ModelTypes["ImageOrderBy"] | undefined,
	imageVariant?: ModelTypes["OrderDirection"] | undefined
};
	["TestimonialFullListItemsByTestimonialUniqueWhere"]: {
	testimonial?: ModelTypes["TestimonialFullUniqueWhere"] | undefined
};
	["TestimonialFullListItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TestimonialFullListItemEdge"]>
};
	["TestimonialFullListItemEdge"]: {
		node: ModelTypes["TestimonialFullListItem"]
};
	["ContentReferenceBlogPostItem"]: {
		_meta?: ModelTypes["ContentReferenceBlogPostItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	publishedAt?: ModelTypes["Date"] | undefined,
	item?: ModelTypes["BlogPost"] | undefined,
	reference?: ModelTypes["ContentReference"] | undefined
};
	["ContentReferenceBlogPostItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	publishedAt?: ModelTypes["FieldMeta"] | undefined,
	item?: ModelTypes["FieldMeta"] | undefined,
	reference?: ModelTypes["FieldMeta"] | undefined
};
	["ContentReferenceBlogPostItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	publishedAt?: ModelTypes["OrderDirection"] | undefined,
	item?: ModelTypes["BlogPostOrderBy"] | undefined,
	reference?: ModelTypes["ContentReferenceOrderBy"] | undefined
};
	["ContentReferencePositionCategoriesByItemUniqueWhere"]: {
	item?: ModelTypes["PositionCategoryUniqueWhere"] | undefined
};
	["ContentReferenceCaseStudiesByItemUniqueWhere"]: {
	item?: ModelTypes["CaseStudyUniqueWhere"] | undefined
};
	["ContentReferenceTagsByItemUniqueWhere"]: {
	item?: ModelTypes["TagUniqueWhere"] | undefined
};
	["ContentReferenceBlogPostsByItemUniqueWhere"]: {
	item?: ModelTypes["BlogPostUniqueWhere"] | undefined
};
	["ContentReferencePositionCategoryItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContentReferencePositionCategoryItemEdge"]>
};
	["ContentReferencePositionCategoryItemEdge"]: {
		node: ModelTypes["ContentReferencePositionCategoryItem"]
};
	["ContentReferenceCaseStudyItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContentReferenceCaseStudyItemEdge"]>
};
	["ContentReferenceCaseStudyItemEdge"]: {
		node: ModelTypes["ContentReferenceCaseStudyItem"]
};
	["ContentReferenceTagItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContentReferenceTagItemEdge"]>
};
	["ContentReferenceTagItemEdge"]: {
		node: ModelTypes["ContentReferenceTagItem"]
};
	["ContentReferenceBlogPostItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContentReferenceBlogPostItemEdge"]>
};
	["ContentReferenceBlogPostItemEdge"]: {
		node: ModelTypes["ContentReferenceBlogPostItem"]
};
	["ContentBlockReferencesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["ContentBlockReferencesByFeaturedCaseStudiesUniqueWhere"]: {
	featuredCaseStudies?: ModelTypes["CaseStudyListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByImagesUniqueWhere"]: {
	images?: ModelTypes["ImageListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByOtherImagesUniqueWhere"]: {
	otherImages?: ModelTypes["ImageListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByInformationListUniqueWhere"]: {
	informationList?: ModelTypes["InformationListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByEmbedUniqueWhere"]: {
	embed?: ModelTypes["EmbedUniqueWhere"] | undefined
};
	["ContentBlockReferencesByLinksUniqueWhere"]: {
	links?: ModelTypes["LinkListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByTalkListsUniqueWhere"]: {
	talkLists?: ModelTypes["TalkListsUniqueWhere"] | undefined
};
	["ContentBlockReferencesByBusinessCardListUniqueWhere"]: {
	businessCardList?: ModelTypes["BusinessCardListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByTestimonialsUniqueWhere"]: {
	testimonials?: ModelTypes["TestimonialListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByContactRequestOptionsUniqueWhere"]: {
	contactRequestOptions?: ModelTypes["ContactRequestOptionListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByYoutubeVideoUniqueWhere"]: {
	youtubeVideo?: ModelTypes["YoutubeVideoUniqueWhere"] | undefined
};
	["ContentBlockReferencesByPositionCategoriesUniqueWhere"]: {
	positionCategories?: ModelTypes["ContentReferencePositionCategoryItemUniqueWhere"] | undefined
};
	["ContentBlockReferencesByCaseStudiesUniqueWhere"]: {
	caseStudies?: ModelTypes["ContentReferenceCaseStudyItemUniqueWhere"] | undefined
};
	["ContentBlockReferencesByTagsUniqueWhere"]: {
	tags?: ModelTypes["ContentReferenceTagItemUniqueWhere"] | undefined
};
	["ContentBlockReferencesByPositionsUniqueWhere"]: {
	positions?: ModelTypes["PositionListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByTestimonialsFullUniqueWhere"]: {
	testimonialsFull?: ModelTypes["TestimonialFullListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByBlogPostsUniqueWhere"]: {
	blogPosts?: ModelTypes["ContentReferenceBlogPostItemUniqueWhere"] | undefined
};
	["ContentBlockReferencesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["ContentReferenceConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContentReferenceEdge"]>
};
	["ContentReferenceEdge"]: {
		node: ModelTypes["ContentReference"]
};
	["ContentBlocksByReferencesUniqueWhere"]: {
	references?: ModelTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentBlockConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContentBlockEdge"]>
};
	["ContentBlockEdge"]: {
		node: ModelTypes["ContentBlock"]
};
	["HeaderLocale"]: {
		_meta?: ModelTypes["HeaderLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	email?: string | undefined,
	root?: ModelTypes["Header"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	linkList?: ModelTypes["LinkList"] | undefined,
	socialLinkList?: ModelTypes["LinkList"] | undefined,
	topLevelLinkItems: Array<ModelTypes["TopLevelLinkItem"]>,
	topLevelLinkItemsBySecondLevelLinkItems?: ModelTypes["TopLevelLinkItem"] | undefined,
	topLevelLinkItemsByLink?: ModelTypes["TopLevelLinkItem"] | undefined,
	paginateTopLevelLinkItems: ModelTypes["TopLevelLinkItemConnection"]
};
	["HeaderLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	email?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	linkList?: ModelTypes["FieldMeta"] | undefined,
	socialLinkList?: ModelTypes["FieldMeta"] | undefined,
	topLevelLinkItems?: ModelTypes["FieldMeta"] | undefined
};
	["Header"]: {
		_meta?: ModelTypes["HeaderMeta"] | undefined,
	id: ModelTypes["UUID"],
	unique: ModelTypes["One"],
	locales: Array<ModelTypes["HeaderLocale"]>,
	localesByLocale?: ModelTypes["HeaderLocale"] | undefined,
	localesByLinkList?: ModelTypes["HeaderLocale"] | undefined,
	localesBySocialLinkList?: ModelTypes["HeaderLocale"] | undefined,
	localesByTopLevelLinkItems?: ModelTypes["HeaderLocale"] | undefined,
	paginateLocales: ModelTypes["HeaderLocaleConnection"]
};
	["HeaderMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	unique?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined
};
	["HeaderLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	email?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["HeaderOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	linkList?: ModelTypes["LinkListOrderBy"] | undefined,
	socialLinkList?: ModelTypes["LinkListOrderBy"] | undefined
};
	["HeaderOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	unique?: ModelTypes["OrderDirection"] | undefined
};
	["HeaderLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["HeaderLocalesByLinkListUniqueWhere"]: {
	linkList?: ModelTypes["LinkListUniqueWhere"] | undefined
};
	["HeaderLocalesBySocialLinkListUniqueWhere"]: {
	socialLinkList?: ModelTypes["LinkListUniqueWhere"] | undefined
};
	["HeaderLocalesByTopLevelLinkItemsUniqueWhere"]: {
	topLevelLinkItems?: ModelTypes["TopLevelLinkItemUniqueWhere"] | undefined
};
	["HeaderLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["HeaderLocaleEdge"]>
};
	["HeaderLocaleEdge"]: {
		node: ModelTypes["HeaderLocale"]
};
	["TopLevelLinkItem"]: {
		_meta?: ModelTypes["TopLevelLinkItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	isLink: ModelTypes["IsLink"],
	order: number,
	title?: string | undefined,
	secondLevelLinkItems: Array<ModelTypes["SecondLevelLinkItem"]>,
	header?: ModelTypes["HeaderLocale"] | undefined,
	link?: ModelTypes["Link"] | undefined,
	secondLevelLinkItemsByLink?: ModelTypes["SecondLevelLinkItem"] | undefined,
	secondLevelLinkItemsByThirdLevelLinkItems?: ModelTypes["SecondLevelLinkItem"] | undefined,
	paginateSecondLevelLinkItems: ModelTypes["SecondLevelLinkItemConnection"]
};
	["TopLevelLinkItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	isLink?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	secondLevelLinkItems?: ModelTypes["FieldMeta"] | undefined,
	header?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined
};
	["SecondLevelLinkItem"]: {
		_meta?: ModelTypes["SecondLevelLinkItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	isLink: ModelTypes["IsLink"],
	order: number,
	title?: string | undefined,
	topLevelLinkItem?: ModelTypes["TopLevelLinkItem"] | undefined,
	link?: ModelTypes["Link"] | undefined,
	thirdLevelLinkItems: Array<ModelTypes["ThirdLevelLinkItem"]>,
	thirdLevelLinkItemsByLink?: ModelTypes["ThirdLevelLinkItem"] | undefined,
	paginateThirdLevelLinkItems: ModelTypes["ThirdLevelLinkItemConnection"]
};
	["SecondLevelLinkItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	isLink?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	topLevelLinkItem?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	thirdLevelLinkItems?: ModelTypes["FieldMeta"] | undefined
};
	["ThirdLevelLinkItem"]: {
		_meta?: ModelTypes["ThirdLevelLinkItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	secondLevelLinkItem?: ModelTypes["SecondLevelLinkItem"] | undefined,
	link?: ModelTypes["Link"] | undefined
};
	["ThirdLevelLinkItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	secondLevelLinkItem?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined
};
	["ThirdLevelLinkItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	secondLevelLinkItem?: ModelTypes["SecondLevelLinkItemOrderBy"] | undefined,
	link?: ModelTypes["LinkOrderBy"] | undefined
};
	["SecondLevelLinkItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	isLink?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	topLevelLinkItem?: ModelTypes["TopLevelLinkItemOrderBy"] | undefined,
	link?: ModelTypes["LinkOrderBy"] | undefined
};
	["TopLevelLinkItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	isLink?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	header?: ModelTypes["HeaderLocaleOrderBy"] | undefined,
	link?: ModelTypes["LinkOrderBy"] | undefined
};
	["SecondLevelLinkItemThirdLevelLinkItemsByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["ThirdLevelLinkItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ThirdLevelLinkItemEdge"]>
};
	["ThirdLevelLinkItemEdge"]: {
		node: ModelTypes["ThirdLevelLinkItem"]
};
	["TopLevelLinkItemSecondLevelLinkItemsByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["TopLevelLinkItemSecondLevelLinkItemsByThirdLevelLinkItemsUniqueWhere"]: {
	thirdLevelLinkItems?: ModelTypes["ThirdLevelLinkItemUniqueWhere"] | undefined
};
	["SecondLevelLinkItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["SecondLevelLinkItemEdge"]>
};
	["SecondLevelLinkItemEdge"]: {
		node: ModelTypes["SecondLevelLinkItem"]
};
	["HeaderLocaleTopLevelLinkItemsBySecondLevelLinkItemsUniqueWhere"]: {
	secondLevelLinkItems?: ModelTypes["SecondLevelLinkItemUniqueWhere"] | undefined
};
	["HeaderLocaleTopLevelLinkItemsByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["TopLevelLinkItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TopLevelLinkItemEdge"]>
};
	["TopLevelLinkItemEdge"]: {
		node: ModelTypes["TopLevelLinkItem"]
};
	["FooterLocale"]: {
		_meta?: ModelTypes["FooterLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	email?: string | undefined,
	root?: ModelTypes["Footer"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	address?: string | undefined,
	phone?: string | undefined,
	legal?: string | undefined,
	navigation?: ModelTypes["LinkList"] | undefined
};
	["FooterLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	email?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	address?: ModelTypes["FieldMeta"] | undefined,
	phone?: ModelTypes["FieldMeta"] | undefined,
	legal?: ModelTypes["FieldMeta"] | undefined,
	navigation?: ModelTypes["FieldMeta"] | undefined
};
	["Footer"]: {
		_meta?: ModelTypes["FooterMeta"] | undefined,
	id: ModelTypes["UUID"],
	unique: ModelTypes["One"],
	locales: Array<ModelTypes["FooterLocale"]>,
	localesByLocale?: ModelTypes["FooterLocale"] | undefined,
	localesByNavigation?: ModelTypes["FooterLocale"] | undefined,
	paginateLocales: ModelTypes["FooterLocaleConnection"]
};
	["FooterMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	unique?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined
};
	["FooterLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	email?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["FooterOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	address?: ModelTypes["OrderDirection"] | undefined,
	phone?: ModelTypes["OrderDirection"] | undefined,
	legal?: ModelTypes["OrderDirection"] | undefined,
	navigation?: ModelTypes["LinkListOrderBy"] | undefined
};
	["FooterOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	unique?: ModelTypes["OrderDirection"] | undefined
};
	["FooterLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["FooterLocalesByNavigationUniqueWhere"]: {
	navigation?: ModelTypes["LinkListUniqueWhere"] | undefined
};
	["FooterLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FooterLocaleEdge"]>
};
	["FooterLocaleEdge"]: {
		node: ModelTypes["FooterLocale"]
};
	["BlogPostCommonLocale"]: {
		_meta?: ModelTypes["BlogPostCommonLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	root?: ModelTypes["BlogPostCommon"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	ctaLink?: ModelTypes["Link"] | undefined,
	ctaTitle?: string | undefined
};
	["BlogPostCommonLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	ctaLink?: ModelTypes["FieldMeta"] | undefined,
	ctaTitle?: ModelTypes["FieldMeta"] | undefined
};
	["BlogPostCommon"]: {
		_meta?: ModelTypes["BlogPostCommonMeta"] | undefined,
	id: ModelTypes["UUID"],
	unique: ModelTypes["One"],
	locales: Array<ModelTypes["BlogPostCommonLocale"]>,
	localesByLocale?: ModelTypes["BlogPostCommonLocale"] | undefined,
	paginateLocales: ModelTypes["BlogPostCommonLocaleConnection"]
};
	["BlogPostCommonMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	unique?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined
};
	["BlogPostCommonLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["BlogPostCommonOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	ctaLink?: ModelTypes["LinkOrderBy"] | undefined,
	ctaTitle?: ModelTypes["OrderDirection"] | undefined
};
	["BlogPostCommonOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	unique?: ModelTypes["OrderDirection"] | undefined
};
	["BlogPostCommonLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["BlogPostCommonLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["BlogPostCommonLocaleEdge"]>
};
	["BlogPostCommonLocaleEdge"]: {
		node: ModelTypes["BlogPostCommonLocale"]
};
	["LocaleGeneralByRootUniqueWhere"]: {
	root?: ModelTypes["GeneralUniqueWhere"] | undefined
};
	["LocaleGeneralBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["LocaleGeneralByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["LocaleGenericPagesByRootUniqueWhere"]: {
	root?: ModelTypes["GenericPageUniqueWhere"] | undefined
};
	["LocaleGenericPagesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["LocaleGenericPagesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleGenericPagesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["LocaleHeadersByRootUniqueWhere"]: {
	root?: ModelTypes["HeaderUniqueWhere"] | undefined
};
	["LocaleHeadersByLinkListUniqueWhere"]: {
	linkList?: ModelTypes["LinkListUniqueWhere"] | undefined
};
	["LocaleHeadersBySocialLinkListUniqueWhere"]: {
	socialLinkList?: ModelTypes["LinkListUniqueWhere"] | undefined
};
	["LocaleHeadersByTopLevelLinkItemsUniqueWhere"]: {
	topLevelLinkItems?: ModelTypes["TopLevelLinkItemUniqueWhere"] | undefined
};
	["LocaleFootersByRootUniqueWhere"]: {
	root?: ModelTypes["FooterUniqueWhere"] | undefined
};
	["LocaleFootersByNavigationUniqueWhere"]: {
	navigation?: ModelTypes["LinkListUniqueWhere"] | undefined
};
	["LocaleCaseStudiesByRootUniqueWhere"]: {
	root?: ModelTypes["CaseStudyUniqueWhere"] | undefined
};
	["LocaleCaseStudiesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["LocaleCaseStudiesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleCaseStudiesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["LocaleTagsBySlugUniqueWhere"]: {
	slug?: string | undefined
};
	["LocaleTagsByRootUniqueWhere"]: {
	root?: ModelTypes["TagUniqueWhere"] | undefined
};
	["LocalePositionTagsBySlugUniqueWhere"]: {
	slug?: string | undefined
};
	["LocalePositionTagsByRootUniqueWhere"]: {
	root?: ModelTypes["PositionTagUniqueWhere"] | undefined
};
	["LocalePositionsByRootUniqueWhere"]: {
	root?: ModelTypes["PositionUniqueWhere"] | undefined
};
	["LocalePositionsByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["LocalePositionsBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["LocalePositionsByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["LocalePositionCategoriesByRootUniqueWhere"]: {
	root?: ModelTypes["PositionCategoryUniqueWhere"] | undefined
};
	["LocaleAuthorsByRootUniqueWhere"]: {
	root?: ModelTypes["AuthorUniqueWhere"] | undefined
};
	["LocaleBlogPostsByRootUniqueWhere"]: {
	root?: ModelTypes["BlogPostUniqueWhere"] | undefined
};
	["LocaleBlogPostsByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["LocaleBlogPostsBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["LocaleBlogPostsByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleBlogPostCommonByRootUniqueWhere"]: {
	root?: ModelTypes["BlogPostCommonUniqueWhere"] | undefined
};
	["GeneralLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["GeneralLocaleEdge"]>
};
	["GeneralLocaleEdge"]: {
		node: ModelTypes["GeneralLocale"]
};
	["GeneralLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["GeneralLocalesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["GeneralLocalesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["GeneralConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["GeneralEdge"]>
};
	["GeneralEdge"]: {
		node: ModelTypes["General"]
};
	["ImageConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ImageEdge"]>
};
	["ImageEdge"]: {
		node: ModelTypes["Image"]
};
	["GenericPageConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["GenericPageEdge"]>
};
	["GenericPageEdge"]: {
		node: ModelTypes["GenericPage"]
};
	["LinkableConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["LinkableEdge"]>
};
	["LinkableEdge"]: {
		node: ModelTypes["Linkable"]
};
	["SeoConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["SeoEdge"]>
};
	["SeoEdge"]: {
		node: ModelTypes["Seo"]
};
	["ContentConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContentEdge"]>
};
	["ContentEdge"]: {
		node: ModelTypes["Content"]
};
	["LocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["LocaleEdge"]>
};
	["LocaleEdge"]: {
		node: ModelTypes["Locale"]
};
	["LinkConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["LinkEdge"]>
};
	["LinkEdge"]: {
		node: ModelTypes["Link"]
};
	["LinkListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["LinkListEdge"]>
};
	["LinkListEdge"]: {
		node: ModelTypes["LinkList"]
};
	["HeaderConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["HeaderEdge"]>
};
	["HeaderEdge"]: {
		node: ModelTypes["Header"]
};
	["FooterConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FooterEdge"]>
};
	["FooterEdge"]: {
		node: ModelTypes["Footer"]
};
	["CaseStudyConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["CaseStudyEdge"]>
};
	["CaseStudyEdge"]: {
		node: ModelTypes["CaseStudy"]
};
	["TagConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TagEdge"]>
};
	["TagEdge"]: {
		node: ModelTypes["Tag"]
};
	["CaseStudyListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["CaseStudyListEdge"]>
};
	["CaseStudyListEdge"]: {
		node: ModelTypes["CaseStudyList"]
};
	["ImageListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ImageListEdge"]>
};
	["ImageListEdge"]: {
		node: ModelTypes["ImageList"]
};
	["InformationListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["InformationListEdge"]>
};
	["InformationListEdge"]: {
		node: ModelTypes["InformationList"]
};
	["PositionTagConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["PositionTagEdge"]>
};
	["PositionTagEdge"]: {
		node: ModelTypes["PositionTag"]
};
	["PositionTagListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["PositionTagListEdge"]>
};
	["PositionTagListEdge"]: {
		node: ModelTypes["PositionTagList"]
};
	["PositionCategoryConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["PositionCategoryEdge"]>
};
	["PositionCategoryEdge"]: {
		node: ModelTypes["PositionCategory"]
};
	["EmbedConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["EmbedEdge"]>
};
	["EmbedEdge"]: {
		node: ModelTypes["Embed"]
};
	["RedirectConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["RedirectEdge"]>
};
	["RedirectEdge"]: {
		node: ModelTypes["Redirect"]
};
	["TalkUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	slug?: string | undefined
};
	["TalkConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TalkEdge"]>
};
	["TalkEdge"]: {
		node: ModelTypes["Talk"]
};
	["TalkListsConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TalkListsEdge"]>
};
	["TalkListsEdge"]: {
		node: ModelTypes["TalkLists"]
};
	["BusinessCardConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["BusinessCardEdge"]>
};
	["BusinessCardEdge"]: {
		node: ModelTypes["BusinessCard"]
};
	["BusinessCardListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["BusinessCardListEdge"]>
};
	["BusinessCardListEdge"]: {
		node: ModelTypes["BusinessCardList"]
};
	["TestimonialConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TestimonialEdge"]>
};
	["TestimonialEdge"]: {
		node: ModelTypes["Testimonial"]
};
	["TestimonialListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TestimonialListEdge"]>
};
	["TestimonialListEdge"]: {
		node: ModelTypes["TestimonialList"]
};
	["ContactRequestOptionListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContactRequestOptionListEdge"]>
};
	["ContactRequestOptionListEdge"]: {
		node: ModelTypes["ContactRequestOptionList"]
};
	["YoutubeVideoConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["YoutubeVideoEdge"]>
};
	["YoutubeVideoEdge"]: {
		node: ModelTypes["YoutubeVideo"]
};
	["YoutubeVideoList"]: {
		_meta?: ModelTypes["YoutubeVideoListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["YoutubeVideoListItem"]>,
	itemsByYoutube?: ModelTypes["YoutubeVideoListItem"] | undefined,
	paginateItems: ModelTypes["YoutubeVideoListItemConnection"]
};
	["YoutubeVideoListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["YoutubeVideoListItem"]: {
		_meta?: ModelTypes["YoutubeVideoListItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	list?: ModelTypes["YoutubeVideoList"] | undefined,
	youtube?: ModelTypes["YoutubeVideo"] | undefined
};
	["YoutubeVideoListItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	youtube?: ModelTypes["FieldMeta"] | undefined
};
	["YoutubeVideoListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["YoutubeVideoListItemWhere"] | undefined,
	and?: Array<ModelTypes["YoutubeVideoListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["YoutubeVideoListWhere"] | undefined> | undefined,
	not?: ModelTypes["YoutubeVideoListWhere"] | undefined
};
	["YoutubeVideoListItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["YoutubeVideoListWhere"] | undefined,
	youtube?: ModelTypes["YoutubeVideoWhere"] | undefined,
	and?: Array<ModelTypes["YoutubeVideoListItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["YoutubeVideoListItemWhere"] | undefined> | undefined,
	not?: ModelTypes["YoutubeVideoListItemWhere"] | undefined
};
	["YoutubeVideoListItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["YoutubeVideoListOrderBy"] | undefined,
	youtube?: ModelTypes["YoutubeVideoOrderBy"] | undefined
};
	["YoutubeVideoListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["YoutubeVideoListItemsByYoutubeUniqueWhere"]: {
	youtube?: ModelTypes["YoutubeVideoUniqueWhere"] | undefined
};
	["YoutubeVideoListItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["YoutubeVideoListItemEdge"]>
};
	["YoutubeVideoListItemEdge"]: {
		node: ModelTypes["YoutubeVideoListItem"]
};
	["YoutubeVideoListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["YoutubeVideoListItemUniqueWhere"] | undefined
};
	["YoutubeVideoListItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	youtube?: ModelTypes["YoutubeVideoUniqueWhere"] | undefined
};
	["YoutubeVideoListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["YoutubeVideoListEdge"]>
};
	["YoutubeVideoListEdge"]: {
		node: ModelTypes["YoutubeVideoList"]
};
	["TranslationCatalogue"]: {
		_meta?: ModelTypes["TranslationCatalogueMeta"] | undefined,
	id: ModelTypes["UUID"],
	domain?: ModelTypes["TranslationDomain"] | undefined,
	identifier?: ModelTypes["TranslationCataloguesIdentifier"] | undefined,
	fallback?: ModelTypes["TranslationCatalogue"] | undefined,
	values: Array<ModelTypes["TranslationValue"]>,
	valuesByKey?: ModelTypes["TranslationValue"] | undefined,
	paginateValues: ModelTypes["TranslationValueConnection"]
};
	["TranslationCatalogueMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	domain?: ModelTypes["FieldMeta"] | undefined,
	identifier?: ModelTypes["FieldMeta"] | undefined,
	fallback?: ModelTypes["FieldMeta"] | undefined,
	values?: ModelTypes["FieldMeta"] | undefined
};
	["TranslationDomain"]: {
		_meta?: ModelTypes["TranslationDomainMeta"] | undefined,
	id: ModelTypes["UUID"],
	identifier: string,
	name: string,
	catalogues: Array<ModelTypes["TranslationCatalogue"]>,
	keys: Array<ModelTypes["TranslationKey"]>,
	cataloguesByIdentifier?: ModelTypes["TranslationCatalogue"] | undefined,
	cataloguesByValues?: ModelTypes["TranslationCatalogue"] | undefined,
	keysByIdentifier?: ModelTypes["TranslationKey"] | undefined,
	keysByValues?: ModelTypes["TranslationKey"] | undefined,
	paginateCatalogues: ModelTypes["TranslationCatalogueConnection"],
	paginateKeys: ModelTypes["TranslationKeyConnection"]
};
	["TranslationDomainMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	identifier?: ModelTypes["FieldMeta"] | undefined,
	name?: ModelTypes["FieldMeta"] | undefined,
	catalogues?: ModelTypes["FieldMeta"] | undefined,
	keys?: ModelTypes["FieldMeta"] | undefined
};
	["TranslationCatalogueWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	domain?: ModelTypes["TranslationDomainWhere"] | undefined,
	identifier?: ModelTypes["TranslationCataloguesIdentifierWhere"] | undefined,
	fallback?: ModelTypes["TranslationCatalogueWhere"] | undefined,
	values?: ModelTypes["TranslationValueWhere"] | undefined,
	and?: Array<ModelTypes["TranslationCatalogueWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TranslationCatalogueWhere"] | undefined> | undefined,
	not?: ModelTypes["TranslationCatalogueWhere"] | undefined
};
	["TranslationDomainWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	identifier?: ModelTypes["StringCondition"] | undefined,
	name?: ModelTypes["StringCondition"] | undefined,
	catalogues?: ModelTypes["TranslationCatalogueWhere"] | undefined,
	keys?: ModelTypes["TranslationKeyWhere"] | undefined,
	and?: Array<ModelTypes["TranslationDomainWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TranslationDomainWhere"] | undefined> | undefined,
	not?: ModelTypes["TranslationDomainWhere"] | undefined
};
	["TranslationKeyWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	identifier?: ModelTypes["StringCondition"] | undefined,
	contentType?: ModelTypes["TranslationContentTypeCondition"] | undefined,
	note?: ModelTypes["StringCondition"] | undefined,
	domain?: ModelTypes["TranslationDomainWhere"] | undefined,
	values?: ModelTypes["TranslationValueWhere"] | undefined,
	and?: Array<ModelTypes["TranslationKeyWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TranslationKeyWhere"] | undefined> | undefined,
	not?: ModelTypes["TranslationKeyWhere"] | undefined
};
	["TranslationContentTypeCondition"]: {
	and?: Array<ModelTypes["TranslationContentTypeCondition"]> | undefined,
	or?: Array<ModelTypes["TranslationContentTypeCondition"]> | undefined,
	not?: ModelTypes["TranslationContentTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["TranslationContentType"] | undefined,
	notEq?: ModelTypes["TranslationContentType"] | undefined,
	in?: Array<ModelTypes["TranslationContentType"]> | undefined,
	notIn?: Array<ModelTypes["TranslationContentType"]> | undefined,
	lt?: ModelTypes["TranslationContentType"] | undefined,
	lte?: ModelTypes["TranslationContentType"] | undefined,
	gt?: ModelTypes["TranslationContentType"] | undefined,
	gte?: ModelTypes["TranslationContentType"] | undefined
};
	["TranslationContentType"]:TranslationContentType;
	["TranslationValueWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	value?: ModelTypes["StringCondition"] | undefined,
	catalogue?: ModelTypes["TranslationCatalogueWhere"] | undefined,
	key?: ModelTypes["TranslationKeyWhere"] | undefined,
	and?: Array<ModelTypes["TranslationValueWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TranslationValueWhere"] | undefined> | undefined,
	not?: ModelTypes["TranslationValueWhere"] | undefined
};
	["TranslationCataloguesIdentifierWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	name?: ModelTypes["StringCondition"] | undefined,
	code?: ModelTypes["StringCondition"] | undefined,
	catalogue?: ModelTypes["TranslationCatalogueWhere"] | undefined,
	and?: Array<ModelTypes["TranslationCataloguesIdentifierWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TranslationCataloguesIdentifierWhere"] | undefined> | undefined,
	not?: ModelTypes["TranslationCataloguesIdentifierWhere"] | undefined
};
	["TranslationCatalogueOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	domain?: ModelTypes["TranslationDomainOrderBy"] | undefined,
	identifier?: ModelTypes["TranslationCataloguesIdentifierOrderBy"] | undefined,
	fallback?: ModelTypes["TranslationCatalogueOrderBy"] | undefined
};
	["TranslationDomainOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	identifier?: ModelTypes["OrderDirection"] | undefined,
	name?: ModelTypes["OrderDirection"] | undefined
};
	["TranslationCataloguesIdentifierOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	name?: ModelTypes["OrderDirection"] | undefined,
	code?: ModelTypes["OrderDirection"] | undefined
};
	["TranslationKey"]: {
		_meta?: ModelTypes["TranslationKeyMeta"] | undefined,
	id: ModelTypes["UUID"],
	identifier: string,
	contentType: ModelTypes["TranslationContentType"],
	note?: string | undefined,
	domain?: ModelTypes["TranslationDomain"] | undefined,
	values: Array<ModelTypes["TranslationValue"]>,
	valuesByCatalogue?: ModelTypes["TranslationValue"] | undefined,
	paginateValues: ModelTypes["TranslationValueConnection"]
};
	["TranslationKeyMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	identifier?: ModelTypes["FieldMeta"] | undefined,
	contentType?: ModelTypes["FieldMeta"] | undefined,
	note?: ModelTypes["FieldMeta"] | undefined,
	domain?: ModelTypes["FieldMeta"] | undefined,
	values?: ModelTypes["FieldMeta"] | undefined
};
	["TranslationValue"]: {
		_meta?: ModelTypes["TranslationValueMeta"] | undefined,
	id: ModelTypes["UUID"],
	value: string,
	catalogue?: ModelTypes["TranslationCatalogue"] | undefined,
	key?: ModelTypes["TranslationKey"] | undefined
};
	["TranslationValueMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	value?: ModelTypes["FieldMeta"] | undefined,
	catalogue?: ModelTypes["FieldMeta"] | undefined,
	key?: ModelTypes["FieldMeta"] | undefined
};
	["TranslationValueOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	value?: ModelTypes["OrderDirection"] | undefined,
	catalogue?: ModelTypes["TranslationCatalogueOrderBy"] | undefined,
	key?: ModelTypes["TranslationKeyOrderBy"] | undefined
};
	["TranslationKeyOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	identifier?: ModelTypes["OrderDirection"] | undefined,
	contentType?: ModelTypes["OrderDirection"] | undefined,
	note?: ModelTypes["OrderDirection"] | undefined,
	domain?: ModelTypes["TranslationDomainOrderBy"] | undefined
};
	["TranslationKeyValuesByCatalogueUniqueWhere"]: {
	catalogue?: ModelTypes["TranslationCatalogueUniqueWhere"] | undefined
};
	["TranslationCatalogueUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	domain?: ModelTypes["TranslationDomainUniqueWhere"] | undefined,
	identifier?: ModelTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined,
	values?: ModelTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationDomainUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	identifier?: string | undefined,
	catalogues?: ModelTypes["TranslationCatalogueUniqueWhere"] | undefined,
	keys?: ModelTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationKeyUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	domain?: ModelTypes["TranslationDomainUniqueWhere"] | undefined,
	identifier?: string | undefined,
	values?: ModelTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationValueUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	catalogue?: ModelTypes["TranslationCatalogueUniqueWhere"] | undefined,
	key?: ModelTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	code?: string | undefined,
	catalogue?: ModelTypes["TranslationCatalogueUniqueWhere"] | undefined
};
	["TranslationValueConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TranslationValueEdge"]>
};
	["TranslationValueEdge"]: {
		node: ModelTypes["TranslationValue"]
};
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
	identifier?: ModelTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined
};
	["TranslationDomainCataloguesByValuesUniqueWhere"]: {
	values?: ModelTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationDomainKeysByIdentifierUniqueWhere"]: {
	identifier?: string | undefined
};
	["TranslationDomainKeysByValuesUniqueWhere"]: {
	values?: ModelTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationCatalogueConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TranslationCatalogueEdge"]>
};
	["TranslationCatalogueEdge"]: {
		node: ModelTypes["TranslationCatalogue"]
};
	["TranslationKeyConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TranslationKeyEdge"]>
};
	["TranslationKeyEdge"]: {
		node: ModelTypes["TranslationKey"]
};
	["TranslationCataloguesIdentifier"]: {
		_meta?: ModelTypes["TranslationCataloguesIdentifierMeta"] | undefined,
	id: ModelTypes["UUID"],
	name: string,
	code: string,
	catalogue: Array<ModelTypes["TranslationCatalogue"]>,
	catalogueByDomain?: ModelTypes["TranslationCatalogue"] | undefined,
	catalogueByValues?: ModelTypes["TranslationCatalogue"] | undefined,
	paginateCatalogue: ModelTypes["TranslationCatalogueConnection"]
};
	["TranslationCataloguesIdentifierMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	name?: ModelTypes["FieldMeta"] | undefined,
	code?: ModelTypes["FieldMeta"] | undefined,
	catalogue?: ModelTypes["FieldMeta"] | undefined
};
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: {
	domain?: ModelTypes["TranslationDomainUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: {
	values?: ModelTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationCatalogueValuesByKeyUniqueWhere"]: {
	key?: ModelTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TranslationCataloguesIdentifierEdge"]>
};
	["TranslationCataloguesIdentifierEdge"]: {
		node: ModelTypes["TranslationCataloguesIdentifier"]
};
	["TranslationDomainConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TranslationDomainEdge"]>
};
	["TranslationDomainEdge"]: {
		node: ModelTypes["TranslationDomain"]
};
	["_ValidationResult"]: {
		valid: boolean,
	errors: Array<ModelTypes["_ValidationError"]>
};
	["_ValidationError"]: {
		path: Array<ModelTypes["_PathFragment"]>,
	message: ModelTypes["_ValidationMessage"]
};
	["_PathFragment"]:ModelTypes["_FieldPathFragment"] | ModelTypes["_IndexPathFragment"];
	["_FieldPathFragment"]: {
		field: string
};
	["_IndexPathFragment"]: {
		index: number,
	alias?: string | undefined
};
	["_ValidationMessage"]: {
		text: string
};
	["ContactRequestSubmissionCreateInput"]: {
	createdAt?: ModelTypes["DateTime"] | undefined,
	requestOption?: string | undefined,
	givenName?: string | undefined,
	familyName?: string | undefined,
	text?: string | undefined,
	locale?: ModelTypes["ContactRequestSubmissionCreateLocaleEntityRelationInput"] | undefined,
	email?: string | undefined,
	marketingConsent?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["ContactRequestSubmissionCreateLocaleEntityRelationInput"]: {
	connect?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["SubscribeFormUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	datacruitJobId?: number | undefined
};
	["SubscribeFormConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["SubscribeFormEdge"]>
};
	["SubscribeFormEdge"]: {
		node: ModelTypes["SubscribeForm"]
};
	["SubscribeFormCreateInput"]: {
	submissions?: Array<ModelTypes["SubscribeFormCreateSubmissionsEntityRelationInput"]> | undefined,
	name?: string | undefined,
	datacruitJobId?: number | undefined,
	datacruitReferenceNumber?: string | undefined,
	lastUpdateAt?: ModelTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["SubscribeFormCreateSubmissionsEntityRelationInput"]: {
	create?: ModelTypes["SubscribeSubmissionWithoutFormCreateInput"] | undefined,
	alias?: string | undefined
};
	["SubscribeSubmissionWithoutFormCreateInput"]: {
	createdAt?: ModelTypes["DateTime"] | undefined,
	email?: string | undefined,
	locale?: ModelTypes["SubscribeSubmissionCreateLocaleEntityRelationInput"] | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	datacruitId?: string | undefined,
	consent?: boolean | undefined,
	url?: string | undefined,
	attachmentFileName?: string | undefined,
	message?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["SubscribeSubmissionCreateLocaleEntityRelationInput"]: {
	connect?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["SubscribeFormUpdateInput"]: {
	name?: string | undefined,
	datacruitJobId?: number | undefined,
	datacruitReferenceNumber?: string | undefined,
	lastUpdateAt?: ModelTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["SubscribeSubmissionCreateInput"]: {
	createdAt?: ModelTypes["DateTime"] | undefined,
	email?: string | undefined,
	form?: ModelTypes["SubscribeSubmissionCreateFormEntityRelationInput"] | undefined,
	locale?: ModelTypes["SubscribeSubmissionCreateLocaleEntityRelationInput"] | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	datacruitId?: string | undefined,
	consent?: boolean | undefined,
	url?: string | undefined,
	attachmentFileName?: string | undefined,
	message?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["SubscribeSubmissionCreateFormEntityRelationInput"]: {
	connect?: ModelTypes["SubscribeFormUniqueWhere"] | undefined,
	create?: ModelTypes["SubscribeFormWithoutSubmissionsCreateInput"] | undefined,
	connectOrCreate?: ModelTypes["SubscribeSubmissionConnectOrCreateFormRelationInput"] | undefined
};
	["SubscribeFormWithoutSubmissionsCreateInput"]: {
	name?: string | undefined,
	datacruitJobId?: number | undefined,
	datacruitReferenceNumber?: string | undefined,
	lastUpdateAt?: ModelTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["SubscribeSubmissionConnectOrCreateFormRelationInput"]: {
	connect: ModelTypes["SubscribeFormUniqueWhere"],
	create: ModelTypes["SubscribeFormWithoutSubmissionsCreateInput"]
};
	["VideoUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["VideoConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["VideoEdge"]>
};
	["VideoEdge"]: {
		node: ModelTypes["Video"]
};
	["PositionListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["PositionListEdge"]>
};
	["PositionListEdge"]: {
		node: ModelTypes["PositionList"]
};
	["TestimonialFullConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TestimonialFullEdge"]>
};
	["TestimonialFullEdge"]: {
		node: ModelTypes["TestimonialFull"]
};
	["TestimonialFullListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TestimonialFullListEdge"]>
};
	["TestimonialFullListEdge"]: {
		node: ModelTypes["TestimonialFullList"]
};
	["AuthorConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["AuthorEdge"]>
};
	["AuthorEdge"]: {
		node: ModelTypes["Author"]
};
	["BlogPostCommonConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["BlogPostCommonEdge"]>
};
	["BlogPostCommonEdge"]: {
		node: ModelTypes["BlogPostCommon"]
};
	["QueryTransaction"]: {
		schema?: ModelTypes["_Schema"] | undefined,
	s3DummyQuery?: string | undefined,
	getGeneral?: ModelTypes["General"] | undefined,
	listGeneral: Array<ModelTypes["General"]>,
	paginateGeneral: ModelTypes["GeneralConnection"],
	getImage?: ModelTypes["Image"] | undefined,
	listImage: Array<ModelTypes["Image"]>,
	paginateImage: ModelTypes["ImageConnection"],
	getGenericPage?: ModelTypes["GenericPage"] | undefined,
	listGenericPage: Array<ModelTypes["GenericPage"]>,
	paginateGenericPage: ModelTypes["GenericPageConnection"],
	getLinkable?: ModelTypes["Linkable"] | undefined,
	listLinkable: Array<ModelTypes["Linkable"]>,
	paginateLinkable: ModelTypes["LinkableConnection"],
	getSeo?: ModelTypes["Seo"] | undefined,
	listSeo: Array<ModelTypes["Seo"]>,
	paginateSeo: ModelTypes["SeoConnection"],
	getContent?: ModelTypes["Content"] | undefined,
	listContent: Array<ModelTypes["Content"]>,
	paginateContent: ModelTypes["ContentConnection"],
	getContentBlock?: ModelTypes["ContentBlock"] | undefined,
	listContentBlock: Array<ModelTypes["ContentBlock"]>,
	paginateContentBlock: ModelTypes["ContentBlockConnection"],
	getContentReference?: ModelTypes["ContentReference"] | undefined,
	listContentReference: Array<ModelTypes["ContentReference"]>,
	paginateContentReference: ModelTypes["ContentReferenceConnection"],
	getGeneralLocale?: ModelTypes["GeneralLocale"] | undefined,
	listGeneralLocale: Array<ModelTypes["GeneralLocale"]>,
	paginateGeneralLocale: ModelTypes["GeneralLocaleConnection"],
	getLocale?: ModelTypes["Locale"] | undefined,
	listLocale: Array<ModelTypes["Locale"]>,
	paginateLocale: ModelTypes["LocaleConnection"],
	getGenericPageLocale?: ModelTypes["GenericPageLocale"] | undefined,
	listGenericPageLocale: Array<ModelTypes["GenericPageLocale"]>,
	paginateGenericPageLocale: ModelTypes["GenericPageLocaleConnection"],
	getLink?: ModelTypes["Link"] | undefined,
	listLink: Array<ModelTypes["Link"]>,
	paginateLink: ModelTypes["LinkConnection"],
	getLinkList?: ModelTypes["LinkList"] | undefined,
	listLinkList: Array<ModelTypes["LinkList"]>,
	paginateLinkList: ModelTypes["LinkListConnection"],
	getLinkListItem?: ModelTypes["LinkListItem"] | undefined,
	listLinkListItem: Array<ModelTypes["LinkListItem"]>,
	paginateLinkListItem: ModelTypes["LinkListItemConnection"],
	getHeader?: ModelTypes["Header"] | undefined,
	listHeader: Array<ModelTypes["Header"]>,
	paginateHeader: ModelTypes["HeaderConnection"],
	getHeaderLocale?: ModelTypes["HeaderLocale"] | undefined,
	listHeaderLocale: Array<ModelTypes["HeaderLocale"]>,
	paginateHeaderLocale: ModelTypes["HeaderLocaleConnection"],
	getFooter?: ModelTypes["Footer"] | undefined,
	listFooter: Array<ModelTypes["Footer"]>,
	paginateFooter: ModelTypes["FooterConnection"],
	getFooterLocale?: ModelTypes["FooterLocale"] | undefined,
	listFooterLocale: Array<ModelTypes["FooterLocale"]>,
	paginateFooterLocale: ModelTypes["FooterLocaleConnection"],
	getCaseStudy?: ModelTypes["CaseStudy"] | undefined,
	listCaseStudy: Array<ModelTypes["CaseStudy"]>,
	paginateCaseStudy: ModelTypes["CaseStudyConnection"],
	getCaseStudyLocale?: ModelTypes["CaseStudyLocale"] | undefined,
	listCaseStudyLocale: Array<ModelTypes["CaseStudyLocale"]>,
	paginateCaseStudyLocale: ModelTypes["CaseStudyLocaleConnection"],
	getTag?: ModelTypes["Tag"] | undefined,
	listTag: Array<ModelTypes["Tag"]>,
	paginateTag: ModelTypes["TagConnection"],
	getTagLocale?: ModelTypes["TagLocale"] | undefined,
	listTagLocale: Array<ModelTypes["TagLocale"]>,
	paginateTagLocale: ModelTypes["TagLocaleConnection"],
	getCaseStudyTag?: ModelTypes["CaseStudyTag"] | undefined,
	listCaseStudyTag: Array<ModelTypes["CaseStudyTag"]>,
	paginateCaseStudyTag: ModelTypes["CaseStudyTagConnection"],
	getCaseStudyList?: ModelTypes["CaseStudyList"] | undefined,
	listCaseStudyList: Array<ModelTypes["CaseStudyList"]>,
	paginateCaseStudyList: ModelTypes["CaseStudyListConnection"],
	getCaseStudyListItem?: ModelTypes["CaseStudyListItem"] | undefined,
	listCaseStudyListItem: Array<ModelTypes["CaseStudyListItem"]>,
	paginateCaseStudyListItem: ModelTypes["CaseStudyListItemConnection"],
	getImageList?: ModelTypes["ImageList"] | undefined,
	listImageList: Array<ModelTypes["ImageList"]>,
	paginateImageList: ModelTypes["ImageListConnection"],
	getImageListItem?: ModelTypes["ImageListItem"] | undefined,
	listImageListItem: Array<ModelTypes["ImageListItem"]>,
	paginateImageListItem: ModelTypes["ImageListItemConnection"],
	getInformationList?: ModelTypes["InformationList"] | undefined,
	listInformationList: Array<ModelTypes["InformationList"]>,
	paginateInformationList: ModelTypes["InformationListConnection"],
	getPieceOfInformation?: ModelTypes["PieceOfInformation"] | undefined,
	listPieceOfInformation: Array<ModelTypes["PieceOfInformation"]>,
	paginatePieceOfInformation: ModelTypes["PieceOfInformationConnection"],
	getPositionTag?: ModelTypes["PositionTag"] | undefined,
	listPositionTag: Array<ModelTypes["PositionTag"]>,
	paginatePositionTag: ModelTypes["PositionTagConnection"],
	getPositionTagLocale?: ModelTypes["PositionTagLocale"] | undefined,
	listPositionTagLocale: Array<ModelTypes["PositionTagLocale"]>,
	paginatePositionTagLocale: ModelTypes["PositionTagLocaleConnection"],
	getPosition?: ModelTypes["Position"] | undefined,
	listPosition: Array<ModelTypes["Position"]>,
	paginatePosition: ModelTypes["PositionConnection"],
	getPositionLocale?: ModelTypes["PositionLocale"] | undefined,
	listPositionLocale: Array<ModelTypes["PositionLocale"]>,
	paginatePositionLocale: ModelTypes["PositionLocaleConnection"],
	getPositionTagList?: ModelTypes["PositionTagList"] | undefined,
	listPositionTagList: Array<ModelTypes["PositionTagList"]>,
	paginatePositionTagList: ModelTypes["PositionTagListConnection"],
	getPositionTagListItem?: ModelTypes["PositionTagListItem"] | undefined,
	listPositionTagListItem: Array<ModelTypes["PositionTagListItem"]>,
	paginatePositionTagListItem: ModelTypes["PositionTagListItemConnection"],
	getPositionCategory?: ModelTypes["PositionCategory"] | undefined,
	listPositionCategory: Array<ModelTypes["PositionCategory"]>,
	paginatePositionCategory: ModelTypes["PositionCategoryConnection"],
	getPositionCategoryLocale?: ModelTypes["PositionCategoryLocale"] | undefined,
	listPositionCategoryLocale: Array<ModelTypes["PositionCategoryLocale"]>,
	paginatePositionCategoryLocale: ModelTypes["PositionCategoryLocaleConnection"],
	getEmbed?: ModelTypes["Embed"] | undefined,
	listEmbed: Array<ModelTypes["Embed"]>,
	paginateEmbed: ModelTypes["EmbedConnection"],
	getRedirect?: ModelTypes["Redirect"] | undefined,
	listRedirect: Array<ModelTypes["Redirect"]>,
	paginateRedirect: ModelTypes["RedirectConnection"],
	getTalk?: ModelTypes["Talk"] | undefined,
	listTalk: Array<ModelTypes["Talk"]>,
	paginateTalk: ModelTypes["TalkConnection"],
	getTalkList?: ModelTypes["TalkList"] | undefined,
	listTalkList: Array<ModelTypes["TalkList"]>,
	paginateTalkList: ModelTypes["TalkListConnection"],
	getTalkListItem?: ModelTypes["TalkListItem"] | undefined,
	listTalkListItem: Array<ModelTypes["TalkListItem"]>,
	paginateTalkListItem: ModelTypes["TalkListItemConnection"],
	getTalkLists?: ModelTypes["TalkLists"] | undefined,
	listTalkLists: Array<ModelTypes["TalkLists"]>,
	paginateTalkLists: ModelTypes["TalkListsConnection"],
	getBusinessCard?: ModelTypes["BusinessCard"] | undefined,
	listBusinessCard: Array<ModelTypes["BusinessCard"]>,
	paginateBusinessCard: ModelTypes["BusinessCardConnection"],
	getBusinessCardList?: ModelTypes["BusinessCardList"] | undefined,
	listBusinessCardList: Array<ModelTypes["BusinessCardList"]>,
	paginateBusinessCardList: ModelTypes["BusinessCardListConnection"],
	getBusinessCardListItem?: ModelTypes["BusinessCardListItem"] | undefined,
	listBusinessCardListItem: Array<ModelTypes["BusinessCardListItem"]>,
	paginateBusinessCardListItem: ModelTypes["BusinessCardListItemConnection"],
	getTestimonial?: ModelTypes["Testimonial"] | undefined,
	listTestimonial: Array<ModelTypes["Testimonial"]>,
	paginateTestimonial: ModelTypes["TestimonialConnection"],
	getTestimonialList?: ModelTypes["TestimonialList"] | undefined,
	listTestimonialList: Array<ModelTypes["TestimonialList"]>,
	paginateTestimonialList: ModelTypes["TestimonialListConnection"],
	getTestimonialListItem?: ModelTypes["TestimonialListItem"] | undefined,
	listTestimonialListItem: Array<ModelTypes["TestimonialListItem"]>,
	paginateTestimonialListItem: ModelTypes["TestimonialListItemConnection"],
	getContactRequestOption?: ModelTypes["ContactRequestOption"] | undefined,
	listContactRequestOption: Array<ModelTypes["ContactRequestOption"]>,
	paginateContactRequestOption: ModelTypes["ContactRequestOptionConnection"],
	getContactRequestOptionList?: ModelTypes["ContactRequestOptionList"] | undefined,
	listContactRequestOptionList: Array<ModelTypes["ContactRequestOptionList"]>,
	paginateContactRequestOptionList: ModelTypes["ContactRequestOptionListConnection"],
	getYoutubeVideo?: ModelTypes["YoutubeVideo"] | undefined,
	listYoutubeVideo: Array<ModelTypes["YoutubeVideo"]>,
	paginateYoutubeVideo: ModelTypes["YoutubeVideoConnection"],
	getYoutubeVideoList?: ModelTypes["YoutubeVideoList"] | undefined,
	listYoutubeVideoList: Array<ModelTypes["YoutubeVideoList"]>,
	paginateYoutubeVideoList: ModelTypes["YoutubeVideoListConnection"],
	getYoutubeVideoListItem?: ModelTypes["YoutubeVideoListItem"] | undefined,
	listYoutubeVideoListItem: Array<ModelTypes["YoutubeVideoListItem"]>,
	paginateYoutubeVideoListItem: ModelTypes["YoutubeVideoListItemConnection"],
	getTranslationCatalogue?: ModelTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<ModelTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: ModelTypes["TranslationCatalogueConnection"],
	getTranslationCataloguesIdentifier?: ModelTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<ModelTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: ModelTypes["TranslationCataloguesIdentifierConnection"],
	getTranslationDomain?: ModelTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<ModelTypes["TranslationDomain"]>,
	paginateTranslationDomain: ModelTypes["TranslationDomainConnection"],
	getTranslationKey?: ModelTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<ModelTypes["TranslationKey"]>,
	paginateTranslationKey: ModelTypes["TranslationKeyConnection"],
	getTranslationValue?: ModelTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<ModelTypes["TranslationValue"]>,
	paginateTranslationValue: ModelTypes["TranslationValueConnection"],
	validateCreateContactRequestSubmission: ModelTypes["_ValidationResult"],
	getSubscribeForm?: ModelTypes["SubscribeForm"] | undefined,
	listSubscribeForm: Array<ModelTypes["SubscribeForm"]>,
	paginateSubscribeForm: ModelTypes["SubscribeFormConnection"],
	validateCreateSubscribeForm: ModelTypes["_ValidationResult"],
	validateUpdateSubscribeForm: ModelTypes["_ValidationResult"],
	validateCreateSubscribeSubmission: ModelTypes["_ValidationResult"],
	getContentReferencePositionCategoryItem?: ModelTypes["ContentReferencePositionCategoryItem"] | undefined,
	listContentReferencePositionCategoryItem: Array<ModelTypes["ContentReferencePositionCategoryItem"]>,
	paginateContentReferencePositionCategoryItem: ModelTypes["ContentReferencePositionCategoryItemConnection"],
	getVideo?: ModelTypes["Video"] | undefined,
	listVideo: Array<ModelTypes["Video"]>,
	paginateVideo: ModelTypes["VideoConnection"],
	getContentReferenceCaseStudyItem?: ModelTypes["ContentReferenceCaseStudyItem"] | undefined,
	listContentReferenceCaseStudyItem: Array<ModelTypes["ContentReferenceCaseStudyItem"]>,
	paginateContentReferenceCaseStudyItem: ModelTypes["ContentReferenceCaseStudyItemConnection"],
	getContentReferenceTagItem?: ModelTypes["ContentReferenceTagItem"] | undefined,
	listContentReferenceTagItem: Array<ModelTypes["ContentReferenceTagItem"]>,
	paginateContentReferenceTagItem: ModelTypes["ContentReferenceTagItemConnection"],
	getPositionList?: ModelTypes["PositionList"] | undefined,
	listPositionList: Array<ModelTypes["PositionList"]>,
	paginatePositionList: ModelTypes["PositionListConnection"],
	getPositionListItem?: ModelTypes["PositionListItem"] | undefined,
	listPositionListItem: Array<ModelTypes["PositionListItem"]>,
	paginatePositionListItem: ModelTypes["PositionListItemConnection"],
	getTestimonialFull?: ModelTypes["TestimonialFull"] | undefined,
	listTestimonialFull: Array<ModelTypes["TestimonialFull"]>,
	paginateTestimonialFull: ModelTypes["TestimonialFullConnection"],
	getTestimonialFullList?: ModelTypes["TestimonialFullList"] | undefined,
	listTestimonialFullList: Array<ModelTypes["TestimonialFullList"]>,
	paginateTestimonialFullList: ModelTypes["TestimonialFullListConnection"],
	getTestimonialFullListItem?: ModelTypes["TestimonialFullListItem"] | undefined,
	listTestimonialFullListItem: Array<ModelTypes["TestimonialFullListItem"]>,
	paginateTestimonialFullListItem: ModelTypes["TestimonialFullListItemConnection"],
	getSecondLevelLinkItem?: ModelTypes["SecondLevelLinkItem"] | undefined,
	listSecondLevelLinkItem: Array<ModelTypes["SecondLevelLinkItem"]>,
	paginateSecondLevelLinkItem: ModelTypes["SecondLevelLinkItemConnection"],
	getThirdLevelLinkItem?: ModelTypes["ThirdLevelLinkItem"] | undefined,
	listThirdLevelLinkItem: Array<ModelTypes["ThirdLevelLinkItem"]>,
	paginateThirdLevelLinkItem: ModelTypes["ThirdLevelLinkItemConnection"],
	getTopLevelLinkItem?: ModelTypes["TopLevelLinkItem"] | undefined,
	listTopLevelLinkItem: Array<ModelTypes["TopLevelLinkItem"]>,
	paginateTopLevelLinkItem: ModelTypes["TopLevelLinkItemConnection"],
	getAuthor?: ModelTypes["Author"] | undefined,
	listAuthor: Array<ModelTypes["Author"]>,
	paginateAuthor: ModelTypes["AuthorConnection"],
	getAuthorLocale?: ModelTypes["AuthorLocale"] | undefined,
	listAuthorLocale: Array<ModelTypes["AuthorLocale"]>,
	paginateAuthorLocale: ModelTypes["AuthorLocaleConnection"],
	getBlogPost?: ModelTypes["BlogPost"] | undefined,
	listBlogPost: Array<ModelTypes["BlogPost"]>,
	paginateBlogPost: ModelTypes["BlogPostConnection"],
	getBlogPostLocale?: ModelTypes["BlogPostLocale"] | undefined,
	listBlogPostLocale: Array<ModelTypes["BlogPostLocale"]>,
	paginateBlogPostLocale: ModelTypes["BlogPostLocaleConnection"],
	getContentReferenceBlogPostItem?: ModelTypes["ContentReferenceBlogPostItem"] | undefined,
	listContentReferenceBlogPostItem: Array<ModelTypes["ContentReferenceBlogPostItem"]>,
	paginateContentReferenceBlogPostItem: ModelTypes["ContentReferenceBlogPostItemConnection"],
	getRelevantBlogPostList?: ModelTypes["RelevantBlogPostList"] | undefined,
	listRelevantBlogPostList: Array<ModelTypes["RelevantBlogPostList"]>,
	paginateRelevantBlogPostList: ModelTypes["RelevantBlogPostListConnection"],
	getBlogPostCommon?: ModelTypes["BlogPostCommon"] | undefined,
	listBlogPostCommon: Array<ModelTypes["BlogPostCommon"]>,
	paginateBlogPostCommon: ModelTypes["BlogPostCommonConnection"],
	getBlogPostCommonLocale?: ModelTypes["BlogPostCommonLocale"] | undefined,
	listBlogPostCommonLocale: Array<ModelTypes["BlogPostCommonLocale"]>,
	paginateBlogPostCommonLocale: ModelTypes["BlogPostCommonLocaleConnection"]
};
	["Info"]: {
		description?: string | undefined
};
	["Mutation"]: {
		generateUploadUrl: ModelTypes["S3SignedUpload"],
	generateReadUrl: ModelTypes["S3SignedRead"],
	createContactRequestSubmission: ModelTypes["ContactRequestSubmissionCreateResult"],
	createSubscribeForm: ModelTypes["SubscribeFormCreateResult"],
	updateSubscribeForm: ModelTypes["SubscribeFormUpdateResult"],
	upsertSubscribeForm: ModelTypes["SubscribeFormUpsertResult"],
	createSubscribeSubmission: ModelTypes["SubscribeSubmissionCreateResult"],
	transaction: ModelTypes["MutationTransaction"],
	query: ModelTypes["Query"]
};
	["S3SignedUpload"]: {
		url: string,
	headers: Array<ModelTypes["S3Header"]>,
	method: string,
	objectKey: string,
	bucket: string,
	publicUrl: string
};
	["S3Header"]: {
		key: string,
	value: string
};
	["S3GenerateSignedUploadInput"]: {
	contentType?: string | undefined,
	/** If not provided, extension is detected from a content-type. */
	extension?: string | undefined,
	/** Signed URL expiration. */
	expiration?: number | undefined,
	/** Uploaded file size. Required when you enable ACL size limits. */
	size?: number | undefined,
	/** Can be used as a "directory". */
	prefix?: string | undefined,
	/** Suffix after generated id and before the extension. */
	suffix?: string | undefined,
	/** This only affects Content-disposition header. Does not affect actual object key. */
	fileName?: string | undefined,
	contentDisposition?: ModelTypes["S33ContentDisposition"] | undefined,
	/** If not supported by S3 provider, an error is thrown. */
	acl?: ModelTypes["S3Acl"] | undefined
};
	["S33ContentDisposition"]:S33ContentDisposition;
	["S3Acl"]:S3Acl;
	["S3SignedRead"]: {
		url: string,
	headers: Array<ModelTypes["S3Header"]>,
	method: string,
	objectKey: string,
	bucket: string
};
	["ContactRequestSubmissionCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	validation: ModelTypes["_ValidationResult"]
};
	["MutationResult"]: ModelTypes["ContactRequestSubmissionCreateResult"] | ModelTypes["SubscribeFormCreateResult"] | ModelTypes["SubscribeFormUpdateResult"] | ModelTypes["SubscribeFormUpsertResult"] | ModelTypes["SubscribeSubmissionCreateResult"];
	["_MutationError"]: {
		path: Array<ModelTypes["_PathFragment"]>,
	paths: Array<Array<ModelTypes["_PathFragment"]>>,
	type: ModelTypes["_MutationErrorType"],
	message?: string | undefined
};
	["_MutationErrorType"]:_MutationErrorType;
	["SubscribeFormCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	node?: ModelTypes["SubscribeForm"] | undefined,
	validation: ModelTypes["_ValidationResult"]
};
	["SubscribeFormUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	node?: ModelTypes["SubscribeForm"] | undefined,
	validation: ModelTypes["_ValidationResult"]
};
	["SubscribeFormUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	node?: ModelTypes["SubscribeForm"] | undefined,
	validation: ModelTypes["_ValidationResult"]
};
	["SubscribeSubmissionCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	validation: ModelTypes["_ValidationResult"]
};
	["MutationTransaction"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	validation: ModelTypes["_ValidationResult"],
	generateUploadUrl: ModelTypes["S3SignedUpload"],
	generateReadUrl: ModelTypes["S3SignedRead"],
	createContactRequestSubmission: ModelTypes["ContactRequestSubmissionCreateResult"],
	createSubscribeForm: ModelTypes["SubscribeFormCreateResult"],
	updateSubscribeForm: ModelTypes["SubscribeFormUpdateResult"],
	upsertSubscribeForm: ModelTypes["SubscribeFormUpsertResult"],
	createSubscribeSubmission: ModelTypes["SubscribeSubmissionCreateResult"],
	query?: ModelTypes["Query"] | undefined
};
	["MutationTransactionOptions"]: {
	deferForeignKeyConstraints?: boolean | undefined,
	deferUniqueConstraints?: boolean | undefined
}
    }

export type GraphQLTypes = {
    ["_Column"]: {
	__typename: "_Column",
	name: string,
	type: string,
	enumName?: string | undefined,
	defaultValue?: GraphQLTypes["Json"] | undefined,
	nullable: boolean,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_Field"]: {
	__typename:"_Column" | "_Relation",
	name: string,
	type: string,
	nullable?: boolean | undefined,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
	['...on _Column']: '__union' & GraphQLTypes["_Column"];
	['...on _Relation']: '__union' & GraphQLTypes["_Relation"];
};
	["_Rule"]: {
	__typename: "_Rule",
	message?: GraphQLTypes["_RuleMessage"] | undefined,
	validator: number
};
	["_RuleMessage"]: {
	__typename: "_RuleMessage",
	text?: string | undefined
};
	["_Validator"]: {
	__typename: "_Validator",
	operation: string,
	arguments: Array<GraphQLTypes["_Argument"]>
};
	["_Argument"]:{
        	__typename:"_ValidatorArgument" | "_PathArgument" | "_LiteralArgument"
        	['...on _ValidatorArgument']: '__union' & GraphQLTypes["_ValidatorArgument"];
	['...on _PathArgument']: '__union' & GraphQLTypes["_PathArgument"];
	['...on _LiteralArgument']: '__union' & GraphQLTypes["_LiteralArgument"];
};
	["_ValidatorArgument"]: {
	__typename: "_ValidatorArgument",
	validator: number
};
	["_PathArgument"]: {
	__typename: "_PathArgument",
	path: Array<string>
};
	["_LiteralArgument"]: {
	__typename: "_LiteralArgument",
	value?: GraphQLTypes["Json"] | undefined
};
	/** Json custom scalar type */
["Json"]: "scalar" & { name: "Json" };
	["_Relation"]: {
	__typename: "_Relation",
	name: string,
	type: string,
	side: GraphQLTypes["_RelationSide"],
	targetEntity: string,
	ownedBy?: string | undefined,
	inversedBy?: string | undefined,
	nullable?: boolean | undefined,
	onDelete?: GraphQLTypes["_OnDeleteBehaviour"] | undefined,
	orphanRemoval?: boolean | undefined,
	orderBy?: Array<GraphQLTypes["_OrderBy"]> | undefined,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_RelationSide"]: _RelationSide;
	["_OnDeleteBehaviour"]: _OnDeleteBehaviour;
	["_OrderBy"]: {
	__typename: "_OrderBy",
	path: Array<string>,
	direction: GraphQLTypes["_OrderByDirection"]
};
	["_OrderByDirection"]: _OrderByDirection;
	["Query"]: {
	__typename: "Query",
	schema?: GraphQLTypes["_Schema"] | undefined,
	s3DummyQuery?: string | undefined,
	getGeneral?: GraphQLTypes["General"] | undefined,
	listGeneral: Array<GraphQLTypes["General"]>,
	paginateGeneral: GraphQLTypes["GeneralConnection"],
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getGenericPage?: GraphQLTypes["GenericPage"] | undefined,
	listGenericPage: Array<GraphQLTypes["GenericPage"]>,
	paginateGenericPage: GraphQLTypes["GenericPageConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getGeneralLocale?: GraphQLTypes["GeneralLocale"] | undefined,
	listGeneralLocale: Array<GraphQLTypes["GeneralLocale"]>,
	paginateGeneralLocale: GraphQLTypes["GeneralLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getGenericPageLocale?: GraphQLTypes["GenericPageLocale"] | undefined,
	listGenericPageLocale: Array<GraphQLTypes["GenericPageLocale"]>,
	paginateGenericPageLocale: GraphQLTypes["GenericPageLocaleConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getLinkList?: GraphQLTypes["LinkList"] | undefined,
	listLinkList: Array<GraphQLTypes["LinkList"]>,
	paginateLinkList: GraphQLTypes["LinkListConnection"],
	getLinkListItem?: GraphQLTypes["LinkListItem"] | undefined,
	listLinkListItem: Array<GraphQLTypes["LinkListItem"]>,
	paginateLinkListItem: GraphQLTypes["LinkListItemConnection"],
	getHeader?: GraphQLTypes["Header"] | undefined,
	listHeader: Array<GraphQLTypes["Header"]>,
	paginateHeader: GraphQLTypes["HeaderConnection"],
	getHeaderLocale?: GraphQLTypes["HeaderLocale"] | undefined,
	listHeaderLocale: Array<GraphQLTypes["HeaderLocale"]>,
	paginateHeaderLocale: GraphQLTypes["HeaderLocaleConnection"],
	getFooter?: GraphQLTypes["Footer"] | undefined,
	listFooter: Array<GraphQLTypes["Footer"]>,
	paginateFooter: GraphQLTypes["FooterConnection"],
	getFooterLocale?: GraphQLTypes["FooterLocale"] | undefined,
	listFooterLocale: Array<GraphQLTypes["FooterLocale"]>,
	paginateFooterLocale: GraphQLTypes["FooterLocaleConnection"],
	getCaseStudy?: GraphQLTypes["CaseStudy"] | undefined,
	listCaseStudy: Array<GraphQLTypes["CaseStudy"]>,
	paginateCaseStudy: GraphQLTypes["CaseStudyConnection"],
	getCaseStudyLocale?: GraphQLTypes["CaseStudyLocale"] | undefined,
	listCaseStudyLocale: Array<GraphQLTypes["CaseStudyLocale"]>,
	paginateCaseStudyLocale: GraphQLTypes["CaseStudyLocaleConnection"],
	getTag?: GraphQLTypes["Tag"] | undefined,
	listTag: Array<GraphQLTypes["Tag"]>,
	paginateTag: GraphQLTypes["TagConnection"],
	getTagLocale?: GraphQLTypes["TagLocale"] | undefined,
	listTagLocale: Array<GraphQLTypes["TagLocale"]>,
	paginateTagLocale: GraphQLTypes["TagLocaleConnection"],
	getCaseStudyTag?: GraphQLTypes["CaseStudyTag"] | undefined,
	listCaseStudyTag: Array<GraphQLTypes["CaseStudyTag"]>,
	paginateCaseStudyTag: GraphQLTypes["CaseStudyTagConnection"],
	getCaseStudyList?: GraphQLTypes["CaseStudyList"] | undefined,
	listCaseStudyList: Array<GraphQLTypes["CaseStudyList"]>,
	paginateCaseStudyList: GraphQLTypes["CaseStudyListConnection"],
	getCaseStudyListItem?: GraphQLTypes["CaseStudyListItem"] | undefined,
	listCaseStudyListItem: Array<GraphQLTypes["CaseStudyListItem"]>,
	paginateCaseStudyListItem: GraphQLTypes["CaseStudyListItemConnection"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getInformationList?: GraphQLTypes["InformationList"] | undefined,
	listInformationList: Array<GraphQLTypes["InformationList"]>,
	paginateInformationList: GraphQLTypes["InformationListConnection"],
	getPieceOfInformation?: GraphQLTypes["PieceOfInformation"] | undefined,
	listPieceOfInformation: Array<GraphQLTypes["PieceOfInformation"]>,
	paginatePieceOfInformation: GraphQLTypes["PieceOfInformationConnection"],
	getPositionTag?: GraphQLTypes["PositionTag"] | undefined,
	listPositionTag: Array<GraphQLTypes["PositionTag"]>,
	paginatePositionTag: GraphQLTypes["PositionTagConnection"],
	getPositionTagLocale?: GraphQLTypes["PositionTagLocale"] | undefined,
	listPositionTagLocale: Array<GraphQLTypes["PositionTagLocale"]>,
	paginatePositionTagLocale: GraphQLTypes["PositionTagLocaleConnection"],
	getPosition?: GraphQLTypes["Position"] | undefined,
	listPosition: Array<GraphQLTypes["Position"]>,
	paginatePosition: GraphQLTypes["PositionConnection"],
	getPositionLocale?: GraphQLTypes["PositionLocale"] | undefined,
	listPositionLocale: Array<GraphQLTypes["PositionLocale"]>,
	paginatePositionLocale: GraphQLTypes["PositionLocaleConnection"],
	getPositionTagList?: GraphQLTypes["PositionTagList"] | undefined,
	listPositionTagList: Array<GraphQLTypes["PositionTagList"]>,
	paginatePositionTagList: GraphQLTypes["PositionTagListConnection"],
	getPositionTagListItem?: GraphQLTypes["PositionTagListItem"] | undefined,
	listPositionTagListItem: Array<GraphQLTypes["PositionTagListItem"]>,
	paginatePositionTagListItem: GraphQLTypes["PositionTagListItemConnection"],
	getPositionCategory?: GraphQLTypes["PositionCategory"] | undefined,
	listPositionCategory: Array<GraphQLTypes["PositionCategory"]>,
	paginatePositionCategory: GraphQLTypes["PositionCategoryConnection"],
	getPositionCategoryLocale?: GraphQLTypes["PositionCategoryLocale"] | undefined,
	listPositionCategoryLocale: Array<GraphQLTypes["PositionCategoryLocale"]>,
	paginatePositionCategoryLocale: GraphQLTypes["PositionCategoryLocaleConnection"],
	getEmbed?: GraphQLTypes["Embed"] | undefined,
	listEmbed: Array<GraphQLTypes["Embed"]>,
	paginateEmbed: GraphQLTypes["EmbedConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getTalk?: GraphQLTypes["Talk"] | undefined,
	listTalk: Array<GraphQLTypes["Talk"]>,
	paginateTalk: GraphQLTypes["TalkConnection"],
	getTalkList?: GraphQLTypes["TalkList"] | undefined,
	listTalkList: Array<GraphQLTypes["TalkList"]>,
	paginateTalkList: GraphQLTypes["TalkListConnection"],
	getTalkListItem?: GraphQLTypes["TalkListItem"] | undefined,
	listTalkListItem: Array<GraphQLTypes["TalkListItem"]>,
	paginateTalkListItem: GraphQLTypes["TalkListItemConnection"],
	getTalkLists?: GraphQLTypes["TalkLists"] | undefined,
	listTalkLists: Array<GraphQLTypes["TalkLists"]>,
	paginateTalkLists: GraphQLTypes["TalkListsConnection"],
	getBusinessCard?: GraphQLTypes["BusinessCard"] | undefined,
	listBusinessCard: Array<GraphQLTypes["BusinessCard"]>,
	paginateBusinessCard: GraphQLTypes["BusinessCardConnection"],
	getBusinessCardList?: GraphQLTypes["BusinessCardList"] | undefined,
	listBusinessCardList: Array<GraphQLTypes["BusinessCardList"]>,
	paginateBusinessCardList: GraphQLTypes["BusinessCardListConnection"],
	getBusinessCardListItem?: GraphQLTypes["BusinessCardListItem"] | undefined,
	listBusinessCardListItem: Array<GraphQLTypes["BusinessCardListItem"]>,
	paginateBusinessCardListItem: GraphQLTypes["BusinessCardListItemConnection"],
	getTestimonial?: GraphQLTypes["Testimonial"] | undefined,
	listTestimonial: Array<GraphQLTypes["Testimonial"]>,
	paginateTestimonial: GraphQLTypes["TestimonialConnection"],
	getTestimonialList?: GraphQLTypes["TestimonialList"] | undefined,
	listTestimonialList: Array<GraphQLTypes["TestimonialList"]>,
	paginateTestimonialList: GraphQLTypes["TestimonialListConnection"],
	getTestimonialListItem?: GraphQLTypes["TestimonialListItem"] | undefined,
	listTestimonialListItem: Array<GraphQLTypes["TestimonialListItem"]>,
	paginateTestimonialListItem: GraphQLTypes["TestimonialListItemConnection"],
	getContactRequestOption?: GraphQLTypes["ContactRequestOption"] | undefined,
	listContactRequestOption: Array<GraphQLTypes["ContactRequestOption"]>,
	paginateContactRequestOption: GraphQLTypes["ContactRequestOptionConnection"],
	getContactRequestOptionList?: GraphQLTypes["ContactRequestOptionList"] | undefined,
	listContactRequestOptionList: Array<GraphQLTypes["ContactRequestOptionList"]>,
	paginateContactRequestOptionList: GraphQLTypes["ContactRequestOptionListConnection"],
	getYoutubeVideo?: GraphQLTypes["YoutubeVideo"] | undefined,
	listYoutubeVideo: Array<GraphQLTypes["YoutubeVideo"]>,
	paginateYoutubeVideo: GraphQLTypes["YoutubeVideoConnection"],
	getYoutubeVideoList?: GraphQLTypes["YoutubeVideoList"] | undefined,
	listYoutubeVideoList: Array<GraphQLTypes["YoutubeVideoList"]>,
	paginateYoutubeVideoList: GraphQLTypes["YoutubeVideoListConnection"],
	getYoutubeVideoListItem?: GraphQLTypes["YoutubeVideoListItem"] | undefined,
	listYoutubeVideoListItem: Array<GraphQLTypes["YoutubeVideoListItem"]>,
	paginateYoutubeVideoListItem: GraphQLTypes["YoutubeVideoListItemConnection"],
	getTranslationCatalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: GraphQLTypes["TranslationCatalogueConnection"],
	getTranslationCataloguesIdentifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<GraphQLTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: GraphQLTypes["TranslationCataloguesIdentifierConnection"],
	getTranslationDomain?: GraphQLTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<GraphQLTypes["TranslationDomain"]>,
	paginateTranslationDomain: GraphQLTypes["TranslationDomainConnection"],
	getTranslationKey?: GraphQLTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<GraphQLTypes["TranslationKey"]>,
	paginateTranslationKey: GraphQLTypes["TranslationKeyConnection"],
	getTranslationValue?: GraphQLTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<GraphQLTypes["TranslationValue"]>,
	paginateTranslationValue: GraphQLTypes["TranslationValueConnection"],
	validateCreateContactRequestSubmission: GraphQLTypes["_ValidationResult"],
	getSubscribeForm?: GraphQLTypes["SubscribeForm"] | undefined,
	listSubscribeForm: Array<GraphQLTypes["SubscribeForm"]>,
	paginateSubscribeForm: GraphQLTypes["SubscribeFormConnection"],
	validateCreateSubscribeForm: GraphQLTypes["_ValidationResult"],
	validateUpdateSubscribeForm: GraphQLTypes["_ValidationResult"],
	validateCreateSubscribeSubmission: GraphQLTypes["_ValidationResult"],
	getContentReferencePositionCategoryItem?: GraphQLTypes["ContentReferencePositionCategoryItem"] | undefined,
	listContentReferencePositionCategoryItem: Array<GraphQLTypes["ContentReferencePositionCategoryItem"]>,
	paginateContentReferencePositionCategoryItem: GraphQLTypes["ContentReferencePositionCategoryItemConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getContentReferenceCaseStudyItem?: GraphQLTypes["ContentReferenceCaseStudyItem"] | undefined,
	listContentReferenceCaseStudyItem: Array<GraphQLTypes["ContentReferenceCaseStudyItem"]>,
	paginateContentReferenceCaseStudyItem: GraphQLTypes["ContentReferenceCaseStudyItemConnection"],
	getContentReferenceTagItem?: GraphQLTypes["ContentReferenceTagItem"] | undefined,
	listContentReferenceTagItem: Array<GraphQLTypes["ContentReferenceTagItem"]>,
	paginateContentReferenceTagItem: GraphQLTypes["ContentReferenceTagItemConnection"],
	getPositionList?: GraphQLTypes["PositionList"] | undefined,
	listPositionList: Array<GraphQLTypes["PositionList"]>,
	paginatePositionList: GraphQLTypes["PositionListConnection"],
	getPositionListItem?: GraphQLTypes["PositionListItem"] | undefined,
	listPositionListItem: Array<GraphQLTypes["PositionListItem"]>,
	paginatePositionListItem: GraphQLTypes["PositionListItemConnection"],
	getTestimonialFull?: GraphQLTypes["TestimonialFull"] | undefined,
	listTestimonialFull: Array<GraphQLTypes["TestimonialFull"]>,
	paginateTestimonialFull: GraphQLTypes["TestimonialFullConnection"],
	getTestimonialFullList?: GraphQLTypes["TestimonialFullList"] | undefined,
	listTestimonialFullList: Array<GraphQLTypes["TestimonialFullList"]>,
	paginateTestimonialFullList: GraphQLTypes["TestimonialFullListConnection"],
	getTestimonialFullListItem?: GraphQLTypes["TestimonialFullListItem"] | undefined,
	listTestimonialFullListItem: Array<GraphQLTypes["TestimonialFullListItem"]>,
	paginateTestimonialFullListItem: GraphQLTypes["TestimonialFullListItemConnection"],
	getSecondLevelLinkItem?: GraphQLTypes["SecondLevelLinkItem"] | undefined,
	listSecondLevelLinkItem: Array<GraphQLTypes["SecondLevelLinkItem"]>,
	paginateSecondLevelLinkItem: GraphQLTypes["SecondLevelLinkItemConnection"],
	getThirdLevelLinkItem?: GraphQLTypes["ThirdLevelLinkItem"] | undefined,
	listThirdLevelLinkItem: Array<GraphQLTypes["ThirdLevelLinkItem"]>,
	paginateThirdLevelLinkItem: GraphQLTypes["ThirdLevelLinkItemConnection"],
	getTopLevelLinkItem?: GraphQLTypes["TopLevelLinkItem"] | undefined,
	listTopLevelLinkItem: Array<GraphQLTypes["TopLevelLinkItem"]>,
	paginateTopLevelLinkItem: GraphQLTypes["TopLevelLinkItemConnection"],
	getAuthor?: GraphQLTypes["Author"] | undefined,
	listAuthor: Array<GraphQLTypes["Author"]>,
	paginateAuthor: GraphQLTypes["AuthorConnection"],
	getAuthorLocale?: GraphQLTypes["AuthorLocale"] | undefined,
	listAuthorLocale: Array<GraphQLTypes["AuthorLocale"]>,
	paginateAuthorLocale: GraphQLTypes["AuthorLocaleConnection"],
	getBlogPost?: GraphQLTypes["BlogPost"] | undefined,
	listBlogPost: Array<GraphQLTypes["BlogPost"]>,
	paginateBlogPost: GraphQLTypes["BlogPostConnection"],
	getBlogPostLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	listBlogPostLocale: Array<GraphQLTypes["BlogPostLocale"]>,
	paginateBlogPostLocale: GraphQLTypes["BlogPostLocaleConnection"],
	getContentReferenceBlogPostItem?: GraphQLTypes["ContentReferenceBlogPostItem"] | undefined,
	listContentReferenceBlogPostItem: Array<GraphQLTypes["ContentReferenceBlogPostItem"]>,
	paginateContentReferenceBlogPostItem: GraphQLTypes["ContentReferenceBlogPostItemConnection"],
	getRelevantBlogPostList?: GraphQLTypes["RelevantBlogPostList"] | undefined,
	listRelevantBlogPostList: Array<GraphQLTypes["RelevantBlogPostList"]>,
	paginateRelevantBlogPostList: GraphQLTypes["RelevantBlogPostListConnection"],
	getBlogPostCommon?: GraphQLTypes["BlogPostCommon"] | undefined,
	listBlogPostCommon: Array<GraphQLTypes["BlogPostCommon"]>,
	paginateBlogPostCommon: GraphQLTypes["BlogPostCommonConnection"],
	getBlogPostCommonLocale?: GraphQLTypes["BlogPostCommonLocale"] | undefined,
	listBlogPostCommonLocale: Array<GraphQLTypes["BlogPostCommonLocale"]>,
	paginateBlogPostCommonLocale: GraphQLTypes["BlogPostCommonLocaleConnection"],
	transaction?: GraphQLTypes["QueryTransaction"] | undefined,
	_info?: GraphQLTypes["Info"] | undefined
};
	["_Schema"]: {
	__typename: "_Schema",
	enums: Array<GraphQLTypes["_Enum"]>,
	entities: Array<GraphQLTypes["_Entity"]>
};
	["_Enum"]: {
	__typename: "_Enum",
	name: string,
	values: Array<string>
};
	["_Entity"]: {
	__typename: "_Entity",
	name: string,
	customPrimaryAllowed: boolean,
	fields: Array<GraphQLTypes["_Field"]>,
	unique: Array<GraphQLTypes["_UniqueConstraint"]>
};
	["_UniqueConstraint"]: {
	__typename: "_UniqueConstraint",
	fields: Array<string>
};
	["General"]: {
	__typename: "General",
	_meta?: GraphQLTypes["GeneralMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["GeneralLocale"]>,
	localesByLocale?: GraphQLTypes["GeneralLocale"] | undefined,
	localesBySeo?: GraphQLTypes["GeneralLocale"] | undefined,
	localesByContent?: GraphQLTypes["GeneralLocale"] | undefined,
	paginateLocales: GraphQLTypes["GeneralLocaleConnection"]
};
	["GeneralMeta"]: {
	__typename: "GeneralMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["FieldMeta"]: {
	__typename: "FieldMeta",
	readable?: boolean | undefined,
	updatable?: boolean | undefined
};
	["UUID"]: "scalar" & { name: "UUID" };
	["One"]: One;
	["GeneralLocale"]: {
	__typename: "GeneralLocale",
	_meta?: GraphQLTypes["GeneralLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	root?: GraphQLTypes["General"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	homeLinkable?: GraphQLTypes["Linkable"] | undefined,
	caseStudiesLink?: GraphQLTypes["Link"] | undefined,
	positionsLink?: GraphQLTypes["Link"] | undefined,
	facebookUrl?: string | undefined,
	twitterUrl?: string | undefined,
	instagramUrl?: string | undefined,
	youtubeUrl?: string | undefined,
	linkedinUrl?: string | undefined,
	formPrivacyPolicy?: string | undefined,
	formMarketingConsent?: string | undefined,
	acceptButtonLabel: string,
	rejectButtonLabel: string,
	content?: GraphQLTypes["Content"] | undefined
};
	["GeneralLocaleMeta"]: {
	__typename: "GeneralLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	homeLinkable?: GraphQLTypes["FieldMeta"] | undefined,
	caseStudiesLink?: GraphQLTypes["FieldMeta"] | undefined,
	positionsLink?: GraphQLTypes["FieldMeta"] | undefined,
	facebookUrl?: GraphQLTypes["FieldMeta"] | undefined,
	twitterUrl?: GraphQLTypes["FieldMeta"] | undefined,
	instagramUrl?: GraphQLTypes["FieldMeta"] | undefined,
	youtubeUrl?: GraphQLTypes["FieldMeta"] | undefined,
	linkedinUrl?: GraphQLTypes["FieldMeta"] | undefined,
	formPrivacyPolicy?: GraphQLTypes["FieldMeta"] | undefined,
	formMarketingConsent?: GraphQLTypes["FieldMeta"] | undefined,
	acceptButtonLabel?: GraphQLTypes["FieldMeta"] | undefined,
	rejectButtonLabel?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined
};
	["GeneralWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["GeneralLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["GeneralWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GeneralWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GeneralWhere"] | undefined
};
	["UUIDCondition"]: {
		and?: Array<GraphQLTypes["UUIDCondition"]> | undefined,
	or?: Array<GraphQLTypes["UUIDCondition"]> | undefined,
	not?: GraphQLTypes["UUIDCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["UUID"] | undefined,
	notEq?: GraphQLTypes["UUID"] | undefined,
	in?: Array<GraphQLTypes["UUID"]> | undefined,
	notIn?: Array<GraphQLTypes["UUID"]> | undefined,
	lt?: GraphQLTypes["UUID"] | undefined,
	lte?: GraphQLTypes["UUID"] | undefined,
	gt?: GraphQLTypes["UUID"] | undefined,
	gte?: GraphQLTypes["UUID"] | undefined
};
	["OneCondition"]: {
		and?: Array<GraphQLTypes["OneCondition"]> | undefined,
	or?: Array<GraphQLTypes["OneCondition"]> | undefined,
	not?: GraphQLTypes["OneCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["One"] | undefined,
	notEq?: GraphQLTypes["One"] | undefined,
	in?: Array<GraphQLTypes["One"]> | undefined,
	notIn?: Array<GraphQLTypes["One"]> | undefined,
	lt?: GraphQLTypes["One"] | undefined,
	lte?: GraphQLTypes["One"] | undefined,
	gt?: GraphQLTypes["One"] | undefined,
	gte?: GraphQLTypes["One"] | undefined
};
	["GeneralLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	root?: GraphQLTypes["GeneralWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	homeLinkable?: GraphQLTypes["LinkableWhere"] | undefined,
	caseStudiesLink?: GraphQLTypes["LinkWhere"] | undefined,
	positionsLink?: GraphQLTypes["LinkWhere"] | undefined,
	facebookUrl?: GraphQLTypes["StringCondition"] | undefined,
	twitterUrl?: GraphQLTypes["StringCondition"] | undefined,
	instagramUrl?: GraphQLTypes["StringCondition"] | undefined,
	youtubeUrl?: GraphQLTypes["StringCondition"] | undefined,
	linkedinUrl?: GraphQLTypes["StringCondition"] | undefined,
	formPrivacyPolicy?: GraphQLTypes["StringCondition"] | undefined,
	formMarketingConsent?: GraphQLTypes["StringCondition"] | undefined,
	acceptButtonLabel?: GraphQLTypes["StringCondition"] | undefined,
	rejectButtonLabel?: GraphQLTypes["StringCondition"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	and?: Array<GraphQLTypes["GeneralLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GeneralLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GeneralLocaleWhere"] | undefined
};
	["LocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	label?: GraphQLTypes["StringCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	general?: GraphQLTypes["GeneralLocaleWhere"] | undefined,
	genericPages?: GraphQLTypes["GenericPageLocaleWhere"] | undefined,
	headers?: GraphQLTypes["HeaderLocaleWhere"] | undefined,
	footers?: GraphQLTypes["FooterLocaleWhere"] | undefined,
	caseStudies?: GraphQLTypes["CaseStudyLocaleWhere"] | undefined,
	tags?: GraphQLTypes["TagLocaleWhere"] | undefined,
	positionTags?: GraphQLTypes["PositionTagLocaleWhere"] | undefined,
	positions?: GraphQLTypes["PositionLocaleWhere"] | undefined,
	positionCategories?: GraphQLTypes["PositionCategoryLocaleWhere"] | undefined,
	authors?: GraphQLTypes["AuthorLocaleWhere"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostLocaleWhere"] | undefined,
	blogPostCommon?: GraphQLTypes["BlogPostCommonLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["LocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LocaleWhere"] | undefined
};
	["StringCondition"]: {
		and?: Array<GraphQLTypes["StringCondition"]> | undefined,
	or?: Array<GraphQLTypes["StringCondition"]> | undefined,
	not?: GraphQLTypes["StringCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: string | undefined,
	notEq?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	containsCI?: string | undefined,
	startsWithCI?: string | undefined,
	endsWithCI?: string | undefined
};
	["IntCondition"]: {
		and?: Array<GraphQLTypes["IntCondition"]> | undefined,
	or?: Array<GraphQLTypes["IntCondition"]> | undefined,
	not?: GraphQLTypes["IntCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["GenericPageLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["GenericPageWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	and?: Array<GraphQLTypes["GenericPageLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GenericPageLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GenericPageLocaleWhere"] | undefined
};
	["GenericPageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["GenericPageLocaleWhere"] | undefined,
	publishedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	and?: Array<GraphQLTypes["GenericPageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GenericPageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GenericPageWhere"] | undefined
};
	["DateTimeCondition"]: {
		and?: Array<GraphQLTypes["DateTimeCondition"]> | undefined,
	or?: Array<GraphQLTypes["DateTimeCondition"]> | undefined,
	not?: GraphQLTypes["DateTimeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["DateTime"] | undefined,
	notEq?: GraphQLTypes["DateTime"] | undefined,
	in?: Array<GraphQLTypes["DateTime"]> | undefined,
	notIn?: Array<GraphQLTypes["DateTime"]> | undefined,
	lt?: GraphQLTypes["DateTime"] | undefined,
	lte?: GraphQLTypes["DateTime"] | undefined,
	gt?: GraphQLTypes["DateTime"] | undefined,
	gte?: GraphQLTypes["DateTime"] | undefined
};
	["DateTime"]: "scalar" & { name: "DateTime" };
	["ContentWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	blocks?: GraphQLTypes["ContentBlockWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentWhere"] | undefined
};
	["ContentBlockWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	json?: GraphQLTypes["StringCondition"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	references?: GraphQLTypes["ContentReferenceWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentBlockWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentBlockWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentBlockWhere"] | undefined
};
	["ContentReferenceWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["ContentReferenceTypeCondition"] | undefined,
	primaryText?: GraphQLTypes["StringCondition"] | undefined,
	block?: GraphQLTypes["ContentBlockWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	secondaryText?: GraphQLTypes["StringCondition"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	featuredCaseStudies?: GraphQLTypes["CaseStudyListWhere"] | undefined,
	images?: GraphQLTypes["ImageListWhere"] | undefined,
	otherImages?: GraphQLTypes["ImageListWhere"] | undefined,
	informationList?: GraphQLTypes["InformationListWhere"] | undefined,
	tertiaryText?: GraphQLTypes["StringCondition"] | undefined,
	embed?: GraphQLTypes["EmbedWhere"] | undefined,
	links?: GraphQLTypes["LinkListWhere"] | undefined,
	talkLists?: GraphQLTypes["TalkListsWhere"] | undefined,
	businessCardList?: GraphQLTypes["BusinessCardListWhere"] | undefined,
	testimonials?: GraphQLTypes["TestimonialListWhere"] | undefined,
	background?: GraphQLTypes["ContentBackgroundTypeCondition"] | undefined,
	otherImage?: GraphQLTypes["ImageWhere"] | undefined,
	caseStudy?: GraphQLTypes["CaseStudyWhere"] | undefined,
	alternative?: GraphQLTypes["BooleanCondition"] | undefined,
	contactRequestOptions?: GraphQLTypes["ContactRequestOptionListWhere"] | undefined,
	youtubeVideo?: GraphQLTypes["YoutubeVideoWhere"] | undefined,
	subscribeForm?: GraphQLTypes["SubscribeFormWhere"] | undefined,
	video?: GraphQLTypes["VideoWhere"] | undefined,
	otherVideo?: GraphQLTypes["VideoWhere"] | undefined,
	positionCategories?: GraphQLTypes["ContentReferencePositionCategoryItemWhere"] | undefined,
	caseStudies?: GraphQLTypes["ContentReferenceCaseStudyItemWhere"] | undefined,
	tags?: GraphQLTypes["ContentReferenceTagItemWhere"] | undefined,
	positions?: GraphQLTypes["PositionListWhere"] | undefined,
	testimonialsFull?: GraphQLTypes["TestimonialFullListWhere"] | undefined,
	blogPosts?: GraphQLTypes["ContentReferenceBlogPostItemWhere"] | undefined,
	verticalMargin?: GraphQLTypes["ContentVerticalMarginCondition"] | undefined,
	variant?: GraphQLTypes["StringCondition"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentReferenceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentReferenceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentReferenceWhere"] | undefined
};
	["ContentReferenceTypeCondition"]: {
		and?: Array<GraphQLTypes["ContentReferenceTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ContentReferenceTypeCondition"]> | undefined,
	not?: GraphQLTypes["ContentReferenceTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ContentReferenceType"] | undefined,
	notEq?: GraphQLTypes["ContentReferenceType"] | undefined,
	in?: Array<GraphQLTypes["ContentReferenceType"]> | undefined,
	notIn?: Array<GraphQLTypes["ContentReferenceType"]> | undefined,
	lt?: GraphQLTypes["ContentReferenceType"] | undefined,
	lte?: GraphQLTypes["ContentReferenceType"] | undefined,
	gt?: GraphQLTypes["ContentReferenceType"] | undefined,
	gte?: GraphQLTypes["ContentReferenceType"] | undefined
};
	["ContentReferenceType"]: ContentReferenceType;
	["ImageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	url?: GraphQLTypes["StringCondition"] | undefined,
	width?: GraphQLTypes["IntCondition"] | undefined,
	height?: GraphQLTypes["IntCondition"] | undefined,
	size?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["StringCondition"] | undefined,
	alt?: GraphQLTypes["StringCondition"] | undefined,
	fileName?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ImageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageWhere"] | undefined
};
	["LinkWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	externalLink?: GraphQLTypes["StringCondition"] | undefined,
	internalLink?: GraphQLTypes["LinkableWhere"] | undefined,
	type?: GraphQLTypes["LinkTypeCondition"] | undefined,
	isTargetBlank?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["LinkWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkWhere"] | undefined
};
	["LinkableWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	url?: GraphQLTypes["StringCondition"] | undefined,
	genericPage?: GraphQLTypes["GenericPageLocaleWhere"] | undefined,
	caseStudy?: GraphQLTypes["CaseStudyLocaleWhere"] | undefined,
	position?: GraphQLTypes["PositionLocaleWhere"] | undefined,
	redirect?: GraphQLTypes["RedirectWhere"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkableWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkableWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkableWhere"] | undefined
};
	["CaseStudyLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["CaseStudyWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["CaseStudyLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CaseStudyLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CaseStudyLocaleWhere"] | undefined
};
	["CaseStudyWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["CaseStudyLocaleWhere"] | undefined,
	tags?: GraphQLTypes["CaseStudyTagWhere"] | undefined,
	wideImage?: GraphQLTypes["ImageWhere"] | undefined,
	narrowImage?: GraphQLTypes["ImageWhere"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	publishedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	and?: Array<GraphQLTypes["CaseStudyWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CaseStudyWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CaseStudyWhere"] | undefined
};
	["CaseStudyTagWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	caseStudy?: GraphQLTypes["CaseStudyWhere"] | undefined,
	tag?: GraphQLTypes["TagWhere"] | undefined,
	and?: Array<GraphQLTypes["CaseStudyTagWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CaseStudyTagWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CaseStudyTagWhere"] | undefined
};
	["TagWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["TagLocaleWhere"] | undefined,
	caseStudyTags?: GraphQLTypes["CaseStudyTagWhere"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["TagWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TagWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TagWhere"] | undefined
};
	["TagLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	slug?: GraphQLTypes["StringCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["TagWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["TagLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TagLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TagLocaleWhere"] | undefined
};
	["SeoWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	ogTitle?: GraphQLTypes["StringCondition"] | undefined,
	ogDescription?: GraphQLTypes["StringCondition"] | undefined,
	ogImage?: GraphQLTypes["ImageWhere"] | undefined,
	noIndex?: GraphQLTypes["BooleanCondition"] | undefined,
	noFollow?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["SeoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SeoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SeoWhere"] | undefined
};
	["BooleanCondition"]: {
		and?: Array<GraphQLTypes["BooleanCondition"]> | undefined,
	or?: Array<GraphQLTypes["BooleanCondition"]> | undefined,
	not?: GraphQLTypes["BooleanCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: boolean | undefined,
	notEq?: boolean | undefined,
	in?: Array<boolean> | undefined,
	notIn?: Array<boolean> | undefined,
	lt?: boolean | undefined,
	lte?: boolean | undefined,
	gt?: boolean | undefined,
	gte?: boolean | undefined
};
	["PositionLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	root?: GraphQLTypes["PositionWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	summary?: GraphQLTypes["StringCondition"] | undefined,
	salaryRange?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["PositionLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PositionLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PositionLocaleWhere"] | undefined
};
	["PositionWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	tags?: GraphQLTypes["PositionTagListWhere"] | undefined,
	locales?: GraphQLTypes["PositionLocaleWhere"] | undefined,
	orderInCategory?: GraphQLTypes["IntCondition"] | undefined,
	category?: GraphQLTypes["PositionCategoryWhere"] | undefined,
	publishedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	and?: Array<GraphQLTypes["PositionWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PositionWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PositionWhere"] | undefined
};
	["PositionTagListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["PositionTagListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["PositionTagListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PositionTagListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PositionTagListWhere"] | undefined
};
	["PositionTagListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["PositionTagListWhere"] | undefined,
	tag?: GraphQLTypes["PositionTagWhere"] | undefined,
	and?: Array<GraphQLTypes["PositionTagListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PositionTagListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PositionTagListItemWhere"] | undefined
};
	["PositionTagWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["PositionTagLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["PositionTagWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PositionTagWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PositionTagWhere"] | undefined
};
	["PositionTagLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	slug?: GraphQLTypes["StringCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["PositionTagWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["PositionTagLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PositionTagLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PositionTagLocaleWhere"] | undefined
};
	["PositionCategoryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	positions?: GraphQLTypes["PositionWhere"] | undefined,
	locales?: GraphQLTypes["PositionCategoryLocaleWhere"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["PositionCategoryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PositionCategoryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PositionCategoryWhere"] | undefined
};
	["PositionCategoryLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["PositionCategoryWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["PositionCategoryLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PositionCategoryLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PositionCategoryLocaleWhere"] | undefined
};
	["RedirectWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	target?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["RedirectWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["RedirectWhere"] | undefined> | undefined,
	not?: GraphQLTypes["RedirectWhere"] | undefined
};
	["BlogPostLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["BlogPostWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	lead?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["BlogPostLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogPostLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogPostLocaleWhere"] | undefined
};
	["BlogPostWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	publishedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	author?: GraphQLTypes["AuthorWhere"] | undefined,
	wideImage?: GraphQLTypes["ImageWhere"] | undefined,
	narrowImage?: GraphQLTypes["ImageWhere"] | undefined,
	locales?: GraphQLTypes["BlogPostLocaleWhere"] | undefined,
	relevantBlogPosts?: GraphQLTypes["RelevantBlogPostListWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogPostWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogPostWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogPostWhere"] | undefined
};
	["AuthorWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["AuthorLocaleWhere"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostWhere"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	profileUrl?: GraphQLTypes["StringCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	avatarImage?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["AuthorWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["AuthorWhere"] | undefined> | undefined,
	not?: GraphQLTypes["AuthorWhere"] | undefined
};
	["AuthorLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	role?: GraphQLTypes["StringCondition"] | undefined,
	bio?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["AuthorWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["AuthorLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["AuthorLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["AuthorLocaleWhere"] | undefined
};
	["RelevantBlogPostListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	blogPost?: GraphQLTypes["BlogPostWhere"] | undefined,
	relevantBlogPost?: GraphQLTypes["BlogPostWhere"] | undefined,
	and?: Array<GraphQLTypes["RelevantBlogPostListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["RelevantBlogPostListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["RelevantBlogPostListWhere"] | undefined
};
	["LinkTypeCondition"]: {
		and?: Array<GraphQLTypes["LinkTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["LinkTypeCondition"]> | undefined,
	not?: GraphQLTypes["LinkTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["LinkType"] | undefined,
	notEq?: GraphQLTypes["LinkType"] | undefined,
	in?: Array<GraphQLTypes["LinkType"]> | undefined,
	notIn?: Array<GraphQLTypes["LinkType"]> | undefined,
	lt?: GraphQLTypes["LinkType"] | undefined,
	lte?: GraphQLTypes["LinkType"] | undefined,
	gt?: GraphQLTypes["LinkType"] | undefined,
	gte?: GraphQLTypes["LinkType"] | undefined
};
	["LinkType"]: LinkType;
	["CaseStudyListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["CaseStudyListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["CaseStudyListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CaseStudyListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CaseStudyListWhere"] | undefined
};
	["CaseStudyListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["CaseStudyListWhere"] | undefined,
	caseStudy?: GraphQLTypes["CaseStudyWhere"] | undefined,
	and?: Array<GraphQLTypes["CaseStudyListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CaseStudyListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CaseStudyListItemWhere"] | undefined
};
	["ImageListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ImageListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["ImageListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageListWhere"] | undefined
};
	["ImageListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["ImageListWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["ImageListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageListItemWhere"] | undefined
};
	["InformationListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["PieceOfInformationWhere"] | undefined,
	and?: Array<GraphQLTypes["InformationListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["InformationListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["InformationListWhere"] | undefined
};
	["PieceOfInformationWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["InformationListWhere"] | undefined,
	and?: Array<GraphQLTypes["PieceOfInformationWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PieceOfInformationWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PieceOfInformationWhere"] | undefined
};
	["EmbedWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["EmbedTypeCondition"] | undefined,
	embedId?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["EmbedWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["EmbedWhere"] | undefined> | undefined,
	not?: GraphQLTypes["EmbedWhere"] | undefined
};
	["EmbedTypeCondition"]: {
		and?: Array<GraphQLTypes["EmbedTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["EmbedTypeCondition"]> | undefined,
	not?: GraphQLTypes["EmbedTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["EmbedType"] | undefined,
	notEq?: GraphQLTypes["EmbedType"] | undefined,
	in?: Array<GraphQLTypes["EmbedType"]> | undefined,
	notIn?: Array<GraphQLTypes["EmbedType"]> | undefined,
	lt?: GraphQLTypes["EmbedType"] | undefined,
	lte?: GraphQLTypes["EmbedType"] | undefined,
	gt?: GraphQLTypes["EmbedType"] | undefined,
	gte?: GraphQLTypes["EmbedType"] | undefined
};
	["EmbedType"]: EmbedType;
	["LinkListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["LinkListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkListWhere"] | undefined
};
	["LinkListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["LinkListWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkListItemWhere"] | undefined
};
	["TalkListsWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["TalkListWhere"] | undefined,
	and?: Array<GraphQLTypes["TalkListsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TalkListsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TalkListsWhere"] | undefined
};
	["TalkListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["TalkListsWhere"] | undefined,
	items?: GraphQLTypes["TalkListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["TalkListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TalkListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TalkListWhere"] | undefined
};
	["TalkListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["TalkListWhere"] | undefined,
	talk?: GraphQLTypes["TalkWhere"] | undefined,
	and?: Array<GraphQLTypes["TalkListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TalkListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TalkListItemWhere"] | undefined
};
	["TalkWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	slug?: GraphQLTypes["StringCondition"] | undefined,
	date?: GraphQLTypes["DateTimeCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	content?: GraphQLTypes["StringCondition"] | undefined,
	coverImage?: GraphQLTypes["ImageWhere"] | undefined,
	otherImage?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["TalkWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TalkWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TalkWhere"] | undefined
};
	["BusinessCardListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["BusinessCardListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["BusinessCardListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BusinessCardListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BusinessCardListWhere"] | undefined
};
	["BusinessCardListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["BusinessCardListWhere"] | undefined,
	businessCard?: GraphQLTypes["BusinessCardWhere"] | undefined,
	and?: Array<GraphQLTypes["BusinessCardListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BusinessCardListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BusinessCardListItemWhere"] | undefined
};
	["BusinessCardWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	role?: GraphQLTypes["StringCondition"] | undefined,
	email?: GraphQLTypes["StringCondition"] | undefined,
	avatar?: GraphQLTypes["ImageWhere"] | undefined,
	phone?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["BusinessCardWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BusinessCardWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BusinessCardWhere"] | undefined
};
	["TestimonialListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["TestimonialListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["TestimonialListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TestimonialListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TestimonialListWhere"] | undefined
};
	["TestimonialListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["TestimonialListWhere"] | undefined,
	testimonial?: GraphQLTypes["TestimonialWhere"] | undefined,
	and?: Array<GraphQLTypes["TestimonialListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TestimonialListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TestimonialListItemWhere"] | undefined
};
	["TestimonialWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	content?: GraphQLTypes["StringCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	avatar?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["TestimonialWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TestimonialWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TestimonialWhere"] | undefined
};
	["ContentBackgroundTypeCondition"]: {
		and?: Array<GraphQLTypes["ContentBackgroundTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ContentBackgroundTypeCondition"]> | undefined,
	not?: GraphQLTypes["ContentBackgroundTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ContentBackgroundType"] | undefined,
	notEq?: GraphQLTypes["ContentBackgroundType"] | undefined,
	in?: Array<GraphQLTypes["ContentBackgroundType"]> | undefined,
	notIn?: Array<GraphQLTypes["ContentBackgroundType"]> | undefined,
	lt?: GraphQLTypes["ContentBackgroundType"] | undefined,
	lte?: GraphQLTypes["ContentBackgroundType"] | undefined,
	gt?: GraphQLTypes["ContentBackgroundType"] | undefined,
	gte?: GraphQLTypes["ContentBackgroundType"] | undefined
};
	["ContentBackgroundType"]: ContentBackgroundType;
	["ContactRequestOptionListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ContactRequestOptionWhere"] | undefined,
	and?: Array<GraphQLTypes["ContactRequestOptionListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContactRequestOptionListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContactRequestOptionListWhere"] | undefined
};
	["ContactRequestOptionWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	label?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["ContactRequestOptionListWhere"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	type?: GraphQLTypes["ContactRequestOptionTypeCondition"] | undefined,
	subscribeForm?: GraphQLTypes["SubscribeFormWhere"] | undefined,
	and?: Array<GraphQLTypes["ContactRequestOptionWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContactRequestOptionWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContactRequestOptionWhere"] | undefined
};
	["ContactRequestOptionTypeCondition"]: {
		and?: Array<GraphQLTypes["ContactRequestOptionTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ContactRequestOptionTypeCondition"]> | undefined,
	not?: GraphQLTypes["ContactRequestOptionTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ContactRequestOptionType"] | undefined,
	notEq?: GraphQLTypes["ContactRequestOptionType"] | undefined,
	in?: Array<GraphQLTypes["ContactRequestOptionType"]> | undefined,
	notIn?: Array<GraphQLTypes["ContactRequestOptionType"]> | undefined,
	lt?: GraphQLTypes["ContactRequestOptionType"] | undefined,
	lte?: GraphQLTypes["ContactRequestOptionType"] | undefined,
	gt?: GraphQLTypes["ContactRequestOptionType"] | undefined,
	gte?: GraphQLTypes["ContactRequestOptionType"] | undefined
};
	["ContactRequestOptionType"]: ContactRequestOptionType;
	["SubscribeFormWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	datacruitJobId?: GraphQLTypes["IntCondition"] | undefined,
	datacruitReferenceNumber?: GraphQLTypes["StringCondition"] | undefined,
	lastUpdateAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	and?: Array<GraphQLTypes["SubscribeFormWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SubscribeFormWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SubscribeFormWhere"] | undefined
};
	["YoutubeVideoWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	videoId?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["YoutubeVideoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["YoutubeVideoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["YoutubeVideoWhere"] | undefined
};
	["VideoWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	src?: GraphQLTypes["StringCondition"] | undefined,
	height?: GraphQLTypes["IntCondition"] | undefined,
	width?: GraphQLTypes["IntCondition"] | undefined,
	duration?: GraphQLTypes["FloatCondition"] | undefined,
	size?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["VideoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["VideoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["VideoWhere"] | undefined
};
	["FloatCondition"]: {
		and?: Array<GraphQLTypes["FloatCondition"]> | undefined,
	or?: Array<GraphQLTypes["FloatCondition"]> | undefined,
	not?: GraphQLTypes["FloatCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["ContentReferencePositionCategoryItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	item?: GraphQLTypes["PositionCategoryWhere"] | undefined,
	reference?: GraphQLTypes["ContentReferenceWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentReferencePositionCategoryItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentReferencePositionCategoryItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentReferencePositionCategoryItemWhere"] | undefined
};
	["ContentReferenceCaseStudyItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	item?: GraphQLTypes["CaseStudyWhere"] | undefined,
	reference?: GraphQLTypes["ContentReferenceWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentReferenceCaseStudyItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentReferenceCaseStudyItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentReferenceCaseStudyItemWhere"] | undefined
};
	["ContentReferenceTagItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	item?: GraphQLTypes["TagWhere"] | undefined,
	reference?: GraphQLTypes["ContentReferenceWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentReferenceTagItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentReferenceTagItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentReferenceTagItemWhere"] | undefined
};
	["PositionListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["PositionListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["PositionListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PositionListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PositionListWhere"] | undefined
};
	["PositionListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["PositionListWhere"] | undefined,
	position?: GraphQLTypes["PositionLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["PositionListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PositionListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PositionListItemWhere"] | undefined
};
	["TestimonialFullListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["TestimonialFullListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["TestimonialFullListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TestimonialFullListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TestimonialFullListWhere"] | undefined
};
	["TestimonialFullListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["TestimonialFullListWhere"] | undefined,
	testimonial?: GraphQLTypes["TestimonialFullWhere"] | undefined,
	and?: Array<GraphQLTypes["TestimonialFullListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TestimonialFullListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TestimonialFullListItemWhere"] | undefined
};
	["TestimonialFullWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	content?: GraphQLTypes["StringCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	avatar?: GraphQLTypes["ImageWhere"] | undefined,
	imageVariant?: GraphQLTypes["ImageVariantCondition"] | undefined,
	and?: Array<GraphQLTypes["TestimonialFullWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TestimonialFullWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TestimonialFullWhere"] | undefined
};
	["ImageVariantCondition"]: {
		and?: Array<GraphQLTypes["ImageVariantCondition"]> | undefined,
	or?: Array<GraphQLTypes["ImageVariantCondition"]> | undefined,
	not?: GraphQLTypes["ImageVariantCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ImageVariant"] | undefined,
	notEq?: GraphQLTypes["ImageVariant"] | undefined,
	in?: Array<GraphQLTypes["ImageVariant"]> | undefined,
	notIn?: Array<GraphQLTypes["ImageVariant"]> | undefined,
	lt?: GraphQLTypes["ImageVariant"] | undefined,
	lte?: GraphQLTypes["ImageVariant"] | undefined,
	gt?: GraphQLTypes["ImageVariant"] | undefined,
	gte?: GraphQLTypes["ImageVariant"] | undefined
};
	["ImageVariant"]: ImageVariant;
	["ContentReferenceBlogPostItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	publishedAt?: GraphQLTypes["DateCondition"] | undefined,
	item?: GraphQLTypes["BlogPostWhere"] | undefined,
	reference?: GraphQLTypes["ContentReferenceWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentReferenceBlogPostItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentReferenceBlogPostItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentReferenceBlogPostItemWhere"] | undefined
};
	["DateCondition"]: {
		and?: Array<GraphQLTypes["DateCondition"]> | undefined,
	or?: Array<GraphQLTypes["DateCondition"]> | undefined,
	not?: GraphQLTypes["DateCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Date"] | undefined,
	notEq?: GraphQLTypes["Date"] | undefined,
	in?: Array<GraphQLTypes["Date"]> | undefined,
	notIn?: Array<GraphQLTypes["Date"]> | undefined,
	lt?: GraphQLTypes["Date"] | undefined,
	lte?: GraphQLTypes["Date"] | undefined,
	gt?: GraphQLTypes["Date"] | undefined,
	gte?: GraphQLTypes["Date"] | undefined
};
	["Date"]: "scalar" & { name: "Date" };
	["ContentVerticalMarginCondition"]: {
		and?: Array<GraphQLTypes["ContentVerticalMarginCondition"]> | undefined,
	or?: Array<GraphQLTypes["ContentVerticalMarginCondition"]> | undefined,
	not?: GraphQLTypes["ContentVerticalMarginCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ContentVerticalMargin"] | undefined,
	notEq?: GraphQLTypes["ContentVerticalMargin"] | undefined,
	in?: Array<GraphQLTypes["ContentVerticalMargin"]> | undefined,
	notIn?: Array<GraphQLTypes["ContentVerticalMargin"]> | undefined,
	lt?: GraphQLTypes["ContentVerticalMargin"] | undefined,
	lte?: GraphQLTypes["ContentVerticalMargin"] | undefined,
	gt?: GraphQLTypes["ContentVerticalMargin"] | undefined,
	gte?: GraphQLTypes["ContentVerticalMargin"] | undefined
};
	["ContentVerticalMargin"]: ContentVerticalMargin;
	["HeaderLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	email?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["HeaderWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	linkList?: GraphQLTypes["LinkListWhere"] | undefined,
	socialLinkList?: GraphQLTypes["LinkListWhere"] | undefined,
	topLevelLinkItems?: GraphQLTypes["TopLevelLinkItemWhere"] | undefined,
	and?: Array<GraphQLTypes["HeaderLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["HeaderLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["HeaderLocaleWhere"] | undefined
};
	["HeaderWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["HeaderLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["HeaderWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["HeaderWhere"] | undefined> | undefined,
	not?: GraphQLTypes["HeaderWhere"] | undefined
};
	["TopLevelLinkItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	isLink?: GraphQLTypes["IsLinkCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	secondLevelLinkItems?: GraphQLTypes["SecondLevelLinkItemWhere"] | undefined,
	header?: GraphQLTypes["HeaderLocaleWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["TopLevelLinkItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TopLevelLinkItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TopLevelLinkItemWhere"] | undefined
};
	["IsLinkCondition"]: {
		and?: Array<GraphQLTypes["IsLinkCondition"]> | undefined,
	or?: Array<GraphQLTypes["IsLinkCondition"]> | undefined,
	not?: GraphQLTypes["IsLinkCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["IsLink"] | undefined,
	notEq?: GraphQLTypes["IsLink"] | undefined,
	in?: Array<GraphQLTypes["IsLink"]> | undefined,
	notIn?: Array<GraphQLTypes["IsLink"]> | undefined,
	lt?: GraphQLTypes["IsLink"] | undefined,
	lte?: GraphQLTypes["IsLink"] | undefined,
	gt?: GraphQLTypes["IsLink"] | undefined,
	gte?: GraphQLTypes["IsLink"] | undefined
};
	["IsLink"]: IsLink;
	["SecondLevelLinkItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	isLink?: GraphQLTypes["IsLinkCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	topLevelLinkItem?: GraphQLTypes["TopLevelLinkItemWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	thirdLevelLinkItems?: GraphQLTypes["ThirdLevelLinkItemWhere"] | undefined,
	and?: Array<GraphQLTypes["SecondLevelLinkItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SecondLevelLinkItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SecondLevelLinkItemWhere"] | undefined
};
	["ThirdLevelLinkItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	secondLevelLinkItem?: GraphQLTypes["SecondLevelLinkItemWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["ThirdLevelLinkItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ThirdLevelLinkItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ThirdLevelLinkItemWhere"] | undefined
};
	["FooterLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	email?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["FooterWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	address?: GraphQLTypes["StringCondition"] | undefined,
	phone?: GraphQLTypes["StringCondition"] | undefined,
	legal?: GraphQLTypes["StringCondition"] | undefined,
	navigation?: GraphQLTypes["LinkListWhere"] | undefined,
	and?: Array<GraphQLTypes["FooterLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FooterLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FooterLocaleWhere"] | undefined
};
	["FooterWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["FooterLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["FooterWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FooterWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FooterWhere"] | undefined
};
	["BlogPostCommonLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	root?: GraphQLTypes["BlogPostCommonWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	ctaLink?: GraphQLTypes["LinkWhere"] | undefined,
	ctaTitle?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["BlogPostCommonLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogPostCommonLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogPostCommonLocaleWhere"] | undefined
};
	["BlogPostCommonWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["BlogPostCommonLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogPostCommonWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogPostCommonWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogPostCommonWhere"] | undefined
};
	["Locale"]: {
	__typename: "Locale",
	_meta?: GraphQLTypes["LocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string,
	label?: string | undefined,
	order: number,
	general: Array<GraphQLTypes["GeneralLocale"]>,
	genericPages: Array<GraphQLTypes["GenericPageLocale"]>,
	headers: Array<GraphQLTypes["HeaderLocale"]>,
	footers: Array<GraphQLTypes["FooterLocale"]>,
	caseStudies: Array<GraphQLTypes["CaseStudyLocale"]>,
	tags: Array<GraphQLTypes["TagLocale"]>,
	positionTags: Array<GraphQLTypes["PositionTagLocale"]>,
	positions: Array<GraphQLTypes["PositionLocale"]>,
	positionCategories: Array<GraphQLTypes["PositionCategoryLocale"]>,
	authors: Array<GraphQLTypes["AuthorLocale"]>,
	blogPosts: Array<GraphQLTypes["BlogPostLocale"]>,
	blogPostCommon: Array<GraphQLTypes["BlogPostCommonLocale"]>,
	generalByRoot?: GraphQLTypes["GeneralLocale"] | undefined,
	generalBySeo?: GraphQLTypes["GeneralLocale"] | undefined,
	generalByContent?: GraphQLTypes["GeneralLocale"] | undefined,
	genericPagesByRoot?: GraphQLTypes["GenericPageLocale"] | undefined,
	genericPagesByContent?: GraphQLTypes["GenericPageLocale"] | undefined,
	genericPagesByLink?: GraphQLTypes["GenericPageLocale"] | undefined,
	genericPagesBySeo?: GraphQLTypes["GenericPageLocale"] | undefined,
	headersByRoot?: GraphQLTypes["HeaderLocale"] | undefined,
	headersByLinkList?: GraphQLTypes["HeaderLocale"] | undefined,
	headersBySocialLinkList?: GraphQLTypes["HeaderLocale"] | undefined,
	headersByTopLevelLinkItems?: GraphQLTypes["HeaderLocale"] | undefined,
	footersByRoot?: GraphQLTypes["FooterLocale"] | undefined,
	footersByNavigation?: GraphQLTypes["FooterLocale"] | undefined,
	caseStudiesByRoot?: GraphQLTypes["CaseStudyLocale"] | undefined,
	caseStudiesByContent?: GraphQLTypes["CaseStudyLocale"] | undefined,
	caseStudiesByLink?: GraphQLTypes["CaseStudyLocale"] | undefined,
	caseStudiesBySeo?: GraphQLTypes["CaseStudyLocale"] | undefined,
	tagsBySlug?: GraphQLTypes["TagLocale"] | undefined,
	tagsByRoot?: GraphQLTypes["TagLocale"] | undefined,
	positionTagsBySlug?: GraphQLTypes["PositionTagLocale"] | undefined,
	positionTagsByRoot?: GraphQLTypes["PositionTagLocale"] | undefined,
	positionsByRoot?: GraphQLTypes["PositionLocale"] | undefined,
	positionsByLink?: GraphQLTypes["PositionLocale"] | undefined,
	positionsBySeo?: GraphQLTypes["PositionLocale"] | undefined,
	positionsByContent?: GraphQLTypes["PositionLocale"] | undefined,
	positionCategoriesByRoot?: GraphQLTypes["PositionCategoryLocale"] | undefined,
	authorsByRoot?: GraphQLTypes["AuthorLocale"] | undefined,
	blogPostsByRoot?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByContent?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsBySeo?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByLink?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostCommonByRoot?: GraphQLTypes["BlogPostCommonLocale"] | undefined,
	paginateGeneral: GraphQLTypes["GeneralLocaleConnection"],
	paginateGenericPages: GraphQLTypes["GenericPageLocaleConnection"],
	paginateHeaders: GraphQLTypes["HeaderLocaleConnection"],
	paginateFooters: GraphQLTypes["FooterLocaleConnection"],
	paginateCaseStudies: GraphQLTypes["CaseStudyLocaleConnection"],
	paginateTags: GraphQLTypes["TagLocaleConnection"],
	paginatePositionTags: GraphQLTypes["PositionTagLocaleConnection"],
	paginatePositions: GraphQLTypes["PositionLocaleConnection"],
	paginatePositionCategories: GraphQLTypes["PositionCategoryLocaleConnection"],
	paginateAuthors: GraphQLTypes["AuthorLocaleConnection"],
	paginateBlogPosts: GraphQLTypes["BlogPostLocaleConnection"],
	paginateBlogPostCommon: GraphQLTypes["BlogPostCommonLocaleConnection"]
};
	["LocaleMeta"]: {
	__typename: "LocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	label?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	general?: GraphQLTypes["FieldMeta"] | undefined,
	genericPages?: GraphQLTypes["FieldMeta"] | undefined,
	headers?: GraphQLTypes["FieldMeta"] | undefined,
	footers?: GraphQLTypes["FieldMeta"] | undefined,
	caseStudies?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	positionTags?: GraphQLTypes["FieldMeta"] | undefined,
	positions?: GraphQLTypes["FieldMeta"] | undefined,
	positionCategories?: GraphQLTypes["FieldMeta"] | undefined,
	authors?: GraphQLTypes["FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["FieldMeta"] | undefined,
	blogPostCommon?: GraphQLTypes["FieldMeta"] | undefined
};
	["GeneralLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["GeneralOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined,
	homeLinkable?: GraphQLTypes["LinkableOrderBy"] | undefined,
	caseStudiesLink?: GraphQLTypes["LinkOrderBy"] | undefined,
	positionsLink?: GraphQLTypes["LinkOrderBy"] | undefined,
	facebookUrl?: GraphQLTypes["OrderDirection"] | undefined,
	twitterUrl?: GraphQLTypes["OrderDirection"] | undefined,
	instagramUrl?: GraphQLTypes["OrderDirection"] | undefined,
	youtubeUrl?: GraphQLTypes["OrderDirection"] | undefined,
	linkedinUrl?: GraphQLTypes["OrderDirection"] | undefined,
	formPrivacyPolicy?: GraphQLTypes["OrderDirection"] | undefined,
	formMarketingConsent?: GraphQLTypes["OrderDirection"] | undefined,
	acceptButtonLabel?: GraphQLTypes["OrderDirection"] | undefined,
	rejectButtonLabel?: GraphQLTypes["OrderDirection"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined
};
	["OrderDirection"]: OrderDirection;
	["GeneralOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined
};
	["LocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	label?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["SeoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	ogTitle?: GraphQLTypes["OrderDirection"] | undefined,
	ogDescription?: GraphQLTypes["OrderDirection"] | undefined,
	ogImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	noIndex?: GraphQLTypes["OrderDirection"] | undefined,
	noFollow?: GraphQLTypes["OrderDirection"] | undefined
};
	["ImageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	url?: GraphQLTypes["OrderDirection"] | undefined,
	width?: GraphQLTypes["OrderDirection"] | undefined,
	height?: GraphQLTypes["OrderDirection"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	alt?: GraphQLTypes["OrderDirection"] | undefined,
	fileName?: GraphQLTypes["OrderDirection"] | undefined
};
	["LinkableOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	url?: GraphQLTypes["OrderDirection"] | undefined,
	genericPage?: GraphQLTypes["GenericPageLocaleOrderBy"] | undefined,
	caseStudy?: GraphQLTypes["CaseStudyLocaleOrderBy"] | undefined,
	position?: GraphQLTypes["PositionLocaleOrderBy"] | undefined,
	redirect?: GraphQLTypes["RedirectOrderBy"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleOrderBy"] | undefined
};
	["GenericPageLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["GenericPageOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined
};
	["GenericPageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	publishedAt?: GraphQLTypes["OrderDirection"] | undefined
};
	["ContentOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["CaseStudyLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["CaseStudyOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined
};
	["CaseStudyOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	wideImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	narrowImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	publishedAt?: GraphQLTypes["OrderDirection"] | undefined
};
	["PositionLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	root?: GraphQLTypes["PositionOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined,
	summary?: GraphQLTypes["OrderDirection"] | undefined,
	salaryRange?: GraphQLTypes["OrderDirection"] | undefined
};
	["PositionOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	tags?: GraphQLTypes["PositionTagListOrderBy"] | undefined,
	orderInCategory?: GraphQLTypes["OrderDirection"] | undefined,
	category?: GraphQLTypes["PositionCategoryOrderBy"] | undefined,
	publishedAt?: GraphQLTypes["OrderDirection"] | undefined
};
	["PositionTagListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["PositionCategoryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["RedirectOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	target?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["LinkOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	externalLink?: GraphQLTypes["OrderDirection"] | undefined,
	internalLink?: GraphQLTypes["LinkableOrderBy"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	isTargetBlank?: GraphQLTypes["OrderDirection"] | undefined
};
	["BlogPostLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["BlogPostOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	lead?: GraphQLTypes["OrderDirection"] | undefined
};
	["BlogPostOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	publishedAt?: GraphQLTypes["OrderDirection"] | undefined,
	author?: GraphQLTypes["AuthorOrderBy"] | undefined,
	wideImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	narrowImage?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["AuthorOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	profileUrl?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	avatarImage?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["GenericPageLocale"]: {
	__typename: "GenericPageLocale",
	_meta?: GraphQLTypes["GenericPageLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	root?: GraphQLTypes["GenericPage"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined
};
	["GenericPageLocaleMeta"]: {
	__typename: "GenericPageLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined
};
	["GenericPage"]: {
	__typename: "GenericPage",
	_meta?: GraphQLTypes["GenericPageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["GenericPageLocale"]>,
	publishedAt?: GraphQLTypes["DateTime"] | undefined,
	localesByLocale?: GraphQLTypes["GenericPageLocale"] | undefined,
	localesByContent?: GraphQLTypes["GenericPageLocale"] | undefined,
	localesByLink?: GraphQLTypes["GenericPageLocale"] | undefined,
	localesBySeo?: GraphQLTypes["GenericPageLocale"] | undefined,
	paginateLocales: GraphQLTypes["GenericPageLocaleConnection"]
};
	["GenericPageMeta"]: {
	__typename: "GenericPageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	publishedAt?: GraphQLTypes["FieldMeta"] | undefined
};
	["GenericPageLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["LocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	general?: GraphQLTypes["GeneralLocaleUniqueWhere"] | undefined,
	genericPages?: GraphQLTypes["GenericPageLocaleUniqueWhere"] | undefined,
	headers?: GraphQLTypes["HeaderLocaleUniqueWhere"] | undefined,
	footers?: GraphQLTypes["FooterLocaleUniqueWhere"] | undefined,
	caseStudies?: GraphQLTypes["CaseStudyLocaleUniqueWhere"] | undefined,
	tags?: GraphQLTypes["TagLocaleUniqueWhere"] | undefined,
	positionTags?: GraphQLTypes["PositionTagLocaleUniqueWhere"] | undefined,
	positions?: GraphQLTypes["PositionLocaleUniqueWhere"] | undefined,
	positionCategories?: GraphQLTypes["PositionCategoryLocaleUniqueWhere"] | undefined,
	authors?: GraphQLTypes["AuthorLocaleUniqueWhere"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostLocaleUniqueWhere"] | undefined,
	blogPostCommon?: GraphQLTypes["BlogPostCommonLocaleUniqueWhere"] | undefined
};
	["GeneralLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["GeneralUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["GeneralUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["GeneralLocaleUniqueWhere"] | undefined
};
	["SeoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	ogImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["ImageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ContentUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	blocks?: GraphQLTypes["ContentBlockUniqueWhere"] | undefined
};
	["ContentBlockUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	references?: GraphQLTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentReferenceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkUniqueWhere"] | undefined,
	featuredCaseStudies?: GraphQLTypes["CaseStudyListUniqueWhere"] | undefined,
	images?: GraphQLTypes["ImageListUniqueWhere"] | undefined,
	otherImages?: GraphQLTypes["ImageListUniqueWhere"] | undefined,
	informationList?: GraphQLTypes["InformationListUniqueWhere"] | undefined,
	embed?: GraphQLTypes["EmbedUniqueWhere"] | undefined,
	links?: GraphQLTypes["LinkListUniqueWhere"] | undefined,
	talkLists?: GraphQLTypes["TalkListsUniqueWhere"] | undefined,
	businessCardList?: GraphQLTypes["BusinessCardListUniqueWhere"] | undefined,
	testimonials?: GraphQLTypes["TestimonialListUniqueWhere"] | undefined,
	contactRequestOptions?: GraphQLTypes["ContactRequestOptionListUniqueWhere"] | undefined,
	youtubeVideo?: GraphQLTypes["YoutubeVideoUniqueWhere"] | undefined,
	positionCategories?: GraphQLTypes["ContentReferencePositionCategoryItemUniqueWhere"] | undefined,
	caseStudies?: GraphQLTypes["ContentReferenceCaseStudyItemUniqueWhere"] | undefined,
	tags?: GraphQLTypes["ContentReferenceTagItemUniqueWhere"] | undefined,
	positions?: GraphQLTypes["PositionListUniqueWhere"] | undefined,
	testimonialsFull?: GraphQLTypes["TestimonialFullListUniqueWhere"] | undefined,
	blogPosts?: GraphQLTypes["ContentReferenceBlogPostItemUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LinkUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["CaseStudyListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["CaseStudyListItemUniqueWhere"] | undefined
};
	["CaseStudyListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ImageListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ImageListItemUniqueWhere"] | undefined
};
	["ImageListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["InformationListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["PieceOfInformationUniqueWhere"] | undefined
};
	["PieceOfInformationUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["EmbedUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["LinkListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["LinkListItemUniqueWhere"] | undefined
};
	["LinkListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["TalkListsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["TalkListUniqueWhere"] | undefined
};
	["TalkListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["TalkListItemUniqueWhere"] | undefined
};
	["TalkListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["BusinessCardListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["BusinessCardListItemUniqueWhere"] | undefined
};
	["BusinessCardListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCard?: GraphQLTypes["BusinessCardUniqueWhere"] | undefined
};
	["BusinessCardUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["TestimonialListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["TestimonialListItemUniqueWhere"] | undefined
};
	["TestimonialListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	testimonial?: GraphQLTypes["TestimonialUniqueWhere"] | undefined
};
	["TestimonialUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ContactRequestOptionListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ContactRequestOptionUniqueWhere"] | undefined
};
	["ContactRequestOptionUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["YoutubeVideoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ContentReferencePositionCategoryItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	item?: GraphQLTypes["PositionCategoryUniqueWhere"] | undefined
};
	["PositionCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	positions?: GraphQLTypes["PositionUniqueWhere"] | undefined,
	locales?: GraphQLTypes["PositionCategoryLocaleUniqueWhere"] | undefined
};
	["PositionUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	tags?: GraphQLTypes["PositionTagListUniqueWhere"] | undefined,
	locales?: GraphQLTypes["PositionLocaleUniqueWhere"] | undefined
};
	["PositionTagListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["PositionTagListItemUniqueWhere"] | undefined
};
	["PositionTagListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["PositionLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["PositionUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LinkableUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	url?: string | undefined,
	genericPage?: GraphQLTypes["GenericPageLocaleUniqueWhere"] | undefined,
	caseStudy?: GraphQLTypes["CaseStudyLocaleUniqueWhere"] | undefined,
	position?: GraphQLTypes["PositionLocaleUniqueWhere"] | undefined,
	redirect?: GraphQLTypes["RedirectUniqueWhere"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleUniqueWhere"] | undefined
};
	["GenericPageLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["GenericPageUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["GenericPageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["GenericPageLocaleUniqueWhere"] | undefined
};
	["CaseStudyLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["CaseStudyUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["CaseStudyUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["CaseStudyLocaleUniqueWhere"] | undefined,
	tags?: GraphQLTypes["CaseStudyTagUniqueWhere"] | undefined
};
	["CaseStudyTagUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["RedirectUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	target?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["BlogPostLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["BlogPostUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["BlogPostUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["BlogPostLocaleUniqueWhere"] | undefined,
	relevantBlogPosts?: GraphQLTypes["RelevantBlogPostListUniqueWhere"] | undefined
};
	["RelevantBlogPostListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	blogPost?: GraphQLTypes["BlogPostUniqueWhere"] | undefined,
	relevantBlogPost?: GraphQLTypes["BlogPostUniqueWhere"] | undefined
};
	["PositionCategoryLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["PositionCategoryUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ContentReferenceCaseStudyItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	item?: GraphQLTypes["CaseStudyUniqueWhere"] | undefined
};
	["ContentReferenceTagItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	item?: GraphQLTypes["TagUniqueWhere"] | undefined
};
	["TagUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["TagLocaleUniqueWhere"] | undefined,
	caseStudyTags?: GraphQLTypes["CaseStudyTagUniqueWhere"] | undefined
};
	["TagLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	slug?: string | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	root?: GraphQLTypes["TagUniqueWhere"] | undefined
};
	["PositionListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["PositionListItemUniqueWhere"] | undefined
};
	["PositionListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["TestimonialFullListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["TestimonialFullListItemUniqueWhere"] | undefined
};
	["TestimonialFullListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	testimonial?: GraphQLTypes["TestimonialFullUniqueWhere"] | undefined
};
	["TestimonialFullUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ContentReferenceBlogPostItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	item?: GraphQLTypes["BlogPostUniqueWhere"] | undefined
};
	["HeaderLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["HeaderUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	linkList?: GraphQLTypes["LinkListUniqueWhere"] | undefined,
	socialLinkList?: GraphQLTypes["LinkListUniqueWhere"] | undefined,
	topLevelLinkItems?: GraphQLTypes["TopLevelLinkItemUniqueWhere"] | undefined
};
	["HeaderUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["HeaderLocaleUniqueWhere"] | undefined
};
	["TopLevelLinkItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	secondLevelLinkItems?: GraphQLTypes["SecondLevelLinkItemUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["SecondLevelLinkItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkUniqueWhere"] | undefined,
	thirdLevelLinkItems?: GraphQLTypes["ThirdLevelLinkItemUniqueWhere"] | undefined
};
	["ThirdLevelLinkItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["FooterLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["FooterUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	navigation?: GraphQLTypes["LinkListUniqueWhere"] | undefined
};
	["FooterUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["FooterLocaleUniqueWhere"] | undefined
};
	["PositionTagLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	slug?: string | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	root?: GraphQLTypes["PositionTagUniqueWhere"] | undefined
};
	["PositionTagUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["PositionTagLocaleUniqueWhere"] | undefined
};
	["AuthorLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["AuthorUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["AuthorUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["AuthorLocaleUniqueWhere"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostUniqueWhere"] | undefined
};
	["BlogPostCommonLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["BlogPostCommonUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BlogPostCommonUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["BlogPostCommonLocaleUniqueWhere"] | undefined
};
	["GenericPageLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["GenericPageLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["GenericPageLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["GenericPageLocaleConnection"]: {
	__typename: "GenericPageLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GenericPageLocaleEdge"]>
};
	["PageInfo"]: {
	__typename: "PageInfo",
	totalCount: number
};
	["GenericPageLocaleEdge"]: {
	__typename: "GenericPageLocaleEdge",
	node: GraphQLTypes["GenericPageLocale"]
};
	["Content"]: {
	__typename: "Content",
	_meta?: GraphQLTypes["ContentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	blocks: Array<GraphQLTypes["ContentBlock"]>,
	blocksByReferences?: GraphQLTypes["ContentBlock"] | undefined,
	paginateBlocks: GraphQLTypes["ContentBlockConnection"]
};
	["ContentMeta"]: {
	__typename: "ContentMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	blocks?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentBlock"]: {
	__typename: "ContentBlock",
	_meta?: GraphQLTypes["ContentBlockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	json: string,
	content?: GraphQLTypes["Content"] | undefined,
	references: Array<GraphQLTypes["ContentReference"]>,
	referencesByLink?: GraphQLTypes["ContentReference"] | undefined,
	referencesByFeaturedCaseStudies?: GraphQLTypes["ContentReference"] | undefined,
	referencesByImages?: GraphQLTypes["ContentReference"] | undefined,
	referencesByOtherImages?: GraphQLTypes["ContentReference"] | undefined,
	referencesByInformationList?: GraphQLTypes["ContentReference"] | undefined,
	referencesByEmbed?: GraphQLTypes["ContentReference"] | undefined,
	referencesByLinks?: GraphQLTypes["ContentReference"] | undefined,
	referencesByTalkLists?: GraphQLTypes["ContentReference"] | undefined,
	referencesByBusinessCardList?: GraphQLTypes["ContentReference"] | undefined,
	referencesByTestimonials?: GraphQLTypes["ContentReference"] | undefined,
	referencesByContactRequestOptions?: GraphQLTypes["ContentReference"] | undefined,
	referencesByYoutubeVideo?: GraphQLTypes["ContentReference"] | undefined,
	referencesByPositionCategories?: GraphQLTypes["ContentReference"] | undefined,
	referencesByCaseStudies?: GraphQLTypes["ContentReference"] | undefined,
	referencesByTags?: GraphQLTypes["ContentReference"] | undefined,
	referencesByPositions?: GraphQLTypes["ContentReference"] | undefined,
	referencesByTestimonialsFull?: GraphQLTypes["ContentReference"] | undefined,
	referencesByBlogPosts?: GraphQLTypes["ContentReference"] | undefined,
	referencesByContent?: GraphQLTypes["ContentReference"] | undefined,
	paginateReferences: GraphQLTypes["ContentReferenceConnection"]
};
	["ContentBlockMeta"]: {
	__typename: "ContentBlockMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	json?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	references?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentReference"]: {
	__typename: "ContentReference",
	_meta?: GraphQLTypes["ContentReferenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["ContentReferenceType"],
	primaryText?: string | undefined,
	block?: GraphQLTypes["ContentBlock"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	secondaryText?: string | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	featuredCaseStudies?: GraphQLTypes["CaseStudyList"] | undefined,
	images?: GraphQLTypes["ImageList"] | undefined,
	otherImages?: GraphQLTypes["ImageList"] | undefined,
	informationList?: GraphQLTypes["InformationList"] | undefined,
	tertiaryText?: string | undefined,
	embed?: GraphQLTypes["Embed"] | undefined,
	links?: GraphQLTypes["LinkList"] | undefined,
	talkLists?: GraphQLTypes["TalkLists"] | undefined,
	businessCardList?: GraphQLTypes["BusinessCardList"] | undefined,
	testimonials?: GraphQLTypes["TestimonialList"] | undefined,
	background: GraphQLTypes["ContentBackgroundType"],
	otherImage?: GraphQLTypes["Image"] | undefined,
	caseStudy?: GraphQLTypes["CaseStudy"] | undefined,
	alternative: boolean,
	contactRequestOptions?: GraphQLTypes["ContactRequestOptionList"] | undefined,
	youtubeVideo?: GraphQLTypes["YoutubeVideo"] | undefined,
	subscribeForm?: GraphQLTypes["SubscribeForm"] | undefined,
	video?: GraphQLTypes["Video"] | undefined,
	otherVideo?: GraphQLTypes["Video"] | undefined,
	positionCategories: Array<GraphQLTypes["ContentReferencePositionCategoryItem"]>,
	caseStudies: Array<GraphQLTypes["ContentReferenceCaseStudyItem"]>,
	tags: Array<GraphQLTypes["ContentReferenceTagItem"]>,
	positions?: GraphQLTypes["PositionList"] | undefined,
	testimonialsFull?: GraphQLTypes["TestimonialFullList"] | undefined,
	blogPosts: Array<GraphQLTypes["ContentReferenceBlogPostItem"]>,
	verticalMargin: GraphQLTypes["ContentVerticalMargin"],
	variant?: string | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	positionCategoriesByItem?: GraphQLTypes["ContentReferencePositionCategoryItem"] | undefined,
	caseStudiesByItem?: GraphQLTypes["ContentReferenceCaseStudyItem"] | undefined,
	tagsByItem?: GraphQLTypes["ContentReferenceTagItem"] | undefined,
	blogPostsByItem?: GraphQLTypes["ContentReferenceBlogPostItem"] | undefined,
	paginatePositionCategories: GraphQLTypes["ContentReferencePositionCategoryItemConnection"],
	paginateCaseStudies: GraphQLTypes["ContentReferenceCaseStudyItemConnection"],
	paginateTags: GraphQLTypes["ContentReferenceTagItemConnection"],
	paginateBlogPosts: GraphQLTypes["ContentReferenceBlogPostItemConnection"]
};
	["ContentReferenceMeta"]: {
	__typename: "ContentReferenceMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	primaryText?: GraphQLTypes["FieldMeta"] | undefined,
	block?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryText?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	featuredCaseStudies?: GraphQLTypes["FieldMeta"] | undefined,
	images?: GraphQLTypes["FieldMeta"] | undefined,
	otherImages?: GraphQLTypes["FieldMeta"] | undefined,
	informationList?: GraphQLTypes["FieldMeta"] | undefined,
	tertiaryText?: GraphQLTypes["FieldMeta"] | undefined,
	embed?: GraphQLTypes["FieldMeta"] | undefined,
	links?: GraphQLTypes["FieldMeta"] | undefined,
	talkLists?: GraphQLTypes["FieldMeta"] | undefined,
	businessCardList?: GraphQLTypes["FieldMeta"] | undefined,
	testimonials?: GraphQLTypes["FieldMeta"] | undefined,
	background?: GraphQLTypes["FieldMeta"] | undefined,
	otherImage?: GraphQLTypes["FieldMeta"] | undefined,
	caseStudy?: GraphQLTypes["FieldMeta"] | undefined,
	alternative?: GraphQLTypes["FieldMeta"] | undefined,
	contactRequestOptions?: GraphQLTypes["FieldMeta"] | undefined,
	youtubeVideo?: GraphQLTypes["FieldMeta"] | undefined,
	subscribeForm?: GraphQLTypes["FieldMeta"] | undefined,
	video?: GraphQLTypes["FieldMeta"] | undefined,
	otherVideo?: GraphQLTypes["FieldMeta"] | undefined,
	positionCategories?: GraphQLTypes["FieldMeta"] | undefined,
	caseStudies?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	positions?: GraphQLTypes["FieldMeta"] | undefined,
	testimonialsFull?: GraphQLTypes["FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["FieldMeta"] | undefined,
	verticalMargin?: GraphQLTypes["FieldMeta"] | undefined,
	variant?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined
};
	["Image"]: {
	__typename: "Image",
	_meta?: GraphQLTypes["ImageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	alt?: string | undefined,
	fileName?: string | undefined
};
	["ImageMeta"]: {
	__typename: "ImageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	alt?: GraphQLTypes["FieldMeta"] | undefined,
	fileName?: GraphQLTypes["FieldMeta"] | undefined
};
	["Link"]: {
	__typename: "Link",
	_meta?: GraphQLTypes["LinkMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	externalLink?: string | undefined,
	internalLink?: GraphQLTypes["Linkable"] | undefined,
	type: GraphQLTypes["LinkType"],
	isTargetBlank: boolean
};
	["LinkMeta"]: {
	__typename: "LinkMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	externalLink?: GraphQLTypes["FieldMeta"] | undefined,
	internalLink?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	isTargetBlank?: GraphQLTypes["FieldMeta"] | undefined
};
	["Linkable"]: {
	__typename: "Linkable",
	_meta?: GraphQLTypes["LinkableMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	genericPage?: GraphQLTypes["GenericPageLocale"] | undefined,
	caseStudy?: GraphQLTypes["CaseStudyLocale"] | undefined,
	position?: GraphQLTypes["PositionLocale"] | undefined,
	redirect?: GraphQLTypes["Redirect"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocale"] | undefined
};
	["LinkableMeta"]: {
	__typename: "LinkableMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	genericPage?: GraphQLTypes["FieldMeta"] | undefined,
	caseStudy?: GraphQLTypes["FieldMeta"] | undefined,
	position?: GraphQLTypes["FieldMeta"] | undefined,
	redirect?: GraphQLTypes["FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["FieldMeta"] | undefined
};
	["CaseStudyLocale"]: {
	__typename: "CaseStudyLocale",
	_meta?: GraphQLTypes["CaseStudyLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	root?: GraphQLTypes["CaseStudy"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	note?: string | undefined
};
	["CaseStudyLocaleMeta"]: {
	__typename: "CaseStudyLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined
};
	["CaseStudy"]: {
	__typename: "CaseStudy",
	_meta?: GraphQLTypes["CaseStudyMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["CaseStudyLocale"]>,
	tags: Array<GraphQLTypes["CaseStudyTag"]>,
	wideImage?: GraphQLTypes["Image"] | undefined,
	narrowImage?: GraphQLTypes["Image"] | undefined,
	order: number,
	publishedAt?: GraphQLTypes["DateTime"] | undefined,
	localesByLocale?: GraphQLTypes["CaseStudyLocale"] | undefined,
	localesByContent?: GraphQLTypes["CaseStudyLocale"] | undefined,
	localesByLink?: GraphQLTypes["CaseStudyLocale"] | undefined,
	localesBySeo?: GraphQLTypes["CaseStudyLocale"] | undefined,
	paginateLocales: GraphQLTypes["CaseStudyLocaleConnection"],
	paginateTags: GraphQLTypes["CaseStudyTagConnection"]
};
	["CaseStudyMeta"]: {
	__typename: "CaseStudyMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	wideImage?: GraphQLTypes["FieldMeta"] | undefined,
	narrowImage?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	publishedAt?: GraphQLTypes["FieldMeta"] | undefined
};
	["CaseStudyTag"]: {
	__typename: "CaseStudyTag",
	_meta?: GraphQLTypes["CaseStudyTagMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	caseStudy?: GraphQLTypes["CaseStudy"] | undefined,
	tag?: GraphQLTypes["Tag"] | undefined
};
	["CaseStudyTagMeta"]: {
	__typename: "CaseStudyTagMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	caseStudy?: GraphQLTypes["FieldMeta"] | undefined,
	tag?: GraphQLTypes["FieldMeta"] | undefined
};
	["Tag"]: {
	__typename: "Tag",
	_meta?: GraphQLTypes["TagMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["TagLocale"]>,
	caseStudyTags: Array<GraphQLTypes["CaseStudyTag"]>,
	order: number,
	localesByLocale?: GraphQLTypes["TagLocale"] | undefined,
	paginateLocales: GraphQLTypes["TagLocaleConnection"],
	paginateCaseStudyTags: GraphQLTypes["CaseStudyTagConnection"]
};
	["TagMeta"]: {
	__typename: "TagMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	caseStudyTags?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["TagLocale"]: {
	__typename: "TagLocale",
	_meta?: GraphQLTypes["TagLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	slug: string,
	title?: string | undefined,
	root?: GraphQLTypes["Tag"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["TagLocaleMeta"]: {
	__typename: "TagLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	slug?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["TagLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	slug?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["TagOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["TagOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["CaseStudyTagOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	caseStudy?: GraphQLTypes["CaseStudyOrderBy"] | undefined,
	tag?: GraphQLTypes["TagOrderBy"] | undefined
};
	["TagLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["TagLocaleConnection"]: {
	__typename: "TagLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TagLocaleEdge"]>
};
	["TagLocaleEdge"]: {
	__typename: "TagLocaleEdge",
	node: GraphQLTypes["TagLocale"]
};
	["CaseStudyTagConnection"]: {
	__typename: "CaseStudyTagConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CaseStudyTagEdge"]>
};
	["CaseStudyTagEdge"]: {
	__typename: "CaseStudyTagEdge",
	node: GraphQLTypes["CaseStudyTag"]
};
	["CaseStudyLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["CaseStudyLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["CaseStudyLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["CaseStudyLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["CaseStudyLocaleConnection"]: {
	__typename: "CaseStudyLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CaseStudyLocaleEdge"]>
};
	["CaseStudyLocaleEdge"]: {
	__typename: "CaseStudyLocaleEdge",
	node: GraphQLTypes["CaseStudyLocale"]
};
	["Seo"]: {
	__typename: "Seo",
	_meta?: GraphQLTypes["SeoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	description?: string | undefined,
	ogTitle?: string | undefined,
	ogDescription?: string | undefined,
	ogImage?: GraphQLTypes["Image"] | undefined,
	noIndex?: boolean | undefined,
	noFollow?: boolean | undefined
};
	["SeoMeta"]: {
	__typename: "SeoMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	ogTitle?: GraphQLTypes["FieldMeta"] | undefined,
	ogDescription?: GraphQLTypes["FieldMeta"] | undefined,
	ogImage?: GraphQLTypes["FieldMeta"] | undefined,
	noIndex?: GraphQLTypes["FieldMeta"] | undefined,
	noFollow?: GraphQLTypes["FieldMeta"] | undefined
};
	["PositionLocale"]: {
	__typename: "PositionLocale",
	_meta?: GraphQLTypes["PositionLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	root?: GraphQLTypes["Position"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	summary?: string | undefined,
	salaryRange?: string | undefined
};
	["PositionLocaleMeta"]: {
	__typename: "PositionLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	summary?: GraphQLTypes["FieldMeta"] | undefined,
	salaryRange?: GraphQLTypes["FieldMeta"] | undefined
};
	["Position"]: {
	__typename: "Position",
	_meta?: GraphQLTypes["PositionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	tags?: GraphQLTypes["PositionTagList"] | undefined,
	locales: Array<GraphQLTypes["PositionLocale"]>,
	orderInCategory: number,
	category?: GraphQLTypes["PositionCategory"] | undefined,
	publishedAt?: GraphQLTypes["DateTime"] | undefined,
	localesByLocale?: GraphQLTypes["PositionLocale"] | undefined,
	localesByLink?: GraphQLTypes["PositionLocale"] | undefined,
	localesBySeo?: GraphQLTypes["PositionLocale"] | undefined,
	localesByContent?: GraphQLTypes["PositionLocale"] | undefined,
	paginateLocales: GraphQLTypes["PositionLocaleConnection"]
};
	["PositionMeta"]: {
	__typename: "PositionMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	orderInCategory?: GraphQLTypes["FieldMeta"] | undefined,
	category?: GraphQLTypes["FieldMeta"] | undefined,
	publishedAt?: GraphQLTypes["FieldMeta"] | undefined
};
	["PositionTagList"]: {
	__typename: "PositionTagList",
	_meta?: GraphQLTypes["PositionTagListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["PositionTagListItem"]>,
	paginateItems: GraphQLTypes["PositionTagListItemConnection"]
};
	["PositionTagListMeta"]: {
	__typename: "PositionTagListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["PositionTagListItem"]: {
	__typename: "PositionTagListItem",
	_meta?: GraphQLTypes["PositionTagListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["PositionTagList"] | undefined,
	tag?: GraphQLTypes["PositionTag"] | undefined
};
	["PositionTagListItemMeta"]: {
	__typename: "PositionTagListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	tag?: GraphQLTypes["FieldMeta"] | undefined
};
	["PositionTag"]: {
	__typename: "PositionTag",
	_meta?: GraphQLTypes["PositionTagMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["PositionTagLocale"]>,
	localesByLocale?: GraphQLTypes["PositionTagLocale"] | undefined,
	paginateLocales: GraphQLTypes["PositionTagLocaleConnection"]
};
	["PositionTagMeta"]: {
	__typename: "PositionTagMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["PositionTagLocale"]: {
	__typename: "PositionTagLocale",
	_meta?: GraphQLTypes["PositionTagLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	slug: string,
	title?: string | undefined,
	root?: GraphQLTypes["PositionTag"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["PositionTagLocaleMeta"]: {
	__typename: "PositionTagLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	slug?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["PositionTagLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	slug?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["PositionTagOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["PositionTagOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["PositionTagLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["PositionTagLocaleConnection"]: {
	__typename: "PositionTagLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PositionTagLocaleEdge"]>
};
	["PositionTagLocaleEdge"]: {
	__typename: "PositionTagLocaleEdge",
	node: GraphQLTypes["PositionTagLocale"]
};
	["PositionTagListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["PositionTagListOrderBy"] | undefined,
	tag?: GraphQLTypes["PositionTagOrderBy"] | undefined
};
	["PositionTagListItemConnection"]: {
	__typename: "PositionTagListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PositionTagListItemEdge"]>
};
	["PositionTagListItemEdge"]: {
	__typename: "PositionTagListItemEdge",
	node: GraphQLTypes["PositionTagListItem"]
};
	["PositionCategory"]: {
	__typename: "PositionCategory",
	_meta?: GraphQLTypes["PositionCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	positions: Array<GraphQLTypes["Position"]>,
	locales: Array<GraphQLTypes["PositionCategoryLocale"]>,
	order: number,
	positionsByTags?: GraphQLTypes["Position"] | undefined,
	positionsByLocales?: GraphQLTypes["Position"] | undefined,
	localesByLocale?: GraphQLTypes["PositionCategoryLocale"] | undefined,
	paginatePositions: GraphQLTypes["PositionConnection"],
	paginateLocales: GraphQLTypes["PositionCategoryLocaleConnection"]
};
	["PositionCategoryMeta"]: {
	__typename: "PositionCategoryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	positions?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["PositionCategoryLocale"]: {
	__typename: "PositionCategoryLocale",
	_meta?: GraphQLTypes["PositionCategoryLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	root?: GraphQLTypes["PositionCategory"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["PositionCategoryLocaleMeta"]: {
	__typename: "PositionCategoryLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["PositionCategoryLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["PositionCategoryOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["PositionCategoryPositionsByTagsUniqueWhere"]: {
		tags?: GraphQLTypes["PositionTagListUniqueWhere"] | undefined
};
	["PositionCategoryPositionsByLocalesUniqueWhere"]: {
		locales?: GraphQLTypes["PositionLocaleUniqueWhere"] | undefined
};
	["PositionCategoryLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["PositionConnection"]: {
	__typename: "PositionConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PositionEdge"]>
};
	["PositionEdge"]: {
	__typename: "PositionEdge",
	node: GraphQLTypes["Position"]
};
	["PositionCategoryLocaleConnection"]: {
	__typename: "PositionCategoryLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PositionCategoryLocaleEdge"]>
};
	["PositionCategoryLocaleEdge"]: {
	__typename: "PositionCategoryLocaleEdge",
	node: GraphQLTypes["PositionCategoryLocale"]
};
	["PositionLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["PositionLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["PositionLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["PositionLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["PositionLocaleConnection"]: {
	__typename: "PositionLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PositionLocaleEdge"]>
};
	["PositionLocaleEdge"]: {
	__typename: "PositionLocaleEdge",
	node: GraphQLTypes["PositionLocale"]
};
	["Redirect"]: {
	__typename: "Redirect",
	_meta?: GraphQLTypes["RedirectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	link?: GraphQLTypes["Linkable"] | undefined,
	target?: GraphQLTypes["Link"] | undefined
};
	["RedirectMeta"]: {
	__typename: "RedirectMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	target?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostLocale"]: {
	__typename: "BlogPostLocale",
	_meta?: GraphQLTypes["BlogPostLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title: string,
	root?: GraphQLTypes["BlogPost"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	lead?: string | undefined
};
	["BlogPostLocaleMeta"]: {
	__typename: "BlogPostLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	lead?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPost"]: {
	__typename: "BlogPost",
	_meta?: GraphQLTypes["BlogPostMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	publishedAt?: GraphQLTypes["DateTime"] | undefined,
	author?: GraphQLTypes["Author"] | undefined,
	wideImage?: GraphQLTypes["Image"] | undefined,
	narrowImage?: GraphQLTypes["Image"] | undefined,
	locales: Array<GraphQLTypes["BlogPostLocale"]>,
	relevantBlogPosts: Array<GraphQLTypes["RelevantBlogPostList"]>,
	localesByLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByContent?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesBySeo?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByLink?: GraphQLTypes["BlogPostLocale"] | undefined,
	relevantBlogPostsByRelevantBlogPost?: GraphQLTypes["RelevantBlogPostList"] | undefined,
	paginateLocales: GraphQLTypes["BlogPostLocaleConnection"],
	paginateRelevantBlogPosts: GraphQLTypes["RelevantBlogPostListConnection"]
};
	["BlogPostMeta"]: {
	__typename: "BlogPostMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	publishedAt?: GraphQLTypes["FieldMeta"] | undefined,
	author?: GraphQLTypes["FieldMeta"] | undefined,
	wideImage?: GraphQLTypes["FieldMeta"] | undefined,
	narrowImage?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	relevantBlogPosts?: GraphQLTypes["FieldMeta"] | undefined
};
	["Author"]: {
	__typename: "Author",
	_meta?: GraphQLTypes["AuthorMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["AuthorLocale"]>,
	blogPosts: Array<GraphQLTypes["BlogPost"]>,
	name: string,
	profileUrl?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	avatarImage?: GraphQLTypes["Image"] | undefined,
	localesByLocale?: GraphQLTypes["AuthorLocale"] | undefined,
	blogPostsByLocales?: GraphQLTypes["BlogPost"] | undefined,
	blogPostsByRelevantBlogPosts?: GraphQLTypes["BlogPost"] | undefined,
	paginateLocales: GraphQLTypes["AuthorLocaleConnection"],
	paginateBlogPosts: GraphQLTypes["BlogPostConnection"]
};
	["AuthorMeta"]: {
	__typename: "AuthorMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	profileUrl?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	avatarImage?: GraphQLTypes["FieldMeta"] | undefined
};
	["AuthorLocale"]: {
	__typename: "AuthorLocale",
	_meta?: GraphQLTypes["AuthorLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	role?: string | undefined,
	bio?: string | undefined,
	root?: GraphQLTypes["Author"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["AuthorLocaleMeta"]: {
	__typename: "AuthorLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	role?: GraphQLTypes["FieldMeta"] | undefined,
	bio?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["AuthorLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	role?: GraphQLTypes["OrderDirection"] | undefined,
	bio?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["AuthorOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["AuthorLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["AuthorBlogPostsByLocalesUniqueWhere"]: {
		locales?: GraphQLTypes["BlogPostLocaleUniqueWhere"] | undefined
};
	["AuthorBlogPostsByRelevantBlogPostsUniqueWhere"]: {
		relevantBlogPosts?: GraphQLTypes["RelevantBlogPostListUniqueWhere"] | undefined
};
	["AuthorLocaleConnection"]: {
	__typename: "AuthorLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AuthorLocaleEdge"]>
};
	["AuthorLocaleEdge"]: {
	__typename: "AuthorLocaleEdge",
	node: GraphQLTypes["AuthorLocale"]
};
	["BlogPostConnection"]: {
	__typename: "BlogPostConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostEdge"]>
};
	["BlogPostEdge"]: {
	__typename: "BlogPostEdge",
	node: GraphQLTypes["BlogPost"]
};
	["RelevantBlogPostList"]: {
	__typename: "RelevantBlogPostList",
	_meta?: GraphQLTypes["RelevantBlogPostListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	blogPost?: GraphQLTypes["BlogPost"] | undefined,
	relevantBlogPost?: GraphQLTypes["BlogPost"] | undefined
};
	["RelevantBlogPostListMeta"]: {
	__typename: "RelevantBlogPostListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["FieldMeta"] | undefined,
	relevantBlogPost?: GraphQLTypes["FieldMeta"] | undefined
};
	["RelevantBlogPostListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	blogPost?: GraphQLTypes["BlogPostOrderBy"] | undefined,
	relevantBlogPost?: GraphQLTypes["BlogPostOrderBy"] | undefined
};
	["BlogPostLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BlogPostLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["BlogPostLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["BlogPostLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["BlogPostRelevantBlogPostsByRelevantBlogPostUniqueWhere"]: {
		relevantBlogPost?: GraphQLTypes["BlogPostUniqueWhere"] | undefined
};
	["BlogPostLocaleConnection"]: {
	__typename: "BlogPostLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostLocaleEdge"]>
};
	["BlogPostLocaleEdge"]: {
	__typename: "BlogPostLocaleEdge",
	node: GraphQLTypes["BlogPostLocale"]
};
	["RelevantBlogPostListConnection"]: {
	__typename: "RelevantBlogPostListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["RelevantBlogPostListEdge"]>
};
	["RelevantBlogPostListEdge"]: {
	__typename: "RelevantBlogPostListEdge",
	node: GraphQLTypes["RelevantBlogPostList"]
};
	["CaseStudyList"]: {
	__typename: "CaseStudyList",
	_meta?: GraphQLTypes["CaseStudyListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["CaseStudyListItem"]>,
	paginateItems: GraphQLTypes["CaseStudyListItemConnection"]
};
	["CaseStudyListMeta"]: {
	__typename: "CaseStudyListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["CaseStudyListItem"]: {
	__typename: "CaseStudyListItem",
	_meta?: GraphQLTypes["CaseStudyListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["CaseStudyList"] | undefined,
	caseStudy?: GraphQLTypes["CaseStudy"] | undefined
};
	["CaseStudyListItemMeta"]: {
	__typename: "CaseStudyListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	caseStudy?: GraphQLTypes["FieldMeta"] | undefined
};
	["CaseStudyListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["CaseStudyListOrderBy"] | undefined,
	caseStudy?: GraphQLTypes["CaseStudyOrderBy"] | undefined
};
	["CaseStudyListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["CaseStudyListItemConnection"]: {
	__typename: "CaseStudyListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CaseStudyListItemEdge"]>
};
	["CaseStudyListItemEdge"]: {
	__typename: "CaseStudyListItemEdge",
	node: GraphQLTypes["CaseStudyListItem"]
};
	["ImageList"]: {
	__typename: "ImageList",
	_meta?: GraphQLTypes["ImageListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ImageListItem"]>,
	itemsByImage?: GraphQLTypes["ImageListItem"] | undefined,
	paginateItems: GraphQLTypes["ImageListItemConnection"]
};
	["ImageListMeta"]: {
	__typename: "ImageListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListItem"]: {
	__typename: "ImageListItem",
	_meta?: GraphQLTypes["ImageListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ImageList"] | undefined,
	image?: GraphQLTypes["Image"] | undefined
};
	["ImageListItemMeta"]: {
	__typename: "ImageListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ImageListOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["ImageListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["ImageListItemsByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["ImageListItemConnection"]: {
	__typename: "ImageListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListItemEdge"]>
};
	["ImageListItemEdge"]: {
	__typename: "ImageListItemEdge",
	node: GraphQLTypes["ImageListItem"]
};
	["InformationList"]: {
	__typename: "InformationList",
	_meta?: GraphQLTypes["InformationListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["PieceOfInformation"]>,
	paginateItems: GraphQLTypes["PieceOfInformationConnection"]
};
	["InformationListMeta"]: {
	__typename: "InformationListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["PieceOfInformation"]: {
	__typename: "PieceOfInformation",
	_meta?: GraphQLTypes["PieceOfInformationMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	description?: string | undefined,
	list?: GraphQLTypes["InformationList"] | undefined
};
	["PieceOfInformationMeta"]: {
	__typename: "PieceOfInformationMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined
};
	["PieceOfInformationOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["InformationListOrderBy"] | undefined
};
	["InformationListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["PieceOfInformationConnection"]: {
	__typename: "PieceOfInformationConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PieceOfInformationEdge"]>
};
	["PieceOfInformationEdge"]: {
	__typename: "PieceOfInformationEdge",
	node: GraphQLTypes["PieceOfInformation"]
};
	["Embed"]: {
	__typename: "Embed",
	_meta?: GraphQLTypes["EmbedMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["EmbedType"],
	embedId?: string | undefined
};
	["EmbedMeta"]: {
	__typename: "EmbedMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	embedId?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkList"]: {
	__typename: "LinkList",
	_meta?: GraphQLTypes["LinkListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["LinkListItem"]>,
	itemsByLink?: GraphQLTypes["LinkListItem"] | undefined,
	paginateItems: GraphQLTypes["LinkListItemConnection"]
};
	["LinkListMeta"]: {
	__typename: "LinkListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkListItem"]: {
	__typename: "LinkListItem",
	_meta?: GraphQLTypes["LinkListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["LinkList"] | undefined,
	link?: GraphQLTypes["Link"] | undefined
};
	["LinkListItemMeta"]: {
	__typename: "LinkListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["LinkListOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["LinkListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["LinkListItemsByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["LinkListItemConnection"]: {
	__typename: "LinkListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkListItemEdge"]>
};
	["LinkListItemEdge"]: {
	__typename: "LinkListItemEdge",
	node: GraphQLTypes["LinkListItem"]
};
	["TalkLists"]: {
	__typename: "TalkLists",
	_meta?: GraphQLTypes["TalkListsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["TalkList"]>,
	itemsByItems?: GraphQLTypes["TalkList"] | undefined,
	paginateItems: GraphQLTypes["TalkListConnection"]
};
	["TalkListsMeta"]: {
	__typename: "TalkListsMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["TalkList"]: {
	__typename: "TalkList",
	_meta?: GraphQLTypes["TalkListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	list?: GraphQLTypes["TalkLists"] | undefined,
	items: Array<GraphQLTypes["TalkListItem"]>,
	paginateItems: GraphQLTypes["TalkListItemConnection"]
};
	["TalkListMeta"]: {
	__typename: "TalkListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["TalkListItem"]: {
	__typename: "TalkListItem",
	_meta?: GraphQLTypes["TalkListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["TalkList"] | undefined,
	talk?: GraphQLTypes["Talk"] | undefined
};
	["TalkListItemMeta"]: {
	__typename: "TalkListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	talk?: GraphQLTypes["FieldMeta"] | undefined
};
	["Talk"]: {
	__typename: "Talk",
	_meta?: GraphQLTypes["TalkMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	slug?: string | undefined,
	date?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	content?: string | undefined,
	coverImage?: GraphQLTypes["Image"] | undefined,
	otherImage?: GraphQLTypes["Image"] | undefined
};
	["TalkMeta"]: {
	__typename: "TalkMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	slug?: GraphQLTypes["FieldMeta"] | undefined,
	date?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	coverImage?: GraphQLTypes["FieldMeta"] | undefined,
	otherImage?: GraphQLTypes["FieldMeta"] | undefined
};
	["TalkListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["TalkListOrderBy"] | undefined,
	talk?: GraphQLTypes["TalkOrderBy"] | undefined
};
	["TalkListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["TalkListsOrderBy"] | undefined
};
	["TalkListsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["TalkOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	slug?: GraphQLTypes["OrderDirection"] | undefined,
	date?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	content?: GraphQLTypes["OrderDirection"] | undefined,
	coverImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	otherImage?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["TalkListItemConnection"]: {
	__typename: "TalkListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TalkListItemEdge"]>
};
	["TalkListItemEdge"]: {
	__typename: "TalkListItemEdge",
	node: GraphQLTypes["TalkListItem"]
};
	["TalkListsItemsByItemsUniqueWhere"]: {
		items?: GraphQLTypes["TalkListItemUniqueWhere"] | undefined
};
	["TalkListConnection"]: {
	__typename: "TalkListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TalkListEdge"]>
};
	["TalkListEdge"]: {
	__typename: "TalkListEdge",
	node: GraphQLTypes["TalkList"]
};
	["BusinessCardList"]: {
	__typename: "BusinessCardList",
	_meta?: GraphQLTypes["BusinessCardListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["BusinessCardListItem"]>,
	itemsByBusinessCard?: GraphQLTypes["BusinessCardListItem"] | undefined,
	paginateItems: GraphQLTypes["BusinessCardListItemConnection"]
};
	["BusinessCardListMeta"]: {
	__typename: "BusinessCardListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCardListItem"]: {
	__typename: "BusinessCardListItem",
	_meta?: GraphQLTypes["BusinessCardListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["BusinessCardList"] | undefined,
	businessCard?: GraphQLTypes["BusinessCard"] | undefined
};
	["BusinessCardListItemMeta"]: {
	__typename: "BusinessCardListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	businessCard?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCard"]: {
	__typename: "BusinessCard",
	_meta?: GraphQLTypes["BusinessCardMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	role?: string | undefined,
	email?: string | undefined,
	avatar?: GraphQLTypes["Image"] | undefined,
	phone?: string | undefined
};
	["BusinessCardMeta"]: {
	__typename: "BusinessCardMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	role?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	avatar?: GraphQLTypes["FieldMeta"] | undefined,
	phone?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCardListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["BusinessCardListOrderBy"] | undefined,
	businessCard?: GraphQLTypes["BusinessCardOrderBy"] | undefined
};
	["BusinessCardListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["BusinessCardOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	role?: GraphQLTypes["OrderDirection"] | undefined,
	email?: GraphQLTypes["OrderDirection"] | undefined,
	avatar?: GraphQLTypes["ImageOrderBy"] | undefined,
	phone?: GraphQLTypes["OrderDirection"] | undefined
};
	["BusinessCardListItemsByBusinessCardUniqueWhere"]: {
		businessCard?: GraphQLTypes["BusinessCardUniqueWhere"] | undefined
};
	["BusinessCardListItemConnection"]: {
	__typename: "BusinessCardListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessCardListItemEdge"]>
};
	["BusinessCardListItemEdge"]: {
	__typename: "BusinessCardListItemEdge",
	node: GraphQLTypes["BusinessCardListItem"]
};
	["TestimonialList"]: {
	__typename: "TestimonialList",
	_meta?: GraphQLTypes["TestimonialListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["TestimonialListItem"]>,
	itemsByTestimonial?: GraphQLTypes["TestimonialListItem"] | undefined,
	paginateItems: GraphQLTypes["TestimonialListItemConnection"]
};
	["TestimonialListMeta"]: {
	__typename: "TestimonialListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["TestimonialListItem"]: {
	__typename: "TestimonialListItem",
	_meta?: GraphQLTypes["TestimonialListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["TestimonialList"] | undefined,
	testimonial?: GraphQLTypes["Testimonial"] | undefined
};
	["TestimonialListItemMeta"]: {
	__typename: "TestimonialListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	testimonial?: GraphQLTypes["FieldMeta"] | undefined
};
	["Testimonial"]: {
	__typename: "Testimonial",
	_meta?: GraphQLTypes["TestimonialMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	content?: string | undefined,
	note?: string | undefined,
	avatar?: GraphQLTypes["Image"] | undefined
};
	["TestimonialMeta"]: {
	__typename: "TestimonialMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	avatar?: GraphQLTypes["FieldMeta"] | undefined
};
	["TestimonialListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["TestimonialListOrderBy"] | undefined,
	testimonial?: GraphQLTypes["TestimonialOrderBy"] | undefined
};
	["TestimonialListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["TestimonialOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	content?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	avatar?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["TestimonialListItemsByTestimonialUniqueWhere"]: {
		testimonial?: GraphQLTypes["TestimonialUniqueWhere"] | undefined
};
	["TestimonialListItemConnection"]: {
	__typename: "TestimonialListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TestimonialListItemEdge"]>
};
	["TestimonialListItemEdge"]: {
	__typename: "TestimonialListItemEdge",
	node: GraphQLTypes["TestimonialListItem"]
};
	["ContactRequestOptionList"]: {
	__typename: "ContactRequestOptionList",
	_meta?: GraphQLTypes["ContactRequestOptionListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ContactRequestOption"]>,
	paginateItems: GraphQLTypes["ContactRequestOptionConnection"]
};
	["ContactRequestOptionListMeta"]: {
	__typename: "ContactRequestOptionListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContactRequestOption"]: {
	__typename: "ContactRequestOption",
	_meta?: GraphQLTypes["ContactRequestOptionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	label?: string | undefined,
	description?: string | undefined,
	list?: GraphQLTypes["ContactRequestOptionList"] | undefined,
	code?: string | undefined,
	type: GraphQLTypes["ContactRequestOptionType"],
	subscribeForm?: GraphQLTypes["SubscribeForm"] | undefined
};
	["ContactRequestOptionMeta"]: {
	__typename: "ContactRequestOptionMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	label?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	subscribeForm?: GraphQLTypes["FieldMeta"] | undefined
};
	["SubscribeForm"]: {
	__typename: "SubscribeForm",
	_meta?: GraphQLTypes["SubscribeFormMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	datacruitJobId?: number | undefined,
	datacruitReferenceNumber?: string | undefined,
	lastUpdateAt?: GraphQLTypes["DateTime"] | undefined
};
	["SubscribeFormMeta"]: {
	__typename: "SubscribeFormMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	datacruitJobId?: GraphQLTypes["FieldMeta"] | undefined,
	datacruitReferenceNumber?: GraphQLTypes["FieldMeta"] | undefined,
	lastUpdateAt?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContactRequestOptionOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	label?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ContactRequestOptionListOrderBy"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	subscribeForm?: GraphQLTypes["SubscribeFormOrderBy"] | undefined
};
	["ContactRequestOptionListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["SubscribeFormOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	datacruitJobId?: GraphQLTypes["OrderDirection"] | undefined,
	datacruitReferenceNumber?: GraphQLTypes["OrderDirection"] | undefined,
	lastUpdateAt?: GraphQLTypes["OrderDirection"] | undefined
};
	["ContactRequestOptionConnection"]: {
	__typename: "ContactRequestOptionConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContactRequestOptionEdge"]>
};
	["ContactRequestOptionEdge"]: {
	__typename: "ContactRequestOptionEdge",
	node: GraphQLTypes["ContactRequestOption"]
};
	["YoutubeVideo"]: {
	__typename: "YoutubeVideo",
	_meta?: GraphQLTypes["YoutubeVideoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	videoId: string
};
	["YoutubeVideoMeta"]: {
	__typename: "YoutubeVideoMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	videoId?: GraphQLTypes["FieldMeta"] | undefined
};
	["Video"]: {
	__typename: "Video",
	_meta?: GraphQLTypes["VideoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	src?: string | undefined,
	height?: number | undefined,
	width?: number | undefined,
	duration?: number | undefined,
	size?: number | undefined,
	type?: string | undefined
};
	["VideoMeta"]: {
	__typename: "VideoMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	src?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentReferencePositionCategoryItem"]: {
	__typename: "ContentReferencePositionCategoryItem",
	_meta?: GraphQLTypes["ContentReferencePositionCategoryItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	item?: GraphQLTypes["PositionCategory"] | undefined,
	reference?: GraphQLTypes["ContentReference"] | undefined
};
	["ContentReferencePositionCategoryItemMeta"]: {
	__typename: "ContentReferencePositionCategoryItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	item?: GraphQLTypes["FieldMeta"] | undefined,
	reference?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentReferencePositionCategoryItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	item?: GraphQLTypes["PositionCategoryOrderBy"] | undefined,
	reference?: GraphQLTypes["ContentReferenceOrderBy"] | undefined
};
	["ContentReferenceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	primaryText?: GraphQLTypes["OrderDirection"] | undefined,
	block?: GraphQLTypes["ContentBlockOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	secondaryText?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined,
	featuredCaseStudies?: GraphQLTypes["CaseStudyListOrderBy"] | undefined,
	images?: GraphQLTypes["ImageListOrderBy"] | undefined,
	otherImages?: GraphQLTypes["ImageListOrderBy"] | undefined,
	informationList?: GraphQLTypes["InformationListOrderBy"] | undefined,
	tertiaryText?: GraphQLTypes["OrderDirection"] | undefined,
	embed?: GraphQLTypes["EmbedOrderBy"] | undefined,
	links?: GraphQLTypes["LinkListOrderBy"] | undefined,
	talkLists?: GraphQLTypes["TalkListsOrderBy"] | undefined,
	businessCardList?: GraphQLTypes["BusinessCardListOrderBy"] | undefined,
	testimonials?: GraphQLTypes["TestimonialListOrderBy"] | undefined,
	background?: GraphQLTypes["OrderDirection"] | undefined,
	otherImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	caseStudy?: GraphQLTypes["CaseStudyOrderBy"] | undefined,
	alternative?: GraphQLTypes["OrderDirection"] | undefined,
	contactRequestOptions?: GraphQLTypes["ContactRequestOptionListOrderBy"] | undefined,
	youtubeVideo?: GraphQLTypes["YoutubeVideoOrderBy"] | undefined,
	subscribeForm?: GraphQLTypes["SubscribeFormOrderBy"] | undefined,
	video?: GraphQLTypes["VideoOrderBy"] | undefined,
	otherVideo?: GraphQLTypes["VideoOrderBy"] | undefined,
	positions?: GraphQLTypes["PositionListOrderBy"] | undefined,
	testimonialsFull?: GraphQLTypes["TestimonialFullListOrderBy"] | undefined,
	verticalMargin?: GraphQLTypes["OrderDirection"] | undefined,
	variant?: GraphQLTypes["OrderDirection"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined
};
	["ContentBlockOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	json?: GraphQLTypes["OrderDirection"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined
};
	["EmbedOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	embedId?: GraphQLTypes["OrderDirection"] | undefined
};
	["YoutubeVideoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	videoId?: GraphQLTypes["OrderDirection"] | undefined
};
	["VideoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	src?: GraphQLTypes["OrderDirection"] | undefined,
	height?: GraphQLTypes["OrderDirection"] | undefined,
	width?: GraphQLTypes["OrderDirection"] | undefined,
	duration?: GraphQLTypes["OrderDirection"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined
};
	["PositionListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["TestimonialFullListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["ContentReferenceCaseStudyItem"]: {
	__typename: "ContentReferenceCaseStudyItem",
	_meta?: GraphQLTypes["ContentReferenceCaseStudyItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	item?: GraphQLTypes["CaseStudy"] | undefined,
	reference?: GraphQLTypes["ContentReference"] | undefined
};
	["ContentReferenceCaseStudyItemMeta"]: {
	__typename: "ContentReferenceCaseStudyItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	item?: GraphQLTypes["FieldMeta"] | undefined,
	reference?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentReferenceCaseStudyItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	item?: GraphQLTypes["CaseStudyOrderBy"] | undefined,
	reference?: GraphQLTypes["ContentReferenceOrderBy"] | undefined
};
	["ContentReferenceTagItem"]: {
	__typename: "ContentReferenceTagItem",
	_meta?: GraphQLTypes["ContentReferenceTagItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	item?: GraphQLTypes["Tag"] | undefined,
	reference?: GraphQLTypes["ContentReference"] | undefined
};
	["ContentReferenceTagItemMeta"]: {
	__typename: "ContentReferenceTagItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	item?: GraphQLTypes["FieldMeta"] | undefined,
	reference?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentReferenceTagItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	item?: GraphQLTypes["TagOrderBy"] | undefined,
	reference?: GraphQLTypes["ContentReferenceOrderBy"] | undefined
};
	["PositionList"]: {
	__typename: "PositionList",
	_meta?: GraphQLTypes["PositionListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["PositionListItem"]>,
	paginateItems: GraphQLTypes["PositionListItemConnection"]
};
	["PositionListMeta"]: {
	__typename: "PositionListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["PositionListItem"]: {
	__typename: "PositionListItem",
	_meta?: GraphQLTypes["PositionListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["PositionList"] | undefined,
	position?: GraphQLTypes["PositionLocale"] | undefined
};
	["PositionListItemMeta"]: {
	__typename: "PositionListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	position?: GraphQLTypes["FieldMeta"] | undefined
};
	["PositionListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["PositionListOrderBy"] | undefined,
	position?: GraphQLTypes["PositionLocaleOrderBy"] | undefined
};
	["PositionListItemConnection"]: {
	__typename: "PositionListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PositionListItemEdge"]>
};
	["PositionListItemEdge"]: {
	__typename: "PositionListItemEdge",
	node: GraphQLTypes["PositionListItem"]
};
	["TestimonialFullList"]: {
	__typename: "TestimonialFullList",
	_meta?: GraphQLTypes["TestimonialFullListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["TestimonialFullListItem"]>,
	itemsByTestimonial?: GraphQLTypes["TestimonialFullListItem"] | undefined,
	paginateItems: GraphQLTypes["TestimonialFullListItemConnection"]
};
	["TestimonialFullListMeta"]: {
	__typename: "TestimonialFullListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["TestimonialFullListItem"]: {
	__typename: "TestimonialFullListItem",
	_meta?: GraphQLTypes["TestimonialFullListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["TestimonialFullList"] | undefined,
	testimonial?: GraphQLTypes["TestimonialFull"] | undefined
};
	["TestimonialFullListItemMeta"]: {
	__typename: "TestimonialFullListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	testimonial?: GraphQLTypes["FieldMeta"] | undefined
};
	["TestimonialFull"]: {
	__typename: "TestimonialFull",
	_meta?: GraphQLTypes["TestimonialFullMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	content: string,
	name: string,
	note?: string | undefined,
	avatar?: GraphQLTypes["Image"] | undefined,
	imageVariant: GraphQLTypes["ImageVariant"]
};
	["TestimonialFullMeta"]: {
	__typename: "TestimonialFullMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	avatar?: GraphQLTypes["FieldMeta"] | undefined,
	imageVariant?: GraphQLTypes["FieldMeta"] | undefined
};
	["TestimonialFullListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["TestimonialFullListOrderBy"] | undefined,
	testimonial?: GraphQLTypes["TestimonialFullOrderBy"] | undefined
};
	["TestimonialFullOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	content?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	avatar?: GraphQLTypes["ImageOrderBy"] | undefined,
	imageVariant?: GraphQLTypes["OrderDirection"] | undefined
};
	["TestimonialFullListItemsByTestimonialUniqueWhere"]: {
		testimonial?: GraphQLTypes["TestimonialFullUniqueWhere"] | undefined
};
	["TestimonialFullListItemConnection"]: {
	__typename: "TestimonialFullListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TestimonialFullListItemEdge"]>
};
	["TestimonialFullListItemEdge"]: {
	__typename: "TestimonialFullListItemEdge",
	node: GraphQLTypes["TestimonialFullListItem"]
};
	["ContentReferenceBlogPostItem"]: {
	__typename: "ContentReferenceBlogPostItem",
	_meta?: GraphQLTypes["ContentReferenceBlogPostItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	publishedAt?: GraphQLTypes["Date"] | undefined,
	item?: GraphQLTypes["BlogPost"] | undefined,
	reference?: GraphQLTypes["ContentReference"] | undefined
};
	["ContentReferenceBlogPostItemMeta"]: {
	__typename: "ContentReferenceBlogPostItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	publishedAt?: GraphQLTypes["FieldMeta"] | undefined,
	item?: GraphQLTypes["FieldMeta"] | undefined,
	reference?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentReferenceBlogPostItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	publishedAt?: GraphQLTypes["OrderDirection"] | undefined,
	item?: GraphQLTypes["BlogPostOrderBy"] | undefined,
	reference?: GraphQLTypes["ContentReferenceOrderBy"] | undefined
};
	["ContentReferencePositionCategoriesByItemUniqueWhere"]: {
		item?: GraphQLTypes["PositionCategoryUniqueWhere"] | undefined
};
	["ContentReferenceCaseStudiesByItemUniqueWhere"]: {
		item?: GraphQLTypes["CaseStudyUniqueWhere"] | undefined
};
	["ContentReferenceTagsByItemUniqueWhere"]: {
		item?: GraphQLTypes["TagUniqueWhere"] | undefined
};
	["ContentReferenceBlogPostsByItemUniqueWhere"]: {
		item?: GraphQLTypes["BlogPostUniqueWhere"] | undefined
};
	["ContentReferencePositionCategoryItemConnection"]: {
	__typename: "ContentReferencePositionCategoryItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentReferencePositionCategoryItemEdge"]>
};
	["ContentReferencePositionCategoryItemEdge"]: {
	__typename: "ContentReferencePositionCategoryItemEdge",
	node: GraphQLTypes["ContentReferencePositionCategoryItem"]
};
	["ContentReferenceCaseStudyItemConnection"]: {
	__typename: "ContentReferenceCaseStudyItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentReferenceCaseStudyItemEdge"]>
};
	["ContentReferenceCaseStudyItemEdge"]: {
	__typename: "ContentReferenceCaseStudyItemEdge",
	node: GraphQLTypes["ContentReferenceCaseStudyItem"]
};
	["ContentReferenceTagItemConnection"]: {
	__typename: "ContentReferenceTagItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentReferenceTagItemEdge"]>
};
	["ContentReferenceTagItemEdge"]: {
	__typename: "ContentReferenceTagItemEdge",
	node: GraphQLTypes["ContentReferenceTagItem"]
};
	["ContentReferenceBlogPostItemConnection"]: {
	__typename: "ContentReferenceBlogPostItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentReferenceBlogPostItemEdge"]>
};
	["ContentReferenceBlogPostItemEdge"]: {
	__typename: "ContentReferenceBlogPostItemEdge",
	node: GraphQLTypes["ContentReferenceBlogPostItem"]
};
	["ContentBlockReferencesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["ContentBlockReferencesByFeaturedCaseStudiesUniqueWhere"]: {
		featuredCaseStudies?: GraphQLTypes["CaseStudyListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByImagesUniqueWhere"]: {
		images?: GraphQLTypes["ImageListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByOtherImagesUniqueWhere"]: {
		otherImages?: GraphQLTypes["ImageListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByInformationListUniqueWhere"]: {
		informationList?: GraphQLTypes["InformationListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByEmbedUniqueWhere"]: {
		embed?: GraphQLTypes["EmbedUniqueWhere"] | undefined
};
	["ContentBlockReferencesByLinksUniqueWhere"]: {
		links?: GraphQLTypes["LinkListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByTalkListsUniqueWhere"]: {
		talkLists?: GraphQLTypes["TalkListsUniqueWhere"] | undefined
};
	["ContentBlockReferencesByBusinessCardListUniqueWhere"]: {
		businessCardList?: GraphQLTypes["BusinessCardListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByTestimonialsUniqueWhere"]: {
		testimonials?: GraphQLTypes["TestimonialListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByContactRequestOptionsUniqueWhere"]: {
		contactRequestOptions?: GraphQLTypes["ContactRequestOptionListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByYoutubeVideoUniqueWhere"]: {
		youtubeVideo?: GraphQLTypes["YoutubeVideoUniqueWhere"] | undefined
};
	["ContentBlockReferencesByPositionCategoriesUniqueWhere"]: {
		positionCategories?: GraphQLTypes["ContentReferencePositionCategoryItemUniqueWhere"] | undefined
};
	["ContentBlockReferencesByCaseStudiesUniqueWhere"]: {
		caseStudies?: GraphQLTypes["ContentReferenceCaseStudyItemUniqueWhere"] | undefined
};
	["ContentBlockReferencesByTagsUniqueWhere"]: {
		tags?: GraphQLTypes["ContentReferenceTagItemUniqueWhere"] | undefined
};
	["ContentBlockReferencesByPositionsUniqueWhere"]: {
		positions?: GraphQLTypes["PositionListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByTestimonialsFullUniqueWhere"]: {
		testimonialsFull?: GraphQLTypes["TestimonialFullListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByBlogPostsUniqueWhere"]: {
		blogPosts?: GraphQLTypes["ContentReferenceBlogPostItemUniqueWhere"] | undefined
};
	["ContentBlockReferencesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["ContentReferenceConnection"]: {
	__typename: "ContentReferenceConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentReferenceEdge"]>
};
	["ContentReferenceEdge"]: {
	__typename: "ContentReferenceEdge",
	node: GraphQLTypes["ContentReference"]
};
	["ContentBlocksByReferencesUniqueWhere"]: {
		references?: GraphQLTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentBlockConnection"]: {
	__typename: "ContentBlockConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentBlockEdge"]>
};
	["ContentBlockEdge"]: {
	__typename: "ContentBlockEdge",
	node: GraphQLTypes["ContentBlock"]
};
	["HeaderLocale"]: {
	__typename: "HeaderLocale",
	_meta?: GraphQLTypes["HeaderLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	email?: string | undefined,
	root?: GraphQLTypes["Header"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	linkList?: GraphQLTypes["LinkList"] | undefined,
	socialLinkList?: GraphQLTypes["LinkList"] | undefined,
	topLevelLinkItems: Array<GraphQLTypes["TopLevelLinkItem"]>,
	topLevelLinkItemsBySecondLevelLinkItems?: GraphQLTypes["TopLevelLinkItem"] | undefined,
	topLevelLinkItemsByLink?: GraphQLTypes["TopLevelLinkItem"] | undefined,
	paginateTopLevelLinkItems: GraphQLTypes["TopLevelLinkItemConnection"]
};
	["HeaderLocaleMeta"]: {
	__typename: "HeaderLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	linkList?: GraphQLTypes["FieldMeta"] | undefined,
	socialLinkList?: GraphQLTypes["FieldMeta"] | undefined,
	topLevelLinkItems?: GraphQLTypes["FieldMeta"] | undefined
};
	["Header"]: {
	__typename: "Header",
	_meta?: GraphQLTypes["HeaderMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["HeaderLocale"]>,
	localesByLocale?: GraphQLTypes["HeaderLocale"] | undefined,
	localesByLinkList?: GraphQLTypes["HeaderLocale"] | undefined,
	localesBySocialLinkList?: GraphQLTypes["HeaderLocale"] | undefined,
	localesByTopLevelLinkItems?: GraphQLTypes["HeaderLocale"] | undefined,
	paginateLocales: GraphQLTypes["HeaderLocaleConnection"]
};
	["HeaderMeta"]: {
	__typename: "HeaderMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["HeaderLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	email?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["HeaderOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	linkList?: GraphQLTypes["LinkListOrderBy"] | undefined,
	socialLinkList?: GraphQLTypes["LinkListOrderBy"] | undefined
};
	["HeaderOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined
};
	["HeaderLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["HeaderLocalesByLinkListUniqueWhere"]: {
		linkList?: GraphQLTypes["LinkListUniqueWhere"] | undefined
};
	["HeaderLocalesBySocialLinkListUniqueWhere"]: {
		socialLinkList?: GraphQLTypes["LinkListUniqueWhere"] | undefined
};
	["HeaderLocalesByTopLevelLinkItemsUniqueWhere"]: {
		topLevelLinkItems?: GraphQLTypes["TopLevelLinkItemUniqueWhere"] | undefined
};
	["HeaderLocaleConnection"]: {
	__typename: "HeaderLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HeaderLocaleEdge"]>
};
	["HeaderLocaleEdge"]: {
	__typename: "HeaderLocaleEdge",
	node: GraphQLTypes["HeaderLocale"]
};
	["TopLevelLinkItem"]: {
	__typename: "TopLevelLinkItem",
	_meta?: GraphQLTypes["TopLevelLinkItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isLink: GraphQLTypes["IsLink"],
	order: number,
	title?: string | undefined,
	secondLevelLinkItems: Array<GraphQLTypes["SecondLevelLinkItem"]>,
	header?: GraphQLTypes["HeaderLocale"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	secondLevelLinkItemsByLink?: GraphQLTypes["SecondLevelLinkItem"] | undefined,
	secondLevelLinkItemsByThirdLevelLinkItems?: GraphQLTypes["SecondLevelLinkItem"] | undefined,
	paginateSecondLevelLinkItems: GraphQLTypes["SecondLevelLinkItemConnection"]
};
	["TopLevelLinkItemMeta"]: {
	__typename: "TopLevelLinkItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	isLink?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	secondLevelLinkItems?: GraphQLTypes["FieldMeta"] | undefined,
	header?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined
};
	["SecondLevelLinkItem"]: {
	__typename: "SecondLevelLinkItem",
	_meta?: GraphQLTypes["SecondLevelLinkItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isLink: GraphQLTypes["IsLink"],
	order: number,
	title?: string | undefined,
	topLevelLinkItem?: GraphQLTypes["TopLevelLinkItem"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	thirdLevelLinkItems: Array<GraphQLTypes["ThirdLevelLinkItem"]>,
	thirdLevelLinkItemsByLink?: GraphQLTypes["ThirdLevelLinkItem"] | undefined,
	paginateThirdLevelLinkItems: GraphQLTypes["ThirdLevelLinkItemConnection"]
};
	["SecondLevelLinkItemMeta"]: {
	__typename: "SecondLevelLinkItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	isLink?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	topLevelLinkItem?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	thirdLevelLinkItems?: GraphQLTypes["FieldMeta"] | undefined
};
	["ThirdLevelLinkItem"]: {
	__typename: "ThirdLevelLinkItem",
	_meta?: GraphQLTypes["ThirdLevelLinkItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	secondLevelLinkItem?: GraphQLTypes["SecondLevelLinkItem"] | undefined,
	link?: GraphQLTypes["Link"] | undefined
};
	["ThirdLevelLinkItemMeta"]: {
	__typename: "ThirdLevelLinkItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	secondLevelLinkItem?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined
};
	["ThirdLevelLinkItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	secondLevelLinkItem?: GraphQLTypes["SecondLevelLinkItemOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["SecondLevelLinkItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	isLink?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	topLevelLinkItem?: GraphQLTypes["TopLevelLinkItemOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["TopLevelLinkItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	isLink?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	header?: GraphQLTypes["HeaderLocaleOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["SecondLevelLinkItemThirdLevelLinkItemsByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["ThirdLevelLinkItemConnection"]: {
	__typename: "ThirdLevelLinkItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ThirdLevelLinkItemEdge"]>
};
	["ThirdLevelLinkItemEdge"]: {
	__typename: "ThirdLevelLinkItemEdge",
	node: GraphQLTypes["ThirdLevelLinkItem"]
};
	["TopLevelLinkItemSecondLevelLinkItemsByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["TopLevelLinkItemSecondLevelLinkItemsByThirdLevelLinkItemsUniqueWhere"]: {
		thirdLevelLinkItems?: GraphQLTypes["ThirdLevelLinkItemUniqueWhere"] | undefined
};
	["SecondLevelLinkItemConnection"]: {
	__typename: "SecondLevelLinkItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SecondLevelLinkItemEdge"]>
};
	["SecondLevelLinkItemEdge"]: {
	__typename: "SecondLevelLinkItemEdge",
	node: GraphQLTypes["SecondLevelLinkItem"]
};
	["HeaderLocaleTopLevelLinkItemsBySecondLevelLinkItemsUniqueWhere"]: {
		secondLevelLinkItems?: GraphQLTypes["SecondLevelLinkItemUniqueWhere"] | undefined
};
	["HeaderLocaleTopLevelLinkItemsByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["TopLevelLinkItemConnection"]: {
	__typename: "TopLevelLinkItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TopLevelLinkItemEdge"]>
};
	["TopLevelLinkItemEdge"]: {
	__typename: "TopLevelLinkItemEdge",
	node: GraphQLTypes["TopLevelLinkItem"]
};
	["FooterLocale"]: {
	__typename: "FooterLocale",
	_meta?: GraphQLTypes["FooterLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	email?: string | undefined,
	root?: GraphQLTypes["Footer"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	address?: string | undefined,
	phone?: string | undefined,
	legal?: string | undefined,
	navigation?: GraphQLTypes["LinkList"] | undefined
};
	["FooterLocaleMeta"]: {
	__typename: "FooterLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	address?: GraphQLTypes["FieldMeta"] | undefined,
	phone?: GraphQLTypes["FieldMeta"] | undefined,
	legal?: GraphQLTypes["FieldMeta"] | undefined,
	navigation?: GraphQLTypes["FieldMeta"] | undefined
};
	["Footer"]: {
	__typename: "Footer",
	_meta?: GraphQLTypes["FooterMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["FooterLocale"]>,
	localesByLocale?: GraphQLTypes["FooterLocale"] | undefined,
	localesByNavigation?: GraphQLTypes["FooterLocale"] | undefined,
	paginateLocales: GraphQLTypes["FooterLocaleConnection"]
};
	["FooterMeta"]: {
	__typename: "FooterMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["FooterLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	email?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["FooterOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	address?: GraphQLTypes["OrderDirection"] | undefined,
	phone?: GraphQLTypes["OrderDirection"] | undefined,
	legal?: GraphQLTypes["OrderDirection"] | undefined,
	navigation?: GraphQLTypes["LinkListOrderBy"] | undefined
};
	["FooterOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined
};
	["FooterLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["FooterLocalesByNavigationUniqueWhere"]: {
		navigation?: GraphQLTypes["LinkListUniqueWhere"] | undefined
};
	["FooterLocaleConnection"]: {
	__typename: "FooterLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FooterLocaleEdge"]>
};
	["FooterLocaleEdge"]: {
	__typename: "FooterLocaleEdge",
	node: GraphQLTypes["FooterLocale"]
};
	["BlogPostCommonLocale"]: {
	__typename: "BlogPostCommonLocale",
	_meta?: GraphQLTypes["BlogPostCommonLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	root?: GraphQLTypes["BlogPostCommon"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	ctaLink?: GraphQLTypes["Link"] | undefined,
	ctaTitle?: string | undefined
};
	["BlogPostCommonLocaleMeta"]: {
	__typename: "BlogPostCommonLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	ctaLink?: GraphQLTypes["FieldMeta"] | undefined,
	ctaTitle?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostCommon"]: {
	__typename: "BlogPostCommon",
	_meta?: GraphQLTypes["BlogPostCommonMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["BlogPostCommonLocale"]>,
	localesByLocale?: GraphQLTypes["BlogPostCommonLocale"] | undefined,
	paginateLocales: GraphQLTypes["BlogPostCommonLocaleConnection"]
};
	["BlogPostCommonMeta"]: {
	__typename: "BlogPostCommonMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostCommonLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["BlogPostCommonOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	ctaLink?: GraphQLTypes["LinkOrderBy"] | undefined,
	ctaTitle?: GraphQLTypes["OrderDirection"] | undefined
};
	["BlogPostCommonOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined
};
	["BlogPostCommonLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BlogPostCommonLocaleConnection"]: {
	__typename: "BlogPostCommonLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostCommonLocaleEdge"]>
};
	["BlogPostCommonLocaleEdge"]: {
	__typename: "BlogPostCommonLocaleEdge",
	node: GraphQLTypes["BlogPostCommonLocale"]
};
	["LocaleGeneralByRootUniqueWhere"]: {
		root?: GraphQLTypes["GeneralUniqueWhere"] | undefined
};
	["LocaleGeneralBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocaleGeneralByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LocaleGenericPagesByRootUniqueWhere"]: {
		root?: GraphQLTypes["GenericPageUniqueWhere"] | undefined
};
	["LocaleGenericPagesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LocaleGenericPagesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleGenericPagesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocaleHeadersByRootUniqueWhere"]: {
		root?: GraphQLTypes["HeaderUniqueWhere"] | undefined
};
	["LocaleHeadersByLinkListUniqueWhere"]: {
		linkList?: GraphQLTypes["LinkListUniqueWhere"] | undefined
};
	["LocaleHeadersBySocialLinkListUniqueWhere"]: {
		socialLinkList?: GraphQLTypes["LinkListUniqueWhere"] | undefined
};
	["LocaleHeadersByTopLevelLinkItemsUniqueWhere"]: {
		topLevelLinkItems?: GraphQLTypes["TopLevelLinkItemUniqueWhere"] | undefined
};
	["LocaleFootersByRootUniqueWhere"]: {
		root?: GraphQLTypes["FooterUniqueWhere"] | undefined
};
	["LocaleFootersByNavigationUniqueWhere"]: {
		navigation?: GraphQLTypes["LinkListUniqueWhere"] | undefined
};
	["LocaleCaseStudiesByRootUniqueWhere"]: {
		root?: GraphQLTypes["CaseStudyUniqueWhere"] | undefined
};
	["LocaleCaseStudiesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LocaleCaseStudiesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleCaseStudiesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocaleTagsBySlugUniqueWhere"]: {
		slug?: string | undefined
};
	["LocaleTagsByRootUniqueWhere"]: {
		root?: GraphQLTypes["TagUniqueWhere"] | undefined
};
	["LocalePositionTagsBySlugUniqueWhere"]: {
		slug?: string | undefined
};
	["LocalePositionTagsByRootUniqueWhere"]: {
		root?: GraphQLTypes["PositionTagUniqueWhere"] | undefined
};
	["LocalePositionsByRootUniqueWhere"]: {
		root?: GraphQLTypes["PositionUniqueWhere"] | undefined
};
	["LocalePositionsByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LocalePositionsBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocalePositionsByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LocalePositionCategoriesByRootUniqueWhere"]: {
		root?: GraphQLTypes["PositionCategoryUniqueWhere"] | undefined
};
	["LocaleAuthorsByRootUniqueWhere"]: {
		root?: GraphQLTypes["AuthorUniqueWhere"] | undefined
};
	["LocaleBlogPostsByRootUniqueWhere"]: {
		root?: GraphQLTypes["BlogPostUniqueWhere"] | undefined
};
	["LocaleBlogPostsByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LocaleBlogPostsBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocaleBlogPostsByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleBlogPostCommonByRootUniqueWhere"]: {
		root?: GraphQLTypes["BlogPostCommonUniqueWhere"] | undefined
};
	["GeneralLocaleConnection"]: {
	__typename: "GeneralLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GeneralLocaleEdge"]>
};
	["GeneralLocaleEdge"]: {
	__typename: "GeneralLocaleEdge",
	node: GraphQLTypes["GeneralLocale"]
};
	["GeneralLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["GeneralLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["GeneralLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["GeneralConnection"]: {
	__typename: "GeneralConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GeneralEdge"]>
};
	["GeneralEdge"]: {
	__typename: "GeneralEdge",
	node: GraphQLTypes["General"]
};
	["ImageConnection"]: {
	__typename: "ImageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageEdge"]>
};
	["ImageEdge"]: {
	__typename: "ImageEdge",
	node: GraphQLTypes["Image"]
};
	["GenericPageConnection"]: {
	__typename: "GenericPageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GenericPageEdge"]>
};
	["GenericPageEdge"]: {
	__typename: "GenericPageEdge",
	node: GraphQLTypes["GenericPage"]
};
	["LinkableConnection"]: {
	__typename: "LinkableConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkableEdge"]>
};
	["LinkableEdge"]: {
	__typename: "LinkableEdge",
	node: GraphQLTypes["Linkable"]
};
	["SeoConnection"]: {
	__typename: "SeoConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SeoEdge"]>
};
	["SeoEdge"]: {
	__typename: "SeoEdge",
	node: GraphQLTypes["Seo"]
};
	["ContentConnection"]: {
	__typename: "ContentConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentEdge"]>
};
	["ContentEdge"]: {
	__typename: "ContentEdge",
	node: GraphQLTypes["Content"]
};
	["LocaleConnection"]: {
	__typename: "LocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LocaleEdge"]>
};
	["LocaleEdge"]: {
	__typename: "LocaleEdge",
	node: GraphQLTypes["Locale"]
};
	["LinkConnection"]: {
	__typename: "LinkConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkEdge"]>
};
	["LinkEdge"]: {
	__typename: "LinkEdge",
	node: GraphQLTypes["Link"]
};
	["LinkListConnection"]: {
	__typename: "LinkListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkListEdge"]>
};
	["LinkListEdge"]: {
	__typename: "LinkListEdge",
	node: GraphQLTypes["LinkList"]
};
	["HeaderConnection"]: {
	__typename: "HeaderConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HeaderEdge"]>
};
	["HeaderEdge"]: {
	__typename: "HeaderEdge",
	node: GraphQLTypes["Header"]
};
	["FooterConnection"]: {
	__typename: "FooterConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FooterEdge"]>
};
	["FooterEdge"]: {
	__typename: "FooterEdge",
	node: GraphQLTypes["Footer"]
};
	["CaseStudyConnection"]: {
	__typename: "CaseStudyConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CaseStudyEdge"]>
};
	["CaseStudyEdge"]: {
	__typename: "CaseStudyEdge",
	node: GraphQLTypes["CaseStudy"]
};
	["TagConnection"]: {
	__typename: "TagConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TagEdge"]>
};
	["TagEdge"]: {
	__typename: "TagEdge",
	node: GraphQLTypes["Tag"]
};
	["CaseStudyListConnection"]: {
	__typename: "CaseStudyListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CaseStudyListEdge"]>
};
	["CaseStudyListEdge"]: {
	__typename: "CaseStudyListEdge",
	node: GraphQLTypes["CaseStudyList"]
};
	["ImageListConnection"]: {
	__typename: "ImageListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListEdge"]>
};
	["ImageListEdge"]: {
	__typename: "ImageListEdge",
	node: GraphQLTypes["ImageList"]
};
	["InformationListConnection"]: {
	__typename: "InformationListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["InformationListEdge"]>
};
	["InformationListEdge"]: {
	__typename: "InformationListEdge",
	node: GraphQLTypes["InformationList"]
};
	["PositionTagConnection"]: {
	__typename: "PositionTagConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PositionTagEdge"]>
};
	["PositionTagEdge"]: {
	__typename: "PositionTagEdge",
	node: GraphQLTypes["PositionTag"]
};
	["PositionTagListConnection"]: {
	__typename: "PositionTagListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PositionTagListEdge"]>
};
	["PositionTagListEdge"]: {
	__typename: "PositionTagListEdge",
	node: GraphQLTypes["PositionTagList"]
};
	["PositionCategoryConnection"]: {
	__typename: "PositionCategoryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PositionCategoryEdge"]>
};
	["PositionCategoryEdge"]: {
	__typename: "PositionCategoryEdge",
	node: GraphQLTypes["PositionCategory"]
};
	["EmbedConnection"]: {
	__typename: "EmbedConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["EmbedEdge"]>
};
	["EmbedEdge"]: {
	__typename: "EmbedEdge",
	node: GraphQLTypes["Embed"]
};
	["RedirectConnection"]: {
	__typename: "RedirectConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["RedirectEdge"]>
};
	["RedirectEdge"]: {
	__typename: "RedirectEdge",
	node: GraphQLTypes["Redirect"]
};
	["TalkUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	slug?: string | undefined
};
	["TalkConnection"]: {
	__typename: "TalkConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TalkEdge"]>
};
	["TalkEdge"]: {
	__typename: "TalkEdge",
	node: GraphQLTypes["Talk"]
};
	["TalkListsConnection"]: {
	__typename: "TalkListsConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TalkListsEdge"]>
};
	["TalkListsEdge"]: {
	__typename: "TalkListsEdge",
	node: GraphQLTypes["TalkLists"]
};
	["BusinessCardConnection"]: {
	__typename: "BusinessCardConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessCardEdge"]>
};
	["BusinessCardEdge"]: {
	__typename: "BusinessCardEdge",
	node: GraphQLTypes["BusinessCard"]
};
	["BusinessCardListConnection"]: {
	__typename: "BusinessCardListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessCardListEdge"]>
};
	["BusinessCardListEdge"]: {
	__typename: "BusinessCardListEdge",
	node: GraphQLTypes["BusinessCardList"]
};
	["TestimonialConnection"]: {
	__typename: "TestimonialConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TestimonialEdge"]>
};
	["TestimonialEdge"]: {
	__typename: "TestimonialEdge",
	node: GraphQLTypes["Testimonial"]
};
	["TestimonialListConnection"]: {
	__typename: "TestimonialListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TestimonialListEdge"]>
};
	["TestimonialListEdge"]: {
	__typename: "TestimonialListEdge",
	node: GraphQLTypes["TestimonialList"]
};
	["ContactRequestOptionListConnection"]: {
	__typename: "ContactRequestOptionListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContactRequestOptionListEdge"]>
};
	["ContactRequestOptionListEdge"]: {
	__typename: "ContactRequestOptionListEdge",
	node: GraphQLTypes["ContactRequestOptionList"]
};
	["YoutubeVideoConnection"]: {
	__typename: "YoutubeVideoConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["YoutubeVideoEdge"]>
};
	["YoutubeVideoEdge"]: {
	__typename: "YoutubeVideoEdge",
	node: GraphQLTypes["YoutubeVideo"]
};
	["YoutubeVideoList"]: {
	__typename: "YoutubeVideoList",
	_meta?: GraphQLTypes["YoutubeVideoListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["YoutubeVideoListItem"]>,
	itemsByYoutube?: GraphQLTypes["YoutubeVideoListItem"] | undefined,
	paginateItems: GraphQLTypes["YoutubeVideoListItemConnection"]
};
	["YoutubeVideoListMeta"]: {
	__typename: "YoutubeVideoListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["YoutubeVideoListItem"]: {
	__typename: "YoutubeVideoListItem",
	_meta?: GraphQLTypes["YoutubeVideoListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["YoutubeVideoList"] | undefined,
	youtube?: GraphQLTypes["YoutubeVideo"] | undefined
};
	["YoutubeVideoListItemMeta"]: {
	__typename: "YoutubeVideoListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	youtube?: GraphQLTypes["FieldMeta"] | undefined
};
	["YoutubeVideoListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["YoutubeVideoListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["YoutubeVideoListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["YoutubeVideoListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["YoutubeVideoListWhere"] | undefined
};
	["YoutubeVideoListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["YoutubeVideoListWhere"] | undefined,
	youtube?: GraphQLTypes["YoutubeVideoWhere"] | undefined,
	and?: Array<GraphQLTypes["YoutubeVideoListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["YoutubeVideoListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["YoutubeVideoListItemWhere"] | undefined
};
	["YoutubeVideoListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["YoutubeVideoListOrderBy"] | undefined,
	youtube?: GraphQLTypes["YoutubeVideoOrderBy"] | undefined
};
	["YoutubeVideoListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["YoutubeVideoListItemsByYoutubeUniqueWhere"]: {
		youtube?: GraphQLTypes["YoutubeVideoUniqueWhere"] | undefined
};
	["YoutubeVideoListItemConnection"]: {
	__typename: "YoutubeVideoListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["YoutubeVideoListItemEdge"]>
};
	["YoutubeVideoListItemEdge"]: {
	__typename: "YoutubeVideoListItemEdge",
	node: GraphQLTypes["YoutubeVideoListItem"]
};
	["YoutubeVideoListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["YoutubeVideoListItemUniqueWhere"] | undefined
};
	["YoutubeVideoListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	youtube?: GraphQLTypes["YoutubeVideoUniqueWhere"] | undefined
};
	["YoutubeVideoListConnection"]: {
	__typename: "YoutubeVideoListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["YoutubeVideoListEdge"]>
};
	["YoutubeVideoListEdge"]: {
	__typename: "YoutubeVideoListEdge",
	node: GraphQLTypes["YoutubeVideoList"]
};
	["TranslationCatalogue"]: {
	__typename: "TranslationCatalogue",
	_meta?: GraphQLTypes["TranslationCatalogueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	domain?: GraphQLTypes["TranslationDomain"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogue"] | undefined,
	values: Array<GraphQLTypes["TranslationValue"]>,
	valuesByKey?: GraphQLTypes["TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["TranslationValueConnection"]
};
	["TranslationCatalogueMeta"]: {
	__typename: "TranslationCatalogueMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	domain?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	fallback?: GraphQLTypes["FieldMeta"] | undefined,
	values?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationDomain"]: {
	__typename: "TranslationDomain",
	_meta?: GraphQLTypes["TranslationDomainMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	name: string,
	catalogues: Array<GraphQLTypes["TranslationCatalogue"]>,
	keys: Array<GraphQLTypes["TranslationKey"]>,
	cataloguesByIdentifier?: GraphQLTypes["TranslationCatalogue"] | undefined,
	cataloguesByValues?: GraphQLTypes["TranslationCatalogue"] | undefined,
	keysByIdentifier?: GraphQLTypes["TranslationKey"] | undefined,
	keysByValues?: GraphQLTypes["TranslationKey"] | undefined,
	paginateCatalogues: GraphQLTypes["TranslationCatalogueConnection"],
	paginateKeys: GraphQLTypes["TranslationKeyConnection"]
};
	["TranslationDomainMeta"]: {
	__typename: "TranslationDomainMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	catalogues?: GraphQLTypes["FieldMeta"] | undefined,
	keys?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationCatalogueWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	domain?: GraphQLTypes["TranslationDomainWhere"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	values?: GraphQLTypes["TranslationValueWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationCatalogueWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationCatalogueWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationCatalogueWhere"] | undefined
};
	["TranslationDomainWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	identifier?: GraphQLTypes["StringCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	catalogues?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	keys?: GraphQLTypes["TranslationKeyWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationDomainWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationDomainWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationDomainWhere"] | undefined
};
	["TranslationKeyWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	identifier?: GraphQLTypes["StringCondition"] | undefined,
	contentType?: GraphQLTypes["TranslationContentTypeCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	domain?: GraphQLTypes["TranslationDomainWhere"] | undefined,
	values?: GraphQLTypes["TranslationValueWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationKeyWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationKeyWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationKeyWhere"] | undefined
};
	["TranslationContentTypeCondition"]: {
		and?: Array<GraphQLTypes["TranslationContentTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["TranslationContentTypeCondition"]> | undefined,
	not?: GraphQLTypes["TranslationContentTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["TranslationContentType"] | undefined,
	notEq?: GraphQLTypes["TranslationContentType"] | undefined,
	in?: Array<GraphQLTypes["TranslationContentType"]> | undefined,
	notIn?: Array<GraphQLTypes["TranslationContentType"]> | undefined,
	lt?: GraphQLTypes["TranslationContentType"] | undefined,
	lte?: GraphQLTypes["TranslationContentType"] | undefined,
	gt?: GraphQLTypes["TranslationContentType"] | undefined,
	gte?: GraphQLTypes["TranslationContentType"] | undefined
};
	["TranslationContentType"]: TranslationContentType;
	["TranslationValueWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	value?: GraphQLTypes["StringCondition"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	key?: GraphQLTypes["TranslationKeyWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationValueWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationValueWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationValueWhere"] | undefined
};
	["TranslationCataloguesIdentifierWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined
};
	["TranslationCatalogueOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	domain?: GraphQLTypes["TranslationDomainOrderBy"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierOrderBy"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogueOrderBy"] | undefined
};
	["TranslationDomainOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	identifier?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined
};
	["TranslationCataloguesIdentifierOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined
};
	["TranslationKey"]: {
	__typename: "TranslationKey",
	_meta?: GraphQLTypes["TranslationKeyMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	contentType: GraphQLTypes["TranslationContentType"],
	note?: string | undefined,
	domain?: GraphQLTypes["TranslationDomain"] | undefined,
	values: Array<GraphQLTypes["TranslationValue"]>,
	valuesByCatalogue?: GraphQLTypes["TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["TranslationValueConnection"]
};
	["TranslationKeyMeta"]: {
	__typename: "TranslationKeyMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	contentType?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	domain?: GraphQLTypes["FieldMeta"] | undefined,
	values?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationValue"]: {
	__typename: "TranslationValue",
	_meta?: GraphQLTypes["TranslationValueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	value: string,
	catalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	key?: GraphQLTypes["TranslationKey"] | undefined
};
	["TranslationValueMeta"]: {
	__typename: "TranslationValueMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	value?: GraphQLTypes["FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["FieldMeta"] | undefined,
	key?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationValueOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	value?: GraphQLTypes["OrderDirection"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueOrderBy"] | undefined,
	key?: GraphQLTypes["TranslationKeyOrderBy"] | undefined
};
	["TranslationKeyOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	identifier?: GraphQLTypes["OrderDirection"] | undefined,
	contentType?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	domain?: GraphQLTypes["TranslationDomainOrderBy"] | undefined
};
	["TranslationKeyValuesByCatalogueUniqueWhere"]: {
		catalogue?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined
};
	["TranslationCatalogueUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	domain?: GraphQLTypes["TranslationDomainUniqueWhere"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined,
	values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationDomainUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	identifier?: string | undefined,
	catalogues?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined,
	keys?: GraphQLTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationKeyUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	domain?: GraphQLTypes["TranslationDomainUniqueWhere"] | undefined,
	identifier?: string | undefined,
	values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationValueUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined,
	key?: GraphQLTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined
};
	["TranslationValueConnection"]: {
	__typename: "TranslationValueConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationValueEdge"]>
};
	["TranslationValueEdge"]: {
	__typename: "TranslationValueEdge",
	node: GraphQLTypes["TranslationValue"]
};
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
		identifier?: GraphQLTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined
};
	["TranslationDomainCataloguesByValuesUniqueWhere"]: {
		values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationDomainKeysByIdentifierUniqueWhere"]: {
		identifier?: string | undefined
};
	["TranslationDomainKeysByValuesUniqueWhere"]: {
		values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationCatalogueConnection"]: {
	__typename: "TranslationCatalogueConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationCatalogueEdge"]>
};
	["TranslationCatalogueEdge"]: {
	__typename: "TranslationCatalogueEdge",
	node: GraphQLTypes["TranslationCatalogue"]
};
	["TranslationKeyConnection"]: {
	__typename: "TranslationKeyConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationKeyEdge"]>
};
	["TranslationKeyEdge"]: {
	__typename: "TranslationKeyEdge",
	node: GraphQLTypes["TranslationKey"]
};
	["TranslationCataloguesIdentifier"]: {
	__typename: "TranslationCataloguesIdentifier",
	_meta?: GraphQLTypes["TranslationCataloguesIdentifierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	catalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	catalogueByDomain?: GraphQLTypes["TranslationCatalogue"] | undefined,
	catalogueByValues?: GraphQLTypes["TranslationCatalogue"] | undefined,
	paginateCatalogue: GraphQLTypes["TranslationCatalogueConnection"]
};
	["TranslationCataloguesIdentifierMeta"]: {
	__typename: "TranslationCataloguesIdentifierMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: {
		domain?: GraphQLTypes["TranslationDomainUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: {
		values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationCatalogueValuesByKeyUniqueWhere"]: {
		key?: GraphQLTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierConnection"]: {
	__typename: "TranslationCataloguesIdentifierConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationCataloguesIdentifierEdge"]>
};
	["TranslationCataloguesIdentifierEdge"]: {
	__typename: "TranslationCataloguesIdentifierEdge",
	node: GraphQLTypes["TranslationCataloguesIdentifier"]
};
	["TranslationDomainConnection"]: {
	__typename: "TranslationDomainConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationDomainEdge"]>
};
	["TranslationDomainEdge"]: {
	__typename: "TranslationDomainEdge",
	node: GraphQLTypes["TranslationDomain"]
};
	["_ValidationResult"]: {
	__typename: "_ValidationResult",
	valid: boolean,
	errors: Array<GraphQLTypes["_ValidationError"]>
};
	["_ValidationError"]: {
	__typename: "_ValidationError",
	path: Array<GraphQLTypes["_PathFragment"]>,
	message: GraphQLTypes["_ValidationMessage"]
};
	["_PathFragment"]:{
        	__typename:"_FieldPathFragment" | "_IndexPathFragment"
        	['...on _FieldPathFragment']: '__union' & GraphQLTypes["_FieldPathFragment"];
	['...on _IndexPathFragment']: '__union' & GraphQLTypes["_IndexPathFragment"];
};
	["_FieldPathFragment"]: {
	__typename: "_FieldPathFragment",
	field: string
};
	["_IndexPathFragment"]: {
	__typename: "_IndexPathFragment",
	index: number,
	alias?: string | undefined
};
	["_ValidationMessage"]: {
	__typename: "_ValidationMessage",
	text: string
};
	["ContactRequestSubmissionCreateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	requestOption?: string | undefined,
	givenName?: string | undefined,
	familyName?: string | undefined,
	text?: string | undefined,
	locale?: GraphQLTypes["ContactRequestSubmissionCreateLocaleEntityRelationInput"] | undefined,
	email?: string | undefined,
	marketingConsent?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["ContactRequestSubmissionCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["SubscribeFormUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	datacruitJobId?: number | undefined
};
	["SubscribeFormConnection"]: {
	__typename: "SubscribeFormConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SubscribeFormEdge"]>
};
	["SubscribeFormEdge"]: {
	__typename: "SubscribeFormEdge",
	node: GraphQLTypes["SubscribeForm"]
};
	["SubscribeFormCreateInput"]: {
		submissions?: Array<GraphQLTypes["SubscribeFormCreateSubmissionsEntityRelationInput"]> | undefined,
	name?: string | undefined,
	datacruitJobId?: number | undefined,
	datacruitReferenceNumber?: string | undefined,
	lastUpdateAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["SubscribeFormCreateSubmissionsEntityRelationInput"]: {
		create?: GraphQLTypes["SubscribeSubmissionWithoutFormCreateInput"] | undefined,
	alias?: string | undefined
};
	["SubscribeSubmissionWithoutFormCreateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	locale?: GraphQLTypes["SubscribeSubmissionCreateLocaleEntityRelationInput"] | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	datacruitId?: string | undefined,
	consent?: boolean | undefined,
	url?: string | undefined,
	attachmentFileName?: string | undefined,
	message?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["SubscribeSubmissionCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["SubscribeFormUpdateInput"]: {
		name?: string | undefined,
	datacruitJobId?: number | undefined,
	datacruitReferenceNumber?: string | undefined,
	lastUpdateAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["SubscribeSubmissionCreateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	form?: GraphQLTypes["SubscribeSubmissionCreateFormEntityRelationInput"] | undefined,
	locale?: GraphQLTypes["SubscribeSubmissionCreateLocaleEntityRelationInput"] | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	datacruitId?: string | undefined,
	consent?: boolean | undefined,
	url?: string | undefined,
	attachmentFileName?: string | undefined,
	message?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["SubscribeSubmissionCreateFormEntityRelationInput"]: {
		connect?: GraphQLTypes["SubscribeFormUniqueWhere"] | undefined,
	create?: GraphQLTypes["SubscribeFormWithoutSubmissionsCreateInput"] | undefined,
	connectOrCreate?: GraphQLTypes["SubscribeSubmissionConnectOrCreateFormRelationInput"] | undefined
};
	["SubscribeFormWithoutSubmissionsCreateInput"]: {
		name?: string | undefined,
	datacruitJobId?: number | undefined,
	datacruitReferenceNumber?: string | undefined,
	lastUpdateAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["SubscribeSubmissionConnectOrCreateFormRelationInput"]: {
		connect: GraphQLTypes["SubscribeFormUniqueWhere"],
	create: GraphQLTypes["SubscribeFormWithoutSubmissionsCreateInput"]
};
	["VideoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["VideoConnection"]: {
	__typename: "VideoConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VideoEdge"]>
};
	["VideoEdge"]: {
	__typename: "VideoEdge",
	node: GraphQLTypes["Video"]
};
	["PositionListConnection"]: {
	__typename: "PositionListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PositionListEdge"]>
};
	["PositionListEdge"]: {
	__typename: "PositionListEdge",
	node: GraphQLTypes["PositionList"]
};
	["TestimonialFullConnection"]: {
	__typename: "TestimonialFullConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TestimonialFullEdge"]>
};
	["TestimonialFullEdge"]: {
	__typename: "TestimonialFullEdge",
	node: GraphQLTypes["TestimonialFull"]
};
	["TestimonialFullListConnection"]: {
	__typename: "TestimonialFullListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TestimonialFullListEdge"]>
};
	["TestimonialFullListEdge"]: {
	__typename: "TestimonialFullListEdge",
	node: GraphQLTypes["TestimonialFullList"]
};
	["AuthorConnection"]: {
	__typename: "AuthorConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AuthorEdge"]>
};
	["AuthorEdge"]: {
	__typename: "AuthorEdge",
	node: GraphQLTypes["Author"]
};
	["BlogPostCommonConnection"]: {
	__typename: "BlogPostCommonConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostCommonEdge"]>
};
	["BlogPostCommonEdge"]: {
	__typename: "BlogPostCommonEdge",
	node: GraphQLTypes["BlogPostCommon"]
};
	["QueryTransaction"]: {
	__typename: "QueryTransaction",
	schema?: GraphQLTypes["_Schema"] | undefined,
	s3DummyQuery?: string | undefined,
	getGeneral?: GraphQLTypes["General"] | undefined,
	listGeneral: Array<GraphQLTypes["General"]>,
	paginateGeneral: GraphQLTypes["GeneralConnection"],
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getGenericPage?: GraphQLTypes["GenericPage"] | undefined,
	listGenericPage: Array<GraphQLTypes["GenericPage"]>,
	paginateGenericPage: GraphQLTypes["GenericPageConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getGeneralLocale?: GraphQLTypes["GeneralLocale"] | undefined,
	listGeneralLocale: Array<GraphQLTypes["GeneralLocale"]>,
	paginateGeneralLocale: GraphQLTypes["GeneralLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getGenericPageLocale?: GraphQLTypes["GenericPageLocale"] | undefined,
	listGenericPageLocale: Array<GraphQLTypes["GenericPageLocale"]>,
	paginateGenericPageLocale: GraphQLTypes["GenericPageLocaleConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getLinkList?: GraphQLTypes["LinkList"] | undefined,
	listLinkList: Array<GraphQLTypes["LinkList"]>,
	paginateLinkList: GraphQLTypes["LinkListConnection"],
	getLinkListItem?: GraphQLTypes["LinkListItem"] | undefined,
	listLinkListItem: Array<GraphQLTypes["LinkListItem"]>,
	paginateLinkListItem: GraphQLTypes["LinkListItemConnection"],
	getHeader?: GraphQLTypes["Header"] | undefined,
	listHeader: Array<GraphQLTypes["Header"]>,
	paginateHeader: GraphQLTypes["HeaderConnection"],
	getHeaderLocale?: GraphQLTypes["HeaderLocale"] | undefined,
	listHeaderLocale: Array<GraphQLTypes["HeaderLocale"]>,
	paginateHeaderLocale: GraphQLTypes["HeaderLocaleConnection"],
	getFooter?: GraphQLTypes["Footer"] | undefined,
	listFooter: Array<GraphQLTypes["Footer"]>,
	paginateFooter: GraphQLTypes["FooterConnection"],
	getFooterLocale?: GraphQLTypes["FooterLocale"] | undefined,
	listFooterLocale: Array<GraphQLTypes["FooterLocale"]>,
	paginateFooterLocale: GraphQLTypes["FooterLocaleConnection"],
	getCaseStudy?: GraphQLTypes["CaseStudy"] | undefined,
	listCaseStudy: Array<GraphQLTypes["CaseStudy"]>,
	paginateCaseStudy: GraphQLTypes["CaseStudyConnection"],
	getCaseStudyLocale?: GraphQLTypes["CaseStudyLocale"] | undefined,
	listCaseStudyLocale: Array<GraphQLTypes["CaseStudyLocale"]>,
	paginateCaseStudyLocale: GraphQLTypes["CaseStudyLocaleConnection"],
	getTag?: GraphQLTypes["Tag"] | undefined,
	listTag: Array<GraphQLTypes["Tag"]>,
	paginateTag: GraphQLTypes["TagConnection"],
	getTagLocale?: GraphQLTypes["TagLocale"] | undefined,
	listTagLocale: Array<GraphQLTypes["TagLocale"]>,
	paginateTagLocale: GraphQLTypes["TagLocaleConnection"],
	getCaseStudyTag?: GraphQLTypes["CaseStudyTag"] | undefined,
	listCaseStudyTag: Array<GraphQLTypes["CaseStudyTag"]>,
	paginateCaseStudyTag: GraphQLTypes["CaseStudyTagConnection"],
	getCaseStudyList?: GraphQLTypes["CaseStudyList"] | undefined,
	listCaseStudyList: Array<GraphQLTypes["CaseStudyList"]>,
	paginateCaseStudyList: GraphQLTypes["CaseStudyListConnection"],
	getCaseStudyListItem?: GraphQLTypes["CaseStudyListItem"] | undefined,
	listCaseStudyListItem: Array<GraphQLTypes["CaseStudyListItem"]>,
	paginateCaseStudyListItem: GraphQLTypes["CaseStudyListItemConnection"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getInformationList?: GraphQLTypes["InformationList"] | undefined,
	listInformationList: Array<GraphQLTypes["InformationList"]>,
	paginateInformationList: GraphQLTypes["InformationListConnection"],
	getPieceOfInformation?: GraphQLTypes["PieceOfInformation"] | undefined,
	listPieceOfInformation: Array<GraphQLTypes["PieceOfInformation"]>,
	paginatePieceOfInformation: GraphQLTypes["PieceOfInformationConnection"],
	getPositionTag?: GraphQLTypes["PositionTag"] | undefined,
	listPositionTag: Array<GraphQLTypes["PositionTag"]>,
	paginatePositionTag: GraphQLTypes["PositionTagConnection"],
	getPositionTagLocale?: GraphQLTypes["PositionTagLocale"] | undefined,
	listPositionTagLocale: Array<GraphQLTypes["PositionTagLocale"]>,
	paginatePositionTagLocale: GraphQLTypes["PositionTagLocaleConnection"],
	getPosition?: GraphQLTypes["Position"] | undefined,
	listPosition: Array<GraphQLTypes["Position"]>,
	paginatePosition: GraphQLTypes["PositionConnection"],
	getPositionLocale?: GraphQLTypes["PositionLocale"] | undefined,
	listPositionLocale: Array<GraphQLTypes["PositionLocale"]>,
	paginatePositionLocale: GraphQLTypes["PositionLocaleConnection"],
	getPositionTagList?: GraphQLTypes["PositionTagList"] | undefined,
	listPositionTagList: Array<GraphQLTypes["PositionTagList"]>,
	paginatePositionTagList: GraphQLTypes["PositionTagListConnection"],
	getPositionTagListItem?: GraphQLTypes["PositionTagListItem"] | undefined,
	listPositionTagListItem: Array<GraphQLTypes["PositionTagListItem"]>,
	paginatePositionTagListItem: GraphQLTypes["PositionTagListItemConnection"],
	getPositionCategory?: GraphQLTypes["PositionCategory"] | undefined,
	listPositionCategory: Array<GraphQLTypes["PositionCategory"]>,
	paginatePositionCategory: GraphQLTypes["PositionCategoryConnection"],
	getPositionCategoryLocale?: GraphQLTypes["PositionCategoryLocale"] | undefined,
	listPositionCategoryLocale: Array<GraphQLTypes["PositionCategoryLocale"]>,
	paginatePositionCategoryLocale: GraphQLTypes["PositionCategoryLocaleConnection"],
	getEmbed?: GraphQLTypes["Embed"] | undefined,
	listEmbed: Array<GraphQLTypes["Embed"]>,
	paginateEmbed: GraphQLTypes["EmbedConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getTalk?: GraphQLTypes["Talk"] | undefined,
	listTalk: Array<GraphQLTypes["Talk"]>,
	paginateTalk: GraphQLTypes["TalkConnection"],
	getTalkList?: GraphQLTypes["TalkList"] | undefined,
	listTalkList: Array<GraphQLTypes["TalkList"]>,
	paginateTalkList: GraphQLTypes["TalkListConnection"],
	getTalkListItem?: GraphQLTypes["TalkListItem"] | undefined,
	listTalkListItem: Array<GraphQLTypes["TalkListItem"]>,
	paginateTalkListItem: GraphQLTypes["TalkListItemConnection"],
	getTalkLists?: GraphQLTypes["TalkLists"] | undefined,
	listTalkLists: Array<GraphQLTypes["TalkLists"]>,
	paginateTalkLists: GraphQLTypes["TalkListsConnection"],
	getBusinessCard?: GraphQLTypes["BusinessCard"] | undefined,
	listBusinessCard: Array<GraphQLTypes["BusinessCard"]>,
	paginateBusinessCard: GraphQLTypes["BusinessCardConnection"],
	getBusinessCardList?: GraphQLTypes["BusinessCardList"] | undefined,
	listBusinessCardList: Array<GraphQLTypes["BusinessCardList"]>,
	paginateBusinessCardList: GraphQLTypes["BusinessCardListConnection"],
	getBusinessCardListItem?: GraphQLTypes["BusinessCardListItem"] | undefined,
	listBusinessCardListItem: Array<GraphQLTypes["BusinessCardListItem"]>,
	paginateBusinessCardListItem: GraphQLTypes["BusinessCardListItemConnection"],
	getTestimonial?: GraphQLTypes["Testimonial"] | undefined,
	listTestimonial: Array<GraphQLTypes["Testimonial"]>,
	paginateTestimonial: GraphQLTypes["TestimonialConnection"],
	getTestimonialList?: GraphQLTypes["TestimonialList"] | undefined,
	listTestimonialList: Array<GraphQLTypes["TestimonialList"]>,
	paginateTestimonialList: GraphQLTypes["TestimonialListConnection"],
	getTestimonialListItem?: GraphQLTypes["TestimonialListItem"] | undefined,
	listTestimonialListItem: Array<GraphQLTypes["TestimonialListItem"]>,
	paginateTestimonialListItem: GraphQLTypes["TestimonialListItemConnection"],
	getContactRequestOption?: GraphQLTypes["ContactRequestOption"] | undefined,
	listContactRequestOption: Array<GraphQLTypes["ContactRequestOption"]>,
	paginateContactRequestOption: GraphQLTypes["ContactRequestOptionConnection"],
	getContactRequestOptionList?: GraphQLTypes["ContactRequestOptionList"] | undefined,
	listContactRequestOptionList: Array<GraphQLTypes["ContactRequestOptionList"]>,
	paginateContactRequestOptionList: GraphQLTypes["ContactRequestOptionListConnection"],
	getYoutubeVideo?: GraphQLTypes["YoutubeVideo"] | undefined,
	listYoutubeVideo: Array<GraphQLTypes["YoutubeVideo"]>,
	paginateYoutubeVideo: GraphQLTypes["YoutubeVideoConnection"],
	getYoutubeVideoList?: GraphQLTypes["YoutubeVideoList"] | undefined,
	listYoutubeVideoList: Array<GraphQLTypes["YoutubeVideoList"]>,
	paginateYoutubeVideoList: GraphQLTypes["YoutubeVideoListConnection"],
	getYoutubeVideoListItem?: GraphQLTypes["YoutubeVideoListItem"] | undefined,
	listYoutubeVideoListItem: Array<GraphQLTypes["YoutubeVideoListItem"]>,
	paginateYoutubeVideoListItem: GraphQLTypes["YoutubeVideoListItemConnection"],
	getTranslationCatalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: GraphQLTypes["TranslationCatalogueConnection"],
	getTranslationCataloguesIdentifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<GraphQLTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: GraphQLTypes["TranslationCataloguesIdentifierConnection"],
	getTranslationDomain?: GraphQLTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<GraphQLTypes["TranslationDomain"]>,
	paginateTranslationDomain: GraphQLTypes["TranslationDomainConnection"],
	getTranslationKey?: GraphQLTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<GraphQLTypes["TranslationKey"]>,
	paginateTranslationKey: GraphQLTypes["TranslationKeyConnection"],
	getTranslationValue?: GraphQLTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<GraphQLTypes["TranslationValue"]>,
	paginateTranslationValue: GraphQLTypes["TranslationValueConnection"],
	validateCreateContactRequestSubmission: GraphQLTypes["_ValidationResult"],
	getSubscribeForm?: GraphQLTypes["SubscribeForm"] | undefined,
	listSubscribeForm: Array<GraphQLTypes["SubscribeForm"]>,
	paginateSubscribeForm: GraphQLTypes["SubscribeFormConnection"],
	validateCreateSubscribeForm: GraphQLTypes["_ValidationResult"],
	validateUpdateSubscribeForm: GraphQLTypes["_ValidationResult"],
	validateCreateSubscribeSubmission: GraphQLTypes["_ValidationResult"],
	getContentReferencePositionCategoryItem?: GraphQLTypes["ContentReferencePositionCategoryItem"] | undefined,
	listContentReferencePositionCategoryItem: Array<GraphQLTypes["ContentReferencePositionCategoryItem"]>,
	paginateContentReferencePositionCategoryItem: GraphQLTypes["ContentReferencePositionCategoryItemConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getContentReferenceCaseStudyItem?: GraphQLTypes["ContentReferenceCaseStudyItem"] | undefined,
	listContentReferenceCaseStudyItem: Array<GraphQLTypes["ContentReferenceCaseStudyItem"]>,
	paginateContentReferenceCaseStudyItem: GraphQLTypes["ContentReferenceCaseStudyItemConnection"],
	getContentReferenceTagItem?: GraphQLTypes["ContentReferenceTagItem"] | undefined,
	listContentReferenceTagItem: Array<GraphQLTypes["ContentReferenceTagItem"]>,
	paginateContentReferenceTagItem: GraphQLTypes["ContentReferenceTagItemConnection"],
	getPositionList?: GraphQLTypes["PositionList"] | undefined,
	listPositionList: Array<GraphQLTypes["PositionList"]>,
	paginatePositionList: GraphQLTypes["PositionListConnection"],
	getPositionListItem?: GraphQLTypes["PositionListItem"] | undefined,
	listPositionListItem: Array<GraphQLTypes["PositionListItem"]>,
	paginatePositionListItem: GraphQLTypes["PositionListItemConnection"],
	getTestimonialFull?: GraphQLTypes["TestimonialFull"] | undefined,
	listTestimonialFull: Array<GraphQLTypes["TestimonialFull"]>,
	paginateTestimonialFull: GraphQLTypes["TestimonialFullConnection"],
	getTestimonialFullList?: GraphQLTypes["TestimonialFullList"] | undefined,
	listTestimonialFullList: Array<GraphQLTypes["TestimonialFullList"]>,
	paginateTestimonialFullList: GraphQLTypes["TestimonialFullListConnection"],
	getTestimonialFullListItem?: GraphQLTypes["TestimonialFullListItem"] | undefined,
	listTestimonialFullListItem: Array<GraphQLTypes["TestimonialFullListItem"]>,
	paginateTestimonialFullListItem: GraphQLTypes["TestimonialFullListItemConnection"],
	getSecondLevelLinkItem?: GraphQLTypes["SecondLevelLinkItem"] | undefined,
	listSecondLevelLinkItem: Array<GraphQLTypes["SecondLevelLinkItem"]>,
	paginateSecondLevelLinkItem: GraphQLTypes["SecondLevelLinkItemConnection"],
	getThirdLevelLinkItem?: GraphQLTypes["ThirdLevelLinkItem"] | undefined,
	listThirdLevelLinkItem: Array<GraphQLTypes["ThirdLevelLinkItem"]>,
	paginateThirdLevelLinkItem: GraphQLTypes["ThirdLevelLinkItemConnection"],
	getTopLevelLinkItem?: GraphQLTypes["TopLevelLinkItem"] | undefined,
	listTopLevelLinkItem: Array<GraphQLTypes["TopLevelLinkItem"]>,
	paginateTopLevelLinkItem: GraphQLTypes["TopLevelLinkItemConnection"],
	getAuthor?: GraphQLTypes["Author"] | undefined,
	listAuthor: Array<GraphQLTypes["Author"]>,
	paginateAuthor: GraphQLTypes["AuthorConnection"],
	getAuthorLocale?: GraphQLTypes["AuthorLocale"] | undefined,
	listAuthorLocale: Array<GraphQLTypes["AuthorLocale"]>,
	paginateAuthorLocale: GraphQLTypes["AuthorLocaleConnection"],
	getBlogPost?: GraphQLTypes["BlogPost"] | undefined,
	listBlogPost: Array<GraphQLTypes["BlogPost"]>,
	paginateBlogPost: GraphQLTypes["BlogPostConnection"],
	getBlogPostLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	listBlogPostLocale: Array<GraphQLTypes["BlogPostLocale"]>,
	paginateBlogPostLocale: GraphQLTypes["BlogPostLocaleConnection"],
	getContentReferenceBlogPostItem?: GraphQLTypes["ContentReferenceBlogPostItem"] | undefined,
	listContentReferenceBlogPostItem: Array<GraphQLTypes["ContentReferenceBlogPostItem"]>,
	paginateContentReferenceBlogPostItem: GraphQLTypes["ContentReferenceBlogPostItemConnection"],
	getRelevantBlogPostList?: GraphQLTypes["RelevantBlogPostList"] | undefined,
	listRelevantBlogPostList: Array<GraphQLTypes["RelevantBlogPostList"]>,
	paginateRelevantBlogPostList: GraphQLTypes["RelevantBlogPostListConnection"],
	getBlogPostCommon?: GraphQLTypes["BlogPostCommon"] | undefined,
	listBlogPostCommon: Array<GraphQLTypes["BlogPostCommon"]>,
	paginateBlogPostCommon: GraphQLTypes["BlogPostCommonConnection"],
	getBlogPostCommonLocale?: GraphQLTypes["BlogPostCommonLocale"] | undefined,
	listBlogPostCommonLocale: Array<GraphQLTypes["BlogPostCommonLocale"]>,
	paginateBlogPostCommonLocale: GraphQLTypes["BlogPostCommonLocaleConnection"]
};
	["Info"]: {
	__typename: "Info",
	description?: string | undefined
};
	["Mutation"]: {
	__typename: "Mutation",
	generateUploadUrl: GraphQLTypes["S3SignedUpload"],
	generateReadUrl: GraphQLTypes["S3SignedRead"],
	createContactRequestSubmission: GraphQLTypes["ContactRequestSubmissionCreateResult"],
	createSubscribeForm: GraphQLTypes["SubscribeFormCreateResult"],
	updateSubscribeForm: GraphQLTypes["SubscribeFormUpdateResult"],
	upsertSubscribeForm: GraphQLTypes["SubscribeFormUpsertResult"],
	createSubscribeSubmission: GraphQLTypes["SubscribeSubmissionCreateResult"],
	transaction: GraphQLTypes["MutationTransaction"],
	query: GraphQLTypes["Query"]
};
	["S3SignedUpload"]: {
	__typename: "S3SignedUpload",
	url: string,
	headers: Array<GraphQLTypes["S3Header"]>,
	method: string,
	objectKey: string,
	bucket: string,
	publicUrl: string
};
	["S3Header"]: {
	__typename: "S3Header",
	key: string,
	value: string
};
	["S3GenerateSignedUploadInput"]: {
		contentType?: string | undefined,
	/** If not provided, extension is detected from a content-type. */
	extension?: string | undefined,
	/** Signed URL expiration. */
	expiration?: number | undefined,
	/** Uploaded file size. Required when you enable ACL size limits. */
	size?: number | undefined,
	/** Can be used as a "directory". */
	prefix?: string | undefined,
	/** Suffix after generated id and before the extension. */
	suffix?: string | undefined,
	/** This only affects Content-disposition header. Does not affect actual object key. */
	fileName?: string | undefined,
	contentDisposition?: GraphQLTypes["S33ContentDisposition"] | undefined,
	/** If not supported by S3 provider, an error is thrown. */
	acl?: GraphQLTypes["S3Acl"] | undefined
};
	["S33ContentDisposition"]: S33ContentDisposition;
	["S3Acl"]: S3Acl;
	["S3SignedRead"]: {
	__typename: "S3SignedRead",
	url: string,
	headers: Array<GraphQLTypes["S3Header"]>,
	method: string,
	objectKey: string,
	bucket: string
};
	["ContactRequestSubmissionCreateResult"]: {
	__typename: "ContactRequestSubmissionCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	validation: GraphQLTypes["_ValidationResult"]
};
	["MutationResult"]: {
	__typename:"ContactRequestSubmissionCreateResult" | "SubscribeFormCreateResult" | "SubscribeFormUpdateResult" | "SubscribeFormUpsertResult" | "SubscribeSubmissionCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>
	['...on ContactRequestSubmissionCreateResult']: '__union' & GraphQLTypes["ContactRequestSubmissionCreateResult"];
	['...on SubscribeFormCreateResult']: '__union' & GraphQLTypes["SubscribeFormCreateResult"];
	['...on SubscribeFormUpdateResult']: '__union' & GraphQLTypes["SubscribeFormUpdateResult"];
	['...on SubscribeFormUpsertResult']: '__union' & GraphQLTypes["SubscribeFormUpsertResult"];
	['...on SubscribeSubmissionCreateResult']: '__union' & GraphQLTypes["SubscribeSubmissionCreateResult"];
};
	["_MutationError"]: {
	__typename: "_MutationError",
	path: Array<GraphQLTypes["_PathFragment"]>,
	paths: Array<Array<GraphQLTypes["_PathFragment"]>>,
	type: GraphQLTypes["_MutationErrorType"],
	message?: string | undefined
};
	["_MutationErrorType"]: _MutationErrorType;
	["SubscribeFormCreateResult"]: {
	__typename: "SubscribeFormCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["SubscribeForm"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["SubscribeFormUpdateResult"]: {
	__typename: "SubscribeFormUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["SubscribeForm"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["SubscribeFormUpsertResult"]: {
	__typename: "SubscribeFormUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["SubscribeForm"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["SubscribeSubmissionCreateResult"]: {
	__typename: "SubscribeSubmissionCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	validation: GraphQLTypes["_ValidationResult"]
};
	["MutationTransaction"]: {
	__typename: "MutationTransaction",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	validation: GraphQLTypes["_ValidationResult"],
	generateUploadUrl: GraphQLTypes["S3SignedUpload"],
	generateReadUrl: GraphQLTypes["S3SignedRead"],
	createContactRequestSubmission: GraphQLTypes["ContactRequestSubmissionCreateResult"],
	createSubscribeForm: GraphQLTypes["SubscribeFormCreateResult"],
	updateSubscribeForm: GraphQLTypes["SubscribeFormUpdateResult"],
	upsertSubscribeForm: GraphQLTypes["SubscribeFormUpsertResult"],
	createSubscribeSubmission: GraphQLTypes["SubscribeSubmissionCreateResult"],
	query?: GraphQLTypes["Query"] | undefined
};
	["MutationTransactionOptions"]: {
		deferForeignKeyConstraints?: boolean | undefined,
	deferUniqueConstraints?: boolean | undefined
}
    }
export const enum _RelationSide {
	owning = "owning",
	inverse = "inverse"
}
export const enum _OnDeleteBehaviour {
	restrict = "restrict",
	cascade = "cascade",
	setNull = "setNull"
}
export const enum _OrderByDirection {
	asc = "asc",
	desc = "desc"
}
export const enum One {
	One = "One"
}
export const enum ContentReferenceType {
	image = "image",
	imageWithCaption = "imageWithCaption",
	caseStudies = "caseStudies",
	featuredCaseStudies = "featuredCaseStudies",
	relatedCaseStudies = "relatedCaseStudies",
	acronym = "acronym",
	logoList = "logoList",
	openPositions = "openPositions",
	specificPositions = "specificPositions",
	values = "values",
	statistics = "statistics",
	catchwords = "catchwords",
	embed = "embed",
	tags = "tags",
	talkList = "talkList",
	callToAction = "callToAction",
	businessCardList = "businessCardList",
	contact = "contact",
	contactForm = "contactForm",
	testimonials = "testimonials",
	testimonialsFull = "testimonialsFull",
	gallery = "gallery",
	title = "title",
	eventHeader = "eventHeader",
	frontPageHeader = "frontPageHeader",
	lock = "lock",
	caseStudy = "caseStudy",
	line = "line",
	letter = "letter",
	textWithButton = "textWithButton",
	titleWithText = "titleWithText",
	shakyHand = "shakyHand",
	blogPosts = "blogPosts",
	highlightedContent = "highlightedContent",
	section = "section"
}
export const enum LinkType {
	internal = "internal",
	external = "external"
}
export const enum EmbedType {
	youtube = "youtube",
	vimeo = "vimeo"
}
export const enum ContentBackgroundType {
	base = "base",
	highlight = "highlight"
}
export const enum ContactRequestOptionType {
	contact = "contact",
	subscribe = "subscribe"
}
export const enum ImageVariant {
	avatar = "avatar",
	logo = "logo"
}
export const enum ContentVerticalMargin {
	large = "large",
	medium = "medium"
}
export const enum IsLink {
	yes = "yes",
	no = "no"
}
export const enum OrderDirection {
	asc = "asc",
	desc = "desc",
	ascNullsFirst = "ascNullsFirst",
	descNullsLast = "descNullsLast"
}
export const enum TranslationContentType {
	textPlain = "textPlain",
	textHtml = "textHtml"
}
export const enum S33ContentDisposition {
	ATTACHMENT = "ATTACHMENT",
	INLINE = "INLINE"
}
export const enum S3Acl {
	PUBLIC_READ = "PUBLIC_READ",
	PRIVATE = "PRIVATE",
	NONE = "NONE"
}
export const enum _MutationErrorType {
	NotNullConstraintViolation = "NotNullConstraintViolation",
	UniqueConstraintViolation = "UniqueConstraintViolation",
	ForeignKeyConstraintViolation = "ForeignKeyConstraintViolation",
	NotFoundOrDenied = "NotFoundOrDenied",
	NonUniqueWhereInput = "NonUniqueWhereInput",
	InvalidDataInput = "InvalidDataInput",
	SqlError = "SqlError"
}

type ZEUS_VARIABLES = {
	["Json"]: ValueTypes["Json"];
	["_RelationSide"]: ValueTypes["_RelationSide"];
	["_OnDeleteBehaviour"]: ValueTypes["_OnDeleteBehaviour"];
	["_OrderByDirection"]: ValueTypes["_OrderByDirection"];
	["UUID"]: ValueTypes["UUID"];
	["One"]: ValueTypes["One"];
	["GeneralWhere"]: ValueTypes["GeneralWhere"];
	["UUIDCondition"]: ValueTypes["UUIDCondition"];
	["OneCondition"]: ValueTypes["OneCondition"];
	["GeneralLocaleWhere"]: ValueTypes["GeneralLocaleWhere"];
	["LocaleWhere"]: ValueTypes["LocaleWhere"];
	["StringCondition"]: ValueTypes["StringCondition"];
	["IntCondition"]: ValueTypes["IntCondition"];
	["GenericPageLocaleWhere"]: ValueTypes["GenericPageLocaleWhere"];
	["GenericPageWhere"]: ValueTypes["GenericPageWhere"];
	["DateTimeCondition"]: ValueTypes["DateTimeCondition"];
	["DateTime"]: ValueTypes["DateTime"];
	["ContentWhere"]: ValueTypes["ContentWhere"];
	["ContentBlockWhere"]: ValueTypes["ContentBlockWhere"];
	["ContentReferenceWhere"]: ValueTypes["ContentReferenceWhere"];
	["ContentReferenceTypeCondition"]: ValueTypes["ContentReferenceTypeCondition"];
	["ContentReferenceType"]: ValueTypes["ContentReferenceType"];
	["ImageWhere"]: ValueTypes["ImageWhere"];
	["LinkWhere"]: ValueTypes["LinkWhere"];
	["LinkableWhere"]: ValueTypes["LinkableWhere"];
	["CaseStudyLocaleWhere"]: ValueTypes["CaseStudyLocaleWhere"];
	["CaseStudyWhere"]: ValueTypes["CaseStudyWhere"];
	["CaseStudyTagWhere"]: ValueTypes["CaseStudyTagWhere"];
	["TagWhere"]: ValueTypes["TagWhere"];
	["TagLocaleWhere"]: ValueTypes["TagLocaleWhere"];
	["SeoWhere"]: ValueTypes["SeoWhere"];
	["BooleanCondition"]: ValueTypes["BooleanCondition"];
	["PositionLocaleWhere"]: ValueTypes["PositionLocaleWhere"];
	["PositionWhere"]: ValueTypes["PositionWhere"];
	["PositionTagListWhere"]: ValueTypes["PositionTagListWhere"];
	["PositionTagListItemWhere"]: ValueTypes["PositionTagListItemWhere"];
	["PositionTagWhere"]: ValueTypes["PositionTagWhere"];
	["PositionTagLocaleWhere"]: ValueTypes["PositionTagLocaleWhere"];
	["PositionCategoryWhere"]: ValueTypes["PositionCategoryWhere"];
	["PositionCategoryLocaleWhere"]: ValueTypes["PositionCategoryLocaleWhere"];
	["RedirectWhere"]: ValueTypes["RedirectWhere"];
	["BlogPostLocaleWhere"]: ValueTypes["BlogPostLocaleWhere"];
	["BlogPostWhere"]: ValueTypes["BlogPostWhere"];
	["AuthorWhere"]: ValueTypes["AuthorWhere"];
	["AuthorLocaleWhere"]: ValueTypes["AuthorLocaleWhere"];
	["RelevantBlogPostListWhere"]: ValueTypes["RelevantBlogPostListWhere"];
	["LinkTypeCondition"]: ValueTypes["LinkTypeCondition"];
	["LinkType"]: ValueTypes["LinkType"];
	["CaseStudyListWhere"]: ValueTypes["CaseStudyListWhere"];
	["CaseStudyListItemWhere"]: ValueTypes["CaseStudyListItemWhere"];
	["ImageListWhere"]: ValueTypes["ImageListWhere"];
	["ImageListItemWhere"]: ValueTypes["ImageListItemWhere"];
	["InformationListWhere"]: ValueTypes["InformationListWhere"];
	["PieceOfInformationWhere"]: ValueTypes["PieceOfInformationWhere"];
	["EmbedWhere"]: ValueTypes["EmbedWhere"];
	["EmbedTypeCondition"]: ValueTypes["EmbedTypeCondition"];
	["EmbedType"]: ValueTypes["EmbedType"];
	["LinkListWhere"]: ValueTypes["LinkListWhere"];
	["LinkListItemWhere"]: ValueTypes["LinkListItemWhere"];
	["TalkListsWhere"]: ValueTypes["TalkListsWhere"];
	["TalkListWhere"]: ValueTypes["TalkListWhere"];
	["TalkListItemWhere"]: ValueTypes["TalkListItemWhere"];
	["TalkWhere"]: ValueTypes["TalkWhere"];
	["BusinessCardListWhere"]: ValueTypes["BusinessCardListWhere"];
	["BusinessCardListItemWhere"]: ValueTypes["BusinessCardListItemWhere"];
	["BusinessCardWhere"]: ValueTypes["BusinessCardWhere"];
	["TestimonialListWhere"]: ValueTypes["TestimonialListWhere"];
	["TestimonialListItemWhere"]: ValueTypes["TestimonialListItemWhere"];
	["TestimonialWhere"]: ValueTypes["TestimonialWhere"];
	["ContentBackgroundTypeCondition"]: ValueTypes["ContentBackgroundTypeCondition"];
	["ContentBackgroundType"]: ValueTypes["ContentBackgroundType"];
	["ContactRequestOptionListWhere"]: ValueTypes["ContactRequestOptionListWhere"];
	["ContactRequestOptionWhere"]: ValueTypes["ContactRequestOptionWhere"];
	["ContactRequestOptionTypeCondition"]: ValueTypes["ContactRequestOptionTypeCondition"];
	["ContactRequestOptionType"]: ValueTypes["ContactRequestOptionType"];
	["SubscribeFormWhere"]: ValueTypes["SubscribeFormWhere"];
	["YoutubeVideoWhere"]: ValueTypes["YoutubeVideoWhere"];
	["VideoWhere"]: ValueTypes["VideoWhere"];
	["FloatCondition"]: ValueTypes["FloatCondition"];
	["ContentReferencePositionCategoryItemWhere"]: ValueTypes["ContentReferencePositionCategoryItemWhere"];
	["ContentReferenceCaseStudyItemWhere"]: ValueTypes["ContentReferenceCaseStudyItemWhere"];
	["ContentReferenceTagItemWhere"]: ValueTypes["ContentReferenceTagItemWhere"];
	["PositionListWhere"]: ValueTypes["PositionListWhere"];
	["PositionListItemWhere"]: ValueTypes["PositionListItemWhere"];
	["TestimonialFullListWhere"]: ValueTypes["TestimonialFullListWhere"];
	["TestimonialFullListItemWhere"]: ValueTypes["TestimonialFullListItemWhere"];
	["TestimonialFullWhere"]: ValueTypes["TestimonialFullWhere"];
	["ImageVariantCondition"]: ValueTypes["ImageVariantCondition"];
	["ImageVariant"]: ValueTypes["ImageVariant"];
	["ContentReferenceBlogPostItemWhere"]: ValueTypes["ContentReferenceBlogPostItemWhere"];
	["DateCondition"]: ValueTypes["DateCondition"];
	["Date"]: ValueTypes["Date"];
	["ContentVerticalMarginCondition"]: ValueTypes["ContentVerticalMarginCondition"];
	["ContentVerticalMargin"]: ValueTypes["ContentVerticalMargin"];
	["HeaderLocaleWhere"]: ValueTypes["HeaderLocaleWhere"];
	["HeaderWhere"]: ValueTypes["HeaderWhere"];
	["TopLevelLinkItemWhere"]: ValueTypes["TopLevelLinkItemWhere"];
	["IsLinkCondition"]: ValueTypes["IsLinkCondition"];
	["IsLink"]: ValueTypes["IsLink"];
	["SecondLevelLinkItemWhere"]: ValueTypes["SecondLevelLinkItemWhere"];
	["ThirdLevelLinkItemWhere"]: ValueTypes["ThirdLevelLinkItemWhere"];
	["FooterLocaleWhere"]: ValueTypes["FooterLocaleWhere"];
	["FooterWhere"]: ValueTypes["FooterWhere"];
	["BlogPostCommonLocaleWhere"]: ValueTypes["BlogPostCommonLocaleWhere"];
	["BlogPostCommonWhere"]: ValueTypes["BlogPostCommonWhere"];
	["GeneralLocaleOrderBy"]: ValueTypes["GeneralLocaleOrderBy"];
	["OrderDirection"]: ValueTypes["OrderDirection"];
	["GeneralOrderBy"]: ValueTypes["GeneralOrderBy"];
	["LocaleOrderBy"]: ValueTypes["LocaleOrderBy"];
	["SeoOrderBy"]: ValueTypes["SeoOrderBy"];
	["ImageOrderBy"]: ValueTypes["ImageOrderBy"];
	["LinkableOrderBy"]: ValueTypes["LinkableOrderBy"];
	["GenericPageLocaleOrderBy"]: ValueTypes["GenericPageLocaleOrderBy"];
	["GenericPageOrderBy"]: ValueTypes["GenericPageOrderBy"];
	["ContentOrderBy"]: ValueTypes["ContentOrderBy"];
	["CaseStudyLocaleOrderBy"]: ValueTypes["CaseStudyLocaleOrderBy"];
	["CaseStudyOrderBy"]: ValueTypes["CaseStudyOrderBy"];
	["PositionLocaleOrderBy"]: ValueTypes["PositionLocaleOrderBy"];
	["PositionOrderBy"]: ValueTypes["PositionOrderBy"];
	["PositionTagListOrderBy"]: ValueTypes["PositionTagListOrderBy"];
	["PositionCategoryOrderBy"]: ValueTypes["PositionCategoryOrderBy"];
	["RedirectOrderBy"]: ValueTypes["RedirectOrderBy"];
	["LinkOrderBy"]: ValueTypes["LinkOrderBy"];
	["BlogPostLocaleOrderBy"]: ValueTypes["BlogPostLocaleOrderBy"];
	["BlogPostOrderBy"]: ValueTypes["BlogPostOrderBy"];
	["AuthorOrderBy"]: ValueTypes["AuthorOrderBy"];
	["GenericPageLocalesByLocaleUniqueWhere"]: ValueTypes["GenericPageLocalesByLocaleUniqueWhere"];
	["LocaleUniqueWhere"]: ValueTypes["LocaleUniqueWhere"];
	["GeneralLocaleUniqueWhere"]: ValueTypes["GeneralLocaleUniqueWhere"];
	["GeneralUniqueWhere"]: ValueTypes["GeneralUniqueWhere"];
	["SeoUniqueWhere"]: ValueTypes["SeoUniqueWhere"];
	["ImageUniqueWhere"]: ValueTypes["ImageUniqueWhere"];
	["ContentUniqueWhere"]: ValueTypes["ContentUniqueWhere"];
	["ContentBlockUniqueWhere"]: ValueTypes["ContentBlockUniqueWhere"];
	["ContentReferenceUniqueWhere"]: ValueTypes["ContentReferenceUniqueWhere"];
	["LinkUniqueWhere"]: ValueTypes["LinkUniqueWhere"];
	["CaseStudyListUniqueWhere"]: ValueTypes["CaseStudyListUniqueWhere"];
	["CaseStudyListItemUniqueWhere"]: ValueTypes["CaseStudyListItemUniqueWhere"];
	["ImageListUniqueWhere"]: ValueTypes["ImageListUniqueWhere"];
	["ImageListItemUniqueWhere"]: ValueTypes["ImageListItemUniqueWhere"];
	["InformationListUniqueWhere"]: ValueTypes["InformationListUniqueWhere"];
	["PieceOfInformationUniqueWhere"]: ValueTypes["PieceOfInformationUniqueWhere"];
	["EmbedUniqueWhere"]: ValueTypes["EmbedUniqueWhere"];
	["LinkListUniqueWhere"]: ValueTypes["LinkListUniqueWhere"];
	["LinkListItemUniqueWhere"]: ValueTypes["LinkListItemUniqueWhere"];
	["TalkListsUniqueWhere"]: ValueTypes["TalkListsUniqueWhere"];
	["TalkListUniqueWhere"]: ValueTypes["TalkListUniqueWhere"];
	["TalkListItemUniqueWhere"]: ValueTypes["TalkListItemUniqueWhere"];
	["BusinessCardListUniqueWhere"]: ValueTypes["BusinessCardListUniqueWhere"];
	["BusinessCardListItemUniqueWhere"]: ValueTypes["BusinessCardListItemUniqueWhere"];
	["BusinessCardUniqueWhere"]: ValueTypes["BusinessCardUniqueWhere"];
	["TestimonialListUniqueWhere"]: ValueTypes["TestimonialListUniqueWhere"];
	["TestimonialListItemUniqueWhere"]: ValueTypes["TestimonialListItemUniqueWhere"];
	["TestimonialUniqueWhere"]: ValueTypes["TestimonialUniqueWhere"];
	["ContactRequestOptionListUniqueWhere"]: ValueTypes["ContactRequestOptionListUniqueWhere"];
	["ContactRequestOptionUniqueWhere"]: ValueTypes["ContactRequestOptionUniqueWhere"];
	["YoutubeVideoUniqueWhere"]: ValueTypes["YoutubeVideoUniqueWhere"];
	["ContentReferencePositionCategoryItemUniqueWhere"]: ValueTypes["ContentReferencePositionCategoryItemUniqueWhere"];
	["PositionCategoryUniqueWhere"]: ValueTypes["PositionCategoryUniqueWhere"];
	["PositionUniqueWhere"]: ValueTypes["PositionUniqueWhere"];
	["PositionTagListUniqueWhere"]: ValueTypes["PositionTagListUniqueWhere"];
	["PositionTagListItemUniqueWhere"]: ValueTypes["PositionTagListItemUniqueWhere"];
	["PositionLocaleUniqueWhere"]: ValueTypes["PositionLocaleUniqueWhere"];
	["LinkableUniqueWhere"]: ValueTypes["LinkableUniqueWhere"];
	["GenericPageLocaleUniqueWhere"]: ValueTypes["GenericPageLocaleUniqueWhere"];
	["GenericPageUniqueWhere"]: ValueTypes["GenericPageUniqueWhere"];
	["CaseStudyLocaleUniqueWhere"]: ValueTypes["CaseStudyLocaleUniqueWhere"];
	["CaseStudyUniqueWhere"]: ValueTypes["CaseStudyUniqueWhere"];
	["CaseStudyTagUniqueWhere"]: ValueTypes["CaseStudyTagUniqueWhere"];
	["RedirectUniqueWhere"]: ValueTypes["RedirectUniqueWhere"];
	["BlogPostLocaleUniqueWhere"]: ValueTypes["BlogPostLocaleUniqueWhere"];
	["BlogPostUniqueWhere"]: ValueTypes["BlogPostUniqueWhere"];
	["RelevantBlogPostListUniqueWhere"]: ValueTypes["RelevantBlogPostListUniqueWhere"];
	["PositionCategoryLocaleUniqueWhere"]: ValueTypes["PositionCategoryLocaleUniqueWhere"];
	["ContentReferenceCaseStudyItemUniqueWhere"]: ValueTypes["ContentReferenceCaseStudyItemUniqueWhere"];
	["ContentReferenceTagItemUniqueWhere"]: ValueTypes["ContentReferenceTagItemUniqueWhere"];
	["TagUniqueWhere"]: ValueTypes["TagUniqueWhere"];
	["TagLocaleUniqueWhere"]: ValueTypes["TagLocaleUniqueWhere"];
	["PositionListUniqueWhere"]: ValueTypes["PositionListUniqueWhere"];
	["PositionListItemUniqueWhere"]: ValueTypes["PositionListItemUniqueWhere"];
	["TestimonialFullListUniqueWhere"]: ValueTypes["TestimonialFullListUniqueWhere"];
	["TestimonialFullListItemUniqueWhere"]: ValueTypes["TestimonialFullListItemUniqueWhere"];
	["TestimonialFullUniqueWhere"]: ValueTypes["TestimonialFullUniqueWhere"];
	["ContentReferenceBlogPostItemUniqueWhere"]: ValueTypes["ContentReferenceBlogPostItemUniqueWhere"];
	["HeaderLocaleUniqueWhere"]: ValueTypes["HeaderLocaleUniqueWhere"];
	["HeaderUniqueWhere"]: ValueTypes["HeaderUniqueWhere"];
	["TopLevelLinkItemUniqueWhere"]: ValueTypes["TopLevelLinkItemUniqueWhere"];
	["SecondLevelLinkItemUniqueWhere"]: ValueTypes["SecondLevelLinkItemUniqueWhere"];
	["ThirdLevelLinkItemUniqueWhere"]: ValueTypes["ThirdLevelLinkItemUniqueWhere"];
	["FooterLocaleUniqueWhere"]: ValueTypes["FooterLocaleUniqueWhere"];
	["FooterUniqueWhere"]: ValueTypes["FooterUniqueWhere"];
	["PositionTagLocaleUniqueWhere"]: ValueTypes["PositionTagLocaleUniqueWhere"];
	["PositionTagUniqueWhere"]: ValueTypes["PositionTagUniqueWhere"];
	["AuthorLocaleUniqueWhere"]: ValueTypes["AuthorLocaleUniqueWhere"];
	["AuthorUniqueWhere"]: ValueTypes["AuthorUniqueWhere"];
	["BlogPostCommonLocaleUniqueWhere"]: ValueTypes["BlogPostCommonLocaleUniqueWhere"];
	["BlogPostCommonUniqueWhere"]: ValueTypes["BlogPostCommonUniqueWhere"];
	["GenericPageLocalesByContentUniqueWhere"]: ValueTypes["GenericPageLocalesByContentUniqueWhere"];
	["GenericPageLocalesByLinkUniqueWhere"]: ValueTypes["GenericPageLocalesByLinkUniqueWhere"];
	["GenericPageLocalesBySeoUniqueWhere"]: ValueTypes["GenericPageLocalesBySeoUniqueWhere"];
	["TagLocaleOrderBy"]: ValueTypes["TagLocaleOrderBy"];
	["TagOrderBy"]: ValueTypes["TagOrderBy"];
	["CaseStudyTagOrderBy"]: ValueTypes["CaseStudyTagOrderBy"];
	["TagLocalesByLocaleUniqueWhere"]: ValueTypes["TagLocalesByLocaleUniqueWhere"];
	["CaseStudyLocalesByLocaleUniqueWhere"]: ValueTypes["CaseStudyLocalesByLocaleUniqueWhere"];
	["CaseStudyLocalesByContentUniqueWhere"]: ValueTypes["CaseStudyLocalesByContentUniqueWhere"];
	["CaseStudyLocalesByLinkUniqueWhere"]: ValueTypes["CaseStudyLocalesByLinkUniqueWhere"];
	["CaseStudyLocalesBySeoUniqueWhere"]: ValueTypes["CaseStudyLocalesBySeoUniqueWhere"];
	["PositionTagLocaleOrderBy"]: ValueTypes["PositionTagLocaleOrderBy"];
	["PositionTagOrderBy"]: ValueTypes["PositionTagOrderBy"];
	["PositionTagLocalesByLocaleUniqueWhere"]: ValueTypes["PositionTagLocalesByLocaleUniqueWhere"];
	["PositionTagListItemOrderBy"]: ValueTypes["PositionTagListItemOrderBy"];
	["PositionCategoryLocaleOrderBy"]: ValueTypes["PositionCategoryLocaleOrderBy"];
	["PositionCategoryPositionsByTagsUniqueWhere"]: ValueTypes["PositionCategoryPositionsByTagsUniqueWhere"];
	["PositionCategoryPositionsByLocalesUniqueWhere"]: ValueTypes["PositionCategoryPositionsByLocalesUniqueWhere"];
	["PositionCategoryLocalesByLocaleUniqueWhere"]: ValueTypes["PositionCategoryLocalesByLocaleUniqueWhere"];
	["PositionLocalesByLocaleUniqueWhere"]: ValueTypes["PositionLocalesByLocaleUniqueWhere"];
	["PositionLocalesByLinkUniqueWhere"]: ValueTypes["PositionLocalesByLinkUniqueWhere"];
	["PositionLocalesBySeoUniqueWhere"]: ValueTypes["PositionLocalesBySeoUniqueWhere"];
	["PositionLocalesByContentUniqueWhere"]: ValueTypes["PositionLocalesByContentUniqueWhere"];
	["AuthorLocaleOrderBy"]: ValueTypes["AuthorLocaleOrderBy"];
	["AuthorLocalesByLocaleUniqueWhere"]: ValueTypes["AuthorLocalesByLocaleUniqueWhere"];
	["AuthorBlogPostsByLocalesUniqueWhere"]: ValueTypes["AuthorBlogPostsByLocalesUniqueWhere"];
	["AuthorBlogPostsByRelevantBlogPostsUniqueWhere"]: ValueTypes["AuthorBlogPostsByRelevantBlogPostsUniqueWhere"];
	["RelevantBlogPostListOrderBy"]: ValueTypes["RelevantBlogPostListOrderBy"];
	["BlogPostLocalesByLocaleUniqueWhere"]: ValueTypes["BlogPostLocalesByLocaleUniqueWhere"];
	["BlogPostLocalesByContentUniqueWhere"]: ValueTypes["BlogPostLocalesByContentUniqueWhere"];
	["BlogPostLocalesBySeoUniqueWhere"]: ValueTypes["BlogPostLocalesBySeoUniqueWhere"];
	["BlogPostLocalesByLinkUniqueWhere"]: ValueTypes["BlogPostLocalesByLinkUniqueWhere"];
	["BlogPostRelevantBlogPostsByRelevantBlogPostUniqueWhere"]: ValueTypes["BlogPostRelevantBlogPostsByRelevantBlogPostUniqueWhere"];
	["CaseStudyListItemOrderBy"]: ValueTypes["CaseStudyListItemOrderBy"];
	["CaseStudyListOrderBy"]: ValueTypes["CaseStudyListOrderBy"];
	["ImageListItemOrderBy"]: ValueTypes["ImageListItemOrderBy"];
	["ImageListOrderBy"]: ValueTypes["ImageListOrderBy"];
	["ImageListItemsByImageUniqueWhere"]: ValueTypes["ImageListItemsByImageUniqueWhere"];
	["PieceOfInformationOrderBy"]: ValueTypes["PieceOfInformationOrderBy"];
	["InformationListOrderBy"]: ValueTypes["InformationListOrderBy"];
	["LinkListItemOrderBy"]: ValueTypes["LinkListItemOrderBy"];
	["LinkListOrderBy"]: ValueTypes["LinkListOrderBy"];
	["LinkListItemsByLinkUniqueWhere"]: ValueTypes["LinkListItemsByLinkUniqueWhere"];
	["TalkListItemOrderBy"]: ValueTypes["TalkListItemOrderBy"];
	["TalkListOrderBy"]: ValueTypes["TalkListOrderBy"];
	["TalkListsOrderBy"]: ValueTypes["TalkListsOrderBy"];
	["TalkOrderBy"]: ValueTypes["TalkOrderBy"];
	["TalkListsItemsByItemsUniqueWhere"]: ValueTypes["TalkListsItemsByItemsUniqueWhere"];
	["BusinessCardListItemOrderBy"]: ValueTypes["BusinessCardListItemOrderBy"];
	["BusinessCardListOrderBy"]: ValueTypes["BusinessCardListOrderBy"];
	["BusinessCardOrderBy"]: ValueTypes["BusinessCardOrderBy"];
	["BusinessCardListItemsByBusinessCardUniqueWhere"]: ValueTypes["BusinessCardListItemsByBusinessCardUniqueWhere"];
	["TestimonialListItemOrderBy"]: ValueTypes["TestimonialListItemOrderBy"];
	["TestimonialListOrderBy"]: ValueTypes["TestimonialListOrderBy"];
	["TestimonialOrderBy"]: ValueTypes["TestimonialOrderBy"];
	["TestimonialListItemsByTestimonialUniqueWhere"]: ValueTypes["TestimonialListItemsByTestimonialUniqueWhere"];
	["ContactRequestOptionOrderBy"]: ValueTypes["ContactRequestOptionOrderBy"];
	["ContactRequestOptionListOrderBy"]: ValueTypes["ContactRequestOptionListOrderBy"];
	["SubscribeFormOrderBy"]: ValueTypes["SubscribeFormOrderBy"];
	["ContentReferencePositionCategoryItemOrderBy"]: ValueTypes["ContentReferencePositionCategoryItemOrderBy"];
	["ContentReferenceOrderBy"]: ValueTypes["ContentReferenceOrderBy"];
	["ContentBlockOrderBy"]: ValueTypes["ContentBlockOrderBy"];
	["EmbedOrderBy"]: ValueTypes["EmbedOrderBy"];
	["YoutubeVideoOrderBy"]: ValueTypes["YoutubeVideoOrderBy"];
	["VideoOrderBy"]: ValueTypes["VideoOrderBy"];
	["PositionListOrderBy"]: ValueTypes["PositionListOrderBy"];
	["TestimonialFullListOrderBy"]: ValueTypes["TestimonialFullListOrderBy"];
	["ContentReferenceCaseStudyItemOrderBy"]: ValueTypes["ContentReferenceCaseStudyItemOrderBy"];
	["ContentReferenceTagItemOrderBy"]: ValueTypes["ContentReferenceTagItemOrderBy"];
	["PositionListItemOrderBy"]: ValueTypes["PositionListItemOrderBy"];
	["TestimonialFullListItemOrderBy"]: ValueTypes["TestimonialFullListItemOrderBy"];
	["TestimonialFullOrderBy"]: ValueTypes["TestimonialFullOrderBy"];
	["TestimonialFullListItemsByTestimonialUniqueWhere"]: ValueTypes["TestimonialFullListItemsByTestimonialUniqueWhere"];
	["ContentReferenceBlogPostItemOrderBy"]: ValueTypes["ContentReferenceBlogPostItemOrderBy"];
	["ContentReferencePositionCategoriesByItemUniqueWhere"]: ValueTypes["ContentReferencePositionCategoriesByItemUniqueWhere"];
	["ContentReferenceCaseStudiesByItemUniqueWhere"]: ValueTypes["ContentReferenceCaseStudiesByItemUniqueWhere"];
	["ContentReferenceTagsByItemUniqueWhere"]: ValueTypes["ContentReferenceTagsByItemUniqueWhere"];
	["ContentReferenceBlogPostsByItemUniqueWhere"]: ValueTypes["ContentReferenceBlogPostsByItemUniqueWhere"];
	["ContentBlockReferencesByLinkUniqueWhere"]: ValueTypes["ContentBlockReferencesByLinkUniqueWhere"];
	["ContentBlockReferencesByFeaturedCaseStudiesUniqueWhere"]: ValueTypes["ContentBlockReferencesByFeaturedCaseStudiesUniqueWhere"];
	["ContentBlockReferencesByImagesUniqueWhere"]: ValueTypes["ContentBlockReferencesByImagesUniqueWhere"];
	["ContentBlockReferencesByOtherImagesUniqueWhere"]: ValueTypes["ContentBlockReferencesByOtherImagesUniqueWhere"];
	["ContentBlockReferencesByInformationListUniqueWhere"]: ValueTypes["ContentBlockReferencesByInformationListUniqueWhere"];
	["ContentBlockReferencesByEmbedUniqueWhere"]: ValueTypes["ContentBlockReferencesByEmbedUniqueWhere"];
	["ContentBlockReferencesByLinksUniqueWhere"]: ValueTypes["ContentBlockReferencesByLinksUniqueWhere"];
	["ContentBlockReferencesByTalkListsUniqueWhere"]: ValueTypes["ContentBlockReferencesByTalkListsUniqueWhere"];
	["ContentBlockReferencesByBusinessCardListUniqueWhere"]: ValueTypes["ContentBlockReferencesByBusinessCardListUniqueWhere"];
	["ContentBlockReferencesByTestimonialsUniqueWhere"]: ValueTypes["ContentBlockReferencesByTestimonialsUniqueWhere"];
	["ContentBlockReferencesByContactRequestOptionsUniqueWhere"]: ValueTypes["ContentBlockReferencesByContactRequestOptionsUniqueWhere"];
	["ContentBlockReferencesByYoutubeVideoUniqueWhere"]: ValueTypes["ContentBlockReferencesByYoutubeVideoUniqueWhere"];
	["ContentBlockReferencesByPositionCategoriesUniqueWhere"]: ValueTypes["ContentBlockReferencesByPositionCategoriesUniqueWhere"];
	["ContentBlockReferencesByCaseStudiesUniqueWhere"]: ValueTypes["ContentBlockReferencesByCaseStudiesUniqueWhere"];
	["ContentBlockReferencesByTagsUniqueWhere"]: ValueTypes["ContentBlockReferencesByTagsUniqueWhere"];
	["ContentBlockReferencesByPositionsUniqueWhere"]: ValueTypes["ContentBlockReferencesByPositionsUniqueWhere"];
	["ContentBlockReferencesByTestimonialsFullUniqueWhere"]: ValueTypes["ContentBlockReferencesByTestimonialsFullUniqueWhere"];
	["ContentBlockReferencesByBlogPostsUniqueWhere"]: ValueTypes["ContentBlockReferencesByBlogPostsUniqueWhere"];
	["ContentBlockReferencesByContentUniqueWhere"]: ValueTypes["ContentBlockReferencesByContentUniqueWhere"];
	["ContentBlocksByReferencesUniqueWhere"]: ValueTypes["ContentBlocksByReferencesUniqueWhere"];
	["HeaderLocaleOrderBy"]: ValueTypes["HeaderLocaleOrderBy"];
	["HeaderOrderBy"]: ValueTypes["HeaderOrderBy"];
	["HeaderLocalesByLocaleUniqueWhere"]: ValueTypes["HeaderLocalesByLocaleUniqueWhere"];
	["HeaderLocalesByLinkListUniqueWhere"]: ValueTypes["HeaderLocalesByLinkListUniqueWhere"];
	["HeaderLocalesBySocialLinkListUniqueWhere"]: ValueTypes["HeaderLocalesBySocialLinkListUniqueWhere"];
	["HeaderLocalesByTopLevelLinkItemsUniqueWhere"]: ValueTypes["HeaderLocalesByTopLevelLinkItemsUniqueWhere"];
	["ThirdLevelLinkItemOrderBy"]: ValueTypes["ThirdLevelLinkItemOrderBy"];
	["SecondLevelLinkItemOrderBy"]: ValueTypes["SecondLevelLinkItemOrderBy"];
	["TopLevelLinkItemOrderBy"]: ValueTypes["TopLevelLinkItemOrderBy"];
	["SecondLevelLinkItemThirdLevelLinkItemsByLinkUniqueWhere"]: ValueTypes["SecondLevelLinkItemThirdLevelLinkItemsByLinkUniqueWhere"];
	["TopLevelLinkItemSecondLevelLinkItemsByLinkUniqueWhere"]: ValueTypes["TopLevelLinkItemSecondLevelLinkItemsByLinkUniqueWhere"];
	["TopLevelLinkItemSecondLevelLinkItemsByThirdLevelLinkItemsUniqueWhere"]: ValueTypes["TopLevelLinkItemSecondLevelLinkItemsByThirdLevelLinkItemsUniqueWhere"];
	["HeaderLocaleTopLevelLinkItemsBySecondLevelLinkItemsUniqueWhere"]: ValueTypes["HeaderLocaleTopLevelLinkItemsBySecondLevelLinkItemsUniqueWhere"];
	["HeaderLocaleTopLevelLinkItemsByLinkUniqueWhere"]: ValueTypes["HeaderLocaleTopLevelLinkItemsByLinkUniqueWhere"];
	["FooterLocaleOrderBy"]: ValueTypes["FooterLocaleOrderBy"];
	["FooterOrderBy"]: ValueTypes["FooterOrderBy"];
	["FooterLocalesByLocaleUniqueWhere"]: ValueTypes["FooterLocalesByLocaleUniqueWhere"];
	["FooterLocalesByNavigationUniqueWhere"]: ValueTypes["FooterLocalesByNavigationUniqueWhere"];
	["BlogPostCommonLocaleOrderBy"]: ValueTypes["BlogPostCommonLocaleOrderBy"];
	["BlogPostCommonOrderBy"]: ValueTypes["BlogPostCommonOrderBy"];
	["BlogPostCommonLocalesByLocaleUniqueWhere"]: ValueTypes["BlogPostCommonLocalesByLocaleUniqueWhere"];
	["LocaleGeneralByRootUniqueWhere"]: ValueTypes["LocaleGeneralByRootUniqueWhere"];
	["LocaleGeneralBySeoUniqueWhere"]: ValueTypes["LocaleGeneralBySeoUniqueWhere"];
	["LocaleGeneralByContentUniqueWhere"]: ValueTypes["LocaleGeneralByContentUniqueWhere"];
	["LocaleGenericPagesByRootUniqueWhere"]: ValueTypes["LocaleGenericPagesByRootUniqueWhere"];
	["LocaleGenericPagesByContentUniqueWhere"]: ValueTypes["LocaleGenericPagesByContentUniqueWhere"];
	["LocaleGenericPagesByLinkUniqueWhere"]: ValueTypes["LocaleGenericPagesByLinkUniqueWhere"];
	["LocaleGenericPagesBySeoUniqueWhere"]: ValueTypes["LocaleGenericPagesBySeoUniqueWhere"];
	["LocaleHeadersByRootUniqueWhere"]: ValueTypes["LocaleHeadersByRootUniqueWhere"];
	["LocaleHeadersByLinkListUniqueWhere"]: ValueTypes["LocaleHeadersByLinkListUniqueWhere"];
	["LocaleHeadersBySocialLinkListUniqueWhere"]: ValueTypes["LocaleHeadersBySocialLinkListUniqueWhere"];
	["LocaleHeadersByTopLevelLinkItemsUniqueWhere"]: ValueTypes["LocaleHeadersByTopLevelLinkItemsUniqueWhere"];
	["LocaleFootersByRootUniqueWhere"]: ValueTypes["LocaleFootersByRootUniqueWhere"];
	["LocaleFootersByNavigationUniqueWhere"]: ValueTypes["LocaleFootersByNavigationUniqueWhere"];
	["LocaleCaseStudiesByRootUniqueWhere"]: ValueTypes["LocaleCaseStudiesByRootUniqueWhere"];
	["LocaleCaseStudiesByContentUniqueWhere"]: ValueTypes["LocaleCaseStudiesByContentUniqueWhere"];
	["LocaleCaseStudiesByLinkUniqueWhere"]: ValueTypes["LocaleCaseStudiesByLinkUniqueWhere"];
	["LocaleCaseStudiesBySeoUniqueWhere"]: ValueTypes["LocaleCaseStudiesBySeoUniqueWhere"];
	["LocaleTagsBySlugUniqueWhere"]: ValueTypes["LocaleTagsBySlugUniqueWhere"];
	["LocaleTagsByRootUniqueWhere"]: ValueTypes["LocaleTagsByRootUniqueWhere"];
	["LocalePositionTagsBySlugUniqueWhere"]: ValueTypes["LocalePositionTagsBySlugUniqueWhere"];
	["LocalePositionTagsByRootUniqueWhere"]: ValueTypes["LocalePositionTagsByRootUniqueWhere"];
	["LocalePositionsByRootUniqueWhere"]: ValueTypes["LocalePositionsByRootUniqueWhere"];
	["LocalePositionsByLinkUniqueWhere"]: ValueTypes["LocalePositionsByLinkUniqueWhere"];
	["LocalePositionsBySeoUniqueWhere"]: ValueTypes["LocalePositionsBySeoUniqueWhere"];
	["LocalePositionsByContentUniqueWhere"]: ValueTypes["LocalePositionsByContentUniqueWhere"];
	["LocalePositionCategoriesByRootUniqueWhere"]: ValueTypes["LocalePositionCategoriesByRootUniqueWhere"];
	["LocaleAuthorsByRootUniqueWhere"]: ValueTypes["LocaleAuthorsByRootUniqueWhere"];
	["LocaleBlogPostsByRootUniqueWhere"]: ValueTypes["LocaleBlogPostsByRootUniqueWhere"];
	["LocaleBlogPostsByContentUniqueWhere"]: ValueTypes["LocaleBlogPostsByContentUniqueWhere"];
	["LocaleBlogPostsBySeoUniqueWhere"]: ValueTypes["LocaleBlogPostsBySeoUniqueWhere"];
	["LocaleBlogPostsByLinkUniqueWhere"]: ValueTypes["LocaleBlogPostsByLinkUniqueWhere"];
	["LocaleBlogPostCommonByRootUniqueWhere"]: ValueTypes["LocaleBlogPostCommonByRootUniqueWhere"];
	["GeneralLocalesByLocaleUniqueWhere"]: ValueTypes["GeneralLocalesByLocaleUniqueWhere"];
	["GeneralLocalesBySeoUniqueWhere"]: ValueTypes["GeneralLocalesBySeoUniqueWhere"];
	["GeneralLocalesByContentUniqueWhere"]: ValueTypes["GeneralLocalesByContentUniqueWhere"];
	["TalkUniqueWhere"]: ValueTypes["TalkUniqueWhere"];
	["YoutubeVideoListWhere"]: ValueTypes["YoutubeVideoListWhere"];
	["YoutubeVideoListItemWhere"]: ValueTypes["YoutubeVideoListItemWhere"];
	["YoutubeVideoListItemOrderBy"]: ValueTypes["YoutubeVideoListItemOrderBy"];
	["YoutubeVideoListOrderBy"]: ValueTypes["YoutubeVideoListOrderBy"];
	["YoutubeVideoListItemsByYoutubeUniqueWhere"]: ValueTypes["YoutubeVideoListItemsByYoutubeUniqueWhere"];
	["YoutubeVideoListUniqueWhere"]: ValueTypes["YoutubeVideoListUniqueWhere"];
	["YoutubeVideoListItemUniqueWhere"]: ValueTypes["YoutubeVideoListItemUniqueWhere"];
	["TranslationCatalogueWhere"]: ValueTypes["TranslationCatalogueWhere"];
	["TranslationDomainWhere"]: ValueTypes["TranslationDomainWhere"];
	["TranslationKeyWhere"]: ValueTypes["TranslationKeyWhere"];
	["TranslationContentTypeCondition"]: ValueTypes["TranslationContentTypeCondition"];
	["TranslationContentType"]: ValueTypes["TranslationContentType"];
	["TranslationValueWhere"]: ValueTypes["TranslationValueWhere"];
	["TranslationCataloguesIdentifierWhere"]: ValueTypes["TranslationCataloguesIdentifierWhere"];
	["TranslationCatalogueOrderBy"]: ValueTypes["TranslationCatalogueOrderBy"];
	["TranslationDomainOrderBy"]: ValueTypes["TranslationDomainOrderBy"];
	["TranslationCataloguesIdentifierOrderBy"]: ValueTypes["TranslationCataloguesIdentifierOrderBy"];
	["TranslationValueOrderBy"]: ValueTypes["TranslationValueOrderBy"];
	["TranslationKeyOrderBy"]: ValueTypes["TranslationKeyOrderBy"];
	["TranslationKeyValuesByCatalogueUniqueWhere"]: ValueTypes["TranslationKeyValuesByCatalogueUniqueWhere"];
	["TranslationCatalogueUniqueWhere"]: ValueTypes["TranslationCatalogueUniqueWhere"];
	["TranslationDomainUniqueWhere"]: ValueTypes["TranslationDomainUniqueWhere"];
	["TranslationKeyUniqueWhere"]: ValueTypes["TranslationKeyUniqueWhere"];
	["TranslationValueUniqueWhere"]: ValueTypes["TranslationValueUniqueWhere"];
	["TranslationCataloguesIdentifierUniqueWhere"]: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"];
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: ValueTypes["TranslationDomainCataloguesByIdentifierUniqueWhere"];
	["TranslationDomainCataloguesByValuesUniqueWhere"]: ValueTypes["TranslationDomainCataloguesByValuesUniqueWhere"];
	["TranslationDomainKeysByIdentifierUniqueWhere"]: ValueTypes["TranslationDomainKeysByIdentifierUniqueWhere"];
	["TranslationDomainKeysByValuesUniqueWhere"]: ValueTypes["TranslationDomainKeysByValuesUniqueWhere"];
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: ValueTypes["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"];
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: ValueTypes["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"];
	["TranslationCatalogueValuesByKeyUniqueWhere"]: ValueTypes["TranslationCatalogueValuesByKeyUniqueWhere"];
	["ContactRequestSubmissionCreateInput"]: ValueTypes["ContactRequestSubmissionCreateInput"];
	["ContactRequestSubmissionCreateLocaleEntityRelationInput"]: ValueTypes["ContactRequestSubmissionCreateLocaleEntityRelationInput"];
	["SubscribeFormUniqueWhere"]: ValueTypes["SubscribeFormUniqueWhere"];
	["SubscribeFormCreateInput"]: ValueTypes["SubscribeFormCreateInput"];
	["SubscribeFormCreateSubmissionsEntityRelationInput"]: ValueTypes["SubscribeFormCreateSubmissionsEntityRelationInput"];
	["SubscribeSubmissionWithoutFormCreateInput"]: ValueTypes["SubscribeSubmissionWithoutFormCreateInput"];
	["SubscribeSubmissionCreateLocaleEntityRelationInput"]: ValueTypes["SubscribeSubmissionCreateLocaleEntityRelationInput"];
	["SubscribeFormUpdateInput"]: ValueTypes["SubscribeFormUpdateInput"];
	["SubscribeSubmissionCreateInput"]: ValueTypes["SubscribeSubmissionCreateInput"];
	["SubscribeSubmissionCreateFormEntityRelationInput"]: ValueTypes["SubscribeSubmissionCreateFormEntityRelationInput"];
	["SubscribeFormWithoutSubmissionsCreateInput"]: ValueTypes["SubscribeFormWithoutSubmissionsCreateInput"];
	["SubscribeSubmissionConnectOrCreateFormRelationInput"]: ValueTypes["SubscribeSubmissionConnectOrCreateFormRelationInput"];
	["VideoUniqueWhere"]: ValueTypes["VideoUniqueWhere"];
	["S3GenerateSignedUploadInput"]: ValueTypes["S3GenerateSignedUploadInput"];
	["S33ContentDisposition"]: ValueTypes["S33ContentDisposition"];
	["S3Acl"]: ValueTypes["S3Acl"];
	["_MutationErrorType"]: ValueTypes["_MutationErrorType"];
	["MutationTransactionOptions"]: ValueTypes["MutationTransactionOptions"];
}