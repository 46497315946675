import { createContext, FunctionComponent, ReactNode, useContext } from 'react'
import { PageProps } from '../pages/[[...path]]'

const specialLinksContext = createContext<SpecialLinksProviderProps['links']>({
	home: '/',
	positions: null,
	caseStudies: null,
})

export interface SpecialLinksProviderProps {
	links: PageProps['specialLinks']
	children: ReactNode
}

export const SpecialLinksProvider: FunctionComponent<SpecialLinksProviderProps> = ({ links, children }) => {
	return <specialLinksContext.Provider value={links}>{children}</specialLinksContext.Provider>
}

export const useHomeUrl = () => useContext(specialLinksContext).home
export const usePositionsLink = () => useContext(specialLinksContext).positions
export const useCaseStudiesLink = () => useContext(specialLinksContext).caseStudies
