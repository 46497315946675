import { RichTextRenderer } from '@contember/react-client'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { FooterResult } from '../data/FooterFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { Contact } from './Contact'
import { Container } from './Container'
import styles from './Footer.module.sass'
import { useTranslate } from './Translations'
import { useCookieConsent } from './CookieConsentProvider'

export interface FooterProps {
	footer: FooterResult
}

export const Footer: FunctionComponent<FooterProps> = ({ footer: { localesByLocale } }) => {
	const translate = useTranslate()
	const { cookieConsentAccepted, onShowCookieBarChanged } = useCookieConsent()

	return (
		<footer className={styles.wrapper}>
			<Container size="wide">
				<div className={styles.in}>
					<div className={styles.primary}>
						<div className={styles.navigation}>
							{localesByLocale?.navigation?.items.map(
								({ id, link }) =>
									link && (
										<Link key={id} className={styles.navigation_item} href={contemberLinkToHref(link)}>
											{link.title}
										</Link>
									),
							)}
						</div>
						<div className={styles.divider} />
						<div className={styles.address}>
							{localesByLocale?.address && <RichTextRenderer source={localesByLocale.address} />}
						</div>
						<div className={styles.contact}>
							<Contact phone={localesByLocale?.phone} email={localesByLocale?.email} />
						</div>
					</div>
					<div className={styles.secondary}>
						<div className={styles.legal}>
							{localesByLocale?.legal && <RichTextRenderer source={localesByLocale.legal} />}
							<div className={styles.consent}>
								<div>{cookieConsentAccepted ? translate('cookies.accepted') : translate('cookies.declined')}</div>
								<a href="#" onClick={(e) => {
									onShowCookieBarChanged(true)
									e.preventDefault()
								}}>
									{translate('cookies.reset')}
								</a>
							</div>
						</div>
						<div className={styles.mangoweb}>
							<a className={styles.mangoweb_link} href="https://mangoweb.cz">
								manGo<span className={styles.mangoweb_highlight}>web</span>
							</a>
						</div>
					</div>
				</div>
			</Container>
		</footer>
	)
}
