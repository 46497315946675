import clsx from 'clsx'
import { FunctionComponent } from 'react'
import style from './Container.module.sass'

export type ContainerProps = {
	size?: 'small' | 'normal' | 'wide' | 'ultraWide' | 'fullWidth' | 'limit'
	disableGutters?: boolean
	children: React.ReactNode
}

export const Container: FunctionComponent<ContainerProps> = ({ children, size = 'normal', disableGutters = false }) => {
	return (
		<div className={clsx(style.wrapper, style[`view_size_${size}`], disableGutters && style.view_disableGutters)}>
			{children}
		</div>
	)
}
