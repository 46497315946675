import { FunctionComponent, useMemo } from 'react'
import { PageProps } from '../pages/[[...path]]'
import { Container } from './Container'
import { Icon } from './Icon'
import styles from './OnlinePresence.module.sass'
import { useTranslate } from './Translations'

export interface OnlinePresenceProps {
	socialLinks: PageProps['socialLinks']
}

export const OnlinePresence: FunctionComponent<OnlinePresenceProps> = ({ socialLinks }) => {
	const items = useMemo(
		() =>
			[
				{
					title: 'Linkedin',
					url: socialLinks.linkedinUrl,
					icon: 'linkedin',
				},
				{
					title: 'Twitter',
					url: socialLinks.twitterUrl,
					icon: 'twitter',
				},
				{
					title: 'Instagram',
					url: socialLinks.instagramUrl,
					icon: 'instagram',
				},
				{
					title: 'YouTube',
					url: socialLinks.youtubeUrl,
					icon: 'youtube',
				},
				{
					title: 'Facebook',
					url: socialLinks.facebookUrl,
					icon: 'facebook',
				},
			] as const,
		[
			socialLinks.facebookUrl,
			socialLinks.instagramUrl,
			socialLinks.linkedinUrl,
			socialLinks.twitterUrl,
			socialLinks.youtubeUrl,
		],
	)
	const translate = useTranslate()

	return (
		<Container size="small">
			<div className={styles.wrapper}>
				<h2 className={styles.title}>{translate('onlinePresence.title')}</h2>
				<ul className={styles.list}>
					{items.map(
						(item, i) =>
							item.url && (
								<li key={i} className={styles.item}>
									<a href={item.url} className={styles.link} title={item.title} target="_blank" rel="noreferrer">
										<Icon name={item.icon} />
									</a>
								</li>
							),
					)}
				</ul>
			</div>
		</Container>
	)
}
