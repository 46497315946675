import type { FunctionComponent, ReactNode } from 'react'
import { SharedProgressLoadingIndicator } from 'shared-loading-indicator'
import { FooterResult } from '../data/FooterFragment'
import { HeaderResult } from '../data/HeaderFragment'
import { PageProps } from '../pages/[[...path]]'
import { Footer } from './Footer'
import { Header } from './Header'
import styles from './Layout.module.sass'
import { OnlinePresence } from './OnlinePresence'

export interface LayoutProps {
	children: ReactNode
	header: HeaderResult | undefined
	footer: FooterResult | undefined
	socialLinks: PageProps['socialLinks']
	locale: string
	locales: Array<{ code: string; url: string }>
}

export const Layout: FunctionComponent<LayoutProps> = ({ children, header, footer, socialLinks, locale, locales }) => {
	return (
		<div className={styles.wrapper}>
			<SharedProgressLoadingIndicator />
			<Header header={header} locale={locale} locales={locales} />
			<div className={styles.content}>{children}</div>
			<div className={styles.footer}>
				<OnlinePresence socialLinks={socialLinks} />
				{footer && <Footer footer={footer} />}
			</div>
		</div>
	)
}
