import clsx from 'clsx'
import { HidingHeader } from 'hiding-header-react'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { HeaderResult } from '../data/HeaderFragment'
import { useOnNavigationFinished } from '../utilities/useOnNavigationFinished'
import styles from './Header.module.sass'
import { HeaderMain } from './HeaderMain'
import { letterClipTargetClassName } from './LetterClip'
import { Menu } from './Menu'

export interface HeaderProps {
	header: HeaderResult | undefined
	locale: string
	locales: Array<{ code: string; url: string }>
}

export const Header: FunctionComponent<HeaderProps> = ({ header, locale, locales }) => {
	const [isHome, setIsHome] = useState(true)
	const [showMobileHeader, setShowMobileHeader] = useState(false)
	const toggleMobileHeader = useCallback(() => {
		setShowMobileHeader((showMobileHeader) => !showMobileHeader)
	}, [])

	const gap = <div className={clsx(styles.gap, isHome && styles.is_home)} />

	useOnNavigationFinished(() => {
		setShowMobileHeader(false)
	})

	const { width } = useWindowSize()
	useEffect(() => {
		setShowMobileHeader(false)
	}, [width])

	return (
		<>
			{gap}
			<Menu
				isHome={isHome}
				isOpen={showMobileHeader}
				onRequestClose={() => {
					setShowMobileHeader(false)
				}}
				header={header}
				locale={locale}
				locales={locales}
				showMobileHeader={showMobileHeader}
				toggleMobileHeader={toggleMobileHeader}
			/>
			<div className={clsx(styles.logoClipPlaceholder, letterClipTargetClassName)} />
			<HidingHeader
				onHomeChange={(isHome) => {
					setIsHome(isHome)
				}}
			>
				<div className={clsx(styles.wrapper, isHome && styles.is_home)}>
					<HeaderMain
						header={header}
						locale={locale}
						locales={locales}
						showMobileHeader={showMobileHeader}
						toggleMobileHeader={toggleMobileHeader}
					/>
				</div>
			</HidingHeader>
			{gap}
		</>
	)
}
