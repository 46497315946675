import { createContext, FunctionComponent, ReactNode, useCallback, useContext } from 'react'

type TranslationMap = { [key: string]: string }
const context = createContext<TranslationMap>({})

export const TranslationsProvider: FunctionComponent<{
	translations: { [key: string]: string }
	children: ReactNode
}> = ({ translations, children }) => {
	return <context.Provider value={translations}>{children}</context.Provider>
}

export const useTranslate = () => {
	const translations = useContext(context)
	return useCallback(
		(key: string) => {
			if (key in translations) {
				return translations[key]
			}
			if (process && process.env.NODE_ENV === 'development') {
				console.warn(`Missing translation for key "${key}".`)
			}
			return key
		},
		[translations],
	)
}
