import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import styles from './Title.module.sass'

export interface TitleProps {
	primary: string | undefined
	secondary?: string
	secondaryFirst?: boolean
}

export const Title: FunctionComponent<TitleProps> = ({ primary, secondary, secondaryFirst = false }) => {
	return (
		<h2 className={clsx(styles.wrapper, secondaryFirst && styles.is_secondaryFirst)}>
			{primary && <div className={styles.primary}>{primary}</div>}
			{secondary && <div className={styles.secondary}>{secondary}</div>}
		</h2>
	)
}
