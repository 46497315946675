import clsx from 'clsx'
import { FunctionComponent } from 'react'
import styles from './A.module.sass'
import { Icon } from './Icon'
import { LetterClip } from './LetterClip'

export interface AProps {
	filled?: boolean
	background?: boolean
}

export const A: FunctionComponent<AProps> = ({ filled = false }) => {
	return (
		<div className={clsx(styles.wrapper, filled && styles.is_filled)}>
			<div className={styles.in}>
				{filled ? (
					<Icon name="a_filled" />
				) : (
					<LetterClip>
						<Icon name="a_outline" />
					</LetterClip>
				)}
			</div>
		</div>
	)
}
