import { CSSProperties, FunctionComponent, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'
import styles from './LetterClip.module.sass'

export interface LetterClipProps {
	children: ReactNode
}

export const letterClipTargetClassName = 'letter-clip-target'
const grow = {
	top: 4,
	left: 24,
	right: 24,
	bottom: 8,
}

type Rectangle = {
	width: number
	height: number
	top: number
	left: number
}

export const LetterClip: FunctionComponent<LetterClipProps> = ({ children }) => {
	const ref = useRef<HTMLDivElement>(null)
	const { width, height } = useWindowSize()
	const [reactangles, setRectangles] = useState<Rectangle[]>([])

	const recalculate = useCallback(() => {
		// @TODO: maybe debounce/throttle
		const wrapper = ref.current
		if (wrapper === null) {
			return
		}
		const wrapperRef = wrapper.getBoundingClientRect()
		const targets = document.querySelectorAll(`.${letterClipTargetClassName}`)
		const rects = Array.from(targets)
			.flatMap((target) => Array.from(target.getClientRects()))
			.filter((rect) => rect.width > 0 && rect.height > 0)
		setRectangles(
			rects.map<Rectangle>((rect) => {
				const left = rect.left - wrapperRef.left - grow.left
				const width = rect.width + grow.left + grow.right
				const top = rect.top - wrapperRef.top - grow.top
				const height = rect.height + grow.top + grow.bottom
				return {
					width,
					height,
					top,
					left,
				}
			}),
		)
	}, [])

	useEffect(() => {
		document.fonts.ready.then(function () {
			if (['Barlow', 'Barlow Condensed'].some((fontFamily) => document.fonts.check(`1em ${fontFamily}`))) {
				recalculate()
			}
		})
	}, [recalculate])

	useEffect(() => {
		recalculate()
	}, [width, height, recalculate])

	return (
		<div className={styles.wrapper} ref={ref}>
			<Nest rectangles={reactangles}>{children}</Nest>
		</div>
	)
}

const Nest: FunctionComponent<{ rectangles: Rectangle[]; children: ReactNode }> = ({ rectangles, children }) => {
	if (rectangles.length === 0) {
		return <>{children}</>
	}
	const [rect, ...restRectangles] = rectangles
	return (
		<div
			className={styles.layer}
			style={
				{
					[`--LetterClip-width`]: `${rect.width}px`,
					[`--LetterClip-height`]: `${rect.height}px`,
					[`--LetterClip-top`]: `${rect.top}px`,
					[`--LetterClip-left`]: `${rect.left}px`,
				} as CSSProperties
			}
		>
			<Nest rectangles={restRectangles}>{children}</Nest>
		</div>
	)
}
