import { FunctionComponent, useMemo } from 'react'
import styles from './Contact.module.sass'
import { Icon, IconName } from './Icon'

export interface ContactProps {
	phone?: string
	email?: string
}

export const Contact: FunctionComponent<ContactProps> = ({ phone, email }) => {
	const items = useMemo(() => {
		const items: Array<{ icon: IconName; href: string; label: string }> = []
		if (phone) {
			items.push({
				icon: 'phone',
				href: `tel:${phone}`,
				label: phone,
			})
		}
		if (email) {
			items.push({
				icon: 'email',
				href: `mailto:${email}`,
				label: email,
			})
		}
		return items
	}, [email, phone])

	return (
		<div className={styles.wrapper}>
			{items.map(({ href, icon, label }, i) => (
				<div className={styles.item} key={i}>
					<a href={href} className={styles.link}>
						<span className={styles.icon}>
							<Icon name={icon} />
						</span>{' '}
						<span>{label}</span>
					</a>
				</div>
			))}
		</div>
	)
}
