import clsx from 'clsx'
import { FunctionComponent } from 'react'
import styles from './MenuToggle.module.sass'
import { useTranslate } from './Translations'

export interface MenuToggleProps {
	isOpen: boolean
	toggle: () => void
}

export const MenuToggle: FunctionComponent<MenuToggleProps> = ({ isOpen, toggle }) => {
	const translate = useTranslate()

	return (
		<button
			type="button"
			aria-label={translate(isOpen ? 'menu.close' : 'menu.open')}
			className={clsx(styles.wrapper, isOpen && styles.is_open)}
			onClick={() => {
				toggle()
			}}
		>
			<span className={styles.bar} />
			<span className={styles.bar} />
			<span className={styles.bar} />
		</button>
	)
}
