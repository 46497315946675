import clsx from 'clsx'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import Modal from 'react-modal'
import { useMedia } from 'react-use'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { A } from './A'
import { HeaderMain, HeaderMainProps } from './HeaderMain'
import styles from './Menu.module.sass'
import { barlow, barlowCondensed } from '../utilities/fonts'

export interface MenuProps extends HeaderMainProps {
	isOpen: boolean
	isHome: boolean
	onRequestClose: () => void
}

export const Menu: FunctionComponent<MenuProps> = ({
	isOpen,
	isHome,
	onRequestClose,
	header,
	locale,
	locales,
	showMobileHeader,
	toggleMobileHeader,
}) => {
	const reducedMotionNoPreference = useMedia('(prefers-reduced-motion: no-preference)', true)

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			closeTimeoutMS={reducedMotionNoPreference ? 300 : 0 /* Same in styles */}
			className={clsx(styles.modal, barlow.variable, barlowCondensed.variable)}
			overlayClassName={styles.modal_overlay}
		>
			<div className={clsx(styles.wrapper, isHome && styles.is_home)}>
				<div className={styles.background}>
					<A filled />
				</div>
				<div className={styles.main}>
					<HeaderMain
						header={header}
						locale={locale}
						locales={locales}
						showMobileHeader={showMobileHeader}
						toggleMobileHeader={toggleMobileHeader}
						isInsideMenu
					/>
					{header?.localesByLocale?.linkList && (
						<ul className={styles.links}>
							{header.localesByLocale.linkList.items.map(
								({ id, link }) =>
									link && (
										<li key={id} className={styles.links_item}>
											<Link className={styles.links_link} {...contemberLinkToHrefTargetRel(link)}>
												{link.title}
											</Link>
										</li>
									),
							)}
						</ul>
					)}
				</div>
			</div>
		</Modal>
	)
}
