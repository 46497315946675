import clsx from 'clsx'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import styles from './LanguageSwitcher.module.sass'

export interface LanguageSwitcherProps {
	locale: string
	locales: Array<{ code: string; url: string }>
}

export const LanguageSwitcher: FunctionComponent<LanguageSwitcherProps> = ({ locale, locales }) => {
	return (
		<ul className={styles.wrapper}>
			{locales.map((otherLocale) => (
				<li className={clsx(styles.item, locale === otherLocale.code && styles.is_active)} key={otherLocale.code}>
					<Link href={otherLocale.url} locale={otherLocale.code} className={styles.link}>
						{otherLocale.code}
					</Link>
				</li>
			))}
		</ul>
	)
}
